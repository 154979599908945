import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { useGtm } from '@gtm-support/vue-gtm';
import type { DpProvider } from '@/core/di/DpProvider';
import type { EnvironmentDetector } from '../../domain/event-subscriber/EnvironmentDetector';
import { EnvironmentDetectorToken, SenderToken } from '../tokens';
import type { Sender } from '../../domain/event-subscriber/Sender';
import { GtmSender } from '../../adapters/GtmSender';

@injectable()
export class EventSubscriberProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<EnvironmentDetector>(EnvironmentDetectorToken, {
      useFactory() {
        return {
          isProduction: import.meta.env.NODE_ENV === 'production',
          isGtmFlagActive: !!import.meta.env.VITE_APP_GTM_ACTIVE,
        };
      },
    });

    container.register<Sender>(SenderToken, {
      useFactory() {
        return new GtmSender(useGtm);
      },
    });
  }
}
