import type { Timeline } from '@/modules/task/domain/timeline/Timeline';
import type { IconStrategy } from '../types';
import type { Task } from '../../task/Task';
import { IconName } from '../types';

export class NewToDo implements IconStrategy {
  get(task: Task, timeline: Timeline): null | IconName {
    if (task.isNewToDo) {
      return IconName.NewToDo;
    }

    return task.isOutboundToDo && !timeline.hasCall ? IconName.NewToDo : null;
  }
}
