import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { RootModule } from '@/common/root';
import { AuthenticationProvider } from './providers/AuthenticationProvider';
import { EventProvider } from './providers/EventProvider';
import { TrackingProvider } from './providers/TrackingProvider';
import { AuthorizationProvider } from './providers/AuthorizationProvider';

export class DashboardModule implements DpModule {
  static readonly namespace = Symbol('dashboardModule');

  static readonly requires = [RootModule];

  providers(): IDpProvider[] {
    return [EventProvider, TrackingProvider, AuthorizationProvider, AuthenticationProvider];
  }
}
