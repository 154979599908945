import { HttpClientApi } from '@/modules/http-client/public/api';
import { injectable } from 'tsyringe';
import type { MediaRepository } from '../../domain/create-task/MediaRepository';
import { Media } from '../../domain/models/Media';
import type { HttpMedia } from './types/HttpMedia';

@injectable()
export class HttpMediaRepository implements MediaRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async upload(fileUrl: string): Promise<Media> {
    const media: HttpMedia = await this.httpClient.put(`/api/v0/facility/{facilityId}/upload`, {
      url: fileUrl,
      storage: 'sensitive',
    });

    return new Media(media.id, media.name, media.url);
  }
}
