<template>
  <span v-once :id="`hs-cta-wrapper-${buttonId}`" class="hs-cta-wrapper">
    <span :id="`hs-cta-${buttonId}`" :class="`hs-cta-node hs-cta-${buttonId}`">
      <!--[if lte IE 8]>
        <div id="hs-cta-ie-element"></div>
      <![endif]-->
      <a :href="href" target="_blank" rel="noopener">
        <img
          :id="`hs-cta-img-${buttonId}`"
          class="hs-cta-img"
          style="border-width: 0"
          :src="`https://no-cache.hubspot.com/cta/default/${clientId}/${buttonId}.png`"
          alt="Button"
        />
      </a>
    </span>
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import DpButton from '@dp-ui-kit/vue/components/button/DpButton.vue';

interface HubSpotWindow {
  hbspt: {
    cta: {
      domReady: boolean;
      load: (clientId, buttonId, options) => void;
    };
  };
}

@Component({
  name: 'CtaButton',
  components: {
    DpButton,
  },
})
export default class CtaButton extends Vue {
  @Prop({ type: Number, required: true })
  readonly clientId: number;

  @Prop({ type: String, required: true })
  readonly buttonId: string;

  get href(): string {
    return `https://cta-redirect.hubspot.com/cta/redirect/${this.clientId}/${this.buttonId}`;
  }

  mounted() {
    const hs_window = window as unknown as HubSpotWindow;

    if (!hs_window.hbspt) {
      return;
    }
    // `domReady` has to be set for Hubspot lib to load the button properly
    hs_window.hbspt.cta.domReady = true;
    hs_window.hbspt.cta.load(this.clientId, this.buttonId, {
      useNewLoader: 'true',
      region: 'na1',
    });
  }
}
</script>
