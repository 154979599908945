import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { TrafficControlModeRepository } from '@/modules/settings/domain/traffic-control/traffic-control-mode/TrafficControlModeRepository';
import type { TrafficControlMode } from '@/modules/settings/domain/traffic-control/traffic-control-mode/TrafficControlMode';
import type { InputTrafficControlMode } from '../types/InputTrafficControlMode';
import { TrafficControlModeFactory } from './TrafficControlModeFactory';

@injectable()
export class HttpTrafficControlModeRepository implements TrafficControlModeRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly trafficControlModeFactory: TrafficControlModeFactory,
  ) {}

  async get(): Promise<TrafficControlMode> {
    const response = await this.httpClient.get<InputTrafficControlMode>(
      '/api/v0/facility/{facilityId}/traffic-control',
    );

    return this.trafficControlModeFactory.fromDTO(response);
  }

  async save(trafficControlOption: TrafficControlMode): Promise<void> {
    const payload = this.trafficControlModeFactory.toDTO(trafficControlOption);

    await this.httpClient.post('/api/v0/facility/{facilityId}/traffic-control', payload);
  }
}
