import { schema } from 'normalizr';
import type { Email, PhoneNumber } from '@/types/commons';
import { mediaSchema } from './media';
import type { Media } from './media';
import { EntityType } from './entity-type';

export type UserId = string;

/** @deprecated use interfaces and models from api/app */
export interface User {
  id: UserId;
  email?: Email;
  phone_number?: PhoneNumber;
  name: string;
  avatar?: Media | null;
  has_signed_in?: boolean;
}

/**
 * @typedef {Object} User
 * @property {number} id
 * @property {string} username probably not used anywhere in frontend, remains as
 * backward compatibility for users without a name.
 * @property {string} email
 * @property {string} [name] contains First and Last name
 * @property {Media} [avatar]
 */
export const userSchema = new schema.Entity(EntityType.USER, {
  avatar: mediaSchema,
});
