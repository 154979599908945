import { CallDurationToken } from '@/modules/phone-widget/di/tokens';
import { inject, injectable } from 'tsyringe';
import type { CallDuration } from '../call-management/CallDuration';
import type { DeviceCall } from '../models/DeviceCall';

@injectable()
export class CallDurationTracker {
  constructor(
    @inject(CallDurationToken)
    private readonly callDurationFetcher: CallDuration,
  ) {}

  get callDuration(): number | null {
    return this.callDurationFetcher.get;
  }

  manageCallDuration(activeCall: DeviceCall): void {
    const shouldCounterBeStopped = activeCall.isInProgress === false;

    if (shouldCounterBeStopped) {
      this.callDurationFetcher.stop();
      return;
    }

    const isCounterStopped = this.callDurationFetcher.get === null;

    if (isCounterStopped) {
      this.callDurationFetcher.start();
    }
  }
}
