<template>
  <dp-circle
    ref="circle"
    v-dpp-tooltip="tooltipContent"
    :class="$b({ border: hasBorder })"
    :size="size"
    :style="style"
  >
    <img
      v-if="hasAvatar"
      :class="$b('image', { grayscale })"
      :src="src"
      alt=""
      @error="hasAvatarError = true"
    />
    <span v-else>{{ initials }}</span>
  </dp-circle>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue } from 'vue-facing-decorator';
import { DpCircle } from '@dp-ui-kit/vue';

import { dppTooltip } from '@/ui/directives/dpp-tooltip';
import { userColor } from '@/ui/utils/user-color';
import { isIncludedIn } from '@/utils/functions';
import { escapeHtml } from '@/ui/utils';
import { AvatarSize } from '@/ui/atoms/UserAvatar/UserAvatar.types';

interface Colors {
  white: string;
  'gray-500': string;
  'gray-900': string;
}

@Component({
  name: 'UserAvatar',
  components: {
    DpCircle,
  },
  directives: {
    dppTooltip,
  },
})
export default class UserAvatar extends Vue {
  @Prop({ type: String, required: true })
  readonly name: string;

  @Prop({
    type: String,
    default: AvatarSize.Sm,
    validator: isIncludedIn(Object.values(AvatarSize)),
  })
  readonly size: string;

  @Prop({ type: String, default: '' })
  readonly src: string;

  @Prop({ type: String, default: '' })
  readonly tooltip: string;

  @Prop({ type: Boolean, default: false })
  readonly grayscale: boolean;

  @Ref()
  readonly imgElement: HTMLImageElement;

  @Ref('circle')
  readonly circle: DpCircle;

  hasAvatarError = false;

  colors: Colors = {
    white: 'white',
    'gray-500': 'gray',
    'gray-900': 'black',
  };

  get hasBorder(): boolean {
    return this.hasAvatar;
  }

  get initials(): string {
    if (!this.name) {
      return '';
    }

    const parts = this.name.split(/[ -]/);
    let initials = '';

    for (const part of parts) {
      initials += part.charAt(0);
    }

    if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
      initials = initials.replace(/[a-z]+/g, '');
    }

    initials = initials.substr(0, 2).toUpperCase();

    return initials;
  }

  get style() {
    if (this.hasAvatar) {
      return {
        color: null,
        backgroundColor: this.colors.white,
      };
    }

    if (this.grayscale) {
      return {
        color: this.colors.white,
        backgroundColor: this.colors['gray-500'],
      };
    }

    const color = userColor(this.name);

    return {
      color: color.isDark ? this.colors.white : this.colors['gray-900'],
      backgroundColor: `rgb(${color.r}, ${color.g}, ${color.b})`,
    };
  }

  get tooltipContent() {
    if (this.tooltip) {
      return {
        content: this.tooltip.split('\n').map(escapeHtml).join('<br>'),
        html: true,
      };
    }

    return {
      content: this.name,
    };
  }

  get hasAvatar() {
    return !!this.src && !this.hasAvatarError;
  }

  mounted() {
    this.colors = this.getColors();
  }

  getColors(): Colors {
    if (!this.circle.value) {
      return {
        white: this.colors.white,
        'gray-500': this.colors['gray-500'],
        'gray-900': this.colors['gray-900'],
      };
    }

    const computedStyles = getComputedStyle(this.circle.value);

    return {
      white: computedStyles.getPropertyValue('--white') || this.colors.white,
      'gray-500': computedStyles.getPropertyValue('--gray-500') || this.colors['gray-500'],
      'gray-900': computedStyles.getPropertyValue('--gray-900') || this.colors['gray-900'],
    };
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-user-avatar {
  --white: #{v.$white};
  --gray-900: #{v.$gray-900};
  --gray-500: #{v.$gray-500};

  position: relative;

  &--border {
    outline: 1px solid rgba(0, 0, 0, 0.04);
    outline-offset: -1px;
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;

    &--grayscale {
      filter: grayscale(1) brightness(1.4);
    }
  }
}
</style>
