import { RootStoreToken } from '@/common/root';
import type { Store } from 'vuex';
import { inject, injectable } from 'tsyringe';
import { StaticCommonEventName } from '../../domain/callbacks/StaticCommonEventName';
import type { Callback } from '../../domain/models/Callback';

@injectable()
export class UpcomingVisitCallback implements Callback {
  readonly name = 'CommonUpcomingVisit';

  readonly eventName = StaticCommonEventName.UpcomingVisit;

  readonly channelName = 'common';

  constructor(
    @inject(RootStoreToken)
    private readonly store: Store<unknown>,
  ) {}

  handler = (payload: unknown): void => {
    this.store.dispatch('upcomingVisits/HANDLE_UPCOMING_VISIT_ACTION', payload);
  };
}
