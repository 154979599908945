import { singleton } from 'tsyringe';
import type { UserSession } from '../../domain/pause/UserSession';
import type { UserSessionRepository } from '../../domain/pause/UserSessionRepository';

@singleton()
export class MemoryUserSessionRepository implements UserSessionRepository {
  private userSession: UserSession | null = null;

  async get(): Promise<UserSession | null> {
    return this.userSession;
  }

  async save(userSession: UserSession): Promise<void> {
    this.userSession = userSession;
  }
}
