<template>
  <div :class="$b()">
    <div v-if="hasComment" :class="$b('text')">
      <slot />
    </div>
    <button :class="$b('button')" type="button" @click="onClick">
      {{ buttonCopy }}
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

@Component({
  name: 'TaskComment',
  emits: ['click'],
})
export default class TaskComment extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  readonly buttonText: string;

  get hasComment(): boolean {
    return !!this.$slots.default;
  }

  get buttonCopy(): string {
    if (this.buttonText) {
      return this.buttonText;
    }

    return this.hasComment ? this.$ts('call.edit_comment') : this.$ts('call.add_comment');
  }

  onClick() {
    this.$emit('click');
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

$text-horizontal-padding: v.$spacer-md;
$text-border-width: v.$spacer-xs * 0.5;

.dp-task-comment {
  $block: &;

  display: flex;

  &__text {
    position: relative;
    padding: 0 $text-horizontal-padding;
    border-left: $text-border-width solid v.$gray-300;
    line-height: 1.6;

    &::after {
      content: '•';
      position: absolute;
      bottom: 0;
      right: $text-horizontal-padding * 0.5;
      color: v.$gray-500;
      transform: translateX(50%);
    }
  }

  &__button {
    color: v.$gray-500;
    align-self: flex-end;
  }
}
</style>
