import { Fallback } from '../models/Fallback';
import type { Workstation } from '../models/Workstation';

export class CallingGroup {
  private workstations: Workstation[];

  constructor(
    initialWorkstations: Workstation[],
    public fallback: Fallback,
  ) {
    this.setWorkstations(initialWorkstations);
  }

  getWorkstations(): Workstation[] {
    return this.workstations;
  }

  get workstationIds(): string[] {
    return this.workstations.map(({ id }) => id);
  }

  get workstationType(): 'reception' | 'non_reception' | 'none' {
    if (this.workstations.length === 0) {
      return 'none';
    }

    return this.workstations[0].isReception ? 'reception' : 'non_reception';
  }

  setWorkstations(workstationList: Workstation[]): void {
    this.validate(workstationList);
    this.workstations = workstationList;
  }

  private validate(workstationList: Workstation[]): void {
    if (!this.isValidWorkstationList(workstationList)) {
      throw new Error('Provide invalid workstation list');
    }
  }

  private isValidWorkstationList(workstationList: Workstation[]): boolean {
    if (workstationList.length === 0) {
      return true;
    }

    const { isReception } = workstationList[0];

    return workstationList.every(workstation => workstation.isReception === isReception);
  }

  static default(): CallingGroup {
    return new CallingGroup([], Fallback.default());
  }
}
