import { RootModule } from '@/common/root';
import type { IDpProvider } from '@/core/di/DpProvider';
import type { DpModule } from '@/core/modules/DpModule';
import { TrackingEventModule } from '@/common/tracking-event';
import { AuthorizationProvider } from './providers/AuthorizationProvider';
import { TrackingProvider } from './providers/TrackingProvider';
import { HelpCenterProvider } from './providers/HelpCenterProvider';
import { NewsProvider } from './providers/NewsProvider';
import { ChatProvider } from './providers/ChatProvider';
import { FeedbackFormProvider } from './providers/FeedbackFormProvider';
import { RepositoryProvider } from './providers/RepositoryProvider';

export class HelpWidgetModule implements DpModule {
  static readonly requires = [RootModule, TrackingEventModule];

  providers(): IDpProvider[] {
    return [
      TrackingProvider,
      RepositoryProvider,
      HelpCenterProvider,
      NewsProvider,
      ChatProvider,
      FeedbackFormProvider,
      AuthorizationProvider,
    ];
  }
}
