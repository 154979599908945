import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { StaticSoundNotificationRepository } from '../../adapters/sound-notification/StaticSoundNotificationRepository';
import type { SoundNotificationRepository } from '../../domain/sound-notification/SoundNotificationRepository';
import { SoundNotificationRepositoryToken } from '../tokens';

@injectable()
export class SoundNotificationProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<SoundNotificationRepository>(
      SoundNotificationRepositoryToken,
      StaticSoundNotificationRepository,
    );
  }
}
