import { injectable } from 'tsyringe';
import type { SsoClientRepository } from '@/modules/authentication/domain/sso/SsoClientRepository';
import { SsoClient } from '@/modules/authentication/domain/sso/SsoClient';
import { HttpClientApi } from '@/modules/http-client/public/api';

interface MarketplaceEndpoint {
  id: string;
  username: string;
  auth_url: string;
  scope: string;
}

@injectable()
export class HttpSsoClientRepository implements SsoClientRepository {
  constructor(private readonly client: HttpClientApi) {}

  async find(): Promise<SsoClient | null> {
    const [endpoint] = await this.client.get<MarketplaceEndpoint[]>(
      '/api/v0/auth/endpoints/marketplace',
    );

    if (!endpoint) {
      return null;
    }

    return new SsoClient(endpoint.id, endpoint.username, endpoint.auth_url, endpoint.scope);
  }
}
