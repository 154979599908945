import type { DependencyContainer } from 'tsyringe';
import { DpModuleFactory } from './DpModuleFactory';
import { DpAppError } from './DpAppError';
import type { IDpModule } from './DpModule';

export class DpAppRegistry {
  private readonly dpModuleFactory = new DpModuleFactory();

  private readonly registeredModules: IDpModule[] = [];

  constructor(private readonly container: DependencyContainer) {}

  registerModules(pendingModules: IDpModule[]): void {
    for (const module of this.orderedModules(pendingModules)) {
      this.registerModule(module);
    }
  }

  private registerModule(module: IDpModule): void {
    this.dpModuleFactory.make(this.container, module);
    this.registeredModules.push(module);
  }

  private *orderedModules(modules: IDpModule[]): Generator<IDpModule, void, unknown> {
    while (true) {
      const unregisteredModules = modules.filter(
        module => !this.registeredModules.includes(module),
      );

      if (unregisteredModules.length === 0) {
        return;
      }

      const moduleToRegister = unregisteredModules.find(module =>
        this.hasRegisteredDependencies(module),
      );

      if (!moduleToRegister) {
        throw new DpAppError(
          "There is a cycle in module's requires, or you haven't register some module and further modules cannot be initialized!",
        );
      }

      yield moduleToRegister;
    }
  }

  private hasRegisteredDependencies(module: IDpModule): boolean {
    if (!module.requires) {
      return true;
    }

    return module.requires.every(requiredModule => this.registeredModules.includes(requiredModule));
  }
}
