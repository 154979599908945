import type { EventsListItem } from '@/modules/timeline/domain/timeline/events-list/EventsListItem';
import type { IsoDate } from '@/modules/timeline/domain/IsoDate';

export class ContactForm implements EventsListItem {
  readonly direction = 'inbound' as const;

  constructor(
    readonly id: string,
    readonly createdAt: IsoDate,
    readonly firstName: string,
    readonly lastName: string,
    readonly email: string,
    readonly message: string,
    readonly channelId: string,
  ) {}
}
