import type { HttpTextChannel } from '@/modules/settings/adapters/channels/HttpTextChannel';
import { TextChannel } from '@/modules/settings/domain/channels/TextChannel';

export class HttpTextChannelFactory {
  static makeMany(channels: HttpTextChannel[]): TextChannel[] {
    return channels.map(channel => {
      if (channel.type === 'whatsapp') {
        return this.makeWhatsApp(channel.id, channel.number);
      }

      if (channel.type === 'sms') {
        return this.makeSms(channel.id, channel.number);
      }

      throw new Error(`Unknown channel type: ${channel.type}`);
    });
  }

  private static makeWhatsApp(id: string, phoneNumber: string): TextChannel {
    return new TextChannel(id, 'WhatsApp', 'whatsapp', phoneNumber);
  }

  private static makeSms(id: string, phoneNumber: string): TextChannel {
    return new TextChannel(id, 'SMS', 'sms', phoneNumber);
  }
}
