import { injectable } from 'tsyringe';
import type { NewsController } from '../../domain/news/NewsController';
import { NewsError } from '../../domain/news/NewsError';

@injectable()
export class BeamerNewsController implements NewsController {
  async show(): Promise<void> {
    if (!window.Beamer) {
      throw new NewsError('News are not available.');
    }

    window.Beamer?.show();
  }
}
