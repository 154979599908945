<template>
  <div v-dpp-tooltip="{ content: tooltip }" :class="$b(blockModifiers)">
    <button v-if="clickable" :class="$b('button')">
      <component :is="icon" :class="$b('icon')" :size="size" />
    </button>
    <component :is="icon" v-else :class="$b('icon')" :size="size" />
  </div>
</template>

<script lang="ts">
import type { Component as VueComponent } from 'vue';

import { Component, Prop, Vue } from 'vue-facing-decorator';

import { dppTooltip } from '@/ui/directives/dpp-tooltip';
import { BadgeIconColors } from './BadgeIcon.types';

@Component({
  name: 'BadgeIcon',
  directives: {
    dppTooltip,
  },
})
export default class BadgeIcon extends Vue {
  @Prop({ type: String, default: BadgeIconColors.Warning })
  readonly color: string;

  @Prop({ type: Object, required: true })
  readonly icon: VueComponent;

  @Prop({ type: Boolean, default: false })
  readonly clickable: boolean;

  @Prop({ type: Boolean, default: false })
  readonly noBackground: boolean;

  @Prop({ type: Number, default: 18 })
  readonly size: number;

  @Prop({ type: String, default: '' })
  readonly tooltip: string;

  get blockModifiers() {
    return {
      clickable: this.clickable,
      color: this.color,
      transparent: this.noBackground,
    };
  }
}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

$badge-size: v.$spacer * 1.25;

$badge-colors: (
  color_success: (
    text-color: v.$success,
    bg-color: v.$success-light,
  ),
  color_warning: (
    text-color: v.$warning,
    bg-color: v.$warning-light,
  ),
  color_gray: (
    text-color: v.$gray-500,
    bg-color: v.$gray-200,
  ),
);

.dp-badge-icon {
  $block: &;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: $badge-size;
  width: $badge-size;
  line-height: 0;

  @each $type, $color-map in $badge-colors {
    $text-color: map_get($color-map, text-color);

    &--#{$type} {
      background: map_get($color-map, bg-color);
      color: $text-color;

      &:hover {
        &#{$block}--clickable {
          color: darken($text-color, 15%);
        }
      }
    }
  }

  &--clickable {
    cursor: pointer;
    transition: 0.3s ease;
  }

  &__button {
    line-height: 0;
    color: inherit;
  }

  &--transparent {
    background: rgba(0, 0, 0, 0);
  }

  :deep(svg) {
    fill: currentColor;
  }
}
</style>
