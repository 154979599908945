import type { Timeline } from '@/modules/task/domain/timeline/Timeline';
import { PatientRequest as TimelinePatientRequest } from '../../patient-request/PatientRequest';
import type { Task } from '../../task/Task';
import { IconName } from '../types';
import type { IconStrategy } from '../types';

export class PatientRequest implements IconStrategy {
  get(task: Task, { lastUserEvent }: Timeline): null | IconName {
    if (!(lastUserEvent instanceof TimelinePatientRequest)) {
      return null;
    }

    return IconName.IncomingDpChatMessage;
  }
}
