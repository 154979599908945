import { injectable } from 'tsyringe';

@injectable()
export class Clock {
  private readonly activeMeasurementsStartTimesList = new Map<string, number>();

  startTimer(id: string): void {
    if (this.activeMeasurementsStartTimesList.has(id)) {
      this.clearTimer(id);
    }

    const now = performance.now();
    this.activeMeasurementsStartTimesList.set(id, now);
  }

  endTimer(id: string): number | null {
    const startTime = this.activeMeasurementsStartTimesList.get(id);

    if (!startTime) {
      return null;
    }

    this.clearTimer(id);

    return performance.now() - startTime;
  }

  hasStartedTimer(id: string): boolean {
    return this.activeMeasurementsStartTimesList.has(id);
  }

  clearTimer(id: string): void {
    this.activeMeasurementsStartTimesList.delete(id);
  }

  async measure(id: string, callback: () => Promise<void>): Promise<number | null> {
    this.startTimer(id);

    try {
      await callback();
      return this.endTimer(id);
    } catch (e) {
      this.clearTimer(id);
      throw e;
    }
  }
}
