import { CallStatus } from '@/app/task/call-status';

const internalPhoneModule = {
  namespaced: true,
};

function ongoingTask(_, getters) {
  return getters.getTaskWithOngoingCallForCurrentUser;
}

function ongoingTaskChangeHandler(store) {
  function resetConnectionState() {
    store.dispatch('phoneWidget/SET_CONNECTION_PHONE_NUMBER_ACTION', null);
    store.dispatch('phoneWidget/SET_CONNECTION_INSTANCE_ACTION', null);
  }

  return task => {
    if (!task) {
      resetConnectionState();
      return;
    }

    const { status: callStatus, direction: callDirection } = task?.history[0];

    const internalConnection = {
      isIncoming: callDirection === 'inbound',
    };

    switch (callStatus) {
      case CallStatus.Waiting: {
        const phoneNumber = task.caller;
        internalConnection.hasWaitingCall = true;

        store.dispatch('phoneWidget/SET_CONNECTION_INSTANCE_ACTION', internalConnection);
        store.dispatch('phoneWidget/SET_CONNECTION_PHONE_NUMBER_ACTION', phoneNumber);
        break;
      }

      case CallStatus.InProgress: {
        internalConnection.isOpen = true;

        store.dispatch('phoneWidget/SET_CONNECTION_INSTANCE_ACTION', internalConnection);
        break;
      }

      default: {
        resetConnectionState();
      }
    }
  };
}

export function registerInternalPhoneModule(store) {
  store.registerModule(['phoneWidget', 'internal'], internalPhoneModule);
  // TODO from some unknown reason attaching it without timeout doesn't work 🤷🏻‍♂️
  setTimeout(() => store.watch(ongoingTask, ongoingTaskChangeHandler(store)), 0);
}
