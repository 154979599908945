import { injectable } from 'tsyringe';
import { Authorization } from '@/common/authorization/public/api';
import type { Authorizer } from '@/modules/tasks-list/domain/authorization/Authorizer';
import {
  FilterByPatientRequest,
  SendTextMessage,
} from '@/modules/tasks-list/domain/authorization/policies';

@injectable()
export class AuthorizationApiAuthorizer implements Authorizer {
  constructor(private readonly authorization: Authorization) {}

  canSendTextMessage(): boolean {
    return this.authorization.can(SendTextMessage);
  }

  canFilterByPatientRequest(): boolean {
    return this.authorization.can(FilterByPatientRequest);
  }
}
