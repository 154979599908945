<template>
  <i :class="iconClass">
    <!-- eslint-disable max-len vue/max-attributes-per-line -->
    <svg width="20" height="22" viewBox="0 0 20 22" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.99918 0.883545H17.9992C19.0992 0.883545 19.9992 1.78355 19.9992 2.88354V14.8835C19.9992 15.9835 19.0992 16.8835 17.9992 16.8835H3.99918L1.86922 20.611C1.36032 21.5015 0.000488174 21.1401 0.000976694 20.1144L0.00918163 2.88354C0.00918163 1.78355 0.899182 0.883545 1.99918 0.883545ZM12.2507 9.98984C11.4557 10.4802 10.6763 11.4307 10.3992 12.11L12.208 14.2122L14.011 12.9022L12.2507 9.98984ZM5.31728 12.6128L7.79909 14.209C9.28535 11.1779 12.124 8.9326 15.5254 8.2626L14.8342 6.13333C10.7074 6.59933 7.1963 9.10051 5.31728 12.6128ZM8.65439 3.2511L8.3407 6.98002L4.70448 6.12383L4.0163 8.24701L6.54098 9.31282C7.05873 8.78917 7.6374 8.33255 8.27369 7.94236C9.1356 7.4125 9.88786 7.06204 11.167 6.62334L10.8838 3.2511H8.65439Z"
      />
    </svg>
    <!-- eslint-enable max-len vue/max-attributes-per-line -->
  </i>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import BaseIcon from '@dp-ui-kit/icons/vue/BaseIcon.vue';

export default defineComponent({
  name: 'DpChat',
  extends: BaseIcon,
  iconName: 'dp-chat',
});
</script>
