import { IsoDate } from '../time/IsoDate';
import type { TimezoneRepository } from '../time/TimezoneRepository';
import type { TimelineEvent } from '../timeline/TimelineEvent';

export class PatientRequest implements TimelineEvent {
  readonly hasOwner = false;

  constructor(
    readonly id: string,
    readonly createdAt: string,
    readonly type:
      | 'prescription'
      | 'certificate'
      | 'refill-prescription'
      | 'test-results'
      | 'other',
    readonly subject: string,
  ) {}

  get isPrescription(): boolean {
    return this.type === 'prescription';
  }

  displayedDate(timezoneRepository: TimezoneRepository): IsoDate {
    return IsoDate.make(this.createdAt, timezoneRepository.get());
  }

  isMine(): boolean {
    return false;
  }

  isOwnedBy(): boolean {
    return false;
  }
}
