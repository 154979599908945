<template>
  <div :class="$b()">
    <main :class="$b('main')">
      <div :class="$b('icon')">
        <dp-icon-docplanner color="aquamarine" :size="64" />
      </div>

      <div :class="$b('panel')">
        <div :class="$b('header')">
          <div v-if="title || subtitle" :class="$b('header-inner')">
            <h1 v-if="title" :class="$b('title')">
              {{ title }}
            </h1>
            <h2 v-if="subtitle" :class="$b('subtitle')">
              {{ subtitle }}
            </h2>
          </div>
          <slot name="header" />
        </div>

        <slot />

        <div :class="$b('footer')">
          <slot name="bottom" />
        </div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { DpIconDocplanner } from '@dp-ui-kit/icons';

@Component({
  name: 'LayoutWithPanel',
  components: {
    DpIconDocplanner,
  },
})
export default class LayoutWithPanel extends Vue {
  @Prop({ type: String, default: '' })
  readonly title: string;

  @Prop({ type: String, default: '' })
  readonly subtitle: string;
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;
@use 'src/assets/scss/mixins' as m;
@use 'src/assets/scss/functions/px-to-rem' as f;

$container-max-width: f.px-to-rem(544px);

.dp-layout-with-panel {
  &__main {
    @include m.container($container-max-width);

    padding-top: v.$spacer-xl;
    padding-bottom: v.$spacer-xl;
  }

  &__icon {
    margin: 0 auto v.$spacer-xl;
    text-align: center;
  }

  &__header-inner {
    margin-bottom: v.$spacer-lg;
  }

  &__title,
  &__subtitle {
    font-size: v.$h2-font-size;
    font-weight: v.$font-weight-semibold;
    letter-spacing: v.$letter-spacing-tight;
    line-height: v.$line-height-normal;
  }

  &__subtitle {
    color: v.$gray-600;
  }

  &__panel {
    @media screen and (min-width: #{$container-max-width}) {
      border-top: 4px solid v.$primary;
      border-left: 1px solid v.$gray-300;
      border-bottom: 1px solid v.$gray-300;
      border-right: 1px solid v.$gray-300;
      border-radius: v.$border-radius-lg;
      padding: v.$spacer-xl;
    }
  }
}
</style>
