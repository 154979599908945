import { injectable } from 'tsyringe';
import type { CallError } from '../models/CallError';
import type { DeviceCall } from '../models/DeviceCall';
import { ErrorProcessor } from './ErrorProcessor';

@injectable()
export class CallErrorTracker {
  constructor(private readonly errorProcessor: ErrorProcessor) {}

  attachListener(call: DeviceCall): void {
    call.addEventListener('error', (error: CallError) => {
      this.errorProcessor.handleCallError(error);
    });
  }
}
