import type { ValidationErrorMessage } from '@/modules/tasks-list/domain/ValidationErrorMessage';

export class CreationError extends Error {
  constructor(public readonly validationErrorMessages: ValidationErrorMessage[]) {
    super('Creation failed due to invalid payload content.');

    // Changing prototype for extending default class is recommended by TS
    // https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, CreationError.prototype);

    this.name = 'CreationError';
  }
}
