import type { TimelineEvent } from '@/modules/task/domain/timeline/TimelineEvent';
import { IsoDate } from '@/modules/task/domain/time/IsoDate';

export class EmptyTimelineEvent implements TimelineEvent {
  readonly id = '';

  readonly hasOwner = false;

  displayedDate(): IsoDate {
    return IsoDate.empty;
  }

  isMine(): boolean {
    return false;
  }

  isOwnedBy(): boolean {
    return false;
  }
}
