import {
  CallErrorRepositoryToken,
  DeviceErrorRepositoryToken,
  ErrorLoggerToken,
} from '@/modules/phone-widget/di/tokens';
import { inject, injectable } from 'tsyringe';
import type { CallError } from '../models/CallError';
import type { DeviceError } from '../models/DeviceError';
import type { CallErrorRepository } from './CallErrorRepository';
import { ContextRepository } from './ContextRepository';
import type { DeviceErrorRepository } from './DeviceErrorRepository';
import type { ErrorLogger } from './ErrorLogger';
import { IgnoredErrors } from './IgnoredErrors';
import { RestartScheduler } from '../restart/RestartScheduler';

@injectable()
export class ErrorProcessor {
  constructor(
    @inject(CallErrorRepositoryToken)
    private readonly callErrorRepository: CallErrorRepository,
    @inject(DeviceErrorRepositoryToken)
    private readonly deviceErrorRepository: DeviceErrorRepository,
    private readonly ignoredErrors: IgnoredErrors,
    @inject(ErrorLoggerToken)
    private readonly errorLogger: ErrorLogger,
    private readonly contextRepository: ContextRepository,
    private readonly restartScheduler: RestartScheduler,
  ) {}

  handleCallError(error: CallError): void {
    if (this.ignoredErrors.isIgnored(error)) {
      return;
    }

    this.callErrorRepository.add(error);

    if (this.ignoredErrors.shouldNotBeTracked(error)) {
      return;
    }

    this.errorLogger.logAsError(error, this.getContext());
  }

  handleDeviceError(error: DeviceError): void {
    if (this.ignoredErrors.isIgnored(error)) {
      return;
    }

    this.deviceErrorRepository.add(error);
    this.restartScheduler.tryRestart();

    if (this.ignoredErrors.shouldNotBeTracked(error)) {
      return;
    }

    this.errorLogger.logAsError(error, this.getContext());
  }

  private getContext() {
    return this.contextRepository.get();
  }
}
