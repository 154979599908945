import { TrafficControlMode } from '@/modules/settings/domain/traffic-control/traffic-control-mode/TrafficControlMode';
import { injectable } from 'tsyringe';
import type { InputTrafficControlMode } from '../types/InputTrafficControlMode';
import type { OutputTrafficControlMode } from '../types/OutputTrafficControlMode';

@injectable()
export class TrafficControlModeFactory {
  fromDTO({ mode }: InputTrafficControlMode): TrafficControlMode {
    return {
      one_by_one: TrafficControlMode.OneByOne,
      priority_group: TrafficControlMode.DecideWhoPicks,
      voice_menu: TrafficControlMode.VoiceMenu,
    }[mode];
  }

  toDTO(trafficControlMode: TrafficControlMode): OutputTrafficControlMode {
    const mode = (
      {
        [TrafficControlMode.OneByOne]: 'one_by_one',
        [TrafficControlMode.DecideWhoPicks]: 'priority_group',
        [TrafficControlMode.VoiceMenu]: 'voice_menu',
      } as const
    )[trafficControlMode];

    return { mode };
  }
}
