import type { TimelineWhatsAppMessage } from '@/modules/timeline/public/api/data-transfer/message/TimelineWhatsAppMessage';
import type { TimelineSmsMessage } from '@/modules/timeline/public/api/data-transfer/message/TimelineSmsMessage';
import { injectable } from 'tsyringe';
import { Message } from '../../domain/Message';
import type { Patient } from '../../domain/Patient';

type TimelineMessage = TimelineWhatsAppMessage | TimelineSmsMessage;

@injectable()
export class MessageFactory {
  makeMany(messages: TimelineMessage[], patient: Patient, taskId: string): Message[] {
    return messages.map(this.makeMap(patient, taskId), this);
  }

  private makeMap(patient: Patient, taskId: string): (message: TimelineMessage) => Message {
    return message => this.make(message, patient, taskId);
  }

  make(message: TimelineMessage, patient: Patient, taskId: string): Message {
    return new Message(message.id, taskId, message.body, message.direction, patient);
  }
}
