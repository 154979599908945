import type { Timeline } from '@/modules/task/domain/timeline/Timeline';
import { WhatsAppMessage } from '@/modules/task/domain/message/WhatsAppMessage';
import type { IconStrategy } from '../types';
import type { Task } from '../../task/Task';
import { IconName } from '../types';

export class OutgoingWhatsAppMessage implements IconStrategy {
  get(task: Task, { lastUserEvent }: Timeline): null | IconName {
    if (!(lastUserEvent instanceof WhatsAppMessage)) {
      return null;
    }

    if (lastUserEvent.isOutbound) {
      return IconName.OutgoingWhatsAppMessage;
    }

    return null;
  }
}
