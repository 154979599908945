import type { UpcomingVisit as RawUpcomingVisit } from '@/api/types/entities/upcoming-visit';
import type { PhoneNumber } from '@/api/types';
import { upcomingVisitSchema } from '@/api/schemas/upcoming-visit-schema';
import { UpcomingVisitOutput } from './UpcomingVisitOutput';

export class UpcomingVisit {
  static schema = upcomingVisitSchema;

  phone_number: PhoneNumber;

  visit_output: UpcomingVisitOutput | null;

  private constructor(data: RawUpcomingVisit) {
    this.phone_number = data.phone_number;
    this.visit_output = UpcomingVisitOutput.tryMake(data.visit_output);
  }

  get phoneNumber(): PhoneNumber {
    return this.phone_number;
  }

  get visitOutput(): UpcomingVisitOutput | null {
    return this.visit_output;
  }

  get hasVisitOutput(): boolean {
    return this.visitOutput !== null;
  }

  static make(data: RawUpcomingVisit): UpcomingVisit {
    return new UpcomingVisit(data);
  }

  static tryMake(data?: RawUpcomingVisit | null): UpcomingVisit | null {
    return data ? UpcomingVisit.make(data) : null;
  }
}
