<template>
  <div v-if="isPresent" :class="$b()">
    {{ text }}
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { isFuture } from 'date-fns';

@Component({
  name: 'ExpiringLabel',
})
export default class ExpiringLabel extends Vue {
  @Prop({ type: String, required: true })
  readonly until: string;

  @Prop({ type: String, default: '' })
  readonly text: string;

  get isPresent(): boolean {
    return isFuture(new Date(this.until));
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-expiring-label {
  padding-left: v.$spacer-xs;
  padding-right: v.$spacer-xs;
  margin-left: v.$spacer-sm;
  background-color: v.$calm;
  border-radius: v.$border-radius-sm;
  text-transform: uppercase;
  font-weight: v.$font-weight-bold;
  font-size: v.$font-size-sm;
  color: v.$white;
}
</style>
