<template>
  <div :class="$b()">
    <dp-nav :class="$b('links')">
      <dp-nav-item
        v-for="item in items"
        :key="`tab-${item.name}`"
        :class="$b('link')"
        :active="isActive(item)"
        @click="setActiveItem(item)"
      >
        {{ item.label }}
      </dp-nav-item>
    </dp-nav>

    <div
      v-for="item in items"
      :key="`panel-${item.name}`"
      :class="$b('panel', { active: isActive(item) })"
    >
      <slot :name="item.name" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { DpNav, DpNavItem } from '@dp-ui-kit/vue';

interface TabItemInterface {
  label: string;
  name: string;
}

@Component({
  name: 'Tabs',
  components: { DpNav, DpNavItem },
})
export default class Tabs extends Vue {
  @Prop({ type: Number, default: 0 })
  readonly activeIndex: number;

  @Prop({ type: Array, required: true })
  readonly items: TabItemInterface[];

  activeItem: TabItemInterface;

  get activeItemName(): string {
    if (!this.items?.length) {
      return '';
    }

    return this.activeItem?.name || this.items[this.activeIndex].name;
  }

  isActive(item: TabItemInterface): boolean {
    return item.name === this.activeItemName;
  }

  setActiveItem(item: TabItemInterface) {
    this.activeItem = item;
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-tabs {
  &__link {
    cursor: pointer;

    :deep(.nav-link.active) {
      color: v.$primary-dark;
      border-color: currentColor;
    }
  }

  &__panel {
    display: none;
    padding-top: v.$spacer-md;

    &--active {
      display: block;
    }
  }
}
</style>
