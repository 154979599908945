import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { VisitConfirmationWriteRepository } from '../../domain/visit/VisitConfirmationWriteRepository';
import type { VisitConfirmation } from '../../domain/visit/VisitConfirmation';

@injectable()
export class HttpVisitConfirmationWriteRepository implements VisitConfirmationWriteRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  setVisitStatus(taskId: string, status: VisitConfirmation['status']): Promise<void> {
    return this.httpClient.post(this.makeEndpointPath(taskId, status));
  }

  private makeEndpointPath(taskId: string, status: VisitConfirmation['status']): string {
    switch (status) {
      case 'canceled':
        return `/api/v0/task/${taskId}/visit/cancel`;
      case 'confirmed':
        return `/api/v0/task/${taskId}/visit/confirm`;
      case 'rescheduled':
        return `/api/v0/task/${taskId}/visit/reschedule`;
      default:
        throw new Error(`Unsupported visit status '${status}'`);
    }
  }
}
