import { AuthorizationModule } from '@/common/authorization';
import { TimelineModule } from '@/modules/timeline';
import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { PatientModule } from '@/modules/patient';
import { RootModule } from '@/common/root';
import { TrackingEventModule } from '@/common/tracking-event';
import { UserModule } from '@/modules/user';
import { TasksListModule } from '@/modules/tasks-list';
import { HttpClientModule } from '@/modules/http-client';
import { RepositoryProvider } from './providers/RepositoryProvider';
import { IconsProvider } from './providers/IconProvider';
import { PhoneNumberFormatterProvider } from './providers/PhoneNumberFormatterProvider';
import { ActionProvider } from './providers/ActionProvider';
import { TranslatorProvider } from './providers/TranslatorProvider';
import { TagProvider } from './providers/TagProvider';
import { TrackingProvider } from './providers/TrackingProvider';
import { WorkstationProvider } from './providers/WorkstationProvider';
import { AuthorizationProvider } from './providers/AuthorizationProvider';
import { SubjectProvider } from './providers/SubjectProvider';
import { ReminderProvider } from './providers/ReminderProvider';
import { TimeProvider } from './providers/TimeProvider';
import { VisitProvider } from './providers/VisitProvider';
import { SettingProvider } from './providers/SettingProvider';
import { ModalProvider } from './providers/ModalProvider';
import { CallQualityProvider } from './providers/CallQualityProvider';
import { ContextProvider } from './providers/ContextProvider';
import { TaskProvider } from './providers/TaskProvider';

export class TaskModule implements DpModule {
  static readonly namespace = Symbol('TaskModule');

  static readonly requires = [
    RootModule,
    UserModule,
    AuthorizationModule,
    PatientModule,
    TimelineModule,
    TrackingEventModule,
    TasksListModule,
    HttpClientModule,
  ];

  providers(): IDpProvider[] {
    return [
      AuthorizationProvider,
      TimeProvider,
      RepositoryProvider,
      IconsProvider,
      PhoneNumberFormatterProvider,
      ActionProvider,
      TranslatorProvider,
      TagProvider,
      TrackingProvider,
      WorkstationProvider,
      SubjectProvider,
      ReminderProvider,
      VisitProvider,
      SettingProvider,
      ModalProvider,
      CallQualityProvider,
      ContextProvider,
      TaskProvider,
    ];
  }
}
