import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { BrowserDesktopNotificationPermission } from '../../adapters/permissions/BrowserDesktopNotificationPermission';
import type { DesktopNotificationPermission } from '../../domain/permissions/DesktopNotificationPermission';
import { DesktopNotificationPermissionToken } from '../tokens';

@injectable()
export class PermissionProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<DesktopNotificationPermission>(
      DesktopNotificationPermissionToken,
      BrowserDesktopNotificationPermission,
    );
  }
}
