<template>
  <div :class="$b({ theme })">
    <audio-player-speed-control
      v-if="canChangeSpeed"
      v-model="speed"
      :class="$b('speed-control')"
      :disabled="!isPlaying"
      :theme="theme"
      @update:model-value="updateSpeed"
    />
    <div :class="$b('player', { disabled: !src })">
      <vue-plyr ref="plyr" :options="playerConfig">
        <audio>
          <source :src="src" type="audio/mp3" />
        </audio>
      </vue-plyr>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue } from 'vue-facing-decorator';

import { PlayerTheme } from '@/ui/molecules/AudioPlayer/AudioPlayer.types';
import type { Player } from '@/ui/molecules/AudioPlayer/AudioPlayer.types';
import AudioPlayerSpeedControl from '@/ui/atoms/AudioPlayerSpeedControl/AudioPlayerSpeedControl.vue';

@Component({
  name: 'AudioPlayer',
  emits: ['play', 'changeSpeed'],

  components: {
    AudioPlayerSpeedControl,
  },
})
export default class AudioPlayer extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly canChangeSpeed: boolean;

  @Prop({ type: String, required: true })
  readonly src: string;

  @Prop({ type: String, default: PlayerTheme.White })
  readonly theme: PlayerTheme;

  speed = 1;

  isPlaying = false;

  get playerConfig() {
    return {
      controls: ['play', 'progress', 'current-time'],
    };
  }

  get player(): Player {
    return this.plyr.player;
  }

  @Ref()
  readonly plyr!: { player: Player };

  mounted(): void {
    this.attachPlayerListeners();
    this.setDefaultPlaybackSpeed();
  }

  beforeUnmount(): void {
    this.player.destroy();
  }

  attachPlayerListeners(): void {
    this.player.on('playing', this.userStartPlayingAudioListener);
    this.player.on('playing', this.setPlayingFlag);
    this.player.on('pause', this.unsetPlayingFlag);
    this.player.on('ended', this.unsetPlayingFlag);
  }

  setDefaultPlaybackSpeed(): void {
    this.speed = 1;
    this.player.speed = 1;
  }

  updateSpeed(newSpeed: number): void {
    this.player.speed = newSpeed;
    this.$emit('changeSpeed');
  }

  userStartPlayingAudioListener(): void {
    if (this.player.currentTime === 0) {
      this.$emit('play');
    }
  }

  setPlayingFlag(): void {
    this.isPlaying = true;
  }

  unsetPlayingFlag(): void {
    this.isPlaying = false;
  }
}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

$audio-player-themes: (
  'white': (
    bg-color: v.$white,
    controls-bg-color: v.$white,
    controls-text-color: v.$gray-600,
    controls-hover-bg-color: v.$white,
    controls-hover-text-color: v.$primary,
  ),
  'gray-100': (
    bg-color: v.$gray-100,
    controls-bg-color: v.$gray-100,
    controls-text-color: v.$gray-600,
    controls-hover-bg-color: v.$primary,
    controls-hover-text-color: v.$gray-100,
  ),
  'primary-light': (
    bg-color: v.$primary-light,
    controls-bg-color: v.$primary-light,
    controls-text-color: v.$gray-600,
    controls-hover-bg-color: v.$primary,
    controls-hover-text-color: v.$primary-light,
  ),
);

.dp-audio-player {
  display: flex;
  align-items: center;
  border-radius: v.$border-radius;

  $this: &;

  @each $theme, $theme-map in $audio-player-themes {
    &--theme_#{$theme} {
      background-color: map_get($theme-map, bg-color);

      #{$this}__player {
        :deep(.plyr) {
          &.plyr--audio {
            .plyr__controls {
              background-color: map_get($theme-map, controls-bg-color);
              color: map_get($theme-map, controls-text-color);
            }

            .plyr__control {
              &.plyr__tab-focus,
              &:hover,
              &[aria-expanded='true'] {
                background-color: map_get($theme-map, controls-hover-bg-color);
                color: map_get($theme-map, controls-hover-text-color);
              }
            }
          }
        }
      }
    }
  }

  &__player {
    position: relative;

    :deep(.plyr) {
      &.plyr--full-ui input[type='range'] {
        color: v.$primary;
      }

      &.plyr--audio {
        .plyr__controls {
          padding: 0;
        }

        .plyr__progress__buffer {
          color: v.$gray-300;
        }

        .plyr__time {
          color: v.$gray-900;
        }
      }
    }

    &--disabled::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: v.$white;
      opacity: 0.75;
      z-index: 2;
      cursor: not-allowed;
    }
  }

  &__speed-control {
    margin-right: v.$spacer-xs;
  }
}
</style>
