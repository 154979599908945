import type { IntegrationRepository } from '@/modules/statistics/domain/integration/IntegrationRepository';
import { inject, injectable } from 'tsyringe';
import { IntegrationRepositoryToken } from '@/modules/statistics/di/tokens';

@injectable()
export class IntegrationGate {
  constructor(
    @inject(IntegrationRepositoryToken)
    private readonly integrationRepository: IntegrationRepository,
  ) {}

  hasVisitConfirmations(): boolean {
    const integrations = this.integrationRepository.get();

    return integrations.includes('docplanner-visit-confirmation');
  }
}
