import type { Module } from 'vuex';
import { DeviceCall } from '../../domain/webphone/models/DeviceCall';
import { CallState } from '../../domain/webphone/models/CallState';
import { DeviceState } from '../../domain/webphone/models/DeviceState';
import type { DeviceError } from '../../domain/webphone/models/DeviceError';
import type { CallError } from '../../domain/webphone/models/CallError';

export const webphoneStoreName = 'phoneWidget:webphone';

export interface Store {
  getters: Record<string, any>;
  commit(name: string, payload?: any): void;
}

interface ModuleState {
  deviceState: DeviceState;
  callState: CallState;
  activeCall: DeviceCall;
  activeCallStartTimestamp: null | number;
  deviceErrors: DeviceError[];
  callErrors: CallError[];
}

export const webphone: Module<ModuleState, unknown> = {
  namespaced: true,
  state: () => ({
    deviceState: DeviceState.Unregistered,
    callState: CallState.Closed,
    activeCall: DeviceCall.empty(),
    activeCallStartTimestamp: null,
    deviceErrors: [],
    callErrors: [],
  }),
  getters: {
    currentTimestamp: (state, getters, rootState, { getCurrentTimestamp }) => getCurrentTimestamp,
    deviceState: ({ deviceState }) => deviceState,
    callState: ({ callState }) => callState,
    activeCall: ({ activeCall }) => activeCall,
    activeCallDuration: ({ activeCallStartTimestamp }, { currentTimestamp }) =>
      activeCallStartTimestamp && currentTimestamp - activeCallStartTimestamp,
    getDeviceErrors: ({ deviceErrors }) => deviceErrors,
    getCallErrors: ({ callErrors }) => callErrors,
  },
  mutations: {
    SET_DEVICE_STATE(state, deviceStatus: DeviceState): void {
      state.deviceState = deviceStatus;
    },
    SET_CALL_STATE(state, deviceStatus: CallState): void {
      state.callState = deviceStatus;
    },
    SET_ACTIVE_CALL(state, call: DeviceCall): void {
      state.activeCall = call;
    },
    START_MEASURE_CALL_DURATION(state, startTimestamp: number) {
      state.activeCallStartTimestamp = startTimestamp;
    },
    STOP_MEASURE_CALL_DURATION(state) {
      state.activeCallStartTimestamp = null;
    },
    ADD_DEVICE_ERROR(state, deviceError: DeviceError): void {
      state.deviceErrors.push(deviceError);
    },
    CLEAR_DEVICE_ERROR(state): void {
      state.deviceErrors = [];
    },
    ADD_CALL_ERROR(state, callError: CallError): void {
      state.callErrors.push(callError);
    },
    CLEAR_CALL_ERROR(state): void {
      state.callErrors = [];
    },
  },
};
