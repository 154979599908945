import type { NormalizedSchema, Schema } from 'normalizr';
import { denormalize, normalize } from 'normalizr';
import type { EntitiesList } from '@/api/types/entities';

type Result = string | number;

type Entities<T> = Record<string, T>;

type ModelFactory<T> = (rawData: unknown) => T;

export class NormalizeMapper<T> {
  constructor(
    private readonly schema: Schema,
    private readonly modelFactory: ModelFactory<T>,
    private readonly denormalizedFactory: ModelFactory<T>,
  ) {
    this.schema = schema;
    this.modelFactory = modelFactory;
  }

  normalizeMany(entitiesList: T[]): NormalizedSchema<Entities<T>, Result[]> {
    return normalize(entitiesList, [this.schema]);
  }

  normalizeSingle(entityData: T): NormalizedSchema<Entities<T>, Result> {
    return normalize(entityData, this.schema);
  }

  mapSingle(entityData: T): T {
    return this.modelFactory(entityData);
  }

  mapMany(entitiesList: T[]): T[] {
    return entitiesList.map(this.modelFactory);
  }

  /** @deprecated */
  denormalizeMany(ids: Result[], entitiesList: EntitiesList<T>): T[] {
    const data = denormalize(ids, [this.schema], entitiesList);

    if (!data.length) {
      return [];
    }

    return data.map(this.denormalizedFactory);
  }

  /** @deprecated */
  denormalizeSingle(id: Result, entityData: EntitiesList<T>): T | null {
    const modelData = denormalize(id, this.schema, entityData);

    if (!modelData) {
      return null;
    }

    return this.denormalizedFactory(modelData);
  }
}
