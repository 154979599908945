import { WaitingMusic } from '../../domain/waiting-music/WaitingMusic';

type UrlLink = string;

export type WaitingMusicFactoryInput = Record<string, Record<string, UrlLink>>;

export class WaitingMusicFactory {
  static makeMany(data: WaitingMusicFactoryInput): WaitingMusic[] {
    const map: Record<string, UrlLink> = Object.values(data).reduce(
      (acc, group) => ({ ...acc, ...group }),
      {},
    );

    return Object.keys(map)
      .map(name => ({ name, url: map[name] }))
      .map(WaitingMusic.make);
  }
}
