import { VisitConfirmation } from '@/modules/task/domain/visit/VisitConformation';
import type { BookVisitModuleVisitConfirmation } from '@/modules/task/adapters/visit/confirmation/BookVisitModuleVisitConfirmation';

export class BookVisitModuleVisitConfirmationFactory {
  static tryMake(
    bookVisitModuleVisitConfirmation: BookVisitModuleVisitConfirmation | null,
  ): VisitConfirmation | null {
    if (!bookVisitModuleVisitConfirmation) {
      return null;
    }

    return new VisitConfirmation(
      bookVisitModuleVisitConfirmation.status,
      bookVisitModuleVisitConfirmation.doctorName,
      bookVisitModuleVisitConfirmation.serviceName,
      bookVisitModuleVisitConfirmation.patientDetailUrl,
      bookVisitModuleVisitConfirmation.startAt,
    );
  }
}
