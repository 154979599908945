import { inject, injectable } from 'tsyringe';
import type { TagFactory } from '@/modules/task/domain/tag/factories/TagFactory';
import type { Tag } from '@/modules/task/domain/tag/Tag';
import type { Task } from '@/modules/task/domain/task/Task';
import type { ReminderReadRepository } from '@/modules/task/domain/reminder/ReminderReadRepository';
import { ReminderReadRepositoryToken } from '@/modules/task/di/tokens';
import { MissingTaskReminderError } from '../../reminder/MissingTaskReminderError';

@injectable()
export class ReminderTagFactory implements TagFactory {
  constructor(
    @inject(ReminderReadRepositoryToken)
    private readonly reminderReadRepository: ReminderReadRepository,
  ) {}

  make(task: Task): Tag[] {
    try {
      const tag = this.reminderReadRepository.get(task.id).getTag();

      return tag ? [tag] : [];
    } catch (e) {
      if (e instanceof MissingTaskReminderError) {
        return [];
      }

      throw e;
    }
  }
}
