import type { DpProvider } from '@/core/di/DpProvider';
import { RootTranslatorToken } from '@/common/root';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import type { Translator } from '../../domain/Translator';
import { TranslatorToken } from '../tokens';

@injectable()
export class TranslatorProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<Translator>(TranslatorToken, {
      useFactory(c) {
        return c.resolve(RootTranslatorToken);
      },
    });
  }
}
