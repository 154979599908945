import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import {
  InteractionEventPublisherToken,
  EventPublisherToken,
  DurationEventTrackerToken,
  SingularEventPublisherToken,
} from '../tokens';
import { BusEventPublisher } from '../../domain/event-publisher/BusEventPublisher';
import { DpBusInteractionEventPublisher } from '../../domain/event-publisher/interaction-event-publisher/DpBusInteractionEventPublisher';
import { DpBusDurationEventTracker } from '../../domain/event-publisher/duration-event-publisher/DpBusDurationEventTracker';
import { DpBusSingularEventPublisher } from '../../domain/event-publisher/singular-event-publisher/DpBusSingularEventPublisher';

@injectable()
export class EventTrackerFacadeProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register(EventPublisherToken, BusEventPublisher);
    container.register(InteractionEventPublisherToken, DpBusInteractionEventPublisher);
    container.register(DurationEventTrackerToken, DpBusDurationEventTracker);
    container.register(SingularEventPublisherToken, DpBusSingularEventPublisher);
  }
}
