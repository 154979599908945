import { schema } from 'normalizr';
import { EntityTypes } from '@/api/types/entities';
import { mediaSchema } from '@/api/schemas/media-schema';

export const userSchema = new schema.Entity(
  EntityTypes.User,
  {
    avatar: mediaSchema,
  },
  {
    processStrategy({ email, ...rest }) {
      return {
        ...rest,
        username: email,
      };
    },
  },
);
