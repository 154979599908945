import { injectable } from 'tsyringe';
import { Store } from 'vuex';
import type { RootState } from '@/store/types';
import type { TimeZoneRepository } from '../domain/TimeZoneRepository';

@injectable()
export class StoreFacilityTimeZoneRepository implements TimeZoneRepository {
  constructor(private readonly store: Store<RootState>) {}

  get(): string {
    return this.store.getters.getFacilityTimeZone;
  }
}
