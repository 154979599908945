import { startOfToday, parseISO, format } from 'date-fns';
import { injectable } from 'tsyringe';
import type { DateFormatter } from '../domain/DateFormatter';

@injectable()
export class DateFnsDateFormatter implements DateFormatter {
  parse(dateString: string): Date {
    return parseISO(dateString);
  }

  startOfToday(): Date {
    return startOfToday();
  }

  asDayString(date: Date): string {
    return format(date, 'yyyy-MM-dd');
  }
}
