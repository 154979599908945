import { injectable } from 'tsyringe';
import type { CostPerVisit } from '../../../domain/overview-callback/revenue/CostPerVisit';
import type { InputCostPerVisit } from './InputCostPerVisit';

@injectable()
export class CostPerVisitFactory {
  make(inputRevenue: InputCostPerVisit): CostPerVisit {
    return {
      currency: inputRevenue.currency,
      value: inputRevenue.value,
    };
  }
}
