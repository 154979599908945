import { inject, injectable } from 'tsyringe';
import type { PasswordResetRequester } from '@/modules/authentication/domain/password-reset/request/PasswordResetRequester';
import { Identity } from '@/modules/authentication/domain/password-reset/request/Identity';
import { AnonymousHttpClientApi } from '@/modules/http-client/public/api';
import type { Locale, LocaleDetector } from '@/modules/authentication/domain/LocaleDetector';
import { LocaleDetectorToken } from '@/modules/authentication/di/token';

@injectable()
export class HttpPasswordResetRequester implements PasswordResetRequester {
  constructor(
    @inject(LocaleDetectorToken)
    private readonly localeDetector: LocaleDetector,
    private readonly httpClient: AnonymousHttpClientApi,
  ) {}

  async requestWithEmail(email: string): Promise<Identity> {
    const { identity } = await this.httpClient.post<{ identity: string }>(
      '/auth/reset-password-request',
      {
        email,
        locale: this.getLocale(),
        target: this.getTargetUrl(),
      },
    );

    return new Identity(identity);
  }

  async requestWithPhoneNumber(phoneNumber: string): Promise<Identity> {
    const { identity } = await this.httpClient.post<{ identity: string }>(
      '/auth/reset-password-request',
      {
        phoneNumber,
        locale: this.getLocale(),
        target: this.getTargetUrl(),
      },
    );

    return new Identity(identity);
  }

  private getLocale(): Locale {
    return this.localeDetector.getBasedOnDomain().locale;
  }

  private getTargetUrl(): string {
    return `${window.location.origin}/#/password-reset/{token}`;
  }
}
