import { VoiceMenuTag } from '@/modules/task/domain/voice-menu/VoiceMenuTag';

export class VoiceMenu {
  private static emptyValue = '';

  private constructor(
    readonly label: string,
    readonly hasPriority: boolean,
  ) {}

  getTag(): VoiceMenuTag {
    return VoiceMenuTag.make(this.label, this.hasPriority);
  }

  get isEmpty(): boolean {
    return this.label === VoiceMenu.emptyValue;
  }

  static make(label: string, hasPriority = false): VoiceMenu {
    return new VoiceMenu(label, hasPriority);
  }

  static makeEmpty(): VoiceMenu {
    return new VoiceMenu(VoiceMenu.emptyValue, false);
  }
}
