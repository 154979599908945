import { RootStoreToken } from '@/common/root';
import { HttpClientApi } from '@/modules/http-client/public/api';
import { inject, injectable } from 'tsyringe';
import { normalize } from 'normalizr';
import { Call as ApiCall } from '@/api/models/Call';
import { MUTATION_ENTITIES_UPDATE } from '@dp-vue/entities';
import type { Store } from 'vuex';
import type { CallQualityReport } from '@/modules/timeline/domain/call/quality/CallQualityReport';
import type { CallQualityReportRepository } from '@/modules/timeline/domain/call/quality/CallQualityReportRepository';
import type { InputCallQualityWithComment } from './InputCallQualityWithComment';
import { CallQualityReportFactory } from './CallQualityReportFactory';

@injectable()
export class HttpCallQualityReportRepository implements CallQualityReportRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    @inject(RootStoreToken)
    private readonly store: Store<unknown>,
    private readonly callQualityReportFactory: CallQualityReportFactory,
  ) {}

  async get(callId: string): Promise<CallQualityReport> {
    const callQuality: InputCallQualityWithComment = await this.httpClient.get(
      `/api/v0/call/${callId}/quality`,
    );

    return this.callQualityReportFactory.make(callId, callQuality);
  }

  async save(callQualityReport: CallQualityReport): Promise<void> {
    const call = await this.httpClient.put(`/api/v0/call/${callQualityReport.callId}/quality`, {
      problems: callQualityReport.problems,
      comment: callQualityReport.comment,
    });

    this.update(call);
  }

  private update(call) {
    const { entities } = normalize(call, ApiCall.schema);
    this.store.commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });
  }
}
