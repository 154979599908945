<template>
  <form-field-wrapper :class="$b()" :icon="icon" :label="label">
    <div :class="$b('input-wrapper')">
      <dp-multiselect
        :class="$b('select')"
        :disabled="disabled"
        :label="label"
        :placeholder="placeholder"
        :readonly="readonly"
        :searchable="searchable"
        :validation="validationResult"
        :model-value="value"
        :options="options"
        :hide-label="true"
        track-by="value"
        item-label="label"
        :option-text-ellipsis="true"
        :show-no-options="false"
        @update:model-value="$emit('update:modelValue', $event)"
        @open="$emit('open', $event)"
      >
        <template v-if="hasOptionSlot" #option="{ option }">
          <slot name="option" v-bind="option" />
        </template>
        <template v-if="hasLabelSlot" #singleLabel="{ option }">
          <slot name="label" v-bind="option" />
        </template>
      </dp-multiselect>
    </div>
  </form-field-wrapper>
</template>

<script lang="ts">
import type { Component as VueComponent } from 'vue';

import { Vue, Component, Prop, Model } from 'vue-facing-decorator';
import { DpMultiselect } from '@dp-ui-kit/vue';

import type { CustomFormSelectOption } from '@/ui/molecules/CustomFormSelect/CustomFormSelect.types';
import SelectListItem from '@/ui/molecules/SelectListItem/SelectListItem.vue';
import FormFieldWrapper from '@/ui/atoms/FormFieldWrapper/FormFieldWrapper.vue';

interface Validation {
  type: 'invalid' | 'valid';
  messages: string[];
}

@Component({
  name: 'CustomFormSelect',
  emits: ['open', 'update:modelValue'],
  components: {
    SelectListItem,
    FormFieldWrapper,
    DpMultiselect,
  },
})
export default class CustomFormSelect extends Vue {
  @Prop({ type: Array, required: true })
  readonly options: CustomFormSelectOption[];

  @Prop({ type: String, required: true })
  readonly name: string;

  @Prop({ type: Boolean, default: false })
  readonly disabled: boolean;

  @Prop({ type: Object, default: null })
  readonly icon: VueComponent | null;

  @Prop({ type: String, required: true })
  readonly label: string;

  @Prop({ type: String, required: false })
  readonly placeholder: string;

  @Prop({ type: Boolean, default: false })
  readonly readonly: boolean;

  @Prop({ type: Boolean, default: false })
  readonly searchable: string;

  @Prop({ type: Object, default: null })
  readonly validationResult: Validation;

  @Model({ type: Object, default: null })
  readonly value: object;

  get hasOptionSlot(): boolean {
    return !!this.$slots?.option ?? false;
  }

  get hasLabelSlot(): boolean {
    return !!this.$slots?.label ?? false;
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/mixins' as m;
@use '@/assets/scss/variables' as v;

$default-bg-color: v.$white;
$active-bg-color: v.$gray-100;

.dp-custom-form-select {
  $block: &;

  &__input-wrapper {
    position: relative;
  }
}

:deep(.dp-custom-form-select__select) {
  margin: 0;
  padding: 0;

  .multiselect__tags {
    @include m.custom-input;

    background: transparent;
    padding-right: v.$spacer-lg;

    &:not(:focus) {
      border: 0;
    }
  }

  .multiselect__single {
    color: v.$gray-600;
    background: transparent;

    @include m.truncate;
  }

  .multiselect__caret {
    visibility: hidden;
  }

  &:hover,
  .multiselect--active {
    background-color: $active-bg-color;

    .multiselect__caret {
      background-color: $active-bg-color;
      visibility: visible;
    }
  }

  .multiselect__input {
    background: transparent;
  }

  .multiselect--disabled .multiselect__caret {
    display: none;
  }
}
</style>
