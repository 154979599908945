import type { ContainerRepository } from '../repositories/ContainerRepository';
import type { Clock } from './Clock';

export class StorageItem<T> {
  constructor(
    private readonly container: ContainerRepository<T>,
    private readonly clock: Clock,
  ) {}

  get value(): T | null {
    const { item } = this.container;

    if (!item) {
      return null;
    }

    const { value, expiresAt } = item;

    if (expiresAt && expiresAt < this.clock.now()) {
      return null;
    }

    return value;
  }

  set(value: T | null, ttl?: number): T | null {
    const expiresAt = ttl ? this.clock.now() + ttl * 1000 : null;

    if (value === null) {
      this.clear();
      return null;
    }

    this.container.set({ value, expiresAt });

    return value;
  }

  clear(): void {
    this.container.clear();
  }
}
