import * as dateFns from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';
import { pl, enUS, es, it, de, ptBR } from 'date-fns/locale';
import { i18n } from '@/ui/plugins/i18n';
import { getLanguageConfig } from '@/utils/languageDetector';
import { DateFnsDateUtils } from './DateFnsDateUtils';
import { DateFormatter } from './DateFormatter';
import { InputDateParser } from './InputDateParser';
import { TranslatedOutputDateNormalizerFactory } from './TranslatedOutputDateNormalizerFactory';
import { TranslateOutputDistanceNormalizerFactory as TranslatedOutputDistanceNormalizerFactory } from './TranslatedOutputDistanceNormalizerFactory';
import type { DateUtils } from './DateUtils';

const { locale } = getLanguageConfig();
const dateUtilsLocale = { en: enUS, mx: es, br: ptBR, de, pl, es, it }[locale] ?? enUS;

export const dateUtils: DateUtils = new DateFnsDateUtils(dateFns, dateFnsTz, dateUtilsLocale);

const dateFormatter = new DateFormatter(
  new InputDateParser(dateUtils),
  new TranslatedOutputDateNormalizerFactory(i18n.global, dateUtils),
  new TranslatedOutputDistanceNormalizerFactory(dateUtils),
);

export { dateFormatter };

export type { DateFormatter };
