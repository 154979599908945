/* eslint-disable no-underscore-dangle */
import { HttpClientApi } from '@/modules/http-client/public/api';
import { injectable } from 'tsyringe';
import { formatISO } from 'date-fns';
import type { BookingSlotsWithNearestDate } from '@/modules/book-visit/domain/booking-slot/BookingSlotsWithNearestDate';
import type { BookingSlotRepository } from '@/modules/book-visit/domain/booking-slot/BookingSlotRepository';
import { HttpBookingSlotFactory } from './HttpBookingSlotFactory';
import type { InputResponse } from '../InputResponses';
import type { HttpBookingSlot } from './HttpBookingSlot';
import type { HttpNearestSlot } from './HttpNearestSlot';

@injectable()
export class HttpBookingSlotRepository implements BookingSlotRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly bookingSlotFactory: HttpBookingSlotFactory,
  ) {}

  async get(
    doctorId: string,
    addressId: string,
    serviceId: string,
    startDate: Date,
    endDate: Date,
  ): Promise<BookingSlotsWithNearestDate> {
    const startDateString = formatISO(startDate);
    const endDateString = formatISO(endDate);

    const bookingSlotsResponse: InputResponse<HttpBookingSlot, HttpNearestSlot> =
      await this.httpClient.get(
        `/api/v0/facility/{facilityId}/booking/get-slots/${doctorId}/${addressId}/${serviceId}/${startDateString}/${endDateString}`,
      );

    const slots = bookingSlotsResponse._items.map(bookingSlot =>
      this.bookingSlotFactory.make(bookingSlot, serviceId),
    );

    return {
      slots,
      nearestSlot: bookingSlotsResponse._embedded?.nearest_slot_after_date?.start ?? null,
    };
  }
}
