export class Media {
  constructor(
    readonly id: string,
    readonly url: string,
    readonly isDirty: boolean,
  ) {}

  static empty(): Media {
    return new Media('', '', false);
  }
}
