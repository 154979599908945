import { injectable } from 'tsyringe';
import type { InputFeatures, InputSubscription } from './InputSubscription';
import { Subscription } from '../../domain/subscription/Subscription';
import { PricingTier } from '../../domain/subscription/PricingTier';
import { Feature } from '../../domain/subscription/Feature';

@injectable()
export class SubscriptionFactory {
  make(inputSubscription: InputSubscription | InputFeatures): Subscription {
    if (Array.isArray(inputSubscription)) {
      return this.makeFromFeaturesOnly(inputSubscription);
    }

    return this.makeFromSubscription(inputSubscription);
  }

  private makeFromFeaturesOnly(features: InputFeatures): Subscription {
    const featureList = features.map(feature => new Feature(feature));
    return new Subscription(null, featureList);
  }

  private makeFromSubscription(subscription: InputSubscription): Subscription {
    const featureList = subscription.features.map(feature => new Feature(feature));
    const pricingTier = PricingTier.make(subscription.plan);

    return new Subscription(pricingTier, featureList);
  }
}
