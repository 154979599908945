import type { FacilityId } from '@/api/types/entities/facility';
import type { HttpClientApi } from '@/modules/http-client/public/api';

export abstract class BaseAxiosRepository {
  constructor(readonly axiosClient: HttpClientApi) {}

  protected readonly v1ApiPrefix = '/api/v1';

  protected readonly v0ApiPrefix = '/api/v0';

  protected makeV1FacilityPointer(facilityId: FacilityId): string {
    return `${this.v1ApiPrefix}/facilities/${facilityId}`;
  }
}
