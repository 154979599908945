import type { Timeline } from '@/modules/task/domain/timeline/Timeline';
import { Call } from '@/modules/task/domain/call/Call';
import type { IconStrategy } from '../types';
import type { Task } from '../../task/Task';
import { IconName } from '../types';

export class Abandoned implements IconStrategy {
  get(task: Task, { lastUserEvent }: Timeline): null | IconName {
    if (!(lastUserEvent instanceof Call)) {
      return null;
    }

    return lastUserEvent.isAbandoned ? IconName.Abandoned : null;
  }
}
