import type { Store } from 'vuex';

export class StoreAdapter {
  constructor(
    private readonly store: Store<unknown>,
    private readonly prefix: string,
  ) {}

  commit(name: string, payload?: unknown): void {
    this.store.commit(`${this.prefix}/${name}`, payload);
  }

  getter(name: string): any {
    return this.store.getters[`${this.prefix}/${name}`];
  }
}
