import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import { HttpClientApi } from '@/modules/http-client/public/api';
import { HttpClientError } from '@/modules/http-client/public/types';
import type { Store } from 'vuex';
import type { CallUpdater } from '../../domain/call/CallUpdater';
import type { HttpTask } from '../action/types/HttpTask';
import { PickUpCallError } from '../../domain/action/action/pick-up-call/PickUpCallError';

@injectable()
export class CallUpdaterAdapter implements CallUpdater {
  constructor(
    private readonly httpClient: HttpClientApi,
    @inject(RootStoreToken)
    private readonly store: Store<unknown>,
  ) {}

  async markAsEnded(callId: string): Promise<void> {
    const task = await this.httpClient.post<HttpTask>(`/api/v0/call/${callId}/mark-as-ended`);

    await this.update(task);
  }

  async pickUpCall(callId: string, workstationId: string): Promise<void> {
    try {
      const task = await this.httpClient.post<HttpTask>(`/api/v0/call/${callId}/take-over-call`, {
        workstationId,
      });

      await this.update(task);
    } catch (error) {
      if (error instanceof HttpClientError && error.statusCode === 400) {
        throw new PickUpCallError('Call has been already taken over or answered');
      }
    }
  }

  private async update(task: HttpTask): Promise<void> {
    await this.store.dispatch('UPDATE_TASK_ACTION', [task]);
  }
}
