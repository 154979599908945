import type { GetterTree, MutationTree, ActionTree } from 'vuex';
import type { TimeZone } from '@/app/create-facility/time-zone';
import { getLanguageBasedOnDomain } from '@/utils/languageDetector';
import { facilityRepository } from '@/api/repositories';

interface State {
  formData: {
    name: string;
    timeZone: TimeZone | '';
    prefix: string;
    waitingMusic: string;
  };
}

const initState: State = {
  formData: {
    name: '',
    timeZone: '',
    prefix: '',
    waitingMusic: '',
  },
};

const state: State = {
  ...initState,
};

const getters: GetterTree<State, unknown> = {
  getFormData: state => state.formData,
};

const mutations: MutationTree<State> = {
  SET_FORM_DATA(state, newFormData: State['formData']) {
    state.formData = { ...newFormData };
  },
};

const actions: ActionTree<State, unknown> = {
  async CREATE_FACILITY_ACTION({ getters }) {
    const payload = {
      name: getters.getFormData.name,
      timezone: getters.getFormData.timeZone,
      defaultPrefix: getters.getFormData.prefix,
      waitingMusic: getters.getFormData.waitingMusic,
      locale: getLanguageBasedOnDomain().facilityLocale,
    };

    return facilityRepository.create(payload);
  },
};

export const createFacility = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
