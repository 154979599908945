import { injectable } from 'tsyringe';
import type { InputWorkstation } from './types/InputWorkstation';
import { Workstation } from '../../domain/workstation/Workstation';
import { PhoneNumber } from '../../domain/workstation/PhoneNumber';
import { SipCredential } from '../../domain/workstation/SipCredential';

@injectable()
export class WorkstationFactory {
  makeFrom(inputWorkstation: InputWorkstation): Workstation {
    if (inputWorkstation.type === 'pstn') {
      const phoneNumber: PhoneNumber | null = this.getPhoneNumber(inputWorkstation);
      return Workstation.make(
        inputWorkstation.id,
        inputWorkstation.name,
        inputWorkstation.mode,
        inputWorkstation.type,
        inputWorkstation.internal_number,
        inputWorkstation.weight,
        phoneNumber,
      );
    }

    if (inputWorkstation.type === 'sip') {
      const sipCredential: SipCredential = this.getSipCredential(inputWorkstation);
      return Workstation.make(
        inputWorkstation.id,
        inputWorkstation.name,
        inputWorkstation.mode,
        inputWorkstation.type,
        inputWorkstation.internal_number,
        inputWorkstation.weight,
        null,
        sipCredential,
      );
    }

    return Workstation.make(
      inputWorkstation.id,
      inputWorkstation.name,
      inputWorkstation.mode,
      inputWorkstation.type,
      inputWorkstation.internal_number,
      inputWorkstation.weight,
    );
  }

  makeMany(inputWorkstations: InputWorkstation[]): Workstation[] {
    return inputWorkstations.map(inputWorkstation => this.makeFrom(inputWorkstation));
  }

  private getPhoneNumber(inputWorkstation: InputWorkstation): PhoneNumber {
    if (inputWorkstation.type !== 'pstn') {
      throw new Error('Cannot get phone number from non-pstn workstation');
    }

    return new PhoneNumber(inputWorkstation.number, inputWorkstation.formatted_number);
  }

  private getSipCredential(inputWorkstation: InputWorkstation): SipCredential {
    if (inputWorkstation.type !== 'sip') {
      throw new Error('Cannot get sip credential from non-sip workstation');
    }

    return new SipCredential(
      inputWorkstation.sip.username,
      inputWorkstation.sip.password,
      inputWorkstation.sip.url,
    );
  }
}
