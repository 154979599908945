import { RootLanguageConfigToken } from '@/common/root';
import { Locale } from '@/common/root/domain/LanguageConfigProvider';
import type { LanguageConfigProvider } from '@/common/root/domain/LanguageConfigProvider';
import { inject, injectable } from 'tsyringe';

@injectable()
export class StaticLocalizedTermsAndConditionsFactory {
  private readonly fileMap: Record<Locale, string> = {
    [Locale.en]: 'TC_ENG.pdf',
    [Locale.pl]: 'PL_Regulamin_AI.pdf',
    [Locale.es]: 'ES_TC_AI.pdf',
    [Locale.mx]: 'TC_MX.pdf',
    [Locale.it]: 'TC_IT.pdf',
    [Locale.br]: 'TC_BR.pdf',
  };

  constructor(
    @inject(RootLanguageConfigToken)
    private readonly languageConfig: LanguageConfigProvider,
  ) {}

  makeAiLink(): string {
    return `${this.baseurl}${this.getFile()}`;
  }

  private getFile(): string {
    const { locale } = this.languageConfig.get();
    return this.fileMap[locale];
  }

  private get baseurl(): string {
    return `//${window.location.host}${window.location.pathname}terms/`;
  }
}
