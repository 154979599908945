<template>
  <div :class="$b('options')">
    <dp-radio
      v-model="radioValue"
      value="text"
      inline
      :label="$t('settings.voice_messages.add_text_message')"
    />

    <dp-radio
      v-model="radioValue"
      value="file"
      inline
      :label="$t('settings.voice_messages.add_media_files')"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Model } from 'vue-facing-decorator';
import { DpRadio } from '@dp-ui-kit/vue';

@Component({
  name: 'VoiceMessageTypeSelector',
  components: {
    DpRadio,
  },
})
export default class VoiceMessageTypeSelector extends Vue {
  @Model({
    type: String,
    required: true,
    validator: (value: string) => ['text', 'file'].includes(value),
  })
  radioValue: 'text' | 'file';
}
</script>
