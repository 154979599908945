export class User {
  private constructor(
    readonly id: string,
    readonly name: string,
    readonly avatarUrl: string,
    readonly email: string,
    readonly phoneNumber: string,
  ) {}

  static ofEmail(id: string, name: string, avatarUrl: string, email: string): User {
    return new User(id, name, avatarUrl, email, '');
  }

  static ofPhone(id: string, name: string, avatarUrl: string, phoneNumber: string): User {
    return new User(id, name, avatarUrl, '', phoneNumber);
  }
}
