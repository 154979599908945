import type { App, Plugin } from 'vue';
import { CustomPropertiesReader } from './CustomPropertiesReader';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    /**
     *
     * @param {string} name Custom property name
     * @return {string} value of custom property
     */
    $customProperty(name: string): string;
  }
}

function install(app: App): void {
  const customPropertiesReader = new CustomPropertiesReader();

  app.config.globalProperties.$customProperty = (name: string) => customPropertiesReader.get(name);
}

const plugin: Plugin = { install };

export { plugin as CustomPropertiesPlugin };
