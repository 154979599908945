import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { Subscription } from '../../domain/subscription/Subscription';
import type { SubscriptionRepository } from '../../domain/subscription/SubscriptionRepository';
import type { InputFeatures, InputSubscription } from './InputSubscription';
import { SubscriptionFactory } from './SubscriptionFactory';

@injectable()
export class HttpSubscriptionRepository implements SubscriptionRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly subscriptionFactory: SubscriptionFactory,
  ) {}

  async get(): Promise<Subscription> {
    const response = await this.httpClient.get<InputSubscription | InputFeatures>(
      '/api/v0/facility/{facilityId}/features',
    );

    return this.subscriptionFactory.make(response);
  }
}
