import { startOfDay, parseISO } from 'date-fns';
import { toZonedTime, fromZonedTime } from 'date-fns-tz';
import { IsoDate } from '@/modules/timeline/domain/IsoDate';
import type { EventsListItem } from './EventsListItem';
import type { EventsListGrouperItem } from './EventsListGrouperItem';

export class EventsListGrouper {
  static groupByDate(items: EventsListItem[]): EventsListGrouperItem[] {
    const groupedItems: EventsListGrouperItem[] = [];
    const itemsByDate = new Map<string, EventsListItem[]>();

    items.forEach(event => {
      const localTime = toZonedTime(parseISO(event.createdAt.isoDate), event.createdAt.timezone);
      const dayStart = startOfDay(localTime);
      const utcTime = fromZonedTime(dayStart, event.createdAt.timezone);
      const dateString = utcTime.toISOString();

      if (!itemsByDate.has(dateString)) {
        itemsByDate.set(dateString, []);
      }

      itemsByDate.get(dateString)?.push(event);
    });

    itemsByDate.forEach((events, dayStart) => {
      groupedItems.push({ label: this.makeLabel(dayStart, events), events });
    });

    return groupedItems;
  }

  private static makeLabel(dayStart: string, events: EventsListItem[]): IsoDate {
    return IsoDate.make(dayStart, events[0].createdAt.timezone);
  }
}
