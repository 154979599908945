import { resolve } from '@/core/di';
import { HttpClientApi } from '@/modules/http-client/public/api';
import { promiseOnce } from '@/utils/functions';

const getV1Facility = id => ({ facility: `/api/v1/facilities/${id}` });

const api = {
  user: () => resolve(HttpClientApi),
};

const searchFacilities = phrase => api.user().get('/api/v0/search-facilities', { phrase });

/**
 * `promiseOnce` will make sure the call is only made once per `api.token` value
 * This way, later, the `GET_USER_ACTION` may be invoked multiple times w/o wasting resources
 */

const isAuthenticated = () => {
  const tokens = localStorage.getItem('tokens');

  if (!tokens) {
    return false;
  }

  return !!JSON.parse(tokens).api;
};

const getMe = promiseOnce(
  () => api.user.get('/api/v0/me'),
  () => isAuthenticated(),
);

const withFacility = id => {
  const withSetting = settingName => ({
    reorder: items => api.user().patch(`/api/v0/facility/${id}/settings/${settingName}`, items),
    add: item => api.user().post(`/api/v0/facility/${id}/settings/${settingName}`, item),
    edit: (itemId, newItem) =>
      api.user().put(`/api/v0/facility/${id}/settings/${settingName}/${itemId}`, newItem),
    delete: itemId => api.user().delete(`/api/v0/facility/${id}/settings/${settingName}/${itemId}`),
  });

  /** @deprecated */
  const withPhones = {
    ...withSetting('phones'),
    /** @deprecated */
    removeForwarding: phoneId =>
      api.user().post(`/api/v0/facility/${id}/settings/phones/${phoneId}/remove-forwarding`),
  };

  const search = {
    suggest: phrase => api.user().post(`/api/v0/facility/${id}/search/suggestions`, { phrase }),
  };

  const regulatory = {
    requirements: endUserType =>
      api.user().get(`/api/v0/facility/${id}/regulatory/requirements/${endUserType}`),
  };

  return {
    setWorkingHours: workingHours =>
      api.user().post(`/api/v0/facility/${id}/settings/working-hours`, workingHours),
    getVoiceMessages: () => api.user().get(`/api/v0/facility/${id}/settings/messages`),
    setVoiceMessages: messages =>
      api.user().put(`/api/v0/facility/${id}/settings/messages`, { messages }),
    patientUploader: url => api.user().post(`/api/v0/facility/${id}/patient-uploader`, { url }),
    runPatientUploader: config =>
      api.user().put(`/api/v0/facility/${id}/patient-uploader/${config.media}`, config.map),
    getComplianceBundles: page => api.user().get(`/api/v1/facilities/${id}/bundles`, { page }),
    addComplianceBundle: bundle =>
      api.user().post(`/api/v1/compliance-bundles`, { ...getV1Facility(id), ...bundle }),
    getWorkstationErrors: () => api.user().get(`/api/v0/facility/${id}/provider-errors`),
    withResults: withSetting('results'),
    withReceptionPhones: withSetting('reception/phones'),
    withPermissions: withSetting('permissions'),
    /** @deprecated */
    withPhones,
    withForms: withSetting('forms'),
    search,
    regulatory,
  };
};

const withCall = id => ({
  inviteToConference: workstationId =>
    api.user().post(`/api/v0/call/${id}/invite/${workstationId}`),
  removeFromConference: participantType =>
    api.user().post(`/api/v0/call/${id}/remove/${participantType}`),
  markAsEnded: () => api.user().post(`/api/v0/call/${id}/mark-as-ended`),
  holdPatient: () => api.user().post(`/api/v0/call/${id}/hold-patient`),
  unholdPatient: () => api.user().post(`/api/v0/call/${id}/unhold-patient`),
});

const withProfile = {
  updateProfile: data => api.user().put('/api/v0/profile/settings', data),
  updatePassword: data => api.user().put('/api/v0/profile/password', data),
};

const getQrCode = id => api.user().post(`/api/v0/mobile-app/qr-code/${id}`);
const getInitialMobileAppData = workstationId =>
  api.user().post(`/api/v0/mobile-app/${workstationId}.json`);

const withPhone = phoneId => ({
  verifyPhone: numberToVerify =>
    api.user().post(`/api/v0/phone/${phoneId}/verification`, { number: numberToVerify }),
});

const createFacility = ({ name, locale, timezone, defaultPrefix, waitingMusic }) =>
  api.user().post(`/api/v1/facilities`, {
    name,
    locale,
    timezone,
    defaultPrefix,
    waitingMusic,
  });

export const phoneApi = {
  client: api,
  getMe,
  isAuthenticated,
  withProfile,
  withFacility,
  withCall,
  withPhone,
  searchFacilities,
  getQrCode,
  getInitialMobileAppData,
  createFacility,
};
