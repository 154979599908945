import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import type { ConnectionIdentifierLabelFetcher } from '../domain/services/ConnectionIdentifierLabelFetcher';

interface Store {
  getters: {
    'phoneWidget/connectionIdentifierLabel': string;
  };
}

@injectable()
export class StoreConnectionIdentifierLabelFetcher implements ConnectionIdentifierLabelFetcher {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
  ) {}

  get(): string {
    return this.store.getters['phoneWidget/connectionIdentifierLabel'];
  }
}
