import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { LinePositionInput } from '../../domain/line-position/LinePosition';
import type { LinePositionRepository } from '../../domain/line-position/LinePositionRepository';
import { LinePosition } from '../../domain/line-position/LinePosition';

@injectable()
export class HttpLinePositionRepository implements LinePositionRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async get(): Promise<LinePosition> {
    const status: LinePositionInput = await this.httpClient.get(
      `/api/v0/facility/{facilityId}/line-position-announcement`,
    );

    return LinePosition.make(status);
  }

  async setAnnouncementStatus(newStatus: boolean): Promise<LinePosition> {
    const status: LinePositionInput = await this.httpClient.post(
      `/api/v0/facility/{facilityId}/line-position-announcement`,
      {
        enabled: newStatus,
      },
    );

    return LinePosition.make(status);
  }
}
