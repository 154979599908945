import { inject, injectable } from 'tsyringe';
import { withEntries } from '@/utils/functions';
import { ConfigurationRepositoryToken } from '../../di/tokens';
import type { ConfigurationRepository } from '../repositories/ConfigurationRepository';
import { DefaultConfigurationFactory } from '../repositories/DefaultConfigurationFactory';
import type { AppConfig } from '../models/AppConfig';
import type { Domain } from '../models/Domain';
import type { StoredAppConfig } from '../models/StoredAppConfig';

type NullableAsOptional<T> = {
  [P in keyof T]?: NonNullable<T[P]>;
};
@injectable()
export class ConfigurationProvider {
  constructor(
    private readonly defaultConfigurationFactory: DefaultConfigurationFactory,
    @inject(ConfigurationRepositoryToken)
    private readonly configurationRepository: ConfigurationRepository,
  ) {}

  get(): AppConfig {
    const storedData = this.getStoredData();
    const defaultConfiguration = this.defaultConfigurationFactory.get();

    return {
      ...defaultConfiguration,
      ...storedData,
    };
  }

  getDefaultForDomain(domain: Domain): AppConfig {
    return this.defaultConfigurationFactory.makeForDomain(domain);
  }

  private getStoredData(): NullableAsOptional<StoredAppConfig> {
    const filterNullValues = entries => entries.filter(([, value]) => value);

    return withEntries(filterNullValues)(this.configurationRepository.get());
  }
}
