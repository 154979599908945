<template>
  <div :class="[$b(), $attrs.class]">
    <dp-textarea
      ref="textarea"
      v-model="text"
      :class="$b('input')"
      :rows="dynamicRows"
      v-bind="attrs"
      @keypress.enter="handleEnter"
    />
    <div :class="$b('actions')">
      <slot />
    </div>
    <dp-textarea
      ref="invisibleTextarea"
      :class="$b('input', { hidden: true })"
      :model-value="text"
      :rows="rows"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch, Ref, Model } from 'vue-facing-decorator';
import { DpTextarea } from '@dp-ui-kit/vue';

@Component({
  name: 'ResizableTextArea',
  inheritAttrs: false,
  emits: ['update:modelValue', 'submit'],
  components: {
    DpTextarea,
  },
})
export default class ResizableTextArea extends Vue {
  static lineHeightInPixels = 22;

  @Model({ type: String, required: true })
  text: string;

  @Prop({ type: Number, default: 1 })
  readonly rows: number;

  @Prop({ type: Number, default: 3 })
  readonly maxRows: number;

  @Ref('textarea')
  readonly textarea: DpTextarea;

  @Ref('invisibleTextarea')
  readonly invisibleTextarea: DpTextarea;

  dynamicRows = 1;

  get attrs() {
    const attrs = { ...this.$attrs };

    delete attrs.class;

    return attrs;
  }

  created() {
    this.dynamicRows = this.rows;
  }

  mounted() {
    this.invisibleTextarea.$el.style.width = `${this.textarea.$el.clientWidth}px`;

    this.$nextTick(() => {
      this.dynamicRows = this.calculateRowsNumber();
      this.$forceUpdate();
    });
  }

  @Watch('text')
  updateRowsNumber() {
    this.$nextTick(() => {
      this.dynamicRows = this.calculateRowsNumber();
    });
  }

  handleEnter({ shiftKey }: KeyboardEvent): void {
    if (!shiftKey) {
      this.$emit('submit');
    }
  }

  focus(): void {
    this.textarea.$el.getElementsByTagName('textarea')[0]?.focus();
  }

  private calculateRowsNumber(): number {
    const scrollHeight = this.invisibleTextarea.$el.firstElementChild.scrollHeight || 0;
    const rowsNumber = Math.floor(scrollHeight / ResizableTextArea.lineHeightInPixels);
    return Math.min(rowsNumber, this.maxRows);
  }

  public insertTextInSelection(text: string): void {
    const textarea: HTMLTextAreaElement = this.textarea.$el.getElementsByTagName('textarea')[0];
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const value = this.text;
    this.text = value.substring(0, start) + text + value.substring(end);
    this.$nextTick(() => {
      textarea.focus();
      textarea.setSelectionRange(start + text.length, start + text.length);
    });
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;
@use '@/assets/scss/functions' as f;

.dp-resizable-text-area {
  position: relative;

  &__actions {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    margin-right: v.$spacer-sm;
  }

  &__input {
    margin-bottom: v.$form-group-margin-bottom; // space for error messages and hints
    font-family: v.$font-family-base-and-emoji;

    &--hidden {
      position: absolute;
      visibility: hidden;
    }

    :deep(textarea) {
      margin-bottom: 0;
      padding: v.$spacer-sm;
      padding-right: f.px-to-rem(100px);
      resize: none;

      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }
}
</style>
