import type { UpcomingVisitOutput as RawUpcomingVisitOutput } from '@/api/types/entities/upcoming-visit';
import type { DateString, PhoneNumber } from '@/api/types';
import { addMinutes, isPast, parseISO } from 'date-fns';
import { upcomingVisitOutputSchema } from '@/api/schemas/upcoming-visit-output-schema';

enum UpcomingVisitOutputStatus {
  Upcoming = 'upcoming',
  Confirmed = 'confirmed',
  InProgress = 'in-progress',
  Completed = 'completed',
  NoShow = 'no-show',
}

export class UpcomingVisitOutput {
  static schema = upcomingVisitOutputSchema;

  static status = UpcomingVisitOutputStatus;

  id: PhoneNumber;

  doctor_name: string;

  service_type: string;

  status: UpcomingVisitOutputStatus | null;

  start: DateString;

  duration: number;

  private constructor(data: RawUpcomingVisitOutput) {
    this.id = data.id;
    this.doctor_name = data.doctor_name;
    this.service_type = data.service_type;
    this.status = UpcomingVisitOutput.mapStatus(data.status);
    this.start = data.start;
    this.duration = data.duration;
  }

  get doctorName(): string {
    return this.doctor_name;
  }

  get serviceType(): string {
    return this.service_type;
  }

  get startDate(): DateString {
    return this.start ?? null;
  }

  get endDate(): Date | null {
    if (!this.startDate) {
      return null;
    }

    return addMinutes(parseISO(this.startDate), this.duration);
  }

  get isExpired(): boolean {
    if (!this.endDate) {
      return false;
    }

    return isPast(this.endDate);
  }

  static mapStatus(status: string): UpcomingVisitOutputStatus | null {
    return (
      {
        [UpcomingVisitOutputStatus.Completed]: UpcomingVisitOutputStatus.Completed,
        [UpcomingVisitOutputStatus.Confirmed]: UpcomingVisitOutputStatus.Confirmed,
        [UpcomingVisitOutputStatus.Upcoming]: UpcomingVisitOutputStatus.Upcoming,
        [UpcomingVisitOutputStatus.InProgress]: UpcomingVisitOutputStatus.InProgress,
        [UpcomingVisitOutputStatus.NoShow]: UpcomingVisitOutputStatus.NoShow,
      }[status] ?? null
    );
  }

  static make(visit_output: RawUpcomingVisitOutput): UpcomingVisitOutput {
    return new UpcomingVisitOutput(visit_output);
  }

  static tryMake(visit_output?: RawUpcomingVisitOutput): UpcomingVisitOutput | null {
    return visit_output ? UpcomingVisitOutput.make(visit_output) : null;
  }
}
