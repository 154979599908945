import { injectable, inject } from 'tsyringe';
import { PatientRepositoryToken } from '../../di/tokens';
import { Patient, PatientType } from '../../domain';
import type { PatientRepository } from '../../domain';
import type {
  NewPatient,
  Criteria,
  UpdatedPatient,
  OutputPatient,
  OutputPatientCollection,
} from '../types';
import { OutputPatientFactory } from '../types';

@injectable()
export class PublicPatientApi {
  constructor(
    @inject(PatientRepositoryToken)
    private readonly patientRepository: PatientRepository,
    private readonly outputPatientFactory: OutputPatientFactory,
  ) {}

  get(id: string): OutputPatient {
    const patient = this.patientRepository.get(id);

    return this.outputPatientFactory.make(patient);
  }

  getByPhoneNumber(phoneNumber: string): OutputPatient {
    const patient = this.patientRepository.getByPhoneNumber(phoneNumber);

    return this.outputPatientFactory.make(patient);
  }

  makeFromPhoneNumber(phoneNumber: string): OutputPatient {
    const patient = Patient.makeFromPhoneNumber(phoneNumber);

    return this.outputPatientFactory.make(patient);
  }

  async update(patientId: string, updatedPatient: UpdatedPatient): Promise<OutputPatient> {
    const patient = await this.patientRepository.update(patientId, updatedPatient);

    return this.outputPatientFactory.make(patient);
  }

  async create(newPatient: NewPatient): Promise<void> {
    const { types, ...rest } = newPatient;

    const mappedTypes: PatientType[] = types
      .map(this.mapType)
      .filter((type): type is PatientType => !!type);

    await this.patientRepository.create({ ...rest, types: mappedTypes });
  }

  async getList(query: string, criteria?: Criteria): Promise<OutputPatientCollection> {
    const { patients, totalNumberOfPatients } = await this.patientRepository.getPatientsByQuery(
      query,
      criteria,
    );

    return {
      totalNumberOfPatients,
      patients: patients.map(this.outputPatientFactory.make),
    };
  }

  private mapType(type: string): PatientType | null {
    return (
      {
        employee: PatientType.Employee,
        blocked: PatientType.Blocked,
      }[type] ?? null
    );
  }
}
