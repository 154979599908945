import { injectable } from 'tsyringe';
import { DpEventBus } from '@/core/events/DpEventBus';
import type { DpProvider } from '@/core/di/DpProvider';
import { NavbarVisibilityChange } from '@/modules/dashboard/public/events';
import { SingularEventListener } from '../../domain/event-subscriber/gtm/SingularEventListener';
import { DurationEventListener } from '../../domain/event-subscriber/gtm/DurationEventListener';
import { InteractionEventListener } from '../../domain/event-subscriber/gtm/InteractionEventListener';
import { NavbarVisibilityChangeListener } from '../../domain/event-subscriber/gtm/NavbarVisibilityChangeListener';
import { DpEventName } from '../../domain/DpEventName';

@injectable()
export class DpEventBusSubscriberProvider implements DpProvider {
  constructor(
    private readonly eventBus: DpEventBus,
    private readonly interactionEventListener: InteractionEventListener,
    private readonly durationEventListener: DurationEventListener,
    private readonly singularEventListener: SingularEventListener,
    private readonly navbarEventListener: NavbarVisibilityChangeListener,
  ) {}

  boot(): void {
    this.eventBus.subscribe(DpEventName.Interaction, this.interactionEventListener);
    this.eventBus.subscribe(DpEventName.Duration, this.durationEventListener);
    this.eventBus.subscribe(DpEventName.Singular, this.singularEventListener);
    this.eventBus.subscribe(NavbarVisibilityChange.eventName, this.navbarEventListener);
  }
}
