import { injectable } from 'tsyringe';
import { Device } from '@twilio/voice-sdk';
import type { CallingDeviceFactory } from '../../../domain/webphone/device/CallingDeviceFactory';
import { TwilioCallingDevice } from './TwilioCallingDevice';
import { TwilioCallingDeviceOptions } from './TwilioCallingDeviceOptions';

@injectable()
export class TwilioCallingDeviceFactory implements CallingDeviceFactory {
  constructor(private readonly twilioCallingDeviceOptions: TwilioCallingDeviceOptions) {}

  make(initialToken: string): TwilioCallingDevice {
    return new TwilioCallingDevice(new Device(initialToken, this.twilioCallingDeviceOptions));
  }
}
