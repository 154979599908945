import { singleton } from 'tsyringe';
import { EventSender } from './EventSender';
import { LastMinuteFrequencyFactory } from './frequency/LastMinuteFrequencyFactory';

@singleton()
export class ThrottledEventSender {
  // no more than once per minute
  private readonly delay = 60 * 1000;

  private lastSendAt: number | null = null;

  private scheduledCallback: number | null = null;

  constructor(
    private readonly eventSender: EventSender,
    private readonly lastMinuteFrequencyFactory: LastMinuteFrequencyFactory,
  ) {}

  private getRemainingDelay(): number {
    if (!this.lastSendAt) {
      return this.delay;
    }

    return Math.max(this.lastSendAt + this.delay - Date.now(), 0);
  }

  private get hasScheduledCallback(): boolean {
    return this.scheduledCallback !== null;
  }

  send(): void {
    if (this.hasScheduledCallback) {
      return;
    }

    this.scheduledCallback = window.setTimeout(() => {
      const frequency = this.lastMinuteFrequencyFactory.get();
      this.eventSender.send(frequency);
      this.lastSendAt = Date.now();
      this.scheduledCallback = null;
    }, this.getRemainingDelay());
  }
}
