import { RootTranslatorToken } from '@/common/root';
import type { Translator } from '@/common/root/domain/Translator';
import { inject, injectable } from 'tsyringe';
import type { DesktopNotificationDescriptionFactory } from '../../domain/desktop-notification/DesktopNotificationDescriptionFactory';

@injectable()
export class TranslatedDesktopNotificationDescriptionFactory
  implements DesktopNotificationDescriptionFactory
{
  constructor(
    @inject(RootTranslatorToken)
    private readonly translator: Translator,
  ) {}

  makeForIncomingCall(): string {
    return this.translator.translate('notifications.incoming_call.body');
  }

  makeForTest(): string {
    return this.translator.translate('notifications.test.body');
  }
}
