import type { DpListener } from '@/core/events/DpListener';
import { injectable } from 'tsyringe';
import { EventConsoleLogger } from './EventConsoleLogger';
import type { SingularEvent } from '../models/SingularEvent';

@injectable()
export class SingularEventLogger implements DpListener {
  constructor(private readonly eventConsoleLogger: EventConsoleLogger) {}

  handle(event: SingularEvent): void {
    this.eventConsoleLogger.display(this.toDisplay(event));
  }

  private toDisplay(event: SingularEvent) {
    return `SINGULAR EVENT: "${event.externalName}"`;
  }
}
