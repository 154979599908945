import {
  startOfToday,
  startOfWeek,
  startOfMonth,
  endOfWeek,
  endOfMonth,
  subWeeks,
  subMonths,
  subDays,
  format,
} from 'date-fns';

const today = startOfToday();
const dayWeekAgo = subWeeks(today, 1);
const dayMonthAgo = subMonths(today, 1);
const weekParams = {
  weekStartsOn: 1,
} as const;

export const last7days: [Date, Date] = [subDays(today, 6), today];
export const last30days: [Date, Date] = [subDays(today, 29), today];
export const currentWeek: [Date, Date] = [startOfWeek(today, weekParams), today];
export const currentMonth: [Date, Date] = [startOfMonth(today), today];
export const previousWeek: [Date, Date] = [
  startOfWeek(dayWeekAgo, weekParams),
  endOfWeek(dayWeekAgo, weekParams),
];
export const previousMonth: [Date, Date] = [startOfMonth(dayMonthAgo), endOfMonth(dayMonthAgo)];

export const defaultTimePeriod = last30days.map(date => format(date, 'yyyy-MM-dd')) as [
  string,
  string,
];
