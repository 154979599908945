import { toNamedParams } from '@/utils/vuex';
import { facilityCallRepository } from '@/api/repositories';
import { ConnectionIdentifierLabelFactory } from '@/modules/phone-widget/connection/ConnectionIdentifierLabelFactory';
import { StoreGetterWorkstationRepository } from '@/modules/phone-widget/connection/StoreGetterWorkstationRepository';
import { StoreGetterTaskRepository } from '@/modules/phone-widget/connection/StoreGetterTaskRepository';
import { patientRepository } from '@/modules/phone-widget/public/api';
import { TaskFactory } from '@/modules/phone-widget/connection/TaskFactory';
import { WorkstationFactory } from '@/modules/phone-widget/connection/WorkstationFactory';
import { registerInternalPhoneModule } from './phoneWidget/internalPhonePlugin';
import { registerConferenceModule } from './phoneWidget/conferencePlugin';

const sharedPhoneWidgetModule = () => ({
  namespaced: true,

  state: {
    connectionInstance: null,
    connectionPhoneNumber: null,
    connectionWarnings: {},
    startConnectionTime: null,
    microphonePermission: null,
    isMuted: false,
    keyboardValue: '',
  },

  getters: {
    connectionInstance: ({ connectionInstance }) => connectionInstance,
    connectionDuration: toNamedParams(
      ({ state, rootGetters }) =>
        state.startConnectionTime && rootGetters.getCurrentTimestamp - state.startConnectionTime,
    ),
    connectionPhoneNumber: ({ connectionPhoneNumber }) => connectionPhoneNumber,
    connectionWarnings: ({ connectionWarnings }) => connectionWarnings,
    hasMicrophonePermission: ({ microphonePermission }) => microphonePermission,
    connectionIdentifierLabel: ({ connectionPhoneNumber }, getters, rootState, rootGetters) => {
      if (!connectionPhoneNumber) {
        return '';
      }

      const factory = new ConnectionIdentifierLabelFactory(
        new StoreGetterWorkstationRepository(
          new WorkstationFactory(),
          rootGetters['settings/reception/getReceptionByInternalNumber'],
          rootGetters['settings/reception/getReceptionPhoneById'],
        ),
        new StoreGetterTaskRepository(
          new TaskFactory(),
          rootGetters.getTaskById,
          () => rootGetters.getTaskWithOngoingCallForCurrentUser,
        ),
        patientRepository(),
      );

      return factory.get(connectionPhoneNumber);
    },
    isMuted: ({ isMuted }) => isMuted,
    keyboardValue: ({ keyboardValue }) => keyboardValue,
  },

  mutations: {
    SET_CONNECTION_INSTANCE(state, value) {
      state.connectionInstance = value;
    },

    SET_START_CONNECTION_TIME(state, value) {
      state.startConnectionTime = value;
    },

    SET_CONNECTION_PHONE_NUMBER(state, value) {
      state.connectionPhoneNumber = value;
    },

    SET_CONNECTION_WARNING(state, warningName) {
      state.connectionWarnings[warningName] = true;
    },

    CLEAR_CONNECTION_WARNING(state, warningName) {
      delete state.connectionWarnings[warningName];
    },

    CLEAR_CONNECTION_WARNINGS(state) {
      state.connectionWarnings = {};
    },

    SET_MUTE(state, value) {
      state.isMuted = value;
    },

    SET_KEYBOARD_VALUE(state, newValue) {
      state.keyboardValue = newValue;
    },
  },

  actions: {
    SET_CONNECTION_INSTANCE_ACTION({ commit }, newValue) {
      commit('SET_CONNECTION_INSTANCE', newValue);
    },

    SET_CONNECTION_PHONE_NUMBER_ACTION({ commit }, newValue) {
      commit('SET_CONNECTION_PHONE_NUMBER', newValue);
    },

    SET_KEYBOARD_VALUE_ACTION({ commit }, newValue) {
      commit('SET_KEYBOARD_VALUE', newValue);
    },

    DESTROY_ACTION({ commit }) {
      commit('SET_CONNECTION_INSTANCE', null);
      commit('SET_CONNECTION_PHONE_NUMBER', null);
    },

    async MAKE_INTERNAL_CALL_ACTION({ rootGetters }, workstationId) {
      await facilityCallRepository.makeInternalCall(rootGetters.getUserFacilityID, workstationId);
    },

    async MAKE_OUTGOING_CALL_ACTION({ rootGetters }, To) {
      await facilityCallRepository.makeOutgoingCall(rootGetters.getUserFacilityID, To);
    },
  },
});

function ongoingCall(_, getters) {
  return getters['phoneWidget/connectionInstance']?.isOpen;
}

function ongoingCallTimer(store) {
  return hasOngoingCall => {
    if (hasOngoingCall) {
      store.dispatch('START_TIMER', 'phoneWidget/connection');
      store.commit('phoneWidget/SET_START_CONNECTION_TIME', store.getters.getCurrentTimestamp);
    } else {
      store.commit('phoneWidget/SET_START_CONNECTION_TIME', null);
      store.dispatch('STOP_TIMER', 'phoneWidget/connection');
    }
  };
}

function registerSharedPhoneModule(store) {
  store.registerModule('phoneWidget', sharedPhoneWidgetModule());
  store.watch(ongoingCall, ongoingCallTimer(store));
}

export function phoneWidgetPlugin(store) {
  registerSharedPhoneModule(store);
  registerInternalPhoneModule(store);
  registerConferenceModule(store);
}
