import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import type { ChatController } from '@/modules/statistics/domain/ChatController';
import { HelpCenterApi } from '@/modules/help-widget/public/api';
import { ChatControllerToken } from '@/modules/statistics/di/tokens';

export class ChatProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<ChatController>(ChatControllerToken, HelpCenterApi);
  }
}
