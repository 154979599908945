import { ComponentStatus, NOT_OPERATIONAL_STATUSES } from '@/app/app-status/component/Component';
import type { GetterTree, MutationTree } from 'vuex';
import type { RootState } from '@/store/types';

interface State {
  appStatus: ComponentStatus;
}

const state: State = {
  appStatus: ComponentStatus.Operational,
};

const mutations: MutationTree<State> = {
  SET_APP_STATUS(state, status: ComponentStatus): void {
    state.appStatus = status;
  },
};

const getters: GetterTree<State, RootState> = {
  isAppNotOperational: state => NOT_OPERATIONAL_STATUSES.includes(state.appStatus),
};

export const appStatus = {
  state,
  mutations,
  getters,
};
