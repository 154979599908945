import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { ReminderReadRepository } from '@/modules/task/domain/reminder/ReminderReadRepository';
import { ReminderFactory } from '@/modules/task/domain/reminder/ReminderFactory';
import type { Reminder } from '@/modules/task/domain/reminder/Reminder';
import { MissingTaskReminderError } from '../../domain/reminder/MissingTaskReminderError';

interface Store {
  getters: {
    getTaskById(id: string): { id: string; reminderAt: string } | null;
  };
}

@injectable()
export class StoreReminderReadRepository implements ReminderReadRepository {
  constructor(
    @inject(RootStoreToken) private readonly store: Store,
    private readonly reminderFactory: ReminderFactory,
  ) {}

  get(taskId: string): Reminder {
    const task = this.store.getters.getTaskById(taskId);

    if (!task) {
      throw new MissingTaskReminderError(taskId);
    }

    return this.reminderFactory.make(task.id, task.reminderAt);
  }
}
