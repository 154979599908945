import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import type { UserStatusRepository } from '@/modules/user/domain/user-status/UserStatusRepository';
import { StoreUserStatusRepository } from '@/modules/user/adapters/StoreUserStatusRepository';
import type { UserRepository } from '@/modules/user/domain/UserRepository';
import { StoreUserRepository } from '@/modules/user/adapters/StoreUserRepository';
import { UserRepositoryToken, UserStatusRepositoryToken } from '@/modules/user/di/tokens';

export class RepositoryProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<UserStatusRepository>(UserStatusRepositoryToken, StoreUserStatusRepository);

    container.register<UserRepository>(UserRepositoryToken, StoreUserRepository);
  }
}
