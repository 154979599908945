import { injectable } from 'tsyringe';
import { Subject } from '../../domain/subject/Subject';
import type { StoreSubject } from './types/StoreSubject';

@injectable()
export class SubjectFactory {
  makeMany(subjects: StoreSubject[]): Subject[] {
    return subjects.map(this.make, this);
  }

  make(storeSubject: StoreSubject): Subject {
    return new Subject(storeSubject.id, storeSubject.name, storeSubject.type);
  }
}
