import type { ErrorConverter } from '@/modules/book-visit/domain/errors/ErrorConverter';
import { BookingError } from '@/modules/book-visit/domain/errors/BookingError';
import { HttpClientError } from '@/modules/http-client/public/types';

export class HttpErrorConverter implements ErrorConverter {
  toBookingError(e: Error): BookingError {
    if (e instanceof BookingError) {
      return e;
    }

    if (!(e instanceof HttpClientError)) {
      throw e;
    }

    if (!this.hasValidErrorCode(e)) {
      throw e;
    }

    const message = e.data?.message;

    if (message) {
      return new BookingError(message);
    }

    const unknownError = JSON.stringify(e);

    return new BookingError(unknownError);
  }

  private hasValidErrorCode(e: HttpClientError): boolean {
    if (!e.statusCode) {
      return false;
    }

    return e.statusCode >= 400 && e.statusCode < 500;
  }
}
