import { RootLanguageConfigToken } from '@/common/root';
import type { PluginLanguageConfigProvider } from '@/common/root/adapters/PluginLanguageConfigProvider';
import { inject, injectable } from 'tsyringe';
import type { TemplatePathFactory } from '../../domain/create-task/TemplatePathFactory';

@injectable()
export class LocalizedTemplatePathFactory implements TemplatePathFactory {
  constructor(
    @inject(RootLanguageConfigToken)
    private readonly languageConfigProvider: PluginLanguageConfigProvider,
  ) {}

  make(): string {
    const { origin } = window.location;
    const pathName = window.location.pathname.replace(/\/index\.html$/gi, '');

    return `${origin}${pathName}/upload-template/${this.getFileName()}`;
  }

  private get locale(): 'en' | 'pl' | 'es' | 'mx' | 'it' | 'br' {
    return this.languageConfigProvider.getBasedOnDomain().locale;
  }

  private getFileName(): string {
    return (
      {
        pl: 'pl.xlsx',
        es: 'es.xlsx',
        mx: 'mx.xlsx',
        it: 'it.xlsx',
        br: 'br.xlsx',
      }[this.locale] ?? 'en.xlsx'
    );
  }
}
