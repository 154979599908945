// TODO (DPP-625): Temporarily solution to proxy media content
export class MediaProxy {
  constructor(private readonly accessToken: () => string) {}

  make(url: string): string {
    return this.getProxyUrl(url);
  }

  private getProxyUrl(contentUrl: string): string {
    const url = new URL(contentUrl);
    const originalSearchParams = url.searchParams;
    originalSearchParams.append('access_token', this.accessToken());

    url.search = originalSearchParams.toString();

    return url.toString();
  }
}
