import { ConferencePatient } from './ConferencePatient';
import { ConferenceReception } from './ConferenceReception';
import { ConferenceStatus } from './ConferenceStatus';
import { ConferenceTarget } from './ConferenceTarget';

export class Conference {
  constructor(
    readonly reception: ConferenceReception,
    readonly patient: ConferencePatient,
    readonly target: ConferenceTarget,
  ) {}

  get isPatientOnHold(): boolean {
    return this.patient.isOnHold;
  }

  get hasTarget(): boolean {
    return [ConferenceStatus.InConference, ConferenceStatus.Invited].includes(this.target.status);
  }

  get targetId(): string {
    return this.target.workstationId;
  }

  get isTargetConnected(): boolean {
    return this.target.status === ConferenceStatus.InConference;
  }

  static empty(): Conference {
    return new Conference(
      ConferenceReception.empty(),
      ConferencePatient.empty(),
      ConferenceTarget.empty(),
    );
  }
}
