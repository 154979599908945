import type { TaskFeedFetcher } from '@/modules/tasks-list/domain/task-feed/TaskFeedFetcher';
import { TaskFeed } from '@/modules/tasks-list/domain/task-feed/TaskFeed';
import { inject, injectable } from 'tsyringe';
import type { TaskRepository } from '@/modules/tasks-list/domain/task-feed/TaskRepository';
import { TaskEntityManagerToken, TaskRepositoryToken } from '@/modules/tasks-list/di/tokens';
import type { TaskEntityManager } from '@/modules/tasks-list/domain/task-feed/TaskEntityManager';

@injectable()
export class TodoTaskFeedFetcher implements TaskFeedFetcher {
  constructor(
    @inject(TaskRepositoryToken) private readonly taskFeedRepository: TaskRepository,
    @inject(TaskEntityManagerToken) private readonly taskEntityManager: TaskEntityManager,
  ) {}

  async fetch(): Promise<TaskFeed> {
    const tasks = await this.taskFeedRepository.getTodoPage();

    const ids = await this.taskEntityManager.storeEntities(tasks);

    return new TaskFeed(ids);
  }
}
