import { CallErrorRepositoryToken } from '@/modules/phone-widget/di/tokens';
import { inject, injectable } from 'tsyringe';
import { DpEventBus } from '@/core/events/DpEventBus';
import { DeviceCallFactory } from '../call-management/DeviceCallFactory';
import type { CallErrorRepository } from '../errors/CallErrorRepository';
import { CallErrorTracker } from '../errors/CallErrorTracker';
import type { DeviceCall } from '../models/DeviceCall';
import type { InputCall } from '../models/dto/InputCall';
import { ActiveCallStateRepository } from './ActiveCallStateRepository';
import { CallDurationTracker } from './CallDurationTracker';
import { CallStateTracker } from './CallStateTracker';
import { ActiveCallChanged } from '../events/ActiveCallChanged';

@injectable()
export class ActiveCallStateTracker {
  constructor(
    private readonly activeCallStateRepository: ActiveCallStateRepository,
    private readonly deviceCallFactory: DeviceCallFactory,
    private readonly callStateTracker: CallStateTracker,
    private readonly callDurationTracker: CallDurationTracker,
    private readonly callErrorTracker: CallErrorTracker,
    @inject(CallErrorRepositoryToken)
    private readonly callErrorRepository: CallErrorRepository,
    private readonly eventPublisher: DpEventBus,
  ) {}

  get activeCall(): DeviceCall {
    return this.activeCallStateRepository.get();
  }

  setActiveCall(call: InputCall): void {
    this.removeListeners();
    this.updateActiveCall(call);
    this.attachListeners(this.activeCall);
  }

  private updateCallState(call: DeviceCall): void {
    this.callStateTracker.setCallState(call.status);
    this.callDurationTracker.manageCallDuration(call);
    this.eventPublisher.publish(new ActiveCallChanged(call));
  }

  private updateActiveCall(call: InputCall) {
    this.activeCallStateRepository.set(this.deviceCallFactory.make(call));
    this.updateCallState(this.activeCall);
  }

  private removeListeners() {
    this.activeCall.removeAllListeners();
    this.callErrorRepository.clear();
  }

  private attachListeners(call: DeviceCall): void {
    this.callErrorTracker.attachListener(call);

    call.addEventListener('disconnect', (inputCall: InputCall) => {
      this.updateActiveCall(inputCall);
    });
    call.addEventListener('accept', (inputCall: InputCall) => {
      this.updateActiveCall(inputCall);
    });
    call.addEventListener('cancel', () => {
      this.updateCallState(this.activeCall);
    });
    // call.addEventListener('error', error => {
    // });
    call.addEventListener('reconnected', () => {
      this.updateCallState(this.activeCall);
    });
    call.addEventListener('reconnecting', () => {
      this.updateCallState(this.activeCall);
    });
    call.addEventListener('reject', () => {
      this.updateCallState(this.activeCall);
    });
    call.addEventListener('ringing', () => {
      this.updateCallState(this.activeCall);
    });
    // call.addEventListener('warning', (warningName, warningData) => {
    // });
    // call.addEventListener('warning-cleared', warningName => {
    // });
  }
}
