import { Owner } from '@/modules/task/domain/owner/Owner';
import { OwnerId } from '@/modules/task/domain/owner/OwnerId';

export class OwnerFactory {
  static make(
    id: string,
    name: string,
    avatar: string | null,
    status: 'online' | 'offline' | 'break',
  ): Owner {
    return new Owner(new OwnerId(id), name, avatar ?? '', status);
  }
}
