import { StateFetcherToken } from '@/modules/phone-widget/di/tokens';
import { inject, injectable } from 'tsyringe';
import type { DeviceCall } from '../models/DeviceCall';
import type { StateFetcher } from './StateFetcher';

@injectable()
export class ActiveCallStateRepository {
  constructor(
    @inject(StateFetcherToken)
    private readonly stateFetcher: StateFetcher,
  ) {}

  get(): DeviceCall {
    return this.stateFetcher.activeCall;
  }

  set(activeCall: DeviceCall): void {
    this.stateFetcher.activeCall = activeCall;
  }
}
