import { HttpClientApi } from '@/modules/http-client/public/api';
import { injectable } from 'tsyringe';
import type { TotalTasks } from '../../domain/overview-total-tasks/TotalTasks';
import type { TotalTasksRepository } from '../../domain/overview-total-tasks/TotalTasksRepository';
import type { InputTotalTasks } from './InputTotalTasks';
import { TotalTasksFactory } from './TotalTasksFactory';

@injectable()
export class HttpTotalTasksRepository implements TotalTasksRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly totalTasksFactory: TotalTasksFactory,
  ) {}

  async get(
    start: string,
    end: string,
    previousStart: string,
    previousEnd: string,
  ): Promise<TotalTasks> {
    const inputTotal = await this.httpClient.get<InputTotalTasks>(
      '/api/v0/facility/{facilityId}/stats/total-tasks',
      {
        start,
        end,
        previousStart,
        previousEnd,
      },
    );

    return this.totalTasksFactory.make(inputTotal);
  }
}
