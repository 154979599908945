import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import type { DateFormatter } from '@/app/formatters/date';
import { dateFormatter } from '@/app/formatters/date';
import { store } from '@/store';
import type { TimeZoneRepository } from '../domain/TimeZoneRepository';
import { StoreFacilityTimeZoneRepository } from '../adapters/StoreFacilityTimeZoneRepository';

export const RootDateFormatterToken = Symbol('rootDateFormatter');
export const RootTimeZoneRepositoryToken = Symbol('rootTimeZoneRepository');

@injectable()
export class RootDateFormatterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<DateFormatter>(RootDateFormatterToken, { useValue: dateFormatter });

    container.register<TimeZoneRepository>(RootTimeZoneRepositoryToken, {
      useValue: new StoreFacilityTimeZoneRepository(store),
    });
  }
}
