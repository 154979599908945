import { RootStoreToken } from '@/common/root';
import { $GET_ENTITIES_BY_TYPE } from '@dp-vue/entities';
import { injectable, inject } from 'tsyringe';
import type { Call } from '../../domain/Call';
import type { CallRepository } from '../../domain/CallRepository';
import { CallFactory } from './CallFactory';
import type { StoreCall } from './types/StoreCall';

interface Store {
  getters: {
    [$GET_ENTITIES_BY_TYPE]: (type: 'call') => StoreCall[];
  };
}

@injectable()
export class StoreCallRepository implements CallRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
    private readonly incomingCallFactory: CallFactory,
  ) {}

  getCallsForTask(taskId: string): Call[] {
    if (!taskId) {
      return [];
    }

    const taskCalls = this.store.getters[$GET_ENTITIES_BY_TYPE]('call').filter(
      storeCall => storeCall.taskId === taskId,
    );

    return this.incomingCallFactory.makeMany(taskCalls);
  }
}
