import { injectable } from 'tsyringe';
import { EventConsoleLogger } from '../../domain/event-console-logger-subscriber/EventConsoleLogger';

@injectable()
export class EventConsoleLoggerFacade {
  constructor(private readonly eventConsoleLogger: EventConsoleLogger) {}

  get status(): boolean {
    return this.eventConsoleLogger.status;
  }

  activate(): void {
    this.eventConsoleLogger.activate();
  }

  deactivate(): void {
    this.eventConsoleLogger.deactivate();
  }
}
