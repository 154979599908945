import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { HttpQuickReplyTemplateRepository } from '@/modules/settings/adapters/quick-replies/HttpQuickReplyTemplateRepository';
import { QuickReplyTemplateRepositoryToken } from '@/modules/settings/di/token';

export class QuickRepliesProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register(QuickReplyTemplateRepositoryToken, HttpQuickReplyTemplateRepository);
  }
}
