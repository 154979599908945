import type { RouteRecordRaw } from 'vue-router';

export const routerConfig = {
  routes: [
    {
      path: '/app-config',
      name: 'app-config',
      component: () => import(/* webpackChunkName: "core" */ './ui/AppConfigSwitcher.vue'),
    },
  ],
} as { routes: RouteRecordRaw[] };
