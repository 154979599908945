import { store } from '@/store';
import type { NavigationGuard } from 'vue-router';
import { UserRole } from '@/app/user-roles';
import { authenticationApi } from '@/modules/authentication/public/api';

/**
 * Check is user authenticated and it's manager
 */
export const ifManager: NavigationGuard = async (to, from, next) => {
  try {
    const user = await store.dispatch('GET_USER_ACTION');
    if (user.isManager) {
      next();

      return;
    }

    next('/app/home');
  } catch {
    next({ name: 'login', query: { target: to.fullPath } });
  }
};

export const ifAuthenticatedAndManager: NavigationGuard = (to, from, next) => {
  if (authenticationApi().isAuthenticated()) {
    ifManager(to, from, next);
    return;
  }

  next({ name: 'login' });
};

/**
 * Prevent of throwing error, when user refresh the page during channels configuration
 * and brings him back to a channels list.
 */
export const canLoadChannelsWizard: NavigationGuard = (to, from, next) => {
  const canLoadNext = store.getters['settings/channels/canLoadWizard'];

  if (canLoadNext) {
    next();
    return;
  }

  next({ name: 'channels-settings' });
};

export const hasRole =
  (role: UserRole): NavigationGuard =>
  (to, from, next): void => {
    if (!authenticationApi().isAuthenticated()) {
      next({ name: 'login' });
      return;
    }

    const canLoadNext = store.getters.hasRole(role);
    if (canLoadNext) {
      next();
      return;
    }

    next(from ?? false);
  };

export const ifManagerExceptForRoutes =
  (routeNames: string[]): NavigationGuard =>
  async (to, from, next) => {
    if (typeof to.name === 'string' && routeNames.includes(to.name)) {
      next();
      return;
    }

    const user = store.getters.getUser;

    if (user.isManager) {
      next();
      return;
    }

    next(false);
  };

export const ifHasAccessToFacility: NavigationGuard = (to, from, next) => {
  const isFacilitySwitcher = store.getters.hasRole(UserRole.FacilitySwitcher);
  const hasFacility = store.getters.getUserFacilityID;

  if (isFacilitySwitcher || hasFacility) {
    next();
    return;
  }

  next({ name: 'no-facility' });
};
