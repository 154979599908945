import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { anonymizationData } from '@/modules/anonymization/public/api';
import type { AnonymizationEvaluator } from '../../domain';
import { AnonymizationEvaluatorToken } from '../tokens';

@injectable()
export class AdaptersProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<AnonymizationEvaluator>(AnonymizationEvaluatorToken, {
      useValue: () => anonymizationData().isEnabled(),
    });
  }
}
