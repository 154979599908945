import { HttpClientApi } from '@/modules/http-client/public/api';
import { injectable } from 'tsyringe';
import type { Integration } from '../../domain/facility-integration/Integration';
import type { IntegrationRepository } from '../../domain/facility-integration/IntegrationRepository';
import { IntegrationFactory } from './IntegrationFactory';
import type { InputIntegrationDTO } from './types/InputIntegrationDTO';

@injectable()
export class HttpIntegrationRepository implements IntegrationRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly integrationFactory: IntegrationFactory,
  ) {}

  async get(): Promise<Integration[]> {
    const integrations: InputIntegrationDTO[] = await this.httpClient.get(
      '/api/v0/facility/{facilityId}/settings/integrations',
    );

    return this.integrationFactory.fromDTO(integrations);
  }

  async update(integrations: Integration[]): Promise<Integration[]> {
    const payload = this.integrationFactory.toDTO(integrations);

    const updatedIntegrations: InputIntegrationDTO[] = await this.httpClient.patch(
      '/api/v0/facility/{facilityId}/settings/integrations',
      payload,
    );

    return this.integrationFactory.fromDTO(updatedIntegrations);
  }
}
