import {
  $FILTER_ENTITIES_BY,
  $GET_ENTITIES_BY_ID,
  MUTATION_ENTITIES_UPDATE,
} from '@dp-vue/entities';
import { EntityTypes } from '@/api/types/entities';
import { toNamedParams } from '@/utils/vuex';
import type { UpcomingVisitFromPusher } from '@/api/types/response/upcoming-visits';
import type { GetterTree, ActionTree, Commit, Dispatch } from 'vuex';
import { upcomingVisitMapper, upcomingVisitOutputMapper } from '@/api/mappers';
import type { UpcomingVisit } from '@/api/models/UpcomingVisit';
import type { RootState } from '@/store/types';
import { compose, isIncludedIn, prop, extendObject, constant } from '@/utils/functions';
import { TaskTab } from '@/api/types/entities/task';
import type { UpcomingVisitOutput } from '@/api/models/UpcomingVisitOutput';
import { validator as patientValidator } from '@/modules/patient/public/api';

type State = Record<string, never>;

function removeUpcomingVisit(
  { commit, rootState }: { commit: Commit; rootState: RootState },
  phoneNumber: string,
): void {
  const hasUpcomingVisitsEntities = !!rootState.entities[EntityTypes.UpcomingVisitOutput];

  if (!hasUpcomingVisitsEntities) {
    return;
  }

  commit(
    'MUTATION_ENTITIES_DELETE_KEY',
    { entity: EntityTypes.UpcomingVisitOutput, key: phoneNumber },
    { root: true },
  );
}

function getTaskForUpcomingVisitsForPatient(rootGetters, patientId: string) {
  return rootGetters[$FILTER_ENTITIES_BY](EntityTypes.Task, 'patient', patientId).filter(
    compose(isIncludedIn([TaskTab.Open, TaskTab.Todo]), prop('tab')),
  );
}

function hasUpcomingVisitFor(rootGetters, phone_number: string): boolean {
  const visits = rootGetters[$GET_ENTITIES_BY_ID](
    EntityTypes.UpcomingVisitOutput,
    [phone_number],
    true,
  );

  return visits.length > 0;
}

function getPatientIdFromPhoneNumber(rootGetters, phone_number) {
  return rootGetters.getPatientByPhoneNumber(phone_number)?.id;
}

async function addNewUpcomingVisitToTask(
  dispatch: Dispatch,
  rootGetters,
  phone_number: string,
  newVisit: UpcomingVisit,
): Promise<void> {
  try {
    const patientId = getPatientIdFromPhoneNumber(rootGetters, phone_number);
    const tasks = getTaskForUpcomingVisitsForPatient(rootGetters, patientId).map(
      extendObject(constant({ patient_upcoming_visit: newVisit })),
    );

    await dispatch('UPDATE_TASK_ACTION', tasks, { root: true });
  } catch (e) {
    if (patientValidator().isPatientError(e)) {
      return;
    }

    throw e;
  }
}

async function updateUpcomingVisit({
  commit,
  dispatch,
  rootGetters,
  entities,
  phone_number,
  newVisit,
}: {
  commit: Commit;
  dispatch: Dispatch;
  rootGetters;
  phone_number: string;
  newVisit: UpcomingVisit;
  entities;
}): Promise<void> {
  if (hasUpcomingVisitFor(rootGetters, phone_number)) {
    commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });
  } else {
    await addNewUpcomingVisitToTask(dispatch, rootGetters, phone_number, newVisit);
  }
}

const getters: GetterTree<State, RootState> = {
  findUpcomingVisitOutput: toNamedParams(({ rootGetters }) => phoneNumber => {
    const visits = rootGetters[$GET_ENTITIES_BY_ID](
      EntityTypes.UpcomingVisitOutput,
      [phoneNumber],
      true,
    ) as UpcomingVisitOutput[];

    if (!visits.length) {
      return null;
    }

    return upcomingVisitOutputMapper.mapSingle(visits[0]);
  }),
};

const actions: ActionTree<State, RootState> = {
  async HANDLE_UPCOMING_VISIT_ACTION(
    { commit, dispatch, rootGetters, rootState },
    visit: UpcomingVisitFromPusher,
  ): Promise<void> {
    const { phone_number } = visit;

    const { entities } = upcomingVisitMapper.normalizeSingle(visit as UpcomingVisit);
    const newVisit = upcomingVisitMapper.denormalizeSingle(phone_number, entities);

    if (!newVisit?.hasVisitOutput) {
      removeUpcomingVisit({ commit, rootState }, phone_number);
    } else {
      await updateUpcomingVisit({
        dispatch,
        commit,
        entities,
        rootGetters,
        phone_number,
        newVisit,
      });
    }
  },
};

export const upcomingVisits = {
  namespaced: true,
  getters,
  actions,
};
