import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { injectable } from 'tsyringe';
import {
  TaskCallRepositoryToken,
  PatientRepositoryToken,
  UserRepositoryToken,
  UserStatusRepositoryToken,
  WorkstationRepositoryToken,
  TaskRepositoryToken,
  TokenRepositoryToken,
} from '../tokens';
import type { PatientRepository } from '../../domain/repositories/PatientRepository';
import { PatientApiPatientRepository } from '../../adapters/repositories/PatientApiPatientRepository';
import type { WorkstationRepository } from '../../domain/repositories/WorkstationRepository';
import { StoreWorkstationRepository } from '../../adapters/repositories/StoreWorkstationRepository';
import type { UserRepository } from '../../domain/repositories/UserRepository';
import { UserApiUserRepository } from '../../adapters/repositories/UserApiUserRepository';
import type { TaskCallRepository } from '../../domain/repositories/TaskCallRepository';
import { StoreTaskCallRepository } from '../../adapters/repositories/StoreTaskCallRepository';
import type { UserStatusRepository } from '../../domain/repositories/UserStatusRepository';
import { StoreUserStatusRepository } from '../../adapters/repositories/StoreUserStatusRepository';
import { StoreTaskRepository } from '../../adapters/repositories/StoreTaskRepository';
import type { TaskRepository } from '../../domain/repositories/TaskRepository';
import type { TokenRepository } from '../../domain/webphone/device/TokenRepository';
import { HttpTokenRepository } from '../../adapters/webphone/device/HttpTokenRepository';

@injectable()
export class RepositoryProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<PatientRepository>(PatientRepositoryToken, PatientApiPatientRepository);
    container.register<WorkstationRepository>(
      WorkstationRepositoryToken,
      StoreWorkstationRepository,
    );
    container.register<UserRepository>(UserRepositoryToken, UserApiUserRepository);
    container.register<TaskCallRepository>(TaskCallRepositoryToken, StoreTaskCallRepository);
    container.register<UserStatusRepository>(UserStatusRepositoryToken, StoreUserStatusRepository);
    container.register<TaskRepository>(TaskRepositoryToken, StoreTaskRepository);
    container.register<TokenRepository>(TokenRepositoryToken, HttpTokenRepository);
  }
}
