import type { RouteRecordRaw } from 'vue-router';
import { SettingMenu } from '@/modules/settings/domain/policies';
import { guard } from '@/common/router/public';
import { NavigationRoute } from './NavigationRoute';

export const settings: RouteRecordRaw[] = [
  {
    path: 'quick-replies',
    name: NavigationRoute.QuickReplies,
    beforeEnter: guard().hasAuthorization(SettingMenu.AccessQuickReplies),
    component: () => import(/* webpackChunkName: "settings" */ '../../ui/pages/QuickReplies.vue'),
  },
  {
    path: 'voice-messages',
    name: NavigationRoute.VoiceMessages,
    component: () =>
      import(
        /* webpackChunkName: "settings" */ '../../../settings-calls-management/ui/pages/VoiceMessages.vue'
      ),
  },
  {
    path: 'traffic-control',
    name: NavigationRoute.TrafficControl,
    beforeEnter: guard().hasAuthorization(SettingMenu.AccessTrafficControl),
    component: () => import(/* webpackChunkName: "settings" */ '../../ui/pages/TrafficControl.vue'),
  },
];
