import type { Store } from 'vuex';
import type { RootState } from '@/store/types';
import type { UserDataManager } from '@/modules/authentication/domain/login/me/UserDataManager';
import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';

@injectable()
export class StoreUserDataManager implements UserDataManager {
  constructor(@inject(RootStoreToken) private readonly store: Store<RootState>) {}

  async flush(): Promise<void> {
    // TODO perform this action in Module Store, emit event for application.
    await this.store.dispatch('USER_LOGOUT_ACTION');
  }

  async persist(user: unknown): Promise<void> {
    // TODO perform this action in Module Store, emit event for application.
    await this.store.dispatch('HANDLE_ME_RESPONSE', user);
  }
}
