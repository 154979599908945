import type { TaskGroup } from '@/modules/tasks-list/domain/task/TaskGroup';
import type { FilterOption } from '@/modules/tasks-list/domain/ownership-filter/options/FilterOption';
import type { TaskType } from '@/modules/tasks-list/domain/task/TaskType';
import type { RequestType } from '@/modules/tasks-list/domain/task-feed/views/PatientRequestViewTasksFilter';

export class Task {
  private constructor(
    private readonly group: TaskGroup,
    private readonly type: TaskType,
    private readonly ownerId: string | null,
    private readonly isAbandoned: boolean,
    private readonly patientRequestTypes: any[],
  ) {}

  canBeSeen(group: TaskGroup, ownershipFilterOption: FilterOption): boolean {
    if (!this.hasAllowedType) {
      return false;
    }

    if (this.isAbandoned) {
      return false;
    }

    if (this.isFilteredView(group)) {
      return this.hasMatchingOwnership(ownershipFilterOption);
    }

    if (!this.belongToGroup(group)) {
      return false;
    }

    if (group === 'open') {
      return this.hasMatchingOwnership(ownershipFilterOption);
    }

    return true;
  }

  private isFilteredView(group: TaskGroup): boolean {
    return (
      ['prescription', 'refill-prescription', 'certificate', 'test-results', 'other'].includes(
        group,
      ) &&
      this.group === 'open' &&
      this.patientRequestTypes.some(type => type === group)
    );
  }

  private belongToGroup(group: TaskGroup): boolean {
    return this.group === group;
  }

  private hasMatchingOwnership(ownershipFilterOption: FilterOption): boolean {
    return this.ownerId === null || ownershipFilterOption.isMatchingOwner(this.ownerId);
  }

  private get hasAllowedType(): boolean {
    const whiteList: TaskType[] = [
      'inbound-call',
      'inbound-form',
      'outbound-call',
      'outbound-visit-confirmation',
    ];

    return whiteList.includes(this.type);
  }

  static make(
    group: TaskGroup,
    type: TaskType,
    ownerId: string | null,
    isAbandoned: boolean,
    patientRequestTypes: RequestType[],
  ): Task {
    return new Task(group, type, ownerId, isAbandoned, patientRequestTypes);
  }
}
