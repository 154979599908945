import { singleton } from 'tsyringe';
import { ConnectOptionsFactory } from './device/ConnectOptionsFactory';
import type { DeviceCall } from './models/DeviceCall';
import type { CallState } from './models/CallState';
import { DeviceState } from './models/DeviceState';
import { PhoneWidgetError } from './PhoneWidgetError';
import type { RefreshableCallingDevice } from './device/RefreshableCallingDevice';
import { RefreshableCallingDeviceFactory } from './device/RefreshableCallingDeviceFactory';
import { DeviceStateTracker } from './state/DeviceStateTracker';
import { CallStateTracker } from './state/CallStateTracker';
import { ActiveCallStateTracker } from './state/ActiveCallStateTracker';
import { CallDurationTracker } from './state/CallDurationTracker';
import { DeviceErrorTracker } from './errors/DeviceErrorTracker';
import { ContextRepository } from './errors/ContextRepository';
import { RestartScheduler } from './restart/RestartScheduler';
/**
 * Class dedicated to maintain device state (currently provided by Twilio).
 * It handles all cases related to managing device or call without phone-api.
 */
@singleton()
export class DeviceManager {
  private callingDevice: RefreshableCallingDevice | null = null;

  constructor(
    private readonly refreshableCallingDeviceFactory: RefreshableCallingDeviceFactory,
    private readonly connectOptionsFactory: ConnectOptionsFactory,
    private readonly deviceStateTracker: DeviceStateTracker,
    private readonly callStateTracker: CallStateTracker,
    private readonly activeCallStateTracker: ActiveCallStateTracker,
    private readonly callDurationTracker: CallDurationTracker,
    private readonly deviceErrorTracker: DeviceErrorTracker,
    private readonly contextRepository: ContextRepository,
    private readonly restartScheduler: RestartScheduler,
  ) {
    this.restartScheduler.setRestartCallback(() => {
      this.restart();
    });
  }

  get callState(): CallState {
    return this.callStateTracker.callState;
  }

  get callDuration(): number | null {
    return this.callDurationTracker.callDuration;
  }

  get activeCall(): DeviceCall {
    return this.activeCallStateTracker.activeCall;
  }

  get isReady(): boolean {
    return this.deviceState === DeviceState.Registered;
  }

  private get deviceState(): DeviceState {
    return this.deviceStateTracker.deviceState;
  }

  async create(): Promise<void> {
    this.callingDevice = await this.refreshableCallingDeviceFactory.make();
    this.deviceStateTracker.attachDeviceListeners(this.callingDevice);
    this.deviceErrorTracker.attachListener(this.callingDevice);
    this.callingDevice.register();
    this.contextRepository.startDeviceSession();
  }

  async destroy(): Promise<void> {
    this.callingDevice?.destroy();
    this.contextRepository.clear();
  }

  async makeCall(to: string): Promise<void> {
    this.assertDevice(this.callingDevice);

    const outgoingCall = await this.callingDevice.connect(this.connectOptionsFactory.make(to));
    this.deviceStateTracker.handleCall(outgoingCall);
  }

  async acceptCall(): Promise<void> {
    this.activeCall.accept();
  }

  async cancelCall(): Promise<void> {
    this.activeCall.cancel();
  }

  async makeInternalCall(workstationId: string): Promise<void> {
    this.makeCall(workstationId);
  }

  async sendDigit(digit: string): Promise<void> {
    this.activeCall.sendDigits(digit);
  }

  async restart(): Promise<void> {
    await this.destroy();
    await this.create();
  }

  private assertDevice(
    callingDevice: RefreshableCallingDevice | null,
  ): asserts callingDevice is RefreshableCallingDevice {
    if (!callingDevice) {
      throw new PhoneWidgetError(
        "You're trying to perform an action on not initialized webphone device.",
      );
    }

    if (callingDevice.state !== DeviceState.Registered) {
      throw new PhoneWidgetError('Device is not ready for handling calls.');
    }
  }
}
