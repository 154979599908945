import { phoneNumberFormatter } from '@/utils/phone-number-formatter';
import type { PatientRepository } from '@/modules/phone-widget/domain/repositories/PatientRepository';

export class Task {
  constructor(
    public readonly id: string,
    public readonly caller: string,
  ) {}

  get formattedNumber(): string {
    return phoneNumberFormatter(this.caller);
  }

  displayName(patients: PatientRepository): string {
    const patient = patients.findByNumber(this.caller);
    return patient?.displayName || this.formattedNumber;
  }
}
