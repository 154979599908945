import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import {
  FallbackWorkstationRepositoryToken,
  WorkstationReadRepositoryToken,
} from '../token/workstation';
import type { FallbackWorkstationRepository } from '../../domain/workstation/FallbackWorkstationRepository';
import { StoreWorkstationReadRepository } from '../../adapters/workstation/StoreWorkstationReadRepository';
import type { WorkstationReadRepository } from '../../domain/workstation/WorkstationReadRepository';
import { HttpFallbackWorkstationRepository } from '../../adapters/workstation/HttpFallbackWorkstationRepository';

export class WorkstationProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<WorkstationReadRepository>(
      WorkstationReadRepositoryToken,
      StoreWorkstationReadRepository,
    );

    container.registerSingleton<FallbackWorkstationRepository>(
      FallbackWorkstationRepositoryToken,
      HttpFallbackWorkstationRepository,
    );
  }
}
