import { injectable } from 'tsyringe';
import { CallQualityReport } from '@/modules/timeline/domain/call/quality/CallQualityReport';
import { ProblemType } from '@/modules/timeline/domain/call/quality/ProblemType';
import type { InputCallQualityProblem } from './InputCallQualityProblem';
import type { InputCallQualityWithComment } from './InputCallQualityWithComment';

@injectable()
export class CallQualityReportFactory {
  make(callId: string, inputReport: InputCallQualityWithComment): CallQualityReport {
    return new CallQualityReport(
      callId,
      this.mapProblems(inputReport.problems),
      inputReport.comment,
    );
  }

  private mapProblems(problems: InputCallQualityProblem[]): ProblemType[] {
    return problems.map(problem => this.getType(problem), this);
  }

  private getType(problem: InputCallQualityProblem): ProblemType {
    switch (problem) {
      case 'audio-latency':
        return ProblemType.AudioLatency;
      case 'dropped-call':
        return ProblemType.DroppedCall;
      case 'imperfect-audio-quality':
        return ProblemType.ImperfectCallQuality;
      case 'incorrect-caller-id':
        return ProblemType.IncorrectCallerId;
      case 'one-way-audio':
        return ProblemType.OneWayAudio;
      case 'post-call-delay':
        return ProblemType.PostCallDelay;
      default: {
        const unknownProblem: never = problem;
        throw new Error(`Invalid problem type: "${unknownProblem}"`);
      }
    }
  }
}
