import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import type { Store } from 'vuex';
import type { PresenceCallbackFactory } from '../../domain/callbacks/PresenceCallbackFactory';
import { PresenceEventName } from '../../domain/callbacks/StaticPresenceEventName';
import type { Callback } from '../../domain/models/Callback';
import type { PresenceChannel } from '../../domain/models/PresenceChannel';

@injectable()
export class StorePresenceCallbackFactory implements PresenceCallbackFactory {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store<unknown>,
  ) {}

  make(commonChannel: PresenceChannel): Callback[] {
    const setActiveUsers = () => {
      this.store.commit('SET_ACTIVE_USERS', Object.values(commonChannel.members));
    };

    return [
      {
        name: 'Presence:MemberAdded',
        channelName: 'common',
        eventName: PresenceEventName.PusherMemberAdded,
        handler: setActiveUsers,
      },
      {
        name: 'Presence:MemberRemoved',
        channelName: 'common',
        eventName: PresenceEventName.PusherMemberRemoved,
        handler: setActiveUsers,
      },
      {
        name: 'Presence:SubscriptionSucceeded',
        channelName: 'common',
        eventName: PresenceEventName.PusherSubscriptionsSucceeded,
        handler: setActiveUsers,
      },
    ];
  }
}
