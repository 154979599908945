import { injectable } from 'tsyringe';
import { Authorization } from '@/common/authorization/public/api';
import type { Authorizer } from '@/modules/phone-widget/domain/Authorizer';

@injectable()
export class AuthorizationApiAuthorizer implements Authorizer {
  constructor(private readonly authorization: Authorization) {}

  can(action: string): boolean {
    return this.authorization.can(action);
  }
}
