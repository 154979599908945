import { map, prop } from '@/utils/functions';
import type { SearchPayload } from '@/api/types/request/search';
import { FilterTypeOption } from '@/app/search/FilterTypeOption';
import { SearchCriteriaName } from '@/app/search/SearchCriteriaName';
import type { SearchCriteria } from '@/app/search/SearchCriteria';

export interface DateRange {
  start: string | null;
  end: string | null;
}
export class SearchPayloadFactory {
  static createPayload(criteria: SearchCriteria): SearchPayload {
    const payload = {
      ...SearchPayloadFactory.default,
      ...criteria,
    };

    const normalize = map(prop('id'));

    return {
      query: {
        dateRange: SearchPayloadFactory.getDateRange(payload),
        personnel: normalize(payload[SearchCriteriaName.Personnel]),
        numbers: normalize(payload[SearchCriteriaName.Numbers]),
        results: normalize(payload[SearchCriteriaName.Results]),
        phones: normalize(payload[SearchCriteriaName.Phones]),
        forms: normalize(payload[SearchCriteriaName.Forms]),
        tasks: normalize(payload[SearchCriteriaName.Tasks]),
        textQueries: normalize(payload[SearchCriteriaName.TextQueries]),
        patientRequestTypes: normalize(payload[SearchCriteriaName.PatientRequests]),
      },
      filter: payload[SearchCriteriaName.Filter]?.id ?? FilterTypeOption.None,
    };
  }

  private static getDateRange(criteria: SearchCriteria): DateRange {
    const dateRange = criteria[SearchCriteriaName.DateRange] ?? [];

    return {
      start: dateRange[0] ?? null,
      end: dateRange[1] ?? null,
    };
  }

  static default: SearchCriteria = {
    [SearchCriteriaName.DateRange]: [null, null],
    [SearchCriteriaName.Filter]: null,
    [SearchCriteriaName.Forms]: [],
    [SearchCriteriaName.Numbers]: [],
    [SearchCriteriaName.Personnel]: [],
    [SearchCriteriaName.Phones]: [],
    [SearchCriteriaName.Results]: [],
    [SearchCriteriaName.Tasks]: [],
    [SearchCriteriaName.TextQueries]: [],
    [SearchCriteriaName.PatientRequests]: [],
  };
}
