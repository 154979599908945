import { inject, injectable } from 'tsyringe';
import { PauseRepositoryToken, UserStatusRepositoryToken } from '../../di/tokens';
import { UserSession } from '../../domain/pause/UserSession';
import type { UserSessionRepository } from '../../domain/pause/UserSessionRepository';
import type { PauseRepository } from '../../domain/pause/PauseRepository';

/**
 * API Class for store, to temporary save stuff outside of domain until we migrate pause to the modules.
 */
@injectable()
export class UserSessionApi {
  constructor(
    @inject(UserStatusRepositoryToken)
    private readonly userSessionRepository: UserSessionRepository,
    @inject(PauseRepositoryToken)
    private readonly pauseRepository: PauseRepository,
  ) {}

  async saveSession(sessionStart: string, isOnline: boolean): Promise<void> {
    this.userSessionRepository.save(new UserSession(sessionStart, isOnline));
  }

  async breakStart(endTime: string): Promise<UserSession> {
    return this.pauseRepository.breakStart(endTime);
  }

  async breakEnd(): Promise<UserSession> {
    return this.pauseRepository.breakEnd();
  }
}
