<template>
  <div :class="$b()">
    <vue-plyr ref="plyr" :options="playerConfig">
      <video>
        <source :src="src" type="video/mp4" />
      </video>
    </vue-plyr>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Ref } from 'vue-facing-decorator';

interface Player {
  currentTime: number;
  on(eventName: string, callback: () => void): void;
  destroy(): void;
  speed: number;
}

@Component({
  name: 'VideoPlayer',
})
export default class VideoPlayer extends Vue {
  @Prop({ type: String, required: true })
  readonly src: string;

  @Prop({ type: Boolean, default: false })
  readonly autoplay: string;

  get playerConfig() {
    return {
      autoplay: this.autoplay,
      controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
    };
  }

  get player(): Player {
    return this.plyr.player;
  }

  @Ref()
  readonly plyr!: { player: Player };

  mounted(): void {
    this.attachPlayerListeners();
  }

  beforeUnmount(): void {
    this.player.destroy();
  }

  attachPlayerListeners(): void {
    this.player.on('playing', this.userStartPlayingAudioListener);
  }

  userStartPlayingAudioListener(): void {
    if (this.player.currentTime === 0) {
      this.$emit('play');
    }
  }
}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-video-player {
  border-radius: v.$border-radius;

  :deep(.plyr) {
    border-radius: v.$border-radius;
  }
}
</style>
