import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { HttpErrorConverter } from '@/modules/book-visit/adapters/errors/HttpErrorConverter';
import { TranslatedErrorMessageProvider } from '@/modules/book-visit/adapters/errors/TranslatedErrorMessageProvider';
import { ErrorConverterToken, ErrorMessageProviderToken } from '../token';

export class AdapterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register(ErrorConverterToken, HttpErrorConverter);
    container.register(ErrorMessageProviderToken, TranslatedErrorMessageProvider);
  }
}
