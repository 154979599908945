import { inject, injectable } from 'tsyringe';
import type { Task } from '../task/Task';
import type { OwnerRepository } from '../owner/OwnerRepository';
import { AuthorizationCheckerToken, OwnerRepositoryToken } from '../../di/tokens';
import type { AuthorizationChecker } from '../authorization/AuthorizationChecker';
import { Policies } from '../authorization/policies';

@injectable()
export class TaskVisibilityChecker {
  constructor(
    @inject(OwnerRepositoryToken)
    private readonly ownerRepository: OwnerRepository,
    @inject(AuthorizationCheckerToken)
    private readonly authorizer: AuthorizationChecker,
  ) {}

  isVisible(task: Task): boolean {
    if (task.isTodoTab) {
      return true;
    }
    if (this.authorizer.can(Policies.SeeAllTasks)) {
      return true;
    }

    return !task.hasOwner || task.isMine(this.ownerRepository);
  }
}
