import type { StoreVisitConfirmation } from '@/modules/book-visit/adapters/visit/StoreVisitConfirmation';
import { VisitConfirmation } from '@/modules/book-visit/domain/visit/VisitConfirmation';
import { StoreVisitFactory } from '@/modules/book-visit/adapters/visit/StoreVisitFactory';

export class StoreVisitConfirmationFactory {
  static tryMake(storeVisitConfirmation: StoreVisitConfirmation | null): VisitConfirmation | null {
    if (!storeVisitConfirmation) {
      return null;
    }

    return new VisitConfirmation(
      storeVisitConfirmation.status,
      storeVisitConfirmation.payload.links.patientDetail,
      StoreVisitFactory.makeFromVisitConfirmation(storeVisitConfirmation.payload),
    );
  }
}
