import { RootStoreToken } from '@/common/root';
import { parseISO } from 'date-fns';
import { inject, injectable } from 'tsyringe';
import type { StoreFacility } from './types/StoreFacility';
import type { FacilityRepository } from '../../domain/tracking/repositories/FacilityRepository';
import { Facility } from '../../domain/tracking/models/Facility';

interface Store {
  getters: {
    getUserFacility: StoreFacility;
  };
}

@injectable()
export class StoreFacilityRepository implements FacilityRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
  ) {}

  get(): Facility {
    const storeFacility = this.store.getters.getUserFacility;
    const activationDate = storeFacility.customer?.activation_date
      ? parseISO(storeFacility.customer.activation_date).toISOString()
      : null;

    return new Facility(
      storeFacility.id ?? '',
      storeFacility.name,
      storeFacility.docplanner_integration?.method ?? 'none',
      storeFacility.docplanner_integration?.saas_only ?? false,
      typeof storeFacility.reception.limit === 'number' ? storeFacility.reception.limit : null,
      storeFacility.reception.phones.length,
      storeFacility.reception.phones.filter(({ type }) => type === 'web').length,
      activationDate,
    );
  }
}
