import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { BrowserDesktopNotificationNotifier } from '../../adapters/desktop-notification/BrowserDesktopNotificationService';
import { TranslatedDesktopNotificationDescriptionFactory } from '../../adapters/desktop-notification/TranslatedDesktopNotificationDescriptionFactory';
import type { DesktopNotificationNotifier } from '../../domain/desktop-notification/DesktopNotificationNotifier';
import type { DesktopNotificationDescriptionFactory } from '../../domain/desktop-notification/DesktopNotificationDescriptionFactory';
import {
  DesktopNotificationNotifierToken,
  DesktopNotificationDescriptionFactoryToken,
} from '../tokens';

@injectable()
export class DesktopNotificationProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<DesktopNotificationNotifier>(
      DesktopNotificationNotifierToken,
      BrowserDesktopNotificationNotifier,
    );

    container.register<DesktopNotificationDescriptionFactory>(
      DesktopNotificationDescriptionFactoryToken,
      TranslatedDesktopNotificationDescriptionFactory,
    );
  }
}
