import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { StoreTaskListenerFactory } from '../../adapters/task/StoreTaskListenerFactory';
import type { TaskListenerFactory } from '../../domain/task/TaskListenerFactory';
import { TaskListenerFactoryToken } from '../tokens';

export class TaskProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<TaskListenerFactory>(TaskListenerFactoryToken, StoreTaskListenerFactory);
  }
}
