export class Seconds {
  private constructor(readonly value: number) {}

  static zero(): Seconds {
    return new Seconds(0);
  }

  static make(value: number): Seconds {
    return new Seconds(value);
  }
}
