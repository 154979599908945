import { RootStoreToken } from '@/common/root';
import { $GET_ENTITIES_BY_ID } from '@dp-vue/entities';
import { inject, injectable } from 'tsyringe';
import { ClosedReasonFactory } from '../../domain/closed-reason/ClosedReasonFactory';
import { TaskFragmentFactory } from '../../domain/closed-reason/TaskFragmentFactory';
import { Context } from '../../domain/context/Context';
import type { ContextRepository } from '../../domain/context/ContextRepository';
import type { InputStoreContext } from './InputStoreContext';
import type { InputStoreTask } from './InputStoreTask';

type ReturnType<T> = T extends 'context'
  ? InputStoreContext[]
  : T extends 'task'
    ? InputStoreTask[]
    : never;

interface Store {
  getters: {
    [$GET_ENTITIES_BY_ID]: <T extends 'context' | 'task'>(type: T, ids: string[]) => ReturnType<T>;
  };
}

@injectable()
export class StoreContextRepository implements ContextRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
    private readonly taskFragmentFactory: TaskFragmentFactory,
    private readonly closedReasonFactory: ClosedReasonFactory,
  ) {}

  get(taskId: string): Context | null {
    const [task] = this.store.getters[$GET_ENTITIES_BY_ID]('task', [taskId]);

    if (!task || !task.context) {
      return null;
    }

    const [context] = this.store.getters[$GET_ENTITIES_BY_ID]('context', [task.context]);

    if (!context) {
      return null;
    }

    const taskFragment = this.taskFragmentFactory.make(
      context.id,
      context.status,
      context.result_type,
      context.result_name,
      context.visit_confirmation_status,
    );
    const closedReason = this.closedReasonFactory.make(taskFragment);
    return new Context(context.id, context.most_recent_call_at, closedReason);
  }
}
