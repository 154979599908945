import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { SessionStorageSsoFlowRepository } from '@/modules/authentication/adapters/sso/SessionStorageSsoFlowRepository';
import type { SsoFlowRepository } from '@/modules/authentication/domain/sso/login/SsoFlowRepository';
import { SsoFlowInitializer } from '@/modules/authentication/domain/sso/login/SsoFlowInitializer';
import {
  SsoClientRepositoryToken,
  SsoFlowRepositoryToken,
  SsoIntegrationRepositoryToken,
  SsoTokenRepositoryToken,
} from '@/modules/authentication/di/token';
import { HttpSsoIntegrationRepository } from '@/modules/authentication/adapters/sso/HttpSsoIntegrationRepository';
import type { SsoIntegrationRepository } from '@/modules/authentication/domain/sso/integration/SsoIntegrationRepository';
import { HttpSsoTokenRepository } from '@/modules/authentication/adapters/sso/HttpSsoTokenRepository';
import type { SsoTokenRepository } from '@/modules/authentication/domain/sso/login/SsoTokenRepository';
import { HttpSsoClientRepository } from '@/modules/authentication/adapters/sso/HttpSsoClientRepository';
import type { SsoClientRepository } from '@/modules/authentication/domain/sso/SsoClientRepository';

export class SsoProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<SsoTokenRepository>(SsoTokenRepositoryToken, HttpSsoTokenRepository);

    container.register<SsoFlowRepository>(SsoFlowRepositoryToken, SessionStorageSsoFlowRepository);

    container.register<SsoIntegrationRepository>(
      SsoIntegrationRepositoryToken,
      HttpSsoIntegrationRepository,
    );

    container.register<SsoClientRepository>(SsoClientRepositoryToken, HttpSsoClientRepository);

    container.register<SsoFlowInitializer>(SsoFlowInitializer, {
      useFactory: (c: DependencyContainer) =>
        new SsoFlowInitializer(c.resolve(SsoFlowRepositoryToken), window),
    });
  }
}
