import { injectable } from 'tsyringe';
import { PingManager } from '../../domain/ping/PingManager';

@injectable()
export class PingApi {
  constructor(private readonly pingManager: PingManager) {}

  async start(): Promise<void> {
    this.pingManager.start();
  }
}
