import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { Task } from '@/api/models/Task';
import type { TaskReadRepository } from '../../domain/real-time-updates/TaskReadRepository';
import {
  TaskFactoryToken,
  TaskReadRepositoryToken,
  TaskNormalizerToken,
  TaskWriteRepositoryToken,
} from '../tokens';
import { HttpTaskReadRepository } from '../../adapters/real-time-communication/HttpTaskReadRepository';
import type { TaskNormalizer } from '../../domain/real-time-updates/TaskNormalizer';
import { ApiTaskNormalizer } from '../../adapters/real-time-communication/ApiTaskNormalizer';
import type { TaskWriteRepository } from '../../domain/real-time-updates/TaskWriteRepository';
import { StoreTaskWriteRepository } from '../../adapters/real-time-communication/StoreTaskWriteRepository';
import type { TaskFactory } from '../../domain/real-time-updates/TaskFactory';

@injectable()
export class RealTimeCommunicationProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<TaskReadRepository>(TaskReadRepositoryToken, HttpTaskReadRepository);
    container.register<TaskNormalizer>(TaskNormalizerToken, ApiTaskNormalizer);
    container.register<TaskWriteRepository>(TaskWriteRepositoryToken, StoreTaskWriteRepository);
    container.register<TaskFactory>(TaskFactoryToken, {
      useFactory: () => Task,
    });
  }
}
