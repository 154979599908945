import type { OwnerId } from '@/modules/task/domain/owner/OwnerId';
import type { OwnerRepository } from '@/modules/task/domain/owner/OwnerRepository';
import type { Owner } from '@/modules/task/domain/owner/Owner';
import { IsoDate } from '@/modules/task/domain/time/IsoDate';
import type { TimezoneRepository } from '@/modules/task/domain/time/TimezoneRepository';
import { getUnixTime, parseISO } from 'date-fns';
import type { Clock } from '@/modules/task/domain/time/Clock';
import type { Call as CallInterface } from './types/Call';

export class Call implements CallInterface {
  constructor(
    readonly id: string,
    private readonly status:
      | 'abandoned'
      | 'completed'
      | 'in-progress'
      | 'intro'
      | 'unanswered'
      | 'waiting',
    private readonly direction: 'outgoing' | 'incoming',
    readonly ownerId: OwnerId,
    readonly createdAt: string,
    readonly updatedAt = '',
    readonly startWaitingAt = '',
    readonly answeredAt = '',
    readonly channelId = '',
    readonly channelName = '',
    readonly voiceMessageUrl = '',
    readonly workstationId = '',
    readonly duration = 0,
  ) {}

  get hasOwner(): boolean {
    return !this.ownerId.isEmpty;
  }

  get isInProgress(): boolean {
    return this.status === 'in-progress';
  }

  get isUnanswered(): boolean {
    return this.status === 'unanswered';
  }

  get isWaiting(): boolean {
    return this.status === 'waiting';
  }

  get isDuringIntro(): boolean {
    return this.status === 'intro';
  }

  get hasVoiceMessage(): boolean {
    return this.voiceMessageUrl !== '';
  }

  get isOngoingCall(): boolean {
    return ['in-progress', 'waiting', 'intro'].includes(this.status);
  }

  get isSuccessful(): boolean {
    return ['in-progress', 'completed'].includes(this.status);
  }

  get isAbandoned(): boolean {
    return this.status === 'abandoned';
  }

  get isOutgoing(): boolean {
    return this.direction === 'outgoing';
  }

  get isIncoming(): boolean {
    return this.direction === 'incoming';
  }

  get isCompleted(): boolean {
    return this.status === 'completed';
  }

  get hasAssignedWorkstation(): boolean {
    return !!this.workstationId;
  }

  get isLongerThan5Minutes(): boolean {
    return this.duration > 5 * 60;
  }

  get isShorterThan5Sec(): boolean {
    return this.duration < 5;
  }

  currentDuration(clock: Clock): number {
    if (!this.isInProgress && !this.isWaiting) {
      return 0;
    }

    try {
      return getUnixTime(clock.now()) - this.startTime;
    } catch {
      return 0;
    }
  }

  isOwnedBy(owner: Owner): boolean {
    return owner.id.isEqual(this.ownerId);
  }

  isMine(ownerRepository: OwnerRepository): boolean {
    return this.isOwnedBy(ownerRepository.getMe());
  }

  displayedDate(timezoneRepository: TimezoneRepository): IsoDate {
    return this.createdAt !== ''
      ? IsoDate.make(this.createdAt, timezoneRepository.get())
      : IsoDate.empty;
  }

  private get startTime() {
    const startDate = this.answeredAt || this.startWaitingAt;

    if (startDate === '') {
      throw new Error('Call starting date is unknown.');
    }

    return getUnixTime(parseISO(startDate));
  }
}
