import { VoiceMenuConfigurationOption } from './VoiceMenuConfigurationOption';
import type { FileRepository } from '../FileRepository';
import type { Digit } from '../models/Digit';
import { Label } from '../models/Label';

export class VoiceMenuConfigurationOptionsList {
  private static readonly allDigits: Digit[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

  constructor(readonly configurationOptions: VoiceMenuConfigurationOption[]) {}

  get identifiersInUse(): Digit[] {
    return this.configurationOptions.map(({ digit }) => digit);
  }

  get canAddNew(): boolean {
    return this.identifiersInUse.length < 9;
  }

  addNewOption(): void {
    this.configurationOptions.push(
      new VoiceMenuConfigurationOption(
        '',
        this.getNextAvailableDigit(),
        Label.empty(),
        'label',
        undefined,
        undefined,
        true,
      ),
    );
  }

  remove(digit: Digit): void {
    const index = this.configurationOptions.findIndex(
      configurationOption => configurationOption.digit === digit,
    );
    this.configurationOptions.splice(index, 1);
  }

  update(digit: Digit, newVoiceMenuConfiguration: VoiceMenuConfigurationOption): void {
    const index = this.configurationOptions.findIndex(
      configurationOption => configurationOption.digit === digit,
    );
    this.configurationOptions.splice(index, 1, newVoiceMenuConfiguration);
  }

  async uploadVoiceMessages(fileRepository: FileRepository): Promise<void> {
    const configurationOptionsToUpload = this.configurationOptions.filter(
      configurationOption => configurationOption.isVoiceMessage,
    );

    await Promise.all(
      configurationOptionsToUpload.map(async configurationOption =>
        configurationOption.saveVoiceMessageFile(fileRepository),
      ),
    );
  }

  private getNextAvailableDigit(): Digit {
    const firstFreeDigit = VoiceMenuConfigurationOptionsList.allDigits.find(
      digit => !this.identifiersInUse.includes(digit),
    );

    if (!firstFreeDigit) {
      throw new Error('You try to obtain invalid identifier');
    }

    return firstFreeDigit;
  }

  static empty(): VoiceMenuConfigurationOptionsList {
    return new VoiceMenuConfigurationOptionsList([]);
  }
}
