import type { VoiceMessageDTO } from '../../domain/voice-messages/VoiceMessageDTO';
import { VoiceMessageName, VoiceMessageType } from '../../domain/voice-messages/VoiceMessage';

interface VoiceMessage {
  file: string;
  type: VoiceMessageType;
  value: string;
}

interface VoiceMessagePayload {
  messages: Partial<Record<VoiceMessageName, VoiceMessage>>;
}

export class VoiceMessagePayloadFactory {
  static makeFromDTOs(voiceMessageDTOs: VoiceMessageDTO[]): VoiceMessagePayload {
    const messages = voiceMessageDTOs.map(this.validate).reduce(
      (acc, messageDTO: VoiceMessageDTO) => ({
        ...acc,
        [this.parsePayloadName(messageDTO.name)]: {
          file: messageDTO.file?.id ?? '',
          type: messageDTO.type,
          value: messageDTO.value,
        },
      }),
      {},
    );

    return {
      messages,
    };
  }

  private static validate(messageDTO: VoiceMessageDTO): VoiceMessageDTO {
    if (!Object.values(VoiceMessageName).includes(messageDTO.name)) {
      throw new Error('Invalid voice message name');
    }

    return messageDTO;
  }

  private static parsePayloadName(name: VoiceMessageName): string {
    return {
      [VoiceMessageName.WelcomeMessage]: 'welcome_message',
      [VoiceMessageName.VoiceMenu]: 'voice_menu',
      [VoiceMessageName.FacilityAfterHours]: 'facility_after_hours',
      [VoiceMessageName.VoicemailInQueue]: 'voicemail_in_queue',
    }[name];
  }
}
