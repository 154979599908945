import type { Timeline } from '@/modules/task/domain/timeline/Timeline';
import { SmsMessage } from '@/modules/task/domain/message/SmsMessage';
import type { IconStrategy } from '../types';
import type { Task } from '../../task/Task';
import { IconName } from '../types';

export class OutgoingSmsMessage implements IconStrategy {
  get(task: Task, { lastUserEvent }: Timeline): null | IconName {
    if (!(lastUserEvent instanceof SmsMessage)) {
      return null;
    }

    if (lastUserEvent.isOutbound) {
      return IconName.OutgoingSmsMessage;
    }

    return null;
  }
}
