<template>
  <form :class="$b()" @submit.prevent="handleSubmit">
    <file-uploader
      :class="$b('attachment')"
      :accept-types="acceptedFileTypes"
      :disabled="disabled"
      :has-visible-file-name="false"
      has-preview
      @update="submitWithFile"
      @error="onError"
    >
      <dp-icon-clip color="gray-600" />
    </file-uploader>

    <resizable-text-area
      v-model="messageText"
      :class="$b('input')"
      :placeholder="$t('task.messages.placeholder.type_message')"
      :disabled="disabled"
      @submit="handleSubmit"
    />

    <dp-button :class="$b('submit')" color="primary" size="lg" type="submit" :state="buttonState">
      <dp-icon-letter-notification color="white" />
    </dp-button>
  </form>
</template>

<script lang="ts">
import { Vue, Component, Emit, Prop, Ref } from 'vue-facing-decorator';
import { DpButton } from '@dp-ui-kit/vue';
import { DpIconLetterNotification, DpIconClip, DpIconClose } from '@dp-ui-kit/icons';

import { ResizableTextArea } from '@/ui/atoms';
import type { TaskMessagePayload } from '@/ui/molecules/TaskMessageInput/TaskMessageInput.types';
import FileUploader from '../FileUploader/FileUploader.vue';

// TODO: remove the component once the organisms/WhatsAppMessageInput is implemented in its place
@Component({
  name: 'TaskMessageInput',
  emits: ['submit'],

  components: {
    FileUploader,
    DpButton,
    DpIconLetterNotification,
    DpIconClip,
    DpIconClose,
    ResizableTextArea,
  },
})
export default class TaskMessageInput extends Vue {
  messageText = '';

  @Prop({ type: Boolean, default: false })
  readonly disabled: boolean;

  @Ref()
  readonly fileInput: HTMLInputElement;

  get acceptedFileTypes(): string[] {
    return ['image/jpeg', 'image/png', 'video/mp4', 'audio/mp3', 'application/pdf', 'audio/mpeg'];
  }

  get buttonState(): 'disabled' | null {
    return this.isSubmitDisabled ? 'disabled' : null;
  }

  get isSubmitDisabled(): boolean {
    return this.disabled || this.isMessageEmpty;
  }

  get isMessageEmpty(): boolean {
    return !this.messageText.trim();
  }

  handleSubmit(): void {
    if (this.isSubmitDisabled) {
      return;
    }

    this.submitWithText();
  }

  @Emit('submit')
  submitWithFile(media: { url: string; mimeType: string }): TaskMessagePayload {
    return {
      text: this.messageText,
      media,
    };
  }

  @Emit('submit')
  submitWithText(): TaskMessagePayload {
    return {
      text: this.messageText,
      media: null,
    };
  }

  resetInput(): void {
    this.messageText = '';
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;
@use 'src/assets/scss/mixins/z-index' as z;

$button-size: 2.5rem;

.dp-task-message-input {
  display: flex;
  align-items: flex-end;

  &__input {
    flex-grow: 1;
  }

  &__attachment {
    margin-right: v.$spacer-sm;
    margin-bottom: v.$form-group-margin-bottom;
  }

  &__submit {
    margin-left: v.$spacer-sm;
    margin-bottom: v.$form-group-margin-bottom;
    width: 4rem;
  }
}
</style>
