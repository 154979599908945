import { inject, injectable } from 'tsyringe';
import { TaskCallRepositoryToken, UserRepositoryToken } from '../../di/tokens';
import { TaskCall } from '../models/TaskCall';
import type { TaskCallRepository } from '../repositories/TaskCallRepository';
import type { UserRepository } from '../repositories/UserRepository';
import { WorkstationFetcher } from './WorkstationFetcher';

@injectable()
export class CurrentUserCallFetcher {
  constructor(
    @inject(UserRepositoryToken)
    private readonly userRepository: UserRepository,
    @inject(TaskCallRepositoryToken)
    private readonly taskCallRepository: TaskCallRepository,
    private readonly workstationFetcher: WorkstationFetcher,
  ) {}

  private get currentUserId() {
    return this.userRepository.getCurrentUser().id;
  }

  private get workstationId(): string {
    return this.workstationFetcher.getMine()?.id ?? '';
  }

  find(): TaskCall {
    return (
      this.taskCallRepository.all().find(call => {
        if (!call.isOngoing) {
          return false;
        }

        if (call.ownerId) {
          return call.ownerId === this.currentUserId;
        }

        return this.workstationId.length > 0 && this.workstationId === call.workstationId;
      }) ?? TaskCall.empty()
    );
  }
}
