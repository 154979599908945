export enum EntityType {
  TASK = 'task',
  CALL = 'call',
  MEDIA = 'media',
  PHONE = 'phone',
  PHONE_VERIFICATION = 'phoneVerification',
  RESULT = 'result',
  USER = 'user',
  FORM = 'form',
  FORM_MESSAGE = 'formMessage',
  RECEPTION_PHONE = 'receptionPhone',
  WORKSTATION_ERROR = 'workstationError',
  COMPLIANCE_BUNDLE = 'complianceBundle',
}
