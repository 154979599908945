import {
  AppointmentCanceledIcon,
  AppointmentConfirmedIcon,
  AppointmentEditedIcon,
} from '@/ui/icons';
import {
  DpIconBin,
  DpIconMail,
  DpIconCalendarWithCheck,
  DpIconEndCall,
  DpIconSandClock,
  DpIconInfo,
} from '@dp-ui-kit/icons';
import { TaskCloseReasonTypes } from './TaskCloseReason.types';

export interface Option {
  color: 'success' | 'danger' | 'neutral' | 'gray';
  icon: any;
}

export const taskBadgeOptions: Record<TaskCloseReasonTypes, Option> = {
  [TaskCloseReasonTypes.Abandoned]: {
    color: 'neutral',
    icon: DpIconEndCall,
  },
  [TaskCloseReasonTypes.AppointmentCanceled]: {
    color: 'danger',
    icon: AppointmentCanceledIcon,
  },
  [TaskCloseReasonTypes.AppointmentConfirmed]: {
    color: 'success',
    icon: AppointmentConfirmedIcon,
  },
  [TaskCloseReasonTypes.AppointmentEdited]: {
    color: 'gray',
    icon: AppointmentEditedIcon,
  },
  [TaskCloseReasonTypes.Archived]: {
    color: 'neutral',
    icon: DpIconBin,
  },
  [TaskCloseReasonTypes.BookedOnline]: {
    color: 'success',
    icon: DpIconMail,
  },
  [TaskCloseReasonTypes.Expired]: {
    color: 'neutral',
    icon: DpIconSandClock,
  },
  [TaskCloseReasonTypes.SubjectBooked]: {
    color: 'success',
    icon: DpIconCalendarWithCheck,
  },
  [TaskCloseReasonTypes.SubjectInformational]: {
    color: 'gray',
    icon: DpIconInfo,
  },
  [TaskCloseReasonTypes.SubjectLost]: {
    color: 'danger',
    icon: AppointmentCanceledIcon,
  },
};
