import { mapActions, mapGetters } from 'vuex';
import { getErrors } from '@/api/validation-handler';
import { notificationsManager } from '@/app/notifications/NotificationsManager';
import { ErrorMessage } from '@/app/errors/ErrorMessage';
import type { ErrorResponseData } from '@/app/errors/ErrorMessage';

interface SuccessPayload {
  type: 'success';
  message?: string;
  message_key?: string;
}

interface ErrorPayload {
  data?: unknown;
  statusCode?: number;
  message?: string;
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    loading?: boolean;

    /**
     * @deprecated please use composables instead
     */
    onSuccess(payload: SuccessPayload): void;

    /**
     * @deprecated please use composables instead
     */
    onError(error: ErrorPayload): void;

    /**
     * @deprecated right not no replacement, you can write one in composables ;)
     */
    genericErrorWrapper(fn: () => Promise<unknown>): Promise<void>;
  }
}

export default {
  computed: {
    ...mapGetters({
      fieldsMapping: 'validationErrors/getFieldsMapping',
    }),
  },

  methods: {
    ...mapActions({
      setValidationErrors: 'validationErrors/SET_ERRORS_ACTION',
    }),

    onSuccess({ type, message, message_key }: SuccessPayload): void {
      if (type !== 'success') {
        return;
      }

      notificationsManager.fire({
        name: 'api-success',
        title: this.$t('notify.success'),
        message: this.$t(message_key || message || ''),
      });
    },

    onError({ statusCode, data, message }: ErrorPayload): void {
      let displayedMessage = new ErrorMessage({}, message).translation;

      if (statusCode && statusCode >= 400) {
        displayedMessage = new ErrorMessage(data as ErrorResponseData, message).translation;
      }

      if (data) {
        const errors = getErrors(data, this.fieldsMapping);
        if (errors) {
          this.setValidationErrors(errors);
          return;
        }
      }

      notificationsManager.fire({
        name: 'api-error',
        title: this.$t('error.title'),
        message: displayedMessage,
      });
    },

    async genericErrorWrapper(fn: () => Promise<unknown>): Promise<void> {
      const hasLoading = typeof this.loading === 'boolean';
      let result;

      if (hasLoading) {
        this.loading = true;
      }

      try {
        result = await fn();

        this.onSuccess({
          type: 'success',
          message_key: 'notify.changes_saved',
        });
      } catch (e) {
        this.onError(e);
      }

      if (hasLoading) {
        this.loading = false;
      }

      return result;
    },
  },
};
