import type { VoiceMenuConfigurationOption } from '@/modules/settings/domain/traffic-control/aggregates/VoiceMenuConfigurationOption';
import type { VoiceMenuConfigurationOptionsList } from '@/modules/settings/domain/traffic-control/aggregates/VoiceMenuConfigurationOptionsList';
import { ConfigurationType } from '@/modules/settings/domain/traffic-control/models/ConfigurationType';
import { FallbackMode } from '@/modules/settings/domain/traffic-control/models/FallbackMode';
import { injectable } from 'tsyringe';
import type { OutputVoiceMenuOption } from '../types/OutputVoiceMenuOption';
import type { OutputVoiceMenuOptions } from '../types/OutputVoiceMenuOptions';

@injectable()
export class OutputConfigurationOptionsFactory {
  make(configurationOptionsList: VoiceMenuConfigurationOptionsList | null): OutputVoiceMenuOptions {
    if (!configurationOptionsList) {
      return {
        options: [],
      };
    }

    const options: OutputVoiceMenuOption[] = configurationOptionsList.configurationOptions
      .map(configurationOption => {
        switch (configurationOption.type) {
          case ConfigurationType.Label:
            return this.makeLabel(configurationOption);
          case ConfigurationType.CallingGroup:
            return this.makeGroup(configurationOption);
          case ConfigurationType.VoiceMessage:
            return this.makeVoiceMessage(configurationOption);
          default:
            return null;
        }
      })
      .filter((option): option is OutputVoiceMenuOption => !!option);

    return {
      options,
    };
  }

  private makeLabel(configuration: VoiceMenuConfigurationOption): OutputVoiceMenuOption {
    return {
      id: configuration.id,
      name: configuration.label.content,
      priority: configuration.label.isStarred ? 1 : 0,
      digit: configuration.digit,
      optionType: 'label',
      // TODO maybe remove at some point
      destinations: [],
      // TODO maybe remove at some point
      type: 'reception',
    };
  }

  private makeGroup(configuration: VoiceMenuConfigurationOption): OutputVoiceMenuOption {
    const { callingGroup } = configuration;
    if (!callingGroup) {
      throw new Error('You cannot create a group configuration without "CallingGroup" defined');
    }

    const destinationType =
      callingGroup.workstationType === 'non_reception' ? 'non_reception' : 'reception';

    return {
      id: configuration.id,
      priority: configuration.label.isStarred ? 1 : 0,
      name: configuration.label.content,
      digit: configuration.digit,
      destinations: callingGroup.workstationIds,
      type: destinationType,
      destinationType,
      optionType: 'workstation',
      timeout: callingGroup.fallback.timeout,
      fallbackToReception:
        callingGroup.fallback.mode === FallbackMode.RedirectToAllReceptionWorkstations,
    };
  }

  private makeVoiceMessage(configuration: VoiceMenuConfigurationOption): OutputVoiceMenuOption {
    const { voiceMessage } = configuration;

    if (!voiceMessage) {
      throw new Error(
        'You cannot create voice message configuration option without "VoiceMessage" defined.',
      );
    }

    return {
      id: configuration.id,
      name: configuration.label.content,
      priority: configuration.label.isStarred ? 1 : 0,
      digit: configuration.digit,
      optionType: 'voice_message',
      voiceMessage: {
        type: voiceMessage.type,
        value: voiceMessage.value,
        file: voiceMessage.file.id,
      },
      // TODO maybe remove at some point
      destinations: [],
      // TODO maybe remove at some point
      type: 'reception',
    };
  }
}
