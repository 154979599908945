import type { DependencyContainer } from 'tsyringe';
import { DpEventBus } from '@/core/events/DpEventBus';
import type { DpProvider } from '@/core/di/DpProvider';
import { ConnectionFailureListener } from '../../domain/connection/ConnectionFailureListener';
import { ClientStateChanged } from '../../domain/events/ClientStateChanged';

export class ConnectionProvider implements DpProvider {
  boot(container: DependencyContainer): void {
    const eventBus = container.resolve(DpEventBus);
    const connectionFailureDetector = container.resolve(ConnectionFailureListener);

    eventBus.subscribe(ClientStateChanged.eventName, connectionFailureDetector);
  }
}
