import { inject, injectable } from 'tsyringe';
import { TaskReadRepositoryToken } from '../../../di/tokens';
import type { TaskPayload } from '../../events/TaskPayload';
import type { TaskReadRepository } from '../TaskReadRepository';
import { TaskUpdater } from '../TaskUpdater';

/**
 * Reads task payload from http repository and updates data in vuex.
 */
@injectable()
export class TaskIdQueueHandler {
  constructor(
    @inject(TaskReadRepositoryToken)
    private readonly taskReadRepository: TaskReadRepository,
    private readonly taskUpdater: TaskUpdater,
  ) {}

  async handle(taskId: string): Promise<TaskPayload> {
    const task = await this.taskReadRepository.get(taskId);
    await this.taskUpdater.update(task);
    return task;
  }
}
