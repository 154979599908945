import { injectable } from 'tsyringe';
import { ClosedReason } from './ClosedReason';
import type { TaskFragment } from './TaskFragment';

@injectable()
export class ClosedReasonFactory {
  make(taskFragment: TaskFragment): ClosedReason | null {
    switch (taskFragment.status) {
      case 'resolved':
        return this.getForSubject(taskFragment);
      case 'visit-confirmation-successful':
        return this.getForVisitConfirmation(taskFragment);
      case 'abandoned':
        return new ClosedReason('abandoned');
      case 'archived':
        return new ClosedReason('archived');
      case 'expired':
        return new ClosedReason('expired');
      case 'online-booking-conversion':
        return new ClosedReason('booked-online');
      case 'open':
        return null;
      default: {
        const exhaustiveCheck: never = taskFragment.status;
        throw new Error(`Unknown task status: ${exhaustiveCheck}`);
      }
    }
  }

  private getForSubject(taskFragment: TaskFragment): ClosedReason {
    if (!taskFragment.subjectType) {
      throw new Error('Subject type is not defined for "resolved" task');
    }

    switch (taskFragment.subjectType) {
      case 'visit':
        return new ClosedReason('subject-booked', taskFragment.subjectName);
      case 'informational':
        return new ClosedReason('subject-informational', taskFragment.subjectName);
      case 'lost-reason':
        return new ClosedReason('subject-lost', taskFragment.subjectName);
      default: {
        const exhaustiveCheck: never = taskFragment.subjectType;
        throw new Error(`Unknown subject type: ${exhaustiveCheck}`);
      }
    }
  }

  private getForVisitConfirmation(taskFragment: TaskFragment): ClosedReason | null {
    if (!taskFragment.visitConfirmationStatus) {
      throw new Error(
        'Visit confirmation status is not defined for "visit-confirmation-successful" task',
      );
    }

    switch (taskFragment.visitConfirmationStatus) {
      case 'confirmed':
        return new ClosedReason('visit-confirmed');
      case 'canceled':
        return new ClosedReason('visit-canceled');
      case 'rescheduled':
        return new ClosedReason('visit-rescheduled');
      case 'new':
        return null;
      default: {
        const exhaustiveCheck: never = taskFragment.visitConfirmationStatus;
        throw new Error(`Unknown visit confirmation status: ${exhaustiveCheck}`);
      }
    }
  }
}
