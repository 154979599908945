import { RootStoreToken } from '@/common/root';
import { $GET_ENTITIES_BY_ID } from '@dp-vue/entities';
import { inject, injectable } from 'tsyringe';
import { Subject } from '../../domain/subject/Subject';
import type { SubjectRepository } from '../../domain/subject/SubjectRepository';
import { SubjectFactory } from './SubjectFactory';
import type { StoreSubject } from './types/StoreSubject';

interface Store {
  getters: {
    [$GET_ENTITIES_BY_ID]: (entityType: 'result', ids: string[]) => StoreSubject[];
    getFacilityResults: StoreSubject[];
  };
}

@injectable()
export class StoreSubjectRepository implements SubjectRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
    private readonly subjectFactory: SubjectFactory,
  ) {}

  get(id: string): Subject {
    const [storeSubject] = this.store.getters[$GET_ENTITIES_BY_ID]('result', [id]);

    if (!storeSubject) {
      return Subject.empty;
    }

    return this.subjectFactory.make(storeSubject);
  }

  allActive(): Subject[] {
    return this.subjectFactory.makeMany(this.store.getters.getFacilityResults);
  }
}
