import { singleton } from 'tsyringe';
import type { Callback } from '../models/Callback';
import type { ChannelName } from '../models/ChannelName';

@singleton()
export class CallbackRepository {
  private callbacks: Callback[] = [];

  add(callback: Callback): void {
    if (this.getByName(callback.name)) {
      throw new Error(`You're trying to add new callback with the same name: ${callback.name}`);
    }

    this.callbacks.push(callback);
  }

  remove(callbackName: string): void {
    if (!this.getByName(callbackName)) {
      throw new Error(`Cannot find callback with name: ${callbackName}`);
    }

    this.callbacks = this.callbacks.filter(callback => callback.name !== callbackName);
  }

  get(channelName: ChannelName): Callback[] {
    return this.callbacks.filter(callback => callback.channelName === channelName);
  }

  all(): Callback[] {
    return this.callbacks;
  }

  private getByName(name: string): Callback | null {
    return this.callbacks.find(callback => callback.name === name) ?? null;
  }
}
