import { inject, injectable } from 'tsyringe';
import type { StorageItemFactory } from '@/common/root/domain/storage/models/StorageItemFactory';
import type { StorageFactory } from '@/common/root/domain/storage/services/StorageFactory';
import { RootSessionStorageFactoryToken } from '@/common/root/providers/RootStorageProvider';
import { SsoFlowState } from '../../domain/sso/login/SsoFlowState';
import type { SsoFlowRepository } from '../../domain/sso/login/SsoFlowRepository';

type StoredSsoFlowsData = Record<string, SsoFlowState>;

@injectable()
export class SessionStorageSsoFlowRepository implements SsoFlowRepository {
  private static readonly TTL = 10 * 60;

  private readonly storageBucket: StorageItemFactory<StoredSsoFlowsData>;

  constructor(@inject(RootSessionStorageFactoryToken) sessionStorageFactory: StorageFactory) {
    this.storageBucket = sessionStorageFactory.createStorageItemFactory('loginWith');
  }

  get(token: string): SsoFlowState | null {
    const item = this.storageBucket(token);

    if (!item.value) {
      return null;
    }

    const ssoFlowState = new SsoFlowState(item.value.id, item.value.redirectUri, item.value.route);

    item.clear();

    return ssoFlowState;
  }

  save(token: string, value: SsoFlowState): void {
    this.storageBucket(token).set(value, SessionStorageSsoFlowRepository.TTL);
  }
}
