import { inject, singleton } from 'tsyringe';
import type { UserRepository } from '../UserRepository';
import { Clock } from './Clock';
import type { PingRepository } from './PingRepository';
import {
  FacilityAccessCheckerToken,
  PingRepositoryToken,
  UserSessionRepositoryToken,
  UserRepositoryToken,
  UserStatusRepositoryToken,
} from '../../di/tokens';
import type { UserSessionRepository } from '../pause/UserSessionRepository';
import type { FacilityAccessChecker } from './UserFacilityAccessValidator';
import type { UserStatusRepository } from '../user-status/UserStatusRepository';
import { TimeValidator } from '../time/TimeValidator';
import type { CurrentUser } from '../CurrentUser';
import type { Ping } from './Ping';
import { NotReadyToPingError } from './NotReadyToPingError';

@singleton()
export class PingManager {
  private static maxNumberOfRetries = 3;

  private clock: Clock | null = null;

  private frequencyInSeconds = 60;

  private retries = 0;

  constructor(
    @inject(PingRepositoryToken)
    private readonly pingRepository: PingRepository,
    @inject(UserRepositoryToken)
    private readonly userRepository: UserRepository,
    @inject(UserSessionRepositoryToken)
    private readonly userSessionRepository: UserSessionRepository,
    @inject(FacilityAccessCheckerToken)
    private readonly facilityAccessChecker: FacilityAccessChecker,
    @inject(UserStatusRepositoryToken)
    private readonly userStatusRepository: UserStatusRepository,
    private readonly timeValidator: TimeValidator,
  ) {}

  start(): void {
    if (this.clock) {
      this.restart();
      return;
    }

    this.clock = new Clock(this.callback.bind(this), this.frequencyInSeconds);

    this.callback();
  }

  stop(): void {
    if (!this.clock) {
      return;
    }

    this.clock.stop();
    this.clock = null;
  }

  private restart(): void {
    this.stop();
    this.start();
  }

  private async callback(): Promise<void> {
    const user = this.userRepository.getCurrentUser();
    const isLoggedUserWithFacility = await this.isLoggedUserWithFacility(user);

    if (!isLoggedUserWithFacility) {
      this.stop();
      this.resetRetryCounter();
      return;
    }

    try {
      await this.tryPing(user);
      this.resetRetryCounter();
    } catch (error) {
      if (error instanceof NotReadyToPingError) {
        this.waitForRetry();
        return;
      }

      throw error;
    }
  }

  private async isLoggedUserWithFacility(user: CurrentUser): Promise<boolean> {
    const hasLoadedFacility = await this.facilityAccessChecker.hasLoadedFacility();
    const isLoggedUser = user.id !== null;

    return hasLoadedFacility && isLoggedUser;
  }

  private async tryPing(user: CurrentUser): Promise<void> {
    const pingData = await this.makePing(user);

    this.userStatusRepository.saveMany(pingData.statuses);
    this.timeValidator.validate(pingData.currentTime);

    if (pingData.userSession) {
      await this.userSessionRepository.save(pingData.userSession);
    }
  }

  private async makePing(user: CurrentUser): Promise<Ping> {
    const isLoggedToWorkstation = user.workstationId !== null;

    if (!isLoggedToWorkstation) {
      return this.pingRepository.noWorkstationPing();
    }

    const userSession = await this.userSessionRepository.get();

    return this.pingRepository.ping(userSession?.sessionStartDate ?? null);
  }

  private waitForRetry(): void {
    setTimeout(() => {
      this.retries += 1;
      if (this.retries <= PingManager.maxNumberOfRetries) {
        this.restart();
      }
    }, 1000 * 10);
  }

  private resetRetryCounter(): void {
    this.retries = 0;
  }
}
