import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { HydraContainer } from '@/modules/http-client/public/types';
import type { DataExportRepository } from '../domain/DataExportRepository';
import { DataExportCollection } from '../domain/DataExportCollection';
import { DataExport, DataExportType } from '../domain/DataExport';

interface File {
  url: string;
}

interface DataExportApi {
  id: string;
  type: string;
  createdAt: string;
  completedAt?: string;
  file?: File;
}

type Response = HydraContainer<DataExportApi>;

@injectable()
export class HttpDataExportRepository implements DataExportRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async paginate(page: number): Promise<DataExportCollection> {
    const results = await this.httpClient.get<Response>(
      `/api/v1/facilities/{facilityId}/data-exports`,
      {
        page,
      },
    );

    const dataExports = results['hydra:member'].map(
      (item: DataExportApi) =>
        new DataExport(item.createdAt, item.type as DataExportType, item.file?.url ?? null),
    );

    return new DataExportCollection(dataExports, results['hydra:totalItems']);
  }

  async create(type: DataExportType, from: string, to: string): Promise<void> {
    if (type === DataExportType.CallLogs) {
      await this.httpClient.post(`/api/v0/facility/{facilityId}/data-exports/call-log`, {
        from,
        to,
      });
      return;
    }
    if (type === DataExportType.Presence) {
      await this.httpClient.post(
        `/api/v0/facility/{facilityId}/export-presence-report/${from}/${to}`,
        {},
      );
      return;
    }

    throw new Error(`Not implemented type: ${type}`);
  }
}
