<template>
  <dp-nav :class="$b()" v-bind="$attrs">
    <dp-nav-item v-for="item in items" :key="item.label" :to="item.route" :class="$b('item')">
      {{ item.label }}
      <counter-badge v-if="hasCounter(item)" :class="$b('badge')" :value="getValue(item)" />
    </dp-nav-item>
  </dp-nav>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { DpNav, DpNavItem } from '@dp-ui-kit/vue';

import CounterBadge from '@/ui/atoms/CounterBadge/CounterBadge.vue';
import type { TabItem } from '@/ui/molecules/TabNavigation/TabNavigation.types';

@Component({
  name: 'TabNavigation',
  components: { CounterBadge, DpNav, DpNavItem },
})
export default class TabNavigation extends Vue {
  @Prop({ type: Array, required: true })
  readonly items: TabItem[];

  getValue(item: TabItem): number {
    if (item.count === undefined || item.count === null) {
      return 0;
    }

    return item.count;
  }

  hasCounter(item: TabItem): boolean {
    return Number.isInteger(item.count);
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables' as v;

.dp-tab-navigation {
  $this: &;

  :deep(.nav) {
    padding-bottom: 0;
  }

  &__item {
    :deep(.nav-link.router-link-active),
    &:hover :deep(.nav-link) {
      color: v.$primary-dark;

      #{$this}__badge {
        background: v.$primary;
        color: v.$white;
      }
    }

    :deep(.nav-link.router-link-active) {
      border-color: currentColor;
      font-weight: v.$font-weight-medium;
    }
  }

  &__badge {
    margin-left: v.$spacer-sm;
  }
}
</style>
