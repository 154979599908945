import type { HttpTask } from '@/modules/tasks-list/adapters/task-feed/HttpTask';
import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { TaskEntityManager } from '@/modules/tasks-list/domain/task-feed/TaskEntityManager';

interface Store {
  dispatch(method: 'UPDATE_TASK_ACTION', tasks: HttpTask[]): string[];
}

@injectable()
export class StoreTaskEntityManager implements TaskEntityManager {
  constructor(@inject(RootStoreToken) private readonly store: Store) {}

  async storeEntities(tasks: HttpTask[]): Promise<string[]> {
    return this.store.dispatch('UPDATE_TASK_ACTION', tasks);
  }
}
