import type { ActionTree } from 'vuex';
import { workingHours } from './settings/working-hours';
import { voiceMessages } from './settings/voice-messages';
import { reception as receptionModule } from './settings/reception';
import { compliance } from './settings/compliance';
import { channels } from './settings/channels';

interface SetActionPayload {
  working_hours;
  reception;
  phones;
}

const actions: ActionTree<unknown, unknown> = {
  async SET_ACTION({ dispatch }, payload: SetActionPayload) {
    const { working_hours, reception, phones } = payload;
    await dispatch('workingHours/CONVERT_AND_SET_ACTION', working_hours);
    await dispatch('reception/SET', reception);
    await dispatch('channels/SET_PHONES_ACTION', phones);
  },
};

export const settings = {
  namespaced: true,
  actions,
  modules: {
    workingHours,
    voiceMessages,
    reception: receptionModule,
    compliance,
    channels,
  },
};
