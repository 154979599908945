import type { Device } from '@twilio/voice-sdk';
import { injectable } from 'tsyringe';

@injectable()
export class TwilioCallingDeviceOptions implements Device.Options {
  readonly appName = 'PhoneWidget - Webphone';

  readonly appVersion = '2.0.0';

  readonly maxCallSignalingTimeoutMs = 0;

  readonly tokenRefreshMs = 30 * 1000; // 30 seconds

  readonly logLevel = 1;

  readonly allowIncomingWhileBusy = true;
}
