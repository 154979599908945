export enum TagType {
  VoiceMenu = 'voice-menu',
  PriorityVoiceMenu = 'priority-voice-menu',
  Channel = 'channel',
  PriorityChannel = 'priority-channel',
  MissedCall = 'missed-call',
  UnansweredCall = 'unanswered-call',
  Reminder = 'reminder',
  ReminderExpired = 'reminder-expired',
  VoiceMessage = 'voice-message',
  PatientPrescriptionRequest = 'patient-prescription-request',
  PatientRefillPrescriptionRequest = 'patient-refill-prescription-request',
  PatientCertificateRequest = 'patient-certificate-request',
  PatientTestResultsRequest = 'patient-test-results-request',
  PatientOtherRequest = 'patient-other-request',
  VisitConfirmation = 'visit-confirmation',
}

export interface Tag {
  readonly type: TagType;
  readonly content: string;
}
