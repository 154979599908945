import { $GET_ENTITIES_BY_ID } from '@dp-vue/entities';
import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { StoreTask } from '@/modules/task/adapters/task/StoreTask';
import type { TaskReadRepository } from '@/modules/task/domain/task/TaskReadRepository';
import { Task } from '@/modules/task/domain/task/Task';
import { TaskFactory } from '@/modules/task/adapters/task/TaskFactory';
import { TaskError } from '../../domain/task/TaskError';

interface Store {
  getters: {
    [$GET_ENTITIES_BY_ID]: (entityType: 'task', ids: string[]) => StoreTask[];
  };
}

@injectable()
export class StoreTaskReadRepository implements TaskReadRepository {
  constructor(
    @inject(RootStoreToken) private readonly store: Store,
    private readonly taskFactory: TaskFactory,
  ) {}

  get(id: string): Task {
    if (id === Task.newToDoId) {
      return this.taskFactory.makeEmptyToDo();
    }

    const [data] = this.store.getters[$GET_ENTITIES_BY_ID]('task', [id]);

    if (!data) {
      throw new TaskError(`We can not fetch the task for ID: ${id}`);
    }

    return this.taskFactory.make(data);
  }
}
