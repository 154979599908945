import { inject, injectable } from 'tsyringe';
import type { LanguageConfigProvider } from '@/common/root/domain/LanguageConfigProvider';
import { RootLanguageConfigToken } from '@/common/root/tokens';
import type { LocalizationRepository } from '../domain/LocalizationRepository';

@injectable()
export class CommonLocalizationRepository implements LocalizationRepository {
  constructor(
    @inject(RootLanguageConfigToken)
    private readonly languageConfigProvider: LanguageConfigProvider,
  ) {}

  get(): 'en' | 'es' | 'it' | 'pl' | 'br' | 'mx' {
    const { locale } = this.languageConfigProvider.get();

    return locale;
  }
}
