import { injectable } from 'tsyringe';
import type { CustomTrackingEvent } from '../../dto/CustomTrackingEvent';
import type { SingularEvent } from '../../../models/SingularEvent';

@injectable()
export class SingularEventConverter {
  make(singularEvent: SingularEvent): CustomTrackingEvent {
    return {
      event: 'singularEvent',
      singularEventName: singularEvent.externalName,

      // Optional fields not used right now.
      category: null,
      action: null,
      label: null,
      value: null,
    };
  }
}
