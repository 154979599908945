import { dayOfWeek } from '@/app/formatters/date/days-names';
import { isWithinInterval, parse } from 'date-fns';

function assertLeadingZero(number) {
  return number.toString().padStart(2, '0');
}

function adjustToRange(number, min, max) {
  return Math.min(Math.max(number, min), max);
}

function convertToString(minutes, fallback = null) {
  if (minutes === null || minutes === undefined) {
    return fallback;
  }

  const resultHours = adjustToRange(Math.floor(minutes / 60), 0, 23);
  const resultMinutes = adjustToRange(minutes % 60, 0, 59);

  return [resultHours, resultMinutes].map(assertLeadingZero).join(':');
}

function singleApiEntryToView({ start, end, open, siesta_start, siesta_end }) {
  const fallbackMorningDate = '06:00';
  const fallbackAfternoonDate = '20:00';
  return {
    morningStart: convertToString(start, fallbackMorningDate),
    morningEnd: convertToString(siesta_start),
    afternoonStart: convertToString(siesta_end),
    afternoonEnd: convertToString(end, fallbackAfternoonDate),
    open,
  };
}

export function apiToView(apiDates) {
  if (!Array.isArray(apiDates) || apiDates.length === 0) {
    return [];
  }

  const dayNames = Array.from(Array(7), (_, i) => dayOfWeek(i + 1));

  return dayNames.map((name, index) => ({
    name,
    ...singleApiEntryToView(apiDates[index]),
  }));
}
/**
 * Converts single working hour entry from view, to format supported in backend
 *
 * @param {workingHoursView} workingHoursView
 * @returns {workingHoursToApi}
 */
export function singleViewEntryToApi({
  morningStart,
  morningEnd,
  afternoonStart,
  afternoonEnd,
  open,
}) {
  return {
    start: morningStart,
    siesta_start: morningEnd,
    siesta_end: afternoonStart,
    end: afternoonEnd,
    open,
  };
}

function isDateWithinTimeRange(currentDate, rangeStart, rangeEnd) {
  if (!rangeStart || !rangeEnd) {
    return false;
  }

  const rangeStartDate = parse(rangeStart, 'HH:mm', currentDate);
  const rangeEndDate = parse(rangeEnd, 'HH:mm', currentDate);

  return isWithinInterval(currentDate, {
    start: rangeStartDate,
    end: rangeEndDate,
  });
}

export function isCurrentlyOpen(currentDate, workingHours) {
  const { morningStart, morningEnd, afternoonStart, afternoonEnd, open } = workingHours;

  if (!open) {
    return false;
  }

  return (
    isDateWithinTimeRange(currentDate, morningStart, afternoonEnd) &&
    isDateWithinTimeRange(currentDate, morningEnd, afternoonStart) === false
  );
}

/**
 * Format of a single working hour entry used to save it on backend. Start and end hours are kept
 * as strings in format `HH:mm`.
 *
 * @typedef workingHoursToApi
 * @property {boolean} open flag determines if facility is working during specific day
 * @property {string} start time when clinic starts to support patients
 * @property {string} end time when clinic ends to support patients
 * @property {string} [siesta_start] time when morning shift ends, for clinic having siesta
 * @property {string} [siesta_end] time when afternoon shift starts,
 * for clinic having siesta
 */
