import { UploadCare } from './UploadCare';

export { uploadDirectly } from './DirectUploadCare';

type AnyFunction = (...args: any[]) => any;

export const makeUploadCare = (
  acceptTypes: string[],
  validator: AnyFunction,
  hasPreview: boolean,
): UploadCare => new UploadCare(acceptTypes, validator, hasPreview);
