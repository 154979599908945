<template>
  <i :class="iconClass">
    <svg width="21" height="20" viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.35 7.8H12.65V13.4C12.65 13.785 12.335 14.1 11.95 14.1H3.55V14.8C3.55 15.57 4.18 16.2 4.95 16.2H11.95L14.75 19V9.2C14.75 8.43 14.12 7.8 13.35 7.8ZM11.25 11.3V6.4C11.25 5.63 10.62 5 9.85 5H2.15C1.38 5 0.75 5.63 0.75 6.4V15.5L3.55 12.7H9.85C10.62 12.7 11.25 12.07 11.25 11.3Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9353 0C16.4495 0 16.0556 0.393824 16.0556 0.87963V2.63889H14.2964C13.8106 2.63889 13.4167 3.03271 13.4167 3.51852C13.4167 4.00432 13.8106 4.39815 14.2964 4.39815H16.0556V6.15741C16.0556 6.64321 16.4495 7.03704 16.9353 7.03704C17.4211 7.03704 17.8149 6.64321 17.8149 6.15741V4.39815H19.5742C20.06 4.39815 20.4538 4.00432 20.4538 3.51852C20.4538 3.03271 20.06 2.63889 19.5742 2.63889H17.8149V0.879629C17.8149 0.393824 17.4211 0 16.9353 0Z"
      />
    </svg>
  </i>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import BaseIcon from '@dp-ui-kit/icons/vue/BaseIcon.vue';

export default defineComponent({
  name: 'BubbleAddIcon',
  extends: BaseIcon as any,
  iconName: 'bubble-add',
});
</script>
