import type { WorkstationUser } from './Workstation';

type CallStatus = 'intro' | 'waiting' | 'in-progress' | 'unanswered' | 'completed';

export class WaitingCall {
  constructor(
    readonly id: string,
    readonly status: CallStatus,
    readonly workstation?: WorkstationUser,
  ) {}

  get userId(): string | null {
    return this.workstation?.userId ?? null;
  }

  get isUserRequired(): boolean {
    return this.workstation?.mode === 'signed-in';
  }
}
