import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { RootErrorLoggerToken } from '@/common/root/providers/RootErrorLoggerProvider';
import { ErrorLoggerToken } from '@/modules/book-visit/di/token';
import type { ErrorLogger } from '@/modules/book-visit/domain/errors/ErrorLogger';

export class ErrorLoggerProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<ErrorLogger>(ErrorLoggerToken, {
      useFactory: c => c.resolve(RootErrorLoggerToken),
    });
  }
}
