export const ChannelRepositoryToken = Symbol('ChannelRepository');
export const CommentRepositoryToken = Symbol('CommentRepository');
export const AnonymizationRepositoryToken = Symbol('AnonymizationRepository');
export const PatientRepositoryToken = Symbol('PatientRepository');
export const PhoneNumberFormatterToken = Symbol('PhoneNumberFormatter');
export const EventTrackerToken = Symbol('EventTracker');
export const WorkstationRepositoryToken = Symbol('WorkstationRepository');
export const TaskCallbackToken = Symbol('TaskCallback');
export const WebphoneToken = Symbol('WebPhone');
export const TranslatorToken = Symbol('Translator');
export const ReminderTagFactoryToken = Symbol('ReminderTagFactory');
export const CallTagFactoryToken = Symbol('CallTagFactory');
export const ChannelTagFactoryToken = Symbol('ChannelTagFactory');
export const VoiceMenuTagFactoryToken = Symbol('VoiceMenuTagFactory');
export const TaskReadRepositoryToken = Symbol('TaskRepository');
export const TimelineRepositoryToken = Symbol('TimelineRepository');
export const OwnerRepositoryToken = Symbol('OwnerRepository');
export const IconListToken = Symbol('IconList');
export const WorkstationCheckerToken = Symbol('WorkstationChecker');
export const TaskUpdaterToken = Symbol('TaskUpdater');
export const AuthorizationCheckerToken = Symbol('AuthorizationChecker');
export const RouteResolverToken = Symbol('RouteResolver');
export const CallUpdaterToken = Symbol('CallUpdater');
export const SubjectModalToken = Symbol('SubjectModal');
export const SubjectRepositoryToken = Symbol('SubjectRepository');
export const TimezoneRepositoryToken = Symbol('TimezoneRepository');
export const ClockToken = Symbol('Clock');
export const OwnershipFilterTypeRepositoryToken = Symbol('OwnershipFilterTypeRepository');
export const ReminderReadRepositoryToken = Symbol('ReminderReadRepository');
export const ReminderWriteRepositoryToken = Symbol('ReminderWriteRepository');
export const DateFormatterToken = Symbol('DateFormatter');
export const DateManipulatorToken = Symbol('DateManipulator');
export const TimezoneManipulatorToken = Symbol('TimezoneManipulator');
export const VisitConfirmationRepositoryToken = Symbol('VisitConfirmationRepository');
export const UpcomingVisitRepositoryToken = Symbol('UpcomingVisitRepository');
export const VisitRenderEvaluatorToken = Symbol('VisitRenderEvaluator');
export const SettingStateToken = Symbol('LayoutSettingState');
export const SettingStorageToken = Symbol('LayoutSettingStorage');
export const ModalControllerToken = Symbol('ModalController');
export const FocusTargetToken = Symbol('FocusTarget');
export const CallQualityModalControllerToken = Symbol('CallQualityModalController');
export const TaskWriteRepositoryToken = Symbol('TaskWriteRepository');
export const ContextRepositoryToken = Symbol('ContextRepository');
export const TaskListenerFactoryToken = Symbol('TaskListenerFactory');
