import type { Locale } from 'date-fns';
import type { DateUtils, DateFunctions, DateTimeZoneFunctions } from './DateUtils';

export class DateFnsDateUtils implements DateUtils {
  constructor(
    private readonly dateFunctions: DateFunctions,
    private readonly dateTimeZoneFunctions: DateTimeZoneFunctions,
    private readonly locale: Locale,
  ) {}

  isToday(date: Date): boolean {
    return this.dateFunctions.isToday(date);
  }

  isTomorrow(date: Date): boolean {
    return this.dateFunctions.isTomorrow(date);
  }

  isYesterday(date: Date): boolean {
    return this.dateFunctions.isYesterday(date);
  }

  isThisYear(date: Date): boolean {
    return this.dateFunctions.isThisYear(date);
  }

  isMonday(date: Date): boolean {
    return this.dateFunctions.isMonday(date);
  }

  isSunday(date: Date): boolean {
    return this.dateFunctions.isSunday(date);
  }

  format(date: Date, format: string): string {
    return this.dateFunctions.format(date, format, this.options);
  }

  formatDistanceStrict(startDate: Date, endDate: Date): string {
    return this.dateFunctions.formatDistanceStrict(startDate, endDate, this.options);
  }

  formatDistanceToNowStrict(date: Date): string {
    return this.dateFunctions.formatDistanceToNowStrict(date, this.optionsWithSuffix);
  }

  startOfWeek(date: Date): Date {
    return this.dateFunctions.startOfWeek(date, this.options);
  }

  endOfWeek(date: Date): Date {
    return this.dateFunctions.endOfWeek(date, this.options);
  }

  startOfHour(date: Date): Date {
    return this.dateFunctions.startOfHour(date);
  }

  endOfHour(date: Date): Date {
    return this.dateFunctions.endOfHour(date);
  }

  startOfMonth(date: Date): Date {
    return this.dateFunctions.startOfMonth(date);
  }

  endOfMonth(date: Date): Date {
    return this.dateFunctions.endOfMonth(date);
  }

  parse(dateString: string, format: string): Date {
    return this.dateFunctions.parse(dateString, format, new Date(), this.options);
  }

  parseISO(dateString: string): Date {
    return this.dateFunctions.parseISO(dateString);
  }

  fromUnixTime(timestamp: number): Date {
    return this.dateFunctions.fromUnixTime(timestamp);
  }

  toZonedTime(date: Date, timezone: string): Date {
    return this.dateTimeZoneFunctions.toZonedTime(date, timezone);
  }

  max(dates: Date[]): Date {
    return this.dateFunctions.max(dates);
  }

  min(dates: Date[]): Date {
    return this.dateFunctions.min(dates);
  }

  getUnixTime(date: Date): number {
    return this.dateFunctions.getUnixTime(date);
  }

  private get options() {
    return {
      locale: this.locale,
      weekStartsOn: 1 as const,
    };
  }

  private get optionsWithSuffix() {
    return {
      ...this.options,
      addSuffix: true as const,
    };
  }
}
