import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { RootModule } from '@/common/root';
import { AuthorizationModule } from '@/common/authorization';
import { HttpClientModule } from '@/modules/http-client';
import { RepositoryProvider } from './providers/RepositoryProvider';
import { AuthorizationProvider } from './providers/AuthorizationProvider';

export class SettingsCallsManagementModule implements DpModule {
  static readonly namespace = Symbol('SettingsVoiceMessagesModule');

  static readonly requires = [AuthorizationModule, RootModule, HttpClientModule];

  providers(): IDpProvider[] {
    return [RepositoryProvider, AuthorizationProvider];
  }
}
