import { injectable } from 'tsyringe';
import type { MimeType, AudioExtension } from './AudioConverter';

type AudioMimeTypeDictionary = {
  [key in AudioExtension]: MimeType;
};

@injectable()
export class MimeTypesFactory {
  private static AUDIO_MIME_TYPES: AudioMimeTypeDictionary = {
    mp3: 'audio/mpeg',
    wav: 'audio/wav',
    ogg: 'audio/ogg',
    opus: 'audio/ogg; codecs=opus',
    aac: 'audio/aac',
    flac: 'audio/flac',
    m4a: 'audio/mp4',
    caf: 'audio/x-caf',
    webm: 'audio/webm',
  } as const;

  public make(format: AudioExtension): MimeType {
    return MimeTypesFactory.AUDIO_MIME_TYPES[format];
  }
}
