import type { Store } from 'vuex';
import type {
  Workstation,
  WorkstationState,
} from '@/modules/phone-widget/services/WorkstationState';
import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';

type UnwatchFunction = () => void;

type CallbackFunction = (workstation: Workstation) => void;

@injectable()
export class StoreToWorkstationStateAdapter implements WorkstationState {
  private callbackFunction: CallbackFunction | null = null;

  private unwatchFunction: UnwatchFunction | null = null;

  constructor(@inject(RootStoreToken) private readonly store: Store<unknown>) {}

  watch(callback: CallbackFunction): void {
    this.callbackFunction = callback;
    this.unwatchFunction = this.store.watch(this.detectChange, this.handleChange.bind(this));
  }

  unwatch(): void {
    this.unwatchFunction?.();
    this.unwatchFunction = null;
  }

  private detectChange(_, getters): Workstation {
    return getters['settings/reception/getCurrentUserPhone'];
  }

  private handleChange(workstation: Workstation, previousWorkstation: Workstation) {
    if (workstation?.id !== previousWorkstation?.id) {
      this.callbackFunction?.(workstation);
    }
  }
}
