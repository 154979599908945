import type { TimelineCall } from './call/TimelineCall';
import type { TimelineWhatsAppMessage } from './message/TimelineWhatsAppMessage';
import type { TimelineContactForm } from './contact-form/TimelineContactForm';
import type { TimelinePatientRequest } from './patient-request/TimelinePatientRequest';
import type { TimelineSmsMessage } from './message/TimelineSmsMessage';

export class OutputTimeline {
  constructor(
    readonly taskId: string,
    readonly hasAwaitingMessages: boolean,
    readonly events: (
      | TimelineCall
      | TimelineWhatsAppMessage
      | TimelineSmsMessage
      | TimelineContactForm
      | TimelinePatientRequest
    )[],
  ) {}
}
