import { RootStoreToken } from '@/common/root';
import type { Store } from 'vuex';
import { PROCESS_PATIENT_ACTION } from '@/store/modules/patient';
import { inject, injectable } from 'tsyringe';
import { StaticCommonEventName } from '../../domain/callbacks/StaticCommonEventName';
import type { Callback } from '../../domain/models/Callback';

@injectable()
export class PatientCallback implements Callback {
  readonly name = 'CommonPatient';

  readonly eventName = StaticCommonEventName.Patient;

  readonly channelName = 'common';

  constructor(
    @inject(RootStoreToken)
    private readonly store: Store<unknown>,
  ) {}

  handler = (patient: unknown): void => {
    this.store.dispatch(PROCESS_PATIENT_ACTION, patient);
  };
}
