export interface WaitingMusicInput {
  name: string | null;
  url: string;
}

export class WaitingMusic {
  readonly name: string | null;

  readonly url: string;

  constructor({ name, url }: WaitingMusicInput) {
    this.name = name;
    this.url = url;
  }

  static make(data: WaitingMusicInput): WaitingMusic {
    return new WaitingMusic(data);
  }
}
