import { injectable } from 'tsyringe';
import type { DpListener } from '@/core/events/DpListener';
import { EventConsoleLogger } from './EventConsoleLogger';
import type { DurationEvent } from '../models/DurationEvent';

@injectable()
export class DurationEventLogger implements DpListener {
  constructor(private readonly eventConsoleLogger: EventConsoleLogger) {}

  handle(durationEvent: DurationEvent): void {
    this.eventConsoleLogger.display(this.toDescription(durationEvent));
  }

  private toDescription({ externalName, duration, extraProperties }: DurationEvent): string {
    return `PERFORMANCE EVENT: "${externalName}" - "${duration}" - "${JSON.stringify(
      extraProperties,
    )}"`;
  }
}
