import { injectable } from 'tsyringe';
import { eachDayOfInterval, endOfDay, format, parse, startOfDay } from 'date-fns';
import type { OmniChannelStatisticsRepository } from '../../domain/omni-statistics/OmniChannelStatisticsRepository';
import type { OmniChannelStatistics } from '../../domain/omni-statistics/types/OmniChannelStatistics';
import type { InboundTasks } from '../../domain/omni-statistics/types/InboundTasks';
import type { OutboundTasks } from '../../domain/omni-statistics/types/OutboundTasks';
import type { TaskTrafficDailyRecord } from '../../domain/omni-statistics/types/TaskTrafficDailyRecord';
import type { Channel } from '../../domain/omni-statistics/types/Channel';

@injectable()
export class StaticOmniChannelStatisticsRepository implements OmniChannelStatisticsRepository {
  async get(start: string, end: string): Promise<OmniChannelStatistics> {
    const traffic = this.getDates(start, end).map((date, index) =>
      this.getRandomDailyRecord(date, index % 7),
    );

    const allTasks = traffic.reduce((acc, record) => acc + record.all, 0);
    const inbound = Math.round(Math.random() * allTasks);
    const outbound = allTasks - inbound;

    return {
      traffic,
      allTasks,
      inbound: this.inboundTasks(inbound),
      outbound: this.outboundTasks(outbound),
      booking: this.getBooking(allTasks),
      channels: this.getChannels(allTasks),
    };
  }

  private getRandomDailyRecord(date, weekDayNumber): TaskTrafficDailyRecord {
    const weekDayData = Math.round(Math.random() * 48 + 192);
    const weekendData = Math.round(Math.random() * 16 + 16);

    return {
      date,
      all: weekDayNumber < 5 ? weekDayData : weekendData,
    };
  }

  private getDates(start: string, end: string): string[] {
    const startDate = startOfDay(parse(start, 'yyyy-MM-dd', new Date()));
    const endDate = endOfDay(parse(end, 'yyyy-MM-dd', new Date()));
    const interval = eachDayOfInterval({ start: startDate, end: endDate });

    return interval.map(date => format(date, 'yyyy-MM-dd'));
  }

  private inboundTasks(total: number): InboundTasks {
    const [successful, unsuccessful, notAttempted] = this.splitIntoThreeParts(total).sort(
      (a, b) => b - a,
    );

    return {
      successful,
      unsuccessful,
      notAttempted,
    };
  }

  private outboundTasks(total: number): OutboundTasks {
    const successful = Math.round((Math.random() * 0.5 + 0.5) * total);
    const unsuccessful = total - successful;

    return {
      successful,
      unsuccessful,
    };
  }

  private getBooking(total: number): number {
    return Math.round((Math.random() * 0.25 + 0.1) * total);
  }

  private getChannels(allTasks: number): Channel[] {
    const [phone, whatsapp, form] = this.splitIntoThreeParts(allTasks);
    const getInteractions = (tasks: number) => Math.round((Math.random() * 3 + 1) * tasks);

    return [
      {
        type: 'phone',
        interactions: getInteractions(phone),
        startedTasks: phone,
      },
      {
        type: 'whatsapp',
        interactions: getInteractions(whatsapp),
        startedTasks: whatsapp,
      },
      {
        type: 'form',
        interactions: getInteractions(form),
        startedTasks: form,
      },
    ];
  }

  // AI-Generated Code
  private splitIntoThreeParts(n: number): [number, number, number] {
    if (n < 3) {
      throw new Error('Number should be at least 3');
    }

    // Generate two random points for splitting
    const part1 = Math.floor(Math.random() * (n - 2)) + 1;
    const part2 = Math.floor(Math.random() * (n - part1 - 1)) + 1;

    const parts = [part1, part2, n - part1 - part2];

    // Shuffle the parts to ensure randomness

    for (let i = parts.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [parts[i], parts[j]] = [parts[j], parts[i]];
    }

    return [parts[0], parts[1], parts[2]];
  }
}
