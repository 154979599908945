<template>
  <div :class="$b({ show: isExpanded })" tabindex="0" @click.self="blur">
    <div :class="$b('buttons')">
      <slot name="buttons" />
      <button :class="$b('close-button')" @click="close">
        <dp-icon-close color="white" :size="20" />
      </button>
    </div>
    <div :class="$b('content')" :style="{ minWidth: `${minWidth}%` }">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { DpIconClose } from '@dp-ui-kit/icons';

@Component({
  name: 'Overlay',
  emits: ['close', 'blur'],
  components: {
    DpIconClose,
  },
})
export default class Overlay extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isExpanded: boolean;

  @Prop({ type: Number, default: 0 })
  readonly minWidth: number;

  @Prop({ type: Boolean, default: true })
  readonly closeOnBlur: boolean;

  close() {
    this.$emit('close');
  }

  blur() {
    if (this.closeOnBlur) {
      this.close();
    }

    this.$emit('blur');
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;
@use 'src/assets/scss/mixins' as m;

$button-size: 2.5rem;

.dp-overlay {
  @include m.z-index(modal);

  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.75);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  overflow: hidden;

  &--show {
    opacity: 1;
    visibility: visible;
  }

  &__buttons {
    position: absolute;
    display: flex;
    top: v.$spacer * 2;
    right: v.$spacer * 2;
    flex-direction: row;
    align-items: center;
  }

  &__close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $button-size;
    height: $button-size;
    border-radius: 50%;
    background: v.$gray-500;

    &:focus {
      outline: none;
      box-shadow: v.$button-box-shadow;
    }
  }

  &__content {
    max-height: 100%;
    max-width: 100%;
    overflow-y: auto;
  }
}

.close-icon {
  @include m.z-index(modal-close);
}
</style>
