import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import type { RootState } from '@/store/types';
import type { Store } from 'vuex';
import type { AnonymizationDataRepository } from '../domain/repositories/AnonymizationDataRepository';

@injectable()
export class StoreAnonymizationDataRepository implements AnonymizationDataRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store<RootState>,
  ) {}

  isEnabled(): boolean {
    return this.store.getters.isFacilityAnonymized;
  }
}
