import { getUnixTime, parseISO } from 'date-fns';
import type { EventsListItem } from '@/modules/timeline/domain/timeline/events-list/EventsListItem';
import type { Ownable } from '@/modules/timeline/domain/owner/Ownable';
import type { Owner } from '@/modules/timeline/domain/owner/Owner';
import { Seconds } from '@/modules/timeline/domain/Seconds';
import type { Summary } from '@/modules/timeline/domain/call/summary/Summary';
import type { Clock } from '@/modules/timeline/domain/Clock';
import type { OwnerRepository } from '@/modules/timeline/domain/owner/OwnerRepository';
import { TimelineError } from '@/modules/timeline/domain/timeline/TimelineError';
import type { IsoDate } from '@/modules/timeline/domain/IsoDate';

export class Call implements EventsListItem, Ownable {
  constructor(
    readonly id: string,
    readonly sid: string,
    readonly createdAt: IsoDate,
    readonly direction: 'inbound' | 'outbound',
    readonly state: 'abandoned' | 'completed' | 'in-progress' | 'intro' | 'unanswered' | 'waiting',
    readonly owner: Owner | null = null,
    readonly updatedAt: string | null = null,
    readonly startWaitingAt: string | null = null,
    readonly answeredAt: string | null = null,
    readonly duration = 0,
    readonly hasQualityReports = false,
    readonly channelId: string | null = null,
    readonly channelName: string | null = null,
    readonly recordingUrl: string | null = null,
    readonly voicemailUrl: string | null = null,
    readonly workstationId: string | null = null,
    readonly summary: Summary | null = null,
  ) {}

  get isOutgoing(): boolean {
    return this.direction === 'outbound';
  }

  get status(): Call['state'] | 'voice-message' {
    if (this.state === 'unanswered' && this.voicemailUrl) {
      return 'voice-message';
    }

    return this.state;
  }

  get isIncoming(): boolean {
    return this.direction === 'inbound';
  }

  get isCompleted(): boolean {
    return this.status === 'completed';
  }

  get isUnanswered(): boolean {
    return this.status === 'unanswered';
  }

  get isUnsuccessful(): boolean {
    return !this.isSuccessful;
  }

  get isSuccessful(): boolean {
    return ['in-progress', 'completed'].includes(this.status);
  }

  get shouldShowRecording(): boolean {
    return (
      (this.status === 'completed' && !!this.recordingUrl) ||
      (this.status === 'voice-message' && !!this.voicemailUrl)
    );
  }

  get hasAudioAttached(): boolean {
    return !!this.recordingUrl || !!this.voicemailUrl;
  }

  get audioUrl(): string | null {
    return this.recordingUrl || this.voicemailUrl;
  }

  get hasSummary(): boolean {
    return !!this.summary;
  }

  currentDuration(clock: Clock): Seconds {
    if (this.status !== 'in-progress') {
      return Seconds.zero();
    }

    return Seconds.make(getUnixTime(clock.now()) - this.startTime);
  }

  private get startTime() {
    const startDate = this.answeredAt;

    if (!startDate) {
      throw new TimelineError('Call answering date is unknown.');
    }

    return getUnixTime(parseISO(startDate));
  }

  isMine(ownerRepository: OwnerRepository): boolean {
    return this.owner?.id === ownerRepository.getMe().id;
  }
}
