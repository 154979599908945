import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import { HttpInterceptionError } from '@/modules/http-client/public/api';
import type { HttpRequestConfig, HttpInterception } from '@/modules/http-client/public/api';

interface Store {
  getters: {
    getUserFacilityID: string;
  };
}

@injectable()
export class StoreFacilityIdHttpInterceptor implements HttpInterception {
  constructor(@inject(RootStoreToken) private readonly store: Store) {}

  async onRequest(config: HttpRequestConfig): Promise<HttpRequestConfig> {
    if (!config.url.includes('{facilityId}')) {
      return config;
    }

    return { ...config, url: this.replaceUrlToken(config.url) };
  }

  private replaceUrlToken(url: string): string {
    const facilityId = this.store.getters.getUserFacilityID;

    if (!facilityId) {
      throw new HttpInterceptionError('Facility ID is not set but used in the request URL.');
    }

    return url.replace(/{facilityId}/, facilityId);
  }
}
