import { injectable } from 'tsyringe';
import type { TaskPayload } from './TaskPayload';
import { TaskUpdatedEvent } from './TaskUpdatedEvent';

@injectable()
export class TaskUpdatedFactory {
  make(taskPayload: TaskPayload, updateTriggers: string[]): TaskUpdatedEvent {
    return new TaskUpdatedEvent(taskPayload.id, updateTriggers, taskPayload);
  }

  makeWithoutPayload(id: string, updateTriggers: string[]): TaskUpdatedEvent {
    return new TaskUpdatedEvent(id, updateTriggers);
  }
}
