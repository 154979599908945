export enum TaskCloseReasonTypes {
  Abandoned = 'abandoned',
  AppointmentCanceled = 'appointment-cancelled',
  AppointmentConfirmed = 'appointment-confirmed',
  AppointmentEdited = 'appointment-edited',
  Archived = 'archived',
  BookedOnline = 'booked-online',
  Expired = 'expired',
  SubjectBooked = 'subject-booked',
  SubjectInformational = 'subject-informational',
  SubjectLost = 'subject-lost',
}
