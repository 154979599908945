import { injectable } from 'tsyringe';
import type { Timeline } from '@/modules/task/domain/timeline/Timeline';
import type { IconStrategy } from '../types';
import type { Task } from '../../task/Task';
import { IconName } from '../types';

@injectable()
export class IncomingWaiting implements IconStrategy {
  get(task: Task, { lastCall }: Timeline): null | IconName {
    return lastCall.isWaiting && lastCall.isIncoming ? IconName.IncomingWaiting : null;
  }
}
