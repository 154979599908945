import type { Plugin, App } from 'vue';
import type { Router, RouteLocation, NavigationFailure, RouteLocationRaw } from 'vue-router';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    /**
     * Wrapper for a regular router.push, which catches and ignore errors, when user is programmatically to current route
     *
     * @param {RouteLocationRaw} route - argument passed to router.push
     */
    $safePush(route: RouteLocationRaw): Promise<NavigationFailure | void | undefined>;
    // TODO provide nice types here 😉 one day.
    /**
     * Process which eventually should redirect user to the "home" route, where "target" param is utilized.
     *
     * @param {string} [name='home'] route name
     */
    $pushToLandingRoute(name?: string): Promise<RouteLocation>;
  }
}

const defaultHomeRoute = 'home' as const;

interface SafePushPluginOptions {
  router: Router;
}

async function safePush(
  router: Router,
  route: RouteLocationRaw,
): Promise<NavigationFailure | void | undefined> {
  // https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
  try {
    return await router.push(route);
  } catch {}
  return undefined;
}

export const safePushPlugin: Plugin = {
  install(app: App, { router }: SafePushPluginOptions): void {
    if (typeof router.push !== 'function') {
      throw new Error('SafePush plugin cannot be initialized without Vue Router.');
    }

    app.config.globalProperties.$safePush = (route: RouteLocationRaw) => safePush(router, route);

    app.config.globalProperties.$pushToLandingRoute = async function pushToLandingRoute(
      name: string = defaultHomeRoute,
    ) {
      const { currentRoute } = router;

      if (!currentRoute) {
        return this.$safePush({ name });
      }

      const { target } = currentRoute.value.query;

      if (!target) {
        return this.$safePush({ name });
      }

      const shouldPreserveTarget = name !== defaultHomeRoute;

      if (shouldPreserveTarget) {
        return this.$safePush({ name, query: { target } });
      }

      return this.$safePush({ path: `${target}` });
    };
  },
};
