import type { InputWorkstation } from './types';
import { Workstation } from '../domain/Workstation';

export class WorkstationsFactory {
  static make(data: InputWorkstation): Workstation {
    return new Workstation(
      data.id,
      data.name,
      data.formatted_number,
      data.type,
      data.mode,
      data.current_user,
    );
  }
}
