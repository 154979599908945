import { RootRouterToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import type { Router } from 'vue-router';
import type { RouteResolver } from '../../domain/action/RouteResolver';

@injectable()
export class RouteResolverAdapter implements RouteResolver {
  constructor(
    @inject(RootRouterToken)
    private readonly router: Router,
  ) {}

  getRoute(name: string, params: Record<string, string>): string {
    return this.router.resolve({ name, params }).href;
  }
}
