import { inject, injectable } from 'tsyringe';
import { WorkstationReadRepositoryToken } from '../../di/token/workstation';
import type { WorkstationReadRepository } from '../../domain/workstation/WorkstationReadRepository';
import { FallbackWorkstation } from '../../domain/workstation/FallbackWorkstation';
import type { InputFallbackWorkstation } from './types/InputFallbackWorkstation';
import type { Workstation } from '../../domain/workstation/Workstation';

@injectable()
export class FallbackWorkstationFactory {
  constructor(
    @inject(WorkstationReadRepositoryToken)
    private readonly workstationRepository: WorkstationReadRepository,
  ) {}

  make(inputFallback: InputFallbackWorkstation, workstations: Workstation[]): FallbackWorkstation {
    const workstation = workstations.find(ws => ws.id === inputFallback.workstation_id);
    if (!workstation) {
      throw new Error(`Workstation with id ${inputFallback.workstation_id} not found`);
    }

    return new FallbackWorkstation(inputFallback.workstation_id, workstation);
  }

  async makeMany(inputFallbacks: InputFallbackWorkstation[]): Promise<FallbackWorkstation[]> {
    const workstations = await this.workstationRepository.get();

    return inputFallbacks.map(inputFallback => this.make(inputFallback, workstations));
  }
}
