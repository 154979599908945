import { singleton } from 'tsyringe';
import type { Policy } from './Policy';

@singleton()
export class PolicyRepository {
  private readonly policies = new Map<string, Policy>();

  add(policies: Policy[]): void {
    policies.forEach(policy => {
      if (this.policies.has(policy.name)) {
        return;
      }

      this.policies.set(policy.name, policy);
    });
  }

  find(policyName: string): Policy | undefined {
    return this.policies.get(policyName);
  }
}
