import type { DesktopNotification } from '../../domain/desktop-notification/DesktopNotification';
import type { DesktopNotificationNotifier } from '../../domain/desktop-notification/DesktopNotificationNotifier';

export class BrowserDesktopNotificationNotifier implements DesktopNotificationNotifier {
  display(notification: DesktopNotification): void {
    try {
      const desktopNotification = new Notification(notification.title, {
        body: notification.body,
      });

      desktopNotification.onclick = () => {
        window.focus();
        desktopNotification.close();
      };
    } catch (error: any) {
      if (
        error.name === 'TypeError' &&
        error.message ===
          "Failed to construct 'Notification': Illegal constructor. Use ServiceWorkerRegistration.showNotification() instead."
      ) {
        // Chrome for android does not support Notification constructor
        // https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
        return;
      }

      throw error;
    }
  }
}
