import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { FacilityBookingOptions } from '@/modules/book-visit/domain/booking/FacilityBookingOptions';
import type { PreloadedBookingOptionsRepository } from '@/modules/book-visit/domain/booking/PreloadedBookingOptionsRepository';

interface BookVisitStore {
  state: {
    bookVisit: {
      bookingOptions: FacilityBookingOptions;
    };
  };
  commit: (name: 'bookVisit/SET_BOOKING_OPTIONS', bookingOptions: FacilityBookingOptions) => void;
}

@injectable()
export class StorePreloadedBookingOptionsRepository implements PreloadedBookingOptionsRepository {
  constructor(@inject(RootStoreToken) private readonly store: BookVisitStore) {}

  get(): FacilityBookingOptions {
    return this.store.state.bookVisit.bookingOptions;
  }

  save(bookingOptions: FacilityBookingOptions): void {
    this.store.commit('bookVisit/SET_BOOKING_OPTIONS', bookingOptions);
  }
}
