import { EntityType } from '@/store/schema/entity-type';
import type { Task as NormalizedStoreTask } from '@/api/types/entities/task';
import { $GET_ENTITIES_BY_TYPE } from '@dp-vue/entities';
import type { Task } from './Task';
import type { TaskRepository } from './TaskRepository';
import type { CallRepository } from './CallRepository';

type ObjectType<T> = T extends EntityType.TASK ? NormalizedStoreTask : never;

interface RootGetters {
  [$GET_ENTITIES_BY_TYPE]: <T extends EntityType>(entityType: T) => ObjectType<T>[];
}

export class StoreTaskRepository implements TaskRepository {
  constructor(
    private readonly rootGetters: RootGetters,
    private readonly callRepository: CallRepository,
  ) {}

  all(): Task[] {
    const taskIds = this.rootGetters[$GET_ENTITIES_BY_TYPE](EntityType.TASK).map(task => task.id);

    return taskIds.map(taskId => ({
      id: taskId,
      history: this.callRepository.getByTask(taskId),
    }));
  }
}
