import { inject, injectable } from 'tsyringe';
import { SsoFlowRepositoryToken } from '@/modules/authentication/di/token';
import { SsoFlowState } from './SsoFlowState';
import { SsoClient } from '../SsoClient';
import type { SsoFlowRepository } from './SsoFlowRepository';

interface Global {
  location: {
    readonly origin: string;
    href: string;
  };
}

@injectable()
export class SsoFlowInitializer {
  constructor(
    @inject(SsoFlowRepositoryToken)
    private readonly ssoFlowRepository: SsoFlowRepository,
    private readonly global: Global,
  ) {}

  create(client: SsoClient, flowRouteName: string): void {
    const stateId = this.generateStateId();

    this.ssoFlowRepository.save(
      stateId,
      new SsoFlowState(client.id, this.redirectUri, flowRouteName),
    );

    this.global.location.href =
      `${client.authUrl}?response_type=code&scope=${encodeURIComponent(client.scope)}` +
      `&client_id=${encodeURIComponent(client.username)}` +
      `&redirect_uri=${encodeURIComponent(this.redirectUri)}` +
      `&state=${encodeURIComponent(stateId)}`;
  }

  private get redirectUri(): string {
    return `${this.global.location.origin}/#/auth`;
  }

  private generateStateId(): string {
    return Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substring(0, 5);
  }
}
