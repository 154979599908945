import type { DependencyContainer } from 'tsyringe';
import { injectable } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { StoreIntegrationRepository } from '@/modules/statistics/adapters/integration/StoreIntegrationRepository';
import { HttpTrafficDataRepository } from '@/modules/statistics/adapters/traffic/HttpTrafficDataRepository';
import {
  CallbacksDataRepositoryToken,
  ChannelsDataRepositoryToken,
  ConfirmationCallsDataRepositoryToken,
  IntegrationRepositoryToken,
  QualityOfServiceDataRepositoryToken,
  SubjectsDataRepositoryToken,
  TeamDataRepositoryToken,
  TextChannelEvaluatorToken,
  TrafficDataRepositoryToken,
} from '@/modules/statistics/di/tokens';
import { HttpCallbacksDataRepository } from '@/modules/statistics/adapters/callbacks/HttpCallbacksDataRepository';
import { HttpChannelsDataRepository } from '@/modules/statistics/adapters/channels/HttpChannelsDataRepository';
import { HttpConfirmationCallsDataRepository } from '@/modules/statistics/adapters/confirmation-calls/HttpConfirmationCallsDataRepository';
import { HttpQualityOfServiceDataRepository } from '@/modules/statistics/adapters/quality-of-service/HttpQualityOfServiceDataRepository';
import { HttpSubjectsDataRepository } from '@/modules/statistics/adapters/subjects/HttpSubjectsDataRepository';
import { HttpTeamDataRepository } from '@/modules/statistics/adapters/team/HttpTeamDataRepository';
import type { IntegrationRepository } from '@/modules/statistics/domain/integration/IntegrationRepository';
import type { CallbacksDataRepository } from '@/modules/statistics/domain/callbacks/CallbacksDataRepository';
import type { ChannelsDataRepository } from '@/modules/statistics/domain/channels/ChannelsDataRepository';
import type { ConfirmationCallsDataRepository } from '@/modules/statistics/domain/confirmation-calls/ConfirmationCallsDataRepository';
import type { QualityOfServiceDataRepository } from '@/modules/statistics/domain/quality-of-service/QualityOfServiceDataRepository';
import type { SubjectsDataRepository } from '@/modules/statistics/domain/subjects/SubjectsDataRepository';
import type { TeamDataRepository } from '@/modules/statistics/domain/team/TeamDataRepository';
import type { TrafficDataRepository } from '@/modules/statistics/domain/traffic/TrafficDataRepository';
import type { TextChannelEvaluator } from '../../domain/TextChannelEvaluator';
import { StoreTextChannelEvaluator } from '../../adapters/StoreTextChannelEvaluator';

@injectable()
export class RepositoryProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<IntegrationRepository>(
      IntegrationRepositoryToken,
      StoreIntegrationRepository,
    );

    container.register<CallbacksDataRepository>(
      CallbacksDataRepositoryToken,
      HttpCallbacksDataRepository,
    );
    container.register<ChannelsDataRepository>(
      ChannelsDataRepositoryToken,
      HttpChannelsDataRepository,
    );
    container.register<ConfirmationCallsDataRepository>(
      ConfirmationCallsDataRepositoryToken,
      HttpConfirmationCallsDataRepository,
    );
    container.register<QualityOfServiceDataRepository>(
      QualityOfServiceDataRepositoryToken,
      HttpQualityOfServiceDataRepository,
    );
    container.register<SubjectsDataRepository>(
      SubjectsDataRepositoryToken,
      HttpSubjectsDataRepository,
    );
    container.register<TeamDataRepository>(TeamDataRepositoryToken, HttpTeamDataRepository);
    container.register<TrafficDataRepository>(
      TrafficDataRepositoryToken,
      HttpTrafficDataRepository,
    );

    container.register<TextChannelEvaluator>(TextChannelEvaluatorToken, StoreTextChannelEvaluator);
  }
}
