export enum SettingMenu {
  AccessGeneralSettings = 'access general settings',
  AccessQuickReplies = 'access quick replies',
  AccessWorkingHoursSettings = 'access working hours settings',
  AccessSubjectsSettings = 'access subjects settings',
  AccessChannelsSettings = 'access channels settings',
  AccessWorkstationsSettings = 'access workstations settings',
  AccessNonReceptionWorkstationsSettings = 'access non-reception workstations settings',
  AccessVoiceIntrosSettings = 'access voice intros settings',
  AccessTrafficControl = 'access traffic control settings',
  AccessPermissionSettings = 'access permission settings',
  AccessContactList = 'access contact list',
  AccessComplianceSettings = 'access compliance settings',
  AccessDataExport = 'access data export',
}

export enum GeneralSettingsOptions {
  AccessTasksAutomation = 'access tasks automation',
}
