import { defineRule, configure } from 'vee-validate';
import * as rules from '@vee-validate/rules';
import { parse, isAfter, isBefore, isPast, subYears, isDate } from 'date-fns';
import { slice, equal } from '@/utils/functions';
import { i18n } from '@/ui/plugins/i18n';

export const FORMATTING_CHAR_REGEXP = /[ ()-]/g;
export const INTERNAL_NUMBER_REGEXP = /^\d{1,3}$/;

export function fileIsNotEmpty(fileInfo: File): void {
  if (fileInfo.size === 0) {
    throw new Error('File is empty');
  }
}

export function maxSize(sizeInBytes: number) {
  return (fileInfo: File): void => {
    if (fileInfo.size > sizeInBytes) {
      throw new Error('size');
    }
  };
}

function isPhoneNumber(phoneNumber) {
  return /^(\+\d{1,3})?[-() \d]{5,}$/.test(phoneNumber);
}

function isEmailAddress(email) {
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
  const re =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return re.test(email);
}

configure({
  generateMessage: ({ field, rule }): string => {
    const hasCustomizedErrorMessage = i18n.global.te(`validation.${field}.${rule}`);

    return hasCustomizedErrorMessage
      ? i18n.global.t(`validation.${field}.${rule?.name}`)
      : i18n.global.t(`validation.${rule?.name}`);
  },
});

defineRule('required', rules.required);

defineRule('required_if', (value, [isSelected]) => {
  if (!isSelected) {
    return true;
  }

  return isSelected && value ? true : i18n.global.t('validation.required');
});

defineRule('confirmed', (value, [target]) => {
  if (value === target) {
    return true;
  }

  return i18n.global.t('validation.passwords_not_match');
});

defineRule('min', (value, params) =>
  rules.min(value, params) ? true : i18n.global.t('validation.password_too_short'),
);

defineRule('min_len', (value, params) =>
  rules.min(value, params) ? true : i18n.global.t('validation.name_too_short'),
);

defineRule('max', (value, params) =>
  rules.max(value, params) ? true : i18n.global.t('validation.value_too_long'),
);

defineRule('valid_phone_number', value => {
  if (!value) {
    return true;
  }

  return isPhoneNumber(value) ? true : i18n.global.t('validation.phone_number.valid');
});

defineRule('used_number', (value = '', data) => {
  const { sourceItem, fieldToCompare, usedNumbers } = data;
  const rawNumber = value.replace(FORMATTING_CHAR_REGEXP, '');
  if (rawNumber === sourceItem?.[fieldToCompare].replace(FORMATTING_CHAR_REGEXP, '')) {
    return true;
  }

  // compare only up to the length of the new value, but at least 8 characters:
  const shortedExpectedPhoneNumber = 8;
  const trimLength = Math.max(shortedExpectedPhoneNumber, value.length);
  return (
    !usedNumbers
      // only check if used numbers end in the same value as the new one
      .map(slice(-trimLength))
      .includes(rawNumber)
      ? true
      : i18n.global.t('validation.phone_number.number_used')
  );
});

defineRule('unique_name', (value, { sourceItem, fieldName, usedNames }) => {
  if (value === sourceItem?.[fieldName]) {
    return true;
  }
  return usedNames.every(item => item.toLowerCase() !== value.toLowerCase())
    ? true
    : i18n.global.t('validation.name.name_used');
});

defineRule('valid_email', value => {
  if (!value) {
    return true;
  }

  return isEmailAddress(value) ? true : i18n.global.t('validation.email_required');
});

defineRule('laterThan', (value, [deadline]) => {
  if (!value) {
    return true;
  }

  const checkedDate = parse(value || '', 'HH:mm', new Date());
  const deadlineDate = parse(deadline || '', 'HH:mm', new Date());

  return isAfter(checkedDate, deadlineDate) ? true : i18n.global.t('validation.too_early');
});

defineRule('laterThanAllOf', (value, deadlines) => {
  const checkedDate = parse(value || '', 'HH:mm', new Date());
  const deadlineDates = deadlines
    .filter(deadline => !!deadline)
    .map(deadline => parse(deadline || '', 'HH:mm', new Date()));
  return deadlineDates.every(deadlineDate => isAfter(checkedDate, deadlineDate))
    ? true
    : i18n.global.t('validation.too_early');
});

defineRule('unique_email_with_role', (value, { role, sourceItem, emailsWithRoles }) => {
  if (!value) {
    return true;
  }

  if (sourceItem.email === value && sourceItem.role === role) {
    return true;
  }

  return !emailsWithRoles.includes(`${value}${role}`)
    ? true
    : i18n.global.t('validation.role_with_email_used');
});

defineRule('unique_phone_number_with_role', (value, { role, sourceItem, phonesWithRoles }) => {
  if (!value) {
    return true;
  }

  if (sourceItem.phoneNumber === value && sourceItem.role === role) {
    return true;
  }

  return !phonesWithRoles.includes(`${value}${role}`)
    ? true
    : i18n.global.t('validation.role_with_phone_number_used');
});

defineRule('unique_role_with_email', (value, { email, sourceItem, emailsWithRoles }) => {
  if (!email) {
    return true;
  }

  if (sourceItem.role === value && sourceItem.email === email) {
    return true;
  }

  return !emailsWithRoles.includes(`${email}${value}`)
    ? true
    : i18n.global.t('validation.role_with_email_used');
});

defineRule(
  'unique_role_with_phone_number',
  (value, { phoneNumber, sourceItem, phonesWithRoles }) => {
    if (!phoneNumber) {
      return true;
    }

    if (sourceItem.role === value && sourceItem.phoneNumber === phoneNumber) {
      return true;
    }

    return !phonesWithRoles.includes(`${phoneNumber}${value}`)
      ? true
      : i18n.global.t('validation.role_with_phone_number_used');
  },
);

defineRule('isPast', value => {
  if (!isDate(value)) {
    throw new Error(`Provided value: "${value}" is not a date`);
  }

  return isPast(value) ? true : i18n.global.t('validation.select_earlier_date');
});

defineRule('isAdult', value => {
  if (!isDate(value)) {
    throw new Error(`Provided value: "${value}" is not a date`);
  }
  const today = new Date();

  return isBefore(value, subYears(today, 18))
    ? true
    : i18n.global.t('validation.select_earlier_date');
});

defineRule('isValidPrefix', value =>
  /^\d{2,3}$/.test(value) ? true : i18n.global.t('validation.invalid_prefix'),
);

defineRule('isAlreadyUsed', (value, { options }) => {
  const duplicates = options.filter(equal(value));

  return duplicates.length < 2 ? true : i18n.global.t('validation.option_used');
});

defineRule('maxListSize', (value, [max]) => {
  const items = value.split('\n').filter(item => !!item);

  return items.length <= max
    ? true
    : i18n.global.t('tasks_list.create_task.phone_number.input.error.max_size', {
        max,
      });
});

defineRule('maxLineLength', (value, [max]) => {
  const items = value.split('\n').filter(item => !!item);

  return items.every(item => item.length <= max)
    ? true
    : i18n.global.t('tasks_list.create_task.phone_number.input.error.oneliner');
});

defineRule('phoneNumberLines', value => {
  const items = value.split('\n').filter(item => !!item);

  const firstInvalidPhoneNumber = items.find(item => !isPhoneNumber(item));

  return firstInvalidPhoneNumber === undefined
    ? true
    : i18n.global.t('tasks_list.create_task.phone_number.input.error.invalid_number', {
        phone: firstInvalidPhoneNumber,
      });
});
