import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { FallbackWorkstation } from '../../domain/workstation/FallbackWorkstation';
import type { FallbackWorkstationRepository } from '../../domain/workstation/FallbackWorkstationRepository';
import { FallbackWorkstationFactory } from './FallbackWorkstationFactory';
import type { InputFallbackWorkstation } from './types/InputFallbackWorkstation';

@injectable()
export class HttpFallbackWorkstationRepository implements FallbackWorkstationRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly fallbackWorkstationFactory: FallbackWorkstationFactory,
  ) {}

  async get(): Promise<FallbackWorkstation[]> {
    const response = await this.httpClient.get<InputFallbackWorkstation[]>(
      '/api/v0/facility/{facilityId}/settings/reception/fallback',
    );

    return this.fallbackWorkstationFactory.makeMany(response);
  }

  async add(workstationId: string): Promise<FallbackWorkstation[]> {
    const response = await this.httpClient.post<InputFallbackWorkstation[]>(
      '/api/v0/facility/{facilityId}/settings/reception/fallback',
      {
        workstationId,
      },
    );

    return this.fallbackWorkstationFactory.makeMany(response);
  }

  async delete(workstationId: string): Promise<FallbackWorkstation[]> {
    const response = await this.httpClient.delete<InputFallbackWorkstation[]>(
      `/api/v0/facility/{facilityId}/settings/reception/fallback/${workstationId}`,
    );

    return this.fallbackWorkstationFactory.makeMany(response);
  }
}
