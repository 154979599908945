import { schema } from 'normalizr';
import type { UUID } from '@/types/commons';
import { EntityType } from './entity-type';
import { userSchema } from './user';

export const TYPES = Object.freeze({
  PSTN: 'pstn',
  CLOUD_WEB: 'web',
  CLOUD_MOBILE: 'mobile',
  SIP: 'sip',
});

export const INPUT_NAMES = Object.freeze({
  TYPE: 'type',
  NAME: 'name',
  FORMATTED_NUMBER: 'formatted_number',
  INTERNAL_NUMBER: 'internal_number',
  MODE: 'mode',
});

export const MODES = Object.freeze({
  ALWAYS: 'always',
  SIGNED_IN: 'signed-in',
  BACKUP: 'backup',
  NEVER: 'never',
});

export interface BaseWorkstation {
  id: UUID;
  name: string;
}

/**
 * @typedef {Object} Phone
 * @property {string} id
 * @property {string} formatted_number number with spaces or other non number characters
 * @property {string} internal_number
 * @property {string} name
 * @property {string} number phone number
 * @property {number} weight
 * @property {string} mode one of modes
 * @property {string} type
 * @property {object} [sip] only for `sip` type
 */
export const workstationSchema = new schema.Entity(EntityType.RECEPTION_PHONE, {
  current_user: userSchema,
});
