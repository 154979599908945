<template>
  <button :class="$b()" type="button" @click="$emit('click')">
    <slot />
  </button>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator';

@Component({
  name: 'InputButton',
  emits: ['click'],
})
export default class InputButton extends Vue {}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/mixins' as m;
@use '@/assets/scss/variables' as v;

.dp-input-button {
  @include m.custom-input;

  color: v.$gray-500;
  text-align: left;
  outline: none;
  border: none;

  &:hover {
    background: v.$gray-100;
    color: v.$gray-600;
  }
}
</style>
