import { ChannelTag } from '@/modules/task/domain/channel/ChannelTag';

export class Channel {
  private constructor(
    readonly id: string,
    readonly name: string,
    readonly hasPriority: boolean,
  ) {}

  getTag(): ChannelTag {
    return ChannelTag.make(this.name, this.hasPriority);
  }

  static make(id: string, name: string, hasPriority: boolean): Channel {
    return new Channel(id, name, hasPriority);
  }

  static makeEmpty(): Channel {
    return new Channel('', '', false);
  }
}
