import type { Tag } from '@/modules/task/domain/tag/Tag';
import { TagType } from '@/modules/task/domain/tag/Tag';

export class ChannelTag implements Tag {
  private constructor(
    readonly content: string,
    readonly type: TagType,
  ) {}

  static make(channelName: string, hasPriority: boolean): ChannelTag {
    return new ChannelTag(channelName, hasPriority ? TagType.PriorityChannel : TagType.Channel);
  }
}
