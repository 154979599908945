import type { DpProvider } from '@/core/di/DpProvider';
import type { StorageFactory } from '@/common/root/domain/storage/services/StorageFactory';
import { RootLocalStorageFactoryToken } from '@/common/root/providers/RootStorageProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { CachedStateFactoryToken } from '@/modules/tasks-list/di/tokens';

@injectable()
export class LocalStorageProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register(CachedStateFactoryToken, {
      useFactory(c: DependencyContainer) {
        return c
          .resolve<StorageFactory>(RootLocalStorageFactoryToken)
          .createStorageItemFactory('TaskListModule');
      },
    });
  }
}
