import { injectable } from 'tsyringe';
import { UserApi } from '@/modules/user/public/api/UserApi';
import type { CurrentUserRepository } from '../../domain/feedback-form/CurrentUserRepository';
import { User } from '../../domain/feedback-form/User';

@injectable()
export class UserApiCurrentUserRepository implements CurrentUserRepository {
  constructor(private readonly api: UserApi) {}

  async get(): Promise<User> {
    const user = this.api.getCurrentUser();
    const role = user.isManager ? 'manager' : 'employee';

    return new User(user.name, user.email, user.phoneNumber, role);
  }
}
