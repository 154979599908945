import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { StoreWaitingCallWatcher } from '../../adapters/StoreWaitingCallWatcher';

export class StoreProvider implements DpProvider {
  boot(container: DependencyContainer): void {
    const storeWatcher = container.resolve(StoreWaitingCallWatcher);

    storeWatcher.watch();
  }
}
