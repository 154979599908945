import type { User } from './User';

type WorkstationType = 'pstn' | 'web' | 'mobile' | 'sip';
type WorkstationMode = 'always' | 'signed-in' | 'backup' | 'never';

export class Workstation {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly formattedNumber: string,
    readonly type: WorkstationType,
    readonly mode: WorkstationMode,
    readonly currentUser?: User,
  ) {}
}
