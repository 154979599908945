export enum TileButtonColors {
  Danger = 'danger',
  Light = 'light',
  Success = 'success',
}

export enum TileButtonStatusTypes {
  Danger = 'danger',
  Light = 'light',
  Success = 'success',
  White = 'white',
}
