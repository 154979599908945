import type { DpProvider } from '@/core/di/DpProvider';
import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import type { Store } from 'vuex';
import { panels, panelStoreName } from '../../ui/store/panels';
import { webphone, webphoneStoreName } from '../../ui/store/webphone';

@injectable()
export class StoreProvider implements DpProvider {
  constructor(@inject(RootStoreToken) private readonly store: Store<unknown>) {}

  boot(): void {
    this.store.registerModule(panelStoreName, panels);
    this.store.registerModule(webphoneStoreName, webphone);
  }
}
