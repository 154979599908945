import { injectable } from 'tsyringe';

@injectable()
export class RefreshingOptions {
  /**
   * 2 hours
   */
  readonly tokenLifetime = 2 * 60 * 60;

  /**
   * Refresh 15 minute before expiration.
   */
  readonly refreshInterval = this.tokenLifetime - 15 * 60;
}
