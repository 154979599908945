export const NotificationSettingRepositoryToken = Symbol('NotificationSettingRepository');
export const DesktopNotificationNotifierToken = Symbol('DesktopNotificationNotifier');
export const DesktopNotificationPermissionToken = Symbol('DesktopNotificationPermission');
export const DesktopNotificationDescriptionFactoryToken = Symbol(
  'DesktopNotificationDescriptionFactory',
);
export const CallRepositoryToken = Symbol('CallRepository');
export const UserRepositoryToken = Symbol('UserRepository');
export const MessageRepositoryToken = Symbol('MessageRepository');
export const SoundNotificationRepositoryToken = Symbol('SoundNotificationRepository');
