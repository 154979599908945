export type FormatterFunction = (phoneNumber: string) => string;

export class ObfuscatePhoneNumberCreator {
  constructor(private readonly phoneNumberFormatter: FormatterFunction) {}

  create(phoneNumber: string): string {
    const formattedNumber = this.phoneNumberFormatter(phoneNumber);

    const pattern = /(\+48|\+34|\+39|\+55|\+52|\+1)?(.*)(\d{2})/;

    const [, countryCode = '', numbersToHide, lastTwoNumbers] =
      pattern[Symbol.match](formattedNumber) ?? [];

    const hiddenNumbers = numbersToHide.replace(/\d/g, '*');

    return `${countryCode}${hiddenNumbers}${lastTwoNumbers}`;
  }
}
