import { injectable } from 'tsyringe';
import type { DpListener } from '@/core/events/DpListener';
import { BookingOptionsPreloaderGate } from '@/modules/book-visit/domain/facility/BookingOptionsPreloaderGate';

@injectable()
export class TaskFeedFetchedListener implements DpListener {
  constructor(private readonly bookingOptionsPreloaderGate: BookingOptionsPreloaderGate) {}

  async handle(): Promise<void> {
    this.bookingOptionsPreloaderGate.markTaskFeedFetched();
    await this.bookingOptionsPreloaderGate.tryPreload();
  }
}
