import { injectable } from 'tsyringe';
import type { Media } from '@/modules/timeline/domain/message-composer/message/media/Media';
import { TimelineMedia } from './TimelineMedia';

@injectable()
export class OutputTimelineMediaFactory {
  makeMany(medias: Media[]): TimelineMedia[] {
    return medias.map(
      media => new TimelineMedia(media.id, media.name, media.url, media.contentType),
    );
  }
}
