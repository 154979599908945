import { eventTracker } from '@/common/tracking-event/public/api';

export type BusinessEventComposable = (
  eventName: string,
  eventProperties?: Record<string, string | number | boolean>,
) => void;

export function useBusinessEvent(): BusinessEventComposable {
  const sendBusinessEvent = (
    eventName: string,
    eventProperties?: Record<string, string | number | boolean>,
  ) => eventTracker().publishBusinessEvent(eventName, eventProperties);

  return sendBusinessEvent;
}
