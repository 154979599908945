import { compose, prop, equal } from '@/utils/functions';
import { onboarding } from './wizard/onboarding';
import { createFacility } from './wizard/create-facility';

const initialState = {
  stepIndex: 0,
  nextStepEnabled: false,
  stepsContent: [],
};

const componentsMap = new Map();

const state = {
  ...initialState,
};

const getters = {
  stepIndex: ({ stepIndex }) => stepIndex,
  nextStepEnabled: ({ nextStepEnabled }, getters) => {
    if (!getters.currentStep?.hasCustomValidation) {
      return true;
    }

    return nextStepEnabled;
  },
  stepsContent: ({ stepsContent }) =>
    stepsContent.map(({ componentName, ...rest }) => ({
      componentName,
      component: componentsMap.get(componentName),
      ...rest,
    })),
  currentStep: ({ stepsContent, stepIndex }) => stepsContent[stepIndex],
  isLastStep: ({ stepsContent, stepIndex }) => stepsContent.length === stepIndex + 1,
};

const mutations = {
  SET_STEP_INDEX(state, value) {
    state.stepIndex = value;
  },

  SET_NEXT_STEP_ENABLED(state, value) {
    state.nextStepEnabled = value;
  },

  SET_STEPS_CONTENT(state, value) {
    state.stepsContent = value;
  },

  RESET_STATE(state) {
    Object.entries(initialState).forEach(([key, value]) => {
      state[key] = value;
    });
  },

  GO_TO_NEXT_STEP(state) {
    const isLast = state.stepsContent.length === state.stepIndex + 1;

    if (isLast) {
      return;
    }

    state.stepIndex += 1;
  },
};

const actions = {
  SET_STEP_INDEX_ACTION({ commit }, value) {
    commit('SET_STEP_INDEX', value);
  },

  SET_NEXT_STEP_ENABLED_ACTION({ commit }, value) {
    commit('SET_NEXT_STEP_ENABLED', value);
  },

  SET_STEPS_CONTENT_ACTION({ commit }, stepsConfiguration) {
    const stepsContent = stepsConfiguration.map(({ component, componentName, ...rest }) => {
      componentsMap.set(componentName, component);
      return {
        componentName,
        ...rest,
      };
    });
    commit('SET_STEPS_CONTENT', stepsContent);
  },

  RESET_FLAGS_ACTION({ commit }) {
    commit('SET_NEXT_STEP_ENABLED', false);
  },

  RESET_STATE_ACTION({ commit }) {
    commit('RESET_STATE');
  },

  GO_TO_NEXT_STEP_ACTION({ commit }) {
    commit('GO_TO_NEXT_STEP');
  },

  CLOSE_WIZARD_ACTION({ commit }) {
    commit('RESET_STATE');
  },

  GO_TO_STEP_ACTION({ commit, getters }, stepName) {
    const index = getters.stepsContent.findIndex(compose(equal(stepName), prop('componentName')));

    if (index > 0) {
      commit('SET_STEP_INDEX', index);
    }
  },
};

export const wizard = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    onboarding,
    createFacility,
  },
};
