import { RootStoreToken } from '@/common/root';
import { $FILTER_ENTITIES_BY, $GET_ENTITIES_BY_ID } from '@dp-vue/entities';
import { inject, injectable } from 'tsyringe';
import type { OwnerId } from '@/modules/task/domain/owner/OwnerId';
import type { Workstation } from '../../domain/workstation/Workstation';
import type { WorkstationRepository } from '../../domain/workstation/WorkstationRepository';
import type { StoreWorkstation } from './types/StoreWorkstation';
import { WorkstationFactory } from './WorkstationFactory';

interface Store {
  getters: {
    [$GET_ENTITIES_BY_ID]: (type: 'receptionPhone', ids: string[]) => StoreWorkstation[];
    [$FILTER_ENTITIES_BY]: (
      type: 'receptionPhone',
      key: keyof StoreWorkstation,
      ids: string[],
    ) => StoreWorkstation[];
  };
}

@injectable()
export class StoreWorkstationRepository implements WorkstationRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
    @inject(WorkstationFactory)
    private readonly workstationFactory: WorkstationFactory,
  ) {}

  get(workstationId: string): Workstation {
    if (!workstationId) {
      return this.workstationFactory.makeEmpty();
    }

    const [storeWorkstation] = this.store.getters[$GET_ENTITIES_BY_ID]('receptionPhone', [
      workstationId,
    ]);

    return this.workstationFactory.tryMake(storeWorkstation);
  }

  getByUserId(userId: OwnerId): Workstation {
    const storeWorkstation = this.store.getters[$FILTER_ENTITIES_BY](
      'receptionPhone',
      'current_user',
      [userId.value],
    )[0];

    return this.workstationFactory.tryMake(storeWorkstation);
  }
}
