import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import { TextChannel } from '../../domain/text-channel/TextChannel';
import type { TextChannelRepository } from '../../domain/text-channel/TextChannelRepository';

interface Store {
  getters: {
    getUserFacility: {
      channels: {
        has_text_channel: boolean;
        type: 'whatsapp' | 'sms';
      };
    };
  };
}

@injectable()
export class StoreTextChannelRepository implements TextChannelRepository {
  constructor(@inject(RootStoreToken) private readonly rootStore: Store) {}

  find(): TextChannel | null {
    const { channels } = this.rootStore.getters.getUserFacility;

    return channels.has_text_channel ? new TextChannel(channels.type) : null;
  }
}
