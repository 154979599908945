import type { Workstation as StoreWorkstation } from '@/api/types/entities/workstation';
import { Workstation } from '@/modules/phone-widget/connection/Workstation';
import { Personnel } from '@/modules/phone-widget/connection/Personnel';

export class WorkstationFactory {
  tryMake(workstation: StoreWorkstation | null): Workstation | null {
    return workstation ? this.make(workstation) : null;
  }

  make(workstation: StoreWorkstation): Workstation {
    return new Workstation(
      workstation.id,
      workstation.name,
      workstation.current_user && new Personnel(workstation.current_user.name),
      workstation.internal_number,
    );
  }
}
