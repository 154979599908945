import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { TaskAutomationStatusRepository } from '../../domain/task-automation/TaskAutomationStatusRepository';
import type { TaskAutomationStatus } from '../../domain/task-automation/TaskAutomationStatus';
import { HttpTaskAutomationStatusFactory } from './HttpTaskAutomationStatusFactory';

@injectable()
export class HttpTaskAutomationStatusRepository implements TaskAutomationStatusRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async get(): Promise<TaskAutomationStatus> {
    return HttpTaskAutomationStatusFactory.make(
      await this.httpClient.get('/api/v0/facility/{facilityId}/settings/task-automation'),
    );
  }

  async enable(): Promise<TaskAutomationStatus> {
    return HttpTaskAutomationStatusFactory.make(
      await this.httpClient.put('/api/v0/facility/{facilityId}/settings/task-automation'),
    );
  }

  async disable(): Promise<TaskAutomationStatus> {
    return HttpTaskAutomationStatusFactory.make(
      await this.httpClient.delete('/api/v0/facility/{facilityId}/settings/task-automation'),
    );
  }
}
