<template>
  <i :class="iconClass">
    <!-- eslint-disable max-len vue/max-attributes-per-line -->
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.66612 0.255615H12.3328C13.0661 0.255615 13.6661 0.855615 13.6661 1.58895V6.18074L13.6624 6.18445L9.62366 10.2232V9.54569C9.62366 8.47513 8.75579 7.60726 7.68522 7.60726C6.61465 7.60726 5.74679 8.47513 5.74679 9.54569V10.9223H2.99945L1.57948 13.4072C1.24022 14.001 0.333659 13.76 0.333984 13.0762L0.339455 1.58895C0.339455 0.855615 0.932788 0.255615 1.66612 0.255615ZM5.53359 1.56372L5.32446 4.04967L2.90032 3.47888L2.44153 4.89433L4.12465 5.60487C4.46982 5.25577 4.8556 4.95135 5.27979 4.69123C5.8544 4.33799 6.35591 4.10435 7.20867 3.81188L7.01985 1.56372H5.53359ZM3.30885 7.80484L4.96339 8.869C5.95423 6.84823 7.84664 5.35139 10.1143 4.90472L9.65346 3.48521C6.90227 3.79588 4.56153 5.46333 3.30885 7.80484ZM6.74626 14.9051C6.74626 15.4234 7.16641 15.8435 7.68469 15.8435H13.0434C13.5617 15.8435 13.9819 15.4234 13.9819 14.9051C13.9819 14.3868 13.5617 13.9666 13.0434 13.9666H9.94906L15.6963 8.21936C16.0628 7.85288 16.0628 7.25869 15.6963 6.89221C15.3298 6.52573 14.7357 6.52573 14.3692 6.89221L8.62333 12.6381V9.54635C8.62333 9.02806 8.20318 8.60791 7.6849 8.60791C7.16661 8.60791 6.74646 9.02806 6.74646 9.54635V14.8853L6.74626 14.9051Z"
      />
    </svg>
    <!-- eslint-enable max-len vue/max-attributes-per-line -->
  </i>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import BaseIcon from '@dp-ui-kit/icons/vue/BaseIcon.vue';

export default defineComponent({
  name: 'DpChatInbound',
  extends: BaseIcon,
  iconName: 'dp-chat-inbound',
});
</script>
