<template>
  <div :class="$b()">
    <div :class="$b('sidebar-wrapper')">
      <div :class="$b('sidebar')">
        <slot name="sidebar" />
      </div>
    </div>
    <main :class="$b('main')">
      <slot name="view">
        <router-view />
      </slot>
    </main>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator';

@Component({
  name: 'LayoutWithSidebar',
})
export default class LayoutWithSidebar extends Vue {}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;
@use 'src/assets/scss/mixins/printable' as p;

$navigation-width: 250px;
$top-space: v.$spacer-lg;
$column-gap: v.$spacer-lg * 2;

.dp-layout-with-sidebar {
  display: grid;
  grid-template-columns: $navigation-width 1fr;
  column-gap: $column-gap;
  margin-top: $top-space;
  padding: 0 v.$spacer-lg 0 v.$spacer-sm;

  @media print {
    grid-template-columns: 1fr;
  }

  &__sidebar-wrapper {
    position: relative;
  }

  &__sidebar {
    position: sticky;
    top: v.$header-height;
    padding-top: $top-space;
    margin-top: -#{$top-space};

    @include p.printable(none);
  }

  &__main {
    margin-bottom: v.$spacer * 6;
    overflow: visible;

    @media print {
      margin-bottom: 0;
    }
  }
}
</style>
