import type { DpEvent } from '@/core/events/DpEvent';
import type { DeviceCall } from '../models/DeviceCall';

export class ActiveCallChanged implements DpEvent {
  static readonly eventName = 'PhoneWidget:ActiveCallChanged';

  readonly name = ActiveCallChanged.eventName;

  constructor(readonly call: DeviceCall) {}
}
