import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { TextChannelRepositoryToken } from '@/modules/settings/di/token';
import { CacheTextChannelRepository } from '@/modules/settings/adapters/channels/CacheTextChannelRepository';
import type { TextChannelRepository } from '@/modules/settings/domain/channels/TextChannelRepository';
import { DpEventBus } from '@/core/events/DpEventBus';
import { FacilityLoadedEvent } from '@/modules/facility/public/events';
import { FacilityLoadedListener } from '@/modules/settings/adapters/FacilityLoadedListener';

@injectable()
export class ChannelsProvider implements DpProvider {
  constructor(
    private readonly eventBus: DpEventBus,
    private readonly facilityLoadedListener: FacilityLoadedListener,
  ) {}

  boot(): void {
    this.eventBus.subscribe(FacilityLoadedEvent.eventName, this.facilityLoadedListener);
  }

  register(container: DependencyContainer): void {
    container.register<TextChannelRepository>(
      TextChannelRepositoryToken,
      CacheTextChannelRepository,
    );
  }
}
