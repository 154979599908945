import { DpEventBus } from '@/core/events/DpEventBus';
import { inject, singleton } from 'tsyringe';
import { ConnectionState } from '@/common/real-time-communication/domain/connection/ConnectionState';
import { ClientFactoryToken } from '../di/tokens';
import { ClientStateChanged } from './events/ClientStateChanged';
import type { ClientFactory } from './ClientFactory';
import type { Callback } from './models/Callback';
import type { Client } from './models/Client';

@singleton()
export class ClientManager {
  private client: Client | null = null;

  constructor(
    @inject(ClientFactoryToken)
    private readonly clientFactory: ClientFactory,
    private readonly eventBus: DpEventBus,
  ) {}

  create(): void {
    if (this.client) {
      this.client = this.clientFactory.change(this.client);
      return;
    }
    this.client = this.clientFactory.make();
    this.client.connection.bind('state_change', ({ current, previous }) => {
      this.eventBus.publish(new ClientStateChanged(new ConnectionState(current, previous)));
    });
  }

  destroy(): void {
    this.client?.disconnect();
    this.client = null;
  }

  bindCallback(callback: Callback, channelId: string): void {
    if (!this.client) {
      return;
    }

    this.client.bind(callback, channelId);
  }

  unbindCallback(callback: Callback, channelId: string): void {
    if (!this.client) {
      return;
    }

    this.client.unbind(callback, channelId);
  }
}
