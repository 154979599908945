import { inject, injectable } from 'tsyringe';
import { CallingDeviceFactoryToken, ClockToken } from '../../../di/tokens';
import type { Clock } from './Clock';
import { TokenGenerator } from './TokenGenerator';
import { RefreshableCallingDevice } from './RefreshableCallingDevice';
import { RefreshingOptions } from './RefreshingOptions';
import type { CallingDeviceFactory } from './CallingDeviceFactory';
import { ContextRepository } from '../errors/ContextRepository';

@injectable()
export class RefreshableCallingDeviceFactory {
  constructor(
    @inject(CallingDeviceFactoryToken)
    private readonly callingDeviceFactory: CallingDeviceFactory,
    @inject(ClockToken)
    private readonly clock: Clock,
    private readonly tokenGenerator: TokenGenerator,
    private readonly refreshingOptions: RefreshingOptions,
    private readonly contextRepository: ContextRepository,
  ) {}

  async make(): Promise<RefreshableCallingDevice> {
    const initialToken = await this.tokenGenerator.get(this.refreshingOptions.tokenLifetime);
    const device = this.callingDeviceFactory.make(initialToken);
    this.contextRepository.setNewTokenTime();

    return new RefreshableCallingDevice(
      device,
      this.clock,
      this.tokenGenerator,
      this.refreshingOptions,
      this.contextRepository,
    );
  }
}
