import type { FunctionDirective } from 'vue';
import { sanitizeHtml } from '@/utils/sanitize-html';

function assertValue(value) {
  if (typeof value !== 'string') {
    throw new Error('v-sanitize-html value should be a string');
  }
}

export const sanitizeHtmlDirective: FunctionDirective<HTMLElement> = (el, binding) => {
  assertValue(binding.value);

  el.innerHTML = sanitizeHtml(binding.value);
};
