export class ConnectionState {
  constructor(
    readonly value: string,
    readonly previousValue: string,
  ) {}

  get isConnected(): boolean {
    return this.value === 'connected';
  }

  get hasConnectionIssues(): boolean {
    return (
      this.hasTemporaryConnectionIssues || this.hasFailedConnection || this.hasIncorrectChannel
    );
  }

  private get hasTemporaryConnectionIssues(): boolean {
    return this.previousValue === 'connected' && this.value === 'connecting';
  }

  private get hasFailedConnection(): boolean {
    return this.previousValue === 'connecting' && this.value === 'unavailable';
  }

  private get hasIncorrectChannel(): boolean {
    return this.value === 'failed';
  }
}
