import { injectable } from 'tsyringe';
import type { AuthRepository } from '@/modules/authentication/domain/repositories/AuthRepository';
import type { EndpointsResponse } from '@/modules/authentication/domain/repositories/types/responses';
import { AnonymousHttpClientApi } from '@/modules/http-client/public/api';

@injectable()
export class HttpAuthRepository implements AuthRepository {
  constructor(private readonly httpClient: AnonymousHttpClientApi) {}

  async getEndpoints(): Promise<EndpointsResponse> {
    return this.httpClient.get(`/auth/endpoints/oauth`);
  }
}
