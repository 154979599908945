export class HttpClientError<T = Record<string, unknown>> extends Error {
  constructor(
    message: string,
    public readonly statusCode: number,
    public readonly data?: T,
  ) {
    super(message);

    // Changing prototype for extending default class is recommended by TS
    // https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, HttpClientError.prototype);

    this.name = 'HttpClientError';
  }
}
