import type { CallbackData } from '../../domain/overview-callback/CallbackData';
import type { CallbackDataRecord } from '../../domain/overview-callback/CallbackDataRecord';
import type { InputCallbackData } from './InputCallbackData';
import type { InputCallbackDataRecord } from './InputCallbackDataRecord';

export class CallbackDataFactory {
  make(input: InputCallbackData): CallbackData {
    return {
      current: input.current.map(this.mapInboundRecord()),
      previous: input.previous.map(this.mapInboundRecord()),
    };
  }

  private mapInboundRecord(): (record: InputCallbackDataRecord) => CallbackDataRecord {
    return record => ({
      date: record.date,
      all: record.all,
      attempted: record.attempted,
      successful: record.successful,
      visits: record.visits,
    });
  }
}
