import { HttpClientApi } from '@/modules/http-client/public/api';
import { injectable } from 'tsyringe';
import type { AnonymizationResponse } from './types/response';
import { Anonymization } from '../domain/Anonymization';
import type { AnonymizationRepository } from '../domain/repositories/AnonymizationRepository';

@injectable()
export class HttpAnonymizationRepository implements AnonymizationRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  public async save(enabled: boolean): Promise<Anonymization> {
    let response: AnonymizationResponse;

    if (!enabled) {
      response = await this.httpClient.put(`/api/v0/facility/{facilityId}/de-anonymization`, {});
    } else {
      response = await this.httpClient.delete(`/api/v0/facility/{facilityId}/de-anonymization`);
    }

    return new Anonymization(response.enabled);
  }

  public async get(): Promise<Anonymization> {
    const response: AnonymizationResponse = await this.httpClient.get(
      `/api/v0/facility/{facilityId}/de-anonymization`,
    );

    return new Anonymization(response.enabled);
  }

  public async isEnabled(): Promise<boolean> {
    return (await this.get()).enabled;
  }
}
