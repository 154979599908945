import { injectable } from 'tsyringe';
import { PusherConfigurationRepository } from '../../configuration/PusherConfigurationRepository';
import { ClientManager } from '../../domain/ClientManager';
import type { ChannelName } from '../../domain/models/ChannelName';
import { CallbackRepository } from '../../domain/repositories/CallbackRepository';
import type { Callback } from '../types/Callback';

@injectable()
export class CallbackUpdater {
  constructor(
    private readonly clientManager: ClientManager,
    private readonly callbackRepository: CallbackRepository,
    private readonly configurationRepository: PusherConfigurationRepository,
  ) {}

  add(callback: Callback): void {
    const channelId = this.getChannelId(callback.channelName);

    this.callbackRepository.add(callback);
    this.clientManager.bindCallback(callback, channelId);
  }

  remove(callback: Callback): void {
    const channelId = this.getChannelId(callback.channelName);

    this.callbackRepository.remove(callback.name);
    this.clientManager.unbindCallback(callback, channelId);
  }

  private getChannelId(channelName: ChannelName): string {
    const { commonChannel, piiChannel } = this.configurationRepository.get();
    return channelName === 'common' ? commonChannel.id : piiChannel.id;
  }
}
