import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { HttpClientModule } from '@/modules/http-client';
import { RootModule } from '@/common/root';
import { AdapterProvider } from './providers/AdapterProvider';
import { SubscriptionProvider } from './providers/SubscriptionProvider';
import { EventProvider } from './providers/EventProvider';
import { CustomerSuccessModeProvider } from './providers/CustomerSuccessModeProvider';
import { AuthorizationProvider } from './providers/AuthorizationProvider';
import { FacilityProvider } from './providers/FacilityProvider';

export class AuthorizationModule implements DpModule {
  static readonly namespace = Symbol('AuthorizationModule');

  static readonly requires = [RootModule, HttpClientModule];

  providers(): IDpProvider[] {
    return [
      SubscriptionProvider,
      AdapterProvider,
      FacilityProvider,
      CustomerSuccessModeProvider,
      EventProvider,
      AuthorizationProvider,
    ];
  }
}
