import type { TaskCommentRepository } from '@/api/repositories/types/TaskCommentRepository';
import { BaseAxiosRepository } from '@/api/repositories/axios/BaseAxiosRepository';
import type { TaskId } from '@/api/models/Task';
import { Task } from '@/api/models/Task';
import type { Task as RawTask } from '@/api/types/entities/task';

export class AxiosTaskCommentRepository
  extends BaseAxiosRepository
  implements TaskCommentRepository
{
  async updateComment(id: TaskId, comment: string): Promise<Task> {
    const data = (await this.axiosClient.put(`/api/v0/task/${id}/comments`, {
      comment,
      todo: false,
    })) as RawTask;

    return Task.make(data);
  }
}
