import { inject, injectable } from 'tsyringe';
import { DomainGetterToken } from '../../di/tokens';
import type { Domain } from '../models/Domain';
import type { Configs } from '../models/Configs';
import type { AppConfig } from '../models/AppConfig';
import type { DomainRepository } from './DomainRepository';

@injectable()
export class DefaultConfigurationFactory {
  constructor(
    private readonly configs: Configs,
    @inject(DomainGetterToken) private readonly domainGetter: DomainRepository,
  ) {}

  get(): AppConfig {
    return this.makeForDomain(this.domainGetter.get());
  }

  makeForDomain(domain: Domain): AppConfig {
    return {
      ...this.configs[domain],
      domain,
    };
  }

  getAll(): AppConfig[] {
    return Object.entries(this.configs).map(([key, value]) => ({
      ...value,
      domain: key,
    }));
  }
}
