import type { DpListener } from '@/core/events/DpListener';
import { injectable } from 'tsyringe';
import { EnvironmentAwareSender } from '../EnvironmentAwareSender';
import { SingularEventConverter } from './factories/SingularEventConverter';
import type { SingularEvent } from '../../models/SingularEvent';

@injectable()
export class SingularEventListener implements DpListener {
  constructor(
    private readonly sender: EnvironmentAwareSender,
    private readonly singularEventConverter: SingularEventConverter,
  ) {}

  handle(singularEvent: SingularEvent): void {
    this.sender.trackEvent(this.singularEventConverter.make(singularEvent));
  }
}
