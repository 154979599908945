import type { Locale } from '@/utils/languageDetector';
import { LocaleMapper } from '@/app/formatters/number/LocaleMapper';

export class CurrencyFormatter {
  private readonly intlLocale: string;

  private readonly style = 'currency';

  private readonly currencyDisplay = 'narrowSymbol';

  private readonly maximumFractionDigits = 0;

  constructor(locale: Locale) {
    this.intlLocale = LocaleMapper.toIntlLocale(locale);
  }

  format(amount: number, currency: 'BRL' | 'EUR' | 'MXN' | 'PLN' | 'USD'): string {
    const formatter = new Intl.NumberFormat(this.intlLocale, {
      currency,
      style: this.style,
      currencyDisplay: this.currencyDisplay,
      maximumFractionDigits: this.maximumFractionDigits,
    });

    return formatter.format(amount);
  }
}
