import type { DpProvider } from '@/core/di/DpProvider';
import { CallbackUpdater } from '@/common/real-time-communication/public/api/CallbackUpdater';
import { CallbackEventName } from '@/common/real-time-communication/public/types';
import type { Callback } from '@/common/real-time-communication/public/types/Callback';
import { injectable } from 'tsyringe';
import { TaskCallback } from '../../domain/real-time-updates/TaskCallback';

@injectable()
export class RealTimeCommunicationBootProvider implements DpProvider {
  private callback: Callback;

  constructor(
    private readonly taskCallback: TaskCallback,
    private readonly callbackUpdater: CallbackUpdater,
  ) {
    this.callback = {
      name: 'TaskListTaskCallback',
      channelName: 'pii',
      eventName: CallbackEventName.Task,
      handler: this.taskCallback.handler.bind(this.taskCallback),
      reset: this.taskCallback.reset.bind(this.taskCallback),
    };
  }

  boot(): void {
    this.callbackUpdater.add(this.callback);
  }
}
