import { injectable } from 'tsyringe';
import type { DpListener } from '@/core/events/DpListener';
import { SubscriptionFetcher } from './SubscriptionFetcher';

@injectable()
export class SubscriptionClearListener implements DpListener {
  constructor(private readonly subscriptionFetcher: SubscriptionFetcher) {}

  handle(): void {
    this.subscriptionFetcher.clear();
  }
}
