import type { DpProvider } from '@/core/di/DpProvider';
import { RootErrorLoggerToken } from '@/common/root/providers/RootErrorLoggerProvider';
import { EventTrackerFacade } from '@/common/tracking-event/public/api';
import { delay, injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import type { ErrorLogger } from '../../domain/tracking/ErrorLogger';
import type { EventTracker } from '../../domain/tracking/EventTracker';
import { ErrorLoggerToken, EventTrackerToken } from '../tokens';

@injectable()
export class EventTrackerProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<EventTracker>(EventTrackerToken, {
      useToken: delay(() => EventTrackerFacade),
    });

    container.register<ErrorLogger>(ErrorLoggerToken, {
      useFactory(c: DependencyContainer) {
        return c.resolve(RootErrorLoggerToken);
      },
    });
  }
}
