import { inject, injectable } from 'tsyringe';
import type { UserTokenPayload } from '@/modules/authentication/domain/login/UserTokenPayload';
import { UserSessionCreator } from '@/modules/authentication/domain/login/UserSessionCreator';
import { UserSessionDestroyer } from '@/modules/authentication/domain/login/UserSessionDestroyer';
import { RedirectionDispatcher } from '@/modules/authentication/domain/login/RedirectionDispatcher';
import { EventEmitter } from '@/modules/authentication/domain/EventEmitter';
import { LogoutEvent } from '@/modules/authentication/domain/logout/LogoutEvent';
import type { UserDataManager } from '@/modules/authentication/domain/login/me/UserDataManager';
import { UserDataManagerToken } from '@/modules/authentication/di/token';
import type { UserCredentials } from '@/modules/authentication/domain/login/UserCredentials';

@injectable()
export class UserAuthenticator {
  constructor(
    private readonly redirectionDispatcher: RedirectionDispatcher,
    private readonly userSessionCreator: UserSessionCreator,
    private readonly userSessionDestroyer: UserSessionDestroyer,
    private readonly eventEmitter: EventEmitter,
    @inject(UserDataManagerToken)
    private readonly userDataManager: UserDataManager,
  ) {}

  async loginWithCredentials(credentials: UserCredentials): Promise<void> {
    await this.userSessionCreator.create(credentials);

    await this.redirectionDispatcher.redirectAuthorized();
  }

  async loginUsingToken(payload: UserTokenPayload): Promise<void> {
    this.userSessionCreator.setUserToken(payload);

    await this.redirectionDispatcher.redirectAuthorized();
  }

  async logout(): Promise<void> {
    this.eventEmitter.send(new LogoutEvent());

    await this.userSessionDestroyer.destroy();

    await this.redirectionDispatcher.redirectUnauthorized();

    await this.userDataManager.flush();
  }
}
