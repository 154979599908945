import { AxiosFacilityCallRepository } from '@/api/repositories/axios/AxiosFacilityCallRepository';
import { AxiosFacilityRepository } from '@/api/repositories/axios/AxiosFacilityRepository';
import { AxiosPresenceSummaryRepository } from '@/api/repositories/axios/AxiosPresenceSummaryRepository';
import { AxiosSettingsChannelsPhonesRepository } from '@/api/repositories/axios/AxiosSettingsChannelsPhonesRepository';
import { AxiosTaskCallbackRepository } from '@/api/repositories/axios/AxiosTaskCallbackRepository';
import { AxiosTaskCommentRepository } from '@/api/repositories/axios/AxiosTaskCommentRepository';
import { AxiosTaskRepository } from '@/api/repositories/axios/AxiosTaskRepository';
import { AxiosTaskVisitRepository } from '@/api/repositories/axios/AxiosTaskVisitRepository';
import { AxiosUploadMediaFileRepository } from '@/api/repositories/axios/AxiosUploadMediaFileRepository';
import { AxiosUserRepository } from '@/api/repositories/axios/AxiosUserRepository';
import { resolve } from '@/core/di';
import { HttpClientApi } from '@/modules/http-client/public/api';

const axiosClient = () => resolve(HttpClientApi);

export const facilityCallRepository = new AxiosFacilityCallRepository(axiosClient());
export const facilityRepository = new AxiosFacilityRepository(axiosClient());
export const presenceSummaryRepository = new AxiosPresenceSummaryRepository(axiosClient());
export const settingsChannelsPhonesRepository = new AxiosSettingsChannelsPhonesRepository(
  axiosClient(),
);
export const taskCallbackRepository = new AxiosTaskCallbackRepository(axiosClient());
export const taskCommentRepository = new AxiosTaskCommentRepository(axiosClient());
export const taskRepository = new AxiosTaskRepository(axiosClient());
export const taskVisitRepository = new AxiosTaskVisitRepository(axiosClient());
export const uploadMediaFileRepository = new AxiosUploadMediaFileRepository(axiosClient());
export const userRepository = new AxiosUserRepository(axiosClient());
