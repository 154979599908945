import type { VoiceMessageCreateInput } from '../../domain/voice-messages/VoiceMessage';
import { VoiceMessageList } from '../../domain/voice-messages/VoiceMessageList';
import { VoiceMessage } from '../../domain/voice-messages/VoiceMessage';

export interface HttpResponse {
  welcome_message: VoiceMessageCreateInput;
  voice_menu: VoiceMessageCreateInput;
  facility_after_hours: VoiceMessageCreateInput;
  voicemail_in_queue: VoiceMessageCreateInput;
}

export class VoiceMessageListFactory {
  static makeFromResponse(response: HttpResponse): VoiceMessageList {
    return VoiceMessageList.make({
      welcomeMessage: VoiceMessage.make(response.welcome_message),
      voiceMenu: VoiceMessage.make(response.voice_menu),
      facilityAfterHours: VoiceMessage.make(response.facility_after_hours),
      voicemailInQueue: VoiceMessage.make(response.voicemail_in_queue),
    });
  }
}
