import { inject, injectable } from 'tsyringe';
import type { FilterOption } from '@/modules/tasks-list/domain/ownership-filter/options/FilterOption';
import { EveryoneOption } from '@/modules/tasks-list/domain/ownership-filter/options/EveryoneOption';
import { MeOption } from '@/modules/tasks-list/domain/ownership-filter/options/MeOption';
import type { UserRepository } from '@/modules/tasks-list/domain/ownership-filter/user/UserRepository';
import { UserRepositoryToken } from '@/modules/tasks-list/di/tokens';

@injectable()
export class DefaultFilterOption {
  constructor(
    @inject(UserRepositoryToken)
    private readonly userRepository: UserRepository,
  ) {}

  forCurrentUser(): FilterOption {
    const currentUser = this.userRepository.getCurrentUser();

    if (currentUser.isManager) {
      return this.forManager();
    }

    return this.forReceptionist(currentUser.id);
  }

  private forManager(): FilterOption {
    return EveryoneOption.make();
  }

  private forReceptionist(userId: string): FilterOption {
    return MeOption.make(userId);
  }
}
