import { DpEventBus } from '@/core/events/DpEventBus';
import { inject, injectable } from 'tsyringe';
import { PhoneWidgetEvent } from '../domain';

@injectable()
export class PhoneWidgetEventPublisher {
  constructor(@inject(DpEventBus) private readonly eventBus: DpEventBus) {}

  publish(name: string): void {
    this.eventBus.publish(new PhoneWidgetEvent(name));
  }
}
