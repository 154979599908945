import { HttpClientApi } from '@/modules/http-client/public/api';
import { injectable } from 'tsyringe';
import type { FacilityProfile } from '../../domain/facility-integration/FacilityProfile';
import type { FacilityProfileRepository } from '../../domain/facility-integration/FacilityProfileRepository';
import { FacilityProfileFactory } from './FacilityProfileFactory';
import type { InputFacilityProfile } from './types/InputFacilityProfile';

@injectable()
export class HttpFacilityProfileRepository implements FacilityProfileRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly facilityProfileFactory: FacilityProfileFactory,
  ) {}

  async get(): Promise<FacilityProfile | null> {
    const inputFacilityProfile: InputFacilityProfile | '' = await this.httpClient.get(
      '/api/v0/facility/{facilityId}/docplanner',
    );

    if (!inputFacilityProfile) {
      return null;
    }

    return this.facilityProfileFactory.make(inputFacilityProfile);
  }

  async updateUrl(url: string): Promise<FacilityProfile> {
    const inputFacilityProfile: InputFacilityProfile = await this.httpClient.put(
      '/api/v0/facility/{facilityId}/docplanner',
      { url },
    );

    return this.facilityProfileFactory.make(inputFacilityProfile);
  }
}
