<template>
  <div :class="$b()">
    <full-page-wrapper>
      <template #logo>
        <slot name="logo" />
      </template>
      <div class="container">
        <div :class="$b('inner')">
          <div :class="$b('col', { form: true })">
            <div :class="$b('form-wrapper')">
              <slot name="form" />
            </div>
          </div>
          <div :class="$b('col', { content: true })">
            <div :class="$b('content-wrapper')">
              <slot name="content" />
            </div>
          </div>
        </div>
      </div>
    </full-page-wrapper>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator';

import { FullPageWrapper } from '@/ui/templates';

@Component({
  name: 'InitiativePage',
  components: {
    FullPageWrapper,
  },
})
export default class InitiativePage extends Vue {}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;
@use 'src/assets/scss/mixins' as m;
@use 'src/assets/scss/functions' as f;

$padding-top: calc(#{v.$full-page-logo-height} + 8vh);

.dp-initiative-page {
  background: #f7f4f0;

  &__col {
    &--content {
      padding: v.$spacer-xl 0;
    }

    &--form {
      position: relative;
      padding: $padding-top 0 v.$spacer-xl;
      background: v.$white;
      min-height: 90vh;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 100vw;
        height: 100%;
        transform: translateX(-50%);
        background: v.$white;
      }
    }
  }

  &__content-wrapper,
  &__form-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
  }

  @include m.media(lg) {
    &__inner {
      display: grid;
      grid-template-columns: f.px-to-rem(480px) 1fr;
    }

    &__col {
      flex-grow: 1;
      flex-basis: 0;
      display: flex;
      align-items: center;
      padding: $padding-top 0 f.px-to-rem(72px);

      &--form {
        padding-right: f.px-to-rem(96px);

        &::before {
          left: auto;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 50vw;
          height: 100vh;
        }
      }

      &--content {
        padding-left: f.px-to-rem(96px);
      }
    }
  }
}
</style>
