import type { IDpProvider } from '@/core/di/DpProvider';
import type { DpModule } from '@/core/modules/DpModule';
import { UserModule } from '@/modules/user';
import { ConfigurationProvider } from './providers/ConfigurationProvider';
import { EventBusProvider } from './providers/EventBusProvider';
import { PermissionProvider } from './providers/PermissionProvider';
import { DesktopNotificationProvider } from './providers/DesktopNotificationProvider';
import { RepositoryProvider } from './providers/RepositoryProvider';
import { SoundNotificationProvider } from './providers/SoundNotificationProvider';

export class NotificationModule implements DpModule {
  static readonly namespace = Symbol('NotificationModule');

  static readonly requires = [UserModule];

  providers(): IDpProvider[] {
    return [
      ConfigurationProvider,
      PermissionProvider,
      DesktopNotificationProvider,
      SoundNotificationProvider,
      RepositoryProvider,
      EventBusProvider,
    ];
  }
}
