import { singleton } from 'tsyringe';
import type { Store } from 'vuex';
import { FacilityPublisher } from '../domain/FacilityPublisher';

interface StoreFacility {
  user: {
    facility?: {
      id: string;
    };
  };
}

@singleton()
export class StoreFacilityStateObserver {
  constructor(private readonly facilityEventPublisher: FacilityPublisher) {}

  observeStore(store: Store<StoreFacility>): void {
    store.watch(this.getObservableFacility(store), this.onFacilityChange.bind(this));
  }

  private getObservableFacility(store: Store<StoreFacility>): () => unknown {
    return () => store.state.user.facility;
  }

  private onFacilityChange(newFacility?: { id: string }, oldFacility?: { id: string }): void {
    if (newFacility?.id !== undefined && oldFacility?.id === undefined) {
      this.facilityEventPublisher.publishFacilityLoadedEvent();
      return;
    }

    if (newFacility?.id === undefined && oldFacility?.id !== undefined) {
      this.facilityEventPublisher.publishFacilityUnloadedEvent();
      return;
    }

    if (newFacility?.id !== oldFacility?.id) {
      this.facilityEventPublisher.publishFacilityUnloadedEvent();
      this.facilityEventPublisher.publishFacilityLoadedEvent();
    }
  }
}
