import type { RouteRecordRaw } from 'vue-router';
import { ifNotAuthenticated } from '../guards';

const requestPasswordResetRoutes: RouteRecordRaw[] = [
  {
    path: '/request-password-reset',
    name: 'request-password-reset',
    beforeEnter: ifNotAuthenticated,
    component: () => import(/* webpackChunkName: "auth" */ './pages/RequestPasswordReset.vue'),
  },
  {
    path: '/request-password-reset/email',
    name: 'request-password-reset-email',
    beforeEnter: ifNotAuthenticated,
    component: () => import(/* webpackChunkName: "auth" */ './pages/RequestPasswordResetEmail.vue'),
  },
  {
    path: '/request-password-reset/phone',
    name: 'request-password-reset-phone',
    beforeEnter: ifNotAuthenticated,
    component: () => import(/* webpackChunkName: "auth" */ './pages/RequestPasswordResetPhone.vue'),
  },
];

const resetRoutes: RouteRecordRaw[] = [
  {
    path: '/password-reset/phone/:phoneNumber',
    name: 'password-reset-phone',
    beforeEnter: ifNotAuthenticated,
    component: () => import(/* webpackChunkName: "auth" */ './pages/PasswordResetPhone.vue'),
    props: true,
  },
  {
    path: '/password-reset/:token/:phoneNumber?',
    name: 'password-reset-token',
    beforeEnter: ifNotAuthenticated,
    component: () => import(/* webpackChunkName: "auth" */ './pages/PasswordResetToken.vue'),
    props: true,
  },
];

export const passwordResetRoutes: RouteRecordRaw[] = [
  ...requestPasswordResetRoutes,
  ...resetRoutes,
];
