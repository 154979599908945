import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import type { LocalizationRepository } from '../../domain/LocalizationRepository';
import { LocalizationRepositoryToken } from '../tokens';
import { CommonLocalizationRepository } from '../../adapters/CommonLocalizationRepository';

export class RepositoryProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<LocalizationRepository>(
      LocalizationRepositoryToken,
      CommonLocalizationRepository,
    );
  }
}
