import type { Permission as StorePermission } from '@/api/models/Permission';
import { injectable } from 'tsyringe';
import { Permission } from '../../domain/permissions/Permission';
import { PermissionRole } from '../../domain/permissions/PermissionRole';
import { UserFactory } from './UserFactory';

@injectable()
export class PermissionFactory {
  constructor(private readonly userFactory: UserFactory) {}

  makeMany(storePermissions: StorePermission[]): Permission[] {
    return storePermissions.map(storePermission => this.make(storePermission));
  }

  private make(storePermission: StorePermission): Permission {
    const role = this.getRole(storePermission.role);
    const user = this.userFactory.make(storePermission.user);

    return new Permission(
      storePermission.id,
      role,
      user,
      storePermission.weight,
      storePermission.hasSignedIn,
    );
  }

  private getRole(role: StorePermission['role']): PermissionRole {
    switch (role) {
      case 'manager':
        return PermissionRole.Manager;
      case 'user':
        return PermissionRole.Receptionist;
      default:
        throw new Error(`Unknown permission role: ${role}`);
    }
  }
}
