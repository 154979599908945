import { injectable } from 'tsyringe';
import { BookVisitApi } from '@/modules/book-visit/public/api';
import type { VisitConfirmationStatus } from '@/modules/task/domain/visit/VisitConfirmationStatus';
import type { VisitConfirmationRepository } from '../../../domain/visit/VisitConfirmationRepository';
import type { Task } from '../../../domain/task/Task';
import type { VisitConfirmation } from '../../../domain/visit/VisitConformation';
import { BookVisitModuleVisitConfirmationFactory } from './BookVisitModuleVisitConfirmationFactory';

@injectable()
export class BookVisitModuleVisitConfirmationRepository implements VisitConfirmationRepository {
  constructor(private readonly bookVisitApi: BookVisitApi) {}

  findFor(task: Task): VisitConfirmation | null {
    return BookVisitModuleVisitConfirmationFactory.tryMake(
      this.bookVisitApi.findVisitToConfirm(task.id),
    );
  }

  existsFor(task: Task): boolean {
    return this.bookVisitApi.hasVisitToConfirm(task.id);
  }

  updateStatus(task: Task, status: VisitConfirmationStatus): Promise<void> {
    return this.bookVisitApi.updateVisitConfirmationStatus(task.id, status);
  }
}
