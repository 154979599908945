import sanitizeHtmlLibrary from 'sanitize-html';
import { escapeHtml } from '@/utils/text';

const config = {
  allowedTags: ['a', 'b', 'br', 'span', 'ul', 'li'],
  allowedAttributes: {
    span: ['class'],
    a: ['class', 'href', 'target'],
    b: ['class'],
  },

  allowedSchemes: ['mailto', 'https', 'http'],
  allowedSchemesAppliedToAttributes: ['href'],
  nestingLimit: 3,
};

const textConfig = {
  allowedTags: [],
  allowedAttributes: {},
  nestingLimit: 0,
};

export const sanitizeHtml = dirty => sanitizeHtmlLibrary(dirty, config);

export const textOnly = dirty => escapeHtml(sanitizeHtmlLibrary(dirty, textConfig));
