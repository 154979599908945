import { inject, injectable } from 'tsyringe';
import type { TagFactory } from '@/modules/task/domain/tag/factories/TagFactory';
import type { Task } from '@/modules/task/domain/task/Task';
import type { Tag } from '@/modules/task/domain/tag/Tag';
import {
  AuthorizationCheckerToken,
  ChannelRepositoryToken,
  TimelineRepositoryToken,
} from '@/modules/task/di/tokens';
import type { TimelineRepository } from '@/modules/task/domain/timeline/TimelineRepository';
import type { ChannelRepository } from '@/modules/task/domain/channel/ChannelRepository';
import type { AuthorizationChecker } from '@/modules/task/domain/authorization/AuthorizationChecker';
import { Policies } from '@/modules/task/domain/authorization/policies';

@injectable()
export class ChannelTagFactory implements TagFactory {
  constructor(
    @inject(TimelineRepositoryToken)
    private readonly timelineRepository: TimelineRepository,
    @inject(ChannelRepositoryToken)
    private readonly channelRepository: ChannelRepository,
    @inject(AuthorizationCheckerToken)
    private readonly authorizationChecker: AuthorizationChecker,
  ) {}

  make(task: Task): Tag[] {
    if (!this.authorizationChecker.can(Policies.SeeChannelName)) {
      return [];
    }

    const { hasCall, lastContactForm, lastCall } = this.timelineRepository.get(task.id);

    if (lastContactForm) {
      const { channelId } = lastContactForm;
      return [this.channelRepository.get(channelId).getTag()];
    }

    if (!hasCall) {
      return [];
    }

    return [this.channelRepository.get(lastCall.channelId).getTag()];
  }
}
