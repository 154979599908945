import { HttpClientApi } from '@/modules/http-client/public/api';
import { injectable } from 'tsyringe';
import type { VoiceMessageRepository } from '@/modules/settings/domain/traffic-control/voice-menu-announcement/VoiceMessageRepository';
import type { VoiceMessage } from '@/modules/settings/domain/traffic-control/models/VoiceMessage';
import { VoiceMessageFactory } from './VoiceMessageFactory';
import type { InputVoiceMessageList } from '../types/InputVoiceMessageList';
import type { OutputVoiceMessageList } from '../types/OutputVoiceMessageList';

@injectable()
export class HttpVoiceMessageRepository implements VoiceMessageRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly voiceMessageFactory: VoiceMessageFactory,
  ) {}

  async getVoiceMenu(): Promise<VoiceMessage> {
    const voiceMessages: InputVoiceMessageList = await this.httpClient.get(
      `/api/v0/facility/{facilityId}/settings/messages`,
    );

    return this.voiceMessageFactory.makeFromInput(voiceMessages.messages.voice_menu);
  }

  async saveVoiceMenu(type: 'text' | 'file', value: string, fileId: string): Promise<VoiceMessage> {
    const payload: OutputVoiceMessageList = {
      messages: {
        voice_menu: {
          type,
          value,
          file: fileId,
        },
      },
    };

    const voiceMessages: InputVoiceMessageList = await this.httpClient.put(
      `/api/v0/facility/{facilityId}/settings/messages`,
      payload,
    );

    return this.voiceMessageFactory.makeFromInput(voiceMessages.messages.voice_menu);
  }
}
