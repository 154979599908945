export const SsoFlowRepositoryToken = Symbol('SsoFlowRepository');
export const SsoIntegrationRepositoryToken = Symbol('SsoIntegrationRepository');
export const SsoTokenRepositoryToken = Symbol('SsoTokenRepository');
export const SsoClientRepositoryToken = Symbol('SsoClientRepository');
export const LocaleDetectorToken = Symbol('LocaleDetector');
export const AuthRepositoryToken = Symbol('AuthRepository');
export const UserConfigToken = Symbol('UserConfig');
export const UserToken = Symbol('User');
export const UserDataManagerToken = Symbol('UserDataManager');
export const PhoneWidgetWorkstationManagerToken = Symbol('PhoneWidgetWorkstationManager');
export const ObfuscatePhoneNumberCreatorToken = Symbol('ObfuscatePhoneNumberCreator');
export const RouterToken = Symbol('Router');

export const AccessTokenRequesterToken = Symbol('AccessTokenRequester');
export const AccessTokenRepositoryToken = Symbol('TokenRepository');
export const UserDataFetcherToken = Symbol('UserDataFetcher');
export const ClientCredentialsRepositoryToken = Symbol('ClientCredentialsRepository');
export const PasswordResetRequesterToken = Symbol('PasswordResetRequester');
export const RequestDetailsRepositoryToken = Symbol('PasswordResetDetailsRepository');
export const PasswordResetDispatcherToken = Symbol('PasswordResetDispatcher');
