import { injectable } from 'tsyringe';
import { UserSession } from '../../domain/pause/UserSession';
import type { InputUserSession } from './InputUserSession';

@injectable()
export class UserSessionFactory {
  make(inputSession: InputUserSession): UserSession {
    return new UserSession(inputSession.start_date, inputSession.is_online);
  }
}
