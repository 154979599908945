import { denormalize, normalize } from 'normalizr';
import { not, sortByWeight } from '@/utils/functions';
import { phoneApi } from '@/api/phone-api';
import {
  $GET_ENTITIES_BY_ID,
  $GET_ENTITIES_BY_TYPE,
  MUTATION_ENTITIES_DELETE_KEY,
  MUTATION_ENTITIES_UPDATE,
  MUTATION_ENTITIES_UPDATE_KEY,
} from '@dp-vue/entities';
import { workstationSchema, MODES, INPUT_NAMES, TYPES } from '@/store/schema/reception-phone';
import { userSchema } from '@/store/schema/user';
import { EntityType } from '@/store/schema/entity-type';
import { toNamedParams } from '@/utils/vuex';
import { workstationErrors } from '@/store/modules/settings/workstation-errors';

const canAcceptPatientsCalls = ({ mode }) =>
  [MODES.ALWAYS, MODES.BACKUP, MODES.SIGNED_IN].includes(mode);

const setInternalNumber = ({ internal_number = '', ...rest }) => ({
  internal_number,
  ...rest,
});

const initialFormData = {
  [INPUT_NAMES.TYPE]: TYPES.CLOUD_WEB,
  [INPUT_NAMES.NAME]: '',
  [INPUT_NAMES.FORMATTED_NUMBER]: '',
  [INPUT_NAMES.INTERNAL_NUMBER]: '',
  [INPUT_NAMES.MODE]: MODES.SIGNED_IN,
};

const initialState = {
  limit: undefined,
  isSavingForm: false,
  formData: {
    ...initialFormData,
  },
};

const state = {
  ...initialState,
};

const getters = {
  getReceptionPhonesLimit: state => state.limit,
  getAllReceptionPhones: toNamedParams(({ rootState, rootGetters }) => {
    const phones = rootGetters[$GET_ENTITIES_BY_TYPE](EntityType.RECEPTION_PHONE);

    if (!phones) {
      return [];
    }

    return phones.sort(sortByWeight).map(({ current_user, ...rest }) => {
      if (!current_user) {
        return rest;
      }

      return {
        ...rest,
        current_user: denormalize(current_user, userSchema, rootState.entities),
      };
    });
  }),
  getReceptionPhones: (_, getters) =>
    getters.getAllReceptionPhones.filter(canAcceptPatientsCalls).map(setInternalNumber),
  getNonReceptionPhones: (_, getters) =>
    getters.getAllReceptionPhones.filter(not(canAcceptPatientsCalls)).map(setInternalNumber),
  getReceptionPhoneById: toNamedParams(({ rootState, rootGetters }) => workstationId => {
    const [phone] = rootGetters[$GET_ENTITIES_BY_ID](EntityType.RECEPTION_PHONE, [workstationId]);
    if (phone && phone.current_user) {
      return {
        ...phone,
        current_user: denormalize(phone.current_user, userSchema, rootState.entities),
      };
    }

    return phone;
  }),
  getReceptionPhoneNumbers: (_, getters) => getters.getReceptionPhones.map(item => item.number),
  getReceptionByInternalNumber: (_, getters) => numberToMatch =>
    getters.getReceptionPhones.find(item => item.internal_number === numberToMatch),
  getCurrentUserPhone: toNamedParams(({ getters, rootGetters }) =>
    getters.getReceptionPhones.find(phone => phone.current_user?.id === rootGetters.getUser.id),
  ),
  isSelectedPhone: (_, getters) => !!getters.getCurrentUserPhone,
  isCloudWebPhoneSelected: (_, getters) =>
    getters.isSelectedPhone && getters.getCurrentUserPhone?.type === TYPES.CLOUD_WEB,
  isMultiWorkstationFacility: (_, getters) => getters.getAllReceptionPhones.length > 1,
  formData: ({ formData }) => formData,
  isSavingForm: ({ isSavingForm }) => isSavingForm,
};

const mutations = {
  SET_LIMIT(state, limit) {
    state.limit = limit;
  },

  SET_FORM_DATA(state, { key, value }) {
    state.formData = {
      ...state.formData,
      [key]: value,
    };
  },

  RESET_FORM(state) {
    state.formData = {
      ...initialFormData,
    };
  },

  SET_FORM_SAVING(state, isSaving) {
    state.isSavingForm = isSaving;
  },
};

const actions = {
  /**
   * @param {object} context
   * @param {object} payload
   */
  SET({ commit, dispatch }, { phones, limit }) {
    dispatch('DELETE_ENTITIES_GROUP_ACTION', EntityType.RECEPTION_PHONE, { root: true });

    const { entities } = normalize(phones, [workstationSchema]);

    commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });
    commit('SET_LIMIT', limit);
  },

  SET_FORM_DATA_ACTION({ commit }, payload) {
    commit('SET_FORM_DATA', payload);
  },

  SET_FORM_SAVING_ACTION({ commit }, isSaving) {
    commit('SET_FORM_SAVING', isSaving);
  },

  RESET_FORM_ACTION({ commit }) {
    commit('RESET_FORM');
  },

  async REORDER({ rootGetters, commit }, list) {
    return phoneApi
      .withFacility(rootGetters.getUserFacilityID)
      .withReceptionPhones.reorder(list)
      .then(response => {
        const { entities } = normalize(response, [workstationSchema]);

        commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });
      });
  },

  async EDIT({ rootGetters, commit }, { id, item }) {
    return phoneApi
      .withFacility(rootGetters.getUserFacilityID)
      .withReceptionPhones.edit(id, item)
      .then(response => {
        const { entities } = normalize(response, workstationSchema);

        commit(
          MUTATION_ENTITIES_UPDATE_KEY,
          {
            key: id,
            entity: EntityType.RECEPTION_PHONE,
            payload: entities,
          },
          { root: true },
        );
      });
  },

  async ADD({ rootGetters, commit }, item) {
    return phoneApi
      .withFacility(rootGetters.getUserFacilityID)
      .withReceptionPhones.add(item)
      .then(response => {
        const { entities } = normalize(response, workstationSchema);

        commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });
      });
  },

  async DELETE({ rootGetters, commit }, id) {
    return phoneApi
      .withFacility(rootGetters.getUserFacilityID)
      .withReceptionPhones.delete(id)
      .then(() => {
        commit(
          MUTATION_ENTITIES_DELETE_KEY,
          {
            entity: EntityType.RECEPTION_PHONE,
            key: id,
          },
          { root: true },
        );
      });
  },
};

export const name = 'reception';

export const reception = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    errors: workstationErrors,
  },
};
