import { mediaSchema } from '../schemas/media-schema';
import type { NumericId, UrlAddress } from '../types';

export class Media {
  static readonly schema = mediaSchema;

  readonly id: NumericId;

  readonly name: string;

  readonly url: UrlAddress;

  private constructor(data: Media) {
    this.id = data.id;
    this.name = data.name;
    this.url = data.url;
  }

  static make(data: Media): Media {
    return new Media(data);
  }

  static tryMake(data?: Media | null): Media | null {
    return data ? Media.make(data) : null;
  }
}
