import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { Authorization } from '@/common/authorization/public/api';
import { Policies } from '@/modules/task/domain/authorization/policies';
import { AuthorizationCheckerAdapter } from '../../adapters/authorization/AuthorizationCheckerAdapter';
import type { AuthorizationChecker } from '../../domain/authorization/AuthorizationChecker';
import { AuthorizationCheckerToken } from '../tokens';

@injectable()
export class AuthorizationProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<AuthorizationChecker>(
      AuthorizationCheckerToken,
      AuthorizationCheckerAdapter,
    );
  }

  boot(container: DependencyContainer): void {
    const authorization = container.resolve(Authorization);
    authorization.addPolicies([
      {
        name: Policies.CopyTaskLink,
        // Affects only diagnostic part of calls, reporting quality issues is available for all users
        handler: user => user.hasRole('CALL_QUALITY'),
      },
      {
        name: Policies.SeeChannelName,
        handler: user => user.hasFeature('CHANNEL_FILTER'),
      },
      {
        name: Policies.SeeAllTasks,
        handler: user => user.isManager(),
      },
      {
        name: Policies.SendTextMessage,
        handler: user => user.isFacilityUser(),
      },
    ]);
  }
}
