import type { TaskResponse } from '@/api/types/response/task';
import type { TaskRepository } from '@/api/repositories/types/TaskRepository';
import type { SearchFetchOptions, TaskFetcher, TasksPage } from './TaskFetcher';

export class SearchTaskFetcher implements TaskFetcher {
  constructor(private readonly taskRepository: TaskRepository) {}

  async create(createOptions: SearchFetchOptions): Promise<TasksPage> {
    const response: TaskResponse = await this.taskRepository.search(
      createOptions.facilityId,
      createOptions.searchPayload,
      createOptions.queryParams,
    );

    return {
      apiTasks: response.data,
      cursor: response.headers.link ?? null,
    };
  }
}
