import { inject, injectable } from 'tsyringe';
import { UserRepositoryToken, UserStatusRepositoryToken } from '../../di/tokens';
import type { UserRepository } from '../../domain/UserRepository';
import type { UserStatusRepository } from '../../domain/user-status/UserStatusRepository';
import type { CurrentUser } from '../types/CurrentUser';
import type { User } from '../types/User';

@injectable()
export class UserApi {
  constructor(
    @inject(UserRepositoryToken)
    private readonly userRepository: UserRepository,
    @inject(UserStatusRepositoryToken)
    private readonly userStatusRepository: UserStatusRepository,
  ) {}

  getById(userId: string): User {
    const user = this.userRepository.get(userId);
    const { status } = this.userStatusRepository.get(userId);
    return {
      ...user,
      status,
    };
  }

  getCurrentUser(): CurrentUser {
    const user = this.userRepository.getCurrentUser();
    const { status } = this.userStatusRepository.get(user.id);

    return {
      ...user,
      status,
    };
  }

  getAll(): User[] {
    return this.userRepository.all().map(user => {
      const { status } = this.userStatusRepository.get(user.id);
      return {
        ...user,
        status,
      };
    });
  }
}
