import { schema } from 'normalizr';
import type { PhoneNumber, UUID, Weight } from '@/types/commons';
import type { Prioritizable } from '@/app/priority/prioritizable';
import { EntityType } from './entity-type';
import { userSchema } from './user';
import type { User } from './user';

export enum PhoneTypes {
  Redirected = 'redirected',
  DpPhone = 'phone',
}

export type PhoneId = UUID;
type SID = string;

interface BasePhone extends Prioritizable {
  id: PhoneId;
  formatted_number: string;
  name: string;
  number: PhoneNumber;
  sid: SID;
  weight: Weight;
  current_user?: User;
}

interface DpPhone extends BasePhone {
  type: PhoneTypes.DpPhone;
}

interface RedirectedPhone extends BasePhone {
  type: PhoneTypes.Redirected;
  redirected_to: PhoneNumber;
  verified: boolean;
}

export type Phone = RedirectedPhone | DpPhone;

export function isRedirectedPhone(arg: RedirectedPhone | DpPhone): arg is RedirectedPhone {
  if (typeof arg !== 'object') {
    return false;
  }

  return arg?.type === PhoneTypes.Redirected;
}

function processStrategy(value): Phone {
  if (value.priority !== undefined) {
    value.priority = Boolean(value.priority);
  }
  return value;
}

export const phoneSchema = new schema.Entity(
  EntityType.PHONE,
  {
    current_user: userSchema,
  },
  {
    processStrategy,
  },
);
