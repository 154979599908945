import type { TextChannel } from './TextChannel';
import type { ConversationRepository } from '../ConversationRepository';
import type { PhoneNumber } from '../PhoneNumber';
import type { ConversationId } from '../ConversationId';

export class SmsChannel implements TextChannel {
  constructor(private readonly messageRepository: ConversationRepository) {}

  readonly hasToInitializeWithTemplate = false;

  readonly canSendFreeFormMessages = true;

  readonly canSendAttachments = false;

  readonly canSendVoiceRecordings = false;

  async sendMessage(
    conversationId: ConversationId,
    phoneNumber: PhoneNumber,
    body: string,
  ): Promise<void> {
    if (!conversationId.value) {
      return this.messageRepository.create(phoneNumber, body);
    }

    return this.messageRepository.addMessage(conversationId, body);
  }
}
