import { Authorization } from '@/common/authorization/public/api';
import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { seeHelpWidget } from '../../domain/policies';

export class AuthorizationProvider implements DpProvider {
  boot(container: DependencyContainer): void {
    const authorization = container.resolve(Authorization);

    authorization.addPolicies([
      {
        name: seeHelpWidget,
        handler: user => user.hasOpenGate('HELP_WIDGET'),
      },
    ]);
  }
}
