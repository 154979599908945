import { injectable } from 'tsyringe';
import type { WhatsAppMessage } from '@/modules/timeline/domain/message-composer/message/WhatsAppMessage';
import { TimelineWhatsAppMessage } from './TimelineWhatsAppMessage';
import { OutputTimelineMediaFactory } from '../media/OutputTimelineMediaFactory';

@injectable()
export class OutputTimelineWhatsAppMessageFactory {
  constructor(private readonly outputTimelineMediaFactory: OutputTimelineMediaFactory) {}

  makeMany(timelineMessages: WhatsAppMessage[]): TimelineWhatsAppMessage[] {
    return timelineMessages.map(this.make, this);
  }

  make(timelineMessage: WhatsAppMessage): TimelineWhatsAppMessage {
    return new TimelineWhatsAppMessage(
      timelineMessage.id,
      timelineMessage.direction,
      timelineMessage.status,
      timelineMessage.body,
      timelineMessage.createdAt.isoDate,
      timelineMessage.owner?.id ?? null,
      this.outputTimelineMediaFactory.makeMany(timelineMessage.media),
    );
  }
}
