import type { TimelineEvent } from '@/modules/task/domain/timeline/TimelineEvent';
import type { Call } from '@/modules/task/domain/call/Call';

export class UnansweredCallCount {
  static ofIncoming(timelineEvents: TimelineEvent[]): number {
    return this.takeCallsSequence(timelineEvents, call => call.isUnanswered && call.isIncoming)
      .length;
  }

  static ofOutgoing(timelineEvents: TimelineEvent[]): number {
    return this.takeCallsSequence(timelineEvents, call => call.isUnanswered && call.isOutgoing)
      .length;
  }

  private static takeCallsSequence(
    timelineEvents: TimelineEvent[],
    filter: (call: Call) => boolean,
  ): TimelineEvent[] {
    const eventsCopy = [...timelineEvents].reverse();

    const indexToSlice = this.findFirstNotMatchingCallIndex(eventsCopy, filter);

    if (indexToSlice < 0) {
      return eventsCopy;
    }

    return this.sliceArrayToIndex(eventsCopy, indexToSlice);
  }

  private static findFirstNotMatchingCallIndex(
    timelineEvents: TimelineEvent[],
    filter: (event: TimelineEvent) => boolean,
  ): number {
    return timelineEvents.findIndex((event: TimelineEvent) => !filter(event));
  }

  private static sliceArrayToIndex(
    timelineEvents: TimelineEvent[],
    endIndex: number,
  ): TimelineEvent[] {
    return timelineEvents.slice(0, endIndex);
  }
}
