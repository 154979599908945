import { inject, injectable } from 'tsyringe';
import type { FilterOption } from '@/modules/tasks-list/domain/ownership-filter/options/FilterOption';
import type { OwnerRepository } from '@/modules/tasks-list/domain/ownership-filter/owner/OwnerRepository';
import type { UserRepository } from '@/modules/tasks-list/domain/ownership-filter/user/UserRepository';
import { MeOption } from '@/modules/tasks-list/domain/ownership-filter/options/MeOption';
import { EveryoneOption } from '@/modules/tasks-list/domain/ownership-filter/options/EveryoneOption';
import { OthersOption } from '@/modules/tasks-list/domain/ownership-filter/options/OthersOption';
import { OwnerRepositoryToken, UserRepositoryToken } from '@/modules/tasks-list/di/tokens';
import { FilterOptionListNotFoundError } from '@/modules/tasks-list/domain/ownership-filter/FilterOptionListNotFoundError';

@injectable()
export class FilterOptionList {
  constructor(
    @inject(OwnerRepositoryToken)
    private readonly ownerRepository: OwnerRepository,
    @inject(UserRepositoryToken)
    private readonly userRepository: UserRepository,
  ) {}

  getOptions(): FilterOption[] {
    return [...this.staticOptions, ...this.dynamicOptions];
  }

  getOptionByValue(searchedValue: string): FilterOption {
    const option = this.getOptions().find(({ value }) => value === searchedValue);

    if (!option) {
      throw new FilterOptionListNotFoundError(searchedValue);
    }

    return option;
  }

  private get staticOptions() {
    return [MeOption.make(this.currentUserId), EveryoneOption.make()];
  }

  private get dynamicOptions() {
    return this.ownerRepository
      .all()
      .sort((alpha, bravo) => alpha.name.localeCompare(bravo.name))
      .map(owner => OthersOption.make(owner.id, owner.name));
  }

  private get currentUserId() {
    return this.userRepository.getCurrentUser().id;
  }
}
