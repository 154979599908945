import { inject, injectable } from 'tsyringe';
import {
  AccessTokenRequesterToken,
  ClientCredentialsRepositoryToken,
  AccessTokenRepositoryToken,
} from '@/modules/authentication/di/token';
import type { AccessTokenRequester } from './token/AccessTokenRequester';
import type { AccessTokenRepository } from './token/AccessTokenRepository';
import type { ClientCredentialsRepository } from './client/ClientCredentialsRepository';
import type { AccessToken } from './token/AccessToken';
import { OAuthClient } from './OAuthClient';

@injectable()
export class AppOAuthClient extends OAuthClient {
  constructor(
    @inject(AccessTokenRequesterToken)
    private readonly accessTokenRequester: AccessTokenRequester,
    @inject(AccessTokenRepositoryToken)
    private readonly tokenRepository: AccessTokenRepository,
    @inject(ClientCredentialsRepositoryToken)
    private readonly clientCredentialsRepository: ClientCredentialsRepository,
  ) {
    super();
  }

  async authorizeUsingClientCredentials(): Promise<void> {
    const { clientId, clientSecret } = await this.clientCredentialsRepository.get();

    const token = await this.accessTokenRequester.requestAccessTokenWithClientCredentials({
      grantType: 'client_credentials',
      clientId,
      clientSecret,
    });

    this.tokenRepository.saveTokens('app', token);
  }

  async refreshAccessToken(): Promise<void> {
    return this.authorizeUsingClientCredentials();
  }

  findAccessToken(): AccessToken | null {
    return this.tokenRepository.findTokens('app');
  }

  deleteAccessToken(): void {
    this.tokenRepository.deleteTokens('app');
  }

  setAccessToken(accessToken: AccessToken): void {
    this.tokenRepository.saveTokens('app', accessToken);
  }
}
