import type { DpListener } from '@/core/events/DpListener';
import { delay, inject, injectable } from 'tsyringe';
import { ClientReconnectService } from '../ClientReconnectService';

@injectable()
export class FinishReconnectListener implements DpListener {
  constructor(
    @inject(delay(() => ClientReconnectService))
    private readonly clientReconnect: ClientReconnectService,
  ) {}

  handle(): void {
    this.clientReconnect.finish();
  }
}
