import { injectable } from 'tsyringe';
import { Chat } from '@/modules/help-widget/domain/chat/Chat';

@injectable()
export class HelpCenterApi {
  constructor(private readonly chat: Chat) {}

  async openChat(): Promise<void> {
    return this.chat.show();
  }
}
