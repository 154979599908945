import type { EventsListItem } from '@/modules/timeline/domain/timeline/events-list/EventsListItem';

export class EventsListSorter {
  static sort(eventsList: EventsListItem[]): EventsListItem[] {
    return [...eventsList].sort(
      (alpha, bravo) =>
        new Date(alpha.createdAt.isoDate).getTime() - new Date(bravo.createdAt.isoDate).getTime(),
    );
  }
}
