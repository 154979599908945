<template>
  <div class :class="$b()">
    <div :class="$b('label')">
      <component :is="icon" color="gray-500" :size="16" />
      <span>{{ label }}</span>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import type { Component as VueComponent } from 'vue';

import { Vue, Component, Prop } from 'vue-facing-decorator';

@Component({
  name: 'FormFieldWrapper',
})
export default class FormFieldWrapper extends Vue {
  @Prop({ type: Object, default: null })
  readonly icon: VueComponent | null;

  @Prop({ type: String, required: true })
  readonly label: string;
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables' as v;
@use '@/assets/scss/functions' as f;

.dp-form-field-wrapper {
  display: grid;
  grid-template-columns: minmax(f.px-to-rem(140px), 2fr) 3fr;
  grid-gap: v.$spacer-sm;
  align-items: self-start;

  &__label {
    display: flex;
    align-items: center;
    gap: 0.4em;
    color: v.$gray-600;
    line-height: 1.9;
    white-space: nowrap;
  }
}
</style>
