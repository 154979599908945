import { injectable } from 'tsyringe';
import type { Permission as StorePermission } from '@/api/models/Permission';
import { User } from '../../domain/permissions/User';

@injectable()
export class UserFactory {
  make(storeUser: StorePermission['user']): User {
    const hasEmail = typeof storeUser.email === 'string' && storeUser.email.length > 0;
    const avatarUrl = storeUser.avatar?.url ?? '';

    if (hasEmail) {
      return User.ofEmail(storeUser.id, storeUser.name, avatarUrl, storeUser.email);
    }

    return User.ofPhone(storeUser.id, storeUser.name, avatarUrl, storeUser.phoneNumber);
  }
}
