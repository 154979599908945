import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import {
  DateFormatterToken,
  DateManipulatorToken,
  TimezoneManipulatorToken,
} from '@/modules/task/di/tokens';
import { DateFnsDateFormatter } from '@/modules/task/adapters/time/DateFnsDateFormatter';
import * as dateFns from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';
import type { DateManipulator } from '@/modules/task/domain/time/DateManipulator';
import type { TimezoneManipulator } from '@/modules/task/domain/time/TimezoneManipulator';

export class TimeProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<DateFnsDateFormatter>(DateFormatterToken, DateFnsDateFormatter);
    container.register<DateManipulator>(DateManipulatorToken, { useValue: dateFns });
    container.register<TimezoneManipulator>(TimezoneManipulatorToken, { useValue: dateFnsTz });
  }
}
