export const WIZARD = Object.freeze({
  MAIN: 'main',
  ADD_NEW_WORK_STATION: 'add-new-work-station',
  ADD_NEW_CHANNEL: 'add-new-channel',
});

const state = {
  name: null,
};

const getters = {
  name: ({ name }) => name,
};

const mutations = {
  SET_WIZARD_NAME(state, name) {
    if (!Object.values(WIZARD).includes(name)) {
      throw new Error('Invalid onboarding wizard name');
    }
    state.name = name;
  },

  RESET_WIZARD_NAME(state) {
    state.name = null;
  },
};

const actions = {
  OPEN_ADD_WORK_STATION_WIZARD_ACTION({ commit }) {
    commit('SET_WIZARD_NAME', WIZARD.ADD_NEW_WORK_STATION);
  },

  OPEN_ADD_CHANNEL_WIZARD_ACTION({ commit, dispatch }) {
    dispatch('settings/channels/ADD_BUYING_NUMBER_STEP_ACTION', null, { root: true });
    commit('SET_WIZARD_NAME', WIZARD.ADD_NEW_CHANNEL);
  },

  OPEN_MAIN_WIZARD_ACTION({ commit }) {
    commit('SET_WIZARD_NAME', WIZARD.MAIN);
  },

  REST_ACTION({ commit }) {
    commit('RESET_WIZARD_NAME');
  },
};

export const onboarding = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
