export enum IconName {
  Intro = 'intro',
  InProgress = 'in-progress',
  Abandoned = 'abandoned',
  IncomingCompleted = 'incoming-completed',
  IncomingUnanswered = 'incoming-unanswered',
  IncomingWaiting = 'incoming-waiting',
  IncomingWaitingAndMine = 'incoming-waiting-and-mine',
  OutgoingCompleted = 'outgoing-completed',
  OutgoingUnanswered = 'outgoing-unanswered',
  OutgoingWaiting = 'outgoing-waiting',
  OutgoingWaitingAndMine = 'outgoing-waiting-and-mine',
  OutgoingWhatsAppMessage = 'outgoing-whats-app-message',
  IncomingWhatsAppMessage = 'incoming-whats-app-message',
  OutgoingSmsMessage = 'outgoing-sms-message',
  IncomingSmsMessage = 'incoming-sms-message',
  VisitConfirmation = 'visit-confirmation',
  VoiceMessage = 'voice-message',
  InboundForm = 'inbound-form',
  NewToDo = 'new-to-do',
  IncomingDpChatMessage = 'incoming-dp-chat-message',
  OutgoingDpChatMessage = 'outgoing-dp-chat-message',
  DpChatMessage = 'dp-chat-message',
}
