import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { Authorization } from '@/common/authorization/public/api';
import { AuthorizationApiAuthorizer } from '@/modules/tasks-list/adapters/authorization/AuthorizationApiAuthorizer';
import type { Authorizer } from '@/modules/tasks-list/domain/authorization/Authorizer';
import {
  FilterByPatientRequest,
  SeeNewListUi,
  SendTextMessage,
} from '../../domain/authorization/policies';
import { AuthorizerToken } from '../tokens';

export class AuthorizationProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<Authorizer>(AuthorizerToken, AuthorizationApiAuthorizer);
  }

  boot(container: DependencyContainer): void {
    const authorization = container.resolve(Authorization);

    authorization.addPolicies([
      {
        name: SendTextMessage,
        handler: user => user.isFacilityUser(),
      },
      {
        name: SeeNewListUi,
        handler: user => user.hasFeature('PATIENT_REQUESTS'),
      },
      {
        name: FilterByPatientRequest,
        handler: user => user.hasFeature('PATIENT_REQUESTS'),
      },
    ]);
  }
}
