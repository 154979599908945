import { i18n } from '@/ui/plugins/i18n';
import {
  FORM_FIELDS,
  FORM_TYPES,
  OBLIGATORY_FORM_FIELDS,
} from '@/store/modules/settings/utils/compliance-form';
import FormInput from '@/components/settings/form/FormInput.vue';
import FormInputDatepicker from '@/components/settings/form/FormInputDatepicker.vue';
import { isIncludedIn, not } from '@/utils/functions';

// ===> DOCUMENTS CONFIRMATION <===

const SECTIONS = Object.freeze({
  AUTH_INFO: 'auth_of_auth_rep_info',
  NAME_INFO: 'name_info',
  ADDRESS_INFO: 'address_info',
  ADDRESS_PROOF_INFO: 'address_proof_info',
  IDENTITY_DOC_NO_INFO: 'identity_doc_no_info',
  BUSINESS_NAME: 'business_name_info',
  BUSINESS_NUMBER: 'business_reg_no_info',
  BUSINESS_ADDRESS: 'business_address_info',
  BUSINESS_ADDRESS_PROOF: 'business_address_proof_info',
  SPANISH_FISCAL_ID: 'spanish_fiscal_identification_number_info',
  NAME_OF_AUTH: 'name_of_auth_rep_info',
});

const ACCEPTABLE_DOCUMENTS = Object.freeze({
  POWER_OF_ATTORNEY: 'power_of_attorney',
  BUSINESS_REGISTRATION: 'business_registration',
  COMMERCIAL_REGISTRAR_EXCERPT: 'commercial_registrar_excerpt',
  RENTAL_RECEIPT: 'rental_receipt',
  TAX_DOCUMENT: 'tax_document',
  TITLE_DEED: 'title_deed',
  UTILITY_BILL: 'utility_bill',
  TRADE_LICENSE: 'trade_license',
  GOVERNMENT_ISSUED_DOCUMENT: 'government_issued_document',
  PASSPORT: 'passport',
  GOVERNMENT_CORRESPONDENCE: 'government_correspondence',
  RESIDENCE_PERMIT: 'residence_permit',
  VISA: 'visa',
  NATIONAL_ID_CARD: 'national_id_card',
});

function assertSection(sectionType) {
  const isAllowedType = Object.values(SECTIONS).includes(sectionType);
  if (!isAllowedType) {
    throw new Error(`Compliance: unknown section type: "${sectionType}"`);
  }
}

function assertAcceptableDocuments(documentType) {
  const isAllowedType = Object.values(ACCEPTABLE_DOCUMENTS).includes(documentType);
  if (!isAllowedType) {
    throw new Error(`Compliance: unknown document type: "${documentType}"`);
  }
}

export function getAcceptableDocumentLabel(documentType) {
  assertAcceptableDocuments(documentType);

  const documentsMap = Object.freeze({
    [ACCEPTABLE_DOCUMENTS.POWER_OF_ATTORNEY]: i18n.global.t(
      'settings.compliance.form.acceptable_document.power_of_attorney',
    ),
    [ACCEPTABLE_DOCUMENTS.BUSINESS_REGISTRATION]: i18n.global.t(
      'settings.compliance.form.acceptable_document.business_registration',
    ),
    [ACCEPTABLE_DOCUMENTS.COMMERCIAL_REGISTRAR_EXCERPT]: i18n.global.t(
      'settings.compliance.form.acceptable_document.commercial_registrar_excerpt',
    ),
    [ACCEPTABLE_DOCUMENTS.RENTAL_RECEIPT]: i18n.global.t(
      'settings.compliance.form.acceptable_document.rental_receipt',
    ),
    [ACCEPTABLE_DOCUMENTS.TAX_DOCUMENT]: i18n.global.t(
      'settings.compliance.form.acceptable_document.tax_document',
    ),
    [ACCEPTABLE_DOCUMENTS.TITLE_DEED]: i18n.global.t(
      'settings.compliance.form.acceptable_document.title_deed',
    ),
    [ACCEPTABLE_DOCUMENTS.UTILITY_BILL]: i18n.global.t(
      'settings.compliance.form.acceptable_document.utility_bill',
    ),
    [ACCEPTABLE_DOCUMENTS.TRADE_LICENSE]: i18n.global.t(
      'settings.compliance.form.acceptable_document.trade_license',
    ),
    [ACCEPTABLE_DOCUMENTS.GOVERNMENT_ISSUED_DOCUMENT]: i18n.global.t(
      'settings.compliance.form.acceptable_document.government_issued_document',
    ),
    [ACCEPTABLE_DOCUMENTS.PASSPORT]: i18n.global.t(
      'settings.compliance.form.acceptable_document.passport',
    ),
    [ACCEPTABLE_DOCUMENTS.GOVERNMENT_CORRESPONDENCE]: i18n.global.t(
      'settings.compliance.form.acceptable_document.government_correspondence',
    ),
    [ACCEPTABLE_DOCUMENTS.RESIDENCE_PERMIT]: i18n.global.t(
      'settings.compliance.form.acceptable_document.residence_permit',
    ),
    [ACCEPTABLE_DOCUMENTS.VISA]: i18n.global.t('settings.compliance.form.acceptable_document.visa'),
    [ACCEPTABLE_DOCUMENTS.NATIONAL_ID_CARD]: i18n.global.t(
      'settings.compliance.form.acceptable_document.national_id_card',
    ),
  });

  return documentsMap[documentType];
}

function getSectionDetails(sectionType) {
  assertSection(sectionType);

  const sectionMap = Object.freeze({
    [SECTIONS.AUTH_INFO]: {
      name: i18n.global.t('settings.compliance.form.section.auth_of_auth_rep_info'),
    },
    [SECTIONS.BUSINESS_NAME]: {
      name: i18n.global.t('settings.compliance.form.section.business_name_info'),
    },
    [SECTIONS.BUSINESS_NUMBER]: {
      name: i18n.global.t('settings.compliance.form.section.business_reg_no_info'),
    },
    [SECTIONS.BUSINESS_ADDRESS]: {
      name: i18n.global.t('settings.compliance.form.section.business_address_info'),
      description: i18n.global.t(
        'settings.compliance.form.section.business_address_info.description',
      ),
    },
    [SECTIONS.BUSINESS_ADDRESS_PROOF]: {
      name: i18n.global.t('settings.compliance.form.section.business_address_proof_info'),
      description: i18n.global.t(
        'settings.compliance.form.section.business_address_proof_info.description',
      ),
    },
    [SECTIONS.ADDRESS_PROOF_INFO]: {
      name: i18n.global.t('settings.compliance.form.section.address_proof_info'),
      description: i18n.global.t('settings.compliance.form.section.address_proof_info.description'),
    },
    [SECTIONS.ADDRESS_INFO]: {
      name: i18n.global.t('settings.compliance.form.section.address_info'),
      description: i18n.global.t('settings.compliance.form.section.address_info.description'),
    },
    [SECTIONS.IDENTITY_DOC_NO_INFO]: {
      name: i18n.global.t('settings.compliance.form.section.identity_doc_no_info'),
      description: i18n.global.t(
        'settings.compliance.form.section.identity_doc_no_info.description',
      ),
    },
    [SECTIONS.SPANISH_FISCAL_ID]: {
      name: i18n.global.t(
        'settings.compliance.form.section.spanish_fiscal_identification_number_info',
      ),
    },
    [SECTIONS.NAME_OF_AUTH]: {
      name: i18n.global.t('settings.compliance.form.section.name_of_auth_rep_info'),
    },
    [SECTIONS.NAME_INFO]: {
      name: i18n.global.t('settings.compliance.form.section.name_info'),
    },
  });

  return sectionMap[sectionType];
}

function getConfirmationData(sectionType) {
  assertSection(sectionType);

  const getFullName = ({ [FORM_TYPES.PERSONAL_INFO]: personal }) => {
    const firstName = personal[FORM_FIELDS.PERSONAL_INFO.FIRST_NAME];
    const lastName = personal[FORM_FIELDS.PERSONAL_INFO.LAST_NAME];
    return [`${firstName} ${lastName}`];
  };

  const getFullAddress = ({ [FORM_TYPES.BUSINESS_INFO]: business }) => {
    const street = business[FORM_FIELDS.BUSINESS_INFO.ADDRESS];
    const cityAndZipCode = `${business[FORM_FIELDS.BUSINESS_INFO.CITY]}, ${
      business[FORM_FIELDS.BUSINESS_INFO.ZIP_CODE]
    }`;
    const country = business[FORM_FIELDS.BUSINESS_INFO.COUNTRY];

    return [street, cityAndZipCode, country];
  };

  const getBusinessName = ({ [FORM_TYPES.BUSINESS_INFO]: business }) => [
    business[FORM_FIELDS.BUSINESS_INFO.BUSINESS_NAME],
  ];

  const getDocumentNumber = ({ [FORM_TYPES.PERSONAL_INFO]: personal }) => [
    personal[FORM_FIELDS.PERSONAL_INFO.DOCUMENT_NUMBER],
  ];

  const getBusinessTaxId = ({ [FORM_TYPES.BUSINESS_INFO]: business }) => [
    business[FORM_FIELDS.BUSINESS_INFO.BUSINESS_TAX_ID],
  ];

  const getSpanishFiscalId = ({
    [FORM_TYPES.PERSONAL_INFO]: personal,
    [FORM_TYPES.BUSINESS_INFO]: business,
  }) => {
    const id = personal[FORM_FIELDS.PERSONAL_INFO.IDENTIFICATION_DOCUMENT_NUMBER];
    if (id) {
      return [id];
    }
    return [business[FORM_FIELDS.BUSINESS_INFO.BUSINESS_TAX_ID]];
  };

  const typeToFieldMap = Object.freeze({
    [SECTIONS.AUTH_INFO]: getFullName,
    [SECTIONS.NAME_INFO]: getFullName,
    [SECTIONS.NAME_OF_AUTH]: getFullName,
    [SECTIONS.BUSINESS_ADDRESS]: getFullAddress,
    [SECTIONS.ADDRESS_INFO]: getFullAddress,
    [SECTIONS.ADDRESS_PROOF_INFO]: getFullAddress,
    [SECTIONS.IDENTITY_DOC_NO_INFO]: getDocumentNumber,
    [SECTIONS.BUSINESS_ADDRESS_PROOF]: getFullAddress,
    [SECTIONS.BUSINESS_NAME]: getBusinessName,
    [SECTIONS.BUSINESS_NUMBER]: getBusinessTaxId,
    [SECTIONS.SPANISH_FISCAL_ID]: getSpanishFiscalId,
  });

  return typeToFieldMap[sectionType];
}

// ===> FORM FIELDS <==
export function getFormField(name) {
  const FORM_FIELDS_TYPES = Object.freeze({
    INPUT: FormInput,
    DATE: FormInputDatepicker,
  });

  const FORM_FIELDS_DEFINITION = Object.freeze({
    [FORM_FIELDS.END_USER_TYPE.BUSINESS]: {
      name: FORM_FIELDS.END_USER_TYPE.BUSINESS,
      label: i18n.global.t('settings.compliance.form.end_user_type.business'),
      description: i18n.global.t('settings.compliance.form.end_user_type.business.description'),
    },
    [FORM_FIELDS.END_USER_TYPE.INDIVIDUAL]: {
      name: FORM_FIELDS.END_USER_TYPE.INDIVIDUAL,
      label: i18n.global.t('settings.compliance.form.end_user_type.individual'),
      description: i18n.global.t('settings.compliance.form.end_user_type.individual.description'),
    },
    [FORM_FIELDS.BUSINESS_INFO.BUSINESS_NAME]: {
      name: FORM_FIELDS.BUSINESS_INFO.BUSINESS_NAME,
      label: i18n.global.t('settings.compliance.form.business_name'),
      rules: 'required|min_len:3',
      component: FORM_FIELDS_TYPES.INPUT,
      maxLength: 100,
    },
    [FORM_FIELDS.BUSINESS_INFO.CUSTOMER_NAME]: {
      name: FORM_FIELDS.BUSINESS_INFO.CUSTOMER_NAME,
      label: i18n.global.t('settings.compliance.form.customer_name'),
      rules: 'required|min_len:3',
      component: FORM_FIELDS_TYPES.INPUT,
      maxLength: 100,
    },
    [FORM_FIELDS.BUSINESS_INFO.ADDRESS]: {
      name: FORM_FIELDS.BUSINESS_INFO.ADDRESS,
      label: i18n.global.t('settings.compliance.form.address'),
      rules: 'required',
      component: FORM_FIELDS_TYPES.INPUT,
    },
    [FORM_FIELDS.BUSINESS_INFO.CITY]: {
      name: FORM_FIELDS.BUSINESS_INFO.CITY,
      label: i18n.global.t('settings.compliance.form.city'),
      rules: 'required',
      component: FORM_FIELDS_TYPES.INPUT,
    },
    [FORM_FIELDS.BUSINESS_INFO.ZIP_CODE]: {
      name: FORM_FIELDS.BUSINESS_INFO.ZIP_CODE,
      label: i18n.global.t('settings.compliance.form.zip_code'),
      rules: 'required',
      component: FORM_FIELDS_TYPES.INPUT,
    },
    [FORM_FIELDS.BUSINESS_INFO.REGION]: {
      name: FORM_FIELDS.BUSINESS_INFO.REGION,
      label: i18n.global.t('settings.compliance.form.region'),
      rules: 'required',
      component: FORM_FIELDS_TYPES.INPUT,
    },
    [FORM_FIELDS.BUSINESS_INFO.BUSINESS_TAX_ID]: {
      name: FORM_FIELDS.BUSINESS_INFO.BUSINESS_TAX_ID,
      label: i18n.global.t('settings.compliance.form.tax_id'),
      rules: 'required',
      component: FORM_FIELDS_TYPES.INPUT,
    },
    [FORM_FIELDS.BUSINESS_INFO.VAT_NUMBER]: {
      name: FORM_FIELDS.BUSINESS_INFO.VAT_NUMBER,
      label: i18n.global.t('settings.compliance.form.vat_number'),
      rules: 'required',
      component: FORM_FIELDS_TYPES.INPUT,
    },
    [FORM_FIELDS.PERSONAL_INFO.FIRST_NAME]: {
      name: FORM_FIELDS.PERSONAL_INFO.FIRST_NAME,
      label: i18n.global.t('settings.compliance.form.first_name'),
      rules: 'required',
      component: FORM_FIELDS_TYPES.INPUT,
    },
    [FORM_FIELDS.PERSONAL_INFO.LAST_NAME]: {
      name: FORM_FIELDS.PERSONAL_INFO.LAST_NAME,
      label: i18n.global.t('settings.compliance.form.last_name'),
      rules: 'required',
      component: FORM_FIELDS_TYPES.INPUT,
    },
    [FORM_FIELDS.PERSONAL_INFO.BIRTH_DATE]: {
      name: FORM_FIELDS.PERSONAL_INFO.BIRTH_DATE,
      label: i18n.global.t('settings.compliance.form.birth_date'),
      rules: 'required|isAdult',
      component: FORM_FIELDS_TYPES.DATE,
    },
    [FORM_FIELDS.PERSONAL_INFO.BIRTH_PLACE]: {
      name: FORM_FIELDS.PERSONAL_INFO.BIRTH_PLACE,
      label: i18n.global.t('settings.compliance.form.birth_place'),
      rules: 'required',
      component: FORM_FIELDS_TYPES.INPUT,
    },
    [FORM_FIELDS.PERSONAL_INFO.NATIONALITY]: {
      name: FORM_FIELDS.PERSONAL_INFO.NATIONALITY,
      label: i18n.global.t('settings.compliance.form.nationality'),
      rules: 'required',
      component: FORM_FIELDS_TYPES.INPUT,
    },
    [FORM_FIELDS.PERSONAL_INFO.PERSONAL_TAX_ID]: {
      name: FORM_FIELDS.PERSONAL_INFO.PERSONAL_TAX_ID,
      label: i18n.global.t('settings.compliance.form.tax_id'),
      rules: 'required',
      component: FORM_FIELDS_TYPES.INPUT,
    },
    [FORM_FIELDS.PERSONAL_INFO.DOCUMENT_NUMBER]: {
      name: FORM_FIELDS.PERSONAL_INFO.DOCUMENT_NUMBER,
      label: i18n.global.t('settings.compliance.form.document_number'),
      rules: 'required',
      component: FORM_FIELDS_TYPES.INPUT,
    },
    [FORM_FIELDS.PERSONAL_INFO.IDENTIFICATION_DOCUMENT_NUMBER]: {
      name: FORM_FIELDS.PERSONAL_INFO.IDENTIFICATION_DOCUMENT_NUMBER,
      label: i18n.global.t('settings.compliance.form.document_number'),
      rules: 'required',
      component: FORM_FIELDS_TYPES.INPUT,
    },
    [FORM_FIELDS.PERSONAL_INFO.ISSUE_DATE]: {
      name: FORM_FIELDS.PERSONAL_INFO.ISSUE_DATE,
      label: i18n.global.t('settings.compliance.form.issue_date'),
      rules: 'required|isPast',
      component: FORM_FIELDS_TYPES.DATE,
    },
    [FORM_FIELDS.PERSONAL_INFO.ISSUING_AUTHORITY]: {
      name: FORM_FIELDS.PERSONAL_INFO.ISSUING_AUTHORITY,
      label: i18n.global.t('settings.compliance.form.issuing_authority'),
      rules: 'required',
      component: FORM_FIELDS_TYPES.INPUT,
    },
  });

  return FORM_FIELDS_DEFINITION[name];
}

// ===> VUEX EXPORTS <===

function getRequiredDocuments(requiredDocuments) {
  return requiredDocuments.map(section => {
    const { name, description = null } = getSectionDetails(section.name);

    const acceptableDocuments = Object.values(section.acceptable_documents).map(({ type }) => ({
      type,
      label: getAcceptableDocumentLabel(type),
    }));

    const toBeConfirmed = getConfirmationData(section.name);

    return {
      id: section.name,
      name,
      description,
      toBeConfirmed,
      acceptableDocuments,
    };
  });
}

function getRequiredFields(requiredFields, endUserType) {
  const businessFormAdditional = Object.values(FORM_FIELDS.BUSINESS_INFO)
    .filter(not(isIncludedIn(OBLIGATORY_FORM_FIELDS[endUserType])))
    .filter(isIncludedIn(requiredFields));

  const personalForm = Object.values(FORM_FIELDS.PERSONAL_INFO).filter(
    isIncludedIn(requiredFields),
  );

  return {
    [FORM_TYPES.BUSINESS_INFO]: [...OBLIGATORY_FORM_FIELDS[endUserType], ...businessFormAdditional],
    [FORM_TYPES.PERSONAL_INFO]: personalForm,
  };
}

export const fromApi =
  endUserType =>
  ({
    required_documents: requiredDocuments,
    additional_data_attributes: additionalRequiredFields,
  }) => ({
    documentsRequirements: getRequiredDocuments(requiredDocuments),
    fieldsRequirements: getRequiredFields(additionalRequiredFields, endUserType),
  });
