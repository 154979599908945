import type { WorkstationRepository } from '@/modules/phone-widget/connection/WorkstationRepository';
import type { Workstation as StoreWorkstation } from '@/api/types/entities/workstation';
import type { Workstation } from '@/modules/phone-widget/connection/Workstation';
import type { WorkstationFactory } from '@/modules/phone-widget/connection/WorkstationFactory';

export class StoreGetterWorkstationRepository implements WorkstationRepository {
  constructor(
    private readonly factory: WorkstationFactory,
    private readonly idFinder: (id: string) => StoreWorkstation | null,
    private readonly internalNumberFinder: (id: string) => StoreWorkstation | null,
  ) {}

  find(id: string): Workstation | null {
    return this.factory.tryMake(this.idFinder(id));
  }

  findByInternalNumber(internalNumber: string): Workstation | null {
    return this.factory.tryMake(this.internalNumberFinder(internalNumber));
  }
}
