import { inject, singleton } from 'tsyringe';
import type { TaskType } from '@/modules/tasks-list/domain/task/TaskType';
import { subHours, isAfter } from 'date-fns';
import { EventTrackerToken } from '@/modules/tasks-list/di/tokens';
import type { EventTracker } from '@/modules/tasks-list/domain/tracking/EventTracker';
import { SingularEvent } from '@/modules/tasks-list/domain/SingularEvent';

@singleton()
export class OutboundCallsTracker {
  constructor(@inject(EventTrackerToken) private readonly eventTracker: EventTracker) {}

  private lastUpdate: Date | null;

  private lastUpdatedTaskId: string | null;

  private isTracking = true;

  track(taskId: string, type: TaskType): void {
    if (!this.isTracking) {
      return;
    }

    if (type !== 'outbound-call') {
      this.reset();
      return;
    }

    if (this.lastTaskIdIsNotSet()) {
      this.lastUpdate = new Date();
      this.lastUpdatedTaskId = taskId;
      return;
    }

    if (this.isPerformingUpdateOnAnotherTask(taskId) && this.isPerformingSecondUpdateWithinHour()) {
      this.eventTracker.publishSingularEvent(SingularEvent.Made2OutboundsIn1Hour);
      this.isTracking = false;
    }

    if (this.isPerformingUpdateOnAnotherTask(taskId)) {
      this.lastUpdate = new Date();
      this.lastUpdatedTaskId = taskId;
    }
  }

  private isPerformingSecondUpdateWithinHour(): boolean {
    if (!this.lastUpdate) {
      return false;
    }

    return isAfter(this.lastUpdate, subHours(new Date(), 1));
  }

  private isPerformingUpdateOnAnotherTask(taskId: string): boolean {
    return this.lastUpdatedTaskId !== taskId;
  }

  private lastTaskIdIsNotSet(): boolean {
    return !this.lastUpdatedTaskId;
  }

  private reset() {
    this.lastUpdatedTaskId = null;
    this.lastUpdate = null;
  }
}
