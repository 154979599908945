import type { DpListener } from '@/core/events/DpListener';
import { inject, injectable } from 'tsyringe';
import type { Cache } from '@/common/root/domain/Cache';
import { ChannelsCacheToken } from '@/modules/settings/di/token';

@injectable()
export class FacilityLoadedListener implements DpListener {
  constructor(@inject(ChannelsCacheToken) private readonly cache: Cache) {}

  handle(): void {
    this.cache.clear();
  }
}
