import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { StoreContextRepository } from '../../adapters/context/StoreContextRepository';
import type { ContextRepository } from '../../domain/context/ContextRepository';
import { ContextRepositoryToken } from '../tokens';

export class ContextProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<ContextRepository>(ContextRepositoryToken, StoreContextRepository);
  }
}
