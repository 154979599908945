import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { CallsRecordingStatusRepository } from '../../domain/call-recording/CallRecordingStatusRepository';
import { CallRecordingStatusRepositoryToken } from '../token';
import { SettingsCallRecordingStatusRepository } from '../../adapters/call-recording/SettingsCallRecordingStatusRepository';

@injectable()
export class CallRecordingProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<CallsRecordingStatusRepository>(
      CallRecordingStatusRepositoryToken,
      SettingsCallRecordingStatusRepository,
    );
  }
}
