import type { StoreContactForm } from '@/modules/timeline/adapters/timeline/store/contact-form/StoreContactForm';
import { ContactForm } from '@/modules/timeline/domain/contact-form/ContactForm';
import type { StoreTask } from '@/modules/timeline/adapters/StoreTask';
import { IsoDate } from '@/modules/timeline/domain/IsoDate';

export class StoreContactFormFactory {
  static make(contactForm: StoreContactForm, task: StoreTask, timezone: string): ContactForm {
    return new ContactForm(
      task.id,
      IsoDate.make(task.created_at, timezone),
      contactForm.name,
      contactForm.surname,
      contactForm.email,
      contactForm.content,
      contactForm.form,
    );
  }
}
