import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { DpEventBus } from '@/core/events/DpEventBus';
import { FacilityUnloadedEvent } from '@/modules/facility/public/events';
import { SubscriptionClearListener } from '../../domain/subscription/SubscriptionClearListener';

export class EventProvider implements DpProvider {
  boot(container: DependencyContainer): void {
    const eventBus = container.resolve(DpEventBus);
    const subscriptionClearListener = container.resolve(SubscriptionClearListener);

    eventBus.subscribe(FacilityUnloadedEvent.eventName, subscriptionClearListener);
  }
}
