import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import type { TaskAutomationStatusRepository } from '../../domain/task-automation/TaskAutomationStatusRepository';
import { HttpTaskAutomationStatusRepository } from '../../adapters/task-automation/HttpTaskAutomationStatusRepository';
import { TaskAutomationStatusRepositoryToken } from '../token';

export class TaskAutomationProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.registerSingleton<TaskAutomationStatusRepository>(
      TaskAutomationStatusRepositoryToken,
      HttpTaskAutomationStatusRepository,
    );
  }
}
