import { injectable } from 'tsyringe';
import type { SoundNotification } from './SoundNotification';

@injectable()
export class SoundPlayer {
  async play(sound: SoundNotification): Promise<void> {
    const audio = new Audio(sound.url);
    try {
      // can throw if the user didn't interact with the document first. https://goo.gl/xX8pDD
      await audio.play();
    } catch {}
  }
}
