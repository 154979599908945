<template>
  <form-field-wrapper :class="$b()" :icon="icon" :label="label">
    <div :class="$b('input-wrapper')">
      <dp-input
        :class="$b('input')"
        :disabled="disabled"
        :label="label"
        :placeholder="placeholder"
        :readonly="readonly"
        :type="type"
        :validation="validationResult"
        :model-value="value"
        hide-label
        @update:model-value="$emit('update:modelValue', $event)"
      />
      <div :class="$b('action')">
        <slot name="action" />
      </div>
    </div>
  </form-field-wrapper>
</template>

<script lang="ts">
import type { Component as VueComponent } from 'vue';

import { Vue, Component, Prop, Model } from 'vue-facing-decorator';
import { DpInput } from '@dp-ui-kit/vue';

import FormFieldWrapper from '@/ui/atoms/FormFieldWrapper/FormFieldWrapper.vue';

interface Validation {
  type: 'invalid' | 'valid';
  messages: string[];
}

@Component({
  name: 'CustomFormInput',
  components: {
    FormFieldWrapper,
    DpInput,
  },
})
export default class CustomFormInput extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly disabled: boolean;

  @Prop({ type: Object, default: null })
  readonly icon: VueComponent | null;

  @Prop({ type: String, required: true })
  readonly label: string;

  @Prop({ type: String, required: true })
  readonly placeholder: string;

  @Prop({ type: Boolean, default: false })
  readonly readonly: boolean;

  @Prop({ type: String, default: 'text' })
  readonly type: string;

  @Prop({ type: Object, default: null })
  readonly validationResult: Validation;

  @Model({ type: String, default: '' })
  readonly value: string;
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/mixins' as m;
@use 'src/assets/scss/variables' as v;

.dp-custom-form-input {
  $block: &;

  &__input-wrapper {
    position: relative;

    &:hover {
      #{$block} {
        &__action {
          display: block;
        }

        &__input:not(:focus):not([readonly]):not([disabled]) {
          background: v.$gray-100;
        }
      }
    }
  }

  &__action {
    position: absolute;
    right: 0.4em;
    top: 50%;
    transform: translateY(-50%);
    display: none;
  }
}

:deep(.dp-custom-form-input__input) {
  margin: 0;
  padding: 0;

  input {
    @include m.custom-input;

    &:focus {
      + .dp-custom-form-input__action {
        display: block;
      }
    }
  }
}
</style>
