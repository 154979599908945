import { inject, injectable } from 'tsyringe';
import type { Timeline } from '@/modules/task/domain/timeline/Timeline';
import { OwnerRepositoryToken } from '@/modules/task/di/tokens';
import type { OwnerRepository } from '@/modules/task/domain/owner/OwnerRepository';
import type { IconStrategy } from '../types';
import type { Task } from '../../task/Task';
import { IconName } from '../types';

@injectable()
export class OutgoingWaitingAndMine implements IconStrategy {
  constructor(@inject(OwnerRepositoryToken) private readonly ownerRepository: OwnerRepository) {}

  get(task: Task, { lastCall }: Timeline): null | IconName {
    return lastCall.isMine(this.ownerRepository) && lastCall.isWaiting && lastCall.isOutgoing
      ? IconName.OutgoingWaitingAndMine
      : null;
  }
}
