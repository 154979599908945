import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { StoreUserRolesRepository } from '../../adapters/user/StoreUserRolesRepository';
import { UserRolesRepositoryToken } from '../token';
import type { UserRolesRepository } from '../../domain/user/UserRolesRepository';

export class AdapterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<UserRolesRepository>(UserRolesRepositoryToken, StoreUserRolesRepository);
  }
}
