import type { URL } from '@/types/commons';
import { HttpClientError } from '@/modules/http-client/public/types';

export const isUnauthorized = (error: HttpClientError): boolean => error.statusCode === 403;
export const isNotFound = (error: HttpClientError): boolean => error.statusCode === 404;

enum ComplianceBundleErrorType {
  InvalidAddress = 'InvalidAddressErrorOutput',
}

interface ComplianceBundleAddressErrorData {
  '@context': {
    '@vocab': URL;
    hydra: URL;
    message: string;
  };
  '@id': string;
  '@type': ComplianceBundleErrorType;
  message: string;
}

export function isInvalidComplianceBundleAddress(
  e: HttpClientError<ComplianceBundleAddressErrorData>,
): boolean {
  return e.data?.['@type'] === ComplianceBundleErrorType.InvalidAddress;
}
