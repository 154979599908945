import { ref } from 'vue';
import type { Ref } from 'vue';

interface ToastConfig {
  leadingIcon?: string;
  content: string;
  variant?: 'default' | 'error';
  timeOut?: number;
}

interface ToasterMethods {
  displayInfo(message: string, icon?: string, timeOut?: number): void;
  displayError(message: string, icon?: string, timeOut?: number): void;
  toasts: Ref<ToastConfig[]>;
}

const toasts = ref<ToastConfig[]>([]);

export function useToaster(): ToasterMethods {
  const addToast = (config: ToastConfig) => {
    toasts.value = [config];
  };

  const displayInfo = (message: string, icon?: string, timeOut?: number) => {
    addToast({
      leadingIcon: icon,
      content: message,
      variant: 'default',
      timeOut: timeOut || 3000,
    });
  };

  const displayError = (message: string, icon?: string, timeOut?: number) => {
    addToast({
      leadingIcon: icon,
      content: message,
      variant: 'error',
      timeOut: timeOut || 3000,
    });
  };

  return { displayError, displayInfo, toasts };
}
