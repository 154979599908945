import { injectable } from 'tsyringe';
import type { CommentRepository } from '@/modules/task/domain/comment/CommentRepository';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { Comment } from '@/modules/task/domain/comment/Comment';

@injectable()
export class HttpCommentRepository implements CommentRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async update({ taskId, text }: Comment): Promise<void> {
    await this.httpClient.put(`/api/v0/task/${taskId}/comments`, { comment: text, todo: false });
  }
}
