import { injectable } from 'tsyringe';
import { Conference } from '../../domain/models/Conference';
import { ConferencePatient } from '../../domain/models/ConferencePatient';
import { ConferenceReception } from '../../domain/models/ConferenceReception';
import { ConferenceStatus } from '../../domain/models/ConferenceStatus';
import { ConferenceTarget } from '../../domain/models/ConferenceTarget';
import type { InputConference } from '../types/InputConference';

@injectable()
export class ConferenceFactory {
  tryMake(inputConference?: InputConference | null): Conference {
    return inputConference ? this.make(inputConference) : this.makeEmpty();
  }

  private make(inputConference: InputConference) {
    return new Conference(
      this.makeReception(inputConference.reception),
      this.makePatient(inputConference.patient),
      this.makeTarget(inputConference.target),
    );
  }

  private makeEmpty(): Conference {
    return Conference.empty();
  }

  private makeReception(reception: InputConference['reception']): ConferenceReception {
    return new ConferenceReception(this.mapStatus(reception.status));
  }

  private makePatient(patient: InputConference['patient']): ConferencePatient {
    return new ConferencePatient(this.mapStatus(patient.status), patient.on_hold);
  }

  private makeTarget(target?: InputConference['target']): ConferenceTarget {
    if (!target) {
      return ConferenceTarget.empty();
    }

    return new ConferenceTarget(this.mapStatus(target.status), target.workstation_id);
  }

  private mapStatus(status: 'invited' | 'in_conference' | 'out_of_conference'): ConferenceStatus {
    return (
      (
        {
          invited: ConferenceStatus.Invited,
          in_conference: ConferenceStatus.InConference,
          out_of_conference: ConferenceStatus.OutOfConference,
        } as const
      )[status] ?? ConferenceStatus.OutOfConference
    );
  }
}
