import { notificationsManager } from '@/app/notifications/NotificationsManager';
import { i18n } from '@/ui/plugins/i18n';
import { injectable } from 'tsyringe';
import type { NotificationService } from '../../domain/time/NotificationService';

@injectable()
export class UiNotificationService implements NotificationService {
  async showInvalidTime(): Promise<void> {
    await notificationsManager.fire({
      name: 'clock-not-synced',
      title: i18n.global.t('error.title'),
      message: i18n.global.t('notifications.error.clock_not_synced'),
    });
  }
}
