export class IsoDate {
  private constructor(
    readonly isoDate: string,
    readonly timezone: string,
  ) {}

  static make(isoDate: string, timezone: string): IsoDate {
    return new IsoDate(isoDate, timezone);
  }
}
