import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { QueryParams } from '@/modules/statistics/domain/QueryParams';
import type { CallbacksDataRepository } from '@/modules/statistics/domain/callbacks/CallbacksDataRepository';
import type { CallbacksData } from '@/modules/statistics/domain/callbacks/CallbacksData';

@injectable()
export class HttpCallbacksDataRepository implements CallbacksDataRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async get(queryParams: QueryParams): Promise<CallbacksData[]> {
    return this.httpClient.get('api/v0/facility/{facilityId}/stats/callbacks', {
      ...queryParams,
    });
  }
}
