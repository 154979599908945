import { RootStoreToken } from '@/common/root';
import type { Store } from 'vuex';
import { inject, injectable } from 'tsyringe';
import { StaticCommonEventName } from '../../domain/callbacks/StaticCommonEventName';
import type { Callback } from '../../domain/models/Callback';

@injectable()
export class PatientUploadCallback implements Callback {
  readonly name = 'CommonPatientUpload';

  readonly eventName = StaticCommonEventName.PatientUpload;

  readonly channelName = 'common';

  constructor(
    @inject(RootStoreToken)
    private readonly store: Store<unknown>,
  ) {}

  handler = (status: unknown): void => {
    this.store.commit('SET_PATIENT_UPLOAD_PROGRESS', status);
  };
}
