import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { RegulatoryRepository } from '../domain/gates/RegulatoryRepository';
import type { Regulatory } from '../domain/gates/Regulatory';

export interface FacilityStore {
  getters: {
    getUserFacility: {
      channels: {
        regulatory: Regulatory;
      };
    };
  };
}

@injectable()
export class StoreRegulatoryRepository implements RegulatoryRepository {
  constructor(@inject(RootStoreToken) private store: FacilityStore) {}

  get(): Regulatory {
    return this.store.getters.getUserFacility.channels.regulatory;
  }
}
