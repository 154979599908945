import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import type { Store } from 'vuex';
import { StoreAdapter } from './StoreAdapter';

@injectable()
export class StoreAdapterFactory {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store<unknown>,
  ) {}

  make(prefix: string): StoreAdapter {
    return new StoreAdapter(this.store, prefix);
  }
}
