<template>
  <div :data-test-id="testId">
    <dp-circle
      size="lg"
      :color="validCircleColor"
      :class="$b({ animation, minor, color: circleColor })"
    >
      <component :is="icon" :color="color" :size="24" :class="$b('icon')" />
    </dp-circle>
  </div>
</template>

<script lang="ts">
import { DpCircle } from '@dp-ui-kit/vue';
import { Vue, Component, Prop } from 'vue-facing-decorator';

import { IconName } from './TaskIcon.types';
import type { Option } from './TaskIcon.config';
import { iconOptions } from './TaskIcon.config';

@Component({
  name: 'TaskIcon',
  components: {
    DpCircle,
  },
})
export default class TaskIcon extends Vue {
  @Prop({
    type: String,
    required: true,
    validator: value => Object.values(IconName).includes(value),
  })
  readonly name: IconName;

  @Prop({ type: Boolean, default: false })
  readonly minor: boolean;

  get icon(): Option['icon'] {
    return this.option.icon;
  }

  get color(): Option['iconColor'] {
    return this.option.iconColor;
  }

  get validCircleColor(): string {
    // UI kit do not support gray-100
    // https://github.com/DocPlanner/dp-ui-kit/issues/1264
    if (this.circleColor === 'gray-100') {
      return 'gray-300';
    }

    return this.circleColor;
  }

  get circleColor(): Option['iconCircleColor'] {
    return this.option.iconCircleColor;
  }

  get animation(): string | boolean {
    return this.option.animation ?? false;
  }

  get testId(): Option['testId'] {
    return this.option.testId;
  }

  get option(): Option {
    return iconOptions[this.name];
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables' as v;
@use '@/assets/scss/functions' as f;
@use '@/assets/scss/mixins' as m;

$tile-icon-size-minor: f.px-to-rem(40px);

.dp-task-icon {
  width: v.$tile-icon-size;
  height: v.$tile-icon-size;

  &--minor {
    width: $tile-icon-size-minor;
    height: $tile-icon-size-minor;
  }

  &__icon {
    display: block;
  }

  &--animation_rotate-45 {
    @include m.default-animation-settings;
    @include m.keyframe-rotate(45deg);

    transform-origin: center center;
    animation-name: rotate;
  }

  &--color_gray-100 {
    background-color: v.$gray-100 !important;
  }
}
</style>
