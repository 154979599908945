/**
 * Keeps track of the ids of the tasks that need to be updated. It maintain their order and prevents duplicates.
 */
export class Queue {
  private readonly items = new Set<string>();

  get isEmpty(): boolean {
    return this.items.size === 0;
  }

  enqueueOrUpdate(id: string): void {
    this.items.add(id);
  }

  dequeueAll(): string[] {
    if (this.isEmpty) {
      return [];
    }

    const ids = [...this.items];

    this.items.clear();

    return ids;
  }

  getMany(amount: number): string[] {
    const all = [...this.items];

    return all.slice(0, amount);
  }

  removeMany(ids: string[]): void {
    ids.forEach(id => this.items.delete(id));
  }

  reset(): void {
    this.items.clear();
  }
}
