<template>
  <task-section-wrapper :class="$b()" :border="hasBorder" no-padding>
    <template #icon>
      <slot />
    </template>
    <ul :class="$b('list')">
      <li
        v-for="(
          { callDuration, color, date, icon, name, phoneNumber, recordingUrl }, index
        ) in visibleCalls"
        :key="`${name}-${index}`"
        :class="$b('item', { 'with-horizontal-padding': hasBorder })"
      >
        <task-call-card
          :call-duration="callDuration"
          :color="color"
          :date="date"
          :icon="icon"
          :name="name"
          :phone-number="phoneNumber"
          :recording-url="recordingUrl"
        >
          <template #actions>
            <slot :name="`actions-for-row-${index}`" />
          </template>
        </task-call-card>
      </li>
    </ul>
    <dp-button
      v-if="hasExpandButton"
      :class="$b('button')"
      color="primary"
      link-type="link"
      full-width
      @click="toggleCalls"
    >
      {{ buttonCopy }}
    </dp-button>
  </task-section-wrapper>
</template>

<script lang="ts">
import type { Component as VueComponent } from 'vue';

import { Component, Prop, Vue } from 'vue-facing-decorator';
import { DpButton } from '@dp-ui-kit/vue';

import type { DateInterface } from '@/ui/atoms/Date/Date.types';
import type { TaskCallColor } from '@/ui/molecules/TaskCallCard/TaskCallCard.types';
import TaskCallCard from '@/ui/molecules/TaskCallCard/TaskCallCard.vue';
import TaskSectionWrapper from '@/ui/molecules/TaskSectionWrapper/TaskSectionWrapper.vue';

interface CallCard {
  date: DateInterface;
  icon: VueComponent;
  name: string;
  callDuration?: string;
  color?: TaskCallColor;
  recordingUrl?: string;
}

@Component({
  name: 'CallsHistory',
  components: {
    DpButton,
    TaskCallCard,
    TaskSectionWrapper,
  },
})
export default class CallsHistory extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  readonly buttonText: string;

  @Prop({
    type: Array,
    required: true,
  })
  readonly calls: CallCard[];

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly hasBorder: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly hasIcon: boolean;

  @Prop({
    type: Number,
    default: 3,
  })
  readonly visibleCallsLimit: number;

  showAllCalls = false;

  get hasExpandButton(): boolean {
    return this.calls.length > this.visibleCallsLimit;
  }

  get visibleCalls(): CallCard[] {
    return this.showAllCalls ? this.calls : this.calls.slice(0, this.visibleCallsLimit);
  }

  get hiddenCallsNumber(): number {
    return this.calls.length - this.visibleCalls.length;
  }

  get buttonCopy(): string {
    if (!this.hasExpandButton) {
      return '';
    }

    if (this.buttonText) {
      return this.buttonText;
    }

    return this.showAllCalls
      ? this.$ts('task.call.history.less')
      : this.$ts('task.call.history.more', { number: this.hiddenCallsNumber });
  }

  toggleCalls(): void {
    this.showAllCalls = !this.showAllCalls;
    this.$emit('toggle', this.showAllCalls);
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-calls-history {
  &__item {
    padding: v.$spacer-md 0;
    border-bottom: 1px solid v.$gray-200;

    &--with-horizontal-padding {
      padding-right: v.$spacer-md;
      padding-left: v.$spacer-md;
    }

    &:last-of-type {
      border: none;
    }
  }

  &__button {
    border-top: 1px solid v.$gray-200;
  }
}
</style>
