import type { Timeline } from '@/modules/task/domain/timeline/Timeline';
import { SmsMessage } from '@/modules/task/domain/message/SmsMessage';
import type { IconStrategy } from '../types';
import { IconName } from '../types';
import type { Task } from '../../task/Task';

export class IncomingSmsMessage implements IconStrategy {
  get(task: Task, { lastUserEvent }: Timeline): null | IconName {
    if (!(lastUserEvent instanceof SmsMessage)) {
      return null;
    }

    return lastUserEvent.isInbound ? IconName.IncomingSmsMessage : null;
  }
}
