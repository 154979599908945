/* eslint-disable no-console */

import type { Logger } from './Logger';
import type { Contexts, Tags } from './types';

export class ConsoleLogger implements Logger {
  logInfoWithContext(error: Error, contexts: Contexts, tags?: Tags): void {
    console.groupCollapsed();
    console.log('logException: info :>> ', error);
    console.log('logException: contexts :>> ', contexts);
    console.log('logException: tags :>> ', tags);
    console.groupEnd();
  }

  logException(error: Error, additionalData: Record<string, unknown>): void {
    console.groupCollapsed();
    console.error('logException: error :>> ', error);
    console.error('logException: additionalData :>> ', additionalData);
    console.groupEnd();
  }

  logExceptionWithContext(error: Error, contexts: Contexts): void {
    console.groupCollapsed();
    console.error('logException: error :>> ', error);
    console.error('logException: contexts :>> ', contexts);
    console.groupEnd();
  }

  logAsInfo(error: Error): void {
    console.log('logAsInfo: error :>> ', error);
  }
}
