import type { Module } from 'vuex';
import { Panel } from '../../domain/actions/Panel';

export const panelStoreName = 'phoneWidget:panels';

export interface Store {
  getters: Record<string, any>;
  commit(name: string, payload?: any): void;
}

interface ModuleState {
  selectedPanel: Panel;
}

export const panels: Module<ModuleState, unknown> = {
  namespaced: true,
  state: () => ({
    selectedPanel: Panel.Empty,
  }),
  getters: {
    isDialKeyboardActive: state => state.selectedPanel === Panel.DialKeyboard,
    isDtmfKeyboardActive: state => state.selectedPanel === Panel.DtmfKeyboard,
    isWorkstationListActive: state => state.selectedPanel === Panel.WorkstationList,
    isInviteToConferenceActive: state => state.selectedPanel === Panel.InviteToConference,
  },
  mutations: {
    SHOW_DIAL_PANEL(state): void {
      state.selectedPanel = Panel.DialKeyboard;
    },

    SHOW_DTMF_PANEL(state): void {
      state.selectedPanel = Panel.DtmfKeyboard;
    },

    SHOW_WORKSTATION_LIST(state): void {
      state.selectedPanel = Panel.WorkstationList;
    },

    SHOW_INVITE_TO_CONFERENCE(state): void {
      state.selectedPanel = Panel.InviteToConference;
    },

    HIDE(state): void {
      state.selectedPanel = Panel.Empty;
    },
  },
};
