import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { uploadMediaFileRepository } from '@/api/repositories';
import { AxiosUploadMediaFileRepository } from '@/api/repositories/axios/AxiosUploadMediaFileRepository';

import { HttpLinePositionRepository } from '../../adapters/http/HttpLinePositionRepository';
import { HttpVoicemailRepository } from '../../adapters/http/HttpVoicemailRepository';
import { HttpVoiceMessageRepository } from '../../adapters/http/HttpVoiceMessageRepository';
import { HttpWaitingMusicRepository } from '../../adapters/http/HttpWaitingMusicRepository';
import { HttpMediaFileRepository } from '../../adapters/http/HttpMediaFileRepository';

import type { LinePositionRepository } from '../../domain/line-position/LinePositionRepository';
import type { VoicemailRepository } from '../../domain/voicemail/VoicemailRepository';
import type { VoiceMessageRepository } from '../../domain/voice-messages/VoiceMessageRepository';
import type { WaitingMusicRepository } from '../../domain/waiting-music/WaitingMusicRepository';
import type { MediaFileRepository } from '../../domain/media/MediaFileRepository';

export enum Repositories {
  VoiceMessageRepositoryToken = 'VoiceMessageRepository',
  LinePositionRepositoryToken = 'LinePositionRepository',
  VoicemailRepositoryToken = 'VoicemailRepository',
  WaitingMusicRepositoryToken = 'WaitingMusicRepository',
  UploadMediaFileRepositoryToken = 'UploadMediaFileRepository',
}

export class RepositoryProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<LinePositionRepository>(
      Repositories.LinePositionRepositoryToken,
      HttpLinePositionRepository,
    );

    container.register<VoicemailRepository>(
      Repositories.VoicemailRepositoryToken,
      HttpVoicemailRepository,
    );

    container.register<VoiceMessageRepository>(
      Repositories.VoiceMessageRepositoryToken,
      HttpVoiceMessageRepository,
    );

    container.register<WaitingMusicRepository>(
      Repositories.WaitingMusicRepositoryToken,
      HttpWaitingMusicRepository,
    );

    container.register(AxiosUploadMediaFileRepository, {
      useValue: uploadMediaFileRepository,
    });

    container.register<MediaFileRepository>(
      Repositories.UploadMediaFileRepositoryToken,
      HttpMediaFileRepository,
    );
  }
}
