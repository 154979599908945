import type { Patient } from './Patient';

export class Call {
  constructor(
    readonly id: string,
    readonly taskId: string,
    readonly status: 'abandoned' | 'completed' | 'in-progress' | 'intro' | 'unanswered' | 'waiting',
    readonly direction: 'inbound' | 'outbound',
    readonly patient: Patient,
    readonly workstationId: string | null,
  ) {}
}
