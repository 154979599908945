import { DpEventBus } from '@/core/events/DpEventBus';
import { injectable } from 'tsyringe';
import type { StoreEntityEvent } from './StoreEntityEvent';

@injectable()
export class StoreEntitiesEventPublisher {
  constructor(private readonly eventBus: DpEventBus) {}

  send(event: StoreEntityEvent): void {
    this.eventBus.publish(event);
  }
}
