import { inject, injectable } from 'tsyringe';
import { notificationsManager } from '@/app/notifications/NotificationsManager';
import { RootTranslatorToken } from '@/common/root';
import type { Translator } from '@/common/root/domain/Translator';
import type { UserNotifier } from '../domain/UserNotifier';

@injectable()
export class AppUserNotifier implements UserNotifier {
  constructor(
    @inject(RootTranslatorToken)
    private readonly translator: Translator,
  ) {}

  async clientUnauthorized(): Promise<void> {
    notificationsManager.fire({
      name: 'network',
      title: this.translator.translate('message.pusher.error_title'),
      message: this.translator.translate('message.pusher.error_content'),
    });
  }
}
