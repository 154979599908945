<template>
  <time v-if="date.dateTime" :datetime="date.dateTime">{{ date.formatted }}</time>
  <span v-else>{{ date.formatted }}</span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

import type { DateInterface } from './Date.types';

@Component({
  name: 'Date',
})
export default class Date extends Vue {
  @Prop({ type: Object, required: true })
  readonly date: DateInterface;
}
</script>
