export class TextChannel {
  constructor(private readonly type: 'sms' | 'whatsapp' | 'dp-chat') {}

  get isDpChat(): boolean {
    return this.type === 'dp-chat';
  }

  get isSms(): boolean {
    return this.type === 'sms';
  }

  get isWhatsApp(): boolean {
    return this.type === 'whatsapp';
  }
}
