import type { DependencyContainer } from 'tsyringe';
import { injectable } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { IconListToken } from '../tokens';
import { OrderedIconList } from '../../domain/icon/OrderedIconList';
import type { IconList } from '../../domain/icon/IconList';

@injectable()
export class IconsProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<IconList>(IconListToken, OrderedIconList);
  }
}
