import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { Store } from 'vuex';
import type { FacilityIdRepository } from '../../domain/customer-success-mode/FacilityIdRepository';

@injectable()
export class StoreFacilityIdRepository implements FacilityIdRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store<unknown>,
  ) {}

  get(): string {
    const facilityId = this.store.getters.getUserFacilityID;

    if (!facilityId) {
      throw new Error('Facility ID is not set');
    }

    return facilityId;
  }
}
