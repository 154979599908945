import { injectable } from 'tsyringe';
import type { DpListener } from '@/core/events/DpListener';
import { DpEventBus } from '@/core/events/DpEventBus';
import { CallFactory } from './CallFactory';
import { CallChanged } from '../webphone/events/CallChanged';

@injectable()
export class ActiveCallChangeToCallChangeListener implements DpListener {
  constructor(
    private readonly eventPublisher: DpEventBus,
    private readonly callFactory: CallFactory,
  ) {}

  handle(): void {
    const call = this.callFactory.make();

    this.eventPublisher.publish(new CallChanged(call));
  }
}
