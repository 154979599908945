import { inject, injectable } from 'tsyringe';
import { RootDateFormatterToken } from '@/common/root';
import type { DateFormatter as RootDateFormatter } from '@/common/root';
import { TimezoneRepositoryToken } from '@/modules/task/di/tokens';
import type { DateFormatter } from '@/modules/task/domain/time/DateFormatter';
import type { TimezoneRepository } from '@/modules/task/domain/time/TimezoneRepository';

@injectable()
export class DateFnsDateFormatter implements DateFormatter {
  constructor(
    @inject(RootDateFormatterToken) private readonly dateFormatter: RootDateFormatter,
    @inject(TimezoneRepositoryToken) private readonly timezoneRepository: TimezoneRepository,
  ) {}

  timestampToString(timestamp: number): string {
    return this.dateFormatter.dateFromUnixTime(timestamp, this.getTimeZone()).toDefaultDate();
  }

  stringToTimestamp(dateString: string): number {
    return this.dateFormatter.dateFromIso(dateString, this.getTimeZone()).toTimestamp();
  }

  private getTimeZone(): string {
    return this.timezoneRepository.get();
  }
}
