export enum DataExportType {
  CallLogs = 'call-logs',
  Contacts = 'contacts',
  Presence = 'presence',
  Recordings = 'recordings',
}

export class DataExport {
  constructor(
    public readonly date: string,
    public readonly type: DataExportType,
    public readonly url: string | null,
  ) {}
}
