import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { UserAndFacilityData } from '../../domain/login/me/models/UserAndFacilityData';
import { UserAndFacilityDataFactory } from './UserAndFacilityDataFactory';
import type { InputMeResponse } from './types/InputMeResponse';
import type { UserDataFetcher } from '../../domain/login/me/UserDataFetcher';

@injectable()
export class HttpUserDataFetcher implements UserDataFetcher {
  constructor(
    private readonly client: HttpClientApi,
    private readonly userAndFacilityDataFactory: UserAndFacilityDataFactory,
  ) {}

  async get(): Promise<UserAndFacilityData> {
    const inputMeResponse: InputMeResponse = await this.client.get('/api/v0/me');

    return this.userAndFacilityDataFactory.make(inputMeResponse);
  }
}
