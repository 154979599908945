import type { TimelineRepository } from '@/modules/task/domain/timeline/TimelineRepository';
import { TimelineApi } from '@/modules/timeline/public/api';
import { Timeline } from '@/modules/task/domain/timeline/Timeline';
import { injectable } from 'tsyringe';
import { TimelineMessagesCount } from '@/modules/task/domain/timeline/TimelineMessagesCount';
import { TimelineEventFactory } from './TimelineEventFactory';
import { TimelineCallCount } from '../../domain/timeline/TimelineCallCount';

@injectable()
export class ApiTimelineRepository implements TimelineRepository {
  constructor(
    private readonly timelineApi: TimelineApi,
    private readonly timelineEventFactory: TimelineEventFactory,
  ) {}

  get(id: string): Timeline {
    const { taskId, events } = this.timelineApi.getTimeline(id);

    return new Timeline(taskId, this.timelineEventFactory.makeEvents(events));
  }

  getMessagesCount(taskId: string): TimelineMessagesCount {
    const { inboundCount, outboundCount } = this.timelineApi.getMessagesCount(taskId);

    return TimelineMessagesCount.of(inboundCount, outboundCount);
  }

  getSuccessfulCallCount(taskId: string): TimelineCallCount {
    const { successfulCount, unsuccessfulCount } = this.timelineApi.getCallCount(taskId);

    return TimelineCallCount.of(successfulCount, unsuccessfulCount);
  }
}
