import { injectable } from 'tsyringe';
import { SubscriptionFetcher } from '../../domain/subscription/SubscriptionFetcher';

@injectable()
export class SubscriptionApi {
  constructor(private readonly subscriptionFetcher: SubscriptionFetcher) {}

  async get(): Promise<string | null> {
    await this.subscriptionFetcher.fetch();
    const subscription = this.subscriptionFetcher.get();
    return subscription?.pricingTier?.name ?? null;
  }
}
