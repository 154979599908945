import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { FileRepository } from '@/modules/settings/domain/traffic-control/FileRepository';
import type { OutputFile } from './types/OutputFile';

@injectable()
export class HttpFileRepository implements FileRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async savePublic(fileUrl: string): Promise<string> {
    const media: OutputFile = await this.httpClient.put(`/api/v0/facility/{facilityId}/upload`, {
      url: fileUrl,
      storage: 'public',
    });

    return media.id;
  }
}
