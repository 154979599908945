import { EntityTypes } from '@/api/types/entities';
import { StoreEntityEvent } from '@/common/root/domain/store/StoreEntityEvent';
import type { DpEventBus } from '@/core/events/DpEventBus';
import type { DpListener } from '@/core/events/DpListener';
import type { TaskListener } from '../../domain/task/TaskListener';

export class StoreTaskListener implements TaskListener {
  private readonly eventName = StoreEntityEvent.eventName;

  private readonly handler: DpListener;

  constructor(
    private readonly callback: (value: string[]) => void,
    private readonly eventBus: DpEventBus,
  ) {
    this.handler = {
      handle: this.handle.bind(this),
    };

    this.eventBus.subscribe(this.eventName, this.handler);
  }

  destroy(): void {
    this.eventBus.unsubscribe(this.eventName, this.handler);
  }

  private handle(event: StoreEntityEvent): void {
    if (event.type !== EntityTypes.Task) {
      return;
    }

    this.callback(event.value);
  }
}
