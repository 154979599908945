import type { GetterTree, Module, MutationTree } from 'vuex';
import type { VoiceMenuConfigurationOptionsList } from '../../domain/traffic-control/aggregates/VoiceMenuConfigurationOptionsList';

interface ModuleState {
  isLoadingConfiguration: boolean;
  configuration: VoiceMenuConfigurationOptionsList | null;
}

const state: ModuleState = {
  isLoadingConfiguration: false,
  configuration: null,
};

const getters: GetterTree<ModuleState, unknown> = {
  getConfiguration: state => state.configuration,
};

const mutations: MutationTree<ModuleState> = {
  SET_CONFIGURATION_LOADING(state: ModuleState, isLoading: boolean): void {
    state.isLoadingConfiguration = isLoading;
  },
  SET_CONFIGURATION(
    state: ModuleState,
    newConfiguration: VoiceMenuConfigurationOptionsList | null,
  ): void {
    state.configuration = newConfiguration;
  },
};

export const trafficControlName = 'settings:traffic-control';

export const trafficControl: Module<ModuleState, unknown> = {
  namespaced: true,
  state,
  getters,
  mutations,
};
