<template>
  <i :class="iconClass">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.5 2.05a1.05 1.05 0 1 1 2.098 0v1.09H10.5V2.05a1.05 1.05 0 1 1 2.098 0v1.092A2 2 0 0 1 14.5 5.14v8a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2V2.05Zm7.69 4.135a.7.7 0 0 1 .041.99l-3.604 3.913a.8.8 0 0 1-1.177 0L4.685 9.17a.7.7 0 0 1 1.03-.948L7.039 9.66 10.2 6.226a.7.7 0 0 1 .99-.04Z"
      />
    </svg>
  </i>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import BaseIcon from '@dp-ui-kit/icons/vue/BaseIcon.vue';

export default defineComponent({
  name: 'AppointmentConfirmedIcon',
  extends: BaseIcon as any,
  iconName: 'appointment-confirmed',
});
</script>
