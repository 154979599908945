export class User {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly email: string,
    readonly createdAt: string | null,
    readonly isManager: boolean,
    readonly roles: string[],
  ) {}

  get isEmpty(): boolean {
    return !this.id;
  }
}
