import { inject, injectable } from 'tsyringe';
import { AccessTokenRepositoryToken } from '../../di/token';
import type { AccessTokenRepository } from '../../domain/oauth/token/AccessTokenRepository';

@injectable()
export class TokenApi {
  constructor(
    @inject(AccessTokenRepositoryToken)
    private readonly tokenRepository: AccessTokenRepository,
  ) {}

  getAccessToken(): string {
    return this.tokenRepository.findTokens('user')?.accessToken ?? '';
  }
}
