import { inject, injectable } from 'tsyringe';
import type { Store } from 'vuex';
import { RootStoreToken } from '@/common/root';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { HttpTask } from './types/HttpTask';
import type { TaskCallback } from '../../domain/action/TaskCallback';

@injectable()
export class TaskCallbackAdapter implements TaskCallback {
  constructor(
    private readonly httpClient: HttpClientApi,
    @inject(RootStoreToken)
    private readonly store: Store<unknown>,
  ) {}

  async abort(taskId: string): Promise<void> {
    const task = await this.httpClient.post<HttpTask>(`/api/v0/task/${taskId}/hang-up`);
    await this.update(task);
  }

  async start(taskId: string): Promise<void> {
    await this.httpClient.post(`/api/v0/task/${taskId}/callback`);
  }

  private async update(task: HttpTask): Promise<void> {
    await this.store.dispatch('UPDATE_TASK_ACTION', [task]);
  }
}
