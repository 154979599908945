<template>
  <i :class="iconClass">
    <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3351 16.3525L15.1126 16.0988C14.5788 16.0375 14.0538 16.2213 13.6776 16.5975L12.0676 18.2075C9.59132 16.9475 7.56132 14.9263 6.30132 12.4413L7.92007 10.8225C8.29632 10.4463 8.48007 9.92126 8.41882 9.38751L8.16507 7.18251C8.06007 6.29876 7.31632 5.63376 6.42382 5.63376H4.91007C3.92132 5.63376 3.09882 6.45626 3.16007 7.44501C3.62382 14.9175 9.60007 20.885 17.0638 21.3488C18.0526 21.41 18.8751 20.5875 18.8751 19.5988V18.085C18.8838 17.2013 18.2188 16.4575 17.3351 16.3525V16.3525Z"
      />
      <path
        d="M13.4354 4.30177C13.4713 4.1175 13.5614 3.94815 13.6942 3.81536C13.7834 3.72618 13.8895 3.65578 14.0064 3.60832C14.1232 3.56086 14.2484 3.5373 14.3745 3.53904L19.7132 3.53904C19.9634 3.53909 20.2034 3.63853 20.3804 3.81549C20.5573 3.99244 20.6568 4.23243 20.6568 4.48269L20.6568 9.82133C20.6594 9.94686 20.6368 10.0716 20.5906 10.1884C20.5443 10.3051 20.4752 10.4114 20.3873 10.5011C20.2994 10.5908 20.1945 10.662 20.0788 10.7107C19.963 10.7593 19.8387 10.7844 19.7132 10.7844C19.5876 10.7844 19.4633 10.7593 19.3475 10.7107C19.2318 10.662 19.1269 10.5908 19.039 10.5011C18.9511 10.4114 18.882 10.3051 18.8358 10.1884C18.7895 10.0716 18.767 9.94686 18.7695 9.82133L18.7695 6.76099L13.0399 12.4906C12.9527 12.5803 12.8486 12.6518 12.7335 12.7009C12.6185 12.75 12.4948 12.7757 12.3698 12.7766C12.2447 12.7775 12.1207 12.7535 12.005 12.706C11.8893 12.6586 11.7841 12.5886 11.6957 12.5001C11.6073 12.4117 11.5373 12.3066 11.4898 12.1909C11.4424 12.0751 11.4184 11.9511 11.4193 11.8261C11.4202 11.701 11.4459 11.5774 11.495 11.4623C11.5441 11.3473 11.6155 11.2432 11.7052 11.156L17.4349 5.42633L14.3745 5.42633C14.1868 5.42888 14.0026 5.37538 13.8454 5.27268C13.6883 5.16998 13.5653 5.02274 13.4923 4.84979C13.4192 4.67683 13.3994 4.48603 13.4354 4.30177Z"
      />
    </svg>
  </i>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import BaseIcon from '@dp-ui-kit/icons/vue/BaseIcon.vue';

export default defineComponent({
  name: 'OutboundPhoneIcon',
  extends: BaseIcon as any,
  iconName: 'outbound-phone',
});
</script>
