import type { TranslateResult } from 'vue-i18n';
import { i18n } from '@/ui/plugins/i18n';
import responseCodeToTranslationKey from './responseCodeToTranslationKey.json';
import type { ErrorTranslator } from './ErrorTranslator';

type ErrorResponseCode = string;

export interface ErrorResponseData {
  code?: ErrorResponseCode;
  message_key?: string;
  message?: string;
}

export class ErrorMessage {
  private static fallbackTranslationKey = 'error.default';

  constructor(
    private readonly errorResponse: ErrorResponseData,
    private readonly providedDefaultMessage?: string,
    private readonly translator: ErrorTranslator = i18n.global,
  ) {}

  get translation(): TranslateResult {
    if (this.translationKey) {
      const translation = this.translator.t(this.translationKey);
      if (translation) {
        return translation;
      }
    }

    if (this.errorResponse?.message) {
      return this.errorResponse.message;
    }

    if (this.providedDefaultMessage) {
      return this.providedDefaultMessage;
    }
    return this.translator.t(ErrorMessage.fallbackTranslationKey);
  }

  private get translationKey(): string | undefined {
    const { code, message_key } = this.errorResponse;

    return ErrorMessage.getKnownErrorTranslationKey(code) || message_key;
  }

  static getKnownErrorTranslationKey(errorCode: ErrorResponseCode | undefined): string | null {
    return errorCode ? responseCodeToTranslationKey[errorCode] : null;
  }
}
