import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { PatientRepositoryFacade } from '../../adapters/PatientRepositoryFacade';
import type { PatientRepository } from '../../domain';
import { PatientRepositoryToken } from '../tokens';

@injectable()
export class RepositoryProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<PatientRepository>(PatientRepositoryToken, PatientRepositoryFacade);
  }
}
