import { singleton } from 'tsyringe';
import type { ChatController } from '../../domain/chat/ChatController';
import { ChatError } from '../../domain/chat/ChatError';

@singleton()
export class SalesForceChatController implements ChatController {
  private hasAttachedListeners = false;

  private timeout = 10 * 1000;

  private initializeCallback: (() => void) | null = null;

  private destroyCallback: (() => void) | null = null;

  async show(): Promise<void> {
    if (this.initializeCallback) {
      throw new ChatError('You cannot show the chat while it is initializing.');
    }

    this.init();

    if (!this.salesForceChat) {
      throw new ChatError('The chat is not available.');
    }

    window.embedded_svc?.onHelpButtonClick();

    await new Promise<void>((resolve, reject) => {
      this.initializeCallback = resolve;

      setTimeout(() => {
        this.initializeCallback = null;
        reject(new ChatError('The chat initialization timed out.'));
      }, this.timeout);
    });
  }

  onDestroyed(callback: (() => void) | null): void {
    if (callback !== null && this.destroyCallback) {
      throw new ChatError('You cannot set a destroy callback more than once.');
    }

    this.destroyCallback = callback;
  }

  private get salesForceChat(): typeof window.embedded_svc {
    return window.embedded_svc;
  }

  private init() {
    if (!this.salesForceChat || this.hasAttachedListeners) {
      return;
    }

    this.attachListeners();
    this.hasAttachedListeners = true;
  }

  private attachListeners() {
    this.salesForceChat?.addEventHandler('afterDestroy', async () => {
      this.destroyCallback?.();
      this.destroyCallback = null;
    });

    this.salesForceChat?.addEventHandler('afterInitialization', async () => {
      this.initializeCallback?.();
      this.initializeCallback = null;
    });
  }
}
