import type { Component as VueComponent } from 'vue';
import PhoneOutboundIcon from '@/ui/icons/OutboundPhoneIcon/OutboundPhoneIcon.vue';
import {
  VoiceMessageIcon,
  InboundPhoneIcon,
  OutboundPhoneIcon,
  BounceArrowOnPhoneLeftIcon,
  BubbleAddIcon,
  InboundWhatsAppIcon,
  OutboundWhatsAppIcon,
  InboundChatIcon,
  OutboundChatIcon,
  DpChatInbound,
  DpChatOutbound,
  DpChat,
} from '@/ui/icons';
import { DpIconPhoneCall, DpIconCalendarWithCheck, DpIconMail } from '@dp-ui-kit/icons';
import { IconName } from './TaskIcon.types';

export interface Option {
  icon: VueComponent;
  iconColor: 'danger' | 'white' | 'primary' | 'secondary' | 'success' | 'gray-600' | 'calm';
  iconCircleColor:
    | 'danger-light'
    | 'danger'
    | 'primary'
    | 'primary-light'
    | 'success'
    | 'secondary-light'
    | 'gray-100'
    | 'calm-light';
  animation?: 'rotate-45';
  testId?: string;
}

export const iconOptions: Record<IconName, Option> = {
  [IconName.IncomingCompleted]: {
    icon: InboundPhoneIcon,
    iconColor: 'gray-600',
    iconCircleColor: 'gray-100',
  },
  [IconName.IncomingUnanswered]: {
    icon: BounceArrowOnPhoneLeftIcon,
    iconColor: 'white',
    iconCircleColor: 'danger',
  },
  [IconName.IncomingWaitingAndMine]: {
    icon: InboundPhoneIcon,
    iconColor: 'primary',
    iconCircleColor: 'primary-light',
    animation: 'rotate-45',
  },
  [IconName.IncomingWaiting]: {
    icon: InboundPhoneIcon,
    iconColor: 'primary',
    iconCircleColor: 'primary-light',
  },
  [IconName.OutgoingWaitingAndMine]: {
    icon: OutboundPhoneIcon,
    iconColor: 'primary',
    iconCircleColor: 'primary-light',
    animation: 'rotate-45',
  },
  [IconName.OutgoingWaiting]: {
    icon: OutboundPhoneIcon,
    iconColor: 'primary',
    iconCircleColor: 'primary-light',
  },
  [IconName.InProgress]: {
    icon: DpIconPhoneCall,
    iconColor: 'white',
    iconCircleColor: 'success',
  },
  [IconName.Intro]: {
    icon: InboundPhoneIcon,
    iconColor: 'primary',
    iconCircleColor: 'primary-light',
  },
  [IconName.Abandoned]: {
    icon: InboundPhoneIcon,
    iconColor: 'danger',
    iconCircleColor: 'danger-light',
  },
  [IconName.NewToDo]: {
    icon: BubbleAddIcon,
    iconColor: 'gray-600',
    iconCircleColor: 'gray-100',
  },
  [IconName.OutgoingCompleted]: {
    icon: PhoneOutboundIcon,
    iconColor: 'gray-600',
    iconCircleColor: 'gray-100',
  },
  [IconName.OutgoingUnanswered]: {
    icon: PhoneOutboundIcon,
    iconColor: 'danger',
    iconCircleColor: 'danger-light',
  },
  [IconName.OutgoingWhatsAppMessage]: {
    icon: OutboundWhatsAppIcon,
    iconColor: 'gray-600',
    iconCircleColor: 'gray-100',
    testId: 'task-tile-message-icon-default',
  },
  [IconName.IncomingWhatsAppMessage]: {
    icon: InboundWhatsAppIcon,
    iconColor: 'white',
    iconCircleColor: 'danger',
    testId: 'task-tile-message-icon-new',
  },
  [IconName.OutgoingSmsMessage]: {
    icon: OutboundChatIcon,
    iconColor: 'gray-600',
    iconCircleColor: 'gray-100',
  },
  [IconName.IncomingSmsMessage]: {
    icon: InboundChatIcon,
    iconColor: 'white',
    iconCircleColor: 'danger',
  },
  [IconName.VisitConfirmation]: {
    icon: DpIconCalendarWithCheck,
    iconColor: 'gray-600',
    iconCircleColor: 'gray-100',
  },
  [IconName.VoiceMessage]: {
    icon: VoiceMessageIcon,
    iconColor: 'white',
    iconCircleColor: 'danger',
    testId: 'task-tile-voicemail-icon',
  },
  [IconName.InboundForm]: {
    icon: DpIconMail,
    iconColor: 'white',
    iconCircleColor: 'danger',
  },
  [IconName.IncomingDpChatMessage]: {
    icon: DpChatInbound,
    iconColor: 'white',
    iconCircleColor: 'danger',
  },
  [IconName.OutgoingDpChatMessage]: {
    icon: DpChatOutbound,
    iconColor: 'gray-600',
    iconCircleColor: 'gray-100',
  },
  [IconName.DpChatMessage]: {
    icon: DpChat,
    iconColor: 'gray-600',
    iconCircleColor: 'gray-100',
  },
};
