import { injectable } from 'tsyringe';
import { FormError } from '../../domain/feedback-form/FormError';
import type { FormController } from '../../domain/feedback-form/FormController';
import type { FormApi } from '../../domain/feedback-form/FormApi';

@injectable()
export class TypeformFormController implements FormController {
  async make(
    localization: 'en' | 'es' | 'it' | 'pl' | 'br' | 'mx',
    hiddenProperties: Record<string, string | number | object>,
  ): Promise<FormApi> {
    if (!window.tf) {
      throw new FormError('Typeform library is not available.');
    }

    return window.tf.createSlider(this.getFormId(localization), {
      position: 'right',
      autoClose: 5 * 1000,
      hidden: hiddenProperties,
    });
  }

  private getFormId(localization: 'en' | 'es' | 'it' | 'pl' | 'br' | 'mx'): string {
    const defaultId = 'SuOvKFr5';

    return (
      (
        {
          pl: 'SuOvKFr5',
          es: 'GcU4a5VA',
          mx: 'mNHjD6pG',
          it: 'LvQgj19i',
          br: 'BoNbZanJ',
        } as const
      )[localization] || defaultId
    );
  }
}
