import type { DpListener } from '@/core/events/DpListener';
import { injectable } from 'tsyringe';
import { ClientManager } from '../ClientManager';
import { CallbackRepository } from '../repositories/CallbackRepository';

@injectable()
export class StopClientListener implements DpListener {
  constructor(
    private readonly clientManager: ClientManager,
    private readonly callbackRepository: CallbackRepository,
  ) {}

  handle(): void {
    this.clientManager.destroy();
    this.callbackRepository.all().forEach(callback => {
      callback.reset?.();
    });
  }
}
