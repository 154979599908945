import { injectable } from 'tsyringe';
import {
  Abandoned,
  InProgress,
  InboundForm,
  IncomingCompleted,
  IncomingWhatsAppMessage,
  IncomingUnanswered,
  IncomingWaiting,
  IncomingWaitingAndMine,
  Intro,
  NewToDo,
  OutgoingCompleted,
  OutgoingWhatsAppMessage,
  OutgoingUnanswered,
  OutgoingWaiting,
  OutgoingWaitingAndMine,
  VisitConfirmation,
  VoiceMessage,
  PatientRequest,
  IncomingSmsMessage,
  OutgoingSmsMessage,
  IncomingDpChatMessage,
  OutgoingDpChatMessage,
} from '@/modules/task/domain/icon/icons';
import type { IconStrategy } from './types';
import type { IconList } from './IconList';

@injectable()
export class OrderedIconList implements IconList {
  constructor(
    private readonly abandoned: Abandoned,
    private readonly inProgress: InProgress,
    private readonly inboundForm: InboundForm,
    private readonly incomingCompleted: IncomingCompleted,
    private readonly incomingWhatsAppMessage: IncomingWhatsAppMessage,
    private readonly incomingSmsMessage: IncomingSmsMessage,
    private readonly incomingUnanswered: IncomingUnanswered,
    private readonly incomingWaiting: IncomingWaiting,
    private readonly incomingWaitingAndMine: IncomingWaitingAndMine,
    private readonly intro: Intro,
    private readonly newToDo: NewToDo,
    private readonly outgoingCompleted: OutgoingCompleted,
    private readonly outgoingWhatsAppMessage: OutgoingWhatsAppMessage,
    private readonly outgoingSmsMessage: OutgoingSmsMessage,
    private readonly outgoingUnanswered: OutgoingUnanswered,
    private readonly outgoingWaiting: OutgoingWaiting,
    private readonly outgoingWaitingAndMine: OutgoingWaitingAndMine,
    private readonly visitConfirmation: VisitConfirmation,
    private readonly voicemail: VoiceMessage,
    private readonly patientRequest: PatientRequest,
    private readonly incomingDpChatMessage: IncomingDpChatMessage,
    private readonly outgoingDpChatMessage: OutgoingDpChatMessage,
  ) {}

  get(): IconStrategy[] {
    return [
      this.intro,
      this.inProgress,
      this.outgoingWaitingAndMine,
      this.incomingWaitingAndMine,
      this.outgoingWaiting,
      this.incomingWaiting,
      this.patientRequest,
      this.incomingWhatsAppMessage,
      this.outgoingWhatsAppMessage,
      this.incomingSmsMessage,
      this.outgoingSmsMessage,
      this.incomingDpChatMessage,
      this.outgoingDpChatMessage,
      this.inboundForm,
      this.visitConfirmation,
      this.voicemail,
      this.newToDo,
      this.abandoned,
      this.outgoingUnanswered,
      this.incomingUnanswered,
      this.outgoingCompleted,
      this.incomingCompleted,
    ];
  }
}
