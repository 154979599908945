import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { PauseRepository } from '../../domain/pause/PauseRepository';
import type { UserSession } from '../../domain/pause/UserSession';
import { UserSessionFactory } from './UserSessionFactory';
import type { InputUserSession } from './InputUserSession';

@injectable()
export class HttpPauseRepository implements PauseRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly userSessionFactory: UserSessionFactory,
  ) {}

  async breakStart(endTime: string): Promise<UserSession> {
    const inputUserSession = await this.httpClient.put<InputUserSession>(
      `/api/v0/facility/{facilityId}/break`,
      {
        until: endTime,
      },
    );

    return this.userSessionFactory.make(inputUserSession);
  }

  async breakEnd(): Promise<UserSession> {
    const inputUserSession = await this.httpClient.delete<InputUserSession>(
      '/api/v0/facility/{facilityId}/break',
    );

    return this.userSessionFactory.make(inputUserSession);
  }
}
