export enum StatisticsMenu {
  AccessStatisticsPage = 'access statistics',
  AccessStatisticsOverviewPage = 'access statistics overview page',
  AccessStatisticsOverviewOutboundPage = 'access statistics overview outbound page',
  AccessStatisticsTrafficPage = 'access statistics traffic page',
  AccessStatisticsCallbackPage = 'access statistics callback page',
  AccessStatisticsChannelsPage = 'access statistics channels page',
  AccessStatisticsTeamPage = 'access statistics team page',
  AccessStatisticsSubjectsPage = 'access statistics subjects page',
  AccessStatisticsQualityPage = 'access statistics quality page',
  AccessStatisticVisitConfirmationPage = 'access statistic visit confirmation page',
}

export enum StatisticsFilters {
  AccessStatisticsChannelsFilter = 'access statistics channels filter',
}

export enum StatisticsOverview {
  AccessStatisticsOverviewOutboundPage = 'access statistics overview outbound page',
}

export enum StatisticsTraffic {
  ShowPresenceOnHeatmap = 'see presence dot on traffic heatmap',
}

export const AccessOmniStatistics = 'access omni statistics';
