import { schema } from 'normalizr';
import { EntityTypes } from '@/api/types/entities';

export const upcomingVisitOutputSchema = new schema.Entity(
  EntityTypes.UpcomingVisitOutput,
  {},
  {
    idAttribute(_, { phone_number }) {
      return phone_number;
    },
  },
);
