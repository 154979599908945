import { RootStoreToken } from '@/common/root';
import { $GET_ENTITIES_BY_TYPE } from '@dp-vue/entities';
import { inject, injectable } from 'tsyringe';
import type { TaskCall } from '../../domain/models/TaskCall';
import type { TaskCallRepository } from '../../domain/repositories/TaskCallRepository';
import { TaskCallFactory } from '../factories/TaskCallFactory';
import type { InputCall } from '../types/InputCall';

interface Store {
  getters: {
    [$GET_ENTITIES_BY_TYPE]: (type: 'call') => InputCall[];
  };
}

@injectable()
export class StoreTaskCallRepository implements TaskCallRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
    private readonly taskCallFactory: TaskCallFactory,
  ) {}

  all(): TaskCall[] {
    const calls = this.store.getters[$GET_ENTITIES_BY_TYPE]('call');
    return this.taskCallFactory.makeMany(calls);
  }
}
