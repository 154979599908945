import type { User } from '@/modules/authentication/domain/login/User';
import { inject, injectable } from 'tsyringe';
import { UserToken, RouterToken } from '@/modules/authentication/di/token';
import type { Router } from '@/modules/authentication/domain/Router';

@injectable()
export class RedirectionDispatcher {
  constructor(
    @inject(RouterToken) private readonly router: Router,
    @inject(UserToken) private readonly user: User,
  ) {}

  async redirectAuthorized(): Promise<void> {
    if (this.user.hasNoFacility() && this.user.canSwitchFacility()) {
      await this.router.navigateToFacilitySelection();
      return;
    }

    if (this.user.hasToSelectPhone()) {
      await this.router.navigateToPhoneSelection();
      return;
    }

    await this.router.navigateToHome();
  }

  async redirectUnauthorized(): Promise<void> {
    await this.router.navigateToLogin();
  }
}
