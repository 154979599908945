<template>
  <button :class="$b({ theme })" :disabled="disabled" @click="changeSpeed">
    {{ displayedLabel }}
  </button>
</template>

<script lang="ts">
import { Vue, Component, Model, Prop } from 'vue-facing-decorator';

import { isIncludedIn } from '@/utils/functions';
import { PlayerTheme } from './AudioPlayerSpeedControl.types';

const availableSpeed = [1, 1.5, 2] as const;

@Component({
  name: 'AudioPlayerSpeedControl',
})
export default class AudioPlayerSpeedControl extends Vue {
  @Model({
    type: Number,
    default: 1,
    validator: isIncludedIn(availableSpeed),
  })
  speed: 1 | 1.5 | 2;

  @Prop({ type: Boolean, default: false })
  readonly disabled: boolean;

  @Prop({ type: String, default: PlayerTheme.White })
  readonly theme: PlayerTheme;

  get displayedLabel(): string {
    return `${this.speed}×`;
  }

  changeSpeed(): void {
    const values = availableSpeed;
    const currentIndex = values.indexOf(this.speed);
    this.speed = values[(currentIndex + 1) % values.length];
  }
}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

$speed-control-size: v.$spacer * 2;
$speed-control-themes: (
  'white': (
    bg-color: v.$white,
    hover-bg-color: v.$primary-light,
    hover-text-color: v.$primary-dark,
  ),
  'gray-100': (
    bg-color: v.$gray-100,
    hover-bg-color: v.$primary-light,
    hover-text-color: v.$primary-dark,
  ),
  'primary-light': (
    bg-color: v.$primary-light,
    hover-bg-color: v.$bubble-primary-light,
    hover-text-color: v.$primary-dark,
  ),
);

.dp-audio-player-speed-control {
  width: $speed-control-size;
  height: $speed-control-size;
  border-radius: v.$border-radius;
  color: v.$gray-900;
  font-size: v.$font-size-sm;
  transition: 0.3s ease-out;

  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }

  @each $theme, $theme-map in $speed-control-themes {
    &--theme_#{$theme} {
      background-color: map_get($theme-map, bg-color);

      &:active,
      &:hover:not([disabled]) {
        background-color: map_get($theme-map, hover-bg-color);
        color: map_get($theme-map, hover-text-color);
      }
    }
  }
}
</style>
