<template>
  <div :class="$b({ border, horizontal })">
    <time :class="$b('date')" :datetime="date.isoDate">{{ formattedDate }}</time>
    <span v-if="horizontal" :class="$b('spacer')">–</span>
    <div :class="$b('name')">
      {{ doctor }}
    </div>
    <span v-if="horizontal" :class="$b('spacer')">–</span>
    <div :class="$b('service')">
      {{ service }}
    </div>
    <div :class="$b('status')">
      {{ status }}
    </div>
  </div>
</template>

<script lang="ts">
import { DpIconCalendar } from '@dp-ui-kit/icons';
import { Component, Prop, Vue } from 'vue-facing-decorator';

import type { IsoDate } from '@/ui/types';

@Component({
  name: 'AppointmentCard',
  components: { DpIconCalendar },
})
export default class AppointmentCard extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly border: boolean;

  @Prop({ type: Object, required: true })
  readonly date: IsoDate;

  @Prop({ type: String, required: true })
  readonly doctor: string;

  @Prop({ type: Boolean, default: false })
  readonly horizontal: boolean;

  @Prop({ type: String, required: true })
  readonly service: string;

  @Prop({ type: String, default: '' })
  readonly status: string;

  get formattedDate(): string {
    return this.$dateFormatter
      .dateFromIso(this.date.isoDate, this.date.timezone)
      .toDateAndTimeWithWeekDayName();
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/functions' as f;
@use 'src/assets/scss/variables' as v;

.dp-appointment-card {
  $block: &;

  color: v.$black;
  font-size: v.$font-size-base;
  line-height: v.$line-height-normal;

  &--border {
    width: f.px-to-rem(300px);
    padding: v.$spacer-sm v.$spacer-md;
    border-radius: v.$border-radius;
    border: 1px solid v.$gray-300;
  }

  &--horizontal {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    gap: v.$spacer-sm;
    color: v.$gray-600;

    #{$block} {
      &__date,
      &__spacer,
      &__name,
      &__service {
        margin: 0;
        order: 1;
        white-space: nowrap;
      }

      &__status {
        margin: 0;
        color: v.$black;
        font-weight: v.$font-weight-medium;
      }
    }
  }

  &__service,
  &__name {
    color: v.$gray-600;
  }

  &__name {
    margin-top: v.$spacer-xs;
  }

  &__status {
    margin-top: v.$spacer-xs;
    font-weight: v.$font-weight-semibold;
  }
}
</style>
