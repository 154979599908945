import { injectable } from 'tsyringe';
import type {
  Patient,
  PatientCollection,
  PatientRepository,
  UpdatedPatient,
  NewPatient,
  Criteria,
} from '../domain';
import { HttpPatientRepository } from './HttpPatientRepository';
import { StorePatientRepository } from './StorePatientRepository';

@injectable()
export class PatientRepositoryFacade implements PatientRepository {
  constructor(
    private readonly httpPatientRepository: HttpPatientRepository,
    private readonly storePatientRepository: StorePatientRepository,
  ) {}

  get(id: string): Patient {
    return this.storePatientRepository.get(id);
  }

  getByPhoneNumber(phoneNumber: string): Patient {
    return this.storePatientRepository.getByPhoneNumber(phoneNumber);
  }

  async create(newPatient: NewPatient): Promise<Patient> {
    return this.httpPatientRepository.create(newPatient);
  }

  async update(patientId: string, updatedPatient: UpdatedPatient): Promise<Patient> {
    return this.httpPatientRepository.update(patientId, updatedPatient);
  }

  async getPatientsByQuery(query = '', params?: Criteria): Promise<PatientCollection> {
    const patientCollection = await this.httpPatientRepository.getPatientsByQuery(query, params);
    this.storePatientRepository.createOrUpdate(patientCollection.patients);
    return patientCollection;
  }
}
