<template>
  <section :class="$b()">
    <h3 :class="$b('title')">
      {{ title }}
    </h3>
    <div :class="$b('content', { 'inline-content': inlineContent })">
      <slot />
    </div>
  </section>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

@Component({
  name: 'OmniTaskSidebarSection',
})
export default class OmniTaskSidebarSection extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly inlineContent: boolean;

  @Prop({ type: String, required: true })
  readonly title: string;
}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-omni-task-sidebar-section {
  padding: v.$spacer-lg;
  border-bottom: 1px solid v.$gray-200;

  &:last-of-type {
    border: none;
  }

  &__title {
    margin-bottom: v.$spacer-md;
    color: v.$gray-600;
    font-family: v.$font-family-base;
    font-size: v.$font-size-sm;
    font-weight: v.$font-weight-medium;
    text-transform: uppercase;
  }

  &__content {
    &--inline-content {
      display: flex;
      flex-wrap: wrap;
      gap: 0.625em;
    }
  }
}
</style>
