export class TaskAutomationStatus {
  constructor(
    readonly isEnabled: boolean,
    readonly areTermsAccepted: boolean,
  ) {}

  get requiresTermsAcceptance(): boolean {
    return !this.areTermsAccepted;
  }
}
