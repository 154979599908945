import { NormalizeMapper } from '@/api/mappers/NormalizeMapper';
import { Facility } from '@/api/models/Facility';
import { Task } from '@/api/models/Task';
import { ChannelPhone } from '@/api/models/ChannelPhone';
import { UpcomingVisit } from '@/api/models/UpcomingVisit';
import type { InputPatient } from '@/modules/patient/public/types';
import { UpcomingVisitOutput } from '../models/UpcomingVisitOutput';
import { patientSchema } from '../schemas/patient-schema';

export const facilityMapper = new NormalizeMapper<Facility>(
  Facility.schema,
  Facility.make,
  Facility.make,
);
export const patientMapper = new NormalizeMapper<InputPatient>(
  patientSchema,
  (patient: InputPatient): InputPatient => patient,
  (patient: InputPatient): InputPatient => patient,
);
export const taskMapper = new NormalizeMapper<Task>(Task.schema, Task.make, Task.denormalizeMake);
export const settingsChannelsPhonesMapper = new NormalizeMapper<ChannelPhone>(
  ChannelPhone.schema,
  ChannelPhone.make,
  ChannelPhone.make,
);
export const upcomingVisitMapper = new NormalizeMapper<UpcomingVisit>(
  UpcomingVisit.schema,
  UpcomingVisit.make,
  UpcomingVisit.make,
);
export const upcomingVisitOutputMapper = new NormalizeMapper<UpcomingVisitOutput>(
  UpcomingVisitOutput.schema,
  UpcomingVisitOutput.make,
  UpcomingVisitOutput.make,
);

export const normalizer = {
  facilityMapper,
  patientMapper,
  taskMapper,
  settingsChannelsPhonesMapper,
  upcomingVisitMapper,
  upcomingVisitOutputMapper,
};
