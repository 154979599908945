import { getName } from '@/store/schema/patient';
import { phoneNumberFormatter } from '@/utils/phone-number-formatter';

export class Patient {
  private static readonly prefix = 'patient:';

  constructor(
    public readonly id: string | null,
    public readonly number: string,
    public readonly name = '',
    public readonly surname = '',
  ) {}

  get displayName(): string {
    return this.fullName || this.formattedNumber;
  }

  get formattedNumber(): string {
    return phoneNumberFormatter(this.number);
  }

  get fullName(): string {
    return getName(this);
  }

  get uri(): string {
    return `${Patient.prefix}${this.id}`;
  }

  static findId(to: string): string | null {
    if (to.startsWith(Patient.prefix)) {
      return to.substring(Patient.prefix.length);
    }

    return null;
  }
}
