import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { LocaleDetectorToken } from '@/modules/authentication/di/token';
import { RootLanguageConfigToken } from '@/common/root';
import type { LocaleDetector } from '@/modules/authentication/domain/LocaleDetector';

export class LocaleProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<LocaleDetector>(LocaleDetectorToken, {
      useFactory: c => c.resolve(RootLanguageConfigToken),
    });
  }
}
