import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { FocusTarget } from '@/modules/task/domain/modal/FocusTarget';

interface Store {
  getters: {
    'task:modal-controller/focusTarget': 'comment' | 'reminder' | null;
  };
}

@injectable()
export class StoreFocusTarget implements FocusTarget {
  constructor(@inject(RootStoreToken) private readonly store: Store) {}

  get isOnComment(): boolean {
    return this.store.getters['task:modal-controller/focusTarget'] === 'comment';
  }

  get isOnReminder(): boolean {
    return this.store.getters['task:modal-controller/focusTarget'] === 'reminder';
  }
}
