import type { GetterTree, Module, MutationTree } from 'vuex';
import { defaultTimePeriod } from '@/app/formatters/date/date-picker-shortcuts';
import type { DateString } from '@/types/commons';
import type { RootState } from '../types';

interface State {
  activeFilters: {
    dateRange: [DateString, DateString];
    personnel: string;
    phone: string;
  };
}

const state: State = {
  activeFilters: {
    dateRange: defaultTimePeriod,
    personnel: '',
    phone: '',
  },
};

const mutations: MutationTree<State> = {
  SET_ACTIVE_FILTER(state, { name, value }) {
    state.activeFilters[name] = value;
  },
};

const getters: GetterTree<State, RootState> = {
  getActiveFilters: state => state.activeFilters,
  getFilterDateRange: state => state.activeFilters.dateRange,
  getFilterPersonnel: state => state.activeFilters.personnel,
  getFilterPhone: state => state.activeFilters.phone,
};

export const stats: Module<State, RootState> = {
  state,
  mutations,
  getters,
};
