import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { RouterProvider } from '@/modules/authentication/di/providers/RouterProvider';
import { AdapterProvider } from '@/modules/authentication/di/providers/AdapterProvider';
import { RepositoryProvider } from '@/modules/authentication/di/providers/RepositoryProvider';
import { HttpClientModule } from '@/modules/http-client';
import { RootModule } from '@/common/root';
import { PasswordResetProvider } from './providers/PasswordResetProvider';
import { OAuthProvider } from './providers/OAuthProvider';
import { SsoProvider } from './providers/SsoProvider';
import { LocaleProvider } from './providers/LocaleProvider';
import { LoginProvider } from './providers/LoginProvider';

export class AuthenticationModule implements DpModule {
  static readonly namespace = Symbol('authModule');

  static readonly requires = [RootModule, HttpClientModule];

  providers(): IDpProvider[] {
    return [
      RouterProvider,
      AdapterProvider,
      LoginProvider,
      RepositoryProvider,
      SsoProvider,
      LocaleProvider,
      OAuthProvider,
      PasswordResetProvider,
    ];
  }
}
