export const FORM_TYPES = Object.freeze({
  END_USER_TYPE: 'endUserType',
  BUSINESS_INFO: 'businessInformation',
  PERSONAL_INFO: 'personalInformation',
  DOCUMENTS: 'documents',
});

export const FORM_STEPS = Object.freeze({
  END_USER_PAGE: 'endUserPage',
  BUSINESS_PAGE: 'businessPage',
  ADDRESS_PAGE: 'addressPage',
  PERSONAL_PAGE: 'personalPage',
  DOCUMENTS_PAGE: 'documentsPage',
});

export const FORM_FIELDS = Object.freeze({
  END_USER_TYPE: {
    BUSINESS: 'business',
    INDIVIDUAL: 'individual',
  },
  BUSINESS_INFO: {
    BUSINESS_NAME: 'name',
    CUSTOMER_NAME: 'customerName',
    ADDRESS: 'address',
    CITY: 'city',
    ZIP_CODE: 'postalCode',
    REGION: 'region',
    BUSINESS_TAX_ID: 'businessTaxId',
    VAT_NUMBER: 'vatNumber',
  },
  PERSONAL_INFO: {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    BIRTH_DATE: 'dateOfBirth',
    BIRTH_PLACE: 'birthPlace',
    NATIONALITY: 'nationality',
    PERSONAL_TAX_ID: 'personalTaxId',
    DOCUMENT_NUMBER: 'documentNumber',
    IDENTIFICATION_DOCUMENT_NUMBER: 'identificationDocumentNumber',
    ISSUE_DATE: 'issueDate',
    ISSUING_AUTHORITY: 'issuingAuthority',
  },
});

export const OBLIGATORY_FORM_FIELDS = Object.freeze({
  [FORM_FIELDS.END_USER_TYPE.BUSINESS]: [
    FORM_FIELDS.BUSINESS_INFO.BUSINESS_NAME,
    FORM_FIELDS.BUSINESS_INFO.ADDRESS,
    FORM_FIELDS.BUSINESS_INFO.CITY,
    FORM_FIELDS.BUSINESS_INFO.ZIP_CODE,
    FORM_FIELDS.BUSINESS_INFO.REGION,
  ],
  [FORM_FIELDS.END_USER_TYPE.INDIVIDUAL]: [
    FORM_FIELDS.BUSINESS_INFO.CUSTOMER_NAME,
    FORM_FIELDS.BUSINESS_INFO.ADDRESS,
    FORM_FIELDS.BUSINESS_INFO.CITY,
    FORM_FIELDS.BUSINESS_INFO.ZIP_CODE,
    FORM_FIELDS.BUSINESS_INFO.REGION,
  ],
});
