import type { DependencyContainer } from 'tsyringe';
import { injectable } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { PluginLanguageConfigProvider } from '@/common/root/adapters/PluginLanguageConfigProvider';

export const RootLanguageConfigToken = Symbol('rootLanguageConfig');

@injectable()
export class RootLanguageConfigProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<PluginLanguageConfigProvider>(
      RootLanguageConfigToken,
      PluginLanguageConfigProvider,
    );
  }
}
