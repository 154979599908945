import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import {
  VisitRenderEvaluatorToken,
  VisitConfirmationRepositoryToken,
  UpcomingVisitRepositoryToken,
} from '@/modules/task/di/tokens';
import { BookVisitModuleVisitConfirmationRepository } from '@/modules/task/adapters/visit/confirmation/BookVisitModuleVisitConfirmationRepository';
import { BookVisitModuleVisitRenderEvaluator } from '@/modules/task/adapters/visit/BookVisitModuleVisitRenderEvaluator';
import type { VisitConfirmationRepository } from '@/modules/task/domain/visit/VisitConfirmationRepository';
import type { VisitRenderEvaluator } from '@/modules/task/domain/visit/VisitRenderEvaluator';
import type { UpcomingVisitRepository } from '@/modules/task/domain/visit/UpcomingVisitRepository';
import { BookVisitModuleUpcomingVisitRepository } from '@/modules/task/adapters/visit/upcoming/BookVisitModuleUpcomingVisitRepository';

export class VisitProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<VisitConfirmationRepository>(
      VisitConfirmationRepositoryToken,
      BookVisitModuleVisitConfirmationRepository,
    );
    container.register<VisitRenderEvaluator>(
      VisitRenderEvaluatorToken,
      BookVisitModuleVisitRenderEvaluator,
    );
    container.register<UpcomingVisitRepository>(
      UpcomingVisitRepositoryToken,
      BookVisitModuleUpcomingVisitRepository,
    );
  }
}
