import type { AudioValidator } from '@/modules/phone-widget/domain/webphone/environment-validation/AudioValidator';

export class BrowserAudioValidator implements AudioValidator {
  async hasMicrophoneAccess(): Promise<boolean> {
    try {
      // In the future it might be replaced with permission API (https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API)
      // Right now it's not supported by Firefox.
      const stream: MediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // Calling getUserMedia will start the media track selected.
      // This is not desired as the user may get the impression the mic is in use.
      // Therefore, we want to avoid having tracks started when they're not needed.
      // We only wanted to get the input device list so we stop the tracks immediately.
      // source: https://www.twilio.com/docs/voice/sdks/javascript/best-practices#working-with-microphones-and-getusermedia
      stream.getTracks().forEach(track => {
        track.stop();
      });
      return true;
    } catch {
      return false;
    }
  }
}
