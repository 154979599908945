import { ConsoleLogger } from './ConsoleLogger';
import type { Logger } from './Logger';
import type { Contexts, Tags } from './types';

export class LoggerStrategy {
  constructor(private loggingStrategy: Logger = new ConsoleLogger()) {}

  updateStrategy(newStrategy: Logger): void {
    this.loggingStrategy = newStrategy;
  }

  logAsInfo(error: Error): void {
    this.loggingStrategy.logAsInfo(error);
  }

  logInfoWithContext(error: Error, contexts: Contexts, tags?: Tags): void {
    this.loggingStrategy.logInfoWithContext(error, contexts, tags);
  }

  logException(error: Error, additionalData: Record<string, unknown> = {}): void {
    this.loggingStrategy.logException(error, additionalData);
  }

  logExceptionWithContext(error: Error, contexts: Contexts, tags?: Tags): void {
    this.loggingStrategy.logExceptionWithContext(error, contexts, tags);
  }
}
