import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { MemoryCache } from '../adapters/MemoryCache';

export const RootCacheToken = Symbol('RootCache');

export class RootCacheProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register(RootCacheToken, MemoryCache);
  }
}
