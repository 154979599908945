import axios from 'axios';
import type { HttpRequestExecutor } from '@/modules/http-client/domain/request/HttpRequestExecutor';
import type { HttpRequestConfig } from '@/modules/http-client/domain/request/HttpRequestConfig';
import { injectable } from 'tsyringe';
import { HttpClientError } from '@/modules/http-client/domain/client/HttpClientError';

@injectable()
export class AxiosHttpRequestExecutor implements HttpRequestExecutor {
  async request<T>(config: HttpRequestConfig): Promise<T> {
    try {
      return await axios.request(config);
    } catch (e) {
      throw new HttpClientError(e.message, e.response?.status, e.response?.data);
    }
  }
}
