import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { RootLanguageConfigToken } from '@/common/root';
import { LocaleProviderToken } from '@/modules/tasks-list/di/tokens';
import type { LocaleDetector } from '../../domain/LocaleDetector';

export class LocaleProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<LocaleDetector>(LocaleProviderToken, {
      useFactory: c => c.resolve(RootLanguageConfigToken),
    });
  }
}
