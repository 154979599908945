import { sleep } from '@/utils/promises';
import type * as Sentry from '@sentry/vue';
import isEmpty from 'lodash-es/isEmpty';
import type { Logger } from './Logger';
import type { Contexts, Tags } from './types';

type Severity = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';

export class SentryLogger implements Logger {
  private constructor(private sentry: typeof Sentry) {}

  logException(error: Error, additionalData: Record<string, unknown>): void {
    if (isEmpty(additionalData)) {
      this.sentry.captureException(error);
      return;
    }

    this.sentry.withScope(scope => {
      scope.setContext('Additional data', additionalData);
      this.sentry.captureException(error);
    });
  }

  logExceptionWithContext(error: Error, contexts: Contexts, tags?: Tags): void {
    this.logWithContext(error, contexts, tags);
  }

  logInfoWithContext(error: Error, contexts: Contexts, tags?: Tags): void {
    this.logWithContext(error, contexts, tags, 'info');
  }

  logAsInfo(error: Error): void {
    this.sentry.withScope(scope => {
      scope.setLevel('info');
      this.sentry.captureException(error);
    });
  }

  private logWithContext(error: Error, contexts: Contexts, tags?: Tags, severity?: Severity): void {
    this.sentry.withScope(scope => {
      if (severity) {
        scope.setLevel(severity);
      }

      Object.entries(contexts).forEach(([contextName, context]) => {
        scope.setContext(contextName, context);
      });

      if (tags) {
        this.sentry.setTags(tags);
      }

      this.sentry.captureException(error);
    });
  }

  static async make(sentry: typeof Sentry, queue = 0): Promise<SentryLogger> {
    if (queue > 5) {
      throw new Error(`Sentry wasn't able to initialize`);
    }

    const isSentryInitialized = sentry.getClient() !== undefined;

    if (!isSentryInitialized) {
      const second = 1000;
      await sleep(2 ** queue * second);
      const newQueue = queue + 1;
      return SentryLogger.make(sentry, newQueue);
    }

    return new SentryLogger(sentry);
  }
}
