import { inject, injectable } from 'tsyringe';
import { $GET_ENTITIES_BY_ID } from '@dp-vue/entities';
import { RootStoreToken } from '@/common/root';
import type { VisitConfirmationReadRepository } from '../../domain/visit/VisitConfirmationReadRepository';
import type { VisitConfirmation } from '../../domain/visit/VisitConfirmation';
import { StoreVisitConfirmationFactory } from './StoreVisitConfirmationFactory';
import type { StoreVisitConfirmation } from './StoreVisitConfirmation';

interface StoreTask {
  visit_confirmation: StoreVisitConfirmation;
}

interface Store {
  getters: {
    [$GET_ENTITIES_BY_ID]: (entityType: 'task', ids: string[]) => StoreTask[];
  };
}

@injectable()
export class StoreVisitConfirmationReadRepository implements VisitConfirmationReadRepository {
  constructor(@inject(RootStoreToken) private readonly store: Store) {}

  find(taskId: string): VisitConfirmation | null {
    const [task] = this.store.getters[$GET_ENTITIES_BY_ID]('task', [taskId]);

    if (!task) {
      return null;
    }

    return StoreVisitConfirmationFactory.tryMake(task.visit_confirmation);
  }
}
