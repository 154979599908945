import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { CallbackRepository } from '../../domain/overview-callback/CallbackRepository';
import type { CallbackData } from '../../domain/overview-callback/CallbackData';
import { CallbackDataFactory } from './CallbackDataFactory';
import type { InputCallbackData } from './InputCallbackData';

@injectable()
export class HttpCallbackRepository implements CallbackRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly callbackDataFactory: CallbackDataFactory,
  ) {}

  async get(
    start: string,
    end: string,
    previousStart: string,
    previousEnd: string,
  ): Promise<CallbackData> {
    const callbackData = await this.httpClient.get<InputCallbackData>(
      '/api/v0/facility/{facilityId}/stats/callbacks-overview',
      {
        start,
        end,
        previousStart,
        previousEnd,
      },
    );

    return this.callbackDataFactory.make(callbackData);
  }
}
