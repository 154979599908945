import type { Tag } from '@/modules/task/domain/tag/Tag';
import { TagType } from '@/modules/task/domain/tag/Tag';

export class ReminderTag implements Tag {
  constructor(
    readonly content: string,
    readonly type: TagType,
  ) {}

  static make(timestamp: string, isExpired: boolean): ReminderTag {
    return new ReminderTag(timestamp, isExpired ? TagType.ReminderExpired : TagType.Reminder);
  }
}
