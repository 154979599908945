import { TimelineMedia } from '../media/TimelineMedia';

export class TimelineDpChatMessage {
  constructor(
    readonly id: string,
    readonly direction: 'inbound' | 'outbound',
    readonly body: string,
    readonly createdAt: string,
    readonly ownerId: string | null,
    readonly media: TimelineMedia[],
  ) {}
}
