const prefix = 'mode_enabled';

/**
 * Checking if the module is enabled
 * @param name
 * @returns {boolean}
 */
export function isModuleEnabled(name) {
  return localStorage.getItem(`${prefix}:${name}`) === 'true';
}

/**
 * Set on/off module
 * @param name
 * @param enabled
 */
export function switchModule(name, enabled) {
  localStorage.setItem(`${prefix}:${name}`, enabled);
  window.location.reload();
}

export function toggleModule(name) {
  return switchModule(name, !isModuleEnabled(name));
}

/**
 * Get all modules that are enabled
 * @returns {string[]}
 */
export function getEnabledModules() {
  const items = { ...localStorage };

  return Object.keys(items)
    .filter(key => key.includes(prefix) && items[key] === 'true')
    .map(name => name.split(':')[1]);
}
