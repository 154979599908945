import { injectable } from 'tsyringe';
import { SoundNotification } from '../../domain/sound-notification/SoundNotification';
import type { SoundNotificationRepository } from '../../domain/sound-notification/SoundNotificationRepository';

@injectable()
export class StaticSoundNotificationRepository implements SoundNotificationRepository {
  getForNewMessage(): SoundNotification {
    const path = window.location.pathname.replace('index.html', '');
    return new SoundNotification(`//${window.location.host}${path}audio/wa-sound-notification.mp3`);
  }
}
