import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import {
  CallSelectedGroupRepositoryToken,
  ConfigurationOptionsListRepositoryToken,
  FileRepositoryToken,
  TrafficControlModeRepositoryToken,
  VoiceMessageRepositoryToken,
  WorkstationRepositoryToken,
} from '../token';
import { HttpVoiceMessageRepository } from '../../adapters/traffic-control/voice-menu/HttpVoiceMessageRepository';
import { HttpFileRepository } from '../../adapters/traffic-control/HttpFileRepository';
import { WorkstationApiWorkstationRepository } from '../../adapters/traffic-control/WorkstationApiWorkstationRepository';
import type { TrafficControlModeRepository } from '../../domain/traffic-control/traffic-control-mode/TrafficControlModeRepository';
import { HttpTrafficControlModeRepository } from '../../adapters/traffic-control/traffic-control-mode/HttpTrafficControlModeRepository';
import type { FileRepository } from '../../domain/traffic-control/FileRepository';
import type { ConfigurationOptionsListRepository } from '../../domain/traffic-control/voice-menu-configuration-options/ConfigurationOptionsListRepository';
import { HttpConfigurationOptionsListRepository } from '../../adapters/traffic-control/voice-menu/HttpConfigurationOptionsListRepository';
import type { WorkstationRepository } from '../../domain/traffic-control/WorkstationRepository';
import type { VoiceMessageRepository } from '../../domain/traffic-control/voice-menu-announcement/VoiceMessageRepository';
import type { CallSelectedGroupRepository } from '../../domain/traffic-control/decide-who-picks/CallSelectedGroupRepository';
import { HttpCallSelectedGroupRepository } from '../../adapters/traffic-control/calling-group/HttpCallSelectedGroupRepository';

export class TrafficControlProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<VoiceMessageRepository>(
      VoiceMessageRepositoryToken,
      HttpVoiceMessageRepository,
    );
    container.register<FileRepository>(FileRepositoryToken, HttpFileRepository);
    container.register<ConfigurationOptionsListRepository>(
      ConfigurationOptionsListRepositoryToken,
      HttpConfigurationOptionsListRepository,
    );
    container.register<WorkstationRepository>(
      WorkstationRepositoryToken,
      WorkstationApiWorkstationRepository,
    );
    container.register<TrafficControlModeRepository>(
      TrafficControlModeRepositoryToken,
      HttpTrafficControlModeRepository,
    );
    container.register<CallSelectedGroupRepository>(
      CallSelectedGroupRepositoryToken,
      HttpCallSelectedGroupRepository,
    );
  }
}
