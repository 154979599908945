import type { Timeline } from '@/modules/task/domain/timeline/Timeline';
import type { IconStrategy } from '../types';
import { IconName } from '../types';
import type { Task } from '../../task/Task';
import { DpChatMessage } from '../../message/DpChatMessage';

export class OutgoingDpChatMessage implements IconStrategy {
  get(task: Task, { lastUserEvent }: Timeline): null | IconName {
    if (!(lastUserEvent instanceof DpChatMessage)) {
      return null;
    }

    return lastUserEvent.isOutbound ? IconName.OutgoingDpChatMessage : null;
  }
}
