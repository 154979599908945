import type { PresenceChannel as ExternalPusherPresenceChannel } from 'pusher-js';
import type { PresenceChannel } from '../../domain/models/PresenceChannel';
import { PusherPrivateChannel } from './PusherPrivateChannel';

export class PusherPresenceChannel extends PusherPrivateChannel implements PresenceChannel {
  constructor(private readonly presenceChannel: ExternalPusherPresenceChannel) {
    super(presenceChannel);
  }

  get members(): any {
    return this.presenceChannel.members.members;
  }
}
