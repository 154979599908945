import { RootStoreToken } from '@/common/root';
import { $GET_ENTITIES_BY_TYPE } from '@dp-vue/entities';
import { inject, injectable } from 'tsyringe';
import type { InputWorkstation } from '../types/InputWorkstation';
import type { Workstation } from '../../domain/models/Workstation';
import type { WorkstationRepository } from '../../domain/repositories/WorkstationRepository';
import { WorkstationFactory } from '../factories/WorkstationFactory';

interface Store {
  getters: {
    [$GET_ENTITIES_BY_TYPE]: (entityType: 'receptionPhone') => InputWorkstation[];
  };
}

@injectable()
export class StoreWorkstationRepository implements WorkstationRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
    private readonly workstationFactory: WorkstationFactory,
  ) {}

  find(id: string): Workstation | null {
    return this.all().find(workstation => workstation.id === id) ?? null;
  }

  findByInternalNumber(internalNumber: string): Workstation | null {
    return this.all().find(workstation => workstation.internalNumber === internalNumber) ?? null;
  }

  all(): Workstation[] {
    const inputWorkstations = this.store.getters[$GET_ENTITIES_BY_TYPE]('receptionPhone');

    return this.workstationFactory.makeMany(inputWorkstations);
  }
}
