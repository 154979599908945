import { normalize } from 'normalizr';
import { $GET_ENTITIES_BY_TYPE, MUTATION_ENTITIES_UPDATE } from '@dp-vue/entities';
import { complianceBundleSchema } from '@/store/schema/compliance-bundle';
import { EntityType } from '@/store/schema/entity-type';
import { phoneApi } from '@/api/phone-api';
import { handleApiResponse } from '@/utils/api-response-handler';
import { toNamedParams } from '@/utils/vuex';
import { transformComplianceBundles } from '@/app/settings/compliance-bundle';
import { ComplianceError } from '@/app/settings/compliance/errors';
import { fromApi } from '@/app/settings/compliance/mappings';
import { equal, filterKeys, isIncludedIn, not, prop } from '@/utils/functions';
import {
  getWizardSecondStep,
  getWizardSteps,
  toApi,
  uploadFile,
} from '@/store/modules/settings/utils/compliance';
import { FORM_FIELDS, FORM_STEPS, FORM_TYPES } from './utils/compliance-form';

const getDefaultFormState = () => ({
  [FORM_TYPES.END_USER_TYPE]: {
    [FORM_TYPES.END_USER_TYPE]: FORM_FIELDS.END_USER_TYPE.BUSINESS,
  },
  [FORM_TYPES.BUSINESS_INFO]: {
    [FORM_FIELDS.BUSINESS_INFO.BUSINESS_NAME]: '',
    [FORM_FIELDS.BUSINESS_INFO.CUSTOMER_NAME]: '',
    [FORM_FIELDS.BUSINESS_INFO.ADDRESS]: '',
    [FORM_FIELDS.BUSINESS_INFO.CITY]: '',
    [FORM_FIELDS.BUSINESS_INFO.ZIP_CODE]: '',
    [FORM_FIELDS.BUSINESS_INFO.REGION]: '',
    [FORM_FIELDS.BUSINESS_INFO.BUSINESS_TAX_ID]: '',
    [FORM_FIELDS.BUSINESS_INFO.VAT_NUMBER]: '',
  },
  [FORM_TYPES.PERSONAL_INFO]: {
    [FORM_FIELDS.PERSONAL_INFO.FIRST_NAME]: '',
    [FORM_FIELDS.PERSONAL_INFO.LAST_NAME]: '',
    [FORM_FIELDS.PERSONAL_INFO.BIRTH_DATE]: null,
    [FORM_FIELDS.PERSONAL_INFO.BIRTH_PLACE]: '',
    [FORM_FIELDS.PERSONAL_INFO.NATIONALITY]: '',
    [FORM_FIELDS.PERSONAL_INFO.PERSONAL_TAX_ID]: '',
    [FORM_FIELDS.PERSONAL_INFO.DOCUMENT_NUMBER]: '',
    [FORM_FIELDS.PERSONAL_INFO.IDENTIFICATION_DOCUMENT_NUMBER]: '',
    [FORM_FIELDS.PERSONAL_INFO.ISSUE_DATE]: null,
    [FORM_FIELDS.PERSONAL_INFO.ISSUING_AUTHORITY]: '',
  },
  [FORM_TYPES.DOCUMENTS]: [],
});

const ITEMS_PER_PAGE = 30;

const state = {
  totalItems: 0,
  formData: getDefaultFormState(),
  documentsRequirements: [],
  fieldsRequirements: {},
  stepsNames: [FORM_STEPS.END_USER_PAGE],
  formErrors: [],
};

const getters = {
  getFormData: (state, getters) => type => {
    const section = state.formData[type];
    const isStaticWizardPage = [FORM_TYPES.DOCUMENTS, FORM_TYPES.END_USER_TYPE].includes(type);

    if (isStaticWizardPage) {
      return section;
    }

    return filterKeys(isIncludedIn(getters.getFormPageRequirement(type)))(section);
  },
  getFormErrors: state => state.formErrors,
  getComplianceBundles: toNamedParams(({ rootGetters }) =>
    rootGetters[$GET_ENTITIES_BY_TYPE](EntityType.COMPLIANCE_BUNDLE),
  ),
  getPagesCount: state => Math.max(1, Math.ceil(state.totalItems / ITEMS_PER_PAGE)),
  getFormPageRequirement: state => pageType => state.fieldsRequirements[pageType],
  getDocumentRequirements: ({ documentsRequirements }) => documentsRequirements,
  getStepsNames: state => state.stepsNames,
};

const mutations = {
  SET_FORM_DATA(state, { type, key, value }) {
    state.formData[type][key] = value;
  },

  CLEAR_FORM_DATA(state) {
    state.formData = getDefaultFormState();
  },

  CLEAR_FORM_SECTION(state, sectionName) {
    state.formData[sectionName] = getDefaultFormState()[sectionName];
  },

  SET_SECOND_WIZARD_STEP(state) {
    const endUserType = state.formData[FORM_TYPES.END_USER_TYPE][FORM_TYPES.END_USER_TYPE];
    const secondStep = getWizardSecondStep(endUserType);

    state.stepsNames = [FORM_STEPS.END_USER_PAGE, secondStep];
  },

  SET_TOTAL_ITEMS(state, totalItems) {
    state.totalItems = totalItems;
  },

  ADD_OR_EDIT_DOCUMENT(state, document) {
    const documents = state.formData[FORM_TYPES.DOCUMENTS];
    const fileIndex = documents.map(prop('file')).findIndex(equal(document.file));

    if (fileIndex < 0) {
      state.formData[FORM_TYPES.DOCUMENTS].push(document);
    } else {
      state.formData[FORM_TYPES.DOCUMENTS].splice(fileIndex, 1, document);
    }
  },

  REMOVE_DOCUMENT(state, fileUrl) {
    state.formData[FORM_TYPES.DOCUMENTS] = state.formData[FORM_TYPES.DOCUMENTS].filter(
      ({ file }) => file !== fileUrl,
    );
  },

  SET_REQUIREMENTS(state, { documentsRequirements, fieldsRequirements }) {
    state.fieldsRequirements = fieldsRequirements;
    state.documentsRequirements = documentsRequirements;

    const endUserType = state.formData[FORM_TYPES.END_USER_TYPE][FORM_TYPES.END_USER_TYPE];
    state.stepsNames = getWizardSteps(endUserType, fieldsRequirements);
  },

  ADD_ERROR(state, errorType) {
    state.formErrors = [...new Set([...state.formErrors, errorType])];
  },

  REMOVE_ERROR(state, errorType) {
    state.formErrors = state.formErrors.filter(not(equal(errorType)));
  },
};

const actions = {
  SET_FORM_DATA_ACTION({ commit }, { type, key, value }) {
    commit('SET_FORM_DATA', { type, key, value });
  },

  CLEAR_FORM_DATA_ACTION({ commit }) {
    commit('CLEAR_FORM_DATA');
  },

  CLEAR_FORM_ADDRESS_ACTION({ commit }) {
    commit('CLEAR_FORM_SECTION', FORM_TYPES.BUSINESS_INFO);
  },

  SET_SECOND_WIZARD_STEP_ACTION({ commit }) {
    commit('SET_SECOND_WIZARD_STEP');
  },

  ADD_DOCUMENT_ACTION({ commit }, document) {
    commit('ADD_OR_EDIT_DOCUMENT', document);
  },

  REMOVE_DOCUMENT_ACTION({ commit }, fileUrl) {
    commit('REMOVE_DOCUMENT', fileUrl);
  },

  SET_INVALID_ADDRESS_ERROR_ACTION({ commit }) {
    commit('ADD_ERROR', ComplianceError.InvalidAddress);
  },

  REMOVE_INVALID_ADDRESS_ERROR_ACTION({ commit }) {
    commit('REMOVE_ERROR', ComplianceError.InvalidAddress);
  },

  async GET_COMPLIANCE_BUNDLES_ACTION({ dispatch, commit, rootGetters }, pageNumber) {
    return phoneApi
      .withFacility(rootGetters.getUserFacilityID)
      .getComplianceBundles(pageNumber)
      .then(handleApiResponse)
      .then(transformComplianceBundles(rootGetters))
      .then(data => {
        if (data.totalItems) {
          commit('SET_TOTAL_ITEMS', data.totalItems);
        }

        dispatch('DELETE_ENTITIES_GROUP_ACTION', EntityType.COMPLIANCE_BUNDLE, { root: true });

        const { entities, result } = normalize(data.items, [complianceBundleSchema]);
        commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });

        return result;
      });
  },

  async GET_REGULATORY_REQUIREMENTS_ACTION({ rootGetters, commit, getters }) {
    const endUserType = getters.getFormData(FORM_TYPES.END_USER_TYPE)[FORM_TYPES.END_USER_TYPE];
    return phoneApi
      .withFacility(rootGetters.getUserFacilityID)
      .regulatory.requirements(endUserType)
      .then(fromApi(endUserType))
      .then(({ documentsRequirements, fieldsRequirements }) => {
        commit('SET_REQUIREMENTS', { documentsRequirements, fieldsRequirements });
      });
  },

  async SEND_BUNDLE_ACTION({ getters, rootGetters, dispatch }) {
    const documents = getters.getFormData(FORM_TYPES.DOCUMENTS);

    const uploadedDocuments = await Promise.all(documents.map(uploadFile(dispatch)));

    const bundle = toApi(getters, uploadedDocuments);

    return phoneApi.withFacility(rootGetters.getUserFacilityID).addComplianceBundle(bundle);
  },
};

export const compliance = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
