import { injectable } from 'tsyringe';
import { PatientRequest as DomainPatientRequest } from '@/modules/timeline/domain/patient-request/PatientRequest';
import type { Timeline } from '@/modules/timeline/domain/timeline/Timeline';
import { WhatsAppMessage as DomainTimelineWhatsAppMessage } from '@/modules/timeline/domain/message-composer/message/WhatsAppMessage';
import { DpChatMessage as DomainTimelineDpChatMessage } from '@/modules/timeline/domain/message-composer/message/DpChatMessage';
import { SmsMessage as DomainTimelineSmsMessage } from '@/modules/timeline/domain/message-composer/message/SmsMessage';
import { Call as DomainTimelineCall } from '@/modules/timeline/domain/call/Call';
import { ContactForm as DomainTimelineContactForm } from '@/modules/timeline/domain/contact-form/ContactForm';
import type { TimelineDpChatMessage } from '@/modules/timeline/public/api/data-transfer/message/TimelineDpChatMessage';
import { OutputTimeline } from './OutputTimeline';
import { OutputTimelineContactFormFactory } from './contact-form/OutputTimelineContactFormFactory';
import { OutputTimelineWhatsAppMessageFactory } from './message/OutputTimelineWhatsAppMessageFactory';
import { OutputTimelineCallFactory } from './call/OutputTimelineCallFactory';
import type { TimelineCall } from './call/TimelineCall';
import type { TimelineWhatsAppMessage } from './message/TimelineWhatsAppMessage';
import type { TimelineContactForm } from './contact-form/TimelineContactForm';
import { OutputTimelinePatientRequestFactory } from './patient-request/OutputTimelinePatientRequest';
import type { TimelinePatientRequest } from './patient-request/TimelinePatientRequest';
import { OutputTimelineSmsMessageFactory } from './message/OutputTimelineSmsMessageFactory';
import { OutputTimelineDpChatMessageFactory } from './message/OutputTimelineDpChatMessageFactory';
import type { TimelineSmsMessage } from './message/TimelineSmsMessage';

type DomainEventType =
  | DomainTimelineCall
  | DomainTimelineWhatsAppMessage
  | DomainTimelineDpChatMessage
  | DomainTimelineSmsMessage
  | DomainTimelineContactForm
  | DomainPatientRequest;

type EventType =
  | TimelineCall
  | TimelineWhatsAppMessage
  | TimelineDpChatMessage
  | TimelineSmsMessage
  | TimelineContactForm
  | TimelinePatientRequest;

@injectable()
export class OutputTimelineFactory {
  constructor(
    private readonly outputTimelineCallFactory: OutputTimelineCallFactory,
    private readonly outputTimelineWhatsAppMessageFactory: OutputTimelineWhatsAppMessageFactory,
    private readonly outputTimelineSmsMessageFactory: OutputTimelineSmsMessageFactory,
    private readonly outputTimelineContactFormFactory: OutputTimelineContactFormFactory,
    private readonly outputTimelinePatientRequestFactory: OutputTimelinePatientRequestFactory,
    private readonly outputTimelineDpChatMessageFactory: OutputTimelineDpChatMessageFactory,
  ) {}

  make(taskTimeline: Timeline): OutputTimeline {
    return new OutputTimeline(
      taskTimeline.taskId,
      taskTimeline.hasAwaitingMessages,
      this.makeEvents(taskTimeline.events.items as DomainEventType[]),
    );
  }

  private makeEvents(events: DomainEventType[]): EventType[] {
    return events.map(this.makeEvent, this);
  }

  private makeEvent(event: DomainEventType): EventType {
    if (event instanceof DomainTimelineCall) {
      return this.outputTimelineCallFactory.make(event);
    }

    if (event instanceof DomainTimelineWhatsAppMessage) {
      return this.outputTimelineWhatsAppMessageFactory.make(event);
    }

    if (event instanceof DomainTimelineSmsMessage) {
      return this.outputTimelineSmsMessageFactory.make(event);
    }

    if (event instanceof DomainTimelineDpChatMessage) {
      return this.outputTimelineDpChatMessageFactory.make(event);
    }

    if (event instanceof DomainTimelineContactForm) {
      return this.outputTimelineContactFormFactory.make(event);
    }

    if (event instanceof DomainPatientRequest) {
      return this.outputTimelinePatientRequestFactory.make(event);
    }

    throw new Error(`Unknown timeline event: ${event}`);
  }
}
