import { injectable } from 'tsyringe';
import type { HttpMessageTemplate } from '@/modules/timeline/adapters/message-composer/template/HttpMessageTemplate';
import { MessageTemplate } from '@/modules/timeline/domain/message-composer/templates/MessageTemplate';

@injectable()
export class MessageTemplateFactory {
  makeMany(messages: HttpMessageTemplate[]): MessageTemplate[] {
    return messages.map(this.make, this);
  }

  make({ id, name, body }: HttpMessageTemplate): MessageTemplate {
    return new MessageTemplate(id, name, body);
  }
}
