import { injectable } from 'tsyringe';
import { BookVisitApi } from '@/modules/book-visit/public/api';
import type { UpcomingVisitRepository } from '@/modules/task/domain/visit/UpcomingVisitRepository';
import type { UpcomingVisit } from '@/modules/task/domain/visit/UpcomingVisit';
import { BookVisitModuleUpcomingVisitFactory } from '@/modules/task/adapters/visit/upcoming/BookVisitModuleUpcomingVisitFactory';

@injectable()
export class BookVisitModuleUpcomingVisitRepository implements UpcomingVisitRepository {
  constructor(private readonly bookVisitApi: BookVisitApi) {}

  findByPatientPhoneNumber(phoneNumber: string): UpcomingVisit | null {
    return BookVisitModuleUpcomingVisitFactory.tryMake(
      this.bookVisitApi.findUpcomingVisit(phoneNumber),
    );
  }
}
