import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { HttpCallRecordingConfigurationRepository } from '../../adapters';
import { CallRecordingConfigurationRepositoryToken } from '../token';

export class AdapterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register(
      CallRecordingConfigurationRepositoryToken,
      HttpCallRecordingConfigurationRepository,
    );
  }
}
