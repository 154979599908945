import type { ObjectDirective } from 'vue';

interface HTMLElementWithHandler extends HTMLElement {
  clickOutside?: (event: MouseEvent) => void;
}

export const clickOutside: ObjectDirective = {
  beforeMount(el: HTMLElementWithHandler, binding) {
    el.clickOutside = function clickOutsideHandler(event: MouseEvent) {
      // Filter out non-HTML targets
      if (!(event.target instanceof HTMLElement)) {
        return;
      }

      // If click current HTML element do nothing;
      if (el === event.target || el.contains(event.target)) {
        return;
      }

      // execute functions only
      if (typeof binding.value === 'function') {
        binding.value.bind(binding.instance)(event);
      }
    };

    window.addEventListener('click', el.clickOutside as EventListener, true);
  },

  unmounted(el: HTMLElementWithHandler) {
    window.removeEventListener('click', el.clickOutside as EventListener, true);
  },
};
