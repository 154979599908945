import { inject, injectable } from 'tsyringe';
import type { TextChannelRepository } from '@/modules/settings/domain/channels/TextChannelRepository';
import type { TextChannel } from '@/modules/settings/domain/channels/TextChannel';
import type { Cache } from '@/common/root/domain/Cache';
import { HttpTextChannelRepository } from '@/modules/settings/adapters/channels/HttpTextChannelRepository';
import { ChannelsCacheToken } from '@/modules/settings/di/token';

@injectable()
export class CacheTextChannelRepository implements TextChannelRepository {
  private readonly cacheKey = 'current-facility-text-channels';

  constructor(
    private readonly inner: HttpTextChannelRepository,
    @inject(ChannelsCacheToken) private readonly cache: Cache,
  ) {}

  async get(): Promise<TextChannel[]> {
    const cachedChannels = this.cache.get<TextChannel[]>(this.cacheKey);

    if (cachedChannels) {
      return cachedChannels;
    }

    const channels = await this.inner.get();
    this.cache.set(this.cacheKey, channels);

    return channels;
  }
}
