import type { Visit } from '@/modules/book-visit/domain/visit/Visit';
import { addMinutes, isFuture, isPast } from 'date-fns';

type Status = 'upcoming' | 'in-progress' | 'completed';

export class UpcomingVisit implements Visit {
  constructor(
    private readonly duration: number,
    private readonly visit: Visit,
  ) {}

  get status(): Status {
    if (isFuture(this.startAt)) {
      return 'upcoming';
    }

    if (isPast(this.startAt) && isFuture(this.endAt)) {
      return 'in-progress';
    }

    return 'completed';
  }

  get startAt(): Date {
    return this.visit.startAt;
  }

  get endAt(): Date {
    return addMinutes(this.startAt, this.duration);
  }

  get serviceName(): string {
    return this.visit.serviceName;
  }

  get doctorName(): string {
    return this.visit.doctorName;
  }
}
