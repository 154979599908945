import { Store } from 'vuex';
import { inject, injectable } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { RootStoreToken } from '@/common/root';
import { state, mutations } from '../../ui/store/book-visit';

@injectable()
export class StoreProvider implements DpProvider {
  constructor(@inject(RootStoreToken) private readonly store: Store<unknown>) {}

  boot(): void {
    this.store.registerModule('bookVisit', { namespaced: true, state, mutations });
  }
}
