import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { phoneNumberFormatter } from '@/utils/phone-number-formatter';

export const RootPhoneNumberFormatterToken = Symbol('RootPhoneNumberFormatter');

export class RootPhoneNumberFormatterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register(RootPhoneNumberFormatterToken, {
      useValue: phoneNumberFormatter,
    });
  }
}
