import { Media } from '@/modules/settings/domain/traffic-control/models/Media';
import { VoiceMessage } from '@/modules/settings/domain/traffic-control/models/VoiceMessage';
import { injectable } from 'tsyringe';
import type { InputVoiceMessage } from '../types/InputVoiceMessage';

@injectable()
export class VoiceMessageFactory {
  makeFromInput(inputVoiceMessage: InputVoiceMessage): VoiceMessage {
    return new VoiceMessage(
      inputVoiceMessage.type,
      inputVoiceMessage.value ?? '',
      inputVoiceMessage.placeholder ?? '',
      inputVoiceMessage.file
        ? new Media(inputVoiceMessage.file.id, inputVoiceMessage.file.url, false)
        : Media.empty(),
    );
  }
}
