import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import type { TermsAndConditionsFactory } from '../../domain/terms-and-conditions/TermsAndConditionsFactory';
import { StaticLocalizedTermsAndConditionsFactory } from '../../adapters/terms-and-conditions/StaticLocalizedTermsAndConditionsFactory';
import { TermsAndConditionsFactoryToken } from '../token';

export class TermsAndConditionsProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<TermsAndConditionsFactory>(
      TermsAndConditionsFactoryToken,
      StaticLocalizedTermsAndConditionsFactory,
    );
  }
}
