import { UserApi } from '@/modules/user/public/api/UserApi';
import { parseISO } from 'date-fns';
import { delay, inject, injectable } from 'tsyringe';
import type { UserRepository } from '../../domain/tracking/repositories/UserRepository';
import { User } from '../../domain/tracking/models/User';

@injectable()
export class UserApiUserRepository implements UserRepository {
  constructor(
    @inject(delay(() => UserApi))
    private readonly userApi: UserApi,
  ) {}

  me(): User {
    const currentUser = this.userApi.getCurrentUser();
    const createdAt = currentUser.createdAt ? parseISO(currentUser.createdAt).toISOString() : null;

    return new User(
      currentUser.id,
      currentUser.name,
      currentUser.email ?? '',
      createdAt,
      currentUser.isManager,
      currentUser.roles,
    );
  }
}
