import { inject, singleton } from 'tsyringe';
import { DesktopNotificationPermissionToken } from '../../di/tokens';
import type { DesktopNotificationPermission } from './DesktopNotificationPermission';

@singleton()
export class DesktopNotificationEvaluator {
  private isValidatingPermissions = false;

  constructor(
    @inject(DesktopNotificationPermissionToken)
    private readonly desktopNotificationPermission: DesktopNotificationPermission,
  ) {}

  supportsNotifications(): boolean {
    return this.desktopNotificationPermission.isSupportingNotifications();
  }

  hasPermission(): boolean {
    return this.desktopNotificationPermission.permissionStatus === 'granted';
  }

  hasDeniedPermission(): boolean {
    return this.desktopNotificationPermission.permissionStatus === 'denied';
  }

  canAskForPermission(): boolean {
    return this.desktopNotificationPermission.permissionStatus === 'default';
  }

  async askAndCheckIfGrantedPermission(): Promise<boolean> {
    if (this.isValidatingPermissions) {
      return this.desktopNotificationPermission.permissionStatus === 'granted';
    }

    this.isValidatingPermissions = true;

    const permissionStatus = await this.desktopNotificationPermission.requestPermission();

    this.isValidatingPermissions = false;

    return permissionStatus === 'granted';
  }
}
