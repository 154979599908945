import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { VoiceMessageList } from '../../domain/voice-messages/VoiceMessageList';
import { VoiceMessageListFactory } from '../factories/VoiceMessageListFactory';
import type { HttpResponse } from '../factories/VoiceMessageListFactory';
import type { VoiceMessageRepository } from '../../domain/voice-messages/VoiceMessageRepository';
import type { VoiceMessageDTO } from '../../domain/voice-messages/VoiceMessageDTO';
import { VoiceMessagePayloadFactory } from '../factories/VoiceMessagePayloadFactory';

@injectable()
export class HttpVoiceMessageRepository implements VoiceMessageRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async get(): Promise<VoiceMessageList> {
    const { messages } = (await this.httpClient.get(
      `/api/v0/facility/{facilityId}/settings/messages`,
    )) as { messages: HttpResponse };

    return VoiceMessageListFactory.makeFromResponse(messages);
  }

  async save(voiceMessageDTOs: VoiceMessageDTO[]): Promise<VoiceMessageList> {
    const { messages } = (await this.httpClient.put(
      `/api/v0/facility/{facilityId}/settings/messages`,
      VoiceMessagePayloadFactory.makeFromDTOs(voiceMessageDTOs),
    )) as { messages: HttpResponse };

    return VoiceMessageListFactory.makeFromResponse(messages);
  }
}
