import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { WorkingHourList } from '@/modules/settings/domain/working-hours/regular-schedule/WorkingHourList';
import type { WorkingHourListWriteRepository } from '@/modules/settings/domain/working-hours/regular-schedule/WorkingHourListWriteRepository';
import { WorkingHourListFactory } from './WorkingHourListFactory';
import { StoreFacilityFetcher } from './StoreFacilityFetcher';

@injectable()
export class HttpWorkingHourListWriteRepository implements WorkingHourListWriteRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly workingHourListFactory: WorkingHourListFactory,
    private readonly facilityFetcher: StoreFacilityFetcher,
  ) {}

  async save(workingHourList: WorkingHourList): Promise<void> {
    const outputWorkingHours = this.workingHourListFactory.toHttp(workingHourList);

    await this.httpClient.post(
      '/api/v0/facility/{facilityId}/settings/working-hours',
      outputWorkingHours,
    );

    await this.facilityFetcher.fetch();
  }
}
