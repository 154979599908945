function currentTimestamp() {
  return Math.ceil(new Date().getTime() / 1000);
}

let timer;
const subscribers = new Set();

const timerModule = {
  state: {
    date: currentTimestamp(),
  },

  getters: {
    getCurrentTimestamp: state => state.date,
  },

  mutations: {
    SET_CURRENT_TIMESTAMP(state, value) {
      state.date = value;
    },
  },

  actions: {
    CURRENT_TIMESTAMP({ commit, getters }) {
      const value = currentTimestamp();
      if (getters.getCurrentTimestamp !== value) {
        commit('SET_CURRENT_TIMESTAMP', value);
      }
    },

    START_TIMER({ dispatch }, subscriber) {
      subscribers.add(subscriber);
      if (timer) {
        return;
      }

      timer = setInterval(() => {
        dispatch('CURRENT_TIMESTAMP');
      }, 100);
    },

    STOP_TIMER(context, subscriber) {
      subscribers.delete(subscriber);
      if (!timer || subscribers.size) {
        return;
      }

      clearInterval(timer);
      timer = undefined;
    },
  },
};

export const timerPlugin = store => {
  store.registerModule('timer', timerModule);
};
