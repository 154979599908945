import { inject, injectable } from 'tsyringe';
import { ContextRepositoryToken } from '../../di/tokens';
import type { ContextRepository } from '../../domain/context/ContextRepository';
import type { Context } from '../types/Context';

@injectable()
export class ContextApi {
  constructor(
    @inject(ContextRepositoryToken)
    private readonly contextRepository: ContextRepository,
  ) {}

  get(taskId: string): Context | null {
    return this.contextRepository.get(taskId);
  }
}
