<template>
  <button
    v-dpp-tooltip="{ content: iconOnly ? text : null }"
    :class="$b(blockModifiers)"
    @click.stop="$emit('click')"
  >
    <div :class="$b('icon-wrapper')">
      <slot name="icon" />
    </div>
    <div v-if="!iconOnly" :class="$b('text')">
      {{ text }}
    </div>
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

import { dppTooltip } from '@/ui/directives/dpp-tooltip';
import { isIncludedIn } from '@/utils/functions';
import {
  TileButtonColors,
  TileButtonStatusTypes,
} from '@/ui/molecules/TileButton/TileButton.types';

@Component({
  name: 'TileButton',
  directives: {
    dppTooltip,
  },
  emits: ['click'],
})
export default class TileButton extends Vue {
  @Prop({
    type: String,
    default: TileButtonColors.Light,
    validator: isIncludedIn(Object.values(TileButtonColors)),
  })
  readonly color: string;

  @Prop({ type: Boolean, default: false })
  readonly iconOnly: boolean;

  @Prop({ type: String, validator: isIncludedIn(Object.values(TileButtonStatusTypes)) })
  readonly statusType: string;

  @Prop({ type: String, default: '' })
  readonly text: string;

  get blockModifiers() {
    return {
      color: this.statusType ? '' : this.color,
      icon: this.iconOnly,
      status: this.statusType,
    };
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;
@use 'src/assets/scss/_mixins' as m;

$icon-button-size: v.$spacer * 2;

$tile-button-colors: (
  color_success: (
    text-color: v.$white,
    bg-color: v.$success,
  ),
  color_danger: (
    text-color: v.$white,
    bg-color: v.$danger,
  ),
  color_light: (
    text-color: v.$gray-600,
    bg-color: v.$white,
    border-color: v.$gray-300,
    bg-color-hover: v.$gray-100,
    border-color-hover: v.$gray-300,
  ),
  status_success: (
    text-color: v.$success,
    bg-color: v.$success-light,
  ),
  status_danger: (
    text-color: v.$danger,
    bg-color: v.$danger-light,
  ),
  status_light: (
    text-color: v.$gray-600,
    bg-color: v.$gray-100,
  ),
  status_white: (
    text-color: v.$gray-600,
    disabled-text-color: v.$gray-500,
    bg-color: v.$white,
    bg-color-hover: v.$gray-200,
  ),
);

.dp-tile-button {
  $block: &;

  display: inline-flex;
  align-items: center;
  border: none;
  border-radius: v.$border-radius-sm;
  outline: 1px solid rgba(255, 255, 255, 0); // border that doesn't change the button layout
  outline-offset: -1px;
  font-size: v.$font-size-sm;
  font-weight: v.$font-weight-medium;
  white-space: nowrap;
  transition: v.$btn-transition;
  cursor: pointer;

  &:not(#{$block}--icon) {
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 0.5em 1em;
  }

  &--icon {
    justify-content: center;
    width: $icon-button-size;
    height: $icon-button-size;
  }

  @each $type, $btn-color-map in $tile-button-colors {
    &--#{$type} {
      $text-color: map_get($btn-color-map, text-color);
      $bg-color: map_get($btn-color-map, bg-color);
      $border-color: map_get($btn-color-map, border-color);

      $bg-color-hover: map_get($btn-color-map, bg-color-hover);
      $border-color-hover: map_get($btn-color-map, border-color-hover);
      $text-color-hover: map_get($btn-color-map, text-color-hover);

      $disabled-text-color: map_get($btn-color-map, disabled-text-color);

      @include m.button-variant(
        $background: $bg-color,
        $border: $border-color,
        $color: $text-color,
        $hover-background: $bg-color-hover,
        $hover-border: $border-color-hover,
        $hover-color: $text-color-hover,
        $disabled-color: $disabled-text-color
      );
    }
  }

  &__text {
    margin-top: 0.333em;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :deep(.svg-icon:not([class*='svg-icon-color'])) {
    fill: currentColor;
  }
}
</style>
