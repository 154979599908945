import { injectable } from 'tsyringe';
import { TaskExpirationDateEvaluator } from '@/modules/tasks-list/domain/task-expiration/TaskExpirationDateEvaluator';
import type { TaskGroup } from '@/modules/tasks-list/domain/task/TaskGroup';
import { TaskList } from '@/modules/tasks-list/domain/task-list/TaskList';
import type { TaskFeedDTO } from '@/modules/tasks-list/public/api/data-transfer/TaskFeedDTO';
import { Task } from '@/modules/tasks-list/domain/task/Task';
import type { TaskDTO } from '@/modules/tasks-list/public/api/data-transfer/TaskDTO';
import { OwnershipFilter } from '@/modules/tasks-list/domain/ownership-filter/OwnershipFilter';
import type { OwnershipFilterOptionDTO } from '@/modules/tasks-list/public/api/data-transfer/OwnershipFilterOptionDTO';

@injectable()
export class TaskListApi {
  constructor(
    private readonly taskExpirationDateEvaluator: TaskExpirationDateEvaluator,
    private readonly taskList: TaskList,
    private readonly ownershipFilter: OwnershipFilter,
  ) {}

  isTaskExpiredToday(date?: string): boolean {
    return this.taskExpirationDateEvaluator.isExpiringToday(date);
  }

  async getGroupPage(group: TaskGroup, lastCursor?: string): Promise<TaskFeedDTO> {
    const { taskIds, cursor } = await this.taskList.getGroupPage(group, lastCursor);

    return {
      taskIds,
      cursor,
    };
  }

  isTaskVisibleForUser(dto: TaskDTO, groupName: TaskGroup): boolean {
    const patientRequestTypes = dto.patientRequests.map(request => {
      if (request.type === 'refillPrescription') {
        return 'refill-prescription';
      }
      if (request.type === 'testResults') {
        return 'test-results';
      }
      return request.type;
    });

    const task = Task.make(dto.group, dto.type, dto.ownerId, dto.isAbandoned, patientRequestTypes);

    return task.canBeSeen(groupName, this.ownershipFilter.getCurrentOption());
  }

  getCurrentOwnershipFilterOption(): OwnershipFilterOptionDTO {
    const { type, value } = this.ownershipFilter.getCurrentOption();

    return { type, value };
  }
}
