type milliseconds = number;

export function sleep(time: milliseconds): Promise<void> {
  return new Promise(resolve => {
    setTimeout(resolve, time);
  });
}

export function noLongerThan<T>(promise: Promise<T>, timeout: milliseconds): Promise<T | void> {
  return Promise.race([promise, sleep(timeout)]);
}

export function isPromise(obj: { then?: unknown }): boolean {
  return obj instanceof Promise || (obj && typeof obj.then === 'function');
}
