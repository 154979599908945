import type { MessagesCount } from '@/modules/timeline/domain/message-composer/message/MessagesCount';

export class TimelineMessagesCountOutput {
  private constructor(
    readonly inboundCount: number,
    readonly outboundCount: number,
  ) {}

  static of(messagesCount: MessagesCount): TimelineMessagesCountOutput {
    return new TimelineMessagesCountOutput(messagesCount.inboundCount, messagesCount.outboundCount);
  }
}
