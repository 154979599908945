import { parseISO } from 'date-fns';
import type { StoreUpcomingVisit } from '@/modules/book-visit/adapters/visit/StoreUpcomingVisit';
import type { StoreVisitConfirmation } from './StoreVisitConfirmation';
import { Visit } from '../../domain/visit/Visit';

export class StoreVisitFactory {
  static makeFromVisitConfirmation(data: StoreVisitConfirmation['payload']): Visit {
    return new Visit(
      parseISO(data.startDateTime),
      data.serviceName,
      `${data.doctor.firstName} ${data.doctor.lastName}`.trim(),
    );
  }

  static makeFromUpcomingVisit(data: StoreUpcomingVisit): Visit {
    return new Visit(parseISO(data.startDate), data.serviceType, data.doctorName);
  }
}
