import { createGtm } from '@gtm-support/vue-gtm';
import config from '@/config/config.json';
import { router } from '@/router';

export const gtmPlugin = createGtm({
  id: config.GTM_ID,
  enabled: true,
  debug: false,
  vueRouter: router,
  ignoredViews: [],
});
