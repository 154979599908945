import { Call } from '@/modules/task/domain/call/Call';
import { WhatsAppMessage } from '@/modules/task/domain/message/WhatsAppMessage';
import { ContactForm } from '@/modules/task/domain/contact-form/ContactForm';
import type { TimelineEvent } from '@/modules/task/domain/timeline/TimelineEvent';
import { EmptyTimelineEvent } from '@/modules/task/domain/timeline/EmptyTimelineEvent';
import { SmsMessage } from '@/modules/task/domain/message/SmsMessage';
import type { Message } from '@/modules/task/domain/message/Message';
import type { Call as CallInterface } from '../call/types/Call';
import { NullCall } from '../call/NullCall';
import type { EventType } from './EventType';

export class Timeline {
  constructor(
    readonly taskId: string,
    readonly events: EventType[],
  ) {}

  get hasCall(): boolean {
    return this.events.some(event => event instanceof Call);
  }

  get hasMessage(): boolean {
    return this.events.some(
      event => event instanceof WhatsAppMessage || event instanceof SmsMessage,
    );
  }

  get isEmpty(): boolean {
    return this.events.length === 0;
  }

  get hasOnlyAbandonedCalls(): boolean {
    if (!this.hasCall) {
      return false;
    }

    return this.callEvents.every(event => event.isAbandoned);
  }

  get hasSuccessfulCall(): boolean {
    if (!this.hasCall) {
      return false;
    }

    return this.callEvents.some(event => event.isSuccessful);
  }

  get hasSuccessfulContact(): boolean {
    return this.hasSuccessfulCall || this.hasSuccessfulMessage;
  }

  get lastUserEvent(): TimelineEvent {
    return this.newestFirstUserEvents[0] ?? new EmptyTimelineEvent();
  }

  get lastCall(): CallInterface {
    return (
      this.newestFirstUserEvents.find((event): event is Call => event instanceof Call) ??
      new NullCall()
    );
  }

  get lastContactForm(): ContactForm | null {
    return (
      this.usersEvents.find((event): event is ContactForm => event instanceof ContactForm) ?? null
    );
  }

  get usersEvents(): EventType[] {
    return this.events.filter(event => {
      if (event instanceof WhatsAppMessage || event instanceof SmsMessage) {
        return !event.isSentBySystem;
      }

      return true;
    });
  }

  private get callEvents(): Call[] {
    return this.events.filter((event): event is Call => event instanceof Call);
  }

  private get messageEvents(): Message[] {
    return this.events.filter(
      (event): event is WhatsAppMessage =>
        event instanceof WhatsAppMessage || event instanceof SmsMessage,
    );
  }

  private get hasSuccessfulMessage(): boolean {
    if (!this.hasMessage) {
      return false;
    }

    const hasSuccessfulOutbound = this.messageEvents.some(message => message.isSuccessfulOutbound);
    const hasSuccessfulInbound = this.messageEvents.some(message => message.isSuccessfulInbound);

    return hasSuccessfulOutbound && hasSuccessfulInbound;
  }

  private get newestFirstUserEvents(): EventType[] {
    return [...this.usersEvents].reverse();
  }
}
