interface FlutterMessage {
  type: string;
  payload?: unknown;
}

interface FlutterMessageHandler {
  postMessage(message: FlutterMessage): void;
}

declare global {
  interface Window {
    flutterMessageHandler: FlutterMessageHandler;
    receiveFromFlutter(message: FlutterMessage): void;
  }
}

const isDebug = import.meta.env.VITE_APP_DEBUG_MOBILE === 'true';

export const isMobileApp = typeof window.flutterMessageHandler !== 'undefined' || isDebug;

export function pushToFlutter(message: FlutterMessage): void {
  if (!isMobileApp) {
    return;
  }

  if (isDebug) {
    // eslint-disable-next-line no-console
    console.log('Pushing to flutter:', message);
    return;
  }

  // `flutterMessageHandler` is set by the Flutter app when initializing its webview
  window.flutterMessageHandler.postMessage(message);
}

export function receiveFromFlutter(message: FlutterMessage): void {
  if (isDebug) {
    // eslint-disable-next-line no-console
    console.log('From flutter:', message);
  }
}
