import { take } from '@/utils/functions';
import { getTopLevelDomain } from '@/utils/domain';
import domainsConfig from '../config/locale-configuration.json';

export enum FacilityLocale {
  PL = 'pl_PL',
  ES = 'es_ES',
  MX = 'es_MX',
  IT = 'it_IT',
  BR = 'pt_BR',
  DE = 'de_DE',
}

export enum Locale {
  EN = 'en',
  PL = 'pl',
  ES = 'es',
  MX = 'mx',
  IT = 'it',
  BR = 'br',
}

enum Language {
  en = 'en',
  pl = 'pl',
  es = 'es',
  it = 'it',
  pt = 'pt',
  de = 'de',
}

enum IETFLanguage {
  EN = 'en',
  PL = 'pl',
  ES = 'es',
  MX = 'es-419',
  IT = 'it',
  BR = 'pt-BR',
  DE = 'de',
}

const fallbackDomain = 'com';
const topLevelDomain = getTopLevelDomain();

interface LanguageConfigBasedOnDomain {
  locale: Locale;
  facilityLocale: FacilityLocale;
  baseUrl: string;
}

export function getLanguageBasedOnDomain(): LanguageConfigBasedOnDomain {
  return take(
    'locale',
    'facilityLocale',
    'baseUrl',
  )(domainsConfig[topLevelDomain] || domainsConfig[fallbackDomain]);
}

export interface LanguageConfig {
  locale: Locale;
  language: Language;
  ietfLanguage: IETFLanguage;
}

export function getLanguageConfig(): LanguageConfig {
  let domain;

  try {
    domain = window.localStorage.getItem('locale') || topLevelDomain;
  } catch {
    domain = topLevelDomain;
  }

  return take(
    'locale',
    'language',
    'ietfLanguage',
  )(domainsConfig[domain] || domainsConfig[fallbackDomain]);
}

export const lang = {};
