import { ContextApi } from '@/modules/task/public/api/ContextApi';
import { delay, inject, injectable } from 'tsyringe';
import type { Context } from '@/modules/timeline/domain/timeline/context/Context';

@injectable()
export class TaskContextRepository {
  constructor(
    // TODO move this API classes into this module, there is no reason to use context outside timeline.
    @inject(delay(() => ContextApi))
    private readonly contextApi: ContextApi,
  ) {}

  find(taskId: string): Context | null {
    return this.contextApi.get(taskId);
  }
}
