export class TimelineMessagesCount {
  private constructor(
    readonly inboundCount: number,
    readonly outboundCount: number,
  ) {}

  static of(inboundCount: number, outboundCount: number): TimelineMessagesCount {
    return new TimelineMessagesCount(inboundCount, outboundCount);
  }
}
