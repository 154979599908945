import { equal, not } from '@/utils/functions';
import { paramCase } from '@/utils/text';

export type Modifiers = Record<string, boolean | string>;

function mapModifiers([key, value]) {
  if (typeof value === 'string') {
    return `${key}_${value}`;
  }

  if (value) {
    return `${key}`;
  }

  return null;
}

function createModifiersNames(modifiers: Modifiers): string[] {
  return Object.entries(modifiers)
    .map(mapModifiers)
    .filter(not(equal(null)));
}

function getModifiers(
  elementOrModifier?: string | Modifiers,
  modifiersObject?: Modifiers,
): string[] | null {
  if (typeof elementOrModifier === 'object') {
    return createModifiersNames(elementOrModifier);
  }

  if (typeof modifiersObject === 'object') {
    return createModifiersNames(modifiersObject);
  }

  return null;
}

export function b(
  blockName: string | null,
  elementOrModifier?: string | Modifiers,
  modifiersObject?: Modifiers,
): string {
  if (!blockName) {
    throw new Error("You're trying to generate a BEM name for a component without the name");
  }

  const block = `dp-${paramCase(blockName)}`;
  const element: string | null = typeof elementOrModifier === 'string' ? elementOrModifier : null;
  const modifiers: string[] | null = getModifiers(elementOrModifier, modifiersObject);
  const rootClass = element ? `${block}__${element}` : block;

  if (modifiers && modifiers?.length > 0) {
    const modifiersClasses = modifiers.map(modifier => `${rootClass}--${modifier}`).join(' ');

    return `${rootClass} ${modifiersClasses}`;
  }

  return rootClass;
}
