<template>
  <span :class="$b()">
    <custom-tooltip>
      <template #tooltip-trigger>
        <a :class="$b('trigger')" :href="href" target="_blank" rel="noopener nofollow">
          {{ linkText }}
        </a>
      </template>
      <template #tooltip-content>
        <span :class="$b('tooltip-content')">
          {{ tooltip }}
          <dp-icon-out :class="$b('tooltip-icon')" :size="12" color="white" />
        </span>
      </template>
    </custom-tooltip>
  </span>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { DpIconOut } from '@dp-ui-kit/icons';

import CustomTooltip from '@/ui/molecules/CustomTooltip/CustomTooltip.vue';

@Component({
  name: 'TaskPatientLink',
  components: {
    CustomTooltip,
    DpIconOut,
  },
})
export default class TaskPatientLink extends Vue {
  @Prop({ type: String, required: true })
  readonly href: string;

  @Prop({ type: String, required: true })
  readonly linkText: string;

  @Prop({ type: String, default: '' })
  readonly tooltipText: string;

  get tooltip(): string {
    if (this.tooltipText) {
      return this.tooltipText;
    }

    return this.$ts('task.call.title.patient_link');
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-task-patient-link {
  &__trigger {
    color: inherit;
    font-weight: v.$font-weight-medium;
    text-decoration: underline;
  }

  &__tooltip-content {
    display: flex;
    align-items: center;
  }

  &__tooltip-icon {
    margin-left: 0.333em;
  }
}
</style>
