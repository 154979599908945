import { injectable } from 'tsyringe';
import type { InterceptionError, RequestConfig, TokenInterception } from './TokenInterception';
import { UserOAuthClient } from '../UserOAuthClient';
import { UserAuthenticator } from '../../login/UserAuthenticator';
import { AccessTokenRefreshError } from '../token/errors/AccessTokenRefreshError';

@injectable()
export class UserApiTokenInterception implements TokenInterception {
  constructor(
    private readonly oauthClient: UserOAuthClient,
    private readonly userAuthenticator: UserAuthenticator,
  ) {}

  async onRequest(config: RequestConfig): Promise<RequestConfig> {
    if (this.oauthClient.isTokenExpired()) {
      try {
        await this.oauthClient.refreshAccessToken();
      } catch (error) {
        if (error instanceof AccessTokenRefreshError) {
          await this.userAuthenticator.logout();
        } else {
          throw error;
        }
      }
    }

    return {
      ...config,
      headers: { ...config.headers, Authorization: this.oauthClient.getTokenHeader() },
    };
  }

  async onError(error: InterceptionError): Promise<void> {
    if (error.statusCode === 401) {
      await this.userAuthenticator.logout();
    }
  }
}
