import type { TaskUpdatedEvent as TasksListTaskUpdatedEvent } from '@/modules/tasks-list/public/events';
import { injectable } from 'tsyringe';
import { TaskUpdated } from '../../domain/TaskUpdated';

@injectable()
export class TaskUpdatedFactory {
  make(event: TasksListTaskUpdatedEvent): TaskUpdated {
    return new TaskUpdated(event.id, event.updateTriggers);
  }
}
