import { inject, injectable } from 'tsyringe';
import { SubjectRepositoryToken, VisitConfirmationRepositoryToken } from '../../di/tokens';
import type { SubjectRepository } from '../subject/SubjectRepository';
import type { Task } from '../task/Task';
import type { TaskStatus } from '../task/TaskStatus';
import type { VisitConfirmationRepository } from '../visit/VisitConfirmationRepository';
import { TaskFragment } from './TaskFragment';

@injectable()
export class TaskFragmentFactory {
  constructor(
    @inject(SubjectRepositoryToken)
    private readonly subjectRepository: SubjectRepository,
    @inject(VisitConfirmationRepositoryToken)
    private readonly visitConfirmationRepository: VisitConfirmationRepository,
  ) {}

  makeFromTask(task: Task): TaskFragment {
    const { subjectType, subjectName } = this.getSubject(task);
    const visitConfirmationStatus = this.getVisit(task);

    return new TaskFragment(
      task.id,
      task.status,
      subjectType,
      subjectName,
      visitConfirmationStatus,
    );
  }

  make(
    taskId: string,
    status: TaskStatus,
    subjectType: 'visit' | 'lost-reason' | 'informational' | null = null,
    subjectName = '',
    visitConfirmationStatus: 'rescheduled' | 'canceled' | 'confirmed' | 'new' | null = null,
  ): TaskFragment {
    return new TaskFragment(taskId, status, subjectType, subjectName, visitConfirmationStatus);
  }

  private getSubject(task: Task): {
    subjectType?: 'visit' | 'lost-reason' | 'informational';
    subjectName?: string;
  } {
    if (task.status !== 'resolved' || !task.subjectId) {
      return {};
    }

    const subject = this.subjectRepository.get(task.subjectId);

    return {
      subjectType: subject?.type || undefined,
      subjectName: subject?.name,
    };
  }

  private getVisit(task: Task): 'rescheduled' | 'canceled' | 'confirmed' | 'new' | undefined {
    if (task.status !== 'visit-confirmation-successful') {
      return undefined;
    }

    const visit = this.visitConfirmationRepository.findFor(task);
    return visit?.status;
  }
}
