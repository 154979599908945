import type { Connection, ConnectionState } from '@/modules/phone-widget/services/ConnectionState';
import { PhoneWidgetEvent } from '@/modules/phone-widget/events';
import type {
  Workstation,
  WorkstationState,
} from '@/modules/phone-widget/services/WorkstationState';
import type { DpProvider } from '@/core/di/DpProvider';
import { inject, injectable } from 'tsyringe';
import { EventTrackerFacade } from '@/common/tracking-event/public/api/EventTrackerFacade';
import { PhoneWidgetBusinessEvent } from '../../business-events';
import { PhoneWidgetEventPublisher } from '../../services/PhoneWidgetEventPublisher';
import { ConnectionStateToken, WorkstationStateToken } from '../tokens';

@injectable()
export class StateObserverProvider implements DpProvider {
  constructor(
    @inject(ConnectionStateToken)
    private readonly connectionState: ConnectionState,
    @inject(WorkstationStateToken)
    private readonly workstationState: WorkstationState,
    private readonly eventTracker: EventTrackerFacade,
    private readonly phoneWidgetEventPublisher: PhoneWidgetEventPublisher,
  ) {}

  boot(): void {
    this.workstationState.watch((workstation: Workstation) => {
      if (workstation) {
        this.eventTracker.publishBusinessEvent(
          PhoneWidgetBusinessEvent.PhoneWidgetLoginWorkstation,
          {
            type: workstation.type,
          },
        );
        this.watchConnection();
      } else {
        this.phoneWidgetEventPublisher.publish(PhoneWidgetEvent.PhoneWidgetDisconnect);
        this.unwatchConnection();
      }
    });
  }

  private watchConnection() {
    this.connectionState.watch((connection: Connection) => {
      if (connection?.isClosed || !connection) {
        this.phoneWidgetEventPublisher.publish(PhoneWidgetEvent.PhoneWidgetStopCalling);
      } else {
        this.phoneWidgetEventPublisher.publish(PhoneWidgetEvent.PhoneWidgetStartCalling);
      }
    });
  }

  private unwatchConnection() {
    this.connectionState.unwatch();
  }
}
