import { injectable } from 'tsyringe';
import { getUnixTime, parseISO } from 'date-fns';
import { UserStatus } from '../../domain/user-status/UserStatus';
import { ServerTime } from '../../domain/time/ServerTime';
import { Ping } from '../../domain/ping/Ping';
import { UserSessionFactory } from '../pause/UserSessionFactory';
import type { InputPing } from './InputPing';
import type { InputGetPing } from './InputGetPing';
import type { InputUserStatus } from './InputUserStatus';

@injectable()
export class PingFactory {
  constructor(private readonly userSessionFactory: UserSessionFactory) {}

  make(inputPing: InputPing | InputGetPing): Ping {
    const getTimestamp = (date: string) => getUnixTime(parseISO(date));

    const currentTime = new ServerTime(inputPing.current_time);
    const statuses = Object.values(inputPing.status).reduce((acc, status: InputUserStatus) => {
      acc[status.id] = new UserStatus(
        status.id,
        status.status,
        getTimestamp(status.first_login_today),
        getTimestamp(status.session_ends),
      );
      return acc;
    }, {});
    const userSession =
      'current' in inputPing ? this.userSessionFactory.make(inputPing.current) : undefined;

    return new Ping(currentTime, statuses, userSession);
  }
}
