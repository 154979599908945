import { injectable } from 'tsyringe';
import { Task } from '@/modules/task/domain/task/Task';
import type { StoreTask } from '@/modules/task/adapters/task/StoreTask';
import { VoiceMenu } from '@/modules/task/domain/voice-menu/VoiceMenu';
import { Caller } from '../../domain/task/Caller';
import { OwnerIdFactory } from '../owner/OwnerIdFactory';
import { Voicemail } from '../../domain/voicemail/Voicemail';

@injectable()
export class TaskFactory {
  constructor(private readonly ownerIdFactory: OwnerIdFactory) {}

  make(task: StoreTask): Task {
    return new Task(
      task.id,
      task.type,
      task.status,
      task.tab,
      this.ownerIdFactory.tryMake(task.personnel),
      task.patientId,
      this.makeVoiceMenu(task.voice_menu),
      task.comment,
      task.anonymous,
      task.starred,
      new Caller(task.caller),
      task.expires_at,
      task.created_at,
      this.makeVoicemail(task.voicemail),
      task.result,
    );
  }

  makeEmptyToDo(): Task {
    return new Task(
      Task.newToDoId,
      'outbound-call',
      'open',
      'todo',
      this.ownerIdFactory.tryMake(),
      '',
      VoiceMenu.makeEmpty(),
      '',
      false,
      false,
      new Caller(''),
      '',
      '',
      this.makeVoicemail({ sent: false, converted: false }),
    );
  }

  private makeVoiceMenu({
    label,
    priority,
  }: {
    label?: string;
    priority?: number;
  } = {}): VoiceMenu {
    return VoiceMenu.make(label ?? '', !!priority);
  }

  private makeVoicemail(voicemail: StoreTask['voicemail']): Voicemail {
    return new Voicemail(voicemail.sent, voicemail.converted);
  }
}
