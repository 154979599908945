import { inject, singleton } from 'tsyringe';
import { BookingOptionsPreloader } from '../booking/BookingOptionsPreloader';
import type { FacilityIntegrationRepository } from './FacilityIntegrationRepository';
import { FacilityIntegrationRepositoryToken } from '../../di/token';

@singleton()
export class BookingOptionsPreloaderGate {
  constructor(
    private readonly bookingOptionsPreloader: BookingOptionsPreloader,
    @inject(FacilityIntegrationRepositoryToken)
    private readonly facilityIntegration: FacilityIntegrationRepository,
  ) {}

  private taskFeedFetched = false;

  private pendingFacilityChanged = false;

  async tryPreload(): Promise<void> {
    if (!this.shouldPreload()) {
      this.taskFeedFetched = false;
      return;
    }
    this.pendingFacilityChanged = false;
    this.taskFeedFetched = false;
    await this.bookingOptionsPreloader.preload();
  }

  markTaskFeedFetched(): void {
    this.taskFeedFetched = true;
  }

  markFacilityChangedPending(): void {
    this.pendingFacilityChanged = true;
  }

  private shouldPreload(): boolean {
    const facilityIntegration = this.facilityIntegration.get();

    return (
      facilityIntegration.isSsoIntegrated && this.taskFeedFetched && this.pendingFacilityChanged
    );
  }
}
