import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { StoreWorkstationRepository } from '@/modules/task/adapters/workstation/StoreWorkstationRepository';
import type { WorkstationRepository } from '@/modules/task/domain/workstation/WorkstationRepository';
import type { WorkstationChecker } from '../../domain/workstation/WorkstationChecker';
import { WorkstationCheckerToken, WorkstationRepositoryToken } from '../tokens';
import { RepositoryWorkstationChecker } from '../../domain/workstation/RepositoryWorkstationChecker';

@injectable()
export class WorkstationProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<WorkstationRepository>(
      WorkstationRepositoryToken,
      StoreWorkstationRepository,
    );
    container.register<WorkstationChecker>(WorkstationCheckerToken, RepositoryWorkstationChecker);
  }
}
