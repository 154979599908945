<template>
  <div :class="$b()">
    <dp-icon-patients :class="$b('icon')" :size="16" color="gray-600" />

    <div :class="$b('name')">
      {{ name }}
    </div>
    <div :class="$b('phone-number')">
      {{ phoneNumber }}
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { DpIconPatients } from '@dp-ui-kit/icons';

@Component({
  name: 'PatientSuggestionLine',
  components: {
    DpIconPatients,
  },
})
export default class PatientSuggestionLine extends Vue {
  @Prop({ type: String, default: '' })
  readonly name: string;

  @Prop({ type: String, default: '' })
  readonly phoneNumber: string;
}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-patient-suggestion-line {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding-left: v.$spacer-sm;

  &__icon {
    display: block;
    flex-shrink: 0;
  }

  &__name {
    color: v.$gray-900;
    margin-left: v.$spacer-md;
    margin-right: v.$spacer-md;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__phone-number {
    color: v.$gray-500;
    font-size: v.$font-size-sm;
    margin-left: auto;
  }
}
</style>
