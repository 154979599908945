import { AnonymizationModule } from '@/modules/anonymization';
import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { RootModule } from '@/common/root';
import { AdaptersProvider } from './providers/AdaptersProvider';
import { RepositoryProvider } from './providers/RepositoryProvider';

export class PatientModule implements DpModule {
  static readonly namespace = Symbol('PatientModule');

  static readonly requires = [RootModule, AnonymizationModule];

  providers(): IDpProvider[] {
    return [RepositoryProvider, AdaptersProvider];
  }
}
