import { inject, singleton } from 'tsyringe';
import { EnvironmentDetectorToken, SenderToken } from '../../di/tokens';
import type { EnvironmentDetector } from './EnvironmentDetector';
import type { Sender } from './Sender';
import type { CustomTrackingEvent } from './dto/CustomTrackingEvent';

@singleton()
export class EnvironmentAwareSender {
  constructor(
    @inject(EnvironmentDetectorToken)
    private readonly environmentDetector: EnvironmentDetector,
    @inject(SenderToken)
    private readonly sender: Sender,
  ) {
    if (this.environmentDetector.isProduction || this.environmentDetector.isGtmFlagActive) {
      this.sender.enable();
    }
  }

  get isActive(): boolean {
    return this.sender.isActive;
  }

  trackEvent(eventPayload: CustomTrackingEvent): void {
    if (!this.isActive) {
      return;
    }

    this.sender.trackEvent(eventPayload);
  }
}
