import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { StoreUser } from '@/modules/authentication/adapters/StoreUser';
import { StorePhoneWidgetWorkstationManager } from '@/modules/authentication/adapters/StorePhoneWidgetWorkstationManager';
import type { User } from '@/modules/authentication/domain/login/User';
import { getUserConfig } from '@/config/user-config';
import type { UserConfig } from '@/config/user-config';
import {
  UserConfigToken,
  UserToken,
  PhoneWidgetWorkstationManagerToken,
  ObfuscatePhoneNumberCreatorToken,
} from '@/modules/authentication/di/token';
import type { PhoneWidgetWorkstationManager } from '@/modules/authentication/domain/PhoneWidgetWorkstationManager';
import { ObfuscatePhoneNumberCreator } from '@/modules/authentication/domain/ObfuscatePhoneNumberCreator';
import { phoneNumberFormatter } from '@/utils/phone-number-formatter';

@injectable()
export class AdapterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<UserConfig>(UserConfigToken, {
      useValue: getUserConfig(),
    });

    container.register<User>(UserToken, StoreUser);

    container.register<PhoneWidgetWorkstationManager>(
      PhoneWidgetWorkstationManagerToken,
      StorePhoneWidgetWorkstationManager,
    );

    container.register<ObfuscatePhoneNumberCreator>(ObfuscatePhoneNumberCreatorToken, {
      useFactory: () => new ObfuscatePhoneNumberCreator(phoneNumberFormatter),
    });
  }
}
