import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { UserRolesRepository } from '../../domain/user/UserRolesRepository';
import { UserRoles } from '../../domain/user/UserRoles';

interface Store {
  getters: {
    getUser: {
      id: string;
      roles: string[];
    };
  };
}

@injectable()
export class StoreUserRolesRepository implements UserRolesRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
  ) {}

  getCurrent(): UserRoles {
    const user = this.store.getters.getUser;

    return new UserRoles(user.id, [...user.roles]);
  }
}
