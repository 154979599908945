<template>
  <div class="user-actions tw-fixed tw-bottom-0 tw-right-0 tw-mr-1 tw-mb-20">
    <dp-button
      v-for="module in getModules()"
      :key="module"
      color="light"
      size="sm"
      class="tw-uppercase tw-ml-1"
      @click="disableModule(module)"
    >
      off {{ module }}
    </dp-button>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

import DpButton from '@dp-ui-kit/vue/components/button/DpButton.vue';

import { getEnabledModules, switchModule } from '@/utils/module-switcher';

export default defineComponent({
  components: {
    DpButton,
  },
  methods: {
    getModules() {
      return getEnabledModules();
    },

    disableModule(name) {
      switchModule(name, false);
    },
  },
});
</script>
<style lang="scss" scoped>
@use '@/assets/scss/mixins' as m;

.user-actions {
  @include m.z-index(user-overlay);
}
</style>
