export class TimelineCallCount {
  private constructor(
    readonly successfulCount: number,
    readonly unsuccessfulCount: number,
  ) {}

  static of(successfulCount: number, unsuccessfulCount: number): TimelineCallCount {
    return new TimelineCallCount(successfulCount, unsuccessfulCount);
  }
}
