import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { AuthenticationToken } from '@/modules/dashboard/di/token';
import type { Authentication } from '@/modules/dashboard/domain/Authentication';
import { AuthenticationApi } from '@/modules/authentication/public/api/AuthenticationApi';

export class AuthenticationProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<Authentication>(AuthenticationToken, AuthenticationApi);
  }
}
