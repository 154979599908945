export class Clock {
  private timeoutId: NodeJS.Timeout | null = null;

  private readonly timeoutFrequencyInSeconds = 15 * 1000;

  private lastCallbackExecutionTime = Date.now();

  private isProcessingCallback = false;

  constructor(
    private readonly callback: () => void,
    private readonly intervalInSeconds: number,
  ) {
    this.start();
  }

  private start(): void {
    this.timeoutId = setInterval(async () => {
      if (this.isProcessingCallback) {
        return;
      }

      if (Date.now() - this.lastCallbackExecutionTime >= this.intervalInSeconds * 1000) {
        this.isProcessingCallback = true;

        try {
          await this.executeCallback();
        } finally {
          this.isProcessingCallback = false;
        }
      }
    }, this.timeoutFrequencyInSeconds);
  }

  stop(): void {
    if (!this.timeoutId) {
      return;
    }

    clearInterval(this.timeoutId);
    this.timeoutId = null;
  }

  private async executeCallback(): Promise<void> {
    await this.callback();
    this.lastCallbackExecutionTime = Date.now();
  }
}
