export interface Player {
  currentTime: number;

  on(eventName: string, callback: () => void): void;

  destroy(): void;

  speed: number;
}

export enum PlayerTheme {
  White = 'white',
  Gray = 'gray-100',
  Primary = 'primary-light',
}
