import type { IDpProvider } from '@/core/di/DpProvider';
import { PatientModule } from '@/modules/patient';
import { RootModule } from '@/common/root';
import type { DpModule } from '@/core/modules/DpModule';
import { TrackingEventModule } from '@/common/tracking-event';
import { HttpClientModule } from '@/modules/http-client';
import { StateObserverProvider } from './providers/StateObserverProvider';
import { RepositoryProvider } from './providers/RepositoryProvider';
import { AdapterProvider } from './providers/AdapterProvider';
import { StoreProvider } from './providers/StoreProvider';
import { WebphoneProvider } from './providers/WebphoneProvider';
import { EventProvider } from './providers/EventProvider';
import { AuthorizationProvider } from './providers/AuthorizationProvider';

export class PhoneWidgetModule implements DpModule {
  static readonly namespace = Symbol('PhoneWidgetModule');

  static readonly requires = [RootModule, PatientModule, TrackingEventModule, HttpClientModule];

  providers(): IDpProvider[] {
    return [
      AuthorizationProvider,
      StoreProvider,
      AdapterProvider,
      StateObserverProvider,
      RepositoryProvider,
      WebphoneProvider,
      EventProvider,
    ];
  }
}
