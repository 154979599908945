export class Address {
  constructor(
    readonly id: string,
    readonly doctorId: string,
    readonly name: string,
    readonly street: string,
    readonly cityName: string,
    readonly postCode: string,
    readonly nearestSlotDate: string,
    readonly hasSlots: boolean,
    readonly isCalendarActive: boolean,
  ) {}

  get isEmpty(): boolean {
    return this.id === '';
  }

  get isAvailableForBookings(): boolean {
    return this.isCalendarActive;
  }

  equals(address: Address): boolean {
    if (this.isEmpty) {
      return false;
    }

    return this.id === address.id;
  }

  belongsTo(doctorId: string): boolean {
    if (this.isEmpty || this.doctorId === '') {
      return false;
    }

    return this.doctorId === doctorId;
  }

  static makeEmpty(): Address {
    return new Address('', '', '', '', '', '', '', false, false);
  }
}
