<template>
  <form-field-wrapper :class="$b()" :label="fieldLabel">
    <div :class="$b('button-wrapper')">
      <priority-button
        :is-active="modelValue"
        :size="24"
        @click="$emit('update:modelValue', !modelValue)"
      />
    </div>
  </form-field-wrapper>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';

import { FormFieldWrapper, PriorityButton } from '@/ui/atoms';

@Component({
  name: 'CustomFormPriorityButton',
  emits: ['update:modelValue'],
  components: {
    FormFieldWrapper,
    PriorityButton,
  },
})
export default class CustomFormPriorityButton extends Vue {
  @Prop({ type: String, default: '' })
  readonly label: string;

  @Prop({ type: Boolean, default: false })
  readonly modelValue: boolean;

  get fieldLabel(): string {
    return this.label || this.$ts('general.patient.priority');
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-custom-form-priority-button {
  &__button-wrapper {
    padding: 0.15em 0.5em;
    line-height: 0;
  }
}
</style>
