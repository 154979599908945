import { capitalizeName } from '@/utils/text';

interface PatientWithName {
  name: string;
  surname: string;
}

export function getName({ name = '', surname = '' }: PatientWithName): string {
  return `${capitalizeName(name)} ${capitalizeName(surname)}`.trim();
}
