import type { OwnershipFilterTypeRepository } from '@/modules/task/domain/ownership-filter/OwnershipFilterTypeRepository';
import { injectable } from 'tsyringe';
import { TaskListApi } from '@/modules/tasks-list/public/api';
import type { OwnershipFilterType } from '@/modules/task/domain/ownership-filter/OwnershipFilterType';

@injectable()
export class TaskListApiOwnershipFilterTypeRepository implements OwnershipFilterTypeRepository {
  constructor(private readonly taskListApi: TaskListApi) {}

  get(): OwnershipFilterType {
    return this.taskListApi.getCurrentOwnershipFilterOption().type;
  }
}
