import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { RootClock } from '@/common/root/providers/RootStoreProvider';
import {
  MediaRepositoryToken,
  MessageTemplateRepositoryToken,
  ConversationRepositoryToken,
  OwnerRepositoryToken,
  ClockToken,
  CallQualityReportRepositoryToken,
  CallRepositoryToken,
  TimelineRepositoryToken,
  SummaryRepositoryToken,
  MessageComposerRepositoryToken,
} from '@/modules/timeline/di/tokens';
import type { OwnerRepository } from '@/modules/timeline/domain/owner/OwnerRepository';
import type { CallRepository } from '@/modules/timeline/domain/call/CallRepository';
import type { MediaRepository } from '@/modules/timeline/domain/message-composer/message/media/MediaRepository';
import type { ConversationRepository } from '@/modules/timeline/domain/message-composer/ConversationRepository';
import { HttpMediaRepository } from '@/modules/timeline/adapters/message-composer/message/media/HttpMediaRepository';
import { HttpCallRepository } from '@/modules/timeline/adapters/call/HttpCallRepository';
import type { Clock } from '@/modules/timeline/domain/Clock';
import { HttpConversationRepository } from '@/modules/timeline/adapters/message-composer/message/HttpConversationRepository';
import type { MessageTemplateRepository } from '@/modules/timeline/domain/message-composer/templates/MessageTemplateRepository';
import { HttpMessageTemplateRepository } from '@/modules/timeline/adapters/message-composer/template/HttpMessageTemplateRepository';
import { UserOwnerRepository } from '@/modules/timeline/adapters/timeline/store/owner/UserOwnerRepository';
import type { CallQualityReportRepository } from '@/modules/timeline/domain/call/quality/CallQualityReportRepository';
import { HttpCallQualityReportRepository } from '@/modules/timeline/adapters/call/quality/HttpCallQualityReportRepository';
import type { SummaryRepository } from '@/modules/timeline/domain/call/summary/SummaryRepository';
import { HttpSummaryRepository } from '@/modules/timeline/adapters/call/summary/HttpSummaryRepository';
import type { TimelineRepository } from '@/modules/timeline/domain/timeline/TimelineRepository';
import { CombinedTimelineRepository } from '@/modules/timeline/adapters/timeline/CombinedTimelineRepository';
import type { MessageComposerRepository } from '@/modules/timeline/domain/message-composer/MessageComposerRepository';
import { StoreMessageComposerRepository } from '@/modules/timeline/adapters/message-composer/StoreMessageComposerRepository';

export class RepositoriesProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<TimelineRepository>(TimelineRepositoryToken, CombinedTimelineRepository);
    container.register<CallRepository>(CallRepositoryToken, HttpCallRepository);
    container.register<MediaRepository>(MediaRepositoryToken, HttpMediaRepository);
    container.register<ConversationRepository>(
      ConversationRepositoryToken,
      HttpConversationRepository,
    );
    container.register<OwnerRepository>(OwnerRepositoryToken, UserOwnerRepository);
    container.register<SummaryRepository>(SummaryRepositoryToken, HttpSummaryRepository);
    container.register<MessageTemplateRepository>(
      MessageTemplateRepositoryToken,
      HttpMessageTemplateRepository,
    );
    container.register<CallQualityReportRepository>(
      CallQualityReportRepositoryToken,
      HttpCallQualityReportRepository,
    );

    container.register<Clock>(ClockToken, {
      useFactory(c: DependencyContainer) {
        return c.resolve(RootClock);
      },
    });

    container.register<MessageComposerRepository>(
      MessageComposerRepositoryToken,
      StoreMessageComposerRepository,
    );
  }
}
