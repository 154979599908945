import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { RepositoryProvider } from '@/modules/user/di/providers/RepositoryProvider';
import { DateFormatterProvider } from '@/modules/user/di/providers/DateFormatterProvider';
import { RootModule } from '@/common/root';
import { HttpClientModule } from '@/modules/http-client';
import { PingProvider } from './providers/PingProvider';
import { PauseProvider } from './providers/PauseProvider';
import { EventBusProvider } from './providers/EventBusProvider';
import { TimeProvider } from './providers/TimeProvider';

export class UserModule implements DpModule {
  static readonly namespace = Symbol('UserModule');

  static readonly requires = [RootModule, HttpClientModule];

  providers(): IDpProvider[] {
    return [
      TimeProvider,
      DateFormatterProvider,
      RepositoryProvider,
      PingProvider,
      PauseProvider,
      EventBusProvider,
    ];
  }
}
