import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import { CallbackRepository } from '../../domain/repositories/CallbackRepository';
import { TaskUpdateCallback } from '../../domain/tracking/TaskUpdateCallback';

@injectable()
export class FrequencyStatsProvider implements DpProvider {
  constructor(
    private readonly callbackRepository: CallbackRepository,
    private readonly taskUpdateCallback: TaskUpdateCallback,
  ) {}

  boot(): void {
    this.callbackRepository.add(this.taskUpdateCallback);
  }
}
