import { phoneApi } from '@/api/phone-api';
import { path } from '@/utils/functions';

export function getSettingsActions(prefix, apiName) {
  return {
    [`REORDER_${prefix}_ACTION`]: async ({ getters, commit }, list) =>
      phoneApi
        .withFacility(getters.getUserFacilityID)
        [apiName].reorder(list)
        .then(response => commit(`REORDER_${prefix}`, response)),

    [`EDIT_${prefix}_ACTION`]: async ({ getters, commit }, { id, item }) =>
      phoneApi
        .withFacility(getters.getUserFacilityID)
        [apiName].edit(id, item)
        .then(response => {
          commit(`EDIT_${prefix}`, response);
          return response;
        }),

    [`ADD_${prefix}_ACTION`]: async ({ getters, commit }, item) =>
      phoneApi
        .withFacility(getters.getUserFacilityID)
        [apiName].add(item)
        .then(response => {
          commit(`ADD_${prefix}`, response);
          return response;
        }),

    [`DELETE_${prefix}_ACTION`]: async ({ getters, commit }, id) =>
      phoneApi
        .withFacility(getters.getUserFacilityID)
        [apiName].delete(id)
        .then(() => commit(`DELETE_${prefix}`, id)),
  };
}

export function getSettingsMutations(prefix, storePath) {
  function getStorePath() {
    return [...storePath];
  }

  return {
    [`REORDER_${prefix}`]: (state, payload) => {
      const tmpStorePath = getStorePath();
      const last = tmpStorePath.splice(tmpStorePath.length - 1, 1);
      const stateFragment = path(tmpStorePath, state.facility);

      const isObject = payload !== null && typeof payload === 'object' && !Array.isArray(payload);

      if (isObject) {
        stateFragment[last] = Object.values(payload);
        return;
      }
      stateFragment[last] = payload;
    },

    [`ADD_${prefix}`]: (state, payload) => {
      const tmpStorePath = getStorePath();
      const stateFragment = path(tmpStorePath, state.facility);
      const hasItem = stateFragment.some(obj => obj.id === payload.id);

      if (!hasItem) {
        stateFragment.push(payload);
      }
    },

    [`EDIT_${prefix}`]: (state, payload) => {
      const tmpStorePath = getStorePath();
      const stateFragment = path(tmpStorePath, state.facility);
      const itemIndex = stateFragment.findIndex(result => result.id === payload.id);

      stateFragment.splice(itemIndex, 1, payload);
    },

    [`DELETE_${prefix}`]: (state, id) => {
      const tmpStorePath = getStorePath();
      const last = tmpStorePath.splice(tmpStorePath.length - 1, 1);
      const stateFragment = path(tmpStorePath, state.facility);

      stateFragment[last] = stateFragment[last].filter(item => item.id !== id);
    },
  };
}
