import { DpEventBus } from '@/core/events/DpEventBus';
import { singleton } from 'tsyringe';
import { ConfigurationCleared } from '../domain/events/ConfigurationCleared';
import { ConfigurationSet } from '../domain/events/ConfigurationSet';
import type {
  CommonChannelConfiguration,
  PiiChannelConfiguration,
} from '../domain/models/ChannelConfiguration';
import { Configuration } from './Configuration';

@singleton()
export class PusherConfigurationRepository {
  private configuration: Configuration = Configuration.empty();

  constructor(private readonly eventBus: DpEventBus) {}

  get(): Configuration {
    return this.configuration;
  }

  set(
    appKey: string,
    cluster: string,
    commonChannel: CommonChannelConfiguration,
    piiChannel: PiiChannelConfiguration,
  ): void {
    this.configuration = new Configuration(appKey, cluster, commonChannel, piiChannel);
    this.eventBus.publish(new ConfigurationSet());
  }

  changeChannels(
    commonChannel: CommonChannelConfiguration,
    piiChannel: PiiChannelConfiguration,
  ): void {
    this.configuration = new Configuration(
      this.configuration.appKey,
      this.configuration.cluster,
      commonChannel,
      piiChannel,
    );
    this.eventBus.publish(new ConfigurationSet());
  }

  clear(): void {
    this.configuration = Configuration.empty();
    this.eventBus.publish(new ConfigurationCleared());
  }
}
