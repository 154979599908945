import { CallRecordingApi } from '@/modules/settings-call-recording/public/CallRecordingApi';
import { injectable } from 'tsyringe';
import type { CallRecordingStatus } from '../../domain/call-recording/CallRecordingStatus';
import type { CallsRecordingStatusRepository } from '../../domain/call-recording/CallRecordingStatusRepository';

@injectable()
export class SettingsCallRecordingStatusRepository implements CallsRecordingStatusRepository {
  constructor(private readonly callRecordingApi: CallRecordingApi) {}

  async get(): Promise<CallRecordingStatus> {
    return this.callRecordingApi.get();
  }

  async enableCallRecording(): Promise<CallRecordingStatus> {
    return this.callRecordingApi.enableCallRecording();
  }

  async disableCallRecording(): Promise<CallRecordingStatus> {
    return this.callRecordingApi.disableCallRecording();
  }
}
