import type { MediaProxy } from '../../../MediaProxy';

export class Media {
  constructor(
    readonly id: string,
    readonly contentType: string,
    readonly name: string,
    private readonly originUrl: string,
    private readonly mediaProxy: MediaProxy | null = null,
  ) {}

  get url(): string {
    if (!this.mediaProxy) {
      return this.originUrl;
    }

    return this.mediaProxy.make(this.originUrl);
  }

  get isImage(): boolean {
    return this.type === 'image';
  }

  get isAudio(): boolean {
    return this.type === 'audio';
  }

  get isVideo(): boolean {
    return this.type === 'video';
  }

  get isPdf(): boolean {
    return this.contentType === 'application/pdf';
  }

  private get type(): string {
    const [type] = this.contentType.split('/');

    return type;
  }
}
