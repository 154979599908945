<template>
  <span :class="$b()">
    <span :class="$b('trigger')">
      <slot name="tooltip-trigger" />
    </span>
    <span :class="$b('content')">
      <slot name="tooltip-content" />
    </span>
  </span>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator';
import { DpPopover } from '@dp-ui-kit/vue';

@Component({
  name: 'CustomTooltip',
  components: {
    DpPopover,
  },
})
export default class CustomTooltip extends Vue {}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

$bg-color: v.$gray-900;
$triangle-size: 0.5em;

.dp-custom-tooltip {
  $block: &;

  position: relative;
  display: inline-block;

  &:hover {
    #{$block}__content {
      opacity: 1;
      visibility: visible;
    }
  }

  &__trigger {
    cursor: pointer;
  }

  &__content {
    position: absolute;
    bottom: calc(100% + $triangle-size);
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5em 0.75em;
    border-radius: v.$border-radius;
    background: $bg-color;
    color: v.$white;
    font-size: v.$font-size-sm;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-left: $triangle-size solid transparent;
      border-right: $triangle-size solid transparent;
      border-top: $triangle-size solid $bg-color;
    }
  }
}
</style>
