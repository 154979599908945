import type { InputDateParser } from './InputDateParser';
import type { OutputDateNormalizer, OutputDateNormalizerFactory } from './OutputDateNormalizer';
import type {
  OutputDistanceNormalizer,
  OutputDistanceNormalizerFactory,
} from './OutputDistanceNormalizer';
import type { DateFormatter as IDateFormatter } from './types';

export class DateFormatter implements IDateFormatter {
  constructor(
    private readonly inputParser: InputDateParser,
    private readonly outputDateNormalizerFactory: OutputDateNormalizerFactory,
    private readonly outputDistanceNormalizerFactory: OutputDistanceNormalizerFactory,
  ) {}

  date(date: Date): OutputDateNormalizer {
    return this.outputDateNormalizerFactory.make(date);
  }

  dateFromIso(date: string, timeZone: string): OutputDateNormalizer {
    const parsedDate = this.getParsedIsoDate(date, timeZone);
    return this.outputDateNormalizerFactory.make(parsedDate);
  }

  dateFromUnixTime(timestamp: number, timeZone: string): OutputDateNormalizer {
    const parsedDate = this.getParsedTimestamp(timestamp, timeZone);
    return this.outputDateNormalizerFactory.make(parsedDate);
  }

  dateFromFormattedString(date: string, timeZone: string, format: string): OutputDateNormalizer {
    const parsedDate = this.getParsedFormattedString(date, timeZone, format);
    return this.outputDateNormalizerFactory.make(parsedDate);
  }

  distanceFromUnixTime(
    startTime: number,
    endTime: number,
    timeZone: string,
  ): OutputDistanceNormalizer {
    const parsedStartDate = this.getParsedTimestamp(startTime, timeZone);
    const parsedEndDate = this.getParsedTimestamp(endTime, timeZone);
    return this.outputDistanceNormalizerFactory.make(parsedStartDate, parsedEndDate);
  }

  distanceToNowFromIso(date: string, timeZone: string): OutputDistanceNormalizer {
    const parsedDate = this.getParsedIsoDate(date, timeZone);
    return this.outputDistanceNormalizerFactory.makeToNow(parsedDate);
  }

  private getParsedIsoDate(date: string, timeZone: string): Date {
    return this.inputParser.fromISO(date, timeZone);
  }

  private getParsedTimestamp(timestamp: number, timeZone: string): Date {
    return this.inputParser.fromUnixTime(timestamp, timeZone);
  }

  private getParsedFormattedString(date: string, timeZone: string, format: string): Date {
    return this.inputParser.fromFormattedString(date, timeZone, format);
  }
}
