import { injectable } from 'tsyringe';
import type { Authorizer } from '@/modules/dashboard/domain/Authorizer';
import { Authorization } from '@/common/authorization/public/api';
import {
  AccessSettings,
  AccessStatistics,
  UseEventLogger,
  ChangeWorkstation,
} from '@/modules/dashboard/domain/policies';

@injectable()
export class AuthorizationApiAuthorizer implements Authorizer {
  constructor(private readonly authorizationApi: Authorization) {}

  canSeeStatistics(): boolean {
    return this.authorizationApi.can(AccessStatistics);
  }

  canUseEventLogger(): boolean {
    return this.authorizationApi.can(UseEventLogger);
  }

  canSeeSettings(): boolean {
    return this.authorizationApi.can(AccessSettings);
  }

  canChangeWorkstation(): boolean {
    return this.authorizationApi.can(ChangeWorkstation);
  }
}
