import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { Authorization } from '@/common/authorization/public/api';
import { injectable } from 'tsyringe';

import {
  crmManagement,
  docplannerUrlIntegration,
} from '../../domain/super-admin-settings/policies';
import type { DocplannerUrlIntegrationRepository } from '../../domain/super-admin-settings/DocplannerUrlIntegrationRepository';
import { CrmRepositoryToken, DocplannerUrlIntegrationRepositoryToken } from '../token';
import { HttpDocplannerUrlIntegrationRepository } from '../../adapters/super-admin/HttpDocplannerUrlIntegrationRepository';
import type { CrmRepository } from '../../domain/super-admin-settings/CrmRepository';
import { HttpCrmRepository } from '../../adapters/super-admin/HttpCrmRepository';

@injectable()
export class SuperAdminProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<DocplannerUrlIntegrationRepository>(
      DocplannerUrlIntegrationRepositoryToken,
      HttpDocplannerUrlIntegrationRepository,
    );
    container.register<CrmRepository>(CrmRepositoryToken, HttpCrmRepository);
  }

  boot(container: DependencyContainer): void {
    const authorization = container.resolve(Authorization);

    authorization.addPolicies([
      {
        name: crmManagement,
        handler: user => user.hasRole('CRM_SETTINGS'),
      },
      {
        name: docplannerUrlIntegration,
        handler: user => user.hasRole('DOCPLANNER_PROFILE_INTEGRATION'),
      },
    ]);
  }
}
