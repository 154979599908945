import { schema } from 'normalizr';
import { EntityTypes } from '@/api/types/entities';
import { conversationMessageSchema } from './conversation-message-schema';

export const conversationSchema = new schema.Entity(
  EntityTypes.Conversation,
  {
    messages: [conversationMessageSchema],
  },
  {
    processStrategy(value, parent) {
      return {
        ...value,
        taskId: parent.id,
        patientId: parent.patientId,
      };
    },
  },
);
