import { injectable } from 'tsyringe';
import { Seconds } from '../../domain/time/Seconds';
import type { PerformanceData } from '../../domain/overview-performance/PerformanceData';
import type { PerformanceDataRecord } from '../../domain/overview-performance/PerformanceDataRecord';
import type { InputPerformanceData } from './InputPerformanceData';

@injectable()
export class PerformanceDataFactory {
  make(
    currentInputData: InputPerformanceData,
    previousInputData: InputPerformanceData,
  ): PerformanceData {
    return {
      current: this.makeSingleRecord(currentInputData),
      previous: this.makeSingleRecord(previousInputData),
    };
  }

  makeSingleRecord(inputData: InputPerformanceData): PerformanceDataRecord {
    return {
      medianWaitingTime: Seconds.make(inputData.median_waiting_time),
      medianCallbackTime: Seconds.fromMinutes(inputData.median_callback_time),
      callbacks10m: inputData.callbacks10m,
      attendance: inputData.attendance,
    };
  }
}
