import { injectable } from 'tsyringe';
import type { TokenRepository } from '@/modules/phone-widget/domain/webphone/device/TokenRepository';
import { HttpClientApi } from '@/modules/http-client/public/api';

@injectable()
export class HttpTokenRepository implements TokenRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  get(workstationId: string, lifetimeInSeconds: number): Promise<string> {
    return this.httpClient.put(`/api/v0/web-phone/${workstationId}`, {
      lifetime: lifetimeInSeconds,
    });
  }
}
