import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { FacilityAccessChecker } from '../../domain/ping/UserFacilityAccessValidator';

interface Store {
  getters: {
    getUserFacilityID: string | null;
  };
}

@injectable()
export class StoreFacilityAccessChecker implements FacilityAccessChecker {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
  ) {}

  async hasLoadedFacility(): Promise<boolean> {
    return this.store.getters.getUserFacilityID !== null;
  }
}
