import { inject, injectable } from 'tsyringe';
import type { FilterOption } from '@/modules/tasks-list/domain/ownership-filter/options/FilterOption';
import type { FilterCurrentStateRepository } from '@/modules/tasks-list/domain/ownership-filter/state/FilterCurrentStateRepository';
import { FilterOptionList } from '@/modules/tasks-list/domain/ownership-filter/FilterOptionList';
import { FilterCurrentState } from '@/modules/tasks-list/domain/ownership-filter/state/FilterCurrentState';
import { FilterCurrentStateRepositoryToken } from '@/modules/tasks-list/di/tokens';

@injectable()
export class CurrentFilterOption {
  constructor(
    @inject(FilterCurrentStateRepositoryToken)
    private readonly filterCurrentStateRepository: FilterCurrentStateRepository,
    private readonly filterOptionList: FilterOptionList,
  ) {}

  get(): FilterOption {
    const state = this.filterCurrentStateRepository.get();

    return this.filterOptionList.getOptionByValue(state.value);
  }

  set(value: string): void {
    return this.filterCurrentStateRepository.set(new FilterCurrentState(value));
  }

  clear(): void {
    return this.filterCurrentStateRepository.clear();
  }
}
