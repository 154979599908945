import { injectable } from 'tsyringe';
import type { BookingRequest } from '@/modules/book-visit/domain/booking/BookingRequest';
import type { HttpOutputBookingRequest } from './HttpBookingRequests';

@injectable()
export class HttpOutputBookingRequestFactory {
  make({
    doctor,
    address,
    service,
    bookingSlot,
    patient,
  }: BookingRequest): HttpOutputBookingRequest {
    if (!doctor || !address || !service || !bookingSlot || !patient) {
      throw new Error('Cannot create payload for a booking');
    }

    return {
      doctorId: doctor.id,
      addressId: address.id,
      addressServiceId: service.id,
      start: bookingSlot.start,
      duration: bookingSlot.duration,
      patient: {
        phone: patient.phone,
        name: patient.firstName,
        surname: patient.lastName,
      },
    };
  }
}
