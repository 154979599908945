import type { StorageItemFactory } from '@/common/root/domain/storage/models/StorageItemFactory';
import { MemoryStorage } from '@/common/root/domain/storage/models/MemoryStorage';
import { StorageFactory } from '@/common/root/domain/storage/services/StorageFactory';
import { getId } from '@/app/get-id';

export function createMemoryBucket(): any {
  return new StorageFactory(new MemoryStorage(), Date).createStorageItemFactory(
    `memory-${getId()}`,
  );
}

export const createLocalStorageBucket = <T>(prefix: string): StorageItemFactory<T> =>
  new StorageFactory(localStorage, Date).createStorageItemFactory(prefix) as any;
