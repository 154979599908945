import { isModuleEnabled } from '@/utils/module-switcher';
import config from '@/config/config.json';
import type { App } from 'vue';

declare global {
  interface Window {
    PHRASEAPP_CONFIG: any;
  }
}

const phraseAppTrans = key => `{{__phrase_${key}__}}`;

export const phraseAppPlugin = {
  install(app: App): void {
    if (!isModuleEnabled('translations')) {
      return;
    }

    window.PHRASEAPP_CONFIG = {
      projectId: config.PHRASEAPP_PROJECT_ID,
    };

    const phraseApp = document.createElement('script');
    phraseApp.type = 'text/javascript';
    phraseApp.async = true;
    phraseApp.src = [
      'https://',
      'phraseapp.com/assets/in-context-editor/2.0/app.js?',
      new Date().getTime(),
    ].join('');

    const s = document.getElementsByTagName('script')[0];
    s.parentNode?.insertBefore(phraseApp, s);

    app.config.globalProperties.$t = phraseAppTrans;
  },
};
