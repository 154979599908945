import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { HttpClientModule } from '@/modules/http-client';
import { RootModule } from '@/common/root';
import { AdapterProvider } from './providers';

export class AnonymizationModule implements DpModule {
  static readonly namespace = Symbol('AnonymizationModule');

  static readonly requires = [RootModule, HttpClientModule];

  providers(): IDpProvider[] {
    return [AdapterProvider];
  }
}
