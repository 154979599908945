import type { DateString } from '@/types/commons';

export const extractPageCursor: (value: string | null) => DateString | null = value => {
  if (!value) {
    return null;
  }

  const cursor = value.match(/before=([-0-9:T+]+)>/);

  if (cursor?.length) {
    return cursor[1] as DateString;
  }

  return null;
};
