import type { EventsList } from '@/modules/timeline/domain/timeline/events-list/EventsList';
import { CallCount } from '@/modules/timeline/domain/call/CallCount';
import { MessagesCount } from '@/modules/timeline/domain/message-composer/message/MessagesCount';
import type { Context } from './context/Context';
import type { EventsListGrouperItem } from './events-list/EventsListGrouperItem';

export class Timeline {
  constructor(
    readonly taskId = '',
    readonly context: Context | null,
    readonly events: EventsList,
  ) {}

  get groupedEvents(): EventsListGrouperItem[] {
    return this.events.groupedEvents;
  }

  get hasAwaitingMessages(): boolean {
    return this.events.lastMessage?.direction === 'inbound' ?? false;
  }

  get hasContext(): boolean {
    return !!this.context;
  }

  get callCount(): CallCount {
    return CallCount.of(this.events.calls);
  }

  get messagesCount(): MessagesCount {
    return MessagesCount.of(this.events.messages);
  }

  hasMoreEventThen(timeline: Timeline): boolean {
    return this.events.length > timeline.events.length;
  }

  isEquals(timeline: Timeline): boolean {
    return JSON.stringify(this) === JSON.stringify(timeline);
  }
}
