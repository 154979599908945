import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { EventTrackerFacade } from '@/common/tracking-event/public/api';
import { RootErrorLoggerToken } from '@/common/root/providers/RootErrorLoggerProvider';
import {
  ClientFactoryToken,
  ErrorLoggerToken,
  EventTrackerToken,
  PresenceCallbackFactoryToken,
  UserNotifierToken,
} from '../tokens';
import { PusherClientFactory } from '../../adapters/PusherClientFactory';
import type { ClientFactory } from '../../domain/ClientFactory';
import type { PresenceCallbackFactory } from '../../domain/callbacks/PresenceCallbackFactory';
import { StorePresenceCallbackFactory } from '../../adapters/callbacks/StorePresenceCallbackFactory';
import type { EventTracker } from '../../domain/EventTracker';
import type { ErrorLogger } from '../../domain/ErrorLogger';
import type { UserNotifier } from '../../domain/UserNotifier';
import { AppUserNotifier } from '../../adapters/AppUserNotifier';

@injectable()
export class AdaptersProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<ClientFactory>(ClientFactoryToken, PusherClientFactory);
    container.register<PresenceCallbackFactory>(
      PresenceCallbackFactoryToken,
      StorePresenceCallbackFactory,
    );
    container.register<EventTracker>(EventTrackerToken, EventTrackerFacade);
    container.register<ErrorLogger>(ErrorLoggerToken, {
      useFactory(c) {
        return c.resolve(RootErrorLoggerToken);
      },
    });

    container.register<UserNotifier>(UserNotifierToken, AppUserNotifier);
  }
}
