import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { Store } from 'vuex';
import type { FacilityRepository } from '../../domain/facility/FacilityRepository';
import { FacilityUser } from '../../domain/facility/FacilityUser';
import { Facility } from '../../domain/facility/Facility';

interface FacilityPermission {
  role: 'manager' | 'receptionist';
  user: {
    id: string;
  };
}

interface StoreFacility {
  id: string;
  _acl: 'VIEW' | 'EDIT';
  permissions: FacilityPermission[];
}

@injectable()
export class StoreFacilityRepository implements FacilityRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store<unknown>,
  ) {}

  find(): Facility | null {
    const { permissions, id, _acl }: StoreFacility = this.store.getters.getUserFacility;

    const facilityUsers = permissions.map(({ user }) => new FacilityUser(user.id));
    const accessType = _acl === 'EDIT' ? 'EDIT' : 'VIEW';

    return new Facility(id, accessType, facilityUsers);
  }
}
