import type { EventsListItem } from '@/modules/timeline/domain/timeline/events-list/EventsListItem';
import type { PatientRequestAttachment } from '@/modules/timeline/domain/patient-request/PatientRequestAttachment';
import type { IsoDate } from '@/modules/timeline/domain/IsoDate';

export class PatientRequest implements EventsListItem {
  readonly direction = 'inbound' as const;

  constructor(
    readonly id: string,
    readonly createdAt: IsoDate,
    readonly type:
      | 'prescription'
      | 'certificate'
      | 'refill-prescription'
      | 'test-results'
      | 'other',
    readonly subject: string,
    readonly patientFullName: string,
    readonly doctorFullName: string,
    readonly comment = '',
    readonly attachments: PatientRequestAttachment[] = [],
  ) {}
}
