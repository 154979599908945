export class Patient {
  constructor(
    readonly phone: string,
    readonly firstName = '',
    readonly lastName = '',
  ) {}

  get isEmpty(): boolean {
    return this.phone === '';
  }

  get hasMissingNameOrSurname(): boolean {
    return !this.firstName || !this.lastName;
  }

  static makeEmpty(): Patient {
    return new Patient('');
  }
}
