import type { FacilityId } from '@/api/models/Facility';
import type { WorkstationId } from '@/api/types/entities/workstation';
import type { FacilityCallRepository } from '../types/FacilityCallRepository';
import { BaseAxiosRepository } from './BaseAxiosRepository';

export class AxiosFacilityCallRepository
  extends BaseAxiosRepository
  implements FacilityCallRepository
{
  async makeOutgoingCall(id: FacilityId, To: string): Promise<void> {
    await this.axiosClient.post(`/api/v0/facility/${id}/call`, { target: To });
  }

  async makeInternalCall(id: FacilityId, workstationId: WorkstationId): Promise<void> {
    await this.axiosClient.post(`/api/v0/facility/${id}/internal-call/${workstationId}`);
  }
}
