import { inject, injectable } from 'tsyringe';
import {
  DesktopNotificationNotifierToken,
  MessageRepositoryToken,
  SoundNotificationRepositoryToken,
} from '../di/tokens';
import { NotificationConfigurationReader } from './configuration/NotificationConfigurationReader';
import { newMessageNotificationName } from './configuration/NotificationName';
import { DesktopNotificationFactory } from './desktop-notification/DesktopNotificationFactory';
import type { DesktopNotificationNotifier } from './desktop-notification/DesktopNotificationNotifier';
import type { MessageRepository } from './MessageRepository';
import { DesktopNotificationEvaluator } from './permissions/DesktopNotificationEvaluator';
import type { SoundNotificationRepository } from './sound-notification/SoundNotificationRepository';
import { SoundPlayer } from './sound-notification/SoundPlayer';
import type { TaskUpdated } from './TaskUpdated';

@injectable()
export class NewMessageHandler {
  constructor(
    private readonly notificationConfigurationReader: NotificationConfigurationReader,
    private readonly desktopNotificationEvaluator: DesktopNotificationEvaluator,
    @inject(MessageRepositoryToken)
    private readonly messageRepository: MessageRepository,
    @inject(DesktopNotificationNotifierToken)
    private readonly desktopNotificationNotifier: DesktopNotificationNotifier,
    private readonly desktopNotificationFactory: DesktopNotificationFactory,
    private readonly soundPlayer: SoundPlayer,
    @inject(SoundNotificationRepositoryToken)
    private readonly soundNotificationRepository: SoundNotificationRepository,
  ) {}

  async handle(event: TaskUpdated): Promise<void> {
    if (
      !this.hasMatchingTrigger(event) ||
      !this.hasEnabledNotification() ||
      this.desktopNotificationEvaluator.hasDeniedPermission()
    ) {
      return;
    }

    if (this.desktopNotificationEvaluator.canAskForPermission()) {
      const hasGrantedPermissions =
        await this.desktopNotificationEvaluator.askAndCheckIfGrantedPermission();
      if (!hasGrantedPermissions) {
        return;
      }
    }

    const lastMessage = this.messageRepository.get(event.id).at(-1);

    if (!lastMessage || lastMessage.direction !== 'inbound') {
      return;
    }

    const notification = this.desktopNotificationFactory.makeForMessage(lastMessage);
    this.desktopNotificationNotifier.display(notification);
    const sound = this.soundNotificationRepository.getForNewMessage();
    this.soundPlayer.play(sound);
  }

  private hasEnabledNotification(): boolean {
    return this.notificationConfigurationReader.isEnabled(newMessageNotificationName);
  }

  private hasMatchingTrigger(event: TaskUpdated): boolean {
    return event.updateTriggers.includes('MessageReceivedEvent');
  }
}
