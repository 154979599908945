import { inject, injectable } from 'tsyringe';
import type { Router as _VueRouter, RouteLocationNamedRaw, RouteLocationRaw } from 'vue-router';
import { RootRouterToken } from '@/common/root';
import type { Router } from '@/modules/authentication/domain/Router';

@injectable()
export class VueRouter implements Router {
  constructor(
    @inject(RootRouterToken)
    private readonly router: _VueRouter,
  ) {}

  navigateToHome(): Promise<void> {
    return this.pushToLanding({ name: 'home' });
  }

  navigateToFacilitySelection(): Promise<void> {
    return this.pushToLanding({ name: 'select-facility' });
  }

  navigateToPhoneSelection(): Promise<void> {
    return this.pushToLanding({ name: 'select-phone' });
  }

  navigateToLogin(): Promise<void> {
    return this.safePush({ name: 'login' });
  }

  // TODO redirect to target explicitly at the end of the login flow
  private pushToLanding(location: RouteLocationNamedRaw) {
    const { currentRoute } = this.router;

    if (!currentRoute) {
      return this.safePush(location);
    }

    const { target } = currentRoute.value.query;

    if (!target) {
      return this.safePush(location);
    }

    const shouldPreserveTarget = location.name !== 'home';

    if (shouldPreserveTarget) {
      return this.safePush({ ...location, query: { target } });
    }

    return this.safePush({ path: `${target}` });
  }

  private async safePush(location: RouteLocationRaw) {
    try {
      await this.router.push(location);
    } catch {
      // do nothing
    }
  }
}
