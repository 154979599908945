import { inject, injectable } from 'tsyringe';
import { CallTrackerResponse } from '@/modules/task/domain/call-tracking/trackers/CallTrackerResponse';
import type { EventTracker } from '../../tracking-event/EventTracker';
import type { OwnerRepository } from '../../owner/OwnerRepository';
import { OwnerRepositoryToken, EventTrackerToken } from '../../../di/tokens';
import type { Call } from '../../call/Call';
import type { CallTracker } from './CallTracker';
import type { Task } from '../../task/Task';

@injectable()
export class ShortOutboundCallsTracker implements CallTracker {
  constructor(
    @inject(OwnerRepositoryToken)
    private readonly ownerRepository: OwnerRepository,
    @inject(EventTrackerToken)
    private readonly eventTracker: EventTracker,
  ) {}

  track(call: Call, task: Task): CallTrackerResponse {
    const isCompletedOutboundCallUnder5Seconds =
      call.isOutgoing &&
      call.isCompleted &&
      call.isShorterThan5Sec &&
      call.isMine(this.ownerRepository);

    if (isCompletedOutboundCallUnder5Seconds) {
      this.eventTracker.publishBusinessEvent('Call - Outbound call under 5 seconds', {
        task_id: task.id,
      });

      return CallTrackerResponse.EventPublished;
    }

    return CallTrackerResponse.EventNotPublished;
  }
}
