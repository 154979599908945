import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { ConversationRepository } from '@/modules/timeline/domain/message-composer/ConversationRepository';
import type { ConversationId } from '@/modules/timeline/domain/message-composer/ConversationId';
import type { PhoneNumber } from '@/modules/timeline/domain/message-composer/PhoneNumber';
import type { MessageTemplate } from '@/modules/timeline/domain/message-composer/templates/MessageTemplate';

@injectable()
export class HttpConversationRepository implements ConversationRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async addMessage(
    conversationId: ConversationId,
    body = '',
    media: string | null = null,
  ): Promise<void> {
    await this.httpClient.post(`/api/v0/conversation/${conversationId.value}/message`, {
      body,
      media,
    });
  }

  async create(phoneNumber: PhoneNumber, body: string): Promise<void> {
    await this.httpClient.post(`/api/v0/facility/{facilityId}/message/${phoneNumber.value}`, {
      body,
    });
  }

  async createUsingTemplate(phoneNumber: PhoneNumber, template: MessageTemplate): Promise<void> {
    return this.addTemplateMessage(phoneNumber, template);
  }

  async addTemplateMessage(phoneNumber: PhoneNumber, template: MessageTemplate): Promise<void> {
    return this.httpClient.post(
      `/api/v0/facility/{facilityId}/message/${phoneNumber.value}/template/${template.id}`,
    );
  }
}
