import type { DpProvider } from '@/core/di/DpProvider';
import { DpEventBus } from '@/core/events/DpEventBus';
import { TaskUpdatedEvent } from '@/modules/tasks-list/public/events';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { TaskUpdatedListener } from '../../adapters/events/TaskUpdatedListener';

@injectable()
export class EventBusProvider implements DpProvider {
  boot(container: DependencyContainer): void {
    const eventBus = container.resolve(DpEventBus);

    eventBus.subscribe(TaskUpdatedEvent.eventName, container.resolve(TaskUpdatedListener));
  }
}
