import type { OutputDistanceNormalizer } from './OutputDistanceNormalizer';
import type { OutputFormatter } from './types';

export class TranslatedOutputDistanceNormalizer implements OutputDistanceNormalizer {
  constructor(
    private readonly outputFormatter: OutputFormatter,
    private readonly startDate: Date,
    private readonly endDate: Date | null = null,
  ) {}

  toDefaultDistance(): string {
    if (this.endDate === null) {
      throw new Error('endDate is null');
    }

    return this.outputFormatter.formatDistanceStrict(this.startDate, this.endDate);
  }

  formatDistanceToNowStrict(): string {
    return this.outputFormatter.formatDistanceToNowStrict(this.startDate);
  }
}
