import { PublicPatientApi } from '@/modules/patient/public/api/PublicPatientApi';
import { delay, inject, injectable } from 'tsyringe';
import type { PatientRepository } from '../../domain/create-task/PatientRepository';
import type { Patient } from '../../domain/models/Patient';
import { PatientFactory } from './factories/PatientFactory';

@injectable()
export class PatientApiPatientRepository implements PatientRepository {
  constructor(
    @inject(delay(() => PublicPatientApi))
    private readonly patientApi: PublicPatientApi,
    private readonly patientFactory: PatientFactory,
  ) {}

  async getByQuery(phrase: string): Promise<Patient[]> {
    const patientsCollection = await this.patientApi.getList(phrase);

    return this.patientFactory.makeMany(patientsCollection);
  }
}
