export class IsoDate {
  private constructor(
    readonly isoDate: string,
    readonly timezone: string,
  ) {}

  static make(isoDate: string, timezone: string): IsoDate {
    return new IsoDate(isoDate, timezone);
  }

  static get empty(): IsoDate {
    const now = new Date();

    return new IsoDate(now.toISOString(), 'Etc/UTC');
  }
}
