import type { Store } from 'vuex';
import type { Connection, ConnectionState } from '@/modules/phone-widget/services/ConnectionState';
import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';

type UnwatchFunction = () => void;

type CallbackFunction = (connection: Connection) => void;

@injectable()
export class StoreToConnectionStateAdapter implements ConnectionState {
  private callbackFunction: CallbackFunction | null = null;

  private unwatchFunction: UnwatchFunction | null = null;

  constructor(@inject(RootStoreToken) private readonly store: Store<unknown>) {}

  watch(callback: CallbackFunction): void {
    this.callbackFunction = callback;
    this.unwatchFunction = this.store.watch(this.detectChange, this.handleChange.bind(this));
  }

  unwatch(): void {
    this.unwatchFunction?.();
    this.unwatchFunction = null;
  }

  private detectChange(_, getters): Connection {
    return getters['phoneWidget/connectionInstance'];
  }

  private handleChange(connection: Connection, previousConnection: Connection): void {
    if (connection?.status !== previousConnection?.status) {
      this.callbackFunction?.(connection);
    }
  }
}
