import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import { $GET_ENTITIES_BY_ID } from '@dp-vue/entities';
import type { Call } from '@/modules/timeline/domain/call/Call';
import type { Store } from '../Store';
import { StoreCallFactory } from './StoreCallFactory';
import type { StoreTask } from '../../../StoreTask';

@injectable()
export class StoreCallRepository {
  constructor(
    @inject(RootStoreToken) private readonly store: Store,
    private readonly callFactory: StoreCallFactory,
  ) {}

  get(task: StoreTask, timezone: string): Call[] {
    const calls = this.store.getters[$GET_ENTITIES_BY_ID]('call', task.history);

    return this.callFactory.makeMany(calls, timezone);
  }
}
