import type { OutputDateNormalizer, OutputDateNormalizerFactory } from './OutputDateNormalizer';
import { TranslatedOutputDateNormalizer } from './TranslatedOutputDateNormalizer';
import type { OutputFormatter, Translator } from './types';

export class TranslatedOutputDateNormalizerFactory implements OutputDateNormalizerFactory {
  constructor(
    private readonly translator: Translator,
    private readonly outputFormatter: OutputFormatter,
  ) {}

  make(date: Date): OutputDateNormalizer {
    return new TranslatedOutputDateNormalizer(this.translator, this.outputFormatter, date);
  }
}
