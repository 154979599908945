import type { Component } from '@/app/app-status/component/Component';
import type { ComponentIdDetector } from '@/app/app-status/component/ComponentIdDetector';
import type { ComponentRepository } from '@/app/app-status/component/ComponentRepository';
import { ComponentNotFoundError } from '@/app/app-status/errors/ComponentNotFoundError';

export class ComponentFetcher {
  constructor(
    private readonly componentIdDetector: ComponentIdDetector,
    private readonly componentRepository: ComponentRepository,
  ) {}

  async fetchDomainApiComponent(): Promise<Component | null> {
    const apiComponentId = this.getComponentId();

    if (!apiComponentId) {
      return null;
    }

    return this.getComponentById(apiComponentId);
  }

  private getComponentId(): string | null {
    return this.componentIdDetector.getStatusPageComponentId();
  }

  private async getComponentById(apiComponentId): Promise<Component> {
    const apiComponents = await this.getAllComponents();

    const apiComponent = apiComponents.find(component => component.id === apiComponentId);

    if (!apiComponent) {
      throw new ComponentNotFoundError(apiComponentId);
    }

    return apiComponent;
  }

  private async getAllComponents(): Promise<Component[]> {
    return this.componentRepository.get();
  }
}
