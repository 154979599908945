import { MessageComposer } from '@/modules/timeline/domain/message-composer/MessageComposer';
import type { MessageComposerRepository } from '@/modules/timeline/domain/message-composer/MessageComposerRepository';
import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import { StoreMessageRepository } from '@/modules/timeline/adapters/timeline/store/message/StoreMessageRepository';
import { $GET_ENTITIES_BY_ID } from '@dp-vue/entities';
import { WhatsAppMessage } from '@/modules/timeline/domain/message-composer/message/WhatsAppMessage';
import type { Store } from './Store';
import { StoreMessageComposerFactory } from './StoreMessageComposerFactory';
import type { StoreTask } from '../StoreTask';

@injectable()
export class StoreMessageComposerRepository implements MessageComposerRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
    private readonly storeMessageRepository: StoreMessageRepository,
    private readonly storeMessageComposerFactory: StoreMessageComposerFactory,
  ) {}

  get(taskId: string): MessageComposer {
    const task = this.store.getters[$GET_ENTITIES_BY_ID]('task', [taskId])[0];
    const patient = this.store.getters[$GET_ENTITIES_BY_ID]('patient', [task.patientId])[0];

    const { channels } = this.store.getters.getUserFacility;

    return this.storeMessageComposerFactory.make(task, patient, this.getMessages(task), channels);
  }

  private getMessages(task: StoreTask): WhatsAppMessage[] {
    return this.storeMessageRepository
      .get(task, this.store.getters.getFacilityTimeZone)
      .filter((message): message is WhatsAppMessage => message instanceof WhatsAppMessage);
  }
}
