import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { ServiceRepository } from '@/modules/book-visit/domain/service/ServiceRepository';
import { ServiceFactory } from '@/modules/book-visit/adapters/service/ServiceFactory';
import type { InputResponse } from '@/modules/book-visit/adapters/InputResponses';
import type { Service } from '@/modules/book-visit/domain/service/Service';
import type { InputService } from '@/modules/book-visit/adapters/service/InputService';

@injectable()
export class HttpServiceRepository implements ServiceRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly serviceFactory: ServiceFactory,
  ) {}

  async get(doctorId: string, addressId: string): Promise<Service[]> {
    const servicesResponse: InputResponse<InputService> = await this.httpClient.get(
      `/api/v0/facility/{facilityId}/booking/get-address-services/${doctorId}/${addressId}`,
    );

    // eslint-disable-next-line no-underscore-dangle
    return servicesResponse._items.map(service => this.serviceFactory.make(service, addressId));
  }
}
