import { delay, inject, injectable } from 'tsyringe';
import type { StorageFactory } from '@/common/root/domain/storage/services/StorageFactory';
import { RootLocalStorageFactoryToken } from '@/common/root/providers/RootStorageProvider';
import { UserApi } from '@/modules/user/public/api/UserApi';
import type { StorageItem } from '@/common/root/domain/storage/models/StorageItem';
import type { StorageItemFactory } from '@/common/root/domain/storage/models/StorageItemFactory';
import type { CSModeRepository } from '../../domain/customer-success-mode/CSModeRepository';
import { CSMode } from '../../domain/customer-success-mode/CSMode';
import type { CustomerSuccessBucket } from './CustomerSuccessBucket';
import type { StoredCSMode } from './StoredCSMode';

@injectable()
export class LocalStorageCSModeRepository implements CSModeRepository {
  private memoryBucket: null | StorageItemFactory<CustomerSuccessBucket> = null;

  private twoWeeksInSeconds = 14 * 24 * 60 * 60;

  constructor(
    @inject(RootLocalStorageFactoryToken)
    private readonly storageFactory: StorageFactory,
    @inject(delay(() => UserApi))
    private readonly userApi: UserApi,
  ) {}

  find(): CSMode | null {
    const memoryCell = this.getMemoryCell();
    return memoryCell?.value ? this.toCSMode(memoryCell.value) : null;
  }

  save(mode: CSMode): void {
    const memoryCell = this.getMemoryCell();

    if (!memoryCell) {
      throw new Error('Cannot initialize LocalStorage memory cell');
    }

    memoryCell.set(this.fromCSMode(mode), this.twoWeeksInSeconds);
  }

  remove(): void {
    const memoryCell = this.getMemoryCell();

    if (!memoryCell) {
      throw new Error('Cannot initialize LocalStorage memory cell');
    }

    memoryCell.clear();
  }

  private initBucket(): void {
    const userId = this.userApi.getCurrentUser().id;
    const bucketNameForUser = `cs-mode-${userId}`;

    this.memoryBucket =
      this.storageFactory.createStorageItemFactory<CustomerSuccessBucket>(bucketNameForUser);
  }

  private getMemoryCell(): StorageItem<StoredCSMode> | null {
    if (this.memoryBucket === null) {
      this.initBucket();
    }

    const fieldName: keyof CustomerSuccessBucket = 'mode';

    return this.memoryBucket ? this.memoryBucket(fieldName) : null;
  }

  private toCSMode(stored: StoredCSMode): CSMode {
    return new CSMode(stored.state, stored.id);
  }

  private fromCSMode(mode: CSMode): StoredCSMode {
    return { id: mode.facilityId, state: mode.status };
  }
}
