import { HttpClientApi } from '@/modules/http-client/public/api';
import { injectable } from 'tsyringe';
import type { OutboundAttendance } from '../../domain/overview-outbound-attendance/OutboundAttendance';
import type { OutboundAttendanceRepository } from '../../domain/overview-outbound-attendance/OutboundAttendanceRepository';
import { OutboundAttendanceFactory } from './OutboundAttendanceFactory';
import type { InputOutboundAttendance } from './InputOutboundAttendance';

@injectable()
export class HttpOutboundAttendanceRepository implements OutboundAttendanceRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private outboundAttendanceFactory: OutboundAttendanceFactory,
  ) {}

  async get(
    start: string,
    end: string,
    previousStart: string,
    previousEnd: string,
  ): Promise<OutboundAttendance> {
    const inputOutboundAttendance: InputOutboundAttendance = await this.httpClient.get(
      `/api/v0/facility/{facilityId}/stats/outbound-traffic-attendance`,
      {
        start,
        end,
        previousStart,
        previousEnd,
      },
    );

    return this.outboundAttendanceFactory.make(inputOutboundAttendance);
  }
}
