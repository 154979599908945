export class ComponentNotFoundError extends Error {
  constructor(apiComponentId: string) {
    super(`API component with ID '${apiComponentId}' was not found.`);

    // Changing prototype for extending default class is recommended by TS
    // https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, ComponentNotFoundError.prototype);

    this.name = 'ComponentNotFoundError';
  }
}
