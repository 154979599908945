/* eslint-disable no-underscore-dangle */
import { injectable } from 'tsyringe';
import type { HttpDoctor } from '@/modules/book-visit/adapters/doctor/HttpDoctor';
import { Doctor } from '@/modules/book-visit/domain/doctor/Doctor';

@injectable()
export class HttpDoctorFactory {
  make(doctor: HttpDoctor): Doctor {
    return new Doctor(
      doctor.id,
      doctor.full_name,
      doctor.prefix,
      doctor._embedded?.specializations?._items[0]?.name ?? '',
    );
  }
}
