import { inject, injectable } from 'tsyringe';
import type { DoctorWithAddress } from '@/modules/book-visit/domain/doctor/DoctorWithAddress';
import { AvailableForBookingDoctorsFilter } from '../doctor/AvailableForBookingDoctorsFilter';
import { UniqueServiceCollectionFactory } from '../service/UniqueServiceCollectionFactory';
import {
  DoctorWithAddressToken,
  PreloadedBookingOptionsRepositoryToken,
  ServiceRepositoryToken,
} from '../../di/token';
import type { DoctorWithAddressRepository } from '../doctor/DoctorWithAddressRepository';
import type { ServiceRepository } from '../service/ServiceRepository';
import type { PreloadedBookingOptionsRepository } from './PreloadedBookingOptionsRepository';
import { FacilityBookingOptions } from './FacilityBookingOptions';
import { BookingSlotList } from '../booking-slot/BookingSlotList';

@injectable()
export class BookingOptionsPreloader {
  constructor(
    @inject(DoctorWithAddressToken)
    private readonly doctorWithAddressRepository: DoctorWithAddressRepository,
    @inject(ServiceRepositoryToken)
    private readonly serviceRepository: ServiceRepository,
    @inject(PreloadedBookingOptionsRepositoryToken)
    private readonly bookingOptions: PreloadedBookingOptionsRepository,
    private readonly serviceFactory: UniqueServiceCollectionFactory,
  ) {}

  async preload(): Promise<void> {
    let doctorWithAddressesList: DoctorWithAddress[];

    try {
      doctorWithAddressesList = await this.doctorWithAddressRepository.get();
    } catch {
      this.bookingOptions.save(FacilityBookingOptions.makeErrored());
      return;
    }

    const availableDoctorList = AvailableForBookingDoctorsFilter.filter(doctorWithAddressesList);
    if (availableDoctorList.length === 0) {
      this.bookingOptions.save(FacilityBookingOptions.makeEmpty());
      return;
    }

    const [firstDoctor] = availableDoctorList;
    const services = this.serviceFactory.get(
      await this.serviceRepository.get(firstDoctor.doctor.id, firstDoctor.address.id),
    );

    const bookingOptions = new FacilityBookingOptions(
      availableDoctorList,
      services,
      BookingSlotList.makeEmpty(),
    );

    this.bookingOptions.save(bookingOptions);
  }
}
