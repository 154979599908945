import { injectable } from 'tsyringe';
import type { TaskGroup } from '@/modules/tasks-list/domain/task/TaskGroup';
import { TaskFeedFetcherFactory } from '@/modules/tasks-list/domain/task-feed/TaskFeedFetcherFactory';
import type { TaskFeed } from '@/modules/tasks-list/domain/task-feed/TaskFeed';

@injectable()
export class TaskList {
  constructor(private readonly taskFeedFetcherFactory: TaskFeedFetcherFactory) {}

  async getGroupPage(group: TaskGroup, cursor?: string): Promise<TaskFeed> {
    return this.taskFeedFetcherFactory.make(group).fetch(cursor);
  }
}
