import { schema } from 'normalizr';
import { EntityTypes } from '@/api/types/entities';
import { upcomingVisitOutputSchema } from './upcoming-visit-output-schema';

export const upcomingVisitSchema = new schema.Entity(
  EntityTypes.UpcomingVisit,
  {
    visit_output: upcomingVisitOutputSchema,
  },
  { idAttribute: 'phone_number' },
);
