import type { OutputDateNormalizer } from './OutputDateNormalizer';
import type { OutputFormatter, Translator } from './types';

export class TranslatedOutputDateNormalizer implements OutputDateNormalizer {
  private dateFormatWithoutYear = 'd MMM';

  private dateFormatWithYear = 'd MMM yyyy';

  private dateFullMonthFormatWithoutYear = 'd MMMM';

  private dateFullMonthFormatWithYear = 'd MMMM yyyy';

  private timeFormat = 'HH:mm';

  private weekdayFormat = 'EEEE';

  private shortWeekdayFormat = 'EEE';

  private monthFormat = 'MMM';

  private apiDateFormat = 'yyy-MM-dd';

  constructor(
    private readonly translator: Translator,
    private readonly outputFormatter: OutputFormatter,
    public readonly date: Date,
  ) {}

  private get dateFormat(): string {
    if (this.outputFormatter.isThisYear(this.date)) {
      return this.dateFormatWithoutYear;
    }

    return this.dateFormatWithYear;
  }

  private get dateWithFullMonthFormat(): string {
    if (this.outputFormatter.isThisYear(this.date)) {
      return this.dateFullMonthFormatWithoutYear;
    }

    return this.dateFullMonthFormatWithYear;
  }

  toDefaultDate(): string {
    return `${this.toDateOnly()}, ${this.toTimeOnly()}`;
  }

  toDateOnly(): string {
    if (this.outputFormatter.isToday(this.date)) {
      return `${this.translator.t('date.today')}`;
    }

    if (this.outputFormatter.isTomorrow(this.date)) {
      return `${this.translator.t('date.tomorrow')}`;
    }
    if (this.outputFormatter.isYesterday(this.date)) {
      return `${this.translator.t('date.yesterday')}`;
    }

    return this.outputFormatter.format(this.date, this.dateFormat);
  }

  toTimeOnly(): string {
    return this.outputFormatter.format(this.date, this.timeFormat);
  }

  toMonthOnly(): string {
    return this.outputFormatter.format(this.date, this.monthFormat);
  }

  toDateAndTimeWithWeekDayName(): string {
    return `${this.toWeekDayNameOnly()}, ${this.toDefaultDate()}`;
  }

  toApiDate(): string {
    return this.outputFormatter.format(this.date, this.apiDateFormat);
  }

  toTimestamp(): number {
    return this.outputFormatter.getUnixTime(this.date);
  }

  toDateWithWeekDayNameAndFullMonth(): string {
    return `${this.toShortWeekDayNameOnly()}, ${this.toDateWithFullMonth()}`;
  }

  private toWeekDayNameOnly(): string {
    return this.outputFormatter.format(this.date, this.weekdayFormat);
  }

  private toShortWeekDayNameOnly(): string {
    return this.outputFormatter.format(this.date, this.shortWeekdayFormat);
  }

  private toDateWithFullMonth() {
    return this.outputFormatter.format(this.date, this.dateWithFullMonthFormat);
  }
}
