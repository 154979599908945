import { injectable } from 'tsyringe';
import { EnvironmentAwareSender } from './EnvironmentAwareSender';
import type { CustomTrackingEvent } from './dto/CustomTrackingEvent';

@injectable()
export class CustomEventSender {
  constructor(private readonly sender: EnvironmentAwareSender) {}

  publishCustomEvent(customEvent: CustomTrackingEvent): void {
    this.sender.trackEvent(customEvent);
  }
}
