import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { ModalController } from '@/modules/task/domain/modal/ModalController';
import type { OpeningMutationPayload } from '@/modules/task/store/modal-controller';

interface Store {
  commit(key: 'task:modal-controller/OPEN_MODAL', payload: OpeningMutationPayload): void;
  commit(key: 'task:modal-controller/CLOSE_MODAL'): void;
  getters: {
    'task:modal-controller/isModalOpen'(taskId: string): boolean;
  };
}

@injectable()
export class StoreModalController implements ModalController {
  constructor(@inject(RootStoreToken) private readonly store: Store) {}

  close(): void {
    this.store.commit('task:modal-controller/CLOSE_MODAL');
  }

  open(taskId: string): void {
    this.store.commit('task:modal-controller/OPEN_MODAL', { taskId, focusTarget: null });
  }

  openToSetComment(taskId: string): void {
    this.store.commit('task:modal-controller/OPEN_MODAL', { taskId, focusTarget: 'comment' });
  }

  openToSetReminder(taskId: string): void {
    this.store.commit('task:modal-controller/OPEN_MODAL', { taskId, focusTarget: 'reminder' });
  }

  isOpen(taskId: string): boolean {
    return this.store.getters['task:modal-controller/isModalOpen'](taskId);
  }
}
