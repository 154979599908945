<template>
  <fieldset :class="$b()">
    <legend :class="$b('title')">
      {{ title }}
    </legend>
    <div :class="$b('fields', { stack })">
      <div v-for="option in options" :key="option.label" :class="$b('field')">
        <input
          :id="getId(option)"
          type="radio"
          :class="$b('input')"
          :name="slugifiedTitle"
          :model-value="option.value"
        />
        <label :class="$b('label', labelModifiers)" :for="getId(option)">{{ option.label }}</label>
      </div>
    </div>
  </fieldset>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

import { isIncludedIn, slugify } from '@/utils/functions';
import { SubjectSelectColors } from './SubjectSelect.types';
import type { SubjectSelectOption } from './SubjectSelect.types';

@Component({
  name: 'SubjectSelect',
})
export default class SubjectSelect extends Vue {
  @Prop({
    type: String,
    default: SubjectSelectColors.Light,
    validator: isIncludedIn(Object.values(SubjectSelectColors)),
  })
  readonly color: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly stack: boolean;

  @Prop({
    type: Array,
    required: true,
  })
  readonly options: SubjectSelectOption[];

  @Prop({ type: String, required: true })
  readonly title: string;

  get labelModifiers() {
    return {
      color: this.color,
    };
  }

  get slugifiedTitle(): string {
    return slugify(this.title);
  }

  getId(option: SubjectSelectOption): string {
    return option.id || slugify(option.label);
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

$subject-select-bg-colors: (
  color_danger: v.$danger-light,
  color_light: v.$gray-100,
  color_success: v.$success-light,
);

$field-margin: v.$spacer-sm;

.dp-subject-select {
  $this: &;

  padding: 0;
  margin: 0;
  border: none;

  &__title {
    margin-bottom: v.$spacer-md;
    font-size: v.$font-size-sm;
    font-weight: v.$font-weight-medium;
  }

  &__fields {
    display: flex;
    flex-wrap: wrap;
    margin: $field-margin * -1;

    &--stack {
      flex-direction: column;
    }
  }

  &__field {
    margin: $field-margin;
  }

  &__label {
    position: relative;
    display: block;
    padding: v.$spacer * 0.75;
    border-radius: v.$border-radius-sm;
    cursor: pointer;

    @each $type, $bg-color in $subject-select-bg-colors {
      &--#{$type} {
        background: $bg-color;
      }
    }
  }

  &__input {
    display: none;

    &:checked + label {
      @each $type, $bg-color in $subject-select-bg-colors {
        &#{$this}__label--#{$type} {
          box-shadow: 0 0 0 0.2rem darken($bg-color, 12%);
        }
      }
    }
  }
}
</style>
