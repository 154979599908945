import type { StoreMedia } from '@/modules/timeline/adapters/timeline/store/message/StoreMedia';
import { Media } from '@/modules/timeline/domain/message-composer/message/media/Media';
import { injectable } from 'tsyringe';
import { MediaProxy } from '@/modules/timeline/domain/MediaProxy';
import { TokenApi } from '@/modules/authentication/public/api/TokenApi';

@injectable()
export class StoreMediaFactory {
  constructor(private readonly tokenApi: TokenApi) {}

  makeManyWithProxy(storeMedias: StoreMedia[]): Media[] {
    const proxy = this.getProxy();

    return storeMedias.map(storeMedia => this.make(storeMedia, proxy));
  }

  makeMany(storeMedias: StoreMedia[]): Media[] {
    return storeMedias.map(storeMedia => this.make(storeMedia));
  }

  make(storeMedia: StoreMedia, proxy?: MediaProxy): Media {
    return new Media(
      storeMedia.id,
      storeMedia.content_type,
      storeMedia.name,
      storeMedia.url,
      proxy,
    );
  }

  private getProxy(): MediaProxy {
    return new MediaProxy(() => this.tokenApi.getAccessToken());
  }
}
