import { injectable } from 'tsyringe';
import type { EventRecord } from './EventRecord';
import type { Frequency } from './Frequency';
import { FrequencyFactory } from './FrequencyFactory';
import { FrequencyStorage } from './FrequencyStorage';

@injectable()
export class LastMinuteFrequencyFactory {
  constructor(
    private readonly frequencyStorage: FrequencyStorage,
    private readonly frequencyFactory: FrequencyFactory,
  ) {}

  get(): Frequency {
    const endOfMinute = Date.now();
    const startOfMinute = endOfMinute - 60 * 1000;

    const events = this.getEvents(startOfMinute, endOfMinute);
    return this.frequencyFactory.make(events, startOfMinute, endOfMinute);
  }

  private getEvents(startTimestamp: number, endTimestamp: number): EventRecord[] {
    const eventRecords = this.frequencyStorage.getRange(startTimestamp, endTimestamp);
    this.frequencyStorage.removeOlderThan(endTimestamp);
    return eventRecords;
  }
}
