import type { SID, UUID } from '@/api/types';
import { channelPhoneSchema } from '@/api/schemas/phone-schema';

export type ChannelPhoneId = UUID;

export class ChannelPhone {
  static schema = channelPhoneSchema;

  id: ChannelPhoneId;

  /** @deprecated */
  formatted_number: string;

  name: string;

  number: string;

  sid: SID;

  weight: number;

  priority: number;

  type: 'phone' | 'redirected';

  redirected_to: string | null;

  verified?: boolean;

  private constructor(data: ChannelPhone) {
    this.id = data.id;
    this.formatted_number = data.formatted_number;
    this.name = data.name;
    this.number = data.number;
    this.sid = data.sid;
    this.weight = data.weight;
    this.priority = data.priority;
    this.type = data.type;
    this.redirected_to = data.redirected_to ?? null;
    this.verified = data.verified ?? false;
  }

  get formattedNumber(): string {
    return this.formatted_number;
  }

  get redirectedTo(): string | null {
    return this.redirected_to;
  }

  static make(data: ChannelPhone): ChannelPhone {
    return new ChannelPhone(data);
  }
}
