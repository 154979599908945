import { injectable } from 'tsyringe';
import { StoreEntityEvent } from '../../domain/store/StoreEntityEvent';
import { StoreEntitiesEventPublisher } from '../../domain/store/StoreEntitiesPublisher';
import type { StoreMutationEvent } from './StoreMutationEvent';

interface SupportedMutationNames {
  singular: string[];
  batch: string[];
}

@injectable()
export class StoreEntityHandler {
  constructor(
    private readonly storeEntityEventPublisher: StoreEntitiesEventPublisher,
    private readonly supportedMutationNames: SupportedMutationNames,
  ) {}

  handle(storeEvent: StoreMutationEvent): void {
    if (this.supportedMutationNames.singular.includes(storeEvent.type)) {
      this.handleSingularMutation(storeEvent);
      return;
    }

    if (this.supportedMutationNames.batch.includes(storeEvent.type)) {
      this.handleBatchMutation(storeEvent);
    }
  }

  private handleSingularMutation(storeEvent: StoreMutationEvent): void {
    this.storeEntityEventPublisher.send(
      new StoreEntityEvent(storeEvent.payload.entity, [storeEvent.payload.key]),
    );
  }

  private handleBatchMutation(storeEvent: StoreMutationEvent): void {
    const updatedEntities = Object.keys(storeEvent.payload);

    updatedEntities.forEach(entityType => {
      const modifiedKeys = Object.keys(storeEvent.payload[entityType]);
      this.storeEntityEventPublisher.send(new StoreEntityEvent(entityType, modifiedKeys));
    });
  }
}
