import { injectable } from 'tsyringe';
import type { Cache, Milliseconds, CacheValue } from '../domain/Cache';

@injectable()
export class MemoryCache implements Cache {
  private static readonly millisecondsOf15Minutes = 15 * 60 * 1000;

  private readonly cache = new Map<string, CacheValue>();

  private defaultTtl: number = MemoryCache.millisecondsOf15Minutes;

  set<T>(key: string, value: T, customTtl?: Milliseconds): void {
    const ttl = customTtl ?? this.defaultTtl;
    const expiresAt = this.getExpiresAt(ttl);

    this.cache.set(key, {
      expiresAt,
      data: value,
    });
  }

  get<T>(key: string): T | null {
    const cachedValue = this.cache.get(key);

    if (!cachedValue) {
      return null;
    }

    const now = new Date().getTime();
    if (now > cachedValue.expiresAt) {
      this.delete(key);
      return null;
    }

    return cachedValue.data as T;
  }

  delete(key: string): void {
    this.cache.delete(key);
  }

  clear(): void {
    this.cache.clear();
  }

  changeDefaultTtl(newDefaultTtl: number): void {
    this.defaultTtl = newDefaultTtl;
  }

  private getExpiresAt(ttl: Milliseconds): Milliseconds {
    const now = new Date().getTime();
    return now + ttl;
  }
}
