import type {
  LanguageConfig,
  LanguageConfigProvider,
  LanguageConfigBasedOnDomain,
} from '@/common/root/domain/LanguageConfigProvider';
import {
  FacilityLocale,
  getLanguageBasedOnDomain,
  getLanguageConfig,
} from '@/utils/languageDetector';
import { IETFLanguage, Language, Locale } from '@/common/root/domain/LanguageConfigProvider';

export class PluginLanguageConfigProvider implements LanguageConfigProvider {
  get(): LanguageConfig {
    const languageConfig = getLanguageConfig();
    return {
      locale: Locale[languageConfig.locale],
      language: Language[languageConfig.language],
      ietfLanguage: IETFLanguage[languageConfig.ietfLanguage],
    };
  }

  getBasedOnDomain(): LanguageConfigBasedOnDomain {
    const languageConfig = getLanguageBasedOnDomain();
    return {
      locale: Locale[languageConfig.locale],
      facilityLocale: FacilityLocale[languageConfig.facilityLocale],
      baseUrl: languageConfig.baseUrl,
    };
  }
}
