<template>
  <i :class="iconClass">
    <!-- eslint-disable -->
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 7.27418V13.5592C0 14.0384 0.500246 14.3296 0.885494 14.0688L5.56596 10.9263C5.92246 10.6897 5.92246 10.1437 5.56596 9.90101L0.885494 6.76459C0.500246 6.50373 0 6.79492 0 7.27418Z"
      />
      <rect x="7.5" y="5.83333" width="2.08333" height="7.5" rx="1.04167" />
      <rect x="10.8333" y="2.5" width="2.08333" height="14.1667" rx="1.04167" />
      <rect x="14.1667" y="5.83333" width="2.08333" height="7.5" rx="1.04167" />
      <rect x="17.5" y="8.33333" width="2.08333" height="2.5" rx="1.04167" />
    </svg>

    <!-- eslint-enable -->
  </i>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import BaseIcon from '@dp-ui-kit/icons/vue/BaseIcon.vue';

export default defineComponent({
  name: 'VoiceMessageIcon',
  extends: BaseIcon as any,
  iconName: 'voice-message',
});
</script>
