import type { Call } from '@/modules/timeline/domain/call/Call';

export class CallCount {
  private constructor(
    readonly successfulCount: number,
    readonly unsuccessfulCount: number,
  ) {}

  static of(calls: Call[]): CallCount {
    return new CallCount(
      calls.filter(({ isSuccessful }) => isSuccessful).length,
      calls.filter(({ isUnsuccessful }) => isUnsuccessful).length,
    );
  }
}
