import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { HttpReminderWriteRepository } from '@/modules/task/adapters/reminder/HttpReminderWriteRepository';
import { StoreReminderReadRepository } from '@/modules/task/adapters/reminder/StoreReminderReadRepository';
import { ReminderReadRepositoryToken, ReminderWriteRepositoryToken } from '../tokens';

export class ReminderProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register(ReminderWriteRepositoryToken, HttpReminderWriteRepository);
    container.register(ReminderReadRepositoryToken, StoreReminderReadRepository);
  }
}
