import type { UUID, URL } from '@/types/commons';
import { schema } from 'normalizr';
import { EntityType } from './entity-type';

type MediaId = UUID;

export interface Media {
  id: MediaId;
  name: string;
  url: URL;
}

/**
 * @typedef {Object} Media
 * @property {MediaId} id
 * @property {String} name
 * @property {URL} url
 */
export const mediaSchema = new schema.Entity(EntityType.MEDIA, {});
