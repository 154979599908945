import { injectable } from 'tsyringe';
import type { HttpInterception } from '@/modules/http-client/domain/interception/HttpInterception';

@injectable()
export class HttpInterceptionManager {
  private interceptors: HttpInterception[] = [];

  addInterceptor(interceptor: HttpInterception): void {
    this.interceptors.push(interceptor);
  }

  getInterceptors(): HttpInterception[] {
    return this.interceptors;
  }

  clearInterceptors(): void {
    this.interceptors = [];
  }
}
