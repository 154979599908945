import type { ActionTree, MutationTree, GetterTree } from 'vuex';
import { phoneApi } from '@/api/phone-api';
import debounce from 'lodash-es/debounce';
import type { Suggestions } from '@/api/types/response/search-suggestion';
import { getId } from '@/app/get-id';
import type { DateString } from '@/types/commons';
import type { TaskId } from '@/api/models/Task';
import type { SearchCriteria } from '@/app/search/SearchCriteria';

const MIN_SEARCH_PHRASE_LENGTH = 1;
const SUGGESTION_DEBOUNCE_TIME = 200;

interface SearchResult {
  result: Set<TaskId>;
  cursor: DateString;
}

const initialSuggestions: Suggestions = {
  patient: [],
  personnel: [],
  result: [],
  phone: [],
  form: [],
  tasks: [],
};

interface SearchState {
  suggestions: Suggestions;
  textQueries: string[];
  searchCriteria: Partial<SearchCriteria>;
  searchCriteriaId: number;
  searchResult: Record<number, SearchResult>;
  suggestionsLoading: boolean;
}

const state: SearchState = (() => {
  const searchCriteriaId: number = getId();
  return {
    suggestions: initialSuggestions,
    textQueries: [],
    searchCriteria: {},
    searchCriteriaId,
    searchResult: {},
    suggestionsLoading: false,
  };
})();

const getters: GetterTree<SearchState, unknown> = {
  getSuggestions: state => state.suggestions,
  getTextQueries: state => state.textQueries,
  getSuggestionLoading: state => state.suggestionsLoading,
  getSearchCriteria: state => state.searchCriteria,
  getSearchCriteriaId: state => state.searchCriteriaId,
  getSearchResult: state => searchCriteriaId =>
    state.searchResult[searchCriteriaId]?.result ?? new Set(),
  getSearchCursor: state => searchCriteriaId =>
    state.searchResult[searchCriteriaId]?.cursor ?? null,
};

const mutations: MutationTree<SearchState> = {
  SET_SUGGESTION_LOADING(state: SearchState, payload: boolean) {
    state.suggestionsLoading = payload;
  },

  SET_SUGGESTIONS(state: SearchState, payload: Suggestions) {
    state.suggestions = payload;
  },

  RESET_SUGGESTIONS(state: SearchState) {
    state.suggestions = initialSuggestions;
  },

  SET_TEXT_QUERIES(state: SearchState, payload: string) {
    state.textQueries = [payload];
  },

  RESET_TEXT_QUERIES(state: SearchState) {
    state.textQueries = [];
  },

  SET_SEARCH_CRITERIA(state, payload: SearchCriteria) {
    state.searchCriteriaId = getId();
    state.searchCriteria = payload;
  },

  RESET_SEARCH_CRITERIA(state) {
    state.searchCriteriaId = getId();
    state.searchCriteria = {};
  },

  SET_SEARCH_RESULT(state, { searchCriteriaId, result, cursor }) {
    const currentResult = state.searchResult[searchCriteriaId]?.result ?? new Set();

    state.searchResult[searchCriteriaId] = {
      result: new Set([...currentResult, ...result]),
      cursor,
    };
  },
};

const actions: ActionTree<SearchState, unknown> = {
  RESET_SUGGESTIONS_ACTION({ commit }) {
    commit('RESET_SUGGESTIONS');
  },

  UPDATE_SUGGESTIONS_ACTION: debounce(async ({ rootGetters, commit }, phrase) => {
    commit('SET_SUGGESTION_LOADING', true);

    commit('SET_TEXT_QUERIES', phrase);

    if (!phrase || phrase.length < MIN_SEARCH_PHRASE_LENGTH) {
      commit('RESET_SUGGESTIONS');

      commit('RESET_TEXT_QUERIES');

      commit('SET_SUGGESTION_LOADING', false);
      return;
    }

    try {
      const response = await phoneApi
        .withFacility(rootGetters.getUserFacilityID)
        .search.suggest(phrase);

      commit('SET_SUGGESTIONS', response);
    } finally {
      commit('SET_SUGGESTION_LOADING', false);
    }
  }, SUGGESTION_DEBOUNCE_TIME),

  SET_SEARCH_CRITERIA_ACTION({ getters, commit }, searchCriteria) {
    const currentCriteria = getters.getSearchCriteria;

    commit('SET_SEARCH_CRITERIA', {
      ...currentCriteria,
      ...searchCriteria,
    });
  },

  RESET_SEARCH_CRITERIA_ACTION({ commit }) {
    commit('RESET_SEARCH_CRITERIA');
  },

  async SET_SEARCH_RESULT_ACTION({ commit }, payload) {
    commit('SET_SEARCH_RESULT', payload);
  },
};

export const search = {
  state,
  getters,
  mutations,
  actions,
};
