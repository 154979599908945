import { inject, injectable } from 'tsyringe';
import { MUTATION_ENTITIES_UPDATE } from '@dp-vue/entities';
import { RootStoreToken } from '@/common/root';
import { HttpClientApi } from '@/modules/http-client/public/api';
import { taskMapper } from '@/api/mappers';
import type { TimelineRepository } from '@/modules/timeline/domain/timeline/TimelineRepository';
import type { Timeline } from '@/modules/timeline/domain/timeline/Timeline';
import type { Store } from '@/modules/timeline/adapters/timeline/store/Store';
import { StoreTimelineRepository } from '@/modules/timeline/adapters/timeline/store/StoreTimelineRepository';
import { Task } from '@/api/models/Task';

@injectable()
export class HttpTimelineRepository implements TimelineRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    @inject(RootStoreToken)
    private readonly store: Store,
    private readonly storeTimelineRepository: StoreTimelineRepository,
  ) {}

  async find(taskId: string): Promise<Timeline | null> {
    const task = await this.httpClient.get(`/api/v0/task/${taskId}`);

    const { entities } = taskMapper.normalizeSingle(task as Task);

    this.store.commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });

    return this.storeTimelineRepository.find(taskId);
  }

  subscribeUpdates(): () => void {
    throw new Error('Method not implemented.');
  }

  findSync(): Timeline | null {
    throw new Error('Method not implemented.');
  }
}
