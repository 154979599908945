import type { Device } from '@twilio/voice-sdk';
import type { CallingDevice } from '../../../domain/webphone/device/CallingDevice';
import type { ConnectOptions } from '../../../domain/webphone/device/ConnectOptions';
import type { InputCall } from '../../../domain/webphone/models/dto/InputCall';

declare global {
  interface Window {
    devices: any;
  }
}

export class TwilioCallingDevice implements CallingDevice {
  constructor(private readonly device: Device) {}

  get state(): 'registered' | 'registering' | 'unregistered' | 'destroyed' {
    return this.device.state;
  }

  get isBusy(): boolean {
    return this.device.isBusy;
  }

  addListener(eventName: string, listener: (...args: any[]) => void): void {
    this.device.addListener(eventName, listener);
  }

  removeListener(eventName: string, listener: (...args: any[]) => void): void {
    this.device.removeListener(eventName, listener);
  }

  async register(): Promise<void> {
    await this.device.register();
  }

  updateToken(token: string): void {
    this.device.updateToken(token);
  }

  async connect(options: ConnectOptions): Promise<InputCall> {
    return this.device.connect(options);
  }

  destroy(): void {
    this.device.destroy();
  }
}
