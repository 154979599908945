import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { Authorization } from '@/common/authorization/public/api';

export const UserToken = Symbol('User');

@injectable()
export class AuthorizationProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register(UserToken, Authorization);
  }
}
