<template>
  <div :class="$b()">
    <div :class="$b('icon')">
      <slot name="icon" />
    </div>
    <div :class="$b('content-layout')">
      <slot name="content">
        <div :class="$b('content')">
          <slot />
        </div>
        <slot name="button" />
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator';

@Component({
  name: 'TaskTileEditTemplate',
})
export default class TaskTileEditTemplate extends Vue {}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables' as v;

.dp-task-tile-edit-template {
  display: grid;
  margin-top: v.$spacer-md * 0.75;
  grid-template-columns: repeat(12, 1fr);

  &__icon {
    margin: v.$spacer-md * 0.75 v.$spacer-md * 1.25 auto auto;
    grid-column: 1;
  }

  &__content-layout {
    grid-column: 2 / span 11;
  }

  &__content {
    border: 1px solid v.$gray-200;
    border-radius: v.$border-radius-lg;
  }
}
</style>
