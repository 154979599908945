import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { VoicemailRepository } from '../../domain/voicemail/VoicemailRepository';
import type { VoicemailSettingsInput } from '../../domain/voicemail/VoicemailSettings';
import { VoicemailSettings } from '../../domain/voicemail/VoicemailSettings';

@injectable()
export class HttpVoicemailRepository implements VoicemailRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async get(): Promise<VoicemailSettings> {
    const settings: VoicemailSettingsInput = await this.httpClient.get(
      `/api/v0/facility/{facilityId}/voicemail-settings`,
    );

    return new VoicemailSettings(settings);
  }

  async setAfterHoursStatus(newStatus: boolean): Promise<VoicemailSettings> {
    const settings: VoicemailSettingsInput = await this.httpClient.post(
      `/api/v0/facility/{facilityId}/voicemail-settings`,
      {
        enabledAfterHours: newStatus,
      },
    );

    return new VoicemailSettings(settings);
  }

  async setInQueueStatus(newStatus: boolean): Promise<VoicemailSettings> {
    const settings: VoicemailSettingsInput = await this.httpClient.post(
      `/api/v0/facility/{facilityId}/voicemail-settings`,
      {
        enabledInQueue: newStatus,
      },
    );

    return new VoicemailSettings(settings);
  }
}
