import type { Module } from 'vuex';

export interface State {
  isVisibleCallQualityModal: boolean;
  callId: string;
}

const state: () => State = () => ({
  isVisibleCallQualityModal: false,
  callId: '',
});

export const module: Module<State, unknown> = {
  namespaced: true,
  state,
};

export const moduleName = 'timeline:call-quality' as const;
