import { injectable } from 'tsyringe';
import { WhatsAppMessage } from '@/modules/task/domain/message/WhatsAppMessage';
import { OwnerId } from '@/modules/task/domain/owner/OwnerId';
import type { TimelineWhatsAppMessage, TimelineMedia } from '@/modules/timeline/public/types';
import { TimelineSmsMessage } from '@/modules/timeline/public/types';
import { TimelineDpChatMessage } from '@/modules/timeline/public/api/data-transfer/message/TimelineDpChatMessage';
import { SmsMessage } from '@/modules/task/domain/message/SmsMessage';
import { DpChatMessage } from '@/modules/task/domain/message/DpChatMessage';

@injectable()
export class MessageFactory {
  make(
    data: TimelineWhatsAppMessage | TimelineSmsMessage | TimelineDpChatMessage,
  ): WhatsAppMessage | SmsMessage | DpChatMessage {
    if (data instanceof TimelineSmsMessage) {
      return new SmsMessage(
        data.id,
        data.direction,
        data.createdAt,
        data.body,
        data.ownerId ? new OwnerId(data.ownerId) : OwnerId.empty,
      );
    }

    if (data instanceof TimelineDpChatMessage) {
      return new DpChatMessage(
        data.id,
        data.direction,
        data.createdAt,
        data.body,
        data.ownerId ? new OwnerId(data.ownerId) : OwnerId.empty,
        this.mapMedia(data.media[0]),
      );
    }

    return new WhatsAppMessage(
      data.id,
      data.status,
      data.direction,
      data.createdAt,
      data.body,
      data.ownerId ? new OwnerId(data.ownerId) : OwnerId.empty,
      this.mapMedia(data.media[0]),
    );
  }

  private mapMedia(media?: TimelineMedia): WhatsAppMessage['mediaType'] {
    if (media?.contentType === 'application/pdf') {
      return 'pdf';
    }

    if (media?.contentType.startsWith('image')) {
      return 'image';
    }

    if (media?.contentType.startsWith('audio')) {
      return 'audio';
    }

    if (media?.contentType.startsWith('video')) {
      return 'video';
    }

    return null;
  }
}
