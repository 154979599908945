import type { EditablePatient } from './dto/EditablePatient';
import type { SearchablePatient } from './dto/SearchablePatient';

export class Patient implements SearchablePatient, EditablePatient {
  readonly type = 'patient';

  constructor(
    readonly phoneNumber: string,
    readonly formattedNumber: string,
    readonly name: string,
    readonly surname: string,
    readonly fullName: string,
    readonly priorityStar: boolean,
  ) {}
}
