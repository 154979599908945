import { WorkingHourList } from '@/modules/settings/domain/working-hours/regular-schedule/WorkingHourList';
import { WorkingHour } from '@/modules/settings/domain/working-hours/WorkingHour';
import type { InputStoreWorkingHour } from './types/InputStoreWorkingHour';
import type { InputHttpWorkingHour } from './types/InputHttpWorkingHour';
import type { OutputHttpWorkingHour } from './types/OutputHttpWorkingHour';

export class WorkingHourListFactory {
  fromStore(inputWorkingHours?: InputStoreWorkingHour[]): WorkingHourList | null {
    if (!inputWorkingHours) {
      return null;
    }

    const workingHours = inputWorkingHours.map(
      ({ open, morningStart, morningEnd, afternoonStart, afternoonEnd }) =>
        WorkingHour.make(open, morningStart, afternoonEnd, morningEnd, afternoonStart),
    );

    return WorkingHourList.make(workingHours);
  }

  toStore(workingHourList: WorkingHourList): InputStoreWorkingHour[] {
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] as const;

    return workingHourList.workingHours.map(
      ({ open, start, end, siestaStart, siestaEnd }: WorkingHour, index) => ({
        open,
        name: days[index],
        morningStart: start,
        morningEnd: siestaEnd,
        afternoonStart: siestaStart,
        afternoonEnd: end,
      }),
    );
  }

  fromHttp(inputWorkingHours: InputHttpWorkingHour[]): WorkingHourList {
    const workingHours = inputWorkingHours.map((inputWorkingHour: InputHttpWorkingHour) => {
      const { open, start_time, end_time } = inputWorkingHour;
      const siesta_start_time =
        'siesta_start_time' in inputWorkingHour ? inputWorkingHour.siesta_start_time : null;
      const siesta_end_time =
        'siesta_end_time' in inputWorkingHour ? inputWorkingHour.siesta_end_time : null;

      return WorkingHour.make(open, start_time, end_time, siesta_start_time, siesta_end_time);
    });

    return WorkingHourList.make(workingHours);
  }

  toHttp(workingHourList: WorkingHourList): OutputHttpWorkingHour[] {
    return workingHourList.workingHours.map(
      ({ open, start, end, siestaStart, siestaEnd }: WorkingHour) => ({
        open,
        start,
        end,
        siesta_start: siestaStart,
        siesta_end: siestaEnd,
      }),
    );
  }
}
