import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import type { CallQualityModalController } from '@/modules/task/domain/call-quality/CallQualityModalController';
import { CallQualityModalControllerToken } from '@/modules/task/di/tokens';
import { TimelineCallQualityModalController } from '@/modules/task/adapters/call-quality/TimelineCallQualityModalController';

export class CallQualityProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<CallQualityModalController>(
      CallQualityModalControllerToken,
      TimelineCallQualityModalController,
    );
  }
}
