import { inject, injectable } from 'tsyringe';
import { VoiceMenuConfigurationOptionsList } from '@/modules/settings/domain/traffic-control/aggregates/VoiceMenuConfigurationOptionsList';
import { VoiceMenuConfigurationOption } from '@/modules/settings/domain/traffic-control/aggregates/VoiceMenuConfigurationOption';
import type { Digit } from '@/modules/settings/domain/traffic-control/models/Digit';
import { Fallback } from '@/modules/settings/domain/traffic-control/models/Fallback';
import { FallbackMode } from '@/modules/settings/domain/traffic-control/models/FallbackMode';
import { CallingGroup } from '@/modules/settings/domain/traffic-control/aggregates/CallingGroup';
import type { WorkstationRepository } from '@/modules/settings/domain/traffic-control/WorkstationRepository';
import { WorkstationRepositoryToken } from '@/modules/settings/di/token';
import { Label } from '@/modules/settings/domain/traffic-control/models/Label';
import type {
  InputVoiceMenuOption,
  InputVoiceMenuOptionGroup,
  InputVoiceMenuOptionLabel,
  InputVoiceMenuOptionVoiceMessage,
} from '../types/InputVoiceMenuOption';
import { VoiceMessageFactory } from './VoiceMessageFactory';

@injectable()
export class ConfigurationOptionsFactory {
  constructor(
    private readonly voiceMessageFactory: VoiceMessageFactory,
    @inject(WorkstationRepositoryToken)
    private readonly workstationRepository: WorkstationRepository,
  ) {}

  make(inputOptions: InputVoiceMenuOption[]): VoiceMenuConfigurationOptionsList {
    const options: VoiceMenuConfigurationOption[] = inputOptions
      .map(inputOption => {
        if (inputOption.option_type === 'label') {
          return this.makeCallOneByOne(inputOption);
        }

        if (inputOption.option_type === 'workstation') {
          return this.makeCallToSelectedGroup(inputOption);
        }

        if (inputOption.option_type === 'voice_message') {
          return this.makeVoiceMessage(inputOption);
        }

        return null;
      })
      .filter((option): option is VoiceMenuConfigurationOption => !!option);

    return new VoiceMenuConfigurationOptionsList(options.sort(this.byDigit));
  }

  private makeCallOneByOne(inputOption: InputVoiceMenuOptionLabel): VoiceMenuConfigurationOption {
    if (!this.isDigit(inputOption.digit)) {
      throw new Error('Voice menu returned invalid digit for the voice menu');
    }

    return new VoiceMenuConfigurationOption(
      inputOption.id,
      inputOption.digit,
      this.getLabel(inputOption),
      'label',
    );
  }

  private makeCallToSelectedGroup(
    inputOption: InputVoiceMenuOptionGroup,
  ): VoiceMenuConfigurationOption {
    if (!this.isDigit(inputOption.digit)) {
      throw new Error('Voice menu returned invalid digit for the voice menu');
    }

    return new VoiceMenuConfigurationOption(
      inputOption.id,
      inputOption.digit,
      this.getLabel(inputOption),
      'calling-group',
      this.getCallingGroup(
        inputOption.destinations ?? [],
        inputOption.redirect_to_reception_after_timeout,
        inputOption.timeout,
      ),
    );
  }

  private makeVoiceMessage(
    inputOption: InputVoiceMenuOptionVoiceMessage,
  ): VoiceMenuConfigurationOption {
    if (!this.isDigit(inputOption.digit)) {
      throw new Error('Voice menu returned invalid digit for the voice menu');
    }

    return new VoiceMenuConfigurationOption(
      inputOption.id,
      inputOption.digit,
      this.getLabel(inputOption),
      'voice-message',
      undefined,
      this.voiceMessageFactory.makeFromInput(inputOption.voice_message),
    );
  }

  private isDigit(digit: string): digit is Digit {
    return ['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(digit);
  }

  private getLabel(inputOption: InputVoiceMenuOption): Label {
    return new Label(inputOption.name, !!inputOption.priority);
  }

  private byDigit(alpha: { digit: Digit }, bravo: { digit: Digit }): number {
    return parseInt(alpha.digit, 10) - parseInt(bravo.digit, 10);
  }

  private getCallingGroup(
    workstationIds: string[],
    isRedirectingToAll: boolean,
    redirectionTimeout: number,
  ): CallingGroup {
    const workstations = this.workstationRepository.get(workstationIds);

    return new CallingGroup(
      workstations,
      new Fallback(
        isRedirectingToAll
          ? FallbackMode.RedirectToAllReceptionWorkstations
          : FallbackMode.NoRedirection,
        redirectionTimeout,
      ),
    );
  }
}
