<template>
  <i :class="iconClass">
    <!-- eslint-disable max-len vue/max-attributes-per-line -->
    <svg width="16" height="17" viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.66612 0.255615H12.3328C13.0661 0.255615 13.6661 0.855615 13.6661 1.58895V5.79272H9.20573C8.13516 5.79272 7.26729 6.66059 7.26729 7.73116C7.26729 8.80173 8.13516 9.6696 9.20573 9.6696H9.88588L8.63319 10.9223H2.99945L1.57948 13.4072C1.24022 14.001 0.333659 13.76 0.333984 13.0762L0.339455 1.58895C0.339455 0.855615 0.932788 0.255615 1.66612 0.255615ZM4.96339 8.869L3.30885 7.80484C4.56153 5.46333 6.90227 3.79588 9.65346 3.48521L10.1143 4.90472C7.84664 5.35139 5.95423 6.84823 4.96339 8.869ZM5.32446 4.04967L5.53359 1.56372H7.01985L7.20867 3.81188C6.35591 4.10435 5.8544 4.33799 5.27979 4.69123C4.8556 4.95135 4.46982 5.25577 4.12465 5.60487L2.44153 4.89433L2.90032 3.47888L5.32446 4.04967ZM15.5029 7.73116C15.5029 7.21288 15.0827 6.79272 14.5645 6.79272H9.20573C8.68744 6.79272 8.26729 7.21288 8.26729 7.73116C8.26729 8.24944 8.68744 8.6696 9.20573 8.6696H12.3001L6.55282 14.4169C6.18633 14.7834 6.18633 15.3775 6.55282 15.744C6.9193 16.1105 7.51348 16.1105 7.87996 15.744L13.6258 9.99817V13.0899C13.6258 13.6082 14.046 14.0283 14.5642 14.0283C15.0825 14.0283 15.5027 13.6082 15.5027 13.0899V7.75093L15.5029 7.73116Z"
      />
    </svg>
    <!-- eslint-enable max-len vue/max-attributes-per-line -->
  </i>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import BaseIcon from '@dp-ui-kit/icons/vue/BaseIcon.vue';

export default defineComponent({
  name: 'DpChatOutbound',
  extends: BaseIcon,
  iconName: 'dp-chat-outbound',
});
</script>
