import { injectable } from 'tsyringe';
import { DpChatMessage } from '@/modules/timeline/domain/message-composer/message/DpChatMessage';
import { IsoDate } from '@/modules/timeline/domain/IsoDate';
import { Media } from '@/modules/timeline/domain/message-composer/message/media/Media';
import { UserOwnerRepository } from '../owner/UserOwnerRepository';
import type { StoreMessage } from './StoreMessage';
import { StoreMediaRepository } from './StoreMediaRepository';

@injectable()
export class StoreDpChatMessageFactory {
  constructor(
    private readonly ownerRepository: UserOwnerRepository,
    private readonly mediaRepository: StoreMediaRepository,
  ) {}

  makeMany(messages: StoreMessage[], timezone: string): DpChatMessage[] {
    return messages.map(message => this.make(message, timezone));
  }

  private make(message: StoreMessage, timezone: string): DpChatMessage {
    return new DpChatMessage(
      message.id,
      IsoDate.make(message.created_at, timezone),
      this.mapDirection(message.type),
      message.body,
      message.owner ? this.ownerRepository.find(message.owner) : null,
      this.getMedia(message),
    );
  }

  private mapDirection(direction: StoreMessage['type']): DpChatMessage['direction'] {
    if (direction === 'outbound-template') {
      return 'outbound';
    }

    return direction;
  }

  private getMedia(message: StoreMessage): Media[] {
    if (!message.media) {
      return [];
    }

    return message.type === 'inbound'
      ? this.mediaRepository.getListWithProxy(message.media)
      : this.mediaRepository.getList(message.media);
  }
}
