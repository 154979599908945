export class Subject {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly type: 'visit' | 'lost-reason' | 'informational' | '',
  ) {}

  get isEmpty(): boolean {
    return this.id === '';
  }

  get isVisit(): boolean {
    return this.type === 'visit';
  }

  get isLost(): boolean {
    return this.type === 'lost-reason';
  }

  get isInformational(): boolean {
    return this.type === 'informational';
  }

  static get empty(): Subject {
    return new Subject('', '', '');
  }
}
