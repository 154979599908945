import { inject, injectable } from 'tsyringe';
import {
  FacilityIntegrationRepositoryToken,
  UpcomingVisitRepositoryToken,
  VisitConfirmationReadRepositoryToken,
  VisitConfirmationWriteRepositoryToken,
} from '../../di/token';
import type { VisitConfirmationReadRepository } from '../../domain/visit/VisitConfirmationReadRepository';
import type { VisitConfirmationDto } from './VisitConfirmationDto';
import type { VisitConfirmation } from '../../domain/visit/VisitConfirmation';
import type { VisitConfirmationWriteRepository } from '../../domain/visit/VisitConfirmationWriteRepository';
import type { FacilityIntegrationRepository } from '../../domain/facility/FacilityIntegrationRepository';
import type { UpcomingVisitDto } from './UpcomingVisitDto';
import type { UpcomingVisitRepository } from '../../domain/visit/UpcomingVisitRepository';

@injectable()
export class BookVisitApi {
  constructor(
    @inject(VisitConfirmationReadRepositoryToken)
    private readonly visitConfirmationRepository: VisitConfirmationReadRepository,
    @inject(VisitConfirmationWriteRepositoryToken)
    private readonly visitConfirmationWriteRepository: VisitConfirmationWriteRepository,
    @inject(FacilityIntegrationRepositoryToken)
    private readonly facilityIntegrationRepository: FacilityIntegrationRepository,
    @inject(UpcomingVisitRepositoryToken)
    private readonly upcomingVisitRepository: UpcomingVisitRepository,
  ) {}

  hasSsoIntegration(): boolean {
    return this.facilityIntegrationRepository.get().isSsoIntegrated;
  }

  hasVisitToConfirm(taskId: string): boolean {
    return !!this.visitConfirmationRepository.find(taskId);
  }

  findVisitToConfirm(taskId: string): VisitConfirmationDto | null {
    const visitConfirmation = this.visitConfirmationRepository.find(taskId);

    if (!visitConfirmation) {
      return null;
    }

    return {
      status: visitConfirmation.status,
      startAt: visitConfirmation.startAt.toISOString(),
      serviceName: visitConfirmation.serviceName,
      doctorName: visitConfirmation.doctorName,
      patientDetailUrl: visitConfirmation.patientDetailUrl,
    };
  }

  findUpcomingVisit(phoneNumber: string): UpcomingVisitDto | null {
    const upcomingVisit = this.upcomingVisitRepository.findByPatientPhoneNumber(phoneNumber);

    if (!upcomingVisit) {
      return null;
    }

    return {
      status: upcomingVisit.status,
      doctorName: upcomingVisit.doctorName,
      serviceName: upcomingVisit.serviceName,
      startAt: upcomingVisit.startAt.toISOString(),
    };
  }

  updateVisitConfirmationStatus(
    taskId: string,
    status: VisitConfirmation['status'],
  ): Promise<void> {
    return this.visitConfirmationWriteRepository.setVisitStatus(taskId, status);
  }
}
