import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import type { CSModeRepository } from '../../domain/customer-success-mode/CSModeRepository';
import { CSModeRepositoryToken } from '../token';
import { LocalStorageCSModeRepository } from '../../adapters/customer-success-mode/LocalStorageCSModeRepository';

export class CustomerSuccessModeProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<CSModeRepository>(CSModeRepositoryToken, LocalStorageCSModeRepository);
  }
}
