import { StateFetcherToken } from '@/modules/phone-widget/di/tokens';
import { inject, injectable } from 'tsyringe';
import type { DeviceState } from '../models/DeviceState';
import type { StateFetcher } from './StateFetcher';

@injectable()
export class DeviceStateRepository {
  constructor(
    @inject(StateFetcherToken)
    private readonly stateFetcher: StateFetcher,
  ) {}

  get(): DeviceState {
    return this.stateFetcher.deviceState;
  }

  set(newState: DeviceState): void {
    this.stateFetcher.deviceState = newState;
  }
}
