<template>
  <div
    :class="$b(blockModifiers)"
    :tabindex="isClickable ? '0' : '-1'"
    @keyup.enter="onEnterKey"
    @click.stop="onClick"
  >
    <div :class="$b('body')">
      <div :class="$b('icon')">
        <slot name="icon" />
      </div>
      <div :class="$b('inner')">
        <div :class="$b('header')">
          <div :class="$b('header-text')">
            <div :class="$b('title')">
              <slot name="title" />
            </div>
            <div :class="$b('subtitle')">
              <slot name="subtitle" />
            </div>
          </div>
          <div :class="$b('actions')">
            <slot name="actions" />
          </div>
        </div>
        <slot name="visible-content" />
        <div v-if="isExpanded" :class="$b('panel')" @click.stop>
          <slot name="hidden-content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Model } from 'vue-facing-decorator';

@Component({
  name: 'TaskTileExpandable',
})
export default class TaskTileExpandable extends Vue {
  @Prop({ type: Boolean, default: true })
  readonly hasToggle: boolean;

  @Model({ type: Boolean, default: false })
  isExpanded: boolean;

  get isClickable(): boolean {
    return this.hasToggle && !!this.$slots['hidden-content'];
  }

  get blockModifiers() {
    return {
      clickable: this.isClickable,
      open: this.isExpanded,
    };
  }

  toggleContent(): void {
    if (this.isExpanded) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  expand(): void {
    this.isExpanded = true;
    this.$emit('expand');
  }

  collapse(): void {
    this.isExpanded = false;
    this.$emit('collapse');
  }

  onEnterKey(event: Event): void {
    if (!this.isClickable) {
      return;
    }

    const target = event.target as HTMLElement;

    if (target.classList.contains('task-tile')) {
      this.toggleContent();
    }
  }

  onClick(): void {
    if (!this.isClickable) {
      return;
    }

    this.toggleContent();
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;
@use 'src/assets/scss/mixins' as m;

.dp-task-tile-expandable {
  $this: &;

  position: relative;
  max-width: v.$max-width-call-tile;
  margin: 0 auto;
  box-shadow: 0 0 0 1px v.$gray-300;
  border-radius: v.$border-radius;
  background: v.$white;

  &--clickable {
    transition: 0.3s ease;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 0 1px v.$primary;
    }
  }

  &--open,
  &--open:hover {
    @include m.z-index(tile-edit);

    max-width: 100%;
    box-shadow: 0 0 0 2px v.$primary;
  }

  &__body {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: v.$spacer-lg;
    max-width: v.$max-width-call-tile;
    padding: v.$spacer-md v.$spacer-lg;
    margin: 0 auto;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: v.$spacer-lg;
    padding-bottom: v.$spacer-sm;
  }

  &__title,
  &__subtitle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__panel {
    padding-top: v.$spacer-md;
  }
}
</style>
