<template>
  <div :class="$b()">
    <img :src="src" :alt="src" :class="$b('thumbnail')" @click.stop="openPreview" />
    <overlay :is-expanded="isExpanded" @click.stop="closePreview" @close="closePreview">
      <template #buttons>
        <a :class="$b('download-button')" :href="src" download @click.stop>
          <dp-icon-download color="white" :size="20" />
        </a>
      </template>
      <img :src="src" :alt="src" :class="$b('preview')" @load="$emit('media-loaded')" />
    </overlay>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { DpIconDownload } from '@dp-ui-kit/icons';

import Overlay from '@/ui/molecules/Overlay/Overlay.vue';

@Component({
  name: 'ImagePreview',
  emits: ['media-loaded'],
  components: { Overlay, DpIconDownload },
})
export default class ImagePreview extends Vue {
  isExpanded = false;

  @Prop({ type: String, required: true })
  readonly src: string;

  openPreview() {
    this.isExpanded = true;
  }

  closePreview() {
    this.isExpanded = false;
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables' as v;

$button-size: 2.5rem;

.dp-image-preview {
  display: block;
  cursor: pointer;

  &__thumbnail {
    border-radius: v.$border-radius;
    object-fit: scale-down;
    width: 100%;
  }

  &__preview {
    border-radius: v.$border-radius;
    object-fit: scale-down;
    width: 80%;
    height: 80%;
  }

  &__download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $button-size;
    height: $button-size;
    border-radius: 50%;
    background: v.$gray-500;
    margin-right: v.$spacer-md;
  }
}
</style>
