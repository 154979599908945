<template>
  <div :class="$b('container')">
    <div ref="chat" :class="$b('chat')" @scroll="$emit('scroll', $event)">
      <slot name="chat" />
    </div>
    <div :class="$b('input', { shadowed: hasShadow })">
      <slot name="input" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Ref, Prop } from 'vue-facing-decorator';

@Component({
  name: 'ChatLayout',
  emits: ['scroll'],
})
export default class ChatLayout extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly hasShadow: boolean;

  @Ref('chat')
  readonly chat: HTMLDivElement;

  public getScrollContainer(): HTMLDivElement {
    return this.chat;
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-chat-layout {
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__chat {
    flex-grow: 1;
    overflow: auto;
    padding: v.$spacer-md v.$spacer-md 0 v.$spacer-md;
  }

  &__input {
    padding: v.$spacer-md v.$spacer-md 0 v.$spacer-md;
    background: v.$white;
    transition: all 0.3s;

    &--shadowed {
      box-shadow: 0 -13px 22px rgba(0, 0, 0, 0.06);
    }
  }
}
</style>
