import type { Component } from '@/app/app-status/component/Component';
import { ComponentStatus } from '@/app/app-status/component/Component';
import type { ComponentFetcher } from '@/app/app-status/component/ComponentFetcher';
import { NetworkError } from '@/app/app-status/errors/NetworkError';
import { ComponentNotFoundError } from '@/app/app-status/errors/ComponentNotFoundError';

const MINUTE = 1000 * 60;

export type AppStatusChangeCallback = (ComponentStatus) => void;

/**
 * This class is responsible for checking on API component status and performing callback on status change.
 */
export class AppStatusSupervisor {
  private status: ComponentStatus = ComponentStatus.Operational;

  constructor(
    private readonly componentFetcher: ComponentFetcher,
    private readonly statusChangeCallback: AppStatusChangeCallback,
    private readonly checkIntervalRate = MINUTE,
  ) {}

  async checkAndWatch(): Promise<void> {
    await this.checkAppStatus();

    this.watch();
  }

  watch(): void {
    setInterval(this.checkAppStatus.bind(this), this.checkIntervalRate);
  }

  private async checkAppStatus(): Promise<void> {
    if (!AppStatusSupervisor.isAppOnline()) {
      return;
    }

    const apiComponent = await this.getApiComponent();

    if (!apiComponent) {
      return;
    }

    if (this.hasNewStatus(apiComponent.status)) {
      this.resolveStatusChange(apiComponent.status);
    }
  }

  private async getApiComponent(): Promise<Component | null> {
    try {
      return await this.componentFetcher.fetchDomainApiComponent();
    } catch (e) {
      if (e instanceof NetworkError || e instanceof ComponentNotFoundError) {
        return null;
      }

      throw e;
    }
  }

  private hasNewStatus(componentStatus: ComponentStatus): boolean {
    return this.status !== componentStatus;
  }

  private resolveStatusChange(componentStatus: ComponentStatus): void {
    this.statusChangeCallback(componentStatus);

    this.status = componentStatus;
  }

  private static isAppOnline(): boolean {
    return window.navigator?.onLine ?? true;
  }
}
