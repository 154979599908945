import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { Store } from 'vuex';
import { Facility } from '../../domain/feedback-form/Facility';
import type { StoreFacility } from './StoreFacility';
import type { CurrentFacilityRepository } from '../../domain/feedback-form/CurrentFacilityRepository';

@injectable()
export class StoreCurrentFacilityRepository implements CurrentFacilityRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store<unknown>,
  ) {}

  async get(): Promise<Facility> {
    const storeFacility: StoreFacility = this.store.getters.getUserFacility;

    return new Facility(storeFacility.id, storeFacility.name);
  }
}
