import type { Timeline } from '@/modules/task/domain/timeline/Timeline';
import type { Task } from '@/modules/task/domain/task/Task';

export enum IconName {
  Abandoned = 'abandoned',
  Intro = 'intro',
  InProgress = 'in-progress',
  IncomingCompleted = 'incoming-completed',
  IncomingUnanswered = 'incoming-unanswered',
  IncomingWaiting = 'incoming-waiting',
  IncomingWaitingAndMine = 'incoming-waiting-and-mine',
  OutgoingCompleted = 'outgoing-completed',
  OutgoingUnanswered = 'outgoing-unanswered',
  OutgoingWaiting = 'outgoing-waiting',
  OutgoingWaitingAndMine = 'outgoing-waiting-and-mine',
  IncomingWhatsAppMessage = 'incoming-whats-app-message',
  OutgoingWhatsAppMessage = 'outgoing-whats-app-message',
  IncomingSmsMessage = 'incoming-sms-message',
  OutgoingSmsMessage = 'outgoing-sms-message',
  VisitConfirmation = 'visit-confirmation',
  VoiceMessage = 'voice-message',
  InboundForm = 'inbound-form',
  NewToDo = 'new-to-do',
  PatientOtherRequest = 'patient-other-request',
  PatientPrescriptionRequest = 'patient-prescription-request',
  IncomingDpChatMessage = 'incoming-dp-chat-message',
  OutgoingDpChatMessage = 'outgoing-dp-chat-message',
}

export interface IconStrategy {
  get(task: Task, timeline: Timeline): IconName | null;
}
