import { StorageFactory } from '@/common/root/domain/storage/services/StorageFactory';
import apiConfiguration from '@/config/api-configuration.json';
import { ConfigurationProvider } from '@/common/app-config/domain/services/ConfigurationProvider';
import { DefaultConfigurationFactory } from '@/common/app-config/domain/repositories/DefaultConfigurationFactory';
import { BrowserDomainRepository } from '@/common/app-config/adapters/BrowserDomainRepository';
import { StoredConfigurationRepository } from '@/common/app-config/domain/repositories/StoredConfigurationRepository';
import { StorageKey } from '../StorageKey';

export const configProvider: ConfigurationProvider = new ConfigurationProvider(
  new DefaultConfigurationFactory(apiConfiguration, new BrowserDomainRepository(window)),
  new StoredConfigurationRepository(
    new StorageFactory(localStorage, Date).createStorageItemFactory(StorageKey),
  ),
);
