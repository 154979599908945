import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { StorePhoneApiCallManager } from '../../adapters/phone-api/StorePhoneApiCallManager';
import { StoreWorkstationAssigner } from '../../adapters/webphone/StoreWorkstationAssigner';
import { TwilioCallingDeviceFactory } from '../../adapters/webphone/device/TwilioCallingDeviceFactory';
import { WindowClock } from '../../adapters/webphone/device/WindowClock';
import type { CallingDeviceFactory } from '../../domain/webphone/device/CallingDeviceFactory';
import type { Clock } from '../../domain/webphone/device/Clock';
import type { WorkstationAssigner } from '../../domain/webphone/services/WorkstationAssigner';
import type { PhoneApiCallManager } from '../../domain/phone-api/PhoneApiCallManager';
import type { StateFetcher } from '../../domain/webphone/state/StateFetcher';
import {
  BrowserSupportValidatorToken,
  CallDurationToken,
  CallingDeviceFactoryToken,
  ClockToken,
  PhoneApiCallManagerToken,
  StateFetcherToken,
  WorkstationAssignerToken,
  DeviceErrorRepositoryToken,
  CallErrorRepositoryToken,
  ErrorLoggerToken,
  AudioValidatorToken,
} from '../tokens';
import type { CallDuration } from '../../domain/webphone/call-management/CallDuration';
import { StoreCallDuration } from '../../adapters/webphone/call-management/StoreCallDuration';
import type { BrowserSupportValidator } from '../../domain/webphone/environment-validation/BrowserSupportValidator';
import { TwilioBrowserSupportValidator } from '../../adapters/webphone/environment-validation/TwilioBrowserSupportValidator';
import type { DeviceErrorRepository } from '../../domain/webphone/errors/DeviceErrorRepository';
import { StoreDeviceErrorRepository } from '../../adapters/webphone/errors/StoreDeviceErrorRepository';
import type { CallErrorRepository } from '../../domain/webphone/errors/CallErrorRepository';
import { StoreCallErrorRepository } from '../../adapters/webphone/errors/StoreCallErrorRepository';
import { SentryErrorLogger } from '../../adapters/webphone/errors/SentryErrorLogger';
import type { ErrorLogger } from '../../domain/webphone/errors/ErrorLogger';
import { BrowserAudioValidator } from '../../adapters/webphone/environment-validation/BrowserAudioValidation';
import type { AudioValidator } from '../../domain/webphone/environment-validation/AudioValidator';
import { StaticStateFetcher } from '../../adapters/webphone/state/StaticStateFetcher';

@injectable()
export class WebphoneProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<WorkstationAssigner>(WorkstationAssignerToken, StoreWorkstationAssigner);
    container.register<Clock>(ClockToken, WindowClock);
    container.register<CallingDeviceFactory>(CallingDeviceFactoryToken, TwilioCallingDeviceFactory);
    container.register<PhoneApiCallManager>(PhoneApiCallManagerToken, StorePhoneApiCallManager);
    container.registerSingleton<StateFetcher>(StateFetcherToken, StaticStateFetcher);
    container.register<CallDuration>(CallDurationToken, StoreCallDuration);
    container.register<BrowserSupportValidator>(
      BrowserSupportValidatorToken,
      TwilioBrowserSupportValidator,
    );
    container.register<DeviceErrorRepository>(
      DeviceErrorRepositoryToken,
      StoreDeviceErrorRepository,
    );
    container.register<CallErrorRepository>(CallErrorRepositoryToken, StoreCallErrorRepository);
    container.register<ErrorLogger>(ErrorLoggerToken, SentryErrorLogger);
    container.register<AudioValidator>(AudioValidatorToken, BrowserAudioValidator);
  }
}
