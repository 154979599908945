import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { TaskWriteRepository } from '@/modules/task/domain/task/TaskWriteRepository';

@injectable()
export class HttpTaskWriteRepository implements TaskWriteRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  updateOwnership(taskId: string, ownerId: string): Promise<void> {
    return this.httpClient.put(`api/v0/task/${taskId}/reassign`, {
      userId: ownerId,
    });
  }
}
