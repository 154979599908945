import type { Workstation } from './Workstation';

export class FallbackWorkstation {
  constructor(
    readonly id: string,
    readonly workstation: Workstation,
  ) {}

  get name(): string {
    return this.workstation.name;
  }
}
