import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { RootModule } from '@/common/root';
import { TrackingEventModule } from '@/common/tracking-event';
import { StoreProvider } from '@/modules/book-visit/di/providers/StoreProvider';
import { AdapterProvider } from '@/modules/book-visit/di/providers/AdapterProvider';
import { RepositoryProvider } from '@/modules/book-visit/di/providers/RepositoryProvider';
import { EventProvider } from '@/modules/book-visit/di/providers/EventProvider';
import { CountryCodeProviderProvider } from '@/modules/book-visit/di/providers/CountryCodeProviderProvider';
import { ErrorLoggerProvider } from '@/modules/book-visit/di/providers/ErrorLoggerProvider';
import { TrackingProvider } from '@/modules/book-visit/di/providers/TrackingProvider';
import { PatientModule } from '@/modules/patient';
import { HttpClientModule } from '@/modules/http-client';
import { PatientProvider } from './providers/PatientProvider';

export class BookVisitModule implements DpModule {
  static readonly namespace = Symbol('BookVisitModule');

  static readonly requires = [RootModule, TrackingEventModule, PatientModule, HttpClientModule];

  providers(): IDpProvider[] {
    return [
      StoreProvider,
      AdapterProvider,
      RepositoryProvider,
      CountryCodeProviderProvider,
      EventProvider,
      ErrorLoggerProvider,
      TrackingProvider,
      PatientProvider,
    ];
  }
}
