import { RootModule } from '@/common/root';
import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { DpEventBusSubscriberProvider } from './providers/DpEventBusSubscriberProvider';
import { EventSubscriberProvider } from './providers/EventSubscriberProvider';
import { EventTrackerFacadeProvider } from './providers/EventTrackerFacadeProvider';
import { TrackingEventSubscriberProvider } from './providers/TrackingEventSubscriberProvider';

export class TrackingEventModule implements DpModule {
  static readonly namespace = Symbol('TrackingEventModule');

  static readonly requires = [RootModule];

  providers(): IDpProvider[] {
    return [
      EventSubscriberProvider,
      EventTrackerFacadeProvider,
      TrackingEventSubscriberProvider,
      DpEventBusSubscriberProvider,
    ];
  }
}
