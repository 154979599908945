<template>
  <div :class="$b()">
    <div :class="$b('icon')">
      <slot name="icon" />
    </div>
    <div :class="$b('body', bodyModifiers)">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';

@Component({
  name: 'TaskSectionWrapper',
})
export default class TaskSectionWrapper extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly border: boolean;

  @Prop({ type: Boolean, default: false })
  readonly noPadding: boolean;

  get bodyModifiers() {
    return {
      border: this.border,
      'no-padding': this.noPadding,
    };
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-task-section-wrapper {
  position: relative;

  &__icon {
    position: absolute;
    top: v.$spacer-sm;
    right: calc(100% + #{v.$spacer-md});
  }

  &__body {
    &--border {
      padding: v.$spacer-md;
      border: 1px solid v.$gray-200;
      border-radius: v.$border-radius-lg;
    }

    &--no-padding {
      padding: 0;
    }
  }
}
</style>
