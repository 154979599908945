import { injectable } from 'tsyringe';
import type { CustomTrackingEvent } from '../../dto/CustomTrackingEvent';
import type { DurationEvent } from '../../../models/DurationEvent';

@injectable()
export class DurationEventConverter {
  private readonly eventName = 'performance-measurement';

  make(durationEvent: DurationEvent): CustomTrackingEvent {
    return {
      event: this.eventName,
      category: 'performance',
      label: durationEvent.externalName,
      value: durationEvent.duration,
      eventProperties: durationEvent.extraProperties,
    };
  }
}
