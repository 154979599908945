import type { Clock } from '../../../domain/webphone/device/Clock';

type Callback = () => Promise<void>;

// todo move intervals to constructor and put the class in the domain
export class WindowClock implements Clock {
  private readonly intervalTick = 10 * 1000;

  private intervalReference: null | NodeJS.Timeout = null;

  private callback: null | Callback = null;

  private callbackFrequencyMs = 600 * 1000;

  private lastCallbackExecutionTime: number = Date.now();

  private isProcessingCallback = false;

  start(callback: Callback, refreshTimeInSeconds: number): void {
    this.callback = callback;
    this.callbackFrequencyMs = refreshTimeInSeconds * 1000;

    this.intervalReference = setInterval(async () => {
      await this.runCallback();
    }, this.intervalTick);
  }

  stop(): void {
    this.callback = null;

    if (this.intervalReference) {
      clearInterval(this.intervalReference);
    }
  }

  private async runCallback(): Promise<void> {
    if (!this.callback) {
      this.stop();
      return;
    }

    if (this.isProcessingCallback) {
      return;
    }

    const now = Date.now();

    if (now - this.lastCallbackExecutionTime < this.callbackFrequencyMs) {
      return;
    }

    this.isProcessingCallback = true;
    await this.callback();
    this.lastCallbackExecutionTime = now;
    this.isProcessingCallback = false;
  }
}
