import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { RootRouterToken } from '@/common/root';
import { statisticsRouter } from '@/modules/statistics/ui/router';
import type { Router } from 'vue-router';

export class RouterProvider implements DpProvider {
  boot(container: DependencyContainer): void {
    const router = container.resolve<Router>(RootRouterToken);

    router.addRoute('dashboard', statisticsRouter);
  }
}
