import { inject, injectable } from 'tsyringe';
import { DateFormatterToken } from '../../di/tokens';
import { Reminder } from './Reminder';
import type { DateFormatter } from '../time/DateFormatter';

@injectable()
export class ReminderFactory {
  constructor(@inject(DateFormatterToken) private readonly dateConverter: DateFormatter) {}

  make(id: string, date: string | null): Reminder {
    if (date) {
      const timestamp = this.dateConverter.stringToTimestamp(date);
      return new Reminder(id, timestamp);
    }

    return new Reminder(id, null);
  }
}
