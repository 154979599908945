import type { Store } from 'vuex';
import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import type { ModalController } from '@/modules/task/domain/modal/ModalController';
import type { RootState } from '@/store/types';
import { FocusTargetToken, ModalControllerToken } from '@/modules/task/di/tokens';
import { StoreModalController } from '@/modules/task/adapters/modal/StoreModalController';
import { RootStoreToken } from '@/common/root';
import { module, moduleName } from '@/modules/task/store/modal-controller';
import type { FocusTarget } from '@/modules/task/domain/modal/FocusTarget';
import { StoreFocusTarget } from '@/modules/task/adapters/modal/StoreFocusTarget';

export class ModalProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<ModalController>(ModalControllerToken, StoreModalController);
    container.register<FocusTarget>(FocusTargetToken, StoreFocusTarget);
  }

  boot(container: DependencyContainer): void {
    const rootStore = container.resolve<Store<RootState>>(RootStoreToken);

    rootStore.registerModule(moduleName, module);
  }
}
