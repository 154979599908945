import type { DpEvent } from '@/core/events/DpEvent';
import { DpEventName } from '../DpEventName';
import type { InteractionEventExtraProperties } from './InteractionEventExtraProperties';
import type { InteractionEventType } from './InteractionEventType';

export class InteractionEvent implements DpEvent {
  readonly name = DpEventName.Interaction;

  constructor(
    readonly type: InteractionEventType,
    readonly externalName: string,
    readonly extraProperties?: InteractionEventExtraProperties,
  ) {}
}
