import { schema } from 'normalizr';
import { EntityType } from './entity-type';

export const BUNDLE_STATUSES = {
  NEW: 'new',
  PROCESSING: 'processing',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

export const FIELDS = {
  BUSINESS_NAME: 'businessName',
  NAME: 'name',
  BUSINESS_ADDRESS: 'businessAddress',
  DOCUMENTS: 'documents',
  STATUS: 'status',
};

export const ADDRESS_CHUNKS = {
  ADDRESS: 'address',
  POSTAL_CODE: 'postalCode',
  CITY: 'city',
  REGION: 'region',
  NATIONALITY: 'nationality',
};

/**
 * @typedef {Object} CommonObject
 * @property {string} value
 */

/**
 * @typedef {Object} StatusProps
 * @property {string} class
 */

/**
 * @typedef {Object} Status
 * @property {string} value
 * @property {StatusProps} props
 */

/**
 * @typedef {Object} ComplianceBundle
 * @property {string} id
 * @property {CommonObject} businessName
 * @property {CommonObject} name
 * @property {CommonObject} businessAddress
 * @property {CommonObject} documents
 * @property {Status} status
 */
export const complianceBundleSchema = new schema.Entity(EntityType.COMPLIANCE_BUNDLE, {});
