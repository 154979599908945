import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { RootModule } from '@/common/root';
import { PatientModule } from '@/modules/patient';
import { TrackingEventModule } from '@/common/tracking-event';
import { UserModule } from '@/modules/user';
import { HttpClientModule } from '@/modules/http-client';
import { RepositoriesProvider } from './providers/RepositoriesProvider';
import { StoreProvider } from './providers/StoreProvider';
import { AuthorizationProvider } from './providers/AuthorizationProvider';
import { TrackingProvider } from './providers/TrackingProvider';
import { AudioConverterProvider } from './providers/AudioConverterProvider';

export class TimelineModule implements DpModule {
  static readonly namespace = Symbol('TimelineModule');

  static readonly requires = [
    RootModule,
    PatientModule,
    UserModule,
    TrackingEventModule,
    HttpClientModule,
  ];

  providers(): IDpProvider[] {
    return [
      RepositoriesProvider,
      StoreProvider,
      AuthorizationProvider,
      TrackingProvider,
      AudioConverterProvider,
    ];
  }
}
