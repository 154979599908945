interface ItemTextQuery {
  type: 'text';
  id: string;
  uri?: string;
}

interface ItemPatient {
  type: 'patient';
  fullName: string;
  formattedNumber: string;
  id: string;
  uri?: string;
}

export type PatientSearchItem = ItemPatient | ItemTextQuery;

export enum PatientSearchTheme {
  White = 'white',
  LightGray = 'light-gray',
}
