import { HttpClientApi } from '@/modules/http-client/public/api';
import { injectable } from 'tsyringe';
import type { VisitSynchronizationRepository } from '../../domain/facility-integration/VisitSynchronizationRepository';
import type { VisitSynchronizationStatus } from '../../domain/facility-integration/VisitSynchronizationStatus';
import type { InputVisitSynchronizationDTO } from './types/InputVisitSynchronizationDTO';
import { VisitSynchronizationStatusFactory } from './VisitSynchronizationStatusFactory';

@injectable()
export class HttpVisitSynchronizationRepository implements VisitSynchronizationRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly visitSynchronizationStatusFactory: VisitSynchronizationStatusFactory,
  ) {}

  async get(): Promise<VisitSynchronizationStatus> {
    const synchronizationStatus: InputVisitSynchronizationDTO = await this.httpClient.get(
      '/api/v0/facility/{facilityId}/settings/last-visit-confirmation-sync-log',
    );

    return this.visitSynchronizationStatusFactory.make(synchronizationStatus);
  }

  async synchronize(): Promise<VisitSynchronizationStatus> {
    const synchronizationStatus: InputVisitSynchronizationDTO = await this.httpClient.post(
      '/api/v0/facility/{facilityId}/settings/sync-unconfirmed-visits',
    );

    return this.visitSynchronizationStatusFactory.make(synchronizationStatus);
  }
}
