import type { ChannelRepository } from '@/modules/task/domain/channel/ChannelRepository';
import { Channel } from '@/modules/task/domain/channel/Channel';
import { $GET_ENTITIES_BY_ID } from '@dp-vue/entities';
import type { StoreChannel } from '@/modules/task/adapters/channel/StoreChannel';
import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { StoreContactFormChannel } from './StoreContactFormChannel';

type ReturnType<T> = T extends 'phone'
  ? StoreChannel[]
  : T extends 'form'
    ? StoreContactFormChannel[]
    : never;

interface Store {
  getters: {
    [$GET_ENTITIES_BY_ID]: <T extends 'phone' | 'form'>(
      entityType: T,
      ids: string[],
    ) => ReturnType<T>;
  };
}

@injectable()
export class StoreChannelRepository implements ChannelRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
  ) {}

  get(channelId: string): Channel {
    const [phoneChannel] = this.store.getters[$GET_ENTITIES_BY_ID]('phone', [channelId]);

    if (phoneChannel) {
      return Channel.make(phoneChannel.id, phoneChannel.name, phoneChannel.priority);
    }

    const [contactFormChannel] = this.store.getters[$GET_ENTITIES_BY_ID]('form', [channelId]);

    if (contactFormChannel) {
      return Channel.make(contactFormChannel.id, contactFormChannel.name, false);
    }

    return Channel.makeEmpty();
  }
}
