import { injectable } from 'tsyringe';
import { Domain } from '../domain/models/Domain';
import type { DomainRepository } from '../domain/repositories/DomainRepository';

@injectable()
export class BrowserDomainRepository implements DomainRepository {
  constructor(private readonly window: Window) {}

  get(): Domain {
    const domain = this.getFromUrl();

    return this.mapDomain(domain);
  }

  private getFromUrl(): string {
    return this.window.location.hostname.split('.').reverse()[0];
  }

  private mapDomain(domainSuffix: string): Domain {
    return (
      {
        br: Domain.BR,
        es: Domain.ES,
        it: Domain.IT,
        mx: Domain.MX,
        pl: Domain.PL,
      }[domainSuffix] ?? Domain.Default
    );
  }
}
