import { injectable } from 'tsyringe';
import { Integration } from '../../domain/facility-integration/Integration';
import type { InputIntegrationDTO } from './types/InputIntegrationDTO';
import type { OutputIntegrationDTO } from './types/OutputIntegrationDTO';

@injectable()
export class IntegrationFactory {
  fromDTO(inputIntegrations: InputIntegrationDTO[]): Integration[] {
    return inputIntegrations.map(
      inputIntegration =>
        new Integration(
          inputIntegration.id,
          inputIntegration.enabled,
          inputIntegration.endpoint.type,
          inputIntegration.endpoint.name,
        ),
    );
  }

  toDTO(integrations: Integration[]): OutputIntegrationDTO {
    return integrations.reduce(
      (acc, integration) => ({
        ...acc,
        [integration.id]: { enabled: integration.enabled },
      }),
      {},
    );
  }
}
