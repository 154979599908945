import { Conference } from './Conference';

export class TaskCall {
  constructor(
    readonly id: string,
    readonly sid: string,
    readonly ownerId: string,
    readonly taskId: string,
    readonly patientId: string,
    private readonly status:
      | 'abandoned'
      | 'completed'
      | 'empty'
      | 'in-progress'
      | 'intro'
      | 'unanswered'
      | 'waiting',
    readonly workstationId: string | null,
    readonly conference: Conference,
    private readonly isOnHold: boolean,
  ) {}

  get isEmpty(): boolean {
    return this.status === 'empty';
  }

  get isInProgress(): boolean {
    return this.status === 'in-progress';
  }

  get isWaiting(): boolean {
    return this.status === 'waiting';
  }

  get isOngoing(): boolean {
    return ['waiting', 'in-progress'].includes(this.status);
  }

  get isNotActive(): boolean {
    return !this.isOngoing;
  }

  get isPatientOnHold(): boolean {
    return this.isOnHold || this.conference.isPatientOnHold;
  }

  get hasConference(): boolean {
    return this.conference.hasTarget;
  }

  get targetId(): string {
    return this.conference.targetId;
  }

  get isTargetConnected(): boolean {
    return this.conference.isTargetConnected;
  }

  static empty(): TaskCall {
    return new TaskCall('', '', '', '', '', 'empty', null, Conference.empty(), false);
  }
}
