import { injectable } from 'tsyringe';
import { TaskCall } from '../../domain/models/TaskCall';
import type { InputCall } from '../types/InputCall';
import { ConferenceFactory } from './ConferenceFactory';

@injectable()
export class TaskCallFactory {
  constructor(private readonly conferenceFactory: ConferenceFactory) {}

  makeMany(calls: InputCall[]): TaskCall[] {
    return calls.map(this.make, this);
  }

  private make(call: InputCall): TaskCall {
    return new TaskCall(
      call.id,
      call.sid,
      call.personnel ?? '',
      call.taskId,
      call.patientId,
      call.status,
      call.workstation_id,
      this.conferenceFactory.tryMake(call.conference),
      call.is_on_hold,
    );
  }
}
