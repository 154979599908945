export class Measurement {
  public queueEnd: null | number = null;

  private repetitions = 1;

  constructor(readonly startTime: number) {}

  get numberOfRepetitions(): number {
    return this.repetitions;
  }

  increaseRepetition(): void {
    this.repetitions += 1;
  }
}
