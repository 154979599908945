import { injectable } from 'tsyringe';
import { TaskIdQueueSupervisor } from './task-id/TaskIdQueueSupervisor';
import type { Task } from './Task';
import { TaskQueueSupervisor } from './task/TaskQueueSupervisor';

@injectable()
export class TaskCallback {
  constructor(
    private readonly taskIdQueueSupervisor: TaskIdQueueSupervisor,
    private readonly taskQueueSupervisor: TaskQueueSupervisor,
  ) {}

  handler(data: { id: string; events: string[]; task?: Task }): void {
    if (!data.task) {
      this.taskIdQueueSupervisor.addToQueue(data.id, data.events);
      return;
    }

    this.taskQueueSupervisor.addToQueue(data.id, data.task, data.events);
  }

  reset(): void {
    this.taskQueueSupervisor.reset();
  }
}
