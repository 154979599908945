import type { Patient } from './Patient';

export class Message {
  constructor(
    readonly id: string,
    readonly taskId: string,
    readonly body: string,
    readonly direction: 'inbound' | 'outbound',
    readonly patient: Patient,
  ) {}
}
