import { phoneApi } from '@/api/phone-api';
import { uploadMediaFileRepository } from '@/api/repositories';
import { prepareAllMessages, ConfigurableKey } from '@/app/voice-messages';
import { VoiceMessageListFactory } from '@/modules/settings-calls-management/adapters/factories/VoiceMessageListFactory';

const state = {
  voiceMessages: {},
};

const getters = {
  voiceMessages: state => state.voiceMessages,
};

const mutations = {
  SET(state, payload) {
    state.voiceMessages = payload;
  },

  SET_SINGLE_MESSAGE(state, payload) {
    state.voiceMessages = {
      ...state.voiceMessages,
      ...payload,
    };
  },
};

const actions = {
  async FETCH_ACTION({ commit, rootGetters }) {
    return phoneApi
      .withFacility(rootGetters.getUserFacilityID)
      .getVoiceMessages()
      .then(response => commit('SET', VoiceMessageListFactory.makeFromResponse(response.messages)));
  },

  async UPDATE_ACTION({ commit, getters, rootGetters }) {
    const uploadMethod = async fileUrl =>
      uploadMediaFileRepository.uploadPublicFile(rootGetters.getUserFacilityID, fileUrl);

    const messages = await prepareAllMessages({
      configurableMessagesKeys: Object.values(ConfigurableKey),
      allMessages: getters.voiceMessages,
      uploadMethod,
    });

    return phoneApi
      .withFacility(rootGetters.getUserFacilityID)
      .setVoiceMessages(messages)
      .then(response => commit('SET', VoiceMessageListFactory.makeFromResponse(response.messages)));
  },
};

export const name = 'voiceMessages';

export const voiceMessages = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
