import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import type { CallQualityModalManager } from '@/modules/timeline/domain/call/quality/CallQualityModalManager';
import { moduleName } from '../../../store/call-module';
import type { State } from '../../../store/call-module';

interface Store {
  state: {
    [moduleName]: State;
  };
  commit(name: string, payload: any);
}

@injectable()
export class StoreCallQualityModalManager implements CallQualityModalManager {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
  ) {}

  get isVisible(): boolean {
    return this.store.state[moduleName].isVisibleCallQualityModal;
  }

  set isVisible(newState: boolean) {
    this.store.state[moduleName].isVisibleCallQualityModal = newState;
  }

  get callId(): string {
    return this.store.state[moduleName].callId;
  }

  set callId(newCallId: string) {
    this.store.state[moduleName].callId = newCallId;
  }

  show(callId: string): void {
    this.callId = callId;
    this.isVisible = true;
  }

  hide(): void {
    this.callId = '';
    this.isVisible = false;
  }
}
