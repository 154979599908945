import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { StoreCallRepository } from '../../adapters/repositories/StoreCallRepository';
import { TimelineMessageRepository } from '../../adapters/repositories/TimelineMessageRepository';
import { UserApiUserRepository } from '../../adapters/repositories/UserApiUserRepository';
import type { CallRepository } from '../../domain/CallRepository';
import type { MessageRepository } from '../../domain/MessageRepository';
import type { UserRepository } from '../../domain/UserRepository';
import { CallRepositoryToken, MessageRepositoryToken, UserRepositoryToken } from '../tokens';

@injectable()
export class RepositoryProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<CallRepository>(CallRepositoryToken, StoreCallRepository);

    container.register<UserRepository>(UserRepositoryToken, UserApiUserRepository);

    container.register<MessageRepository>(MessageRepositoryToken, TimelineMessageRepository);
  }
}
