import type { CallErrorRepository } from '@/modules/phone-widget/domain/webphone/errors/CallErrorRepository';
import type { CallError } from '@/modules/phone-widget/domain/webphone/models/CallError';
import { webphoneStoreName } from '@/modules/phone-widget/ui/store/webphone';
import { injectable } from 'tsyringe';
import type { StoreAdapter } from '../../StoreAdapter';
import { StoreAdapterFactory } from '../../StoreAdapterFactory';

@injectable()
export class StoreCallErrorRepository implements CallErrorRepository {
  private storeAdapter: StoreAdapter;

  constructor(storeAdapterFactory: StoreAdapterFactory) {
    this.storeAdapter = storeAdapterFactory.make(webphoneStoreName);
  }

  all(): CallError[] {
    return this.storeAdapter.getter('getCallErrors');
  }

  clear(): void {
    this.storeAdapter.commit('CLEAR_CALL_ERROR');
  }

  add(callError: CallError): void {
    this.storeAdapter.commit('ADD_CALL_ERROR', callError);
  }
}
