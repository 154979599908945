import { FallbackMode } from './FallbackMode';

export class Fallback {
  static readonly defaultTimeout = 120;

  constructor(
    public mode: FallbackMode,
    public timeout: number,
  ) {}

  get isRedirectingToAllReceptionWorkstations(): boolean {
    return this.mode === FallbackMode.RedirectToAllReceptionWorkstations;
  }

  static default(): Fallback {
    return new Fallback(FallbackMode.RedirectToAllReceptionWorkstations, Fallback.defaultTimeout);
  }
}
