import { inject, injectable } from 'tsyringe';
import { WorkstationRepositoryToken } from '@/modules/workstation/di/tokens';
import type { Workstation } from '../../domain/Workstation';
import type { WorkstationRepository } from '../../domain/WorkstationRepository';

@injectable()
export class PublicWorkstationRepository {
  constructor(
    @inject(WorkstationRepositoryToken)
    private readonly workstationRepository: WorkstationRepository,
  ) {}

  all(): Workstation[] {
    return this.workstationRepository.all();
  }
}
