import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { CostPerVisitRepository } from '@/modules/statistics/domain/overview-callback/revenue/CostPerVisitRepository';
import type { CostPerVisit } from '@/modules/statistics/domain/overview-callback/revenue/CostPerVisit';
import type { InputCostPerVisit } from './InputCostPerVisit';
import { CostPerVisitFactory } from './CostPerVisitFactory';

@injectable()
export class HttpCostPerVisitRepository implements CostPerVisitRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly costPerVisitFactory: CostPerVisitFactory,
  ) {}

  async get(): Promise<CostPerVisit> {
    const inputCostPerVisit = await this.httpClient.get<InputCostPerVisit>(
      `/api/v1/facilities/{facilityId}/estimated-booking-revenue`,
    );

    return this.costPerVisitFactory.make(inputCostPerVisit);
  }

  async set(revenue: CostPerVisit): Promise<CostPerVisit> {
    const inputCostPerVisit = await this.httpClient.post<InputCostPerVisit>(
      `/api/v1/facilities/{facilityId}/estimated-booking-revenue`,
      revenue,
    );

    return this.costPerVisitFactory.make(inputCostPerVisit);
  }
}
