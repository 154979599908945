import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import type { WorkingHoursSiestaEvaluator } from '../domain/working-hours/WorkingHoursSiestaEvaluator';

interface Store {
  getters: {
    'settings/workingHours/hasSiesta': boolean;
  };
}

@injectable()
export class StoreWorkingHoursSiestaEvaluator implements WorkingHoursSiestaEvaluator {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
  ) {}

  hasSiesta(): boolean {
    return this.store.getters['settings/workingHours/hasSiesta'];
  }
}
