import { phoneApi } from '@/api/phone-api';
import { apiToView, singleViewEntryToApi } from './utils/working-hours';

const state = {
  working_hours: [],
};

const getters = {
  workingHours: state => state.working_hours,
  workingHoursApiFormat: state => state.working_hours.map(singleViewEntryToApi),
  hasSiesta: state =>
    state.working_hours.some(({ morningEnd, afternoonStart }) => !!morningEnd || !!afternoonStart),
};

const mutations = {
  /**
   * @param {object} state
   * @param {workingHoursView[]} viewWorkingHours
   */
  SET(state, viewWorkingHours) {
    state.working_hours = viewWorkingHours;
  },
};

const actions = {
  /**
   * Converts data from api and saves it as current view.
   *
   * @param {object} context
   * @param {workingHoursFromApi[]} apiWorkingHours
   */
  CONVERT_AND_SET_ACTION({ commit }, apiWorkingHours) {
    const viewWorkingHours = apiToView(apiWorkingHours);
    commit('SET', viewWorkingHours);
  },

  /**
   * Updates current view stored in vuex, when user set up different settings for the working hours.
   *
   * @param {object} context
   * @param {workingHoursView[]} viewWorkingHours
   */
  SET_ACTION({ commit }, viewWorkingHours) {
    commit('SET', viewWorkingHours);
  },

  /**
   * Puts current view in backend and refresh the current view state with backend response.
   *
   * @async
   * @param {object} context
   */
  async UPDATE_ACTION({ getters, rootGetters }) {
    return phoneApi
      .withFacility(rootGetters.getUserFacilityID)
      .setWorkingHours(getters.workingHoursApiFormat);
  },
};

export const name = 'workingHours';

export const workingHours = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

/**
 * Format of a single working hour entry kept in the vuex store and used by the view.
 *
 * @typedef {object} workingHoursView
 * @property {boolean} open flag determines if clinic is open during specific day
 * @property {string} name translated day's name
 * @property {string} morningStart time when clinic starts to support patients
 * @property {string} afternoonEnd time when clinic ends to support patients
 * @property {string|null} [morningEnd=null] time when morning shift ends, for clinic having siesta
 * @property {string|null} [afternoonStart=null] time when afternoon shift starts,
 * for clinic having siesta
 */

/**
 * Format of a single working hour entry obtained from the backend. Number values returns
 * number of minutes from midnight.
 *
 * @typedef {object} workingHoursFromApi
 * @property {string} day 3 letters english name of a day - value not used anywhere
 * @property {boolean} open flag determines if facility is working during specific day
 * @property {number} start time when clinic starts to support patients
 * @property {number} end time when clinic ends to support patients
 * @property {number|null} [siesta_start] time when morning shift ends, for clinic having siesta
 * @property {number|null} [siesta_end] time when afternoon shift starts,
 * for clinic having siesta
 */
