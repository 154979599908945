import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import type { DataExportRepository } from '../domain/DataExportRepository';
import { HttpDataExportRepository } from '../adapters/HttpDataExportRepository';

export enum Tokens {
  DataExportRepositoryToken = 'DataExportRepositoryToken',
}

export class RepositoryProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<DataExportRepository>(
      Tokens.DataExportRepositoryToken,
      HttpDataExportRepository,
    );
  }
}
