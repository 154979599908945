import type { Store } from 'vuex';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { store } from '@/store';
import type { RootState } from '@/store/types';
import type { DpProvider } from '@/core/di/DpProvider';
import {
  MUTATION_ENTITIES_DELETE_KEY,
  MUTATION_ENTITIES_MERGE,
  MUTATION_ENTITIES_UPDATE,
  MUTATION_ENTITIES_UPDATE_KEY,
} from '@dp-vue/entities';
import { StoreEntitySubscriber } from '../adapters/store/StoreEntitySubscriber';
import { StoreEntityHandler } from '../adapters/store/StoreEntityHandler';
import { StoreEntitiesEventPublisher } from '../domain/store/StoreEntitiesPublisher';
import { StoreClock } from '../adapters/StoreClock';
import type { Clock } from '../domain/Clock';

export const RootStoreToken = Symbol('rootStore');
export const RootClock = Symbol('rootTimeRepository');

@injectable()
export class RootStoreProvider implements DpProvider {
  private storeSubscriber: StoreEntitySubscriber | null = null;

  register(container: DependencyContainer): void {
    container.register<Store<RootState>>(RootStoreToken, { useFactory: () => store });

    container.register<Clock>(RootClock, {
      useValue: new StoreClock(store),
    });

    container.register<StoreEntitySubscriber>(StoreEntitySubscriber, {
      useFactory(c) {
        return new StoreEntitySubscriber(
          c.resolve(RootStoreToken),
          new StoreEntityHandler(c.resolve(StoreEntitiesEventPublisher), {
            singular: [MUTATION_ENTITIES_UPDATE_KEY, MUTATION_ENTITIES_DELETE_KEY],
            batch: [MUTATION_ENTITIES_UPDATE, MUTATION_ENTITIES_MERGE],
          }),
        );
      },
    });

    this.storeSubscriber = container.resolve(StoreEntitySubscriber);
  }

  boot(): void {
    if (!this.storeSubscriber) {
      return;
    }

    this.storeSubscriber.subscribe();
  }
}
