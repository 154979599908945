import { inject, singleton } from 'tsyringe';
import { OutboundTaskRepositoryToken } from '../../di/token';
import type { OutboundTaskStatus } from './OutboundTaskStatus';
import type { OutboundTaskRepository } from './OutboundTaskRepository';

@singleton()
export class OutboundTaskSwitcher {
  private status: OutboundTaskStatus | null = null;

  constructor(
    @inject(OutboundTaskRepositoryToken)
    private readonly outboundTaskRepository: OutboundTaskRepository,
  ) {}

  async get(): Promise<OutboundTaskStatus> {
    if (this.status) {
      return this.status;
    }

    this.status = await this.outboundTaskRepository.get();
    return this.status;
  }

  async enableCreatingTasks(): Promise<OutboundTaskStatus> {
    this.status = await this.outboundTaskRepository.update(true);
    return this.status;
  }

  async disableCreatingTasks(): Promise<OutboundTaskStatus> {
    this.status = await this.outboundTaskRepository.update(false);
    return this.status;
  }
}
