export class Doctor {
  constructor(
    readonly id: string,
    readonly fullName: string,
    readonly prefix: string,
    readonly specialization: string,
  ) {}

  get displayedName(): string {
    return `${this.prefix} ${this.fullName}`.trim();
  }

  get isEmpty(): boolean {
    return this.id === '';
  }

  equals(doctor: Doctor): boolean {
    if (this.isEmpty) {
      return false;
    }

    return this.id === doctor.id;
  }

  static makeEmpty(): Doctor {
    return new Doctor('', '', '', '');
  }
}
