import type { VoiceMessage } from '@/modules/settings-calls-management/domain/voice-messages';
import type { Media } from '@/api/models/Media';
import type { RequestMessages, MessageName, RequestMessage } from '@/api/types/request/message';
import { i18n } from '@/ui/plugins/i18n';
import { snakeCase } from 'lodash-es';

/** @deprecated */
export enum ConfigurableKey {
  WelcomeMessage = 'welcomeMessage',
  FacilityAfterHours = 'facilityAfterHours',
  VoicemailInQueue = 'voicemailInQueue',
}

/** @deprecated */
export async function prepareVoiceMessagePayload(
  currentVoiceMessage: VoiceMessage,
  uploadMethod: (fileUrl: string) => Promise<Media>,
): Promise<RequestMessage> {
  const { value, type, file } = currentVoiceMessage;

  if (type === 'file' && file === null) {
    throw new Error(`${i18n.global.t('validation.no_uploaded_file')}`);
  }

  if (file?.isDirty) {
    const response = await uploadMethod(file.url);
    return { value, type, file: response.id };
  }

  return {
    value,
    type,
    file: file?.id ?? '',
  };
}

/**
 * Validate form and uploads new file with voice message if necessary.
 *
 * @param {Object.<string, voiceMessage>} voiceMessages
 * @param {function} uploadMethod
 *
 * @deprecated
 */
function prepareVoiceMessageToSet(
  voiceMessages: Partial<Record<MessageName, VoiceMessage>>,
  uploadMethod: (fileUrl: string) => Promise<Media>,
) {
  return async (key: MessageName): Promise<[MessageName, RequestMessage]> => {
    const currentVoiceMessage = voiceMessages[key];

    if (!currentVoiceMessage) {
      throw new Error('Invalid voice message key');
    }

    const requestPayload = await prepareVoiceMessagePayload(currentVoiceMessage, uploadMethod);

    return [snakeCase(key) as MessageName, requestPayload];
  };
}

interface PrepareAllMessages {
  configurableMessagesKeys: MessageName[];
  allMessages: Partial<Record<MessageName, VoiceMessage>>;
  uploadMethod: (fileUrl: string) => Promise<Media>;
}

/**
 * Prepares messages to be send to backend. Validates data and upload necessary files.
 *
 * @param {object} options
 * @param {string[]} options.configurableMessagesKeys keys of messages allowed to be configured
 * @param {Object.<string, voiceMessage} options.allMessages object with voiceMessages, can contain
 * non-configurable fields
 * @param {function} options.uploadMethod
 *
 * @deprecated
 */
export async function prepareAllMessages({
  configurableMessagesKeys,
  allMessages,
  uploadMethod,
}: PrepareAllMessages): Promise<RequestMessages> {
  return Promise.all(
    configurableMessagesKeys.map(prepareVoiceMessageToSet(allMessages, uploadMethod)),
  ).then(Object.fromEntries);
}
