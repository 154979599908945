import { injectable } from 'tsyringe';
import type { DpListener } from '@/core/events/DpListener';
import { EventConsoleLogger } from './EventConsoleLogger';
import type { InteractionEvent } from '../models/InteractionEvent';

@injectable()
export class InteractionEventLogger implements DpListener {
  constructor(private readonly eventConsoleLogger: EventConsoleLogger) {}

  handle(interactionEvent: InteractionEvent): void {
    this.eventConsoleLogger.display(this.toDescription(interactionEvent));
  }

  private toDescription({ externalName, type, extraProperties }: InteractionEvent): string {
    const title = `BUSINESS EVENT: "${type}" - "${externalName}"`;

    return typeof extraProperties === 'object'
      ? `${title} - "${JSON.stringify(extraProperties)}"`
      : title;
  }
}
