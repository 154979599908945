import type { Ownable } from './Ownable';
import { OwnerId } from './OwnerId';

export class Owner {
  constructor(
    readonly id: OwnerId,
    readonly name: string,
    readonly avatarUrl: string,
    readonly status: 'online' | 'offline' | 'break',
  ) {}

  owns(item: Ownable): boolean {
    return item.isOwnedBy(this);
  }

  isEqual(owner: Owner | null): boolean {
    if (!owner || this.id.isEmpty) {
      return false;
    }

    return this.id.isEqual(owner.id);
  }

  get isOnBreak(): boolean {
    return this.status === 'break';
  }

  static get empty(): Owner {
    return new Owner(OwnerId.empty, '', '', 'offline');
  }
}
