import { injectable } from 'tsyringe';
import { EventBus } from '@/ui/plugins/event-bus';
import type { SubjectModal } from '../../domain/subject/SubjectModal';

@injectable()
export class SubjectModalAdapter implements SubjectModal {
  private legacyEventBus = EventBus;

  open(taskId: string): void {
    this.legacyEventBus.emit('status-modal', taskId);
  }
}
