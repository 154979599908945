export class OwnerId {
  constructor(readonly value: string) {}

  get isEmpty(): boolean {
    return this.value === '';
  }

  isEqual(ownerId: OwnerId): boolean {
    if (this.isEmpty) {
      return false;
    }

    return this.value === ownerId.value;
  }

  static get empty(): OwnerId {
    return new OwnerId('');
  }
}
