import { Authorization } from '@/common/authorization/public/api';
import { injectable } from 'tsyringe';
import type { PolicyValidator } from '../domain/PolicyValidator';

@injectable()
export class AuthorizationPolicyValidator implements PolicyValidator {
  constructor(private readonly authorization: Authorization) {}

  can(policyName: string): boolean {
    return this.authorization.can(policyName);
  }
}
