import { injectable } from 'tsyringe';
import type { QuickReplyTemplateRepository } from '@/modules/settings/domain/quick-replies/QuickReplyTemplateRepository';
import { HttpClientApi } from '@/modules/http-client/public/api';
import { QuickReplyTemplate } from '@/modules/settings/domain/quick-replies/QuickReplyTemplate';

interface HttpQuickReplyTemplate {
  id: string;
  name: string;
  body: string;
}

@injectable()
export class HttpQuickReplyTemplateRepository implements QuickReplyTemplateRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async get(): Promise<QuickReplyTemplate[]> {
    const templates: HttpQuickReplyTemplate[] = await this.httpClient.get(
      `/api/v0/facility/{facilityId}/message-templates`,
    );

    if (!templates) {
      return [];
    }

    // TODO improve it when we start receive status from the backend
    return templates.map(({ id, name, body }) => new QuickReplyTemplate(id, name, body, 'active'));
  }
}
