import type { AccessToken } from '@/modules/authentication/domain/oauth/token/AccessToken';

export abstract class OAuthClient {
  isTokenExpired(): boolean {
    const token = this.findAccessToken();

    if (!token) {
      return true;
    }

    return token.isExpired;
  }

  getTokenHeader(): string {
    const token = this.findAccessToken();

    if (!token) {
      return '';
    }

    return token.authorizationHeader;
  }

  isAuthenticated(): boolean {
    return !!this.findAccessToken();
  }

  abstract refreshAccessToken(): Promise<void>;

  abstract findAccessToken(): AccessToken | null;

  abstract setAccessToken(accessToken: AccessToken): void;

  abstract deleteAccessToken(): void;
}
