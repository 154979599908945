import { injectable } from 'tsyringe';
import type { DpChatMessage } from '@/modules/timeline/domain/message-composer/message/DpChatMessage';
import { TimelineDpChatMessage } from './TimelineDpChatMessage';
import { OutputTimelineMediaFactory } from '../media/OutputTimelineMediaFactory';

@injectable()
export class OutputTimelineDpChatMessageFactory {
  constructor(private readonly outputTimelineMediaFactory: OutputTimelineMediaFactory) {}

  makeMany(timelineMessages: DpChatMessage[]): TimelineDpChatMessage[] {
    return timelineMessages.map(this.make, this);
  }

  make(timelineMessage: DpChatMessage): TimelineDpChatMessage {
    return new TimelineDpChatMessage(
      timelineMessage.id,
      timelineMessage.direction,
      timelineMessage.body,
      timelineMessage.createdAt.isoDate,
      timelineMessage.owner?.id ?? null,
      this.outputTimelineMediaFactory.makeMany(timelineMessage.media),
    );
  }
}
