import type { Status } from './Status';

export class UserStatus {
  constructor(
    readonly id: string,
    readonly status: Status,
    readonly firstLogin: number | null,
    readonly endDate: number | null,
  ) {}

  get isPause(): boolean {
    return this.status === 'break';
  }

  static empty(): UserStatus {
    return new UserStatus('', 'offline', null, null);
  }
}
