import { toNamedParams } from '@/utils/vuex';
import { getUserDisplayName } from '@/utils/user-name';
import { phoneApi } from '@/api/phone-api';
import { ConferenceParticipantStatus, ConferenceParticipantType } from '@/types/conference';

function removeFromConference(rootGetters, participantType) {
  const callId = rootGetters.getOngoingCallForCurrentUser.id;
  return phoneApi.withCall(callId).removeFromConference(participantType);
}

const getters = {
  getConferenceData: toNamedParams(
    ({ rootGetters }) => rootGetters.getOngoingCallForCurrentUser?.conference,
  ),

  getTargetParticipantData: toNamedParams(({ getters }) => getters.getConferenceData?.target),

  isTargetParticipantInConference: toNamedParams(({ getters }) =>
    [ConferenceParticipantStatus.Invited, ConferenceParticipantStatus.InConference].includes(
      getters.getTargetParticipantData?.status,
    ),
  ),

  getTargetParticipantName: toNamedParams(({ getters, rootGetters }) => {
    if (!getters.isTargetParticipantInConference) {
      return null;
    }

    const workstationId = getters.getTargetParticipantData.workstation_id;
    const workstation = rootGetters['settings/reception/getReceptionPhoneById'](workstationId);

    return workstation.current_user
      ? getUserDisplayName(workstation.current_user)
      : workstation.name;
  }),

  getTargetParticipantStatus: toNamedParams(
    ({ getters }) => getters.getTargetParticipantData?.status,
  ),

  isPatientOnHold: toNamedParams(({ getters, rootGetters }) => {
    const conferenceHold = getters.getConferenceData?.patient.on_hold ?? false;
    const taskHold = rootGetters.getTaskWithOngoingCallForCurrentUser?.isOnHold() ?? false;

    return conferenceHold || taskHold;
  }),
};

const actions = {
  async INVITE_TO_CONFERENCE_ACTION({ rootGetters }, workstationId) {
    const callId = rootGetters.getOngoingCallForCurrentUser.id;

    await phoneApi.withCall(callId).inviteToConference(workstationId);
  },

  async REMOVE_RECEPTION_FROM_CONFERENCE_ACTION({ rootGetters }) {
    return removeFromConference(rootGetters, ConferenceParticipantType.Reception);
  },

  async REMOVE_TARGET_FROM_CONFERENCE_ACTION({ rootGetters }) {
    return removeFromConference(rootGetters, ConferenceParticipantType.Target);
  },

  async HOLD_PATIENT_CALL_ACTION({ rootGetters }) {
    const callId = rootGetters.getOngoingCallForCurrentUser.id;

    await phoneApi.withCall(callId).holdPatient();
  },

  async UNHOLD_PATIENT_CALL_ACTION({ rootGetters }) {
    const callId = rootGetters.getOngoingCallForCurrentUser.id;

    await phoneApi.withCall(callId).unholdPatient();
  },
};

const conferenceModule = {
  namespaced: true,
  actions,
  getters,
};

export function registerConferenceModule(store) {
  store.registerModule(['phoneWidget', 'conference'], conferenceModule);
}
