import { injectable } from 'tsyringe';
import type { CallDuration } from '@/modules/phone-widget/domain/webphone/call-management/CallDuration';
import { webphoneStoreName } from '../../../ui/store/webphone';
import type { StoreAdapter } from '../../StoreAdapter';
import { StoreAdapterFactory } from '../../StoreAdapterFactory';

@injectable()
export class StoreCallDuration implements CallDuration {
  private storeAdapter: StoreAdapter;

  constructor(storeAdapterFactory: StoreAdapterFactory) {
    this.storeAdapter = storeAdapterFactory.make(webphoneStoreName);
  }

  get get(): number | null {
    return this.getter('activeCallDuration');
  }

  private get currentTimestamp(): number {
    return this.getter('currentTimestamp');
  }

  start(): void {
    this.commit('START_MEASURE_CALL_DURATION', this.currentTimestamp);
  }

  stop(): void {
    this.commit('STOP_MEASURE_CALL_DURATION');
  }

  private commit(name: string, payload?: any): void {
    this.storeAdapter.commit(name, payload);
  }

  private getter(name: string): any {
    return this.storeAdapter.getter(name);
  }
}
