<template>
  <i :class="iconClass">
    <svg width="21" height="18" viewBox="0 0 21 18" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(3.7795276,0,0,3.7795276,-380.81836,-554.03713)">
        <path
          d="m 102.6635,146.58899 c -1.05204,0 -1.90531,0.85276 -1.90531,1.90479 0,0.35738 0.10059,0.69037 0.27182,0.97617 l -0.25477,0.92862 0.94878,-0.24856 c 0.27739,0.15775 0.59761,0.24856 0.93948,0.24856 0.69187,0 1.29723,-0.36852 1.63091,-0.92036 -0.007,-0.004 -0.0143,-0.008 -0.0212,-0.0129 -0.0645,-0.0423 -0.11491,-0.10274 -0.14521,-0.17364 -0.0306,-0.071 -0.0387,-0.14941 -0.0243,-0.22531 0.0144,-0.0759 0.0508,-0.14538 0.10542,-0.19998 l 5.2e-4,5.1e-4 c 0.0366,-0.0367 0.0806,-0.0654 0.12867,-0.0848 0.0478,-0.0197 0.0989,-0.0288 0.15038,-0.0279 l 0.062,5.1e-4 c 0.0117,-0.0853 0.0176,-0.17245 0.0176,-0.26096 0,-1.05203 -0.85276,-1.90479 -1.90479,-1.90479 z m -0.65216,1.01595 c 0.031,0 0.063,-1.4e-4 0.0904,0.001 0.034,8e-4 0.0705,0.003 0.10593,0.0817 0.0421,0.093 0.13398,0.32619 0.14573,0.34985 0.0118,0.0236 0.0197,0.0517 0.004,0.0827 -0.0156,0.0318 -0.0237,0.051 -0.0465,0.0791 -0.0237,0.0273 -0.0494,0.0615 -0.0708,0.0822 -0.0237,0.0237 -0.048,0.0495 -0.0207,0.0966 0.0273,0.0471 0.12182,0.20181 0.262,0.3266 0.18018,0.16097 0.33251,0.21043 0.37982,0.23409 0.0473,0.0237 0.0745,0.0199 0.10181,-0.0119 0.0281,-0.0309 0.11811,-0.13718 0.14986,-0.18449 0.031,-0.0473 0.0625,-0.0393 0.10542,-0.0238 0.0435,0.0156 0.27567,0.12983 0.32298,0.15348 0.0473,0.0237 0.078,0.0355 0.0899,0.0548 0.0122,0.0198 0.0123,0.1141 -0.0269,0.22427 -0.0392,0.11002 -0.23196,0.2163 -0.31832,0.22376 -0.0871,0.008 -0.1684,0.0394 -0.56638,-0.1173 -0.48007,-0.18907 -0.7825,-0.68087 -0.80615,-0.71262 -0.0237,-0.031 -0.19224,-0.25554 -0.19224,-0.48731 0,-0.23257 0.12163,-0.34648 0.16433,-0.39378 0.0435,-0.0473 0.0943,-0.0589 0.12609,-0.0589 z"
        />
        <path
          d="m 104.23301,149.09028 c 0.0102,-0.05 0.0342,-0.0959 0.0701,-0.1319 0.0242,-0.0242 0.0529,-0.0433 0.0845,-0.0562 0.0316,-0.0129 0.0655,-0.0193 0.0997,-0.0187 h 1.44533 c 0.0677,1e-5 0.13272,0.0269 0.18063,0.0749 0.0479,0.048 0.0748,0.11306 0.0748,0.18092 v 1.44765 c 6.9e-4,0.034 -0.005,0.0678 -0.0179,0.0995 -0.0125,0.0317 -0.0312,0.0605 -0.0551,0.0849 -0.0237,0.0243 -0.0522,0.0437 -0.0835,0.0569 -0.0313,0.0132 -0.065,0.02 -0.099,0.02 -0.034,0 -0.0676,-0.007 -0.099,-0.02 -0.0313,-0.0132 -0.0598,-0.0325 -0.0835,-0.0569 -0.0237,-0.0243 -0.0425,-0.0532 -0.055,-0.0849 -0.0125,-0.0317 -0.0186,-0.0655 -0.0179,-0.0995 v -0.82986 l -1.5512,1.55368 c -0.0236,0.0243 -0.0518,0.0437 -0.083,0.057 -0.0311,0.0133 -0.0646,0.0203 -0.0984,0.0205 -0.0339,2.4e-4 -0.0674,-0.006 -0.0987,-0.0192 -0.0313,-0.0129 -0.0598,-0.0319 -0.0837,-0.0558 -0.0239,-0.024 -0.0429,-0.0525 -0.0558,-0.0838 -0.0129,-0.0314 -0.0193,-0.065 -0.0191,-0.0989 2.3e-4,-0.0339 0.007,-0.0674 0.0205,-0.0986 0.0133,-0.0312 0.0326,-0.0594 0.0569,-0.0831 l 1.5512,-1.55367 h -0.82852 c -0.0509,6.9e-4 -0.10069,-0.0138 -0.14326,-0.0416 -0.0426,-0.0279 -0.0758,-0.0677 -0.0956,-0.11468 -0.0198,-0.0469 -0.0252,-0.0986 -0.0155,-0.14859 z"
        />
      </g>
    </svg>
  </i>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import BaseIcon from '@dp-ui-kit/icons/vue/BaseIcon.vue';

export default defineComponent({
  name: 'OutboundWhatsAppIcon',
  extends: BaseIcon as any,
  iconName: 'outbound-whats-app',
});
</script>
