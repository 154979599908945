import { injectable } from 'tsyringe';
import type { HttpBookingSlot } from '@/modules/book-visit/adapters/booking-slot/HttpBookingSlot';
import { BookingSlot } from '@/modules/book-visit/domain/booking-slot/BookingSlot';

@injectable()
export class HttpBookingSlotFactory {
  make(booking: HttpBookingSlot, serviceId: string): BookingSlot {
    return new BookingSlot(booking.start, booking.booked, booking.quantum, serviceId);
  }
}
