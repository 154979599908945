import { injectable } from 'tsyringe';
import { Call } from '../../domain/Call';
import { StorePatientRepository } from './StorePatientRepository';
import type { StoreCall } from './types/StoreCall';

@injectable()
export class CallFactory {
  constructor(private readonly patientRepository: StorePatientRepository) {}

  make(storeCall: StoreCall): Call {
    const patient = this.patientRepository.get(storeCall.patientId);

    if (!patient) {
      throw new Error(`Patient with id ${storeCall.patientId} not found`);
    }

    return new Call(
      storeCall.id,
      storeCall.taskId,
      storeCall.status,
      storeCall.direction,
      patient,
      storeCall.workstation_id,
    );
  }

  makeMany(storeCalls: StoreCall[]): Call[] {
    return storeCalls.map(storeCall => this.make(storeCall), this);
  }
}
