import { HttpClientApi } from '@/modules/http-client/public/api';
import { injectable } from 'tsyringe';
import type { PerformanceDataRepository } from '../../domain/overview-performance/PerformanceDataRepository';
import type { PerformanceData } from '../../domain/overview-performance/PerformanceData';
import type { PerformanceDataRecord } from '../../domain/overview-performance/PerformanceDataRecord';
import { PerformanceDataFactory } from './PerformanceDataFactory';
import type { InputPerformanceData } from './InputPerformanceData';

@injectable()
export class HttpPerformanceDataRepository implements PerformanceDataRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly performanceDataFactory: PerformanceDataFactory,
  ) {}

  async get(
    start: string,
    end: string,
    previousStart: string,
    previousEnd: string,
  ): Promise<PerformanceData> {
    const [currentInputPerformanceData, previousInputPerformanceData] = await Promise.all([
      this.httpClient.get<InputPerformanceData>('/api/v0/facility/{facilityId}/stats/performance', {
        start,
        end,
      }),
      this.httpClient.get<InputPerformanceData>('/api/v0/facility/{facilityId}/stats/performance', {
        start: previousStart,
        end: previousEnd,
      }),
    ]);

    return this.performanceDataFactory.make(
      currentInputPerformanceData,
      previousInputPerformanceData,
    );
  }

  async getSingle(start: string, end: string): Promise<PerformanceDataRecord> {
    const data = await this.httpClient.get<InputPerformanceData>(
      '/api/v0/facility/{facilityId}/stats/performance',
      {
        start,
        end,
      },
    );

    return this.performanceDataFactory.makeSingleRecord(data);
  }
}
