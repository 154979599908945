<template>
  <i :class="iconClass">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 3a1 1 0 0 1 2 0v1h4V3a1 1 0 1 1 2 0v1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2V3Zm5.9 2.854a.5.5 0 0 0-.707 0l-.414.414 2.123 2.122.414-.414a.5.5 0 0 0 0-.707L9.9 5.854Zm.336 3.203L8.113 6.935 5.212 9.836a.2.2 0 0 0-.058.123l-.182 1.959a.2.2 0 0 0 .212.218l2.024-.122a.2.2 0 0 0 .13-.058l2.898-2.899Z"
      />
    </svg>
  </i>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import BaseIcon from '@dp-ui-kit/icons/vue/BaseIcon.vue';

export default defineComponent({
  name: 'AppointmentEditedIcon',
  extends: BaseIcon as any,
  iconName: 'appointment-edited',
});
</script>
