export class AccessToken {
  public readonly tokenType = 'Bearer';

  private static readonly expirationOffset = 60 * 1000;

  private constructor(
    readonly accessToken: string,
    readonly refreshToken: string,
    readonly expiresIn: number,
    readonly expiresAt: number = Date.now() + expiresIn * 1000,
  ) {}

  get isExpired(): boolean {
    return this.expiresAt - AccessToken.expirationOffset <= Date.now();
  }

  get authorizationHeader(): string {
    return `${this.tokenType} ${this.accessToken}`;
  }

  static make(accessToken: string, refreshToken: string, expiresIn: number): AccessToken {
    return new AccessToken(accessToken, refreshToken, expiresIn);
  }

  static recreate(
    accessToken: string,
    refreshToken: string,
    expiresIn: number,
    expiresAt: number,
  ): AccessToken {
    return new AccessToken(accessToken, refreshToken, expiresIn, expiresAt);
  }
}
