type WorkstationType = 'pstn' | 'sip' | 'mobile' | 'web';
type WorkstationMode = 'never' | 'always' | 'backup' | 'signed-in';

export class Workstation {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly type: WorkstationType,
    readonly mode: WorkstationMode,
  ) {}

  get isReception(): boolean {
    return ['always', 'backup', 'signed-in'].includes(this.mode);
  }

  get isNonReception(): boolean {
    return this.mode === 'never';
  }
}
