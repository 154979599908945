import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { WorkstationReadRepository } from '../../domain/workstation/WorkstationReadRepository';
import type { Workstation } from '../../domain/workstation/Workstation';
import { WorkstationFactory } from './WorkstationFactory';
import type { InputWorkstation } from './types/InputWorkstation';

interface Store {
  getters: {
    ['settings/reception/getAllReceptionPhones']: InputWorkstation[];
  };
}

@injectable()
export class StoreWorkstationReadRepository implements WorkstationReadRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
    private readonly workstationFactory: WorkstationFactory,
  ) {}

  async get(): Promise<Workstation[]> {
    const workstations = this.store.getters['settings/reception/getAllReceptionPhones'];

    return this.workstationFactory.makeMany(workstations);
  }
}
