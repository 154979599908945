import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { CountryCodeProviderToken } from '../token';
import { StoreCountryCodeProvider } from '../../adapters/service/StoreCountryCodeProvider';
import type { CountryCodeProvider } from '../../domain/service/CountryCodeProvider';

export class CountryCodeProviderProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<CountryCodeProvider>(CountryCodeProviderToken, StoreCountryCodeProvider);
  }
}
