export class SentryIgnoredErrors {
  private readonly ignoredErrors: RegExp[];

  constructor() {
    this.ignoredErrors = [
      SentryIgnoredErrors.NavigationRedirect,
      SentryIgnoredErrors.RedundantNavigation,
      SentryIgnoredErrors.NavigationCanceled,
      SentryIgnoredErrors.ChunkLoad,
      SentryIgnoredErrors.ResizeObserver,
      SentryIgnoredErrors.ResizeObserverNotification,
      SentryIgnoredErrors.FailedToFetch,
      SentryIgnoredErrors.ModuleLoading,
      ...SentryIgnoredErrors.AxiosIrrelevantErrors,
      ...SentryIgnoredErrors.WistiaPlayerAssets,
      ...SentryIgnoredErrors.OauthErrors,
    ];
  }

  get(): RegExp[] {
    return this.ignoredErrors;
  }

  // https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378`
  static NavigationRedirect =
    /^Redirected when going from "[\w/-]+" to "[\w/-]+" via a navigation guard\.$/;

  static RedundantNavigation = /^Avoided redundant navigation to current location: "[\w/-]+"\.$/;

  static NavigationCanceled =
    /^Navigation cancelled from "[\w/-]+" to "[\w/-]+" with a new navigation\.$/;

  static ChunkLoad = /^Loading (CSS )?chunk [-\d\w~]+ failed\./;

  /**
   * https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
   */
  static ResizeObserver = /^ResizeObserver loop limit exceeded$/;

  static ResizeObserverNotification =
    /^ResizeObserver loop completed with undelivered notifications.$/;

  static FailedToFetch = /^Failed to fetch$/;

  static ModuleLoading = /^Importing a module script failed.$/;

  static AxiosIrrelevantErrors = [/^Network Error$/, /^No error message$/];

  static OauthErrors = [
    /^There is no way of getting OAuth token for this request$/,
    /^Invalid refresh token$/,
  ];

  static WistiaPlayerAssets = [
    /^Failed to fetch dynamically imported module: https:\/\/fast\.wistia\.com\/assets\/external\/.*/,
    /^error loading dynamically imported module: https:\/\/fast\.wistia\.com\/assets\/external\/.*/,
  ];
}
