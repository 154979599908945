import type { Timeline } from '@/modules/task/domain/timeline/Timeline';
import type { IconStrategy } from '../types';
import type { Task } from '../../task/Task';
import { IconName } from '../types';

export class IncomingUnanswered implements IconStrategy {
  get(task: Task, { lastCall }: Timeline): null | IconName {
    return lastCall.isUnanswered && lastCall.isIncoming ? IconName.IncomingUnanswered : null;
  }
}
