import { inject, injectable } from 'tsyringe';
import { TimelineRepositoryToken, TaskReadRepositoryToken } from '../../../di/tokens';
import type { TaskReadRepository } from '../../task/TaskReadRepository';
import type { TimelineRepository } from '../../timeline/TimelineRepository';
import type { CallTracker } from './CallTracker';
import type { Task } from '../../task/Task';
import { CallTrackerResponse } from './CallTrackerResponse';
import type { Call } from '../../call/types/Call';

@injectable()
export class CallTrackerDispatcher {
  constructor(
    @inject(TaskReadRepositoryToken)
    private readonly taskReadRepository: TaskReadRepository,
    @inject(TimelineRepositoryToken)
    private readonly timelineRepository: TimelineRepository,
  ) {}

  private trackers: CallTracker[] = [];

  addTracker(tracker: CallTracker): void {
    this.trackers.push(tracker);
  }

  dispatch(taskId: string): void {
    if (this.hasNoTrackers) {
      return;
    }

    const entities = this.getEntities(taskId);

    if (!entities) {
      return;
    }

    this.trackers.forEach(tracker => {
      const result = tracker.track(entities.call, entities.task);

      if (CallTrackerResponse.EventPublished === result) {
        this.removeTracker(tracker);
      }
    });
  }

  private getEntities(taskId: string): { task: Task; call: Call } | null {
    const task = this.taskReadRepository.get(taskId);

    const { lastCall } = this.timelineRepository.get(taskId);

    return { task, call: lastCall };
  }

  private removeTracker(tracker: CallTracker): void {
    this.trackers = this.trackers.filter(item => item !== tracker);
  }

  private get hasNoTrackers(): boolean {
    return this.trackers.length === 0;
  }
}
