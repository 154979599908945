<template>
  <div :class="$b({ color })">
    <dp-icon-alarm-clock :class="$b('icon')" />
    <date :class="$b('date')" :date="date" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { DpIconAlarmClock } from '@dp-ui-kit/icons';
import { includes } from 'lodash-es';

import { Date } from '@/ui/atoms';
import type { DateInterface } from '@/ui/atoms/Date/Date.types';
import { ReminderBadgeColors } from './ReminderBadge.types';

@Component({
  name: 'ReminderBadge',
  components: {
    DpIconAlarmClock,
    Date,
  },
})
export default class ReminderBadge extends Vue {
  @Prop({
    type: String,
    default: ReminderBadgeColors.Primary,
    validator(value: string) {
      return includes(ReminderBadgeColors, value);
    },
  })
  readonly color: string;

  @Prop({ type: Object, required: true })
  readonly date: DateInterface;
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

$reminder-badge-colors: (
  color_danger: v.$danger,
  color_primary: v.$primary,
);

.dp-reminder-badge {
  display: inline-flex;
  align-items: center;
  font-size: v.$font-size-sm;

  @each $type, $color in $reminder-badge-colors {
    &--#{$type} {
      color: $color;
    }
  }

  &__icon {
    margin-right: v.$spacer-sm;
  }

  :deep(svg) {
    fill: currentColor;
  }
}
</style>
