import type { TaskFeedFetcher } from '@/modules/tasks-list/domain/task-feed/TaskFeedFetcher';
import { TaskFeed } from '@/modules/tasks-list/domain/task-feed/TaskFeed';
import { inject, injectable } from 'tsyringe';
import type { TaskRepository } from '@/modules/tasks-list/domain/task-feed/TaskRepository';
import { OwnershipFilter } from '@/modules/tasks-list/domain/ownership-filter/OwnershipFilter';
import { TaskEntityManagerToken, TaskRepositoryToken } from '@/modules/tasks-list/di/tokens';
import type { TaskEntityManager } from '@/modules/tasks-list/domain/task-feed/TaskEntityManager';

@injectable()
export class InboxTaskFeedFetcher implements TaskFeedFetcher {
  constructor(
    @inject(TaskRepositoryToken)
    private readonly taskFeedRepository: TaskRepository,
    @inject(TaskEntityManagerToken)
    private readonly taskEntityManager: TaskEntityManager,
    private readonly ownershipFilter: OwnershipFilter,
  ) {}

  async fetch(): Promise<TaskFeed> {
    const tasks = await this.taskFeedRepository.getInboxPage(
      this.ownershipFilter.getCurrentOption(),
    );

    const ids = await this.taskEntityManager.storeEntities(tasks);

    return new TaskFeed(ids);
  }
}
