import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { RootCacheToken } from '@/common/root/providers/RootCacheProvider';
import { ChannelsCacheToken } from '@/modules/settings/di/token';

export class CacheProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.registerSingleton(ChannelsCacheToken, RootCacheToken);
  }
}
