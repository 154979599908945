import { injectable } from 'tsyringe';
import type { InputMeResponse } from './types/InputMeResponse';
import type { UserAndFacilityData } from '../../domain/login/me/models/UserAndFacilityData';
import type { UserData } from '../../domain/login/me/models/UserData';
import type { PusherConfiguration } from '../../domain/login/me/models/PusherConfiguration';
import type { UserAvatar } from '../../domain/login/me/models/UserAvatar';
import type { FacilityName } from '../../domain/login/me/models/FacilityName';
import type { InputPusherConfig } from './types/InputPusherConfig';
import type { InputFacility } from './types/InputFacility';
import type { InputUser } from './types/InputUser';

@injectable()
export class UserAndFacilityDataFactory {
  make(inputUserAndFacilityData: InputMeResponse): UserAndFacilityData {
    return {
      user: this.makeUserData(inputUserAndFacilityData.me),
      pusherConfiguration: this.makePusherConfiguration(inputUserAndFacilityData.pusher),
      facilities: this.makeManyFacilityNames(inputUserAndFacilityData.facilities),
      userRoles: [...inputUserAndFacilityData.roles],
      legacyResponse: inputUserAndFacilityData, // todo remove legacyResponse from UserAndFacilityData
    };
  }

  private makeUserData({
    id,
    name,
    username,
    has_password,
    created_at,
    email,
    phone_number,
    avatar,
  }: InputUser): UserData {
    const userAvatar: UserAvatar | undefined = avatar
      ? { id: avatar.id, name: avatar.name, url: avatar.url }
      : undefined;

    return {
      id,
      name,
      username,
      createdAt: created_at,
      hasPassword: has_password,
      email,
      phoneNumber: phone_number,
      avatar: userAvatar,
    };
  }

  private makeFacilityName({ id, name }: InputFacility): FacilityName {
    return {
      id,
      name,
    };
  }

  private makeManyFacilityNames(inputFacilities: InputFacility[]): FacilityName[] {
    return inputFacilities.map(this.makeFacilityName);
  }

  private makePusherConfiguration({ app_key, cluster }: InputPusherConfig): PusherConfiguration {
    return {
      appKey: app_key,
      cluster,
    };
  }
}
