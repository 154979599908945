import { inject, injectable } from 'tsyringe';
import { DateFormatterToken } from '../../di/token';
import type { DateFormatter } from '../../domain/DateFormatter';
import type { WorkingHourOverride } from '../../domain/working-hours/WorkingHourOverride';
import type { OutputWorkingHourOverride } from './types/OutputWorkingHourOverride';

@injectable()
export class OutputWorkingHourOverrideFactory {
  constructor(
    @inject(DateFormatterToken)
    private readonly dateFormatter: DateFormatter,
  ) {}

  make(workingHourOverride: WorkingHourOverride): OutputWorkingHourOverride {
    if (workingHourOverride.workingHour.open === false) {
      return this.makeClosed(workingHourOverride);
    }

    return this.makeChangedWorkingHours(workingHourOverride);
  }

  private makeClosed(workingHourOverride: WorkingHourOverride): OutputWorkingHourOverride {
    return {
      open: workingHourOverride.workingHour.open,
      date: this.parseFromDate(workingHourOverride.date),
      start: null,
      end: null,
      siesta_start: null,
      siesta_end: null,
    };
  }

  private makeChangedWorkingHours(
    workingHourOverride: WorkingHourOverride,
  ): OutputWorkingHourOverride {
    return {
      open: workingHourOverride.workingHour.open,
      date: this.parseFromDate(workingHourOverride.date),
      start: workingHourOverride.workingHour.start,
      end: workingHourOverride.workingHour.end,
      siesta_start: workingHourOverride.workingHour.siestaStart,
      siesta_end: workingHourOverride.workingHour.siestaEnd,
    };
  }

  private parseFromDate(date: Date): string {
    return this.dateFormatter.asDayString(date);
  }
}
