import { Component } from '@/app/app-status/component/Component';
import type { AxiosStatic } from 'axios';
import { AxiosError } from 'axios';
import config from '@/config/config.json';
import { NetworkError } from '@/app/app-status/errors/NetworkError';

interface ApiComponentResponse {
  data: {
    components: Component[];
  };
}

export class ComponentRepository {
  constructor(readonly axiosClient: AxiosStatic) {}

  async get(): Promise<Component[]> {
    const dataSourceUrl = `${config.STATUSPAGE_URL}/api/v2/summary.json`;

    try {
      const { data } = (await this.axiosClient.get(dataSourceUrl)) as ApiComponentResponse;

      return data.components.map(Component.make);
    } catch (e) {
      if (e instanceof AxiosError) {
        throw new NetworkError();
      }

      throw e;
    }
  }
}
