import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import type { Store } from 'vuex';
import { WaitingCall } from '../domain/WaitingCall';
import { WorkstationUser } from '../domain/Workstation';
import { WaitingCallChecker } from '../domain/WaitingCallChecker';
import { ErrorSenderToken } from '../di/tokens';
import type { ErrorSender } from '../domain/ErrorSender';
import { WaitingCallError } from '../domain/WaitingCallError';

@injectable()
export class StoreWaitingCallWatcher {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store<any>,
    private readonly waitingCallChecker: WaitingCallChecker,
    @inject(ErrorSenderToken)
    private readonly errorSender: ErrorSender,
  ) {}

  watch(): void {
    const filterWaitingAnInProgress = (call: any) =>
      (call.status === 'waiting' || call.status === 'in-progress') &&
      (call.workstation_id || call.personnel);

    this.store.watch(
      state => Object.values(state.entities?.call ?? {}).filter(filterWaitingAnInProgress),
      calls => {
        const timer1 = performance?.now();
        calls
          .filter(filterWaitingAnInProgress)
          .map(call => this.buildWaitingCall(call))
          .forEach(waitingCall => {
            this.waitingCallChecker.check(waitingCall);
          });

        if (performance && performance.now() - timer1 > 30) {
          this.errorSender.send(
            new WaitingCallError('Calculation time of call check exceed 30ms'),
            {
              performance: { time: performance.now() - timer1 },
            },
          );
        }
      },
    );
  }

  private buildWaitingCall(call: any): WaitingCall {
    if (call.personnel) {
      const workstation: any = Object.values(this.store.state.entities.receptionPhone ?? {}).find(
        ({ current_user }: any) => current_user === call.personnel,
      );

      return new WaitingCall(
        call.id,
        call.status,
        new WorkstationUser(workstation.id, workstation.current_user, workstation.mode),
      );
    }

    const workstation: any = Object.values(this.store.state.entities.receptionPhone ?? {}).find(
      ({ id }: any) => id === call.workstation_id,
    );

    return new WaitingCall(
      call.id,
      call.status,
      new WorkstationUser(workstation.id, workstation.mode, workstation.current_user),
    );
  }
}
