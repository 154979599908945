import type { DpProvider } from '@/core/di/DpProvider';
import { RootStoreToken } from '@/common/root';
import type { RootState } from '@/store/types';
import { inject, injectable } from 'tsyringe';
import type { Store } from 'vuex';
import { trafficControl, trafficControlName } from '../../ui/store/traffic-control';

@injectable()
export class StoreBootProvider implements DpProvider {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store<RootState>,
  ) {}

  boot(): void {
    this.store.registerModule(trafficControlName, trafficControl);
  }
}
