import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { UpcomingVisitRepository } from '@/modules/book-visit/domain/visit/UpcomingVisitRepository';
import type { UpcomingVisit } from '@/modules/book-visit/domain/visit/UpcomingVisit';
import type { StoreUpcomingVisit } from '@/modules/book-visit/adapters/visit/StoreUpcomingVisit';
import { StoreUpcomingVisitFactory } from '@/modules/book-visit/adapters/visit/StoreUpcomingVisitFactory';

interface Store {
  getters: {
    ['upcomingVisits/findUpcomingVisitOutput']: (phoneNumber: string) => StoreUpcomingVisit | null;
  };
}

@injectable()
export class StoreUpcomingVisitRepository implements UpcomingVisitRepository {
  constructor(@inject(RootStoreToken) private readonly store: Store) {}

  findByPatientPhoneNumber(phoneNumber: string): UpcomingVisit | null {
    return StoreUpcomingVisitFactory.tryMake(
      this.store.getters['upcomingVisits/findUpcomingVisitOutput'](phoneNumber),
    );
  }
}
