import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import type { DateRangeRepository } from '../../domain/filters/DateRangeRepository';
import type { DateRange } from '../../domain/filters/DateRange';

interface Store {
  getters: {
    getFilterDateRange: [string, string];
  };
}

@injectable()
export class StoreDateRangeRepository implements DateRangeRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
  ) {}

  get(): DateRange {
    return this.store.getters.getFilterDateRange;
  }
}
