import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import type { WorkingHourList } from '@/modules/settings/domain/working-hours/regular-schedule/WorkingHourList';
import type { WorkingHourListReadRepository } from '@/modules/settings/domain/working-hours/regular-schedule/WorkingHourListReadRepository';
import { WorkingHourListFactory } from './WorkingHourListFactory';
import type { InputStoreWorkingHour } from './types/InputStoreWorkingHour';

interface Store {
  getters: {
    workingHours: InputStoreWorkingHour[];
  };
}

@injectable()
export class StoreWorkingHourListReadRepository implements WorkingHourListReadRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly rootStore: Store,
    private readonly workingHourListFactory: WorkingHourListFactory,
  ) {}

  async get(): Promise<WorkingHourList | null> {
    const inputWorkingHours = this.rootStore.getters['settings/workingHours/workingHours'];
    return this.workingHourListFactory.fromStore(inputWorkingHours);
  }
}
