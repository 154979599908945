export interface WaitingMusicUrlInput {
  waiting_music: string;
}

export class WaitingMusicUrl {
  readonly waitingMusic: string;

  constructor({ waiting_music }: WaitingMusicUrlInput) {
    this.waitingMusic = waiting_music;
  }

  static make(data: WaitingMusicUrlInput): WaitingMusicUrl {
    return new WaitingMusicUrl(data);
  }
}
