import type {
  PatientRequestViewTasksFilter,
  RequestType,
} from '@/modules/tasks-list/domain/task-feed/views/PatientRequestViewTasksFilter';
import { injectable } from 'tsyringe';
import type { TaskType } from '@/modules/tasks-list/domain/task/TaskType';

export interface HttpPatientRequest {
  type: 'prescription' | 'refillPrescription' | 'certificate' | 'testResults' | 'other';
}

export interface HttpTask {
  id: string;
  type: TaskType;
  patient_requests?: HttpPatientRequest[];
}

@injectable()
export class HttpPatientRequestViewTasksFilter implements PatientRequestViewTasksFilter {
  byType(tasks: HttpTask[], type: RequestType): HttpTask[] {
    return tasks.filter(task =>
      task.patient_requests?.some(request => request.type === this.mapRequestType(type)),
    );
  }

  private mapRequestType(type: RequestType): HttpPatientRequest['type'] {
    switch (type) {
      case 'prescription':
        return 'prescription';
      case 'refill-prescription':
        return 'refillPrescription';
      case 'certificate':
        return 'certificate';
      case 'test-results':
        return 'testResults';
      case 'other':
        return 'other';
      default:
        throw new Error(`Unknown request type: ${type}`);
    }
  }
}
