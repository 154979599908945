import type { User } from '@/modules/authentication/domain/login/User';
import type { Store } from 'vuex';
import type { RootState } from '@/store/types';
import { UserRole } from '@/app/user-roles';
import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';

@injectable()
export class StoreUser implements User {
  constructor(@inject(RootStoreToken) private readonly store: Store<RootState>) {}

  canSwitchFacility(): boolean {
    return this.store.getters.hasRole(UserRole.FacilitySwitcher);
  }

  hasNoFacility(): boolean {
    return this.store.getters.hasNoFacility;
  }

  hasToSelectPhone(): boolean {
    return this.store.getters.needsToSelectPhone;
  }
}
