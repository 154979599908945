import type { Timeline } from '@/modules/task/domain/timeline/Timeline';
import { WhatsAppMessage } from '@/modules/task/domain/message/WhatsAppMessage';
import type { IconStrategy } from '../types';
import { IconName } from '../types';
import type { Task } from '../../task/Task';

export class IncomingWhatsAppMessage implements IconStrategy {
  get(task: Task, { lastUserEvent }: Timeline): null | IconName {
    if (!(lastUserEvent instanceof WhatsAppMessage)) {
      return null;
    }

    return lastUserEvent.isInbound ? IconName.IncomingWhatsAppMessage : null;
  }
}
