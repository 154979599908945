import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import type { FacilityTimeZone } from '@/modules/tasks-list/domain/task-expiration/FacilityTimeZone';

interface Store {
  getters: {
    getFacilityTimeZone: string;
  };
}

@injectable()
export class StoreFacilityTimeZone implements FacilityTimeZone {
  constructor(@inject(RootStoreToken) private readonly store: Store) {}

  get(): string {
    return this.store.getters.getFacilityTimeZone;
  }
}
