export class Patient {
  constructor(
    readonly id: string,
    private readonly name: string,
    private readonly surname: string,
    readonly phoneNumber: string,
  ) {}

  get displayedName(): string {
    const fullName = `${this.name} ${this.surname}`.trim();

    return fullName || this.phoneNumber;
  }

  static ofTest(): Patient {
    return new Patient('1', 'John', 'Doe', '');
  }
}
