import { resolve } from '@/core/di';
import {
  PanelControllerToken,
  PatientRepositoryToken,
  WorkstationRepositoryToken,
} from '../../di/tokens';
import type { WorkstationRepository } from '../../domain/repositories/WorkstationRepository';
import type { PatientRepository } from '../../domain/repositories/PatientRepository';
import type { PanelController } from '../../domain/actions/PanelController';

export const workstationRepository = (): WorkstationRepository =>
  resolve(WorkstationRepositoryToken);
export const patientRepository = (): PatientRepository => resolve(PatientRepositoryToken);
export const panelController = (): PanelController => resolve(PanelControllerToken);
