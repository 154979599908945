import { RootTranslatorToken } from '@/common/root';
import type { Translator } from '@/common/root/domain/Translator';
import { inject, injectable } from 'tsyringe';
import type { ErrorMessageProvider } from '@/modules/book-visit/domain/errors/ErrorMessageProvider';
import { ErrorMessage } from '@/modules/book-visit/domain/errors/ErrorMessage';

@injectable()
export class TranslatedErrorMessageProvider implements ErrorMessageProvider {
  constructor(@inject(RootTranslatorToken) private readonly translator: Translator) {}

  get(errorMessage: ErrorMessage): string {
    switch (errorMessage) {
      case ErrorMessage.NoDoctors:
        return this.translate('book_visit.alert.no_doctor');
      case ErrorMessage.NoAddresses:
        return this.translate('book_visit.alert.no_address');
      case ErrorMessage.NoServices:
        return this.translate('book_visit.alert.no_service');
      case ErrorMessage.NoBookingSlots:
        return this.translate('book_visit.alert.no_booking_slots');
      default: {
        throw new Error(`There is unprocessed error message "${errorMessage}"`);
      }
    }
  }

  private translate(key: string): string {
    return this.translator.translate(key);
  }
}
