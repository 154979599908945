import type LocaleConfiguration from '@/config/locale-configuration.json';
import { getTopLevelDomain } from '@/utils/domain';

export interface ComponentIdDetectorInterface {
  getStatusPageComponentId(): string | null;
}

export class ComponentIdDetector implements ComponentIdDetectorInterface {
  constructor(private readonly config: typeof LocaleConfiguration) {}

  getStatusPageComponentId(): string | null {
    const domain = getTopLevelDomain();
    const config = this.config[domain] || this.config.com;

    return config.statusPageComponentId;
  }
}
