import type { NotificationsManagerInterface } from '@/common/root/domain/NotificationsManager';
import { NotificationsConfigFactory } from './NotificationsConfigFactory';

class NotificationsManager implements NotificationsManagerInterface {
  private subscriber: any;

  constructor(private readonly notificationsConfigFactory: NotificationsConfigFactory) {}

  registerSubscriber(callback: (...args: any[]) => any): void {
    this.subscriber = callback;
  }

  async fire({ name, ...args }: { name: string; [k: string]: any }): Promise<any> {
    const callbackToMake = this.subscriber;

    if (!callbackToMake) {
      return null;
    }

    return callbackToMake({
      ...this.notificationsConfigFactory.get(name),
      ...args,
    });
  }
}

export const notificationsManager = new NotificationsManager(new NotificationsConfigFactory());
