import { schema } from 'normalizr';
import { EntityTypes } from '@/api/types/entities';
import { userSchema } from '@/api/schemas/user-schema';
import { Media } from '@/api/models/Media';

export const callSchema = new schema.Entity(
  EntityTypes.Call,
  {
    personnel: userSchema,
    recording: Media.schema,
    voicemail: Media.schema,
  },
  {
    processStrategy(value, parent) {
      return {
        workstation_id: null,
        ...value,
        taskId: parent.id,
        patientId: parent.patientId,
      };
    },
  },
);
