import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import { $GET_ENTITIES_BY_ID } from '@dp-vue/entities';
import type { ContactForm } from '@/modules/timeline/domain/contact-form/ContactForm';
import type { Store } from '../Store';
import { StoreContactFormFactory } from './StoreContactFormFactory';
import type { StoreTask } from '../../../StoreTask';

@injectable()
export class StoreContactFormRepository {
  constructor(@inject(RootStoreToken) private readonly store: Store) {}

  get(task: StoreTask, timezone: string): ContactForm[] {
    if (!task.form_message) {
      return [];
    }

    const data = this.store.getters[$GET_ENTITIES_BY_ID]('formMessage', [task.form_message]);

    return data.map(contactForm => StoreContactFormFactory.make(contactForm, task, timezone));
  }
}
