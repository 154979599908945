import { inject, injectable } from 'tsyringe';
import type { TrackingFacilityEvent } from './TrackingFacilityEvent';
import {
  BrandFactoryToken,
  FacilityRepositoryToken,
  LanguageRepositoryToken,
  SubscriptionPlanRepositoryToken,
  UserRepositoryToken,
} from '../../di/tokens';
import type { UserRepository } from './repositories/UserRepository';
import type { FacilityRepository } from './repositories/FacilityRepository';
import type { BrandFactory } from './repositories/BrandFactory';
import type { LanguageRepository } from './repositories/LanguageRepository';
import type { SubscriptionPlanRepository } from './repositories/SubscriptionPlanRepository';

@injectable()
export class TrackingFacilityEventFactory {
  constructor(
    @inject(UserRepositoryToken)
    private readonly userRepository: UserRepository,
    @inject(FacilityRepositoryToken)
    private readonly facilityRepository: FacilityRepository,
    @inject(BrandFactoryToken)
    private readonly brandFactory: BrandFactory,
    @inject(LanguageRepositoryToken)
    private readonly languageRepository: LanguageRepository,
    @inject(SubscriptionPlanRepositoryToken)
    private readonly subscriptionPlanRepository: SubscriptionPlanRepository,
  ) {}

  async make(): Promise<TrackingFacilityEvent> {
    const user = this.userRepository.me();
    const facility = this.facilityRepository.get();
    const brand = this.brandFactory.make();
    const ietfLanguage = this.languageRepository.getIETFLanguage();
    const subscriptionPlan = await this.subscriptionPlanRepository.getPlan();

    return {
      event: 'facility_loaded',
      user_is_superadmin: false,
      // user
      user_name: user.name,
      user_email: user.email,
      user_id: user.id,
      user_is_manager: user.isManager,
      user_is_client_beta: user.roles.includes('ROLE_GATE_KEEPER_IS_CLIENT_BETA'),
      created_at: user.createdAt,
      // facility
      facility_id: facility.id,
      facility_name: facility.name,
      activation_date: facility.activationDate,
      docplanner_integration_method: facility.integrationMethod,
      docplanner_integration_saas_only: facility.saasOnly,
      workstation_limit: facility.workstationLimit,
      number_of_workstations: facility.numberOfWorkstations,
      number_of_webphone_workstations: facility.numberOfWebphoneWorkstations,
      'Facility subscription plan': subscriptionPlan,
      // other
      locale: ietfLanguage.name,
      brand: brand.name,
    };
  }

  async makeEmptyEvent(): Promise<TrackingFacilityEvent> {
    return {
      event: 'facility_unloaded',
      user_name: null,
      user_email: null,
      user_id: null,
      user_is_superadmin: false,
      user_is_manager: false,
      facility_name: null,
      facility_id: null,
      brand: null,
      locale: null,
      created_at: null,
      activation_date: null,
      docplanner_integration_method: 'none',
      docplanner_integration_saas_only: false,
      workstation_limit: null,
      number_of_workstations: 0,
      number_of_webphone_workstations: 0,
      'Facility subscription plan': null,
      user_is_client_beta: false,
    };
  }
}
