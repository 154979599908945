import { injectable } from 'tsyringe';
import type { StoredItem } from './StoredItem';
import type { StoredAppConfig } from '../models/StoredAppConfig';
import type { StoredItemFactory } from './StoredItemFactory';
import type { ConfigurationRepository } from './ConfigurationRepository';

@injectable()
export class StoredConfigurationRepository implements ConfigurationRepository {
  constructor(private readonly storedItemFactory: StoredItemFactory<StoredAppConfig>) {}

  get(): StoredAppConfig {
    return {
      apiUrl: this.getItemValue('apiUrl'),
      clientId: this.getItemValue('clientId'),
      clientSecret: this.getItemValue('clientSecret'),
      domain: this.getItemValue('domain'),
    };
  }

  save(storedConfig: StoredAppConfig): void {
    Object.entries(storedConfig).map(([key, value]) =>
      this.saveItem(key as keyof StoredAppConfig, value),
    );
  }

  clear(): void {
    this.getItem('apiUrl').clear();
    this.getItem('clientId').clear();
    this.getItem('clientSecret').clear();
    this.getItem('domain').clear();
  }

  private getItemValue<K extends keyof StoredAppConfig>(key: K): StoredAppConfig[K] {
    return this.getItem(key).value;
  }

  private getItem<K extends keyof StoredAppConfig>(key: K): StoredItem<StoredAppConfig[K]> {
    return this.storedItemFactory(key);
  }

  private saveItem<K extends keyof StoredAppConfig, T extends StoredAppConfig[K]>(
    key: K,
    value: T,
  ) {
    const item = this.getItem(key);
    item.set(value);
  }
}
