export enum ComponentStatus {
  Operational = 'operational',
  UnderMaintenance = 'under_maintenance',
  DegradedPerformance = 'degraded_performance',
  PartialOutage = 'partial_outage',
  MajorOutage = 'major_outage',
}

export const NOT_OPERATIONAL_STATUSES = [
  ComponentStatus.UnderMaintenance,
  ComponentStatus.DegradedPerformance,
  ComponentStatus.PartialOutage,
  ComponentStatus.MajorOutage,
];

export class Component {
  id: string;

  name?: string;

  description?: string;

  status: ComponentStatus;

  constructor({ id, name, status, description }: Component) {
    this.id = id;
    this.status = status;
    this.name = name;
    this.description = description;
  }

  static make(data: Component): Component {
    return new Component(data);
  }
}
