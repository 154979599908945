import type { Phone } from '@/api/types/entities/phone';
import type { Form } from '@/api/types/entities/form';
import type { Workstation } from '@/api/types/entities/workstation';
import type { Result } from '@/api/types/entities/result';
import type { Regulatory } from '@/api/types/entities/channel';
import type { DateString, UUID } from '@/api/types';
import type { WorkingHour } from '@/api/types/entities/working-hour';
import { facilitySchema } from '@/api/schemas/facility-schema';
import type { DocplannerIntegration } from '@/api/types/entities/docplanner-integration';
import type { FacilityCountryCode, Facility as InputFacility } from '@/api/types/entities/facility';
import { Permission } from '@/api/models/Permission';

export type FacilityId = UUID;
export interface Reception {
  limit?: number;
  phones: Workstation[];
}

export class Facility {
  static schema = facilitySchema;

  readonly id: FacilityId;

  readonly name: string;

  readonly timezone: string;

  readonly country: string;

  readonly country_code: FacilityCountryCode;

  readonly integrations: string[];

  readonly customer?: {
    activation_date?: DateString;
  };

  readonly docplanner_integration: DocplannerIntegration;

  readonly channels: Regulatory;

  readonly phones: Phone[];

  readonly forms: Form[];

  readonly reception: Reception;

  readonly results: Result[];

  readonly permissions: Permission[];

  readonly working_hours: WorkingHour[];

  readonly terms_url: string;

  readonly anonymized: boolean;

  readonly _acl: 'EDIT' | 'VIEW';

  readonly created_at: string;

  readonly hasTextChannel: boolean;

  readonly realtimeChannels: Record<'common' | 'pii', string>;

  private constructor(data: InputFacility) {
    this.id = data.id;
    this.name = data.name;
    this.timezone = data.timezone;
    this.country = data.country;
    this.country_code = data.country_code;
    this.created_at = data.created_at;
    this.integrations = data.integrations;
    this.channels = data.channels;
    this.phones = data.phones;
    this.forms = data.forms;
    this.reception = data.reception;
    this.results = data.results;
    this.permissions = data.permissions.map(Permission.make);
    this.working_hours = data.working_hours;
    this.terms_url = data.plan?.terms_url ?? '';
    this._acl = data._acl;
    this.customer = data.customer;
    this.docplanner_integration = data.docplanner_integration;
    this.anonymized = data.anonymized;
    this.hasTextChannel = data.channels.has_text_channel ?? false;
    this.realtimeChannels = data.realtime_channels;
  }

  get countryCode(): FacilityCountryCode {
    return this.country_code;
  }

  get workingHours(): WorkingHour[] {
    return this.working_hours;
  }

  get workstations(): Workstation[] {
    return this.reception.phones;
  }

  get createdAt(): string {
    return this.created_at;
  }

  get isSassIntegrated(): boolean {
    return this.docplanner_integration.method === 'sso';
  }

  static make(data: InputFacility): Facility {
    return new Facility(data);
  }
}
