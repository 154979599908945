import type { DpEvent } from '@/core/events/DpEvent';
import { DpEventName } from '../DpEventName';

export class SingularEvent implements DpEvent {
  readonly name = DpEventName.Singular;

  constructor(readonly externalName: string) {}

  equals(event: SingularEvent): boolean {
    return this.externalName === event.externalName;
  }
}
