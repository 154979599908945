<template>
  <i :class="iconClass">
    <svg width="22" height="19" viewBox="0 0 22 19" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1843 1.61666V7.21666C12.1843 8.09666 11.4643 8.81666 10.5843 8.81666H3.38433L0.184326 12.0167V1.61666C0.184326 0.736663 0.904326 0.0166626 1.78433 0.0166626H10.5843C11.4643 0.0166626 12.1843 0.736663 12.1843 1.61666Z"
      />
      <path
        d="M13.7843 3.21666H14.5843C15.4643 3.21666 16.1843 3.93666 16.1843 4.81666V12.0796L15.0072 13.2567L15.0029 12.5252C15.0051 12.2676 14.9566 12.0124 14.8609 11.7738C14.7636 11.5316 14.6195 11.3111 14.437 11.1248C14.2544 10.9386 14.0368 10.79 13.7967 10.688C13.5565 10.5859 13.2981 10.5322 13.0365 10.5306C12.7748 10.5291 12.5152 10.5798 12.273 10.6804C12.0308 10.781 11.8112 10.9294 11.6275 11.1169C11.4438 11.3045 11.3 11.527 11.2044 11.7712C11.1103 12.0117 11.0648 12.2681 11.07 12.5256L11.0717 12.8167H4.98433C4.10433 12.8167 3.38433 12.0967 3.38433 11.2167V10.4167H12.9843C13.4243 10.4167 13.7843 10.0567 13.7843 9.61666V3.21666Z"
      />
      <path
        d="M19.237 18.7042C19.3722 18.569 19.4634 18.3961 19.4992 18.2076C19.5349 18.0191 19.5135 17.8235 19.4376 17.646C19.3618 17.4684 19.235 17.3169 19.0734 17.2107C18.9118 17.1046 18.7228 17.0487 18.5306 17.0502L15.3963 17.0318L21.2301 11.198C21.3214 11.1093 21.394 11.0031 21.4436 10.8855C21.4931 10.768 21.5188 10.6415 21.5189 10.5134C21.5191 10.3854 21.4938 10.2582 21.4445 10.1394C21.3952 10.0206 21.3229 9.91253 21.2318 9.82143C21.1407 9.73032 21.0326 9.65802 20.9138 9.60872C20.795 9.55943 20.6678 9.53413 20.5397 9.53428C20.4117 9.53444 20.2852 9.56005 20.1677 9.60963C20.0501 9.65922 19.9439 9.73178 19.8552 9.8231L14.0213 15.6569L14.003 12.5226C14.0049 12.3941 13.9811 12.2661 13.933 12.1463C13.8849 12.0265 13.8135 11.9172 13.7229 11.8248C13.6324 11.7324 13.5245 11.6588 13.4057 11.6083C13.2868 11.5578 13.1594 11.5314 13.0308 11.5306C12.9022 11.5299 12.775 11.5548 12.6568 11.6039C12.5385 11.653 12.4315 11.7254 12.342 11.8167C12.2526 11.908 12.1824 12.0165 12.1357 12.1358C12.089 12.2551 12.0667 12.3827 12.0701 12.5113L12.1021 17.979C12.1036 18.2353 12.2069 18.4817 12.3892 18.664C12.5715 18.8463 12.8179 18.9496 13.0742 18.9511L18.5419 18.9831C18.6711 18.9856 18.7991 18.9623 18.9185 18.9144C19.0379 18.8664 19.1462 18.795 19.237 18.7042Z"
      />
    </svg>
  </i>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import BaseIcon from '@dp-ui-kit/icons/vue/BaseIcon.vue';

export default defineComponent({
  name: 'InboundChatIcon',
  extends: BaseIcon as any,
  iconName: 'inbound-chat',
});
</script>
