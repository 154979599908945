import { injectable } from 'tsyringe';
import { EventTrackerFacade } from '@/common/tracking-event/public/api';
import type { EventSender } from '../../domain/tracking/EventSender';

@injectable()
export class CommonEventSender implements EventSender {
  constructor(private readonly eventTrackedFacade: EventTrackerFacade) {}

  async send(
    name: string,
    extraProperties?: Record<string, string | number | boolean> | undefined,
  ): Promise<void> {
    this.eventTrackedFacade.publishBusinessEvent(name, extraProperties);
  }
}
