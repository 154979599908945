import type { Timeline } from '@/modules/task/domain/timeline/Timeline';
import { ContactForm } from '@/modules/task/domain/contact-form/ContactForm';
import type { IconStrategy } from '../types';
import type { Task } from '../../task/Task';
import { IconName } from '../types';

export class InboundForm implements IconStrategy {
  get(task: Task, { lastUserEvent }: Timeline): null | IconName {
    return lastUserEvent instanceof ContactForm ? IconName.InboundForm : null;
  }
}
