import type { IDpModule } from '@/core/modules/DpModule';
import type { DpProvider, IDpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { DpAppError } from './DpAppError';

export class DpModuleFactory {
  private initializedProviders = new Set<IDpProvider>();

  make(container: DependencyContainer, ModuleClass: IDpModule): void {
    const moduleInstance = new ModuleClass();

    moduleInstance.providers?.().forEach(Provider => {
      if (this.initializedProviders.has(Provider)) {
        throw new DpAppError(
          `You're trying to initialize the same Provider twice! (${Provider.name}})`,
        );
      }

      const provider = container.resolve<DpProvider>(Provider);

      provider.register?.(container);

      provider.boot?.(container);

      this.initializedProviders.add(Provider);
    });
  }
}
