import { CallStatus } from '@/api/models/Call';
import { Workstation } from './Workstation';
import type { Call } from './Call';
import type { Task } from './Task';
import type { TaskRepository } from './TaskRepository';
import type { User } from './User';
import type { WorkstationRepository } from './WorkstationRepository';

type StatusPriorities = {
  [key in CallStatus]?: number;
};

const ongoingStatuses = [CallStatus.Intro, CallStatus.Waiting, CallStatus.InProgress];
const statusPriorities: StatusPriorities = {
  [CallStatus.Intro]: 0,
  [CallStatus.Waiting]: 1,
  [CallStatus.InProgress]: 2,
};
const UnknownLowPriority = -1;

type CallFunction<T> = (call: Call) => T;
type TaskComparisonFunction = (task: Task) => number;

function isOngoing(call: Call) {
  return ongoingStatuses.includes(call.status);
}
function findMostRecentOngoingCall(task: Task) {
  return task.history.find(isOngoing);
}
function ifExists<T>(call: Call | undefined, fn: CallFunction<T>, fallback: T) {
  return call ? fn(call) : fallback;
}
function taskMostRecentCallEvaluator<T>(fn: CallFunction<T>, fallback: T, task: Task) {
  return ifExists(findMostRecentOngoingCall(task), fn, fallback);
}
function withMostRecentOngoingCall<T>(fn: CallFunction<T>, fallback: T) {
  return taskMostRecentCallEvaluator.bind(null, fn, fallback);
}
function callOwnershipArbiter(user: User, workstation: Workstation, call: Call) {
  return call.workstation === workstation.id || call.personnel === user.id;
}
function belongsToMe(user: User, workstation: Workstation) {
  return callOwnershipArbiter.bind(null, user, workstation);
}
function firstOngoingCallBelongsToMe(user: User, workstation: Workstation) {
  return withMostRecentOngoingCall(belongsToMe(user, workstation), false);
}
function callPriorityEvaluator(priorities: StatusPriorities, call: Call) {
  return priorities[call.status] || UnknownLowPriority;
}
function mapStatusToPriority(priorities: StatusPriorities) {
  return callPriorityEvaluator.bind(null, priorities);
}
function taskSortDescending(fn: TaskComparisonFunction, alpha: Task, bravo: Task) {
  return fn(bravo) - fn(alpha);
}
function sortDescending(fn: TaskComparisonFunction) {
  return taskSortDescending.bind(null, fn);
}
const byMostRecentOngoingCallStatusOrder = sortDescending(
  withMostRecentOngoingCall(mapStatusToPriority(statusPriorities), UnknownLowPriority),
);
const first = () => true;

export class OngoingTaskRepository {
  constructor(
    private readonly tasks: TaskRepository,
    private readonly workstations: WorkstationRepository,
  ) {}

  get(user: User): Task | null {
    const workstation = this.workstations.findByUser(user) ?? Workstation.none();

    return (
      this.tasks
        .all()
        .filter(firstOngoingCallBelongsToMe(user, workstation))
        .sort(byMostRecentOngoingCallStatusOrder)
        .find(first) ?? null
    );
  }
}
