import { injectable } from 'tsyringe';
import type { InboundAttendance } from '../../domain/overview-inbound-attendance/InboundAttendance';
import type { InboundAttendanceRecord } from '../../domain/overview-inbound-attendance/InboundAttendanceRecord';
import type { InputInboundAttendance } from './InputInboundAttendance';
import type { InputInboundAttendanceRecord } from './InputInboundAttendanceRecord';

@injectable()
export class InboundAttendanceFactory {
  make(input: InputInboundAttendance): InboundAttendance {
    return {
      current: input.current.map(this.mapInboundRecord()),
      previous: input.previous.map(this.mapInboundRecord()),
    };
  }

  private mapInboundRecord(): (record: InputInboundAttendanceRecord) => InboundAttendanceRecord {
    return (record: InputInboundAttendanceRecord): InboundAttendanceRecord => ({
      date: record.date,
      callbackAttempts: record.callback_first_attempt + record.multiple_attempts,
      firstAttempt: record.first_attempt,
      noContact: record.no_contact,
      all:
        record.first_attempt +
        record.callback_first_attempt +
        record.multiple_attempts +
        record.no_contact,
    });
  }
}
