import { injectable } from 'tsyringe';
import type { SmsMessage } from '@/modules/timeline/domain/message-composer/message/SmsMessage';
import { TimelineSmsMessage } from './TimelineSmsMessage';

@injectable()
export class OutputTimelineSmsMessageFactory {
  makeMany(timelineMessages: SmsMessage[]): TimelineSmsMessage[] {
    return timelineMessages.map(this.make, this);
  }

  make(timelineMessage: SmsMessage): TimelineSmsMessage {
    return new TimelineSmsMessage(
      timelineMessage.id,
      timelineMessage.direction,
      timelineMessage.body,
      timelineMessage.createdAt.isoDate,
      timelineMessage.owner?.id ?? null,
    );
  }
}
