import { injectable } from 'tsyringe';
import { PublicPatientApi } from '@/modules/patient/public/api/PublicPatientApi';
import type { Patient } from '@/modules/task/domain/patient/Patient';
import type { PatientRepository } from '@/modules/task/domain/patient/PatientRepository';
import { PatientFactory } from './PatientFactory';

@injectable()
export class ApiPatientRepository implements PatientRepository {
  constructor(
    private readonly patientApi: PublicPatientApi,
    private readonly patientFactory: PatientFactory,
  ) {}

  get(id: string): Patient {
    const patient = this.patientApi.get(id);

    return this.patientFactory.make(patient);
  }

  async update(data: Patient): Promise<Patient> {
    const patient = await this.patientApi.update(data.id, {
      ...data,
      priority: data.priority ? 1 : 0,
    });

    return this.patientFactory.make(patient);
  }
}
