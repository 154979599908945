import { injectable } from 'tsyringe';
import { PublicPatientApi } from '@/modules/patient/public/api/PublicPatientApi';
import type { OutputPatient } from '@/modules/patient/public/types';
import type { PatientRepository } from '../../domain/patient/PatientRepository';
import { Patient } from '../../domain/patient/Patient';

@injectable()
export class PatientApiPatientRepository implements PatientRepository {
  constructor(private readonly api: PublicPatientApi) {}

  get(id: string): Patient {
    const patient: OutputPatient = this.api.get(id);
    return new Patient(patient.phoneNumber, patient.name, patient.surname);
  }
}
