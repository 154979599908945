import type { Criteria } from '../../domain';
import type { QueryParams } from './QueryParams';

export class QueryParamsFactory {
  make(phrase: string, params: Criteria = {}): Record<string, string | number> {
    const defaultParams: QueryParams = {
      page: 1,
      'order[surname]': 'asc',
      'order[name]': 'asc',
    };

    const customParams: QueryParams = {
      ...(params.page ? { page: params.page } : {}),
      ...(params.order?.name ? { 'order[name]': params.order?.name } : {}),
      ...(params.order?.surname ? { 'order[surname]': params.order?.surname } : {}),
      ...(params.types ? { types: params.types } : {}),
      ...(phrase ? { phrase } : {}),
    };

    return {
      ...defaultParams,
      ...customParams,
    };
  }
}
