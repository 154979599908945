import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { StorageFactory } from '../domain/storage/services/StorageFactory';

export const RootLocalStorageFactoryToken = Symbol('RootLocalStorageFactory');
export const RootSessionStorageFactoryToken = Symbol('RootSessionStorageFactory');

export class RootStorageProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<StorageFactory>(RootLocalStorageFactoryToken, {
      useFactory: () => new StorageFactory(localStorage, Date),
    });
    container.register<StorageFactory>(RootSessionStorageFactoryToken, {
      useFactory: () => new StorageFactory(sessionStorage, Date),
    });
  }
}
