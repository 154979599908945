import { inject, injectable } from 'tsyringe';
import { AuthorizerToken } from '@/modules/timeline/di/tokens';
import type { Authorizer } from '@/modules/timeline/domain/authorization/Authorizer';
import { CopyCallSid, DownloadRecording, ReportCallQuality } from '../../../di/policies';

@injectable()
export class CallActionPermissionGuard {
  constructor(@inject(AuthorizerToken) private readonly authorizer: Authorizer) {}

  isAllowingToCopyCallSid(): boolean {
    return this.authorizer.can(CopyCallSid);
  }

  isAllowingQualityReporting(): boolean {
    return this.authorizer.can(ReportCallQuality);
  }

  isAllowingRecordingDownloading(): boolean {
    return this.authorizer.can(DownloadRecording);
  }
}
