import type { Store } from 'vuex';
import type { RootState } from '@/store/types';
import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { PhoneWidgetWorkstationManager } from '@/modules/authentication/domain/PhoneWidgetWorkstationManager';

/**
 * TODO remove it from authentication module. Authenticator should not be aware of workstation existence.
 */
@injectable()
export class StorePhoneWidgetWorkstationManager implements PhoneWidgetWorkstationManager {
  constructor(@inject(RootStoreToken) private readonly store: Store<RootState>) {}

  async logoutFromAllWorkstation(): Promise<void> {
    if (this.store.getters['phoneWidget/deviceInstance']) {
      await this.store.dispatch('phoneWidget/DESTROY_ACTION');
    }

    if (this.store.getters['phoneWidget/web/deviceInstance']) {
      await this.store.dispatch('phoneWidget/web/DESTROY_ACTION');
    }
  }
}
