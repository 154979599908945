<template>
  <div :class="$b()">
    <div :class="$b('header-wrapper')">
      <div :class="$b('number-wrapper')">
        <dp-circle color="primary-light">
          <span :class="$b('number')">{{ stepNumber }}</span>
        </dp-circle>
      </div>
      <div :class="$b('header', { 'align-top': hasSubtitle })">
        <h4 :class="$b('title')">
          {{ title }}
        </h4>
        <slot name="subtitle">
          <p v-if="hasSubtitle" :class="$b('subtitle')">
            {{ subtitle }}
          </p>
        </slot>
      </div>
    </div>
    <div :class="$b('content', { 'is-compact': isCompact })">
      <slot name="default" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { DpCircle } from '@dp-ui-kit/vue';

@Component({
  name: 'TheStepperItem',
  components: {
    DpCircle,
  },
})
export default class TheStepperItem extends Vue {
  @Prop({ type: Number, required: true })
  readonly stepNumber: number;

  @Prop({ type: String, required: true })
  readonly title: string;

  @Prop({ type: String, default: '' })
  readonly subtitle: string;

  @Prop({ type: Boolean, default: false })
  readonly isCompact: boolean;

  get hasSubtitle(): boolean {
    return this.subtitle.length > 0;
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables' as v;
@use '@/assets/scss/functions' as f;

$number-size: f.px-to-rem(32px);
$number-right-margin: v.$spacer-md;

.dp-the-stepper-item {
  position: relative;

  &__header-wrapper {
    display: flex;
  }

  &__number-wrapper {
    width: $number-size;
    height: $number-size;
    margin-right: $number-right-margin;
  }

  &__number {
    color: v.$primary;
    font-weight: v.$font-weight-bold;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--align-top {
      justify-content: start;
    }
  }

  &__title {
    font-size: v.$h4-font-size;
    font-weight: v.$font-weight-medium;
  }

  &__subtitle {
    font-size: v.$font-size-sm;
    color: v.$gray-600;
  }

  &__content {
    margin-top: v.$spacer-md;
    padding-left: $number-size + $number-right-margin;

    &--is-compact {
      margin-top: v.$spacer-xs;
    }
  }
}
</style>
