import type { FileRepository } from '../FileRepository';
import { ConfigurationType } from '../models/ConfigurationType';
import type { Digit } from '../models/Digit';
import type { Fallback } from '../models/Fallback';
import type { Label } from '../models/Label';
import { Media } from '../models/Media';
import { VoiceMessage } from '../models/VoiceMessage';
import type { Workstation } from '../models/Workstation';
import { CallingGroup } from './CallingGroup';

export class VoiceMenuConfigurationOption {
  type: ConfigurationType;

  constructor(
    public id: string,
    public digit: Digit,
    public label: Label,
    initialType: 'label' | 'calling-group' | 'voice-message',
    public callingGroup = CallingGroup.default(),
    public voiceMessage = VoiceMessage.empty(),
    public isOpen = false,
  ) {
    this.type = {
      label: ConfigurationType.Label,
      'calling-group': ConfigurationType.CallingGroup,
      'voice-message': ConfigurationType.VoiceMessage,
    }[initialType];
  }

  get isLabel(): boolean {
    return this.type === ConfigurationType.Label;
  }

  get isVoiceMessage(): boolean {
    return this.type === ConfigurationType.VoiceMessage;
  }

  get isCallingGroup(): boolean {
    return this.type === ConfigurationType.CallingGroup;
  }

  get workstations(): Workstation[] {
    if (!this.isCallingGroup) {
      return [];
    }

    return this.callingGroup.getWorkstations();
  }

  set workstations(workstations: Workstation[]) {
    this.callingGroup.setWorkstations(workstations);
  }

  setFallback(newFallback: Fallback): void {
    this.callingGroup.fallback = newFallback;
  }

  async saveVoiceMessageFile(fileRepository: FileRepository): Promise<void> {
    if (!this.voiceMessage.requireFileUpload) {
      return;
    }

    const fileId = await fileRepository.savePublic(this.voiceMessage.fileUrl);
    this.voiceMessage.file = new Media(fileId, this.voiceMessage.fileUrl, false);
  }
}
