import type { App } from 'vue';
import type { Router } from 'vue-router';
import type { Store } from 'vuex';
import type { RootState } from '@/store/types';
import * as Sentry from '@sentry/vue';
import { LoggerStrategy } from './LoggerStrategy';
import { SentryLogger } from './SentryLogger';
import { SentryConfiguration } from './SentryConfiguration';

interface InitErrorLoggerOptions {
  store: Store<RootState>;
  router: Router;
}

export const errorLogger = new LoggerStrategy();

function attachStoreWatcher(store: Store<RootState>, sentry: typeof Sentry) {
  store.watch(
    (_, getters) => getters.getUser,
    val => {
      if (val) {
        sentry.setUser({
          id: val.id,
        });
      }
    },
  );
}

export async function initErrorLogger(
  app: App,
  { router, store }: InitErrorLoggerOptions,
): Promise<void> {
  const shouldInitSentryLogger =
    import.meta.env.NODE_ENV === 'production' || Boolean(import.meta.env.VITE_APP_SENTRY_ACTIVE);

  if (shouldInitSentryLogger) {
    const release = import.meta.env.VITE_APP_COMMIT_SHA || 'local-release';

    try {
      Sentry.init(new SentryConfiguration(app, router, release, store));
      attachStoreWatcher(store, Sentry);

      const newLogger = await SentryLogger.make(Sentry);

      errorLogger.updateStrategy(newLogger);
    } catch (e) {
      errorLogger.logException(e);
    }
  }
}
