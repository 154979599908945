import type { DpListener } from '@/core/events/DpListener';
import { injectable } from 'tsyringe';
import type { TaskUpdatedEvent as TasksListTaskUpdatedEvent } from '@/modules/tasks-list/public/events';
import { TaskUpdatedHandler } from '../../domain/TaskUpdatedHandler';
import { TaskUpdatedFactory } from './TaskUpdatedFactory';

@injectable()
export class TaskUpdatedListener implements DpListener {
  constructor(
    private readonly taskUpdatedFactory: TaskUpdatedFactory,
    private readonly taskUpdatedHandler: TaskUpdatedHandler,
  ) {}

  handle(event: TasksListTaskUpdatedEvent): void {
    const taskUpdated = this.taskUpdatedFactory.make(event);
    this.taskUpdatedHandler.handle(taskUpdated);
  }
}
