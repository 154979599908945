import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { RootModule } from '@/common/root';
import { UserModule } from '@/modules/user';
import { DateProvider } from '@/modules/tasks-list/di/providers/DateProvider';
import { RepositoryProvider } from '@/modules/tasks-list/di/providers/RepositoryProvider';
import { LocalStorageProvider } from '@/modules/tasks-list/di/providers/LocalStorageProvider';
import { EventTrackerProvider } from '@/modules/tasks-list/di/providers/EventTrackerProvider';
import { EventProvider } from '@/modules/tasks-list/di/providers/EventProvider';
import { RealTimeCommunicationModule } from '@/common/real-time-communication';
import { HttpClientModule } from '@/modules/http-client';
import { AuthorizationProvider } from './providers/AuthorizationProvider';
import { RealTimeCommunicationProvider } from './providers/RealTimeCommunicationProvider';
import { RealTimeCommunicationBootProvider } from './providers/RealTimeCommunicationBootProvider';
import { LocaleProvider } from './providers/LocaleProvider';

export class TasksListModule implements DpModule {
  static readonly namespace = Symbol('TasksListModule');

  static readonly requires = [
    RootModule,
    UserModule,
    RealTimeCommunicationModule,
    HttpClientModule,
  ];

  providers(): IDpProvider[] {
    return [
      LocalStorageProvider,
      DateProvider,
      RepositoryProvider,
      AuthorizationProvider,
      EventTrackerProvider,
      EventProvider,
      RealTimeCommunicationProvider,
      RealTimeCommunicationBootProvider,
      LocaleProvider,
    ];
  }
}
