import { injectable } from 'tsyringe';
import type { ConnectOptions } from './ConnectOptions';

@injectable()
export class ConnectOptionsFactory {
  make(target: string): ConnectOptions {
    return {
      params: {
        To: target,
        Called: target,
      },
    };
  }
}
