import { injectable } from 'tsyringe';
import { UpcomingVisit } from '@/modules/book-visit/domain/visit/UpcomingVisit';
import type { StoreUpcomingVisit } from '@/modules/book-visit/adapters/visit/StoreUpcomingVisit';
import { StoreVisitFactory } from '@/modules/book-visit/adapters/visit/StoreVisitFactory';

@injectable()
export class StoreUpcomingVisitFactory {
  static tryMake(storeUpcomingVisit: StoreUpcomingVisit | null): UpcomingVisit | null {
    if (!storeUpcomingVisit) {
      return null;
    }

    return new UpcomingVisit(
      storeUpcomingVisit.duration,
      StoreVisitFactory.makeFromUpcomingVisit(storeUpcomingVisit),
    );
  }
}
