import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import type { EventTracker } from '@/modules/task/domain/tracking-event/EventTracker';
import { EventTrackerToken } from '@/modules/task/di/tokens';
import { EventTrackerFacade } from '@/common/tracking-event/public/api';
import { DpEventBus } from '@/core/events/DpEventBus';
import { TaskUpdateListener } from '@/modules/task/adapters/call-tracking/TaskUpdateListener';
import { TaskUpdatedEvent } from '@/modules/tasks-list/domain/events/TaskUpdatedEvent';

export class TrackingProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<EventTracker>(EventTrackerToken, EventTrackerFacade);
  }

  boot(container: DependencyContainer): void {
    const eventBus = container.resolve(DpEventBus);
    const taskUpdateListener = container.resolve(TaskUpdateListener);

    eventBus.subscribe(TaskUpdatedEvent.eventName, taskUpdateListener);
  }
}
