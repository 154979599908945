import { PatientRequest } from '@/modules/timeline/domain/patient-request/PatientRequest';
import { IsoDate } from '@/modules/timeline/domain/IsoDate';
import { injectable } from 'tsyringe';
import { TokenApi } from '@/modules/authentication/public/api/TokenApi';
import type { StorePatientRequest } from './StorePatientRequest';
import type { StorePatientRequestMedia } from './StorePatientRequestMedia';
import type { PatientRequestAttachment } from '@/modules/timeline/domain/patient-request/PatientRequestAttachment';
import { MediaProxy } from '@/modules/timeline/domain/MediaProxy';

@injectable()
export class StorePatientRequestFactory {
  constructor(private readonly tokenApi: TokenApi) {}

  makeMany(inputPatientRequest: StorePatientRequest[], timezone: string): PatientRequest[] {
    return inputPatientRequest.map(request => this.make(request, timezone));
  }

  private make(inputPatientRequest: StorePatientRequest, timezone: string): PatientRequest {
    const doctorFullName = `${inputPatientRequest.doctor.prefix || ''} ${
      inputPatientRequest.doctor.name
    } ${inputPatientRequest.doctor.surname}`.trim();

    const subject = inputPatientRequest.requested_objects.join(', ');

    const attachments = this.makeAttachments(inputPatientRequest.files ?? []);

    return new PatientRequest(
      inputPatientRequest.id,
      IsoDate.make(inputPatientRequest.created_at, timezone),
      this.mapType(inputPatientRequest.type),
      subject,
      inputPatientRequest.subject_full_name,
      doctorFullName,
      inputPatientRequest.comment,
      attachments,
    );
  }

  private mapType(type: string): PatientRequest['type'] {
    const typeMapping: Record<string, PatientRequest['type']> = {
      prescription: 'prescription',
      certificate: 'certificate',
      refillPrescription: 'refill-prescription',
      testResults: 'test-results',
    };

    return typeMapping[type] || 'other';
  }

  private makeAttachments(medias: StorePatientRequestMedia[]): PatientRequestAttachment[] {
    const mediaProxy = new MediaProxy(() => this.tokenApi.getAccessToken());

    return medias.map(({ name, url }) => ({
      name,
      url: mediaProxy.make(url),
    }));
  }
}
