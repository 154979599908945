import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import { FacilityIntegration } from '@/modules/book-visit/domain/facility/FacilityIntegration';
import { DocplannerIntegrationMethod } from '@/modules/book-visit/domain/facility/DocplannerIntegrationMethod';
import type { FacilityIntegrationRepository } from '@/modules/book-visit/domain/facility/FacilityIntegrationRepository';

export interface FacilityStore {
  getters: {
    getUserFacility: {
      docplanner_integration: {
        method: 'none' | 'sso' | 'url';
      };
    };
  };
}

@injectable()
export class StoreFacilityIntegrationRepository implements FacilityIntegrationRepository {
  constructor(@inject(RootStoreToken) private store: FacilityStore) {}

  get(): FacilityIntegration {
    const { method } = this.store.getters.getUserFacility.docplanner_integration;
    return new FacilityIntegration(DocplannerIntegrationMethod[method]);
  }
}
