import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { EventTrackerFacade } from '@/common/tracking-event/public/api';
import { EventTrackerToken } from '@/modules/dashboard/di/token';
import type { EventTracker } from '@/modules/dashboard/domain/EventTracker';

export class TrackingProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<EventTracker>(EventTrackerToken, EventTrackerFacade);
  }
}
