import { HttpClientApi } from '@/modules/http-client/public/api';

import { injectable } from 'tsyringe';
import type { MessageTemplateRepository } from '@/modules/timeline/domain/message-composer/templates/MessageTemplateRepository';
import type { MessageTemplate } from '@/modules/timeline/domain/message-composer/templates/MessageTemplate';
import type { HttpMessageTemplate } from './HttpMessageTemplate';
import { MessageTemplateFactory } from './MessageTemplateFactory';

@injectable()
export class HttpMessageTemplateRepository implements MessageTemplateRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly messageTemplateMapper: MessageTemplateFactory,
  ) {}

  async get(): Promise<MessageTemplate[]> {
    const templates: HttpMessageTemplate[] | '' = await this.httpClient.get(
      `/api/v0/facility/{facilityId}/message-templates`,
    );

    if (!templates) {
      return [];
    }

    return this.messageTemplateMapper.makeMany(templates);
  }
}
