import { inject, injectable } from 'tsyringe';
import { CallRecordingConfigurationRepositoryToken } from '../di/token';
import type { CallRecordingConfigurationRepository } from '../domain/repositories/CallRecordingConfigurationRepository';
import type { CallRecordingStatus } from './types/CallRecordingStatus';

@injectable()
export class CallRecordingApi {
  constructor(
    @inject(CallRecordingConfigurationRepositoryToken)
    private readonly callRecordingConfigurationRepository: CallRecordingConfigurationRepository,
  ) {}

  async get(): Promise<CallRecordingStatus> {
    const status = await this.callRecordingConfigurationRepository.get();

    return { isRecordingEnabled: status.enabled };
  }

  async enableCallRecording(): Promise<CallRecordingStatus> {
    const status = await this.callRecordingConfigurationRepository.save(true);

    return { isRecordingEnabled: status.enabled };
  }

  async disableCallRecording(): Promise<CallRecordingStatus> {
    const status = await this.callRecordingConfigurationRepository.save(false);

    return { isRecordingEnabled: status.enabled };
  }
}
