import { injectable } from 'tsyringe';
import { Patient } from '../../domain/Patient';
import type { StorePatient } from './types/StorePatient';

@injectable()
export class PatientFactory {
  make(storePatient: StorePatient): Patient {
    return new Patient(
      storePatient.id,
      storePatient.name,
      storePatient.surname,
      storePatient.phoneNumber,
    );
  }
}
