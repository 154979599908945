import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import type { Store } from 'vuex';
import { StaticCommonEventName } from '../../domain/callbacks/StaticCommonEventName';
import type { Callback } from '../../domain/models/Callback';

@injectable()
export class FacilityCallback implements Callback {
  readonly name = 'CommonFacility';

  readonly eventName = StaticCommonEventName.Facility;

  readonly channelName = 'common';

  constructor(
    @inject(RootStoreToken)
    private readonly store: Store<unknown>,
  ) {}

  handler = (): void => {
    this.store.dispatch('GET_FACILITY_ACTION');
  };
}
