import { DpEventName } from '../DpEventName';

export class DurationEvent {
  readonly name = DpEventName.Duration;

  constructor(
    readonly externalName: string,
    readonly duration: number | null,
    readonly extraProperties: Record<string, string | number | boolean | null> = {},
  ) {}
}
