import type { DpProvider } from '@/core/di/DpProvider';
import { inject, injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { Store } from 'vuex';
import { StoreCallQualityModalManager } from '@/modules/timeline/adapters/call/quality/StoreCallQualityModalManager';
import type { CallQualityModalManager } from '@/modules/timeline/domain/call/quality/CallQualityModalManager';
import { module, moduleName } from '../../store/call-module';
import { CallQualityModalManagerToken } from '../tokens';

@injectable()
export class StoreProvider implements DpProvider {
  constructor(
    @inject(RootStoreToken)
    private readonly rootStore: Store<unknown>,
  ) {}

  register(container: DependencyContainer): void {
    container.register<CallQualityModalManager>(
      CallQualityModalManagerToken,
      StoreCallQualityModalManager,
    );
  }

  boot(): void {
    this.rootStore.registerModule(moduleName, module);
  }
}
