<template>
  <div :class="$b()">
    {{ label }}
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';

@Component({
  name: 'Separator',
})
export default class Separator extends Vue {
  @Prop({ type: String, required: true })
  readonly label: string;
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;
.dp-separator {
  display: block;
  height: 2em;
  overflow: hidden;
  color: v.$gray-600;
  font-size: v.$font-size-sm;
  text-align: center;
  text-transform: capitalize;
}
</style>
