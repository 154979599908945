export type UpcomingVisitStatus = 'upcoming' | 'in-progress';

export class UpcomingVisit {
  constructor(
    readonly status: UpcomingVisitStatus,
    readonly doctorName: string,
    readonly serviceName: string,
    readonly startAt: string,
  ) {}
}
