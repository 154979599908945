import { inject, injectable } from 'tsyringe';
import { $GET_ENTITIES_BY_ID } from '@dp-vue/entities';
import { RootStoreToken } from '@/common/root';
import type { PatientRequest } from '@/modules/timeline/domain/patient-request/PatientRequest';
import type { Store } from '../Store';
import { StorePatientRequestFactory } from './StorePatientRequestFactory';
import type { StoreTask } from '../../../StoreTask';

@injectable()
export class StorePatientRequestRepository {
  constructor(
    @inject(RootStoreToken) private readonly store: Store,
    private readonly patientRequestFactory: StorePatientRequestFactory,
  ) {}

  get(task: StoreTask, timezone: string): PatientRequest[] {
    const { patient_requests } = this.store.getters[$GET_ENTITIES_BY_ID]('task', [task.id])[0];

    if (!patient_requests) {
      return [];
    }

    return this.patientRequestFactory.makeMany(patient_requests, timezone);
  }
}
