import type { Message } from '@/modules/timeline/domain/message-composer/message/Message';
import { differenceInHours, parseISO } from 'date-fns';
import { Owner } from '@/modules/timeline/domain/owner/Owner';
import type { Media } from '@/modules/timeline/domain/message-composer/message/media/Media';
import type { IsoDate } from '@/modules/timeline/domain/IsoDate';
import type { BodyPart } from '@/modules/timeline/domain/message-composer/message/body/BodyPart';
import { BodyPartsFactory } from '@/modules/timeline/domain/message-composer/message/body/BodyPartsFactory';

export class WhatsAppMessage implements Message {
  constructor(
    readonly id: string,
    readonly createdAt: IsoDate,
    readonly direction: 'inbound' | 'outbound',
    readonly status: 'sent' | 'delivered' | 'read' | 'failed',
    readonly body: string,
    readonly owner: Owner | null,
    readonly media: Media[],
  ) {}

  get ageInHours(): number {
    return differenceInHours(new Date(), parseISO(this.createdAt.isoDate));
  }

  get hasMedia(): boolean {
    return this.media.length > 0;
  }

  get bodyParts(): BodyPart[] {
    if (!this.body.length) {
      return [];
    }

    return BodyPartsFactory.make(this.body);
  }

  get isValid(): boolean {
    return !!this.body.length || this.hasMedia;
  }
}
