import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import type { LanguageRepository } from '../../domain/tracking/repositories/LanguageRepository';
import {
  BrandFactoryToken,
  EventPublisherToken,
  FacilityRepositoryToken,
  LanguageRepositoryToken,
  SubscriptionPlanRepositoryToken,
  UserRepositoryToken,
} from '../tokens';
import { RootLanguageRepository } from '../../adapters/tracking/RootLanguageRepository';
import type { FacilityRepository } from '../../domain/tracking/repositories/FacilityRepository';
import { StoreFacilityRepository } from '../../adapters/tracking/StoreFacilityRepository';
import { TranslatedBrandFactory } from '../../adapters/tracking/TranslatedBrandFactory';
import type { BrandFactory } from '../../domain/tracking/repositories/BrandFactory';
import { UserApiUserRepository } from '../../adapters/tracking/UserApiUserRepository';
import type { UserRepository } from '../../domain/tracking/repositories/UserRepository';
import type { EventPublisher } from '../../domain/tracking/EventPublisher';
import { TrackingEventPublisher } from '../../adapters/tracking/TrackingEventPublisher';
import { AuthSubscriptionPlanRepository } from '../../adapters/tracking/AuthSubscriptionPlanRepository';
import type { SubscriptionPlanRepository } from '../../domain/tracking/repositories/SubscriptionPlanRepository';

export class TrackingProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<LanguageRepository>(LanguageRepositoryToken, RootLanguageRepository);
    container.register<FacilityRepository>(FacilityRepositoryToken, StoreFacilityRepository);
    container.register<BrandFactory>(BrandFactoryToken, TranslatedBrandFactory);
    container.register<UserRepository>(UserRepositoryToken, UserApiUserRepository);
    container.register<EventPublisher>(EventPublisherToken, TrackingEventPublisher);
    container.register<SubscriptionPlanRepository>(
      SubscriptionPlanRepositoryToken,
      AuthSubscriptionPlanRepository,
    );
  }
}
