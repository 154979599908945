import { RootLanguageConfigToken } from '@/common/root';
import type { PluginLanguageConfigProvider } from '@/common/root/adapters/PluginLanguageConfigProvider';
import { inject, injectable } from 'tsyringe';

type Locale = 'en' | 'pl' | 'es' | 'mx' | 'it' | 'br';

@injectable()
export class LocaleRepository {
  constructor(
    @inject(RootLanguageConfigToken)
    private readonly languageConfig: PluginLanguageConfigProvider,
  ) {}

  get(): Locale {
    return this.languageConfig.get().locale;
  }
}
