import { inject, injectable } from 'tsyringe';
import type { RegulatoryRepository } from './RegulatoryRepository';
import { RegulatoryRepositoryToken } from '../../di/providers/AdapterProvider';

@injectable()
export class ComplianceTabGate {
  constructor(
    @inject(RegulatoryRepositoryToken)
    private readonly regulatoryRepository: RegulatoryRepository,
  ) {}

  requiredRegulatory(): boolean {
    const regulatory = this.regulatoryRepository.get();

    return regulatory.required;
  }
}
