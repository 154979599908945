<template>
  <dp-input
    v-if="edit"
    v-model="valueAgent"
    :placeholder="placeholder"
    :disabled="disabled"
    :validation="validationResult"
    :label="label"
    :size="size"
    class="tw-w-full"
    :class="$attrs.class"
    :maxlength="maxLength"
  />
  <form-show-text v-else :content="valueAgent" />
</template>

<script>
import { defineComponent } from 'vue';

import DpInput from '@dp-ui-kit/vue/components/input/DpInput.vue';

import FormShowText from '@/components/settings/form/FormShowText.vue';
import { APP_INPUT_MAX_LENGTH } from '@/config/app-config';

export default defineComponent({
  name: 'FormInput',

  components: {
    DpInput,
    FormShowText,
  },

  inheritAttrs: false,

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    edit: {
      type: Boolean,
      required: true,
    },

    placeholder: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: null,
    },

    size: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    validationResult: {
      type: Object,
      default: null,
    },

    maxLength: {
      type: Number,
      default: APP_INPUT_MAX_LENGTH,
    },
  },

  emits: ['update:modelValue'],

  computed: {
    valueAgent: {
      get() {
        return this.modelValue;
      },

      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
});
</script>
