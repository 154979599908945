import { singleton } from 'tsyringe';
import { RestartExecutor } from './RestartExecutor';
import { PendingCallError } from './errors/PendingCallError';
import { RestartFailError } from './errors/RestartFailError';
import { RestartTimer } from './RestartTimer';

type Callback = () => void;

@singleton()
export class RestartScheduler {
  private isWaitingForReset = false;

  private restartCallback: Callback;

  constructor(
    private readonly restartExecutor: RestartExecutor,
    private readonly restartTimer: RestartTimer,
  ) {}

  setRestartCallback(callback: Callback): void {
    this.restartCallback = callback;
  }

  tryRestart(): void {
    if (this.restartTimer.isRunning()) {
      this.tryRestartLater();
      return;
    }

    this.reset();
  }

  forceAwaitingReset(): void {
    if (!this.isWaitingForReset) {
      return;
    }

    this.reset();
  }

  private reset() {
    this.restartTimer.start();

    try {
      this.restartExecutor.execute(this.restartCallback);

      this.isWaitingForReset = false;
      this.restartTimer.resetPeriod();
    } catch (error) {
      if (error instanceof RestartFailError) {
        this.restartTimer.increasePeriod();
        this.tryRestartLater();
        return;
      }

      if (error instanceof PendingCallError) {
        this.tryRestartAsSoonAsPossible();
        return;
      }

      throw error;
    }
  }

  private tryRestartLater() {
    this.isWaitingForReset = true;

    setTimeout(this.reset.bind(this), this.restartTimer.getTimeLeft());
  }

  private tryRestartAsSoonAsPossible() {
    this.isWaitingForReset = true;
  }
}
