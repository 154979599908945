<template>
  <div :class="$b()">
    <layout-wrapper :class="$b('inner')">
      <template #header>
        <div :class="$b('header')">
          <slot name="header" />
        </div>
      </template>

      <template #main>
        <div :class="$b('container')">
          <div :class="$b('content')">
            <slot name="view">
              <router-view />
            </slot>
          </div>
        </div>
      </template>
    </layout-wrapper>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator';

import { LayoutWithSidebar, LayoutWrapper } from '@/ui/templates';

@Component({
  name: 'UserSettingsPage',
  components: {
    LayoutWithSidebar,
    LayoutWrapper,
  },
})
export default class UserSettingsPage extends Vue {}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;
@use 'src/assets/scss/mixins' as m;

.dp-user-settings-page {
  --nav-height: #{v.$navbar-simple-height};

  &__container {
    @include m.container(100%);

    margin-top: var(--nav-height);
  }

  &__content {
    padding-top: v.$spacer-lg;
    padding-bottom: v.$spacer-lg;
  }

  @include m.media(md) {
    --nav-height: #{v.$header-height};

    &__container {
      padding: 0;
    }

    &__content {
      max-width: 25rem;
    }
  }
}
</style>
