import { injectable } from 'tsyringe';
import { InboxTaskFeedFetcher } from '@/modules/tasks-list/domain/task-feed/fetchers/InboxTaskFeedFetcher';
import { TodoTaskFeedFetcher } from '@/modules/tasks-list/domain/task-feed/fetchers/TodoTaskFeedFetcher';
import { PrescriptionTaskFeedFetcher } from '@/modules/tasks-list/domain/task-feed/fetchers/PrescriptionTaskFeedFetcher';
import { RefillPrescriptionTaskFeedFetcher } from '@/modules/tasks-list/domain/task-feed/fetchers/RefillPrescriptionTaskFeedFetcher';
import { CertificateTaskFeedFetcher } from '@/modules/tasks-list/domain/task-feed/fetchers/CertificateTaskFeedFetcher';
import { TestResultsTaskFeedFetcher } from '@/modules/tasks-list/domain/task-feed/fetchers/TestResultsTaskFeedFetcher';
import { OtherTaskFeedFetcher } from '@/modules/tasks-list/domain/task-feed/fetchers/OtherTaskFeedFetcher';
import type { TaskFeedFetcher } from '@/modules/tasks-list/domain/task-feed/TaskFeedFetcher';
import type { TaskGroup } from '@/modules/tasks-list/domain/task/TaskGroup';

@injectable()
export class TaskFeedFetcherFactory {
  constructor(
    private readonly inboxFetchingStrategy: InboxTaskFeedFetcher,
    private readonly todoFetchingStrategy: TodoTaskFeedFetcher,
    private readonly prescriptionFetchingStrategy: PrescriptionTaskFeedFetcher,
    private readonly refillPrescriptionTaskFeedFetcher: RefillPrescriptionTaskFeedFetcher,
    private readonly certificateTaskFeedFetcher: CertificateTaskFeedFetcher,
    private readonly testResultsTaskFeedFetcher: TestResultsTaskFeedFetcher,
    private readonly otherTaskFeedFetcher: OtherTaskFeedFetcher,
  ) {}

  make(group: TaskGroup): TaskFeedFetcher {
    switch (group) {
      case 'open':
        return this.inboxFetchingStrategy;
      case 'todo':
        return this.todoFetchingStrategy;
      case 'prescription':
        return this.prescriptionFetchingStrategy;
      case 'refill-prescription':
        return this.refillPrescriptionTaskFeedFetcher;
      case 'certificate':
        return this.certificateTaskFeedFetcher;
      case 'test-results':
        return this.testResultsTaskFeedFetcher;
      case 'other':
        return this.otherTaskFeedFetcher;
      default: {
        throw new Error('Invalid task group name.');
      }
    }
  }
}
