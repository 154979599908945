import { getLanguageConfig } from '@/utils/languageDetector';
import { compose } from '@/utils/functions';

const { locale } = getLanguageConfig();

/**
 * @param {string} name
 * @returns {string}
 */
function capitalizeFirstLetter([firstLetter, ...rest]) {
  // Support for empty strings.
  if (!firstLetter) {
    return '';
  }

  return [firstLetter.toLocaleUpperCase(locale), rest.join('')].join('');
}
/**
 * @param {string} separator
 * @returns {function}
 */
function capitalizeWithSeparator(separator) {
  return name => name.split(separator).map(capitalizeFirstLetter).join(separator);
}

const normalizeName = name => name.toLocaleLowerCase(locale);

/**
 * @param {string} name
 * @returns {string}
 */
export const capitalizeName = compose(
  capitalizeWithSeparator(' '),
  capitalizeWithSeparator('-'),
  normalizeName,
);

function escapeRegExp(str) {
  return str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
}

export function splitAndMark(full, part) {
  if (part === '') {
    return [{ tag: 'span', fragment: full }];
  }

  const matchedRegExp = new RegExp(`(${escapeRegExp(part)})`, 'gi');

  return full
    .split(matchedRegExp)
    .map(fragment =>
      matchedRegExp.test(fragment) ? { tag: 'mark', fragment } : { tag: 'span', fragment },
    );
}

export function escapeHtml(dirty) {
  return dirty
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#x27;');
}

export function paramCase(name) {
  return name.replace(/([a-z])([A-Z])/g, `$1-$2`).toLowerCase();
}
