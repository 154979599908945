import { singleton } from 'tsyringe';
import { EventRecord } from './EventRecord';

@singleton()
export class FrequencyStorage {
  private events = new Map<number, EventRecord>();

  add(): void {
    const timestamp = Date.now();
    this.events.set(timestamp, new EventRecord(timestamp));
  }

  getRange(startTimestamp: number, endTimestamp: number): EventRecord[] {
    return [...this.events.entries()]
      .filter(
        ([eventTimestamp]) => eventTimestamp >= startTimestamp && eventTimestamp < endTimestamp,
      )
      .map(([, eventRecord]) => eventRecord);
  }

  removeOlderThan(timestamp: number): void {
    this.events = new Map(
      [...this.events.entries()].filter(([eventTimestamp]) => eventTimestamp >= timestamp),
    );
  }
}
