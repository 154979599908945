import type { VoiceMessage } from './VoiceMessage';
import type { VoiceMessageDTO } from './VoiceMessageDTO';
import { VoiceMessageName } from './VoiceMessage';

export interface VoiceMessageListInput {
  welcomeMessage?: VoiceMessage;
  voiceMenu?: VoiceMessage;
  facilityAfterHours?: VoiceMessage;
  voicemailInQueue?: VoiceMessage;
}

export class VoiceMessageList {
  welcomeMessage?: VoiceMessage;

  voiceMenu?: VoiceMessage;

  facilityAfterHours?: VoiceMessage;

  voicemailInQueue?: VoiceMessage;

  private constructor(messages: VoiceMessageListInput) {
    this.welcomeMessage = messages.welcomeMessage;
    this.voiceMenu = messages.voiceMenu;
    this.facilityAfterHours = messages.facilityAfterHours;
    this.voicemailInQueue = messages.voicemailInQueue;
  }

  static make(data: VoiceMessageListInput): VoiceMessageList {
    return new VoiceMessageList(data);
  }

  toDTO(): VoiceMessageDTO[] {
    const messageNames = Object.values(VoiceMessageName);

    const voiceMessages: (VoiceMessageDTO | null)[] = messageNames.map(name => {
      const message = this[name];

      if (!message) {
        return null;
      }

      return {
        type: message.type,
        value: message.value,
        file: message.file ?? null,
        name,
      };
    });

    return voiceMessages.filter(message => message) as VoiceMessageDTO[];
  }
}
