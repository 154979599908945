export const BookingRepositoryToken = Symbol('BookingRepositoryToken');
export const BookingSlotRepositoryToken = Symbol('BookingSlotRepository');
export const DoctorWithAddressToken = Symbol('DoctorWithAddress');
export const ErrorConverterToken = Symbol('ErrorConverter');
export const ErrorMessageProviderToken = Symbol('ErrorMessageProvider');
export const FacilityIntegrationRepositoryToken = Symbol('FacilityIntegrationRepository');
export const CountryCodeProviderToken = Symbol('CountryCodeProviderToken');
export const PreloadedBookingOptionsRepositoryToken = Symbol('PreloadedBookingOptionsRepository');
export const ServiceRepositoryToken = Symbol('ServiceRepository');
export const ErrorLoggerToken = Symbol('ErrorLogger');
export const EventTrackerToken = Symbol('EventTracker');
export const VisitConfirmationReadRepositoryToken = Symbol('VisitConfirmationReadRepository');
export const VisitConfirmationWriteRepositoryToken = Symbol(
  'VisitConfirmationWriteRepositoryToken',
);
export const UpcomingVisitRepositoryToken = Symbol('UpcomingVisitRepository');
export const PatientRepositoryToken = Symbol('PatientRepository');
