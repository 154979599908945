import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';

import type { UserWriteRepository } from '../../domain/permissions/UserWriteRepository';
import { InvitationUrlFactory } from './InvitationUrlFactory';
import { LocaleRepository } from './LocaleRepository';
import type { UserProposal } from '../../domain/permissions/UserProposal';

interface ApiUser {
  id: string;
}

@injectable()
export class HttpUserWriteRepository implements UserWriteRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly invitationUrlFactory: InvitationUrlFactory,
    private readonly localeRepository: LocaleRepository,
  ) {}

  async create(userProposal: UserProposal): Promise<string> {
    return userProposal.email
      ? this.createWithEmail(userProposal)
      : this.createWithPhone(userProposal);
  }

  private async createWithEmail(userProposal: UserProposal): Promise<string> {
    const { id } = await this.httpClient.put<ApiUser>('/api/v0/facility/{facilityId}/create-user', {
      name: userProposal.name,
      email: userProposal.email,
      locale: this.localeRepository.get(),
      target: this.invitationUrlFactory.make(),
    });

    return id;
  }

  private async createWithPhone(userProposal: UserProposal): Promise<string> {
    const { id } = await this.httpClient.put<ApiUser>('/api/v0/facility/{facilityId}/create-user', {
      name: userProposal.name,
      phoneNumber: userProposal.phoneNumber,
      locale: this.localeRepository.get(),
      target: this.invitationUrlFactory.make(),
    });

    return id;
  }
}
