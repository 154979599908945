import { injectable } from 'tsyringe';
import type { OutboundAttendance } from '../../domain/overview-outbound-attendance/OutboundAttendance';
import type { OutboundAttendanceRecord } from '../../domain/overview-outbound-attendance/OutboundAttendanceRecord';
import type { InputOutboundAttendance } from './InputOutboundAttendance';
import type { InputOutboundAttendanceRecord } from './InputOutboundAttendanceRecord';

@injectable()
export class OutboundAttendanceFactory {
  make(input: InputOutboundAttendance): OutboundAttendance {
    return {
      current: input.current.map(this.mapOutboundRecord()),
      previous: input.previous.map(this.mapOutboundRecord()),
    };
  }

  private mapOutboundRecord(): (record: InputOutboundAttendanceRecord) => OutboundAttendanceRecord {
    return (record: InputOutboundAttendanceRecord): OutboundAttendanceRecord => ({
      date: record.date,
      callbackAttempts: record.callback_first_attempt + record.multiple_attempts,
      firstAttempt: record.first_attempt,
      noContact: record.no_contact,
      all:
        record.first_attempt +
        record.callback_first_attempt +
        record.multiple_attempts +
        record.no_contact,
    });
  }
}
