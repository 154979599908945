import { SmsMessage } from '@/modules/timeline/domain/message-composer/message/SmsMessage';
import { WhatsAppMessage } from '@/modules/timeline/domain/message-composer/message/WhatsAppMessage';
import type { Message } from '@/modules/timeline/domain/message-composer/message/Message';
import { EventsListGrouper } from '@/modules/timeline/domain/timeline/events-list/EventsListGrouper';
import type { EventsListItem } from '@/modules/timeline/domain/timeline/events-list/EventsListItem';
import { EventsListSorter } from '@/modules/timeline/domain/timeline/events-list/EventsListSorter';
import { Call } from '@/modules/timeline/domain/call/Call';
import type { EventsListGrouperItem } from '@/modules/timeline/domain/timeline/events-list/EventsListGrouperItem';
import { DpChatMessage } from '@/modules/timeline/domain/message-composer/message/DpChatMessage';

export class EventsList {
  private constructor(public readonly items: EventsListItem[]) {}

  get calls(): Call[] {
    return this.items.filter((event): event is Call => event instanceof Call);
  }

  get groupedEvents(): EventsListGrouperItem[] {
    return EventsListGrouper.groupByDate(this.items);
  }

  get lastInboundMessage(): Message | null {
    return [...this.messages].reverse().find(message => message.direction === 'inbound') ?? null;
  }

  get lastMessage(): Message | null {
    return [...this.messages].reverse().at(0) ?? null;
  }

  get lastInboundWhatsAppMessage(): WhatsAppMessage | null {
    return (
      [...this.whatsAppMessages].reverse().find(message => message.direction === 'inbound') ?? null
    );
  }

  get messages(): (SmsMessage | WhatsAppMessage | DpChatMessage)[] {
    return this.items.filter(
      (event): event is SmsMessage | WhatsAppMessage | DpChatMessage =>
        event instanceof SmsMessage ||
        event instanceof WhatsAppMessage ||
        event instanceof DpChatMessage,
    );
  }

  get whatsAppMessages(): WhatsAppMessage[] {
    return this.items.filter((event): event is WhatsAppMessage => event instanceof WhatsAppMessage);
  }

  get smsMessages(): SmsMessage[] {
    return this.items.filter((event): event is SmsMessage => event instanceof SmsMessage);
  }

  get dpChatMessages(): DpChatMessage[] {
    return this.items.filter((event): event is DpChatMessage => event instanceof DpChatMessage);
  }

  get length(): number {
    return this.items.length;
  }

  static make(events: EventsListItem[]): EventsList {
    return new EventsList(EventsListSorter.sort(events));
  }
}
