import { ContainerRepository } from '../repositories/ContainerRepository';
import { StorageItem } from '../models/StorageItem';
import type { Clock } from '../models/Clock';
import type { InputStorage } from '../models/InputStorage';
import type { StorageItemFactory } from '../models/StorageItemFactory';

export class StorageFactory {
  constructor(
    private readonly storage: InputStorage,
    private readonly clock: Clock,
  ) {}

  createStorageItemFactory<T extends Record<string, any>>(prefix: string): StorageItemFactory<T> {
    return <K extends keyof T>(name: K) =>
      new StorageItem(
        new ContainerRepository(this.storage, `${prefix}:${String(name)}`),
        this.clock,
      );
  }
}
