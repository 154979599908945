import { injectable, inject } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { IntegrationRepository } from '../../domain/integration/IntegrationRepository';

export interface FacilityStore {
  getters: {
    getUserFacility: {
      integrations: string[];
    };
  };
}

@injectable()
export class StoreIntegrationRepository implements IntegrationRepository {
  constructor(@inject(RootStoreToken) private store: FacilityStore) {}

  get(): string[] {
    return this.store.getters.getUserFacility.integrations;
  }
}
