import type { WorkstationId } from '@/api/types/entities/workstation';
import type { User } from '@/api/types/entities/user';
import type { Media } from '@/api/types/entities/media';
import type { Conference } from '@/api/types/entities/conference';
import type { DateString, NumericId, SID } from '@/api/types';
import type { ChannelName } from './channel';

export enum CallDirection {
  Inbound = 'inbound',
  Outbound = 'outbound',
}

export enum CallStatus {
  Abandoned = 'abandoned',
  Completed = 'completed',
  InProgress = 'in-progress',
  Intro = 'intro',
  Unanswered = 'unanswered',
  Waiting = 'waiting',
}

type CallId = NumericId;

export interface Call {
  id: CallId;
  direction: CallDirection;
  sid: SID;
  status: CallStatus;
  duration: number;
  phone: ChannelName;
  created_at: DateString;
  updated_at: DateString;
  // TODO remove optional after cleaning up tests
  waiting_at?: DateString;
  answered_at?: DateString;
  conference?: Conference;
  personnel?: User;
  recording?: Media;
  voicemail?: Media;
  workstation_id?: WorkstationId;
  has_quality_reports?: boolean;
  summary?: string;
  is_on_hold: boolean;
}
