import type { MutationTree } from 'vuex';
import { FacilityBookingOptions } from '@/modules/book-visit/domain/booking/FacilityBookingOptions';

interface State {
  bookingOptions: FacilityBookingOptions;
}

const state: State = {
  bookingOptions: FacilityBookingOptions.makeEmpty(),
};

const mutations: MutationTree<State> = {
  SET_BOOKING_OPTIONS(state, bookingOptions: FacilityBookingOptions): void {
    state.bookingOptions = bookingOptions;
  },
};

export { state, mutations };
