import { addDays, nextSunday, startOfToday } from 'date-fns';
import { dateUtils } from './index';

export function dayOfWeek(day: number): string {
  // sunday is 0;
  const sunday = nextSunday(startOfToday());
  const dayNumber = day % 7;

  return dateUtils.format(addDays(sunday, dayNumber), 'EEE');
}
