import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { DocplannerUrlIntegrationRepository } from '../../domain/super-admin-settings/DocplannerUrlIntegrationRepository';
import type { InputFacilityProfile } from '../facility-integration/types/InputFacilityProfile';

@injectable()
export class HttpDocplannerUrlIntegrationRepository implements DocplannerUrlIntegrationRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async get(): Promise<string> {
    const inputFacilityProfile: InputFacilityProfile | '' = await this.httpClient.get(
      '/api/v0/facility/{facilityId}/docplanner',
    );

    if (!inputFacilityProfile) {
      return '';
    }

    return inputFacilityProfile.url;
  }

  async update(url: string): Promise<string> {
    const inputFacilityProfile: InputFacilityProfile | '' = await this.httpClient.put(
      '/api/v0/facility/{facilityId}/docplanner',
      { url },
    );

    if (!inputFacilityProfile) {
      return '';
    }

    return inputFacilityProfile.url;
  }
}
