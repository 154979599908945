import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { AudioConverterToken } from '../tokens';
import { FfmpegAudioConverter } from '../../adapters/audio-formatter/FfmpegAudioConverter';
import type { AudioConverter } from '../../domain/audio-recorder/AudioConverter';

@injectable()
export class AudioConverterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<AudioConverter>(AudioConverterToken, FfmpegAudioConverter);
  }
}
