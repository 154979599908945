import type { TaskCallbackRepository } from '@/api/repositories/types/TaskCallbackRepository';
import { BaseAxiosRepository } from '@/api/repositories/axios/BaseAxiosRepository';
import { Task } from '@/api/models/Task';
import type { TaskId } from '@/api/models/Task';
import type { Task as RawTask } from '@/api/types/entities/task';

export class AxiosTaskCallbackRepository
  extends BaseAxiosRepository
  implements TaskCallbackRepository
{
  async startCallback(id: TaskId): Promise<void> {
    await this.axiosClient.post(`/api/v0/task/${id}/callback`);
  }

  async abortCallback(id: TaskId): Promise<Task> {
    const data = (await this.axiosClient.post(`/api/v0/task/${id}/hang-up`)) as RawTask;

    return Task.make(data);
  }
}
