export class Summary {
  constructor(
    readonly callId: string,
    readonly content: string,
    readonly finishedAt: string,
    private readonly status:
      | 'pending'
      | 'not-verified'
      | 'failed'
      | 'approved'
      | 'rejected'
      | 'modified',
  ) {}

  get isPending(): boolean {
    return this.status === 'pending';
  }

  get isFailed(): boolean {
    return this.status === 'failed';
  }

  get isModified(): boolean {
    return this.status === 'modified';
  }

  get isApproved(): boolean {
    return this.status === 'approved';
  }

  get isNotVerified(): boolean {
    return this.status === 'not-verified';
  }

  get isRejected(): boolean {
    return this.status === 'rejected';
  }

  get isReady(): boolean {
    return !this.isPending && !this.isFailed;
  }

  get canBeVerified(): boolean {
    return this.isNotVerified || this.isApproved || this.isRejected;
  }

  get canBeModified(): boolean {
    return !this.isPending;
  }
}
