import type { App, Plugin } from 'vue';
import type { Modifiers } from './bem-name-generator';
import { b } from './bem-name-generator';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    /**
     * Function generates BEM string for a given component. By default component name is used as a block element.
     * Name is transformed to a kebab-case. You can provide different arguments to get name with specific element name or modifier.
     * If multiple modifiers are provided then returned string contains multiple class names split with a space.
     *
     * For example component name `MyPascalCaseComponent` will gain a block name as  follows `my-pascal-case-component`.
     *
     * Examples for component with name `FooBar`:
     *  ```ts
     *  this.$b(); // 'foo-bar';
     *
     *  this.$b('element'); // 'foo-bar__element';
     *
     *  this.$b('element', { active: true }); // 'foo-bar__element--active';
     *
     *  this.$b({ active: true }); // 'foo-bar--active';
     *
     *  this.$b('element', { highlighted: 'light-gray' });
     *  // 'foo-bar__element--highlighted_light-gray';
     *
     *  this.$b({ active: true, secondary: true });
     *  // 'foo-bar--active foo-bar--secondary';
     *
     *  this.$b('element' { active: true, highlighted: 'light-gray' });
     *  // 'foo-bar__element--active foo-bar__element--highlighted_light-gray';
     *  ```
     *
     * @param {string|object} [elementOrModifier] For string argument is an element name. For object argument is used as modifiers for a block.
     * Key represents modifier name, boolean value sets modifier visibility, string value is added as modifier value.
     * @param {object} [modifiersObject] Modifiers for elements, behaves the same as modifier for a block.
     * @return {string} generated bem string
     */
    $b(elementOrModifier?: string | Modifiers, modifiersObject?: Modifiers): string;
  }
}

function install(app: App): void {
  app.config.globalProperties.$b = function getBemName(
    this: { $options: { name: string } },
    elementOrModifier?: string | Modifiers,
    modifiersObject?: Modifiers,
  ) {
    const componentName = this.$options.name ?? null;
    return b(componentName, elementOrModifier, modifiersObject);
  };
}

export const plugin: Plugin = { install };
