import type Pusher from 'pusher-js';
import type { PresenceChannel as ExternalPusherPresenceClient } from 'pusher-js';

import type { Client } from '../../domain/models/Client';
import { PusherPresenceChannel } from './PusherPresenceChannel';
import { PusherPrivateChannel } from './PusherPrivateChannel';
import type { PrivateChannel } from '../../domain/models/PrivateChannel';
import type { PresenceChannel } from '../../domain/models/PresenceChannel';
import type { Callback } from '../../domain/models/Callback';
import type { Connection } from '../../domain/models/Connection';
import { PusherConnection } from './PusherConnection';

export class PusherClient implements Client {
  constructor(private readonly pusherInstance: Pusher) {}

  get connection(): Connection {
    return new PusherConnection(this.pusherInstance.connection);
  }

  subscribe(channelId: string): void {
    this.pusherInstance.subscribe(channelId);
  }

  reset(): void {
    this.pusherInstance.unbind_all();
    this.pusherInstance.allChannels().forEach(channel => {
      this.pusherInstance.unsubscribe(channel.name);
    });
  }

  disconnect(): void {
    this.reset();
    this.pusherInstance.disconnect();
  }

  getChannel(channelId: string): PrivateChannel | PresenceChannel {
    const channel = this.pusherInstance.channel(channelId);
    if (channelId.startsWith('presence-')) {
      return new PusherPresenceChannel(channel as ExternalPusherPresenceClient);
    }
    return new PusherPrivateChannel(channel);
  }

  bind(callback: Callback, channelId: string): void {
    const channel = this.getChannel(channelId);
    channel.bind(callback.eventName, callback.handler);
  }

  unbind(callback: Callback, channelId: string): void {
    const channel = this.getChannel(channelId);
    channel.unbind(callback.eventName, callback.handler);
  }
}
