import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { InvitationSender } from '../../domain/permissions/InvitationSender';
import { LocaleRepository } from './LocaleRepository';
import { InvitationUrlFactory } from './InvitationUrlFactory';

interface InvitationPayload {
  locale: string;
  url: string;
}

@injectable()
export class HttpInvitationSender implements InvitationSender {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly localeRepository: LocaleRepository,
    private readonly invitationUrlFactory: InvitationUrlFactory,
  ) {}

  async send(permissionId: string): Promise<void> {
    await this.httpClient.post(
      `/api/v0/auth/invite-user/${permissionId}`,
      this.getInvitationPayload(),
    );
  }

  private getInvitationPayload(): InvitationPayload {
    return {
      locale: this.localeRepository.get(),
      url: this.invitationUrlFactory.make(),
    };
  }
}
