import type { DateFormatter } from '@/modules/task/domain/time/DateFormatter';
import { ReminderTag } from '@/modules/task/domain/reminder/ReminderTag';

export class Reminder {
  constructor(
    readonly taskId: string,
    readonly timestamp: number | null,
  ) {}

  // TODO move formatting to UI
  getFormattedDate(dateConverter: DateFormatter): string {
    if (!this.timestamp) {
      return '';
    }
    return dateConverter.timestampToString(this.timestamp);
  }

  // TODO remove this invalid example of empty-object pattern 🙈
  get isEmpty(): boolean {
    return !this.timestamp;
  }

  get isExpired(): boolean {
    if (!this.timestamp) {
      return false;
    }

    const currentTimestamp = Math.floor(new Date().getTime() / 1000);
    return this.timestamp < currentTimestamp;
  }

  getTag(): ReminderTag | null {
    if (!this.timestamp) {
      return null;
    }

    return ReminderTag.make(this.timestamp.toString(), this.isExpired);
  }

  setNewTimestamp(timestamp: number): Reminder {
    return new Reminder(this.taskId, timestamp);
  }
}
