import { Call } from '@/modules/task/domain/call/Call';
import { OwnerId } from '@/modules/task/domain/owner/OwnerId';
import type { TimelineCall } from '@/modules/timeline/public/types';
import { injectable } from 'tsyringe';

@injectable()
export class CallFactory {
  makeMany(data: TimelineCall[]): Call[] {
    return data.map(this.make);
  }

  make(data: TimelineCall): Call {
    if (!data.id) {
      throw new Error("You shouldn't create call without id. Use NullCall instead");
    }

    return new Call(
      data.id,
      data.status,
      data.direction,
      new OwnerId(data.ownerId ?? ''),
      data.createdAt,
      data.updatedAt ?? '',
      data.startWaitingAt ?? '',
      data.answeredAt ?? '',
      data.channelId ?? '',
      data.channelName ?? '',
      data.voicemailUrl ?? '',
      data.workstationId ?? '',
      data.duration ?? 0,
    );
  }
}
