<template>
  <div :class="$b({ color })" @click="$emit('click')">
    <component :is="icon" />
    {{ text }}
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

import { isIncludedIn } from '@/utils/functions';
import { TaskCloseReasonTypes } from './TaskCloseReason.types';
import type { Option } from './TaskCloseReason.config';
import { taskBadgeOptions } from './TaskCloseReason.config';

@Component({
  name: 'TaskBadge',
  emits: ['click'],
})
export default class TaskCloseReason extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  readonly label: string;

  @Prop({
    type: String,
    required: true,
    validator: isIncludedIn(Object.values(TaskCloseReasonTypes)),
  })
  readonly type: string;

  get option(): Option {
    return taskBadgeOptions[this.type];
  }

  get color(): Option['color'] {
    return this.option.color;
  }

  get icon(): Option['icon'] {
    return this.option.icon;
  }

  get text(): string {
    return this.label;
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-task-badge {
  display: inline-flex;
  align-items: center;
  gap: v.$spacer-xs;
  padding: 0 (v.$spacer * 0.75);
  font-size: v.$font-size-base;
  font-weight: v.$font-weight-medium;
  line-height: 2;
  background: v.$gray-100;
  color: v.$gray-500;
  cursor: pointer;

  &--color_danger {
    background: v.$danger-light;
    color: v.$danger;
  }

  &--color_gray {
    background: v.$gray-200;
    color: v.$gray-600;
  }

  &--color_success {
    background: v.$secondary-light;
    color: v.$secondary;
  }

  :deep(svg) {
    fill: currentColor;
  }
}
</style>
