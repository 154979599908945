import { EventPublisherToken } from '@/common/tracking-event/di/tokens';
import { inject, injectable } from 'tsyringe';
import type { InteractionEventPublisher } from './InteractionEventPublisher';
import type { EventPublisher } from '../EventPublisher';
import type { InteractionEventType } from '../../models/InteractionEventType';
import type { InteractionEventExtraProperties } from '../../models/InteractionEventExtraProperties';
import { InteractionEvent } from '../../models/InteractionEvent';

@injectable()
export class DpBusInteractionEventPublisher implements InteractionEventPublisher {
  constructor(
    @inject(EventPublisherToken)
    private readonly eventPublisher: EventPublisher,
  ) {}

  send(
    type: InteractionEventType,
    externalName: string,
    extraProperties?: InteractionEventExtraProperties,
  ): void {
    this.eventPublisher.send(new InteractionEvent(type, externalName, extraProperties));
  }
}
