import type { AppStatusChangeCallback } from '@/app/app-status/AppStatusSupervisor';
import { AppStatusSupervisor } from '@/app/app-status/AppStatusSupervisor';
import { ComponentFetcher } from '@/app/app-status/component/ComponentFetcher';
import { ComponentRepository } from '@/app/app-status/component/ComponentRepository';
import { ComponentIdDetector } from '@/app/app-status/component/ComponentIdDetector';
import axios from 'axios';
import locale from '@/config/locale-configuration.json';

export class AppStatusSupervisorFactory {
  private constructor() {}

  static create(appStatusChangeCallback: AppStatusChangeCallback): AppStatusSupervisor {
    return new AppStatusSupervisor(
      new ComponentFetcher(new ComponentIdDetector(locale), new ComponentRepository(axios)),
      appStatusChangeCallback,
    );
  }
}
