import type { Tag } from '@/modules/task/domain/tag/Tag';
import { TagType } from '@/modules/task/domain/tag/Tag';

export class VoiceMenuTag implements Tag {
  private constructor(
    readonly content: string,
    readonly type: TagType,
  ) {}

  static make(voiceMenuLabel: string, hasPriority: boolean): VoiceMenuTag {
    return new VoiceMenuTag(
      voiceMenuLabel,
      hasPriority ? TagType.PriorityVoiceMenu : TagType.VoiceMenu,
    );
  }
}
