import { injectable } from 'tsyringe';
import type { DpListener } from '@/core/events/DpListener';
import { EnvironmentAwareSender } from '../EnvironmentAwareSender';
import { InteractionEventConverter } from './factories/InteractionEventConverter';
import type { InteractionEvent } from '../../models/InteractionEvent';

@injectable()
export class InteractionEventListener implements DpListener {
  constructor(
    private readonly sender: EnvironmentAwareSender,
    private readonly interactionEventConverter: InteractionEventConverter,
  ) {}

  handle(interactionEvent: InteractionEvent): void {
    this.sender.trackEvent(this.interactionEventConverter.make(interactionEvent));
  }
}
