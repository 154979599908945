export class RealTimeConnectionError extends Error {
  constructor(message?: string) {
    super(message);

    // Changing prototype for extending default class is recommended by TS
    // https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, RealTimeConnectionError.prototype);

    this.name = 'RealTimeConnectionError';
  }
}
