import { injectable } from 'tsyringe';
import type { CustomTrackingEvent } from '../../dto/CustomTrackingEvent';
import type { InteractionEvent } from '../../../models/InteractionEvent';

@injectable()
export class InteractionEventConverter {
  make(interactionEvent: InteractionEvent): CustomTrackingEvent {
    return {
      event: interactionEvent.type,
      eventName: interactionEvent.externalName,
      eventProperties: interactionEvent.extraProperties,

      // Optional fields not used right now.
      category: null,
      action: null,
      label: null,
      value: null,
    };
  }
}
