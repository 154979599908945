<template>
  <div :class="$b()">
    <a
      v-if="hasReturnButton"
      :class="$b('return-button')"
      :href="returnUrl"
      aria-label="Go to previous page"
    >
      <dp-icon-arrow-left size="18" />
    </a>
    <h1 :class="$b('title', { centered: hasReturnButton })">
      {{ title }}
    </h1>
    <div :class="$b('content')">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { DpIconArrowLeft } from '@dp-ui-kit/icons';

@Component({
  name: 'NavbarSimple',
  components: {
    DpIconArrowLeft,
  },
})
export default class NavbarSimple extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  readonly returnUrl: string;

  @Prop({
    type: String,
    required: true,
  })
  readonly title: string;

  get hasReturnButton(): boolean {
    return !!this.returnUrl;
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-navbar-simple {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: v.$navbar-simple-height;
  padding: 0 v.$container-horizontal-padding;

  &__return-button {
    line-height: 0;
    padding: v.$spacer * 0.75;
  }

  &__title {
    margin: 0;
    font-size: v.$h3-font-size;

    &--centered {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
