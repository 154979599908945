import { toNamedParams } from '@/utils/vuex';
import {
  $FILTER_ENTITIES_BY,
  $GET_ENTITIES_BY_TYPE,
  MUTATION_ENTITIES_UPDATE,
} from '@dp-vue/entities';
import { EntityType } from '@/store/schema/entity-type';
import { phoneApi } from '@/api/phone-api';
import { normalize } from 'normalizr';
import { workstationErrorSchema } from '@/store/schema/workstation-error';

const getters = {
  getWorkstationErrors: toNamedParams(
    ({ rootGetters }) => rootGetters[$GET_ENTITIES_BY_TYPE](EntityType.WORKSTATION_ERROR) ?? [],
  ),
  getWorkstationsError: toNamedParams(({ rootGetters }) => workstationId => {
    const errors = rootGetters[$FILTER_ENTITIES_BY](
      EntityType.WORKSTATION_ERROR,
      'workstation_id',
      workstationId,
    );
    return errors.length ? errors[0] : null;
  }),
};

const actions = {
  async GET_ERRORS_ACTION({ dispatch, commit, rootGetters }) {
    return phoneApi
      .withFacility(rootGetters.getUserFacilityID)
      .getWorkstationErrors()
      .then(data => {
        dispatch('DELETE_ENTITIES_GROUP_ACTION', EntityType.WORKSTATION_ERROR, { root: true });

        const { entities, result } = normalize(data, [workstationErrorSchema]);
        commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });

        return result;
      });
  },
};

export const workstationErrors = {
  namespaced: true,
  getters,
  actions,
};
