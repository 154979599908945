import { inject, injectable } from 'tsyringe';
import { AnonymizationRepositoryToken } from '../../di/token';
import type { AnonymizationRepository } from '../../domain/repositories/AnonymizationRepository';
import type { AnonymizationStatus } from '../types/AnonymizationStatus';

@injectable()
export class AnonymizationApi {
  constructor(
    @inject(AnonymizationRepositoryToken)
    private readonly anonymizationRepository: AnonymizationRepository,
  ) {}

  async get(): Promise<AnonymizationStatus> {
    const anonymization = await this.anonymizationRepository.get();
    return { isDataPublic: anonymization.enabled };
  }

  async makeDataPublic(): Promise<AnonymizationStatus> {
    const anonymization = await this.anonymizationRepository.save(false);

    return { isDataPublic: anonymization.enabled };
  }

  async makeDataPrivate(): Promise<AnonymizationStatus> {
    const anonymization = await this.anonymizationRepository.save(true);

    return { isDataPublic: anonymization.enabled };
  }
}
