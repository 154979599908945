import { inject, injectable } from 'tsyringe';
import { differenceInSeconds } from 'date-fns';
import { NotificationServiceToken } from '../../di/tokens';
import type { ServerTime } from './ServerTime';
import type { NotificationService } from './NotificationService';

@injectable()
export class TimeValidator {
  constructor(
    @inject(NotificationServiceToken)
    private readonly notificationService: NotificationService,
  ) {}

  validate(serverTime: ServerTime): void {
    if (this.hasNotSyncedClock(serverTime.time)) {
      this.notificationService.showInvalidTime();
    }
  }

  private hasNotSyncedClock(backendDate) {
    const SYNC_OFFSET_IN_SECONDS = 60;
    const backendTimestamp = Date.parse(backendDate);

    return Math.abs(differenceInSeconds(backendTimestamp, Date.now())) > SYNC_OFFSET_IN_SECONDS;
  }
}
