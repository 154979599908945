import { singleton } from 'tsyringe';

/**
 * It's important for the SalesForce chat, to handle use case when you minimize the chat window.
 */
@singleton()
export class HelpWidgetVisibilityStatus {
  private visibilityFlag = false;

  private onChangedCallback: ((isVisible: boolean) => void) | null = null;

  get isVisible(): boolean {
    return this.visibilityFlag;
  }

  show(): void {
    this.visibilityFlag = true;
    this.onChangedCallback?.(this.visibilityFlag);
  }

  hide(): void {
    this.visibilityFlag = false;
    this.onChangedCallback?.(this.visibilityFlag);
  }

  onChanged(callback: (isVisible: boolean) => void): void {
    this.onChangedCallback = callback;
  }
}
