export class Label {
  constructor(
    public content: string,
    public isStarred: boolean,
  ) {}

  static empty(): Label {
    return new Label('', false);
  }
}
