import { TimeFormatter } from '@/app/formatters/number/TimeFormatter';
import { ValueUnit } from '@/app/formatters/number/types';
import type { Locale } from '@/utils/languageDetector';
import { LocaleMapper } from '@/app/formatters/number/LocaleMapper';

export class NumberFormatter {
  private numberFormatter = Intl.NumberFormat;

  private timeFormatter: TimeFormatter;

  private intlLocale: string;

  private constructor(locale: Locale) {
    this.intlLocale = LocaleMapper.toIntlLocale(locale);

    this.timeFormatter = TimeFormatter.make(locale);
  }

  asUnit(value: number, unit: ValueUnit): string {
    switch (unit) {
      case ValueUnit.Number:
        return this.asFormattedNumber(value);
      case ValueUnit.Percentage:
        return this.asPercentage(value);
      case ValueUnit.PercentagePoint:
        return this.asPercentagePoints(value);
      case ValueUnit.Time:
        return this.asTime(value);
      case ValueUnit.Duration:
        return this.asDuration(value);
      default: {
        // noinspection UnnecessaryLocalVariableJS
        const u: never = unit;
        throw new Error(`There is handled unknown unit "${u}".`);
      }
    }
  }

  asFormattedNumber(value: number): string {
    return this.numberFormatter(this.intlLocale).format(value);
  }

  asPercentage(value: number): string {
    return this.numberFormatter(this.intlLocale, { style: 'percent' }).format(value);
  }

  asPercentagePoints(value: number): string {
    return `${this.numberFormatter(this.intlLocale).format(value)} PP`;
  }

  asTime(value: number): string {
    return this.timeFormatter.asTime(value);
  }

  asDuration(value: number): string {
    return this.timeFormatter.asDuration(value);
  }

  static make(locale: Locale): NumberFormatter {
    return new NumberFormatter(locale);
  }
}
