import { differenceInDays, max, min } from 'date-fns';
import type { BookingSlot } from './BookingSlot';

export class BookingSlotList {
  constructor(
    readonly slots: BookingSlot[],
    readonly range: [Date, Date],
    readonly nearestSlotDate: string | null,
  ) {}

  get hasSlots(): boolean {
    return this.slots.length > 0;
  }

  get isEmpty(): boolean {
    return !this.hasSlots;
  }

  get hasMoreSlotsInFuture(): boolean {
    return !!this.nearestSlotDate;
  }

  get last(): BookingSlot | null {
    return this.slots[this.slots.length - 1] ?? null;
  }

  get startRangeDate(): Date {
    return this.range[0];
  }

  get endRangeDate(): Date {
    return this.range[1];
  }

  get rangeLength(): number {
    return differenceInDays(this.endRangeDate, this.startRangeDate) + 1;
  }

  isAboveLimit(limit: number): boolean {
    return this.slots.length > limit;
  }

  concat(bookingSlotList: BookingSlotList): BookingSlotList {
    const startDate = min([bookingSlotList.startRangeDate, this.startRangeDate]);
    const endDate = max([bookingSlotList.endRangeDate, this.endRangeDate]);

    return new BookingSlotList(
      [...this.slots, ...bookingSlotList.slots],
      [startDate, endDate],
      bookingSlotList.nearestSlotDate,
    );
  }

  static makeEmpty(): BookingSlotList {
    const emptyDate = new Date(0);
    return new BookingSlotList([], [emptyDate, emptyDate], null);
  }
}
