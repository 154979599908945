import type { App, Plugin } from 'vue';
import { dateFormatter } from '@/app/formatters/date';
import type { DateFormatter } from '@/app/formatters/date';
import { numberFormatter, currencyFormatter } from '@/app/formatters/number';
import type { NumberFormatter } from '@/app/formatters/number/NumberFormatter';
import { phoneNumberFormatter } from '@/utils/phone-number-formatter';
import type { CurrencyFormatter } from '@/app/formatters/number/CurrencyFormatter';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $dateFormatter: DateFormatter;
    $numberFormatter: NumberFormatter;
    $phoneFormatter: (phoneNumber: string) => string;
    $currencyFormatter: CurrencyFormatter;
  }
}

export const formattersPlugin: Plugin = {
  install(app: App): void {
    app.config.globalProperties.$dateFormatter = dateFormatter;

    app.config.globalProperties.$numberFormatter = numberFormatter;

    app.config.globalProperties.$phoneFormatter = phoneNumberFormatter;

    app.config.globalProperties.$currencyFormatter = currencyFormatter;
  },
};
