import { inject, injectable } from 'tsyringe';
import { TaskFactoryToken, TaskNormalizerToken, TaskWriteRepositoryToken } from '../../di/tokens';
import type { Task } from './Task';
import type { TaskFactory } from './TaskFactory';
import type { TaskNormalizer } from './TaskNormalizer';
import type { TaskWriteRepository } from './TaskWriteRepository';

@injectable()
export class TaskUpdater {
  constructor(
    @inject(TaskNormalizerToken)
    private readonly taskNormalizer: TaskNormalizer,
    @inject(TaskWriteRepositoryToken)
    private readonly taskWriteRepository: TaskWriteRepository,
    @inject(TaskFactoryToken)
    private readonly taskFactory: TaskFactory,
  ) {}

  async update(task: Task): Promise<void> {
    const { entities } = await this.normalize(task);
    await this.updateStoreData(entities);
    await this.updateGroup(task);
  }

  async updateMany(tasks: Task[]): Promise<void> {
    const { entities } = await this.normalizeMany(tasks);
    await this.updateStoreData(entities);
    await this.updateManyInGroup(tasks);
  }

  private async normalize(task: Task) {
    return this.taskNormalizer.make(task);
  }

  private async normalizeMany(tasks: Task[]) {
    return this.taskNormalizer.makeMany(tasks);
  }

  private async updateStoreData(entities: unknown) {
    return this.taskWriteRepository.update(entities);
  }

  private async updateGroup(task) {
    return this.taskWriteRepository.updateGroupForTask(this.taskFactory.make(task));
  }

  private async updateManyInGroup(tasks) {
    const processedTasks = tasks.map(task => this.taskFactory.make(task));

    return this.taskWriteRepository.updateGroupForTasks(processedTasks);
  }
}
