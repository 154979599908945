<template>
  <div :class="$b()">
    <slot />
    <i v-if="knownStatus" :class="$b('status-icon', { online, background })" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

import type { BackgroundColor } from './AvatarWithStatus.types';
import { Status } from './AvatarWithStatus.types';

@Component({
  name: 'AvatarWithStatus',
})
export default class AvatarWithStatus extends Vue {
  @Prop({ type: String, required: true })
  readonly status: Status;

  @Prop({ type: String, default: null })
  readonly background: BackgroundColor | null;

  get knownStatus(): boolean {
    return this.status !== Status.Unknown;
  }

  get online(): boolean {
    return this.status === Status.Online;
  }
}
</script>
<style lang="scss" scoped>
@use 'sass:math';
@use 'src/assets/scss/variables' as v;

$avatar-radius: 16;
$status-icon: 4;
$status-border: 2;
$status-radius: $status-icon + $status-border;
$status-circumference: $status-radius * 2;
$status-scale: percentage(math.div($status-radius, $avatar-radius));
$br-offset: percentage(math.div($status-border, $status-circumference));
$status-icon-scale: percentage(math.div($status-icon, $status-radius));

.dp-avatar-with-status {
  position: relative;
  display: inline-block;
  line-height: 0;

  &__status-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $status-scale;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background: v.$white;
    transform: translate($br-offset, $br-offset); // move border out of the avatar box

    &::after {
      content: '';
      display: block;
      width: $status-icon-scale;
      height: $status-icon-scale;
      border-radius: 50%;
      background: v.$gray-600;
    }

    &--online::after {
      background: v.$success;
    }

    &--background_primary {
      background: v.$primary-dark;
    }

    &--background_white {
      background: v.$white;
    }
  }
}
</style>
