import { inject, injectable } from 'tsyringe';
import { EventTrackerToken } from '../../di/tokens';
import type { EventTracker } from '../EventTracker';
import type { Frequency } from './frequency/Frequency';

@injectable()
export class EventSender {
  // Disabled as we exceed mixpanel quota.
  private readonly disabled = true;

  constructor(
    @inject(EventTrackerToken)
    private readonly eventTracker: EventTracker,
  ) {}

  send(frequency: Frequency): void {
    if (this.disabled) {
      return;
    }

    this.eventTracker.publishCustomPerformance('Real-time update frequency', null, {
      size: frequency.size,
      median: frequency.median,
      p90: frequency.p90,
      max: frequency.max,
    });
  }
}
