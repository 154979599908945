import { HttpClientApi } from '@/modules/http-client/public/api';
import { injectable } from 'tsyringe';
import type { Patient, PatientCollection, UpdatedPatient, NewPatient, Criteria } from '../domain';
import { UpdatedPatientFactory } from '../domain';
import type { InputPatient, InputPatientCollection } from './input';
import { PatientFactory } from './PatientFactory';
import { QueryParamsFactory } from './http-patient-repository/QueryParamsFactory';

@injectable()
export class HttpPatientRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly patientFactory: PatientFactory,
    private readonly updatedPatientFactory: UpdatedPatientFactory,
    private readonly queryParamsFactory: QueryParamsFactory,
  ) {}

  async create({ facilityId, ...patientPayload }: NewPatient): Promise<Patient> {
    const patient: InputPatient = await this.httpClient.post('/api/v1/patients.json', {
      facility: `/api/v1/facilities/${facilityId}`,
      ...patientPayload,
    });

    return this.patientFactory.make(patient);
  }

  async update(patientId: string, updatedPatient: UpdatedPatient): Promise<Patient> {
    const patient: InputPatient = await this.httpClient.put(
      `/api/v1/patients/${patientId}.json`,
      this.updatedPatientFactory.make(updatedPatient),
    );

    return this.patientFactory.make(patient);
  }

  async getPatientsByQuery(query = '', params?: Criteria): Promise<PatientCollection> {
    const jsonApiPatients: InputPatientCollection = await this.httpClient.get(
      '/api/v1/facilities/{facilityId}/patients.jsonapi',
      this.queryParamsFactory.make(query, params),
    );

    return this.patientFactory.makeCollection(jsonApiPatients);
  }
}
