import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { StoreFacilityRepository } from '../../adapters/facility/StoreFacilityRepository';
import { FacilityRepositoryToken } from '../token';
import type { FacilityRepository } from '../../domain/facility/FacilityRepository';

export class FacilityProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<FacilityRepository>(FacilityRepositoryToken, StoreFacilityRepository);
  }
}
