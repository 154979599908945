import { DpEventBus } from '@/core/events/DpEventBus';
import { injectable } from 'tsyringe';
import type { TaskListener } from '../../domain/task/TaskListener';
import type { TaskListenerFactory } from '../../domain/task/TaskListenerFactory';
import { StoreTaskListener } from './StoreTaskListener';

@injectable()
export class StoreTaskListenerFactory implements TaskListenerFactory {
  constructor(private readonly eventBus: DpEventBus) {}

  make(callback: (value: string[]) => void): TaskListener {
    return new StoreTaskListener(callback, this.eventBus);
  }
}
