const state = {
  errors: {},
  fieldsMapping: {},
};

const getters = {
  getErrors: state => state.errors,
  getFieldsMapping: state => state.fieldsMapping,
};

const mutations = {
  SET_ERRORS(state, errors) {
    state.errors = errors;
  },

  SET_FIELDS_MAPPING(state, fieldsMapping) {
    state.fieldsMapping = fieldsMapping;
  },
};

const actions = {
  SET_ERRORS_ACTION({ commit }, payload) {
    commit('SET_ERRORS', payload);
  },

  SET_FIELDS_MAPPING_ACTION({ commit }, payload) {
    commit('SET_FIELDS_MAPPING', payload);
  },

  CLEAR_ACTION({ commit }) {
    commit('SET_ERRORS', {});
    commit('SET_FIELDS_MAPPING', {});
  },
};

export const validationErrors = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
