import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { NewsControllerToken } from '../tokens';
import { BeamerNewsController } from '../../adapters/news/BeamerNewsController';
import type { NewsController } from '../../domain/news/NewsController';

export class NewsProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<NewsController>(NewsControllerToken, BeamerNewsController);
  }
}
