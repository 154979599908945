import { inject, injectable } from 'tsyringe';
import {
  DesktopNotificationNotifierToken,
  CallRepositoryToken,
  UserRepositoryToken,
} from '../di/tokens';
import { NotificationConfigurationReader } from './configuration/NotificationConfigurationReader';
import { incomingCallNotificationName } from './configuration/NotificationName';
import { DesktopNotificationFactory } from './desktop-notification/DesktopNotificationFactory';
import type { DesktopNotificationNotifier } from './desktop-notification/DesktopNotificationNotifier';
import type { CallRepository } from './CallRepository';
import type { TaskUpdated } from './TaskUpdated';
import type { UserRepository } from './UserRepository';
import type { Call } from './Call';
import { DesktopNotificationEvaluator } from './permissions/DesktopNotificationEvaluator';

@injectable()
export class IncomingCallHandler {
  constructor(
    private readonly notificationConfigurationReader: NotificationConfigurationReader,
    private readonly desktopNotificationEvaluator: DesktopNotificationEvaluator,
    @inject(UserRepositoryToken)
    private readonly userRepository: UserRepository,
    @inject(CallRepositoryToken)
    private readonly callRepository: CallRepository,
    @inject(DesktopNotificationNotifierToken)
    private readonly desktopNotificationNotifier: DesktopNotificationNotifier,
    private readonly desktopNotificationFactory: DesktopNotificationFactory,
  ) {}

  async handle(event: TaskUpdated): Promise<void> {
    if (
      !this.hasMatchingTrigger(event) ||
      !this.hasEnabledNotification() ||
      this.desktopNotificationEvaluator.hasDeniedPermission()
    ) {
      return;
    }

    if (this.desktopNotificationEvaluator.canAskForPermission()) {
      const hasGrantedPermissions =
        await this.desktopNotificationEvaluator.askAndCheckIfGrantedPermission();
      if (!hasGrantedPermissions) {
        return;
      }
    }

    const user = this.userRepository.getMe();
    const hasAssignedWorkstation = user.workstationId !== null;

    if (!hasAssignedWorkstation) {
      return;
    }

    const myWaitingCall = this.callRepository
      .getCallsForTask(event.id)
      .find(this.hasValidCall(user.workstationId), this);

    if (!myWaitingCall) {
      return;
    }

    const notification = this.desktopNotificationFactory.makeForIncomingCall(myWaitingCall);
    this.desktopNotificationNotifier.display(notification);
  }

  private hasEnabledNotification(): boolean {
    return this.notificationConfigurationReader.isEnabled(incomingCallNotificationName);
  }

  private hasMatchingTrigger(event: TaskUpdated): boolean {
    return event.updateTriggers.includes('CallWorkstationAssignedEvent');
  }

  private hasValidCall(workstationId: string) {
    return (call: Call) =>
      call.workstationId === workstationId &&
      call.status === 'waiting' &&
      call.direction === 'inbound';
  }
}
