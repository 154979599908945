<template>
  <div v-click-outside="onClickOut" :class="$b()">
    <button :class="$b('toggle', { open: isOpen })" type="button" @click="toggleDatepicker">
      {{ $t('reminder_datepicker.custom_time') }}
      <dp-icon-caret :class="$b('toggle-icon', { open: isOpen })" />
    </button>
    <div v-if="isOpen" :class="$b('panel')">
      <dp-datepicker
        :date="calendarDate"
        :disabled-dates="disabledDates"
        :inline="true"
        :translations="datepickerTranslations"
        @select="setDate"
      />

      <div :class="$b('panel-footer')">
        <dp-input
          v-model="reminderHour"
          type="time"
          :validation="hourValidation"
          :class="$b('hour-input')"
          :label="$t('reminder_datepicker.hour')"
        />
        <dp-button
          :class="$b('submit-button')"
          color="primary"
          size="lg"
          full-width
          @click="handleSubmit"
        >
          {{ $t('reminder_datepicker.done') }}
        </dp-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Vue, Prop } from 'vue-facing-decorator';
import { DpButton, DpDatepicker, DpDropdown, DpInput } from '@dp-ui-kit/vue';
import { DpIconCaret } from '@dp-ui-kit/icons';
import { addYears, endOfYesterday, format } from 'date-fns';

import { clickOutside } from '@/ui/directives/click-outside';
import type { Validation } from '@/ui/types';
import type { DatepickerTranslations, ReminderDate } from './ReminderDatepicker.types';

@Component({
  name: 'ReminderDatepicker',
  components: {
    DpDatepicker,
    DpDropdown,
    DpIconCaret,
    DpInput,
    DpButton,
  },
  directives: {
    clickOutside,
  },
})
export default class ReminderDatepicker extends Vue {
  @Prop({ type: Object })
  readonly datepickerTranslations: DatepickerTranslations;

  isOpen = false;

  calendarDate: Date | null = null;

  disabledDates: { to: Date; from: Date } | null = null;

  reminderHour = '09:00';

  hourValidation: Validation | null = null;

  created() {
    this.disabledDates = { to: endOfYesterday(), from: addYears(new Date(), 1) };
    this.calendarDate = new Date();
  }

  setDate(date: Date): void {
    this.calendarDate = date;
  }

  @Emit('set-reminder')
  private emitSetReminder(date: Date): ReminderDate {
    return this.createReminderDate(date);
  }

  toggleDatepicker(): void {
    this.isOpen = !this.isOpen;
  }

  private closeDatepicker(): void {
    this.isOpen = false;
  }

  onClickOut(): void {
    this.closeDatepicker();
  }

  private validate(): void {
    if (!this.reminderHour.trim()) {
      throw new Error('Please set the time');
    }

    this.hourValidation = null;
  }

  private createReminderDate(date: Date): ReminderDate {
    const formattedDate = format(date, 'yyyy-MM-dd');
    return {
      date: formattedDate,
      time: this.reminderHour,
    };
  }

  handleSubmit(): void {
    try {
      this.validate();
      if (!this.calendarDate) {
        return;
      }
      this.emitSetReminder(this.calendarDate);
    } catch (e) {
      this.setValidation(e);
      return;
    }

    this.closeDatepicker();
  }

  private setValidation(e: Error) {
    this.hourValidation = { type: 'invalid', messages: [e.message] };
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;
@use 'src/assets/scss/mixins' as m;

.dp-reminder-datepicker {
  position: relative;

  &__panel {
    @include m.z-index(modal);

    position: absolute;
    top: calc(100% + #{v.$spacer-sm});
    right: 0;
    border: 1px solid v.$gray-200;
    border-radius: v.$border-radius;
    box-shadow: v.$box-shadow;
    background: v.$white;
  }

  &__panel-footer {
    padding: 0 (v.$spacer * 1.25) v.$spacer-lg;
  }

  &__toggle {
    @include m.reminder-button;
  }

  &__toggle-icon {
    transform-origin: center;
    transition: 0.3s ease;

    &--open {
      transform: rotate(180deg);
    }

    :deep(svg) {
      fill: currentColor;
    }
  }

  &__hour-input {
    display: flex;
    margin-top: v.$spacer-xs; // to make the input outline visible

    :deep(label) {
      margin-right: v.$spacer-sm;
      margin-bottom: 0;
      color: v.$gray-600;
      font-weight: v.$font-weight-normal;
    }

    :deep(input) {
      width: auto;

      &::-webkit-calendar-picker-indicator {
        display: none;
      }
    }
  }

  // removing some styling from datepicker so it feels like one element with panel-footer
  :deep(.dp-datepicker) {
    .dp-datepicker-calendar {
      padding-bottom: v.$spacer-xs;
      border: 0;
      box-shadow: none;
    }

    .dp-datepicker-item-inline {
      margin-right: 0 !important;
    }
  }
}
</style>
