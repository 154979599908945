import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { HttpTotalTasksRepository } from '../../adapters/overview-total-tasks/HttpTotalTasksRepository';
import { HttpInboundAttendanceRepository } from '../../adapters/overview-inbound-attendance/HttpInboundAttendanceRepository';
import { HttpOutboundAttendanceRepository } from '../../adapters/overview-outbound-attendance/HttpOutboundAttendanceRepository';
import { HttpPerformanceDataRepository } from '../../adapters/overview-performance/HttpPerformanceDataRepository';
import type { InboundAttendanceRepository } from '../../domain/overview-inbound-attendance/InboundAttendanceRepository';
import type { OutboundAttendanceRepository } from '../../domain/overview-outbound-attendance/OutboundAttendanceRepository';
import type { PerformanceDataRepository } from '../../domain/overview-performance/PerformanceDataRepository';
import type { TotalTasksRepository } from '../../domain/overview-total-tasks/TotalTasksRepository';
import type { CostPerVisitRepository } from '../../domain/overview-callback/revenue/CostPerVisitRepository';
import {
  CallbackRepositoryToken,
  CostPerVisitRepositoryToken,
  FacilityCountryCodeRepositoryToken,
  InboundAttendanceRepositoryToken,
  OutboundAttendanceRepositoryToken,
  PerformanceDataRepositoryToken,
  TotalTasksRepositoryToken,
} from '../tokens';
import { HttpCostPerVisitRepository } from '../../adapters/overview-callback/revenue/HttpCostPerVisitRepository';
import { HttpCallbackRepository } from '../../adapters/overview-callback/HttpCallbackRepository';
import type { CallbackRepository } from '../../domain/overview-callback/CallbackRepository';
import type { FacilityCountryCodeRepository } from '../../domain/overview-callback/revenue/FacilityCountryCodeRepository';
import { StoreFacilityCountryCodeRepository } from '../../adapters/overview-callback/revenue/StoreFacilityCountryCodeRepository';

export class OverviewProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<InboundAttendanceRepository>(
      InboundAttendanceRepositoryToken,
      HttpInboundAttendanceRepository,
    );

    container.register<OutboundAttendanceRepository>(
      OutboundAttendanceRepositoryToken,
      HttpOutboundAttendanceRepository,
    );

    container.register<PerformanceDataRepository>(
      PerformanceDataRepositoryToken,
      HttpPerformanceDataRepository,
    );

    container.register<TotalTasksRepository>(TotalTasksRepositoryToken, HttpTotalTasksRepository);

    container.register<CostPerVisitRepository>(
      CostPerVisitRepositoryToken,
      HttpCostPerVisitRepository,
    );

    container.register<CallbackRepository>(CallbackRepositoryToken, HttpCallbackRepository);

    container.register<FacilityCountryCodeRepository>(
      FacilityCountryCodeRepositoryToken,
      StoreFacilityCountryCodeRepository,
    );
  }
}
