import { inject, injectable } from 'tsyringe';
import { DesktopNotificationDescriptionFactoryToken } from '../../di/tokens';
import type { Call } from '../Call';
import type { Message } from '../Message';
import { Patient } from '../Patient';
import { DesktopNotification } from './DesktopNotification';
import type { DesktopNotificationDescriptionFactory } from './DesktopNotificationDescriptionFactory';

@injectable()
export class DesktopNotificationFactory {
  constructor(
    @inject(DesktopNotificationDescriptionFactoryToken)
    private readonly desktopNotificationDescriptionFactory: DesktopNotificationDescriptionFactory,
  ) {}

  makeForIncomingCall({ patient }: Call): DesktopNotification {
    return new DesktopNotification(
      patient.displayedName,
      this.desktopNotificationDescriptionFactory.makeForIncomingCall(),
    );
  }

  makeForMessage(message: Message): DesktopNotification {
    return new DesktopNotification(message.patient.displayedName, message.body);
  }

  makeTestNotification(): DesktopNotification {
    const testPatient = Patient.ofTest();

    return new DesktopNotification(
      testPatient.displayedName,
      this.desktopNotificationDescriptionFactory.makeForTest(),
    );
  }
}
