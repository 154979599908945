import { injectable } from 'tsyringe';
import type { Timeline } from '@/modules/task/domain/timeline/Timeline';
import { CallOwnershipEvaluator } from '@/modules/task/domain/call/CallOwnershipEvaluator';
import type { IconStrategy } from '../types';
import type { Task } from '../../task/Task';
import { IconName } from '../types';

@injectable()
export class IncomingWaitingAndMine implements IconStrategy {
  constructor(private readonly callOwnershipEvaluator: CallOwnershipEvaluator) {}

  get(task: Task, { lastCall }: Timeline): null | IconName {
    return this.callOwnershipEvaluator.isMine(lastCall) && lastCall.isWaiting && lastCall.isIncoming
      ? IconName.IncomingWaitingAndMine
      : null;
  }
}
