import { HttpClientApi } from '@/modules/http-client/public/api';
import { injectable } from 'tsyringe';
import type { CrmRepository } from '../../domain/super-admin-settings/CrmRepository';
import type { InputCrm } from './InputCrm';

@injectable()
export class HttpCrmRepository implements CrmRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async get(): Promise<string> {
    const crm: InputCrm = await this.httpClient.get('/api/v0/facility/{facilityId}/crm');

    return crm.id;
  }

  async update(newCrmId: string): Promise<string> {
    const crm: InputCrm = await this.httpClient.put('/api/v0/facility/{facilityId}/crm', {
      id: newCrmId,
    });
    return crm.id;
  }
}
