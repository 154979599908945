import { injectable } from 'tsyringe';
import type { TotalTasks } from '../../domain/overview-total-tasks/TotalTasks';
import type { InputTotalTasks } from './InputTotalTasks';

@injectable()
export class TotalTasksFactory {
  make(input: InputTotalTasks): TotalTasks {
    return {
      current: {
        inboundTotal: input.current.inbound_total_tasks,
        outboundTotal: input.current.outbound_total_tasks,
      },
      previous: {
        inboundTotal: input.previous.inbound_total_tasks,
        outboundTotal: input.previous.outbound_total_tasks,
      },
    };
  }
}
