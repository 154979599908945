import type { FilterOption } from './FilterOption';

export class OthersOption implements FilterOption {
  readonly type = 'others';

  private constructor(
    readonly value: string,
    private readonly name: string,
  ) {}

  getName(): string {
    return this.name;
  }

  isMatchingOwner(ownerId: string): boolean {
    return this.value === ownerId;
  }

  static make(value: string, name: string): OthersOption {
    return new OthersOption(value, name);
  }
}
