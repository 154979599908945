import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { StoreWorkingHoursSiestaEvaluator } from '../../adapters/StoreWorkingHoursSiestaEvaluator';
import { HttpWorkingHourOverrideRepository } from '../../adapters/working-hours/HttpWorkingHourOverrideRepository';
import type { WorkingHourOverrideRepository } from '../../domain/working-hours/WorkingHourOverrideRepository';
import type { WorkingHoursSiestaEvaluator } from '../../domain/working-hours/WorkingHoursSiestaEvaluator';
import {
  WorkingHourListReadRepositoryToken,
  WorkingHourListWriteRepositoryToken,
  WorkingHourOverrideRepositoryToken,
  WorkingHoursSiestaEvaluatorToken,
} from '../token';
import { StoreWorkingHourListReadRepository } from '../../adapters/working-hours/regular-schedule/StoreWorkingHourListReadRepository';
import type { WorkingHourListReadRepository } from '../../domain/working-hours/regular-schedule/WorkingHourListReadRepository';
import type { WorkingHourListWriteRepository } from '../../domain/working-hours/regular-schedule/WorkingHourListWriteRepository';
import { HttpWorkingHourListWriteRepository } from '../../adapters/working-hours/regular-schedule/HttpWorkingHourListWriteRepository';

export class WorkingHoursProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<WorkingHourOverrideRepository>(
      WorkingHourOverrideRepositoryToken,
      HttpWorkingHourOverrideRepository,
    );

    container.register<WorkingHoursSiestaEvaluator>(
      WorkingHoursSiestaEvaluatorToken,
      StoreWorkingHoursSiestaEvaluator,
    );

    container.register<WorkingHourListReadRepository>(
      WorkingHourListReadRepositoryToken,
      StoreWorkingHourListReadRepository,
    );
    container.register<WorkingHourListWriteRepository>(
      WorkingHourListWriteRepositoryToken,
      HttpWorkingHourListWriteRepository,
    );
  }
}
