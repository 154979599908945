<template>
  <div>
    <voice-message-type-selector v-model="radioValue" :class="$b('type')" />

    <dp-textarea
      v-if="isText"
      v-model="textMessage"
      :placeholder="placeholder"
      :class="$b('textarea')"
      :validation="errors"
    />

    <voice-message-uploader v-if="isFile" v-model="fileUrl" :validation="errors" />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Model, Watch, Prop } from 'vue-facing-decorator';
import { DpTextarea } from '@dp-ui-kit/vue';

import type { Validation } from '@/ui/types';
import VoiceMessageUploader from './components/VoiceMessageUploader.vue';
import VoiceMessageTypeSelector from './components/VoiceMessageTypeSelector.vue';
import type { VoiceMessageData } from './VoiceMessageData';

@Component({
  name: 'VoiceMessage',
  components: {
    DpTextarea,
    VoiceMessageUploader,
    VoiceMessageTypeSelector,
  },
})
export default class VoiceMessage extends Vue {
  @Model({ type: Object, required: true })
  valueAgent: VoiceMessageData;

  @Prop({ type: Object, default: null })
  errors: Validation;

  initialFileUrl = '';

  get radioValue(): 'text' | 'file' {
    return this.valueAgent.type;
  }

  set radioValue(newType: 'text' | 'file') {
    if (newType === 'file') {
      this.valueAgent = {
        ...this.valueAgent,
        type: newType,
        placeholder: this.placeholder,
        fileUrl: this.fileUrl,
        fileId: this.valueAgent.fileId ?? '',
        isDirty: this.valueAgent.isDirty ?? false,
      };
      return;
    }

    this.valueAgent = {
      ...this.valueAgent,
      type: newType,
      placeholder: this.placeholder,
      value: this.textMessage,
    };
  }

  get textMessage(): string {
    return this.valueAgent.value ?? '';
  }

  set textMessage(newMessage: string) {
    this.valueAgent = {
      ...this.valueAgent,
      value: newMessage,
    };
  }

  get fileUrl(): string {
    return this.valueAgent.fileUrl ?? '';
  }

  set fileUrl(newFileUrl) {
    this.valueAgent = {
      ...this.valueAgent,
      fileUrl: newFileUrl,
      fileId: '',
      isDirty: newFileUrl !== this.initialFileUrl,
    };
  }

  get placeholder(): string {
    return this.valueAgent.placeholder || this.$ts('settings.voice_messages.placeholder');
  }

  get isText(): boolean {
    return this.radioValue === 'text';
  }

  get isFile(): boolean {
    return this.radioValue === 'file';
  }

  @Watch('valueAgent', { immediate: true })
  setInitialFileUrl(value: VoiceMessageData) {
    if (value.isDirty) {
      return;
    }

    this.initialFileUrl = value.fileUrl ?? '';
  }

  @Watch('errors', { immediate: true })
  onErrorsChanged(newErrors: Validation) {
    if (newErrors && Object.keys(newErrors).length > 0) {
      this.$emit('error-in-voice-message');
    }
  }
}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-voice-message {
  &__textarea {
    margin-bottom: unset;
  }

  &__type {
    margin-bottom: v.$spacer-md;
  }
}
</style>
