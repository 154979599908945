export const TimelineRepositoryToken = Symbol('TimelineRepository');
export const CallRepositoryToken = Symbol('CallWriteRepository');
export const ConversationRepositoryToken = Symbol('MessageWriteRepository');
export const MessageTemplateRepositoryToken = Symbol('MessageTemplateRepository');
export const MediaRepositoryToken = Symbol('MediaWriteRepository');
export const OwnerRepositoryToken = Symbol('OwnerRepository');
export const DateFormatterToken = Symbol('DateFormatter');
export const ClockToken = Symbol('Clock');
export const CallQualityReportRepositoryToken = Symbol('CallQualityReportRepository');
export const SummaryRepositoryToken = Symbol('SummaryRepository');
export const CallQualityModalManagerToken = Symbol('CallQualityModalManager');
export const AuthorizerToken = Symbol('Authorizer');
export const EventTrackerToken = Symbol('EventTracker');
export const MessageComposerRepositoryToken = Symbol('MessageComposerRepository');
export const AudioConverterToken = Symbol('AudioConverter');
