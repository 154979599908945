import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { Permission as StorePermission } from '@/api/models/Permission';
import type { PermissionReadRepository } from '../../domain/permissions/PermissionReadRepository';
import type { Permission } from '../../domain/permissions/Permission';
import { PermissionFactory } from './PermissionFactory';
import { PermissionError } from '../../domain/permissions/PermissionError';

interface Store {
  getters: {
    getFacilityPermissions: StorePermission[];
  };
}

@injectable()
export class StorePermissionReadRepository implements PermissionReadRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
    private readonly permissionFactory: PermissionFactory,
  ) {}

  async all(): Promise<Permission[]> {
    const permissions = this.store.getters.getFacilityPermissions;

    return this.permissionFactory.makeMany(permissions);
  }

  async getById(id: string): Promise<Permission> {
    const allPermissions = await this.all();
    const selectedPermission = allPermissions.find(permission => permission.id === id);

    if (!selectedPermission) {
      throw new PermissionError(`Permission with id: ${id} not found`);
    }

    return selectedPermission;
  }
}
