import type { DependencyContainer } from 'tsyringe';
import { injectable } from 'tsyringe';
import type { Router } from 'vue-router';
import type { DpProvider } from '@/core/di/DpProvider';
import { router as rootRouter } from '@/router';

export const RootRouterToken = Symbol('rootRouter');

@injectable()
export class RootRouterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<Router>(RootRouterToken, { useValue: rootRouter });
  }
}
