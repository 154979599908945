import { inject, injectable } from 'tsyringe';
import { PhoneWidgetWorkstationManagerToken } from '@/modules/authentication/di/token';
import type { PhoneWidgetWorkstationManager } from '@/modules/authentication/domain/PhoneWidgetWorkstationManager';
import { UserOAuthClient } from '@/modules/authentication/domain/oauth/UserOAuthClient';
import { UserSessionCreator } from './UserSessionCreator';

@injectable()
export class UserSessionDestroyer {
  constructor(
    @inject(PhoneWidgetWorkstationManagerToken)
    private readonly phoneWidgetWorkstationManager: PhoneWidgetWorkstationManager,
    private readonly oauthClient: UserOAuthClient,
    private readonly userSessionCreator: UserSessionCreator,
  ) {}

  async destroy(): Promise<void> {
    await this.logoutFromApi();

    await this.logoutFromDevices();

    await this.userSessionCreator.resetUserAndFacilityData();
  }

  private async logoutFromApi(): Promise<void> {
    this.oauthClient.deleteAccessToken();
  }

  private async logoutFromDevices(): Promise<void> {
    await this.phoneWidgetWorkstationManager.logoutFromAllWorkstation();
  }
}
