import type { RootState } from '@/store/types';
import type { Store } from 'vuex';
import type { Clock } from '@/common/root/domain/Clock';

export class StoreClock implements Clock {
  constructor(private readonly store: Store<RootState>) {}

  now(): Date {
    return new Date(this.store.getters.getCurrentTimestamp * 1000);
  }

  timestamp(): number {
    return this.store.getters.getCurrentTimestamp;
  }
}
