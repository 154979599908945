import { injectable } from 'tsyringe';
import type { DpListener } from '@/core/events/DpListener';
import { BookingOptionsPreloaderGate } from './BookingOptionsPreloaderGate';

@injectable()
export class FacilityChangedListener implements DpListener {
  constructor(private readonly bookingOptionsPreloaderGate: BookingOptionsPreloaderGate) {}

  async handle(): Promise<void> {
    this.bookingOptionsPreloaderGate.markFacilityChangedPending();
    await this.bookingOptionsPreloaderGate.tryPreload();
  }
}
