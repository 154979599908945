import { schema } from 'normalizr';
import { EntityType } from './entity-type';
import { workstationSchema } from './reception-phone';

export const WORKSTATION_ERROR_TYPES = Object.freeze({
  PSTN_UNREACHABLE: 'pstn_unreachable',
  SIP_NOT_REGISTERED: 'sip_not_registered',
  SIP_UNREACHABLE: 'sip_unreachable',
  CLOUD_WEB_NOT_REGISTERED: 'cloud_web_not_registered',
  CLOUD_MOBILE_NOT_REGISTERED: 'cloud_mobile_not_registered',
  DEVICE_MISCONFIGURATION: 'device_misconfiguration',
});

/**
 * @typedef {Object} Workstation error
 * @property {string} id
 * @property {string} workstation_id
 * @property {string} error_type
 * @property {number} count
 * @property {boolean} resolved
 * @property {string} created_at
 * @property {string} updated_at
 */
export const workstationErrorSchema = new schema.Entity(EntityType.WORKSTATION_ERROR, {
  workstation: workstationSchema,
});
