import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { ChatControllerToken } from '../tokens';
import type { ChatController } from '../../domain/chat/ChatController';
import { SalesForceChatController } from '../../adapters/chat/SalesForceChatController';

export class ChatProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.registerSingleton<ChatController>(ChatControllerToken, SalesForceChatController);
  }
}
