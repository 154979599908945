import type { TimelineMedia } from '../media/TimelineMedia';

export class TimelineWhatsAppMessage {
  constructor(
    readonly id: string,
    readonly direction: 'inbound' | 'outbound',
    readonly status: 'sent' | 'delivered' | 'read' | 'failed',
    readonly body: string,
    readonly createdAt: string,
    readonly ownerId: string | null,
    readonly media: TimelineMedia[],
  ) {}
}
