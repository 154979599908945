export interface VoicemailSettingsInput {
  enabled_after_hours: boolean;
  enabled_in_queue: boolean;
}

export class VoicemailSettings {
  readonly enabledAfterHours: boolean;

  readonly enabledInQueue: boolean;

  constructor(data: VoicemailSettingsInput) {
    this.enabledAfterHours = data.enabled_after_hours;
    this.enabledInQueue = data.enabled_in_queue;
  }
}
