import uploadCare from 'uploadcare-widget';

import { i18n } from '@/ui/plugins/i18n';

type AnyFunction = (...args: any[]) => any;

export class UploadCare {
  private readonly fileUploaderPublicKey = 'e69efacb79bcec81cb36';

  private readonly uploadCare = uploadCare;

  private readonly i18n = i18n;

  constructor(
    readonly acceptTypes: string[],
    readonly validator: AnyFunction,
    readonly hasPreview: boolean,
  ) {}

  get configuration(): Record<string, any> {
    return {
      publicKey: this.fileUploaderPublicKey,
      tabs: ['file', 'url', 'gdrive', 'dropbox', 'onedrive'],
      inputAcceptTypes: this.acceptTypes,
      validators: [this.validator],
      previewStep: this.hasPreview,
      crop: this.hasPreview,
      locale: this.i18n.global.locale,
    };
  }

  fileFromUpload(fileHash: string): any {
    return this.uploadCare.fileFrom('uploaded', fileHash, {
      publicKey: this.fileUploaderPublicKey,
    });
  }

  fileFromUrl(url: string): any {
    return this.uploadCare.fileFrom('url', url, { publicKey: this.fileUploaderPublicKey });
  }

  fileFromObject(file: File | Blob): any {
    return this.uploadCare.fileFrom('object', file, this.configuration);
  }

  openDialog(file: unknown): any {
    return this.uploadCare.openDialog(file, this.configuration);
  }
}
