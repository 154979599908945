import type { TaskStatus } from '../task/TaskStatus';

export class TaskFragment {
  constructor(
    readonly id: string,
    readonly status: TaskStatus,
    readonly subjectType: 'visit' | 'lost-reason' | 'informational' | null = null,
    readonly subjectName?: string,
    readonly visitConfirmationStatus:
      | 'rescheduled'
      | 'canceled'
      | 'confirmed'
      | 'new'
      | null = null,
  ) {}
}
