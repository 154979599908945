import type { DateString, PhoneNumber, UUID } from '@/types/commons';
import { schema } from 'normalizr';
import { EntityType } from './entity-type';

export enum STATUS {
  UNDETERMINED = '',
  WAITING = 'waiting',
  FAILED = 'failed',
  VERIFIED = 'verified',
}

export enum TYPES {
  CALLER_ID = 'caller_id',
  REDIRECTION = 'redirection',
}

type PhoneVerificationId = UUID;
type CallerIdVerificationCode = string;

export interface PhoneVerification {
  id: PhoneVerificationId;
  number: PhoneNumber;
  started_at: DateString;
  code: CallerIdVerificationCode;
  [TYPES.CALLER_ID]: STATUS;
  [TYPES.REDIRECTION]: STATUS;
}

/**
 * @typedef {Object} PhoneVerification
 * @property {string} id
 * @property {string} number
 * @property {string} started_at
 * @property {''|'failed'|'verified'|'waiting'} caller_id
 * @property {''|'failed'|'verified'|'waiting'} redirection
 */
export const phoneVerificationSchema = new schema.Entity(EntityType.PHONE_VERIFICATION, {});
