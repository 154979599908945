import { inject, injectable } from 'tsyringe';
import { UserRepositoryToken } from '../../di/tokens';
import type { User } from '../../domain/models/User';
import { Workstation } from '../../domain/models/Workstation';
import type { UserRepository } from '../../domain/repositories/UserRepository';
import type { InputWorkstation } from '../types/InputWorkstation';

@injectable()
export class WorkstationFactory {
  constructor(
    @inject(UserRepositoryToken)
    private readonly userRepository: UserRepository,
  ) {}

  makeMany(workstations: InputWorkstation[]): Workstation[] {
    return workstations.map(this.make, this);
  }

  private make(workstation: InputWorkstation): Workstation {
    return new Workstation(
      workstation.id,
      workstation.name,
      workstation.type,
      workstation.mode,
      workstation.internal_number,
      this.getUser(workstation.current_user),
    );
  }

  private getUser(userId?: string): User | null {
    if (!userId) {
      return null;
    }

    return this.userRepository.find(userId);
  }
}
