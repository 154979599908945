import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { RootErrorLoggerProvider } from '@/common/root/providers/RootErrorLoggerProvider';
import { RootCacheProvider } from '@/common/root/providers/RootCacheProvider';
import { RootDateFormatterProvider } from '@/common/root/providers/RootDateFormatterProvider';
import { RootLanguageConfigProvider } from '@/common/root/providers/RootLanguageConfigProvider';
import { RootNotificationsManagerProvider } from '@/common/root/providers/RootNotificationsManagerProvider';
import { RootRouterProvider } from '@/common/root/providers/RootRouterProvider';
import { RootStorageProvider } from '@/common/root/providers/RootStorageProvider';
import { RootTranslatorProvider } from '@/common/root/providers/RootTranslatorProvider';
import { RootPhoneNumberFormatterProvider } from '@/common/root/providers/RootPhoneNumberFormatterProvider';
import { RootStoreProvider } from '@/common/root/providers/RootStoreProvider';

export class RootModule implements DpModule {
  static readonly namespace = Symbol('RootModule');

  providers(): IDpProvider[] {
    return [
      RootCacheProvider,
      RootDateFormatterProvider,
      RootErrorLoggerProvider,
      RootLanguageConfigProvider,
      RootNotificationsManagerProvider,
      RootPhoneNumberFormatterProvider,
      RootRouterProvider,
      RootStorageProvider,
      RootStoreProvider,
      RootTranslatorProvider,
    ];
  }
}
