import type { Queue } from './Queue';
import type { QueueHandler } from './QueueHandler';
import type { QueueRunner } from './QueueRunner';

/**
 * {@link QueueRunner} implementation that processes items from the queue in chunks.
 * Main purpose is to process at once up to 6 items from the queue. What is a limit for http requests.
 * We don't want to spam user with too many http requests at once
 */
export class ChunkQueueRunner implements QueueRunner {
  private readonly maxRequestNumber = 6;

  constructor(private readonly chunkHandler: QueueHandler) {}

  async run(queue: Queue): Promise<void> {
    if (queue.isEmpty) {
      return;
    }

    const ids = queue.getMany(this.maxRequestNumber);

    await this.chunkHandler(ids);

    queue.removeMany(ids);
  }
}
