import { injectable } from 'tsyringe';
import type { NotificationsConfig, Options } from '@/app/notifications/types/NotificationsConfig';

@injectable()
export class NotificationsConfigFactory {
  private readonly options: Options = {
    'api-error': {
      type: 'error',
    },

    'api-success': {
      type: 'success',
    },

    network: {
      type: 'warning',
      iconClass: 'danger pt-1',
      position: 'top-right',
      duration: 0,
    },

    upload: {
      type: 'error',
    },

    'workstation-error': {
      type: 'error',
      duration: 0,
    },

    'web-phone-connection-warning': {
      type: 'warning',
      iconClass: 'warning pt-1',
      duration: 0,
    },

    'group-changed': {
      type: 'info',
    },
  };

  get(name: string): NotificationsConfig {
    return this.options[name];
  }
}
