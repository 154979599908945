import { RootDateFormatterToken, RootTimeZoneRepositoryToken } from '@/common/root';
import type { DateFormatter, TimeZoneRepository } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import { VisitSynchronizationStatus } from '../../domain/facility-integration/VisitSynchronizationStatus';
import type { InputVisitSynchronizationDTO } from './types/InputVisitSynchronizationDTO';

@injectable()
export class VisitSynchronizationStatusFactory {
  constructor(
    @inject(RootDateFormatterToken)
    private readonly dateFormatter: DateFormatter,
    @inject(RootTimeZoneRepositoryToken)
    private readonly timezoneRepository: TimeZoneRepository,
  ) {}

  make(visitSynchronizationDTO: InputVisitSynchronizationDTO): VisitSynchronizationStatus {
    return new VisitSynchronizationStatus(
      this.dateFormatter
        .dateFromIso(visitSynchronizationDTO.synced_at, this.timezoneRepository.get())
        .toDefaultDate(),
      visitSynchronizationDTO.count,
    );
  }
}
