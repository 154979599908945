import type { Visit } from '@/modules/book-visit/domain/visit/Visit';

export class VisitConfirmation implements Visit {
  constructor(
    readonly status: 'new' | 'canceled' | 'confirmed' | 'rescheduled',
    readonly patientDetailUrl: string,
    private readonly visit: Visit,
  ) {}

  get startAt(): Date {
    return this.visit.startAt;
  }

  get serviceName(): string {
    return this.visit.serviceName;
  }

  get doctorName(): string {
    return this.visit.doctorName;
  }
}
