import { injectable } from 'tsyringe';
import { DeviceManager } from '../../domain/webphone/DeviceManager';

@injectable()
export class WebphoneApi {
  constructor(private readonly deviceManager: DeviceManager) {}

  async call(to: string): Promise<void> {
    return this.webphoneCall(to);
  }

  private webphoneCall(to: string): Promise<void> {
    return this.deviceManager.makeCall(to);
  }
}
