import type { Call as DomainTimelineCall } from '@/modules/timeline/domain/call/Call';
import { TimelineCall } from './TimelineCall';

export class OutputTimelineCallFactory {
  tryMake(timelineCall: DomainTimelineCall | null): TimelineCall | null {
    return timelineCall ? this.make(timelineCall) : null;
  }

  makeMany(timelineCalls: DomainTimelineCall[]): TimelineCall[] {
    return timelineCalls.map(this.make, this);
  }

  make(timelineCall: DomainTimelineCall): TimelineCall {
    return new TimelineCall(
      timelineCall.id,
      timelineCall.sid,
      timelineCall.state,
      timelineCall.direction === 'inbound' ? 'incoming' : 'outgoing',
      timelineCall.createdAt.isoDate,
      timelineCall.updatedAt,
      timelineCall.startWaitingAt,
      timelineCall.answeredAt,
      timelineCall.duration,
      timelineCall.hasQualityReports,
      timelineCall.recordingUrl,
      timelineCall.voicemailUrl,
      timelineCall.channelId,
      timelineCall.channelName,
      timelineCall.owner?.id ?? '',
      timelineCall.workstationId,
    );
  }
}
