export class AccessTokenGrantError extends Error {
  constructor() {
    super('Credentials are invalid for token grant');

    // Changing prototype for extending default class is recommended by TS
    // https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, AccessTokenGrantError.prototype);

    this.name = 'AccessTokenGrantError';
  }
}
