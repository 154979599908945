<template>
  <div :class="$b()">
    <slot />
    <small :class="$b('message')">
      <dp-icon-hide :size="12" />
      {{ $ts('task.message.not_visible_by_user') }}
    </small>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator';
import { DpIconHide } from '@dp-ui-kit/icons';

@Component({
  name: 'HistoryItemServiceMessage',
  components: { DpIconHide },
})
export default class HistoryItemServiceMessage extends Vue {}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

$timestamp-height: 1.75rem;

.dp-history-item-service-message {
  position: relative;
  display: inline-block;
  background: none;
  padding-right: v.$spacer-lg;
  line-height: v.$line-height-loose;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 4px;
    border-radius: v.$border-radius;
    background: v.$gray-300;
  }

  &__message {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: v.$spacer-sm;
    color: v.$gray-600;
    font-size: v.$font-size-sm;
  }
}
</style>
