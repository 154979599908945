<template>
  <ul :class="$b()">
    <li v-for="item in items" :key="item.name">
      <router-link
        :is="itemType(item)"
        :to="item.route"
        :active-class="$b('item', { active: true })"
        :class="$b('item', itemClassModifiers(item))"
        @click="$emit('item-click', item)"
      >
        <component :is="item.icon" v-if="item.icon" :size="20" :class="$b('icon')" />
        <span :class="$b('label')">{{ item.name }}</span>
        <counter-badge
          v-if="typeof item.counter === 'number'"
          :value="item.counter"
          :max-value="99"
          :class="$b('badge')"
        />
      </router-link>
    </li>
  </ul>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

import CounterBadge from '@/ui/atoms/CounterBadge/CounterBadge.vue';
import type { SidebarMenuItem } from '@/ui/organisms/SidebarMenu/SidebarMenu.types';

@Component({
  name: 'SidebarMenu',
  components: { CounterBadge },
})
export default class SidebarMenu extends Vue {
  @Prop({ type: Array, required: true })
  readonly items: SidebarMenuItem[];

  itemType(item: SidebarMenuItem): string | null {
    return item.disabled ? 'span' : 'router-link';
  }

  itemClassModifiers({ disabled }: SidebarMenuItem) {
    return { disabled };
  }
}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-sidebar-menu {
  $this: &;

  list-style-type: none;
  padding-left: 0;

  &__item {
    display: flex;
    align-items: center;
    padding: v.$spacer-sm v.$spacer-md v.$spacer-sm v.$spacer-lg;
    color: v.$gray-900;
    line-height: 1.8;
    border-radius: v.$border-radius;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: v.$gray-100;

      #{$this}__icon :deep(svg) {
        fill: v.$gray-600;
      }
    }

    &--active,
    &--active:hover,
    &--active:focus {
      font-weight: v.$font-weight-medium;
      color: v.$primary;
      background-color: v.$primary-light;

      #{$this}__icon :deep(svg) {
        fill: v.$primary;
      }

      #{$this}__badge {
        background: v.$primary;
        color: v.$white;
      }
    }

    &--disabled,
    &--disabled:hover,
    &--disabled:focus {
      font-weight: v.$font-weight-normal;
      color: v.$gray-500;
      cursor: not-allowed;
      background-color: transparent;

      #{$this}__icon :deep(svg) {
        fill: v.$gray-500;
      }
    }
  }

  &__label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__icon {
    margin-right: v.$spacer-sm;
    flex-shrink: 0;
  }

  &__badge {
    margin-left: auto;
    flex-shrink: 0;
  }
}
</style>
