import type { BrowserSupportValidator } from '@/modules/phone-widget/domain/webphone/environment-validation/BrowserSupportValidator';
import { Device } from '@twilio/voice-sdk';
import { injectable } from 'tsyringe';

@injectable()
export class TwilioBrowserSupportValidator implements BrowserSupportValidator {
  get isSupported(): boolean {
    return Device.isSupported;
  }
}
