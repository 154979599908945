<template>
  <div :class="$b()">
    <component :is="icon" :class="$b('icon')" :size="16" :color="iconColor" />
    <p :class="$b('content')">
      <slot />
    </p>
  </div>
</template>

<script lang="ts">
import type { Component as VueComponent } from 'vue';

import { Vue, Component, Prop } from 'vue-facing-decorator';

@Component({
  name: 'TaskTileContextField',
})
export default class TaskTileContextField extends Vue {
  @Prop({ type: Object, required: true })
  readonly icon: VueComponent;

  @Prop({ type: String, required: true })
  readonly iconColor: string;
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/mixins' as m;
@use '@/assets/scss/variables' as v;

.dp-task-tile-context-field {
  display: flex;
  align-items: center;

  &__icon {
    flex: none;
  }

  &__content {
    @include m.truncate;

    display: inline-block;
    margin-left: v.$spacer-sm;
  }
}
</style>
