import { RootLanguageConfigToken } from '@/common/root';
import type { LanguageConfigProvider } from '@/common/root/domain/LanguageConfigProvider';
import { IETFLanguage as RootIETFLanguage } from '@/common/root/domain/LanguageConfigProvider';
import { inject, injectable } from 'tsyringe';
import type { LanguageRepository } from '../../domain/tracking/repositories/LanguageRepository';
import { IETFLanguage } from '../../domain/tracking/models/IETFLanguage';

@injectable()
export class RootLanguageRepository implements LanguageRepository {
  constructor(
    @inject(RootLanguageConfigToken)
    private readonly languageConfig: LanguageConfigProvider,
  ) {}

  getIETFLanguage(): IETFLanguage {
    const { ietfLanguage } = this.languageConfig.get();

    return new IETFLanguage(this.mapLanguage(ietfLanguage));
  }

  private mapLanguage(
    ietfLanguage: RootIETFLanguage,
  ): 'en' | 'pt-br' | 'es-419' | 'es' | 'pl' | 'it' {
    return (
      {
        [RootIETFLanguage.br]: 'pt-br',
        [RootIETFLanguage.en]: 'en',
        [RootIETFLanguage.es]: 'es',
        [RootIETFLanguage.it]: 'it',
        [RootIETFLanguage.mx]: 'es-419',
        [RootIETFLanguage.pl]: 'pl',
      } as const
    )[ietfLanguage];
  }
}
