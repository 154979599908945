import { RootStoreToken } from '@/common/root';
import { MUTATION_ENTITIES_UPDATE } from '@dp-vue/entities';
import { inject, injectable } from 'tsyringe';
import type { Store } from 'vuex';
import type { Task } from '../../domain/real-time-updates/Task';
import type { TaskWriteRepository } from '../../domain/real-time-updates/TaskWriteRepository';

@injectable()
export class StoreTaskWriteRepository implements TaskWriteRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store<unknown>,
  ) {}

  update(entities: unknown): void {
    this.store.commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });
  }

  async updateGroupForTask(task: Task): Promise<void> {
    await this.store.dispatch('UPDATE_TASK_ID_IN_COUNTERED_GROUPS', task);
  }

  async updateGroupForTasks(tasks: Task[]): Promise<void> {
    await this.store.dispatch('UPDATE_TASK_IDS_IN_COUNTERED_GROUPS', tasks);
  }
}
