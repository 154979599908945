import type { Plugin, App } from 'vue';
import { AppStatusSupervisorFactory } from '@/app/app-status/AppStatusSupervisorFactory';
import type { Store } from 'vuex';
import type { RootState } from '@/store/types';
import type { ComponentStatus } from '@/app/app-status/component/Component';

interface PluginOptions {
  store: Store<RootState>;
}

export const appStatusPlugin: Plugin = {
  async install(app: App, { store }: PluginOptions): Promise<void> {
    const appStatusSupervisor = AppStatusSupervisorFactory.create((newStatus: ComponentStatus) => {
      store.commit('SET_APP_STATUS', newStatus);
    });

    await appStatusSupervisor.checkAndWatch();
  },
};
