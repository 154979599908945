import { HttpClientApi } from '@/modules/http-client/public/api';
import { injectable } from 'tsyringe';
import type { InboundAttendance } from '../../domain/overview-inbound-attendance/InboundAttendance';
import type { InboundAttendanceRepository } from '../../domain/overview-inbound-attendance/InboundAttendanceRepository';
import { InboundAttendanceFactory } from './InboundAttendanceFactory';
import type { InputInboundAttendance } from './InputInboundAttendance';

@injectable()
export class HttpInboundAttendanceRepository implements InboundAttendanceRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private inboundAttendanceFactory: InboundAttendanceFactory,
  ) {}

  async get(
    start: string,
    end: string,
    previousStart: string,
    previousEnd: string,
  ): Promise<InboundAttendance> {
    const inputInboundAttendance: InputInboundAttendance = await this.httpClient.get(
      `/api/v0/facility/{facilityId}/stats/inbound-traffic-attendance`,
      {
        start,
        end,
        previousStart,
        previousEnd,
      },
    );

    return this.inboundAttendanceFactory.make(inputInboundAttendance);
  }
}
