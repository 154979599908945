import type { DpProvider } from '@/core/di/DpProvider';
import { DpEventBus } from '@/core/events/DpEventBus';
import { injectable } from 'tsyringe';
import { ClientAuthorized } from '../../domain/events/ClientAuthorized';
import { ClientUnauthorized } from '../../domain/events/ClientUnauthorized';
import { FinishReconnectListener } from '../../domain/listeners/FinishReconnectListener';
import { InitializeReconnectListener } from '../../domain/listeners/InitializeReconnectListener';

@injectable()
export class ClientReconnectionProvider implements DpProvider {
  constructor(
    private readonly eventBus: DpEventBus,
    private readonly finishReconnectListener: FinishReconnectListener,
    private readonly initializeReconnectListener: InitializeReconnectListener,
  ) {}

  boot(): void {
    this.eventBus.subscribe(ClientAuthorized.eventName, this.finishReconnectListener);
    this.eventBus.subscribe(ClientUnauthorized.eventName, this.initializeReconnectListener);
  }
}
