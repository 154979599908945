import { injectable } from 'tsyringe';
import type { HttpAddress } from '@/modules/book-visit/adapters/doctor/HttpAddress';
import { Address } from '@/modules/book-visit/domain/doctor/Address';

@injectable()
export class HttpAddressFactory {
  make({
    id,
    name,
    street,
    city_name,
    post_code,
    nearest_slot_date,
    has_slots,
    calendar_active,
    doctor_id,
  }: HttpAddress): Address {
    return new Address(
      id,
      doctor_id,
      name,
      street,
      city_name,
      post_code,
      nearest_slot_date ?? '',
      has_slots,
      calendar_active,
    );
  }
}
