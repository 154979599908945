import { inject, injectable } from 'tsyringe';
import type { TimelineRepository } from '@/modules/timeline/domain/timeline/TimelineRepository';
import type { Timeline } from '@/modules/timeline/domain/timeline/Timeline';
import { TimelineError } from '@/modules/timeline/domain/timeline/TimelineError';
import type { CallQualityModalManager } from '@/modules/timeline/domain/call/quality/CallQualityModalManager';
import { CallActionPermissionGuard } from '@/modules/timeline/domain/call/actions/CallActionPermissionGuard';
import type { TimelineDpChatMessage } from '@/modules/timeline/public/api/data-transfer/message/TimelineDpChatMessage';
import type { OutputTimeline } from './data-transfer/OutputTimeline';
import type { TimelineCall } from './data-transfer/call/TimelineCall';
import { OutputTimelineFactory } from './data-transfer/OutputTimelineFactory';
import { OutputTimelineCallFactory } from './data-transfer/call/OutputTimelineCallFactory';
import { TimelineMessagesCountOutput } from './data-transfer/message/TimelineMessagesCountOutput';
import { TimelineCallCountOutput } from './data-transfer/call/TimelineCallCountOutput';
import { CallQualityModalManagerToken, TimelineRepositoryToken } from '../../di/tokens';
import type { TimelineWhatsAppMessage } from './data-transfer/message/TimelineWhatsAppMessage';
import type { TimelineSmsMessage } from './data-transfer/message/TimelineSmsMessage';
import { OutputTimelineWhatsAppMessageFactory } from './data-transfer/message/OutputTimelineWhatsAppMessageFactory';
import { OutputTimelineSmsMessageFactory } from './data-transfer/message/OutputTimelineSmsMessageFactory';
import { OutputTimelineDpChatMessageFactory } from './data-transfer/message/OutputTimelineDpChatMessageFactory';

@injectable()
export class TimelineApi {
  constructor(
    @inject(TimelineRepositoryToken)
    private readonly timelineRepository: TimelineRepository,
    private readonly outputTimelineFactory: OutputTimelineFactory,
    private readonly outputTimelineCallFactory: OutputTimelineCallFactory,
    @inject(CallQualityModalManagerToken)
    private readonly callQualityModalManager: CallQualityModalManager,
    private readonly callActionPermissionGuard: CallActionPermissionGuard,
    private readonly outputTimelineWhatsAppMessageFactory: OutputTimelineWhatsAppMessageFactory,
    private readonly outputTimelineSmsMessageFactory: OutputTimelineSmsMessageFactory,
    private readonly outputTimelineDpChatMessageFactory: OutputTimelineDpChatMessageFactory,
  ) {}

  getTimeline(taskId: string): OutputTimeline {
    const timeline = this.fetchTimeline(taskId);

    if (!timeline) {
      throw new TimelineError(`Timeline with id ${taskId} not found`);
    }

    return this.outputTimelineFactory.make(timeline);
  }

  getMessages(
    taskId: string,
  ): TimelineWhatsAppMessage[] | TimelineSmsMessage[] | TimelineDpChatMessage[] {
    const timeline = this.fetchTimeline(taskId);

    if (!timeline) {
      throw new TimelineError(`Timeline with id ${taskId} not found`);
    }

    if (timeline.events.whatsAppMessages) {
      return this.outputTimelineWhatsAppMessageFactory.makeMany(
        timeline.events.whatsAppMessages.reverse(),
      );
    }

    if (timeline.events.dpChatMessages) {
      return this.outputTimelineDpChatMessageFactory.makeMany(
        timeline.events.dpChatMessages.reverse(),
      );
    }

    return this.outputTimelineSmsMessageFactory.makeMany(timeline.events.smsMessages.reverse());
  }

  getCallHistory(taskId: string): TimelineCall[] {
    const timeline = this.fetchTimeline(taskId);

    return this.outputTimelineCallFactory.makeMany(timeline?.events.calls.reverse() || []);
  }

  getCallCount(taskId: string): TimelineCallCountOutput {
    const timeline = this.fetchTimeline(taskId);

    if (!timeline) {
      throw new TimelineError(`Timeline with id ${taskId} not found`);
    }

    return TimelineCallCountOutput.of(timeline.callCount);
  }

  getMessagesCount(taskId: string): TimelineMessagesCountOutput {
    const timeline = this.fetchTimeline(taskId);

    if (!timeline) {
      throw new TimelineError(`Timeline with id ${taskId} not found`);
    }

    return TimelineMessagesCountOutput.of(timeline.messagesCount);
  }

  canOpenCallQualityModal(): boolean {
    return this.callActionPermissionGuard.isAllowingQualityReporting();
  }

  openCallQualityModal(callId: string): void {
    if (this.canOpenCallQualityModal()) {
      this.callQualityModalManager.show(callId);
    }
  }

  private fetchTimeline(taskId: string): Timeline | null {
    return this.timelineRepository.findSync(taskId);
  }
}
