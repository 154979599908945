import type { InputParser } from './types';

export class InputDateParser {
  constructor(private readonly inputParser: InputParser) {}

  fromISO(date: string, timeZone: string): Date {
    return this.inputParser.toZonedTime(this.inputParser.parseISO(date), timeZone);
  }

  fromUnixTime(date: number, timeZone: string): Date {
    return this.inputParser.toZonedTime(this.inputParser.fromUnixTime(date), timeZone);
  }

  fromFormattedString(date: string, timeZone: string, format: string): Date {
    return this.inputParser.toZonedTime(this.inputParser.parse(date, format), timeZone);
  }
}
