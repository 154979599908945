import type { App } from 'vue';
import * as Sentry from '@sentry/vue';
import type { Integration, Options, ErrorEvent } from '@sentry/types';
import type { Router } from 'vue-router';
import type { Store } from 'vuex';
import type { RootState } from '@/store/types';
import { SentryIgnoredErrors } from './SentryIgnoredErrors';

export class SentryConfiguration implements Options {
  readonly dsn = 'https://d6bfe40bf33942cbabe55838aebb5226@o130295.ingest.sentry.io/5534902';

  readonly integrations: Integration[] = [];

  readonly tracesSampleRate = 0.05;

  readonly trackComponents = false;

  readonly ignoreErrors = new SentryIgnoredErrors().get();

  readonly environment = 'production';

  readonly debug = false;

  readonly transport = Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport);

  constructor(
    public readonly app: App,
    private readonly router: Router,
    public readonly release: string,
    private readonly store: Store<RootState>,
  ) {
    this.integrations.push(Sentry.browserTracingIntegration({ router, routeLabel: 'path' }));
  }

  // It cannot be in prototype in order to work correctly!
  beforeSend = (event: ErrorEvent): ErrorEvent | null => {
    const extendedEvent = event;

    extendedEvent.tags = extendedEvent.tags ?? {};

    // send user's network conditions info to Sentry as tags
    if (typeof navigator !== 'undefined') {
      extendedEvent.tags['connection.isOnline'] = navigator.onLine;
      extendedEvent.tags['connection.type'] = (navigator as any).connection?.effectiveType;
      extendedEvent.tags['connection.downlink'] = (navigator as any).connection?.downlink;
      extendedEvent.tags['connection.rtt'] = (navigator as any).connection?.rtt;
      extendedEvent.tags['connection.saveData'] = (navigator as any).connection?.saveData;
    }

    if (this.router) {
      extendedEvent.tags['router.name'] = this.router.currentRoute.value.name;
      extendedEvent.tags['router.fullPath'] = this.router.currentRoute.value.fullPath;
      extendedEvent.tags['router.redirectedFrom'] =
        this.router.currentRoute.value.redirectedFrom?.fullPath;
    }

    return extendedEvent;
  };

  tracesSampler = (): number => {
    // TODO find a better solution for this case
    if (this.store.getters.getUser.id === '396') {
      return 0;
    }

    return this.tracesSampleRate;
  };
}
