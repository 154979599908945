import { prop } from '@/utils/functions';

const pusherModule = {
  state: {
    activeUsers: [],
  },

  getters: {
    getActiveUsers: state => state.activeUsers,
    getActiveUsersIds: (_, getters) => getters.getActiveUsers.map(prop('id')) || [],
  },

  mutations: {
    SET_ACTIVE_USERS(state, data = []) {
      state.activeUsers = data;
    },
  },
};

export const pusherPlugin = store => {
  store.registerModule('pusher', pusherModule);
};
