export enum Status {
  Online = 'online',
  Pause = 'pause',
  Unknown = 'unknown',
}

export enum BackgroundColor {
  BrandPrimary = 'primary',
  White = 'white',
}
