import { inject, injectable } from 'tsyringe';
import { HelpWidgetVisibilityStatus } from '../HelpWidgetVisibilityStatus';
import type { EventSender } from '../tracking/EventSender';
import { ItemSelectedEvent } from '../tracking/ItemSelectedEvent';
import type { Trackable } from '../tracking/Trackable';
import type { ChatController } from './ChatController';
import { ChatControllerToken, EventSenderToken } from '../../di/tokens';

@injectable()
export class Chat implements Trackable {
  constructor(
    @inject(EventSenderToken)
    private readonly eventSender: EventSender,
    @inject(ChatControllerToken)
    private readonly chatController: ChatController,
    private readonly helpWidgetVisibilityStatus: HelpWidgetVisibilityStatus,
  ) {}

  async track(): Promise<void> {
    const businessEvent = ItemSelectedEvent.make('Chat');
    return businessEvent.track(this.eventSender);
  }

  async show(): Promise<void> {
    this.chatController.onDestroyed(() => {
      this.helpWidgetVisibilityStatus.show();
    });

    try {
      await this.chatController.show();
      this.helpWidgetVisibilityStatus.hide();
    } catch (e) {
      this.chatController.onDestroyed(null);
      throw e;
    }
  }
}
