import type { DpEvent } from '@/core/events/DpEvent';

export class StoreEntityEvent implements DpEvent {
  static readonly eventName = 'Common:StoreEntity';

  readonly name = StoreEntityEvent.eventName;

  constructor(
    readonly type: string,
    readonly value: string[],
  ) {}
}
