import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import { $GET_ENTITIES_BY_ID } from '@dp-vue/entities';
import type { TimelineRepository } from '@/modules/timeline/domain/timeline/TimelineRepository';
import { Timeline } from '@/modules/timeline/domain/timeline/Timeline';
import { EventsList } from '@/modules/timeline/domain/timeline/events-list/EventsList';
import type { Store, UnsubscribeHook } from './Store';
import type { StoreTask } from '../../StoreTask';
import { TaskContextRepository } from './context/TaskContextRepository';
import { StoreCallRepository } from './call/StoreCallRepository';
import { StoreMessageRepository } from './message/StoreMessageRepository';
import { StoreContactFormRepository } from './contact-form/StoreContactFormRepository';
import { StorePatientRequestRepository } from './patient-request/StorePatientRequestRepository';

@injectable()
export class StoreTimelineRepository implements TimelineRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
    private readonly taskContextRepository: TaskContextRepository,
    private readonly callRepository: StoreCallRepository,
    private readonly messageRepository: StoreMessageRepository,
    private readonly contactFormRepository: StoreContactFormRepository,
    private readonly patientRequestRepository: StorePatientRequestRepository,
  ) {}

  async find(taskId: string): Promise<Timeline | null> {
    const task = this.store.getters[$GET_ENTITIES_BY_ID]('task', [taskId])[0];

    if (!task) {
      return null;
    }

    return this.makeTimeline(task);
  }

  subscribeUpdates(taskId: string, callback: (timeline: Timeline) => void): UnsubscribeHook {
    return this.store.subscribe((mutation, state) => {
      if (mutation.type === 'MUTATION_ENTITIES_UPDATE') {
        const task = state.entities.task[taskId];

        if (task) {
          callback(this.makeTimeline(task));
        }
      }
    });
  }

  findSync(taskId: string): Timeline | null {
    const task = this.store.getters[$GET_ENTITIES_BY_ID]('task', [taskId])[0];

    if (!task) {
      return null;
    }

    return this.makeTimeline(task);
  }

  private makeTimeline(task: StoreTask): Timeline {
    return new Timeline(
      task.id,
      this.taskContextRepository.find(task.id),
      this.makeEventList(task),
    );
  }

  private makeEventList(task: StoreTask): EventsList {
    const timezone = this.store.getters.getFacilityTimeZone;

    return EventsList.make([
      ...this.callRepository.get(task, timezone),
      ...this.messageRepository.get(task, timezone),
      ...this.patientRequestRepository.get(task, timezone),
      ...this.contactFormRepository.get(task, timezone),
    ]);
  }
}
