import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import { HttpClientError } from '@/modules/http-client/public/types';
import { CreationError } from '@/modules/tasks-list/domain/create-task/CreationError';
import type { ValidationErrorMessage } from '@/modules/tasks-list/domain/ValidationErrorMessage';
import type { TaskCreationRepository } from '@/modules/tasks-list/domain/create-task/TaskCreationRepository';
import type { PhoneNumberList } from '@/modules/tasks-list/domain/create-task/phone-number/PhoneNumberList';

@injectable()
export class HttpTaskCreationRepository implements TaskCreationRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async create(
    comment: string,
    name: string,
    surname: string,
    phoneNumber: string,
    priority: number,
  ): Promise<void> {
    try {
      await this.httpClient.post('api/v0/facility/{facilityId}/task/outbound-call', {
        comment,
        patient: {
          name,
          surname,
          phoneNumber,
          priority,
        },
      });
    } catch (e) {
      this.handleValidation(e);
    }
  }

  async createByFile(comment: string, mediaId: string): Promise<void> {
    try {
      await this.httpClient.post('api/v0/facility/{facilityId}/task-import', {
        name: comment,
        fileId: mediaId,
      });
    } catch (e) {
      this.handleValidation(e);
    }
  }

  async createByPhoneNumbers(comment: string, phoneNumbers: PhoneNumberList): Promise<void> {
    try {
      await this.httpClient.post('api/v0/facility/{facilityId}/task-import/create-tasks', {
        name: comment,
        numbers: phoneNumbers.toArray(),
      });
    } catch (e) {
      this.handleValidation(e);
    }
  }

  private handleValidation(error: HttpClientError) {
    if (!error.data || error.statusCode !== 422) {
      throw error;
    }

    throw new CreationError(error.data.errors as ValidationErrorMessage[]);
  }
}
