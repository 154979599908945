import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { DoctorWithAddressRepository } from '../../domain/doctor/DoctorWithAddressRepository';
import type { DoctorWithAddress } from '../../domain/doctor/DoctorWithAddress';
import { HttpDoctorWithAddressFactory } from './HttpDoctorWithAddressFactory';
import type { InputResponse } from '../InputResponses';
import type { HttpDoctorWithAddresses } from './HttpDoctorWithAddresses';

@injectable()
export class HttpDoctorWithAddressRepository implements DoctorWithAddressRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly doctorWithAddressFactory: HttpDoctorWithAddressFactory,
  ) {}

  async get(): Promise<DoctorWithAddress[]> {
    const doctorWithAddresses: InputResponse<HttpDoctorWithAddresses> = await this.httpClient.get(
      '/api/v0/facility/{facilityId}/booking/get-doctors',
    );

    // eslint-disable-next-line no-underscore-dangle
    return doctorWithAddresses._items
      .map(doctorWithAddress => this.doctorWithAddressFactory.make(doctorWithAddress))
      .flat();
  }
}
