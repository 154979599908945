import { phoneApi } from '@/api/phone-api';

const state = {
  patientUploadProgress: {},
};

const getters = {
  getPatientUploadProgress: state => state.patientUploadProgress,
};

const mutations = {
  SET_PATIENT_UPLOAD_PROGRESS(state, data) {
    const { id, ...content } = data;

    state.patientUploadProgress[id] = content;
  },
};

const actions = {
  async PATIENT_UPLOADER_ACTION({ rootGetters }, payload) {
    return phoneApi.withFacility(rootGetters.getUserFacilityID).patientUploader(payload);
  },

  async RUN_PATIENT_UPLOADER_ACTION({ rootGetters }, payload) {
    return phoneApi.withFacility(rootGetters.getUserFacilityID).runPatientUploader(payload);
  },
};

export const imports = {
  state,
  mutations,
  getters,
  actions,
};
