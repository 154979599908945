import type { PhoneNumber } from './PhoneNumber';
import type { SipCredential } from './SipCredential';

export class Workstation {
  private constructor(
    readonly id: string,
    readonly name: string,
    readonly mode: 'always' | 'signed-in' | 'backup' | 'never',
    readonly type: 'pstn' | 'sip' | 'web' | 'mobile',
    readonly internalNumber: string,
    readonly weight: number,
    readonly phoneNumber: PhoneNumber | null = null,
    readonly sipCredential: SipCredential | null = null,
  ) {}

  get canBeUsedAsFallback(): boolean {
    return this.type === 'pstn' || this.type === 'sip';
  }

  get isReceptionWorkstation(): boolean {
    return this.mode !== 'never';
  }

  equals(workstation: Workstation): boolean {
    return this.id === workstation.id;
  }

  static make(
    id: string,
    name: string,
    mode: 'always' | 'signed-in' | 'backup' | 'never',
    type: 'pstn',
    internalNumber: string,
    weight: number,
    phoneNumber: PhoneNumber,
    sipCredential?: null,
  ): Workstation;

  static make(
    id: string,
    name: string,
    mode: 'always' | 'signed-in' | 'backup' | 'never',
    type: 'sip',
    internalNumber: string,
    weight: number,
    phoneNumber: undefined | null,
    sipCredential: SipCredential,
  ): Workstation;

  static make(
    id: string,
    name: string,
    mode: 'always' | 'signed-in' | 'backup' | 'never',
    type: 'web' | 'mobile',
    internalNumber: string,
    weight: number,
    phoneNumber?: null,
    sipCredential?: null,
  ): Workstation;

  static make(
    id: string,
    name: string,
    mode: 'always' | 'signed-in' | 'backup' | 'never',
    type: 'pstn' | 'sip' | 'web' | 'mobile',
    internalNumber: string,
    weight: number,
    phoneNumber: PhoneNumber | null = null,
    sipCredential: SipCredential | null = null,
  ): Workstation {
    if (type === 'pstn' && !phoneNumber) {
      throw new Error('A PSTN workstation must have a phone number');
    }

    if (type === 'sip' && !sipCredential) {
      throw new Error('A SIP workstation must have a SIP credential');
    }

    return new Workstation(
      id,
      name,
      mode,
      type,
      internalNumber,
      weight,
      phoneNumber,
      sipCredential,
    );
  }
}
