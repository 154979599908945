import { delay, injectable, inject } from 'tsyringe';
import type { NavigationGuard, NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { Authorization } from '../authorization/public/api';

@injectable()
export class Guard {
  constructor(
    @inject(delay(() => Authorization))
    private readonly authorization: Authorization,
  ) {}

  hasAuthorization(policyName: string): NavigationGuard {
    return (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ): void => {
      if (!this.authorization.can(policyName)) {
        next(false);
        return;
      }

      next();
    };
  }
}
