import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { DpEventBus } from '@/core/events/DpEventBus';
import { FacilityLoadedEvent } from '@/modules/facility/public/events';
import { CurrentUserWorkstationChangedEvent } from '@/modules/workstation/public/events';
import { FacilityLoadedListener } from '../../domain/ping/FacilityLoadedListener';
import { WorkstationChangedListener } from '../../domain/ping/WorkstationChangedListener';

export class EventBusProvider implements DpProvider {
  boot(container: DependencyContainer): void {
    const eventBus = container.resolve(DpEventBus);
    const facilityListener = container.resolve(FacilityLoadedListener);
    const workstationListener = container.resolve(WorkstationChangedListener);

    eventBus.subscribe(FacilityLoadedEvent.eventName, facilityListener);
    eventBus.subscribe(CurrentUserWorkstationChangedEvent.eventName, workstationListener);
  }
}
