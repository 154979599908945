import { inject, injectable } from 'tsyringe';
import {
  AccessTokenRequesterToken,
  ClientCredentialsRepositoryToken,
  LocaleDetectorToken,
  AccessTokenRepositoryToken,
} from '@/modules/authentication/di/token';
import type { LocaleDetector } from '../LocaleDetector';
import type { AccessTokenRequester } from './token/AccessTokenRequester';
import type { AccessTokenRepository } from './token/AccessTokenRepository';
import type { ClientCredentialsRepository } from './client/ClientCredentialsRepository';
import { OAuthClient } from './OAuthClient';
import { AccessToken } from './token/AccessToken';
import { AccessTokenRefreshError } from './token/errors/AccessTokenRefreshError';

@injectable()
export class UserOAuthClient extends OAuthClient {
  constructor(
    @inject(AccessTokenRequesterToken)
    private readonly accessTokenRequester: AccessTokenRequester,
    @inject(AccessTokenRepositoryToken)
    private readonly tokenRepository: AccessTokenRepository,
    @inject(LocaleDetectorToken)
    private readonly localeDetector: LocaleDetector,
    @inject(ClientCredentialsRepositoryToken)
    private readonly clientCredentialsRepository: ClientCredentialsRepository,
  ) {
    super();
  }

  // TODO use it with SSO
  async authorizeUsingCode(code: string, clientId: string, redirectUri: string): Promise<void> {
    const token = await this.accessTokenRequester.requestAccessTokenWithCode({
      grantType: 'authorization_code',
      code,
      clientId,
      redirectUri,
    });

    this.setAccessToken(token);
  }

  async authorizeUsingPassword(username: string, password: string): Promise<void> {
    const { locale } = this.localeDetector.getBasedOnDomain();
    const { clientId, clientSecret } = await this.clientCredentialsRepository.get();

    const token = await this.accessTokenRequester.requestAccessTokenWithPassword({
      grantType: 'password',
      username,
      password,
      locale,
      clientId,
      clientSecret,
    });

    this.setAccessToken(token);
  }

  async refreshAccessToken(): Promise<void> {
    const refreshToken = this.findAccessToken()?.refreshToken;

    if (!refreshToken) {
      throw new AccessTokenRefreshError();
    }

    const { clientId, clientSecret } = await this.clientCredentialsRepository.get();

    const token = await this.accessTokenRequester.requestAccessTokenWithRefreshToken({
      grantType: 'refresh_token',
      refreshToken,
      clientId,
      clientSecret,
    });

    this.setAccessToken(token);
  }

  findAccessToken(): AccessToken | null {
    return this.tokenRepository.findTokens('user');
  }

  deleteAccessToken(): void {
    this.tokenRepository.deleteTokens('user');
  }

  setAccessToken(accessToken: AccessToken): void {
    this.tokenRepository.saveTokens('user', accessToken);
  }
}
