import { RootStoreToken } from '@/common/root';
import { $GET_ENTITIES_BY_ID } from '@dp-vue/entities';
import { inject, injectable } from 'tsyringe';
import type { Patient } from '../../domain/Patient';
import { PatientFactory } from './PatientFactory';
import type { StorePatient } from './types/StorePatient';
import type { StoreTask } from './types/StoreTask';

type ReturnType<T> = T extends 'patient' ? StorePatient[] : T extends 'task' ? StoreTask[] : never;

interface Store {
  getters: {
    [$GET_ENTITIES_BY_ID]: <T extends 'patient' | 'task'>(type: T, ids: string[]) => ReturnType<T>;
  };
}

@injectable()
export class StorePatientRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
    private readonly patientFactory: PatientFactory,
  ) {}

  get(patientId: string): Patient | null {
    const [storePatient] = this.store.getters[$GET_ENTITIES_BY_ID]('patient', [patientId]);

    if (!storePatient) {
      return null;
    }

    return this.patientFactory.make(storePatient);
  }

  getByTaskId(taskId: string): Patient | null {
    const { patientId } = this.store.getters[$GET_ENTITIES_BY_ID]('task', [taskId])[0];

    return this.get(patientId);
  }
}
