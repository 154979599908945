import { ConferenceStatus } from './ConferenceStatus';

export class ConferenceTarget {
  constructor(
    readonly status: ConferenceStatus,
    readonly workstationId: string,
  ) {}

  static empty(): ConferenceTarget {
    return new ConferenceTarget(ConferenceStatus.OutOfConference, '');
  }
}
