import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { WorkstationRepository } from '../domain/WorkstationRepository';
import type { Workstation } from '../domain/Workstation';
import { WorkstationsFactory } from './WorkstationsFactory';
import type { InputWorkstation } from './types';

interface WorkstationStore {
  getters: {
    'settings/reception/getReceptionPhones': InputWorkstation[];
    'settings/reception/getAllReceptionPhones': InputWorkstation[];
  };
}

@injectable()
export class StoreWorkstationRepository implements WorkstationRepository {
  constructor(@inject(RootStoreToken) private readonly store: WorkstationStore) {}

  allReception(): Workstation[] {
    const workstations = this.store.getters['settings/reception/getReceptionPhones'];

    return workstations.map(WorkstationsFactory.make);
  }

  all(): Workstation[] {
    const workstations = this.store.getters['settings/reception/getAllReceptionPhones'];

    return workstations.map(WorkstationsFactory.make);
  }
}
