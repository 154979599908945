import type { TermsUrlFactory } from '@/modules/settings/domain/gates/TermsUrlFactory';
import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';

interface Store {
  getters: {
    getUserFacility: {
      terms_url: string;
    };
  };
}

@injectable()
export class StoreTermsUrlFactory implements TermsUrlFactory {
  constructor(@inject(RootStoreToken) private readonly store: Store) {}

  make(): string {
    return this.store.getters.getUserFacility.terms_url;
  }
}
