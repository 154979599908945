import type { DpEvent } from '@/core/events/DpEvent';
import type { DeviceState } from '../models/DeviceState';

export class DeviceStateChanged implements DpEvent {
  static readonly eventName = 'PhoneWidget:DeviceStateChanged';

  readonly name = DeviceStateChanged.eventName;

  constructor(public readonly state: DeviceState) {}
}
