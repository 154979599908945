<template>
  <i :class="iconClass">
    <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8351 16.3525L14.6126 16.0988C14.0788 16.0375 13.5538 16.2213 13.1776 16.5975L11.5676 18.2075C9.09132 16.9475 7.06132 14.9263 5.80132 12.4413L7.42007 10.8225C7.79632 10.4463 7.98007 9.92126 7.91882 9.38751L7.66507 7.18251C7.56007 6.29876 6.81632 5.63376 5.92382 5.63376H4.41007C3.42132 5.63376 2.59882 6.45626 2.66007 7.44501C3.12382 14.9175 9.10007 20.885 16.5638 21.3488C17.5526 21.41 18.3751 20.5875 18.3751 19.5988V18.085C18.3838 17.2013 17.7188 16.4575 16.8351 16.3525Z"
      />
      <path
        d="M18.1443 12.017C18.1083 12.2013 18.0182 12.3706 17.8855 12.5034C17.7963 12.5926 17.6901 12.663 17.5733 12.7105C17.4564 12.7579 17.3312 12.7815 17.2051 12.7797L11.8665 12.7797C11.6162 12.7797 11.3762 12.6803 11.1993 12.5033C11.0223 12.3263 10.9229 12.0864 10.9228 11.8361L10.9228 6.49746C10.9203 6.37192 10.9428 6.24714 10.9891 6.13041C11.0354 6.01369 11.1045 5.90737 11.1923 5.81768C11.2802 5.72799 11.3851 5.65674 11.5009 5.6081C11.6166 5.55946 11.7409 5.53441 11.8665 5.53441C11.9921 5.53441 12.1164 5.55946 12.2321 5.6081C12.3479 5.65675 12.4528 5.72799 12.5406 5.81768C12.6285 5.90737 12.6976 6.01369 12.7439 6.13041C12.7902 6.24714 12.8127 6.37192 12.8101 6.49746L12.8101 9.55779L18.5398 3.82814C18.627 3.73846 18.7311 3.667 18.8461 3.61792C18.9612 3.56883 19.0848 3.5431 19.2099 3.54222C19.3349 3.54133 19.4589 3.56531 19.5747 3.61277C19.6904 3.66022 19.7955 3.7302 19.8839 3.81864C19.9724 3.90708 20.0424 4.01221 20.0898 4.12793C20.1373 4.24365 20.1613 4.36764 20.1604 4.49271C20.1595 4.61778 20.1338 4.74142 20.0847 4.85645C20.0356 4.97149 19.9641 5.07562 19.8744 5.1628L14.1448 10.8925L17.2051 10.8925C17.3928 10.8899 17.5771 10.9434 17.7342 11.0461C17.8914 11.1488 18.0144 11.296 18.0874 11.469C18.1604 11.642 18.1802 11.8328 18.1443 12.017Z"
      />
    </svg>
  </i>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import BaseIcon from '@dp-ui-kit/icons/vue/BaseIcon.vue';

export default defineComponent({
  name: 'InboundPhoneIcon',
  extends: BaseIcon as any,
  iconName: 'inbound-phone',
});
</script>
