import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import type { NotificationService } from '../../domain/time/NotificationService';
import { UiNotificationService } from '../../adapters/time/UiNotificationService';
import { NotificationServiceToken } from '../tokens';

export class TimeProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<NotificationService>(NotificationServiceToken, UiNotificationService);
  }
}
