import { EntityType } from '@/store/schema/entity-type';
import { normalize, denormalize } from 'normalizr';
import { phoneSchema, PhoneTypes } from '@/store/schema/phone';
import { phoneVerificationSchema, STATUS, TYPES } from '@/store/schema/phone-verification';
import {
  MUTATION_ENTITIES_UPDATE,
  MUTATION_ENTITIES_MERGE,
  $GET_ENTITIES_BY_TYPE,
  $GET_ENTITIES_BY_ID,
} from '@dp-vue/entities';
import { toNamedParams } from '@/utils/vuex';
import { sortByWeight, compose, prop, equal } from '@/utils/functions';
import { phoneApi } from '@/api/phone-api';
import { settingsChannelsPhonesRepository } from '@/api/repositories';
import { normalizer } from '@/api/mappers';
import { WizardFormFields } from './utils/channels';

const getDefaultFormState = () => ({
  [WizardFormFields.ChannelType]: PhoneTypes.DpPhone,
  [WizardFormFields.ChannelName]: '',
  [WizardFormFields.PhoneNumber]: '',
});

const state = {
  formData: getDefaultFormState(),
  verificationChannelId: null,
  verificationProcessId: null,
  requireManualVerification: false,
  requireNewNumber: false,
};

const getters = {
  getFormData: state => state.formData,
  getPhones: toNamedParams(({ rootGetters }) => {
    const phones = rootGetters[$GET_ENTITIES_BY_TYPE](EntityType.PHONE) ?? [];
    return phones.sort(sortByWeight);
  }),
  hasManyPhones: toNamedParams(
    ({ rootGetters }) => rootGetters[$GET_ENTITIES_BY_TYPE](EntityType.PHONE).length > 1,
  ),
  getChannelForVerification: (state, getters) =>
    getters.getPhones.find(compose(equal(state.verificationChannelId), prop('id'))),
  externalPhoneNumber: (_, getters) => getters.getFormData[WizardFormFields.PhoneNumber],
  getCurrentVerification: toNamedParams(({ state, rootGetters, rootState }) => {
    const [verification = null] = rootGetters[$GET_ENTITIES_BY_ID](EntityType.PHONE_VERIFICATION, [
      state.verificationProcessId,
    ]);

    if (!verification) {
      return null;
    }

    return denormalize(verification, phoneVerificationSchema, rootState.entities);
  }),
  dpPhoneNumber: (_, getters) =>
    getters.getChannelForVerification.redirected_to || getters.getChannelForVerification.number,
  redirectionCode: (_, getters) => `*21*${getters.dpPhoneNumber}#`,
  status: (_, getters) => type => getters.getCurrentVerification?.[type],
  isUnverified: (_, getters) => type => !getters.status(type),
  isWaiting: (_, getters) => type => getters.status(type) === STATUS.WAITING,
  isVerified: (_, getters) => type => getters.status(type) === STATUS.VERIFIED,
  isFailed: (_, getters) => type => getters.status(type) === STATUS.FAILED,
  callerIdStatus: (_, getters) => getters.status(TYPES.CALLER_ID),
  redirectionStatus: (_, getters) => getters.status(TYPES.REDIRECTION),
  verificationCode: (_, getters) => getters.getCurrentVerification?.code ?? '',
  isCallerIdVerified: (_, getters) => getters.isVerified(TYPES.CALLER_ID),
  isRedirectionVerified: (_, getters) => getters.isVerified(TYPES.REDIRECTION),
  isCallerIdFailed: (_, getters) => getters.isFailed(TYPES.CALLER_ID),
  isRedirectionFailed: (_, getters) => getters.isFailed(TYPES.REDIRECTION),
  isVerifying: (_, getters) =>
    getters.isWaiting(TYPES.CALLER_ID) || getters.isWaiting(TYPES.REDIRECTION),

  hasSuccessVerifications: (_, getters) =>
    getters.isCallerIdVerified && getters.isRedirectionVerified,
  requireNewNumber: state => state.requireNewNumber,
  requireRedirectionVerification: (_, getters) =>
    getters.getFormData[WizardFormFields.ChannelType] === PhoneTypes.Redirected,
  requireManualVerification: state => state.requireManualVerification,
  canLoadWizard: (state, getters) => getters.requireNewNumber || state.verificationChannelId,
};

const mutations = {
  SET_FORM_DATA(state, { key, value }) {
    state.formData[key] = value;
  },

  CLEAR_FORM_DATA(state) {
    state.formData = getDefaultFormState();
  },

  SET_VERIFICATION_CHANNEL_ID(state, id) {
    state.verificationChannelId = id;
  },

  SET_MANUAL_VERIFICATION(state, value) {
    state.requireManualVerification = value;
  },

  SET_NUMBER_REQUIREMENT(state, value) {
    state.requireNewNumber = value;
  },

  SET_CURRENT_VERIFICATION_ID(state, value) {
    state.verificationProcessId = value;
  },
};

const actions = {
  async ADD_NEW_CHANNEL_PHONE_ACTION({ commit, dispatch }, payload) {
    const phone = await dispatch('ADD_PHONES_ACTION', payload, { root: true });

    const { entities } = normalize(phone, phoneSchema);
    commit(MUTATION_ENTITIES_MERGE, entities, { root: true });

    return phone;
  },

  async FETCH_PHONES_ACTION({ commit, rootGetters }) {
    const phones = await settingsChannelsPhonesRepository.get(rootGetters.getUserFacilityID);

    const { entities } = normalizer.settingsChannelsPhonesMapper.normalizeMany(phones);

    commit(MUTATION_ENTITIES_MERGE, entities, { root: true });

    return phones;
  },

  async REMOVE_FORWARDING_ACTION({ commit, rootGetters }, phoneId) {
    const phone = await phoneApi
      .withFacility(rootGetters.getUserFacilityID)
      .withPhones.removeForwarding(phoneId);

    const { entities } = normalize(phone, phoneSchema);
    commit(MUTATION_ENTITIES_MERGE, entities, { root: true });

    return phone;
  },

  SET_FORM_DATA_ACTION({ commit }, { key, value }) {
    commit('SET_FORM_DATA', { key, value });
  },

  CLEAR_FORM_DATA_ACTION({ commit }) {
    commit('SET_VERIFICATION_CHANNEL_ID', null);
    commit('SET_CURRENT_VERIFICATION_ID', null);
    commit('CLEAR_FORM_DATA');
    commit('SET_MANUAL_VERIFICATION', false);
    commit('SET_NUMBER_REQUIREMENT', false);
  },

  INIT_FOR_VERIFICATION_ACTION({ commit, getters }, { phoneId = null, callerId = null }) {
    const { name = '', id = null } = getters.getPhones.find(compose(equal(phoneId), prop('id')));

    if (!id) {
      return;
    }

    commit('SET_VERIFICATION_CHANNEL_ID', id);

    commit('SET_FORM_DATA', {
      key: WizardFormFields.ChannelType,
      value: PhoneTypes.Redirected,
    });

    commit('SET_FORM_DATA', {
      key: WizardFormFields.ChannelName,
      value: name,
    });

    commit('SET_FORM_DATA', {
      key: WizardFormFields.PhoneNumber,
      value: callerId,
    });
  },

  ADD_MANUAL_VERIFICATION_STEPS_ACTION({ commit }) {
    commit('SET_MANUAL_VERIFICATION', true);
  },

  ADD_BUYING_NUMBER_STEP_ACTION({ commit }) {
    commit('SET_NUMBER_REQUIREMENT', true);
  },

  async PERFORM_VERIFICATION_ACTION({ getters, commit }) {
    const { id } = getters.getChannelForVerification;
    const callerId = getters.externalPhoneNumber;

    return phoneApi
      .withPhone(id)
      .verifyPhone(callerId)
      .then(response => {
        const { entities } = normalize(response, phoneVerificationSchema);

        commit('SET_CURRENT_VERIFICATION_ID', response.id);

        commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });
      });
  },

  async SET_PHONES_ACTION({ dispatch, commit }, phones) {
    await dispatch('REMOVE_ALL_PHONES_ACTION');

    const { entities } = normalize(phones, [phoneSchema]);

    commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });
  },

  async REMOVE_ALL_PHONES_ACTION({ dispatch }) {
    await dispatch('DELETE_ENTITIES_GROUP_ACTION', EntityType.PHONE, { root: true });
  },

  UPDATE_VERIFICATION_STATUS_ACTION({ commit }, payload) {
    const { entities } = normalize(payload, phoneVerificationSchema);
    commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });
  },
};

export const channels = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
