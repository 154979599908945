import { injectable } from 'tsyringe';
import { Service } from '@/modules/book-visit/domain/service/Service';
import type { InputService } from './InputService';

@injectable()
export class ServiceFactory {
  make(service: InputService, addressId: string): Service {
    return new Service(service.id, service.name, addressId, service.price || 0);
  }
}
