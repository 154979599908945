export enum NavigationRoute {
  GeneralSettings = 'generalSettings',
  QuickReplies = 'quick-replies',
  WorkingHours = 'working-hours',
  Subjects = 'statuses',
  Channels = 'channels-settings',
  Workstations = 'work-stations',
  VoiceMessages = 'voice-messages',
  TrafficControl = 'traffic-control',
  Permissions = 'permissions',
  Contacts = 'contacts',
  Compliance = 'compliance',
  DataExport = 'data-export',
}
