import type { App, Plugin } from 'vue';
import type { Path, PathValue } from 'vue-i18n';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $ts(path: Path, values?: PathValue): string;
  }
}

export const safeTranslatePlugin: Plugin = {
  install(app: App): void {
    app.config.globalProperties.$ts = function translateSafe(
      path: Path,
      value?: PathValue,
    ): string {
      const result = this.$t(path, value);
      if (typeof result === 'string') {
        return result;
      }

      return path;
    };
  },
};
