import type { DpListener } from '@/core/events/DpListener';
import { injectable } from 'tsyringe';
import { EnvironmentAwareSender } from '../EnvironmentAwareSender';
import { DurationEventConverter } from './factories/DurationEventConverter';
import type { DurationEvent } from '../../models/DurationEvent';

@injectable()
export class DurationEventListener implements DpListener {
  constructor(
    private readonly sender: EnvironmentAwareSender,
    private readonly durationEventConverter: DurationEventConverter,
  ) {}

  handle(performanceEvent: DurationEvent): void {
    this.sender.trackEvent(this.durationEventConverter.make(performanceEvent));
  }
}
