import type { Comparable } from '@/modules/task/domain/Comparable';

export class Patient implements Comparable {
  constructor(
    readonly id: string,
    readonly phoneNumber: string,
    readonly fullName: string,
    readonly name: string,
    readonly surname: string,
    readonly note: string,
    readonly priority: boolean,
    readonly url: string,
  ) {}

  isEqual({ phoneNumber, name, surname, note, priority }: Patient): boolean {
    return (
      this.phoneNumber === phoneNumber &&
      this.name === name &&
      this.surname === surname &&
      this.note === note &&
      this.priority === priority
    );
  }

  static get empty(): Patient {
    return new Patient('', '', '', '', '', '', false, '');
  }

  copy(): Patient {
    return new Patient(
      this.id,
      this.phoneNumber,
      this.fullName,
      this.name,
      this.surname,
      this.note,
      this.priority,
      this.url,
    );
  }

  get hasNote(): boolean {
    return this.note.trim() !== '';
  }
}
