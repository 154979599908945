import { RootStoreToken } from '@/common/root';
import type { FacilityCountryCodeRepository } from '@/modules/statistics/domain/overview-callback/revenue/FacilityCountryCodeRepository';
import { inject, injectable } from 'tsyringe';

interface Store {
  getters: {
    getUserFacility?: { country_code: 'PL' | 'ES' | 'IT' | 'BR' | 'MX' };
  };
}

@injectable()
export class StoreFacilityCountryCodeRepository implements FacilityCountryCodeRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
  ) {}

  get(): 'PL' | 'ES' | 'IT' | 'BR' | 'MX' | '' {
    return this.store.getters.getUserFacility?.country_code ?? '';
  }
}
