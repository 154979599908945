export class User {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly avatar: string | null,
  ) {}

  static empty(): User {
    return new User('', '', null);
  }
}
