import type { Queue } from './Queue';
import type { QueueRunner } from './QueueRunner';

/**
 * Takes care of processing queue in a valid way. Which is:
 *   - process queue with specified timeout
 *   - executes valid {@link QueueRunner}, that can consume all queue or just portion of it
 *   - makes sure to re-run itself if the queue is not empty
 *   - makes sure there is no multiple timeouts that compete for processing queue
 */
export class QueueConsumer {
  private isConsumingQueue = false;

  private timeoutId: number | null = null;

  constructor(
    private readonly handler: QueueRunner,
    private readonly queue: Queue,
    private readonly queueTimeout: number,
  ) {}

  consume(): void {
    if (this.isConsumingQueue) {
      return;
    }

    this.consumeQueue();
  }

  private consumeQueue(): void {
    if (this.queue.isEmpty) {
      this.isConsumingQueue = false;
      return;
    }

    this.isConsumingQueue = true;

    window.setTimeout(async () => {
      await this.handler.run(this.queue);

      this.timeoutId = window.setTimeout(() => {
        this.timeoutId = null;
        this.consumeQueue();
      }, this.queueTimeout);
    }, 0);
  }

  reset(): void {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
  }
}
