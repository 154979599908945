<template>
  <div :class="$b()">
    <header ref="header" :class="$b('header')">
      <slot name="header" />
    </header>
    <div :class="$b('main')">
      <slot name="main">
        <router-view />
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Ref } from 'vue-facing-decorator';
import throttle from 'lodash-es/throttle';

@Component({
  name: 'LayoutWrapper',
})
export default class LayoutWrapper extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  readonly collapsingHeaderOffset: string;

  @Ref('header')
  readonly $header: HTMLElement;

  private startY = 0;

  private static readonly throttleWaitTime = 500;

  handleScroll = throttle((header: HTMLElement): void => {
    const { scrollY } = window;
    const isScrollUp = scrollY > this.startY;

    if (!header) {
      return;
    }

    if (isScrollUp) {
      header.style.transform = `translateY(-${this.collapsingHeaderOffset})`;
    } else {
      header.style.transform = `translateY(0)`;
    }

    this.startY = scrollY;
  }, LayoutWrapper.throttleWaitTime);

  onScroll() {
    this.handleScroll(this.$header);
  }

  mounted(): void {
    if (!this.collapsingHeaderOffset) {
      return;
    }

    window.addEventListener('scroll', this.onScroll);
  }

  beforeUnmount(): void {
    window.removeEventListener('scroll', this.onScroll);
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-layout-wrapper {
  &__header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    background: v.$white;
    box-shadow: 0 4px 8px rgb(0 0 0 / 4%);
    transition: transform 0.3s ease;
  }
}
</style>
