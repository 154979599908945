import { DpEventBus } from '@/core/events/DpEventBus';
import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { injectable } from 'tsyringe';
import type { EventPublisher } from '../../domain/webphone/events/EventPublisher';
import { EventPublisherToken } from '../tokens';
import { ActiveCallChanged } from '../../domain/webphone/events/ActiveCallChanged';
import { ActiveCallChangedListener } from '../../domain/webphone/restart/ActiveCallChangedListener';
import { ActiveCallChangeToCallChangeListener } from '../../domain/factories/ActiveCallChangeToCallChangeListener';

@injectable()
export class EventProvider implements DpProvider {
  constructor(private readonly eventBus: DpEventBus) {}

  register(container: DependencyContainer): void {
    container.registerSingleton<EventPublisher>(EventPublisherToken, DpEventBus);
  }

  boot(container: DependencyContainer): void {
    const activeCallChangedListener = container.resolve(ActiveCallChangedListener);
    const activeCallChangeToCallChangeListener = container.resolve(
      ActiveCallChangeToCallChangeListener,
    );

    this.eventBus.subscribe(ActiveCallChanged.eventName, activeCallChangedListener);
    this.eventBus.subscribe(ActiveCallChanged.eventName, activeCallChangeToCallChangeListener);
  }
}
