import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { AuthorizationModule } from '@/common/authorization';
import { AuthorizationProvider } from '@/common/legacy/router/di/providers/AuthorizationProvider';
import { RootModule } from '@/common/root';

export class RouterModule implements DpModule {
  readonly requires = [RootModule, AuthorizationModule];

  providers(): IDpProvider[] {
    return [AuthorizationProvider];
  }
}
