import { inject, injectable } from 'tsyringe';
import { CallTrackerResponse } from '@/modules/task/domain/call-tracking/trackers/CallTrackerResponse';
import type { CallTracker } from './CallTracker';
import type { Call } from '../../call/Call';
import type { Task } from '../../task/Task';
import { EventTrackerToken } from '../../../di/tokens';
import type { EventTracker } from '../../tracking-event/EventTracker';
import { TaskVisibilityChecker } from '../TaskVisibilityChecker';

@injectable()
export class UnansweredCallTracker implements CallTracker {
  constructor(
    @inject(EventTrackerToken)
    private readonly eventTracker: EventTracker,
    private readonly taskVisibilityChecker: TaskVisibilityChecker,
  ) {}

  track(call: Call, task: Task): CallTrackerResponse {
    if (call.isIncoming && call.isUnanswered && this.taskVisibilityChecker.isVisible(task)) {
      this.eventTracker.publishSingularEvent('Call unanswered');

      return CallTrackerResponse.EventPublished;
    }

    return CallTrackerResponse.EventNotPublished;
  }
}
