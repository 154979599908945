import type { DateString, NumericId, SID } from '@/api/types';
import type { ChannelName } from '@/api/types/entities/channel';
import type { Conference } from '@/api/types/entities/conference';
import type { User } from '@/api/types/entities/user';
import type { WorkstationId } from '@/api/types/entities/workstation';
import type { Call as RawCall } from '@/api/types/entities/call';
import { CallDirection as ApiCallDirection, CallStatus } from '@/api/types/entities/call';
import { Media } from './Media';
import { callSchema } from '../schemas/call-schema';

export type CallId = NumericId;

export enum CallDirection {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
}

export { CallStatus };

export class Call {
  static readonly schema = callSchema;

  readonly id: CallId;

  readonly direction: ApiCallDirection;

  readonly sid: SID;

  readonly status: CallStatus;

  readonly duration: number;

  readonly phone: ChannelName;

  readonly conference: Conference | null;

  readonly personnel: User | null;

  readonly recording: Media | null;

  readonly voicemail: Media | null;

  readonly workstation_id: WorkstationId | null;

  readonly created_at: DateString;

  readonly updated_at: DateString;

  readonly waiting_at: DateString | null;

  readonly answered_at: DateString | null;

  readonly has_quality_reports: boolean;

  readonly summary: string | null;

  readonly is_on_hold: boolean;

  private constructor(data: RawCall | Call) {
    this.id = data.id;
    this.direction = data.direction;
    this.sid = data.sid;
    this.status = data.status;
    this.duration = data.duration;
    this.phone = data.phone;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.waiting_at = data.waiting_at ?? null;
    this.answered_at = data.answered_at ?? null;
    this.conference = data.conference ?? null;
    this.personnel = data.personnel ?? null;
    this.recording = Media.tryMake(data.recording);
    this.workstation_id = data.workstation_id ?? null;
    this.voicemail = Media.tryMake(data.voicemail);
    this.has_quality_reports = data.has_quality_reports ?? false;
    this.summary = data.summary ?? null;
    this.is_on_hold = data.is_on_hold;
  }

  get parsedDirection(): CallDirection {
    return {
      [ApiCallDirection.Inbound]: CallDirection.Incoming,
      [ApiCallDirection.Outbound]: CallDirection.Outgoing,
    }[this.direction];
  }

  get workstationId(): WorkstationId | null {
    return this.workstation_id;
  }

  get createdAt(): DateString {
    return this.created_at;
  }

  get updatedAt(): DateString {
    return this.updated_at;
  }

  get waitingAt(): DateString | null {
    return this.waiting_at;
  }

  get answeredAt(): DateString | null {
    return this.answered_at;
  }

  isWaiting(): boolean {
    return this.status === CallStatus.Waiting;
  }

  isCompleted(): boolean {
    return this.status === CallStatus.Completed;
  }

  isSuccessful(): boolean {
    return [CallStatus.InProgress, CallStatus.Completed].includes(this.status);
  }

  isIncoming(): boolean {
    return this.isLastCallDirection(CallDirection.Incoming);
  }

  isOutgoing(): boolean {
    return this.isLastCallDirection(CallDirection.Outgoing);
  }

  isLastCallDirection(direction: CallDirection): boolean {
    return this.parsedDirection === direction;
  }

  get isOnHold(): boolean {
    return this.is_on_hold;
  }

  static make(data: RawCall | Call): Call {
    return new Call(data);
  }
}
