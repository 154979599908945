import { injectable } from 'tsyringe';
import { DpEventBus } from '@/core/events/DpEventBus';
import type { DpEvent } from '@/core/events/DpEvent';

@injectable()
export class EventEmitter {
  constructor(private readonly eventBus: DpEventBus) {}

  send(event: DpEvent): void {
    this.eventBus.publish(event);
  }
}
