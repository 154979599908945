import { injectable } from 'tsyringe';
import { IncomingCallHandler } from './IncomingCallHandler';
import { NewMessageHandler } from './NewMessageHandler';
import type { TaskUpdated } from './TaskUpdated';

@injectable()
export class TaskUpdatedHandler {
  constructor(
    private readonly incomingCallHandler: IncomingCallHandler,
    private readonly newMessageHandler: NewMessageHandler,
  ) {}

  handle(event: TaskUpdated): void {
    this.incomingCallHandler.handle(event);
    this.newMessageHandler.handle(event);
  }
}
