import { inject, injectable } from 'tsyringe';
import { AnonymizationDataRepositoryToken } from '../../di/token';
import type { AnonymizationDataRepository } from '../repositories/AnonymizationDataRepository';

@injectable()
export class AnonymizationData {
  constructor(
    @inject(AnonymizationDataRepositoryToken)
    private readonly anonymizationData: AnonymizationDataRepository,
  ) {}

  isEnabled(): boolean {
    return this.anonymizationData.isEnabled();
  }
}
