import { WorkstationRepositoryToken } from '@/modules/settings/di/token';
import { CallingGroup } from '@/modules/settings/domain/traffic-control/aggregates/CallingGroup';
import { Fallback } from '@/modules/settings/domain/traffic-control/models/Fallback';
import { FallbackMode } from '@/modules/settings/domain/traffic-control/models/FallbackMode';
import type { Workstation } from '@/modules/settings/domain/traffic-control/models/Workstation';
import type { WorkstationRepository } from '@/modules/settings/domain/traffic-control/WorkstationRepository';
import { inject, injectable } from 'tsyringe';
import type { InputPriorityGroup } from '../types/InputPriorityGroup';

@injectable()
export class CallingGroupFactory {
  constructor(
    @inject(WorkstationRepositoryToken)
    private readonly workstationRepository: WorkstationRepository,
  ) {}

  make(priorityGroup: InputPriorityGroup): CallingGroup {
    const workstations = this.getWorkstations(priorityGroup.destinations);
    const fallbackMode = priorityGroup.fallback_to_reception
      ? FallbackMode.RedirectToAllReceptionWorkstations
      : FallbackMode.NoRedirection;

    return new CallingGroup(workstations, new Fallback(fallbackMode, priorityGroup.timeout));
  }

  private getWorkstations(ids: string[]): Workstation[] {
    return this.workstationRepository.get(ids);
  }
}
