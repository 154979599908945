import type { Cache } from '@/common/root/domain/Cache';
import { RootCacheToken } from '@/common/root/providers/RootCacheProvider';
import { inject, injectable } from 'tsyringe';
import type { DoctorWithAddressRepository } from '@/modules/book-visit/domain/doctor/DoctorWithAddressRepository';
import type { DoctorWithAddress } from '@/modules/book-visit/domain/doctor/DoctorWithAddress';

@injectable()
export class CachedDoctorWithAddressRepository implements DoctorWithAddressRepository {
  constructor(
    private readonly inner: DoctorWithAddressRepository,
    @inject(RootCacheToken) private readonly cache: Cache,
  ) {}

  async get(): Promise<DoctorWithAddress[]> {
    const cacheKey = this.getCacheKey();
    const cachedValue = this.cache.get<DoctorWithAddress[]>(cacheKey);

    if (cachedValue) {
      return cachedValue;
    }

    const addresses = await this.inner.get();
    this.cache.set(cacheKey, addresses);
    return addresses;
  }

  private getCacheKey(): string {
    return 'doctors-with-addresses';
  }
}
