<template>
  <div v-dpp-tooltip="{ content: tooltipContent }" :class="$b()">
    <transition name="fade" mode="out-in">
      <button v-if="!isCopied" aria-label="Copy text" type="button" @click="copyToClipboard">
        <dp-icon-copy :size="16" />
      </button>
      <dp-icon-check-filled v-else color="meadow" :size="16" />
    </transition>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { DpIconCheckFilled, DpIconCopy } from '@dp-ui-kit/icons';

import { dppTooltip } from '@/ui/directives/dpp-tooltip';

@Component({
  name: 'CopyButton',
  components: {
    DpIconCheckFilled,
    DpIconCopy,
  },
  directives: {
    dppTooltip,
  },
})
export default class CopyButton extends Vue {
  tooltipContent = '';

  isCopied = false;

  @Prop({ type: String, default: '' })
  readonly value: string;

  mounted() {
    this.tooltipContent = this.copyLabel;
  }

  copyToClipboard(): void {
    navigator.clipboard.writeText(this.value).then((): void => {
      this.isCopied = true;
      this.tooltipContent = this.copiedLabel;

      setTimeout(() => {
        this.isCopied = false;
        this.tooltipContent = this.copyLabel;
      }, 2000);
    });
  }

  get copiedLabel(): string {
    return this.$ts('button_label.copied');
  }

  get copyLabel(): string {
    return this.$ts('button_label.copy');
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-copy-button {
  display: inline-block;
  padding: v.$spacer-xs;
  line-height: 0;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.1s ease-in-out;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
