import { injectable } from 'tsyringe';
import type { CallError } from '../models/CallError';
import type { DeviceError } from '../models/DeviceError';

@injectable()
export class IgnoredErrors {
  private readonly targetOffline = 'Error sent from gateway in HANGUP';

  private readonly highRiskNumber =
    'No International Permission. To call this phone number you must enable the High Risk:IRSF permission for';

  private readonly lowRiskNumber =
    'No International Permission. To call this phone number you must enable the Low Risk permission for';

  private readonly connectionError = 'A connection error occurred during the call';

  private readonly transport = 'No transport available to send or receive messages';

  private readonly unknown = ' An unknown error has occurred';

  private readonly ignoredErrorMessages: string[] = [
    this.targetOffline,
    this.lowRiskNumber,
    this.highRiskNumber,
  ];

  private readonly notTrackableMessages: string[] = [
    this.connectionError,
    this.transport,
    this.unknown,
  ];

  isIgnored(error: CallError | DeviceError): boolean {
    return this.ignoredErrorMessages.some(message => error.message.includes(message));
  }

  shouldNotBeTracked(error: CallError | DeviceError): boolean {
    return this.notTrackableMessages.some(message => error.message.includes(message));
  }
}
