import { phoneApi } from '@/api/phone-api';
import { authenticationApi } from '@/modules/authentication/public/api';

const initialUser = {
  id: null,
  username: '',
  has_password: null,
  created_at: null,
};

const state = {
  // TODO check where is used, and remove it if not needed;
  isAuthenticated: phoneApi.isAuthenticated(),
  user: initialUser,
};
const getters = {
  /** @returns UserProfile */
  getUser: (state, getters, rootState, rootGetters) => ({
    ...state.user,
    isManager: rootGetters.getUserFacility._acl === 'EDIT',
    roles: rootGetters.getRoles,
  }),
  isUserLoaded: state => !!state.user.id,
  hasPassword: state => !!state.user.has_password,
};
const mutations = {
  SET_USER(state, data) {
    state.user = data;
    state.isAuthenticated = authenticationApi().isAuthenticated();
  },

  DESTROY_USER(state) {
    state.isAuthenticated = false;
    state.user = initialUser;
  },
};
const actions = {
  async ACTION_UPDATE_PROFILE({ commit }, payload) {
    return phoneApi.withProfile.updateProfile(payload).then(data => {
      commit('SET_USER', data);
    });
  },
  async ACTION_UPDATE_PASSWORD(_, passwordObject) {
    return phoneApi.withProfile.updatePassword(passwordObject);
  },
};

export const profile = {
  state,
  getters,
  mutations,
  actions,
};
