export class Voicemail {
  constructor(
    readonly sent: boolean,
    readonly converted: boolean,
  ) {}

  get isEmpty(): boolean {
    return !this.sent;
  }

  static makeEmpty(): Voicemail {
    return new Voicemail(false, false);
  }
}
