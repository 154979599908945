export class Seconds {
  private constructor(public readonly value: number) {}

  lessThan(other: Seconds): boolean {
    return this.value < other.value;
  }

  lessThanOrEqual(other: Seconds): boolean {
    return this.value <= other.value;
  }

  static fromMinutes(minutes: number): Seconds {
    return this.make(minutes * 60);
  }

  static make(value: number): Seconds {
    return new Seconds(value);
  }

  static zero(): Seconds {
    return new Seconds(0);
  }
}
