import type { TimelineEvent } from '@/modules/task/domain/timeline/TimelineEvent';
import { IsoDate } from '@/modules/task/domain/time/IsoDate';
import type { TimezoneRepository } from '@/modules/task/domain/time/TimezoneRepository';

export class ContactForm implements TimelineEvent {
  constructor(
    readonly id: string,
    readonly createdAt: string,
    readonly channelId: string,
  ) {}

  readonly hasOwner = false;

  displayedDate(timezoneRepository: TimezoneRepository): IsoDate {
    return IsoDate.make(this.createdAt, timezoneRepository.get());
  }

  isMine(): boolean {
    return false;
  }

  isOwnedBy(): boolean {
    return false;
  }
}
