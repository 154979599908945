<template>
  <div :class="$b()">
    <div :class="$b('label')">
      {{ $t('anonymization_switch.label') }}
    </div>
    <div :class="$b('switch')">
      <dp-switch
        :model-value="isEnabled"
        value="anonymization-switch"
        :label="$t('anonymization_switch.info')"
        @update:model-value="save"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { DpSwitch } from '@dp-ui-kit/vue';

import { resolve } from '@/core/di';
import type { AnonymizationRepository } from '../domain/repositories/AnonymizationRepository';
import { AnonymizationRepositoryToken } from '../di/token';

@Component({
  name: 'AnonymizationSwitch',
  components: {
    DpSwitch,
  },
})
export default class AnonymizationSwitch extends Vue {
  isEnabled = false;

  get buttonLabel(): string {
    return !this.isEnabled
      ? this.$ts('anonymization_switch.enable')
      : this.$ts('anonymization_switch.disable');
  }

  get buttonType(): string {
    return !this.isEnabled ? 'primary' : 'danger';
  }

  private get anonymizationService(): AnonymizationRepository {
    return resolve(AnonymizationRepositoryToken);
  }

  async created(): Promise<void> {
    this.isEnabled = await this.anonymizationService.isEnabled();
  }

  async save(): Promise<void> {
    const response = await this.anonymizationService.save(this.isEnabled);
    this.isEnabled = response.enabled;
  }
}
</script>

<style lang="scss" scoped>
.dp-anonymization-switch {
  display: grid;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 0.5rem;

  &__label {
    grid-column: span 2 / span 2;
  }

  &__switch {
    grid-column: span 10 / span 10;
  }
}
</style>
