import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import {
  FacilityProfileRepositoryToken,
  IntegrationRepositoryToken,
  VisitSynchronizationRepositoryToken,
} from '../token';
import type { FacilityProfileRepository } from '../../domain/facility-integration/FacilityProfileRepository';
import { HttpFacilityProfileRepository } from '../../adapters/facility-integration/HttpFacilityProfileRepository';
import type { IntegrationRepository } from '../../domain/facility-integration/IntegrationRepository';
import { HttpIntegrationRepository } from '../../adapters/facility-integration/HttpIntegrationRepository';
import type { VisitSynchronizationRepository } from '../../domain/facility-integration/VisitSynchronizationRepository';
import { HttpVisitSynchronizationRepository } from '../../adapters/facility-integration/HttpVisitSynchronizationRepository';

@injectable()
export class FacilityIntegrationProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<FacilityProfileRepository>(
      FacilityProfileRepositoryToken,
      HttpFacilityProfileRepository,
    );
    container.register<IntegrationRepository>(
      IntegrationRepositoryToken,
      HttpIntegrationRepository,
    );
    container.register<VisitSynchronizationRepository>(
      VisitSynchronizationRepositoryToken,
      HttpVisitSynchronizationRepository,
    );
  }
}
