<template>
  <span :class="$b({ 'full-opacity': fullOpacity })" @click="$emit('click')">
    <slot />
  </span>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';

@Component({
  name: 'ClickableIcon',
  emits: ['click'],
})
export default class ClickableIcon extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly fullOpacity: boolean;
}
</script>

<style lang="scss" scoped>
.dp-clickable-icon {
  opacity: 0.5;
  cursor: pointer;

  &:hover,
  &--full-opacity {
    opacity: 1;
  }
}
</style>
