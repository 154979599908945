import { injectable } from 'tsyringe';
import type { OutboundTaskStatus } from '../../domain/outbound-task/OutboundTaskStatus';
import { OutboundTaskSwitcher } from '../../domain/outbound-task/OutboundTaskSwitcher';

@injectable()
export class OutboundTaskSwitcherApi {
  constructor(private readonly outboundTaskSwitcher: OutboundTaskSwitcher) {}

  async get(): Promise<OutboundTaskStatus> {
    return this.outboundTaskSwitcher.get();
  }

  async enable(): Promise<OutboundTaskStatus> {
    return this.outboundTaskSwitcher.enableCreatingTasks();
  }
}
