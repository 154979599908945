import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { LocalStorageNotificationSettingRepository } from '../../adapters/configuration/LocalStorageNotificationSettingRepository';
import type { NotificationSettingRepository } from '../../domain/configuration/NotificationSettingRepository';
import { NotificationSettingRepositoryToken } from '../tokens';

@injectable()
export class ConfigurationProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<NotificationSettingRepository>(
      NotificationSettingRepositoryToken,
      LocalStorageNotificationSettingRepository,
    );
  }
}
