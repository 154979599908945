import type { DataExport } from './DataExport';

export class DataExportCollection {
  constructor(
    public readonly items: DataExport[],
    public readonly totalItems: number,
  ) {}

  static empty(): DataExportCollection {
    return new DataExportCollection([], 0);
  }
}
