export class CurrentUser {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly isManager: boolean,
    readonly email: string | null,
    readonly createdAt: string | null,
    readonly roles: string[],
    readonly avatar: string | null,
    readonly workstationId: string | null,
    readonly phoneNumber: string | null,
  ) {}
}
