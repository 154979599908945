<template>
  <i :class="iconClass">
    <svg width="21" height="18" viewBox="0 0 21 18" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(3.7795276,0,0,3.7795276,-386.63086,-551.65429)">
        <path
          d="m 104.20087,145.95853 c -1.05202,0 -1.90479,0.85276 -1.90479,1.9048 0,0.35737 0.10007,0.69088 0.2713,0.97668 l -0.25425,0.92863 0.94827,-0.24908 c 0.27738,0.15774 0.59762,0.24908 0.93947,0.24908 0.3248,0 0.63054,-0.0814 0.89814,-0.2248 l -0.003,-0.48937 c -0.001,-0.0512 0.008,-0.10175 0.0269,-0.14935 0.0188,-0.0481 0.047,-0.0918 0.0832,-0.12867 0.0361,-0.0369 0.0794,-0.0665 0.12712,-0.0863 0.0477,-0.0195 0.0988,-0.0297 0.15038,-0.0295 0.0517,3e-4 0.10288,0.0108 0.15038,0.031 0.0475,0.0202 0.0905,0.05 0.12661,0.0868 0.0245,0.0251 0.0455,0.0532 0.0625,0.0837 0.17915,-0.29039 0.28267,-0.63268 0.28267,-0.9989 0,-1.05204 -0.85328,-1.9048 -1.90531,-1.9048 z m -0.65163,1.01596 -5.2e-4,5.2e-4 c 0.031,0 0.063,-1.6e-4 0.0904,0.001 0.034,7.9e-4 0.071,0.003 0.10646,0.0811 0.0421,0.093 0.13347,0.32671 0.14521,0.35037 0.0118,0.0236 0.0202,0.0512 0.004,0.0822 -0.0156,0.0317 -0.0236,0.051 -0.0465,0.0791 -0.0237,0.0273 -0.0494,0.0616 -0.0708,0.0822 -0.0237,0.0237 -0.0485,0.0495 -0.0212,0.0966 0.0273,0.0471 0.12233,0.20182 0.26251,0.3266 0.18019,0.16097 0.332,0.21044 0.37931,0.23409 0.0473,0.0237 0.075,0.0199 0.10232,-0.0119 0.0281,-0.031 0.11759,-0.13717 0.14934,-0.18448 0.0309,-0.0473 0.0625,-0.0393 0.10542,-0.0238 0.0435,0.0156 0.27567,0.12982 0.32298,0.15348 0.0473,0.0237 0.0785,0.0355 0.0904,0.0548 0.0122,0.0198 0.0118,0.11411 -0.0274,0.22428 -0.0392,0.11001 -0.23145,0.2163 -0.31781,0.22376 -0.0872,0.008 -0.16839,0.0394 -0.56638,-0.11731 -0.48006,-0.18907 -0.78301,-0.68086 -0.80667,-0.71261 -0.0236,-0.031 -0.19223,-0.25553 -0.19223,-0.48731 0,-0.23257 0.12213,-0.34647 0.16484,-0.39378 0.0435,-0.0473 0.0943,-0.0589 0.1261,-0.0589 z"
        />
        <path
          d="m 107.19909,150.5657 c -0.0103,0.0501 -0.0342,0.0961 -0.0703,0.13209 -0.0242,0.0242 -0.0531,0.0433 -0.0848,0.0563 -0.0317,0.0129 -0.0657,0.0193 -0.1,0.0188 h -1.44982 c -0.0679,-1e-5 -0.13313,-0.027 -0.18119,-0.075 -0.048,-0.048 -0.075,-0.11323 -0.075,-0.18119 v -1.44983 c -6.9e-4,-0.0341 0.005,-0.0679 0.018,-0.0997 0.0125,-0.0317 0.0313,-0.0606 0.0552,-0.0849 0.0238,-0.0243 0.0524,-0.0437 0.0838,-0.057 0.0314,-0.0132 0.0652,-0.02 0.0992,-0.02 0.0341,0 0.0679,0.007 0.0993,0.02 0.0314,0.0132 0.0599,0.0325 0.0838,0.0569 0.0238,0.0243 0.0426,0.0533 0.0552,0.085 0.0125,0.0317 0.0187,0.0656 0.018,0.0997 v 0.83111 l 1.55601,-1.55601 c 0.0237,-0.0243 0.0519,-0.0437 0.0832,-0.0571 0.0312,-0.0133 0.0649,-0.0203 0.0987,-0.0205 0.034,-2.4e-4 0.0676,0.006 0.0991,0.0192 0.0314,0.0129 0.0599,0.0319 0.084,0.0559 0.024,0.024 0.043,0.0526 0.0559,0.084 0.0129,0.0314 0.0194,0.0651 0.0192,0.0991 -2.4e-4,0.034 -0.007,0.0675 -0.0205,0.0987 -0.0134,0.0312 -0.0328,0.0595 -0.0571,0.0832 l -1.55601,1.55601 h 0.83109 c 0.051,-7e-4 0.101,0.0138 0.14369,0.0417 0.0427,0.0279 0.0761,0.0679 0.0959,0.11485 0.0198,0.047 0.0252,0.0987 0.0155,0.14883 z"
        />
      </g>
    </svg>
  </i>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import BaseIcon from '@dp-ui-kit/icons/vue/BaseIcon.vue';

export default defineComponent({
  name: 'InboundWhatsAppIcon',
  extends: BaseIcon as any,
  iconName: 'inbound-whats-app',
});
</script>
