import { singleton } from 'tsyringe';
import { DeviceCall } from '../../../domain/webphone/models/DeviceCall';
import { CallState } from '../../../domain/webphone/models/CallState';
import { DeviceState } from '../../../domain/webphone/models/DeviceState';
import type { StateFetcher } from '../../../domain/webphone/state/StateFetcher';

@singleton()
export class StaticStateFetcher implements StateFetcher {
  public deviceState: DeviceState = DeviceState.Unregistered;

  public callState: CallState = CallState.Closed;

  public activeCall: DeviceCall = DeviceCall.empty();
}
