import { ADDRESS_CHUNKS, BUNDLE_STATUSES, FIELDS } from '@/store/schema/compliance-bundle';
import { dateFormatter } from '@/app/formatters/date';
import { i18n } from '@/ui/plugins/i18n';
import { getAcceptableDocumentLabel } from './compliance/mappings';

const addressChunksInDisplayOrder = [
  ADDRESS_CHUNKS.ADDRESS,
  ADDRESS_CHUNKS.POSTAL_CODE,
  ADDRESS_CHUNKS.CITY,
  ADDRESS_CHUNKS.REGION,
];

const getBusinessName = ({ businessInformation }) => businessInformation?.name ?? '';

const getPersonalInformationName = ({ personalInformation }) => {
  if (!personalInformation) {
    return '';
  }

  const { firstName, lastName } = personalInformation;
  return `${firstName || ''} ${lastName || ''}`.trim();
};

const getBusinessAddress = ({ businessInformation }) => {
  if (!businessInformation) {
    return '';
  }

  return addressChunksInDisplayOrder
    .map(chunkName => {
      const value = businessInformation[chunkName] || '';

      if (chunkName !== ADDRESS_CHUNKS.POSTAL_CODE && value.length > 0) {
        return `${value},`;
      }

      return value;
    })
    .filter(Boolean) // filter empty values
    .join(' ')
    .replace(/,$/, ''); // remove trailing commas
};

const getBundleDocumentsLabel = ({ documents }) =>
  documents?.map(({ type }) => getAcceptableDocumentLabel(type)).join(', ') ?? '';

const getTranslatedStatus = status => {
  switch (status) {
    case BUNDLE_STATUSES.NEW:
      return i18n.global.t('settings.compliance.bundle.status.new');

    case BUNDLE_STATUSES.PROCESSING:
      return i18n.global.t('settings.compliance.bundle.status.processing');

    case BUNDLE_STATUSES.ACCEPTED:
      return i18n.global.t('settings.compliance.bundle.status.accepted');

    case BUNDLE_STATUSES.REJECTED:
      return i18n.global.t('settings.compliance.bundle.status.rejected');

    default:
      return status;
  }
};

const getDateToDisplay = ({ status, createdAt, acceptedAt }) => {
  switch (status) {
    case BUNDLE_STATUSES.NEW:
      return createdAt;

    case BUNDLE_STATUSES.ACCEPTED:
      return acceptedAt;

    default:
      return null;
  }
};

const getFormattedDate = (dateString, timeZone) => {
  if (!dateString) {
    return '';
  }

  return dateFormatter.dateFromIso(dateString, timeZone).toDefaultDate();
};

const getBundleStatusLabel = (bundle, timeZone) => {
  const translatedStatus = getTranslatedStatus(bundle.status);
  const dateToDisplay = getDateToDisplay(bundle);
  const dateLabel = dateToDisplay ? ` (${getFormattedDate(dateToDisplay, timeZone)})` : '';

  return `${translatedStatus}${dateLabel}`;
};

const getBundleStatusClassName = bundle => {
  switch (bundle.status) {
    case BUNDLE_STATUSES.ACCEPTED:
      return 'tw-text-green';

    case BUNDLE_STATUSES.REJECTED:
      return 'tw-text-red-600';

    default:
      return null;
  }
};

const getTransformedBundle = (bundle, timeZone) => ({
  id: bundle.id,
  [FIELDS.BUSINESS_NAME]: {
    value: getBusinessName(bundle),
  },
  [FIELDS.NAME]: {
    value: getPersonalInformationName(bundle),
  },
  [FIELDS.BUSINESS_ADDRESS]: {
    value: getBusinessAddress(bundle),
  },
  [FIELDS.DOCUMENTS]: {
    value: getBundleDocumentsLabel(bundle),
  },
  [FIELDS.STATUS]: {
    value: getBundleStatusLabel(bundle, timeZone),
    rawValue: bundle.status,
    props: {
      class: getBundleStatusClassName(bundle),
    },
  },
});

export const transformComplianceBundles = rootGetters => data => {
  const timeZone = rootGetters.getFacilityTimeZone;
  return {
    ...data,
    items: data.items.map(bundle => getTransformedBundle(bundle, timeZone)),
  };
};
