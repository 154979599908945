import type { Patient } from '../models/Patient';
import type { PatientSearchSuggestion } from '../models/data-transfer';

export function patientSearchSuggestionFactory(patient: Patient): PatientSearchSuggestion {
  return {
    id: patient.id ?? '',
    type: 'patient',
    uri: patient.uri,
    formattedNumber: patient.formattedNumber,
    fullName: patient.fullName,
  };
}
