import type { UserTokenPayload } from '@/modules/authentication/domain/login/UserTokenPayload';
import { inject, singleton } from 'tsyringe';
import { UserDataManagerToken, UserDataFetcherToken } from '@/modules/authentication/di/token';
import type { UserCredentials } from '@/modules/authentication/domain/login/UserCredentials';
import { UserOAuthClient } from '@/modules/authentication/domain/oauth/UserOAuthClient';
import type { UserAndFacilityData } from './me/models/UserAndFacilityData';
import type { UserDataFetcher } from './me/UserDataFetcher';
import type { UserDataManager } from './me/UserDataManager';
import { AccessToken } from '../oauth/token/AccessToken';

@singleton()
export class UserSessionCreator {
  private userAndFacilityData: UserAndFacilityData | null = null;

  constructor(
    private readonly oauthClient: UserOAuthClient,
    @inject(UserDataManagerToken)
    private readonly userDataManager: UserDataManager,
    @inject(UserDataFetcherToken)
    private readonly userDataFetcher: UserDataFetcher,
  ) {}

  async create({ username, password }: UserCredentials): Promise<void> {
    await this.oauthClient.authorizeUsingPassword(username, password);

    this.userAndFacilityData = await this.userDataFetcher.get();

    await this.userDataManager.persist(this.userAndFacilityData.legacyResponse);
  }

  async restore(): Promise<void> {
    if (this.userAndFacilityData === null) {
      this.userAndFacilityData = await this.userDataFetcher.get();
    }

    await this.userDataManager.persist(this.userAndFacilityData.legacyResponse);
  }

  async resetUserAndFacilityData(): Promise<void> {
    this.userAndFacilityData = null;
  }

  setUserToken({ accessToken, expiresIn, refreshToken }: UserTokenPayload): void {
    this.oauthClient.setAccessToken(AccessToken.make(accessToken, refreshToken, expiresIn));
  }
}
