import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { HttpClientApi } from '@/modules/http-client/public/api';
import { StoreFacilityIdHttpInterceptor } from '@/modules/facility/adapters/StoreFacilityIdHttpInterceptor';

export class HttpApiProvider implements DpProvider {
  boot(container: DependencyContainer): void {
    const httpClient = container.resolve(HttpClientApi);
    const interceptor = container.resolve(StoreFacilityIdHttpInterceptor);

    httpClient.addInterceptor(interceptor);
  }
}
