import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { Summary } from '@/modules/timeline/domain/call/summary/Summary';
import type { SummaryRepository } from '@/modules/timeline/domain/call/summary/SummaryRepository';

@injectable()
export class HttpSummaryRepository implements SummaryRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  approve({ callId }: Summary): Promise<void> {
    return this.httpClient.put(`/api/v0/facility/{facilityId}/call/${callId}/summary/approve`);
  }

  reject({ callId }: Summary): Promise<void> {
    return this.httpClient.put(`/api/v0/facility/{facilityId}/call/${callId}/summary/reject`);
  }

  edit({ callId }: Summary, newContent: string): Promise<void> {
    return this.httpClient.put(`/api/v0/facility/{facilityId}/call/${callId}/summary/edit`, {
      content: newContent,
    });
  }
}
