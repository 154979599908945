import { resolve } from '@/core/di';
import {
  AuthRepositoryToken,
  LocaleDetectorToken,
  SsoClientRepositoryToken,
  SsoFlowRepositoryToken,
  SsoIntegrationRepositoryToken,
  SsoTokenRepositoryToken,
} from '@/modules/authentication/di/token';
import type { AuthRepository } from '@/modules/authentication/domain/repositories/AuthRepository';
import type { LocaleDetector } from '@/modules/authentication/domain/LocaleDetector';
import { UserAuthenticator } from '@/modules/authentication/domain/login/UserAuthenticator';
import { SsoFlowInitializer } from '@/modules/authentication/domain/sso/login/SsoFlowInitializer';
import type { SsoFlowRepository } from '@/modules/authentication/domain/sso/login/SsoFlowRepository';
import type { SsoIntegrationRepository } from '@/modules/authentication/domain/sso/integration/SsoIntegrationRepository';
import type { SsoTokenRepository } from '@/modules/authentication/domain/sso/login/SsoTokenRepository';
import type { SsoClientRepository } from '@/modules/authentication/domain/sso/SsoClientRepository';
import { UserOAuthClient } from '@/modules/authentication/domain/oauth/UserOAuthClient';

export const localeDetector = (): LocaleDetector => resolve(LocaleDetectorToken);
export const userAuthenticator = (): UserAuthenticator => resolve(UserAuthenticator);
export const authRepository = (): AuthRepository => resolve(AuthRepositoryToken);
export const ssoFlowRepository = (): SsoFlowRepository => resolve(SsoFlowRepositoryToken);
export const ssoFlowInitializer = (): SsoFlowInitializer => resolve(SsoFlowInitializer);
export const ssoIntegrationRepository = (): SsoIntegrationRepository =>
  resolve(SsoIntegrationRepositoryToken);
export const ssoTokenRepository = (): SsoTokenRepository => resolve(SsoTokenRepositoryToken);
export const oauthClient = (): UserOAuthClient => resolve(UserOAuthClient);
export const ssoClientRepository = (): SsoClientRepository => resolve(SsoClientRepositoryToken);
