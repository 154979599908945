import { BaseAxiosRepository } from '@/api/repositories/axios/BaseAxiosRepository';
import type { TaskId } from '@/api/models/Task';
import { Task } from '@/api/models/Task';
import type { TaskVisitRepository } from '@/api/repositories/types/TaskVisitRepository';
import type { Task as RawTask } from '@/api/types/entities/task';

export class AxiosTaskVisitRepository extends BaseAxiosRepository implements TaskVisitRepository {
  async cancelVisit(id: TaskId): Promise<Task> {
    const data = (await this.axiosClient.post(`/api/v0/task/${id}/visit/cancel`)) as RawTask;

    return Task.make(data);
  }

  async confirmVisit(id: TaskId): Promise<Task> {
    const data = (await this.axiosClient.post(`/api/v0/task/${id}/visit/confirm`)) as RawTask;

    return Task.make(data);
  }

  async rescheduleVisit(id: TaskId): Promise<Task> {
    const data = (await this.axiosClient.post(`/api/v0/task/${id}/visit/reschedule`)) as RawTask;

    return Task.make(data);
  }
}
