<template>
  <i :class="iconClass">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.639 1.554a1.054 1.054 0 0 1 2.108 0V2.64H10.5V1.554a1.054 1.054 0 0 1 2.108 0v1.089A2 2 0 0 1 14.5 4.64v8a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h.139V1.554Zm7.104 4.19a.708.708 0 0 0-.995 0L7.96 7.514 6.18 5.752a.708.708 0 0 0-.994 0 .692.692 0 0 0 0 .985L6.966 8.5l-1.781 1.763a.692.692 0 0 0 0 .985c.274.272.72.272.994 0L7.96 9.484l1.79 1.771c.274.272.72.272.995 0a.692.692 0 0 0 0-.984l-1.79-1.772 1.788-1.77a.691.691 0 0 0 0-.985Z"
      />
    </svg>
  </i>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import BaseIcon from '@dp-ui-kit/icons/vue/BaseIcon.vue';

export default defineComponent({
  name: 'AppointmentCanceledIcon',
  extends: BaseIcon as any,
  iconName: 'appointment-cancelled',
});
</script>
