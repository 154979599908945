import { ConferenceStatus } from './ConferenceStatus';

export class ConferencePatient {
  constructor(
    readonly status: ConferenceStatus,
    readonly isOnHold: boolean,
  ) {}

  static empty(): ConferencePatient {
    return new ConferencePatient(ConferenceStatus.OutOfConference, false);
  }
}
