import type { ErrorLogger } from '@/modules/phone-widget/domain/webphone/errors/ErrorLogger';
import { injectable } from 'tsyringe';
import { errorLogger } from '@/ui/plugins/error-logger';

@injectable()
export class SentryErrorLogger implements ErrorLogger {
  logAsInfo(error: Error): void {
    errorLogger.logAsInfo(error);
  }

  logAsError(error: Error, context: Record<string, any> = {}): void {
    errorLogger.logExceptionWithContext(error, context, { tags: 'phone-widget' });
  }
}
