import { resolve } from '@/core/di';
import type { NavigationGuard } from 'vue-router';
import { oauthClient } from '@/modules/authentication/ui/container';
import { UserSessionCreator } from '../domain/login/UserSessionCreator';

const userSessionCreator = (): UserSessionCreator => resolve(UserSessionCreator);

export const ifAuthenticated: NavigationGuard = async (to, from, next) => {
  const loginPage = { name: 'login', query: { target: to.fullPath } };

  if (oauthClient().isAuthenticated()) {
    try {
      await userSessionCreator().restore();
    } catch {
      next(loginPage);
      return;
    }

    next();
    return;
  }

  next(loginPage);
};

export const ifNotAuthenticated: NavigationGuard = (to, from, next) => {
  if (!oauthClient().isAuthenticated()) {
    next();

    return;
  }

  next('/app/home');
};

export const hasSsoFlowStateParams: NavigationGuard = (to, from, next) => {
  if (to.params.clientId && to.params.redirectUri && to.params.code) {
    next();
    return;
  }

  next({ name: 'login' });
};
