import { ErrorLoggerToken, EventTrackerToken } from '@/modules/tasks-list/di/tokens';
import { inject, injectable } from 'tsyringe';
import { TaskProcessingPerformanceTracker } from './TaskProcessingPerformanceTracker';
import type { ErrorLogger } from '../ErrorLogger';
import type { EventTracker } from '../EventTracker';

@injectable()
export class TaskProcessingPerformanceTrackerFactory {
  constructor(
    @inject(EventTrackerToken)
    private readonly eventTracker: EventTracker,
    @inject(ErrorLoggerToken)
    private readonly errorLogger: ErrorLogger,
  ) {}

  make(flow: 'api' | 'websocket'): TaskProcessingPerformanceTracker {
    return new TaskProcessingPerformanceTracker(flow, this.eventTracker, this.errorLogger);
  }
}
