import type { Component as VueComponent } from 'vue';

export enum BadgeIconColors {
  Success = 'success',
  Warning = 'warning',
  Gray = 'gray',
}

export interface BadgeIconInterface {
  icon: VueComponent;
  color?: string;
  noBackground?: boolean;
  tooltip?: string;
}
