import type { RouteRecordRaw } from 'vue-router';
import { ifNotAuthenticated } from '../guards';

export const loginRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth" */ './pages/Login.vue'),
    beforeEnter: ifNotAuthenticated,
  },
];
