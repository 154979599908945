export class BookingSlot {
  /**
   * Required for DpCalendar
   *
   * @see https://github.com/DocPlanner/dp-ui-kit/blob/develop/packages/vue/components/calendar/DpCalendar.vue#L323-L335
   */
  readonly link = null;

  constructor(
    readonly start: string,
    readonly booked: boolean,
    readonly duration: number,
    readonly serviceId: string,
  ) {}

  get isEmpty(): boolean {
    return this.start.length === 0;
  }

  belongsTo(serviceId: string): boolean {
    if (this.isEmpty || this.serviceId === '') {
      return false;
    }

    return this.serviceId === serviceId;
  }

  static makeEmpty(): BookingSlot {
    return new BookingSlot('', true, 0, '');
  }
}
