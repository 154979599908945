import { injectable } from 'tsyringe';
import type { DpListener } from '@/core/events/DpListener';
import { HubspotChatVisibilityManager } from './HubspotChatVisibilityManager';

@injectable()
export class ShowHubspotChatListener implements DpListener {
  constructor(private readonly hubspotChatVisibilityManager: HubspotChatVisibilityManager) {}

  handle(): void {
    this.hubspotChatVisibilityManager.show();
  }
}
