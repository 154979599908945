<template>
  <div :class="$b()">
    <slot />
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator';

@Component({
  name: 'TaskTileContent',
})
export default class TaskTileContent extends Vue {}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/functions' as f;

$gap: f.px-to-rem(20px);
$icon-size: f.px-to-rem(50px);

.dp-task-tile-content {
  margin-left: $gap + $icon-size;
}
</style>
