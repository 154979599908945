import { injectable } from 'tsyringe';
import type { RequestConfig, TokenInterception } from './TokenInterception';
import { AppOAuthClient } from '../AppOAuthClient';

@injectable()
export class AppApiTokenInterception implements TokenInterception {
  constructor(private readonly oauthClient: AppOAuthClient) {}

  async onRequest(config: RequestConfig): Promise<RequestConfig> {
    if (!this.oauthClient.isAuthenticated()) {
      await this.oauthClient.authorizeUsingClientCredentials();
    }

    if (this.oauthClient.isTokenExpired()) {
      await this.oauthClient.refreshAccessToken();
    }

    return {
      ...config,
      headers: { ...config.headers, Authorization: this.oauthClient.getTokenHeader() },
    };
  }
}
