import type { Task as StoreTask } from '@/api/models/Task';
import { Task } from '@/modules/phone-widget/connection/Task';

export class TaskFactory {
  tryMake(task: StoreTask | null): Task | null {
    return task ? this.make(task) : null;
  }

  make(task: StoreTask): Task {
    return new Task(task.id, task.caller);
  }
}
