import isEmpty from 'lodash-es/isEmpty';

type FieldsMapping = Record<string, string>;

type Errors = Record<string, string>;

interface ApiV0ErrorMessages {
  errors: string[];
}

interface ApiV0Error {
  children: ApiV0SingleError;
}

type ApiV0SingleError = Record<string, ApiV0Error | ApiV0ErrorMessages>;

interface ApiV0Errors {
  children: ApiV0Error[];
}

interface ApiV0ErrorResponse {
  code: number;
  message: string;
  errors: ApiV0Error | ApiV0Errors;
}

interface ApiV1Error {
  propertyPath: string;
  message: string;
}

interface ApiV1ErrorResponse {
  'hydra:title': string;
  'hydra:description': string;
  violations: ApiV1Error[];
}

function hasApiV1Errors(data): data is ApiV1ErrorResponse {
  return !!data?.violations;
}

function hasApiV0Errors(data): data is ApiV0ErrorResponse {
  return !!data?.errors?.children;
}

function hasErrors(data): data is ApiV0ErrorResponse | ApiV1ErrorResponse {
  return hasApiV1Errors(data) || hasApiV0Errors(data);
}

function getV1Errors(errors: ApiV1Error[], fieldsMapping: FieldsMapping = {}): Errors {
  return Object.fromEntries(
    errors.map(({ propertyPath: fieldName, message }) => {
      const key = fieldsMapping[fieldName] ? fieldsMapping[fieldName] : fieldName;
      return [key, message];
    }),
  );
}

function hasArrayOfErrors(errors: ApiV0SingleError | ApiV0Error[]): errors is ApiV0Error[] {
  return Array.isArray(errors);
}

function hasErrorMessages(error: ApiV0Error | ApiV0ErrorMessages): error is ApiV0ErrorMessages {
  return Array.isArray((error as ApiV0ErrorMessages).errors);
}

function getPrefixedFieldName(prefix: string, fieldName: string): string {
  if (prefix && fieldName) {
    return `${prefix}.${fieldName}`;
  }
  if (prefix) {
    return prefix;
  }
  return fieldName;
}

function getIndexedFieldName(index: number | null, prefixedFieldName: string): string {
  if (index === null && prefixedFieldName) {
    return prefixedFieldName;
  }
  if (index !== null && !prefixedFieldName) {
    return `${index}`;
  }

  return `${prefixedFieldName}.${index}`;
}

function getFieldName(fieldName = '', prefix = '', index: number | null = null): string {
  const prefixedFieldName = getPrefixedFieldName(prefix, fieldName);
  return getIndexedFieldName(index, prefixedFieldName);
}

function transformFromArray(
  errors: ApiV0SingleError | ApiV0Error[],
  fieldsMapping: FieldsMapping,
  prefix: string,
) {
  if (hasArrayOfErrors(errors)) {
    errors.reduce(
      (acc, error, index) => ({
        ...acc,

        ...transformErrors(error, fieldsMapping, getFieldName('', prefix, index)),
      }),
      {},
    );
  }
  return {};
}

function transformFromNested(
  errors: ApiV0SingleError | ApiV0Error[],
  fieldsMapping: FieldsMapping,
  prefix: string,
) {
  const withNestedErrors = Object.entries(errors).filter(
    ([, error]) => !isEmpty(error) && !hasErrorMessages(error),
  ) as [string, ApiV0Error][];

  if (withNestedErrors.length) {
    return withNestedErrors.reduce(
      (acc: Errors, [fieldName, error]) => ({
        ...acc,

        ...transformErrors(error, fieldsMapping, getFieldName(fieldName, prefix)),
      }),
      {},
    );
  }

  return null;
}

function getErrorMessages(
  errors: ApiV0SingleError | ApiV0Error[],
  prefix: string,
  fieldsMapping: FieldsMapping,
): Errors | null {
  const withErrorMessages = Object.entries(errors).filter(([, error]) =>
    hasErrorMessages(error),
  ) as [string, ApiV0ErrorMessages][];

  if (withErrorMessages.length) {
    return withErrorMessages.reduce((mappedErrors, [fieldName, data]) => {
      const keyName = getFieldName(fieldName, prefix);
      const key = fieldsMapping[keyName] ? fieldsMapping[keyName] : keyName;
      return { ...mappedErrors, [key]: data.errors };
    }, {});
  }
  return null;
}

function transformErrors(
  apiErrors: ApiV0Error | ApiV0Errors,
  fieldsMapping: FieldsMapping,
  prefix = '',
): Errors {
  const errors = apiErrors.children;

  return {
    ...transformFromArray(errors, fieldsMapping, prefix),
    ...transformFromNested(errors, fieldsMapping, prefix),
    ...getErrorMessages(errors, prefix, fieldsMapping),
  };
}

function getApiV0Errors(apiErrors: ApiV0Error | ApiV0Errors, fieldsMapping: FieldsMapping = {}) {
  return transformErrors(apiErrors, fieldsMapping);
}

/**
 * Get backend validation errors from API response
 *
 * Use `fieldsMapping` if your frontend form fields names are different than backend field names.
 * Example:
 * fieldsMapping = { backendFieldName: "frontendFieldName" }
 */
export function getErrors(data: unknown, fieldsMapping: FieldsMapping = {}): Errors | null {
  if (!hasErrors(data)) {
    return null;
  }

  if (hasApiV1Errors(data)) {
    return getV1Errors(data.violations, fieldsMapping);
  }

  return getApiV0Errors(data.errors, fieldsMapping);
}
