import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import type { FacilityNameRepository } from '../domain/FacilityNameRepository';

interface Facility {
  name: string;
}

interface Store {
  getters: {
    getUserFacility: Facility;
  };
}

@injectable()
export class StoreFacilityNameRepository implements FacilityNameRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
  ) {}

  get(): string {
    return this.store.getters.getUserFacility.name;
  }
}
