import { HttpClientApi } from '@/modules/http-client/public/api';
import { inject, injectable } from 'tsyringe';
import type { Task as RawTask } from '@/api/types/entities/task';
import type { TaskReadRepository } from '../../domain/real-time-updates/TaskReadRepository';
import type { TaskFactory } from '../../domain/real-time-updates/TaskFactory';
import { TaskFactoryToken } from '../../di/tokens';
import type { Task } from '../../domain/real-time-updates/Task';

@injectable()
export class HttpTaskReadRepository implements TaskReadRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    @inject(TaskFactoryToken)
    private readonly taskFactory: TaskFactory,
  ) {}

  async get(id: string): Promise<Task> {
    const task = await this.httpClient.get<RawTask>(`/api/v0/task/${id}`);
    return this.taskFactory.make(task);
  }
}
