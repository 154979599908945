import { injectable } from 'tsyringe';
import type { RefreshableCallingDevice } from '../device/RefreshableCallingDevice';
import type { DeviceError } from '../models/DeviceError';
import { ErrorProcessor } from './ErrorProcessor';

@injectable()
export class DeviceErrorTracker {
  constructor(private readonly errorProcessor: ErrorProcessor) {}

  attachListener(device: RefreshableCallingDevice): void {
    device.addListener('error', (error: DeviceError) => {
      this.errorProcessor.handleDeviceError(error);
    });
  }
}
