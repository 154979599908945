<template>
  <div :class="$b()">
    <slot />
    <dp-dropdown v-if="hasDropdown" :class="$b('dropdown')" menu-alignment="right" @click.stop>
      <template #toggle>
        <div :class="$b('dropdown-toggle')">
          <dp-icon-hellip :size="24" color="gray-600" />
        </div>
      </template>
      <div :class="$b('dropdown-body')">
        <slot name="dropdown-actions" />
      </div>
    </dp-dropdown>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { DpDropdown } from '@dp-ui-kit/vue';
import { DpIconHellip } from '@dp-ui-kit/icons';

@Component({
  name: 'TaskActions',
  components: { DpDropdown, DpIconHellip },
})
export default class TaskActions extends Vue {
  get hasDropdown(): boolean {
    return !!this.$slots['dropdown-actions'];
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-task-actions {
  display: flex;
  align-items: center;
  gap: v.$spacer-sm;

  &__dropdown-toggle {
    height: 100%;
    display: flex;
    align-items: center;
  }

  :deep(.dropdown-menu) {
    top: 150%;
  }

  &__dropdown-body :deep(:is(button, a)) {
    display: block;
    width: 100%;
    padding: v.$spacer-sm v.$spacer-md;
    text-align: left;
    text-decoration: none;

    &:hover {
      background: v.$gray-200;
    }
  }
}
</style>
