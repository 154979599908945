import { RootStoreToken } from '@/common/root';
import type { DpProvider } from '@/core/di/DpProvider';
import { DpEventBus } from '@/core/events/DpEventBus';
import type { DependencyContainer } from 'tsyringe';
import type { Store } from 'vuex';
import { CurrentUserWorkstationChangedEvent } from '../../public/events';

export class EventBusProvider implements DpProvider {
  boot(container: DependencyContainer): void {
    const store: Store<unknown> = container.resolve(RootStoreToken);
    const eventBus = container.resolve(DpEventBus);

    store.watch(
      () => store.getters['settings/reception/getCurrentUserPhone'],
      (newValue, oldValue) => {
        const newId = newValue?.id ?? null;
        const oldId = oldValue?.id ?? null;

        if (newId !== oldId) {
          eventBus.publish(new CurrentUserWorkstationChangedEvent(newValue?.id ?? null));
        }
      },
    );
  }
}
