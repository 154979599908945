import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { HttpClientModule } from '@/modules/http-client';
import { RepositoryProvider } from './RepositoryProvider';

export class SettingsDataExportsModule implements DpModule {
  static readonly namespace = Symbol('SettingsDataExportsModule');

  static readonly requires = [HttpClientModule];

  providers(): IDpProvider[] {
    return [RepositoryProvider];
  }
}
