import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import { $GET_ENTITIES_BY_ID } from '@dp-vue/entities';
import type { Message } from '@/modules/timeline/domain/message-composer/message/Message';
import type { StoreConversation } from './StoreConversation';
import type { Store } from '../Store';
import type { StoreTask } from '../../../StoreTask';
import { StoreMessageFactory } from './StoreMessageFactory';
import type { StoreMessage } from './StoreMessage';

@injectable()
export class StoreMessageRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
    private readonly storeMessageFactory: StoreMessageFactory,
  ) {}

  get(task: StoreTask, timezone: string): Message[] {
    if (!task.conversation) {
      return [];
    }

    const conversation = this.getConversation(task.conversation);

    if (!conversation || !conversation.messages) {
      return [];
    }

    const messages = this.getMessages(conversation.messages);

    return this.storeMessageFactory.makeMany(messages, conversation, timezone);
  }

  private getConversation(conversationId: string): StoreConversation | null {
    return this.store.getters[$GET_ENTITIES_BY_ID]('conversation', [conversationId])[0] ?? null;
  }

  private getMessages(messagesIds: string[]): StoreMessage[] {
    return this.store.getters[$GET_ENTITIES_BY_ID]('conversationMessage', messagesIds);
  }
}
