<template>
  <div :class="$b()">
    <slot />
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator';

@Component({
  name: 'TheStepper',
})
export default class TheStepper extends Vue {}
</script>

<style lang="scss">
@use '@/assets/scss/variables' as v;
@use '@/assets/scss/functions' as f;
@use 'sass:math';

$step-number-size: f.px-to-rem(32px);
$step-margin: v.$spacer-lg;
$line-distance: f.px-to-rem(4px);

.dp-the-stepper {
  & > *:not(:last-child) {
    margin-bottom: $step-margin;

    &::after {
      position: absolute;
      content: ' ';
      width: 2px;
      border-radius: v.$border-radius-lg;
      background-color: v.$gray-300;
      top: $step-number-size + $line-distance;
      bottom: $line-distance - $step-margin;
      left: math.div($step-number-size, 2);
    }
  }
}
</style>
