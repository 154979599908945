<template>
  <div :class="$b()">
    <div
      v-if="canDisplayFileName"
      :class="$b('file-item', { uploading: isUploading, ready: !isUploading })"
    >
      <div v-if="isUploading" :class="$b('progress')" :style="{ width: `${uploadProgress}%` }" />

      <span :class="$b('file-name')">{{ displayedFileName }}</span>

      <dp-spinner v-if="isUploading" size="sm" :class="$b('spinner')" />
      <dp-icon-close v-else :class="$b('file-remove')" @click="removeFile" />
    </div>

    <template v-else>
      <slot name="upload" :add-file="addFile">
        <dp-button
          link-type="muted"
          size="lg"
          :state="buttonState"
          type="button"
          full-width
          @click="addFile"
        >
          <slot>{{ $t('button.upload_media_file') }}</slot>
        </dp-button>
      </slot>
    </template>
  </div>
</template>
<script lang="ts">
import { DpButton, DpSpinner } from '@dp-ui-kit/vue';
import { DpIconClose } from '@dp-ui-kit/icons';
import { Vue, Component, Prop, Watch, Model } from 'vue-facing-decorator';

import type { UploadCare } from '@/ui/helpers/upload-care/UploadCare';
import { makeUploadCare } from '@/ui/helpers/upload-care';

type FileSizeLimit = Record<'default' | 'image', { maxSizeInBytes: number }>;

@Component({
  name: 'FileUploader',
  components: {
    DpButton,
    DpIconClose,
    DpSpinner,
  },
  emits: ['error', 'update'],
})
export default class FileUploader extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly acceptTypes: string[];

  @Prop({ type: Boolean, default: true })
  readonly hasVisibleFileName: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hasPreview: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled: boolean;

  @Prop({
    type: Object,
    default: null,
    validator: (filesSizeLimit: FileSizeLimit) =>
      Object.keys(filesSizeLimit).every(key => ['default', 'image'].includes(key)) &&
      Object.values(filesSizeLimit).every(value => typeof value.maxSizeInBytes === 'number'),
  })
  readonly fileSizeLimit: FileSizeLimit;

  @Model({ type: String, default: '' })
  url: string;

  uploadProgress = 0;

  fileHash = '';

  fileName: string | null = null;

  get displayedFileName(): string {
    return this.fileName ? decodeURIComponent(this.fileName) : '';
  }

  get buttonState(): 'disabled' | null {
    return this.disabled ? 'disabled' : null;
  }

  get canDisplayFileName(): boolean {
    return !!this.fileName && this.hasVisibleFileName;
  }

  get isUploading() {
    return !!this.uploadProgress;
  }

  get file(): unknown | null {
    if (!this.url) {
      return null;
    }

    if (this.fileHash) {
      return this.uploadCare.fileFromUpload(this.fileHash);
    }

    return this.uploadCare.fileFromUrl(this.url);
  }

  get uploadCare(): UploadCare {
    return makeUploadCare(this.acceptTypes, this.fileValidation, this.hasPreview);
  }

  get fileListener() {
    return file => {
      file.progress(this.trackProgress);
      file.done(this.updateFile);
      file.fail(this.handleError);
    };
  }

  @Watch('url', { immediate: true })
  updateFileName(): void {
    this.fileName = this.url.split('/')?.pop() ?? null;
  }

  addFile() {
    if (this.disabled) {
      return;
    }

    const dialog = this.uploadCare.openDialog(this.file);

    dialog.done(this.fileListener);
  }

  uploadFile(file: File) {
    const processedFile = this.uploadCare.fileFromObject(file);

    const dialog = this.uploadCare.openDialog(processedFile);

    dialog.done(this.fileListener);
  }

  trackProgress({ state, uploadProgress, incompleteFileInfo }) {
    if (state !== 'uploading') {
      return;
    }

    this.fileName = incompleteFileInfo.name;

    const progressPercent = Math.round(uploadProgress * 100);
    this.uploadProgress = Math.max(progressPercent, 1);
  }

  updateFile({ cdnUrl, name, uuid, mimeType }) {
    this.fileHash = uuid;
    this.uploadProgress = 0;

    this.emitEvents(`${cdnUrl}${name}`, mimeType);
  }

  handleError(errorMessage) {
    this.uploadProgress = 0;

    this.removeFile();

    this.$emit('error', errorMessage);
  }

  removeFile() {
    this.fileName = null;
    this.url = '';
    this.fileHash = '';

    this.emitEvents('', null);
  }

  private emitEvents(url, mimeType) {
    this.url = url;
    this.$emit('update', { url, mimeType });
  }

  private fileValidation({ sourceInfo }) {
    if (!sourceInfo.file) {
      return;
    }

    if (this.acceptTypes.length && !this.acceptTypes.includes(sourceInfo.file.type)) {
      throw new Error(this.$ts('error.default'));
    }

    if (sourceInfo.file.size === 0) {
      throw new Error(this.$ts('error.file_empty'));
    }

    if (this.fileSizeLimit && sourceInfo.file.size) {
      this.validateFileSize(sourceInfo.file);
    }
  }

  private validateFileSize(file: File): void {
    if (!this.fileSizeLimit) {
      return;
    }

    if (file.type.startsWith('image/')) {
      const maxFileSize = this.fileSizeLimit.image.maxSizeInBytes;

      if (file.size > maxFileSize) {
        // TODO: Find way to inject translations
        throw new Error('Image is too big');
      }
      return;
    }

    const maxFileSize = this.fileSizeLimit.default.maxSizeInBytes;

    if (file.size > maxFileSize) {
      // TODO: Find way to inject translations
      throw new Error('File is too big');
    }
  }
}
</script>
<style lang="scss" scoped>
@use '@/assets/scss/variables' as v;

.dp-file-uploader {
  &__file-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: v.$spacer-sm v.$spacer-md;
    gap: v.$spacer-md;

    &--uploading {
      background-color: v.$gray-200;
      color: v.$gray-600;
    }

    &--ready {
      background-color: v.$primary-light;
      color: v.$primary-dark;
    }
  }

  &__progress {
    background-color: v.$primary-light;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__file-remove {
    cursor: pointer;
  }

  &__spinner {
    :deep(.spinner-stop-color) {
      stop-color: v.$primary;
    }
  }

  &__file-name {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 28rem;
  }

  &__error {
    color: v.$danger;
    margin-top: v.$spacer-sm;
  }
}
</style>
