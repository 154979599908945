import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';

interface Store {
  dispatch: (actionName: 'GET_FACILITY_ACTION') => Promise<void>;
}

@injectable()
export class StoreFacilityFetcher {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
  ) {}

  async fetch(): Promise<void> {
    await this.store.dispatch('GET_FACILITY_ACTION');
  }
}
