import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { VoiceMenuConfigurationOptionsList } from '@/modules/settings/domain/traffic-control/aggregates/VoiceMenuConfigurationOptionsList';
import type { ConfigurationOptionsListRepository } from '@/modules/settings/domain/traffic-control/voice-menu-configuration-options/ConfigurationOptionsListRepository';
import { ConfigurationOptionsFactory } from './ConfigurationOptionsFactory';
import { OutputConfigurationOptionsFactory } from './OutputConfigurationOptionsFactory';
import type { InputVoiceMenu } from '../types/InputVoiceMenu';

@injectable()
export class HttpConfigurationOptionsListRepository implements ConfigurationOptionsListRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly configurationOptionsFactory: ConfigurationOptionsFactory,
    private readonly outputConfigurationOptionsFactory: OutputConfigurationOptionsFactory,
  ) {}

  async get(): Promise<VoiceMenuConfigurationOptionsList | null> {
    const inputVoiceMenu: InputVoiceMenu = await this.httpClient.get(
      '/api/v0/facility/{facilityId}/voice-menu',
    );

    return this.configurationOptionsFactory.make(inputVoiceMenu.options);
  }

  async save(
    data: VoiceMenuConfigurationOptionsList | null,
  ): Promise<VoiceMenuConfigurationOptionsList> {
    const inputVoiceMenu: InputVoiceMenu = await this.httpClient.post(
      '/api/v0/facility/{facilityId}/voice-menu-options',
      this.outputConfigurationOptionsFactory.make(data),
    );

    return this.configurationOptionsFactory.make(inputVoiceMenu.options);
  }
}
