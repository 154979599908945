import metadata from '@/config/libphonenumber-metadata.json';

const formats = metadata.map(({ countryCode, leadingDigits, pattern, format }) => ({
  leadingDigits:
    leadingDigits.map(item => new RegExp(`^\\+${countryCode}(${item})`)).pop() ||
    new RegExp(`^\\+${countryCode}`),
  pattern: new RegExp(`^\\+${countryCode}${pattern}$`),
  format: `+${countryCode} ${format}`,
}));

export function phoneNumberFormatter(phoneNumber: string | null): string {
  if (!phoneNumber) {
    return '';
  }
  const format = formats.find(
    ({ pattern, leadingDigits }) => leadingDigits.test(phoneNumber) && pattern.test(phoneNumber),
  );

  if (!format) {
    return phoneNumber;
  }

  return phoneNumber.replace(format.pattern, format.format);
}
