export class BookingError extends Error {
  translatedMessage: string | null = null;

  translatedDescription: string | null = null;

  constructor(readonly message = '') {
    super(message);

    this.name = 'BookingError';
  }

  get isEmpty(): boolean {
    return this.message === '';
  }

  addCustomMessage(message: string): BookingError {
    this.translatedMessage = message;
    return this;
  }

  addExtendedDescription(description: string): BookingError {
    this.translatedDescription = description;
    return this;
  }

  static makeEmpty(): BookingError {
    return new BookingError();
  }

  static withNoPreloadedOptions(): BookingError {
    return new BookingError('Could not get preloaded booking options');
  }
}
