import { inject, singleton } from 'tsyringe';
import { EventPublisherToken } from '@/common/tracking-event/di/tokens';
import type { SingularEventPublisher } from './SingularEventPublisher';
import type { EventPublisher } from '../EventPublisher';
import { SingularEvent } from '../../models/SingularEvent';

/**
 * This publisher limit amount of data sent to GTM to save user traffic for stuff that need to be
 * registered once.
 */
@singleton()
export class DpBusSingularEventPublisher implements SingularEventPublisher {
  private sentEvents: string[] = [];

  constructor(@inject(EventPublisherToken) private readonly eventPublisher: EventPublisher) {}

  tryPublishEvent(externalName: string): void {
    if (this.wasSend(externalName)) {
      return;
    }

    this.sentEvents.push(externalName);
    this.eventPublisher.send(new SingularEvent(externalName));
  }

  private wasSend(externalName: string): boolean {
    return this.sentEvents.includes(externalName);
  }
}
