import { injectable } from 'tsyringe';
import { Call } from '@/modules/timeline/domain/call/Call';
import { Summary } from '@/modules/timeline/domain/call/summary/Summary';
import { UserOwnerRepository } from '@/modules/timeline/adapters/timeline/store/owner/UserOwnerRepository';
import type { Owner } from '@/modules/timeline/domain/owner/Owner';
import { IsoDate } from '@/modules/timeline/domain/IsoDate';
import { StoreRecordingUrlRepository } from '../recording-url/StoreRecordingUrlRepository';
import type { StoreCall } from './StoreCall';

@injectable()
export class StoreCallFactory {
  constructor(
    private readonly userOwnerRepository: UserOwnerRepository,
    private readonly storeRecordingUrlFactory: StoreRecordingUrlRepository,
  ) {}

  makeMany(calls: StoreCall[], timezone: string): Call[] {
    return calls.map(call => this.make(call, timezone));
  }

  make(call: StoreCall, timezone: string): Call {
    return new Call(
      call.id,
      call.sid,
      IsoDate.make(call.created_at, timezone),
      call.direction,
      call.status,
      this.getOwner(call.personnel),
      call.updated_at,
      call.waiting_at,
      call.answered_at,
      call.duration,
      call.has_quality_reports,
      call.phone.id,
      call.phone.name,
      this.mapUrl(call.recording),
      this.mapUrl(call.voicemail),
      call.workstation_id,
      this.mapSummary(call.id, call.summary),
    );
  }

  private getOwner(ownerId: string | null): Owner | null {
    if (!ownerId) {
      return null;
    }

    return this.userOwnerRepository.find(ownerId);
  }

  private mapSummary(callId: string, summary: StoreCall['summary']): Summary | null {
    if (!summary) {
      return null;
    }

    return new Summary(callId, summary.content ?? '', summary.finished_at ?? '', summary.status);
  }

  private mapUrl(urlId: string | null): string | null {
    if (!urlId) {
      return null;
    }

    return this.storeRecordingUrlFactory.get(urlId);
  }
}
