import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { SubscriptionRepositoryToken, FacilityIdRepositoryToken } from '../token';
import type { SubscriptionRepository } from '../../domain/subscription/SubscriptionRepository';
import { HttpSubscriptionRepository } from '../../adapters/subscription/HttpSubscriptionRepository';
import type { FacilityIdRepository } from '../../domain/customer-success-mode/FacilityIdRepository';
import { StoreFacilityIdRepository } from '../../adapters/customer-success-mode/StoreFacilityIdRepository';

export class SubscriptionProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<SubscriptionRepository>(
      SubscriptionRepositoryToken,
      HttpSubscriptionRepository,
    );

    container.register<FacilityIdRepository>(FacilityIdRepositoryToken, StoreFacilityIdRepository);
  }
}
