import type { MutationTree } from 'vuex';

type FocusTarget = 'comment' | 'reminder' | null;

export interface OpeningMutationPayload {
  taskId: string;
  focusTarget: FocusTarget;
}

interface State {
  openedTaskId: string | null;
  focusTarget: FocusTarget;
}

const state: State = {
  openedTaskId: null,
  focusTarget: null,
};

const mutations: MutationTree<State> = {
  OPEN_MODAL(state: State, { taskId, focusTarget = null }: OpeningMutationPayload): void {
    state.focusTarget = focusTarget;
    state.openedTaskId = taskId;
  },
  CLOSE_MODAL(state: State): void {
    state.focusTarget = null;
    state.openedTaskId = null;
  },
};

const getters = {
  isModalOpen:
    (state: State) =>
    (taskId: string): boolean =>
      state.openedTaskId === taskId,
  focusTarget: (state: State): FocusTarget => state.focusTarget,
};

export const module = { state, mutations, getters, namespaced: true };

export const moduleName = 'task:modal-controller';
