import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { StoreAnonymizationDataRepository, HttpAnonymizationRepository } from '../../adapters';
import { AnonymizationData } from '../../domain/facades/AnonymizationData';
import {
  AnonymizationDataToken,
  AnonymizationDataRepositoryToken,
  AnonymizationRepositoryToken,
} from '../token';

export class AdapterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register(AnonymizationRepositoryToken, HttpAnonymizationRepository);
    container.register(AnonymizationDataRepositoryToken, StoreAnonymizationDataRepository);
    container.register(AnonymizationDataToken, AnonymizationData);
  }
}
