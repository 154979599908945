import type { PresenceSummaryResponse } from '@/api/types/response/presence-summary';
import type { PresenceSummaryRepository } from '../types/PresenceSummaryRepository';
import { BaseAxiosRepository } from './BaseAxiosRepository';

export class AxiosPresenceSummaryRepository
  extends BaseAxiosRepository
  implements PresenceSummaryRepository
{
  async getPresenceStats(facilityId: string, startDate: string): Promise<PresenceSummaryResponse> {
    return (await this.axiosClient.get(
      `${this.v0ApiPrefix}/facility/${facilityId}/stats/presence-summary`,
      {
        date: startDate,
      },
    )) as PresenceSummaryResponse;
  }
}
