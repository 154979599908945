import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import { HttpInterceptionError } from '@/modules/http-client/public/types';
import { NotReadyToPingError } from '@/modules/user/domain/ping/NotReadyToPingError';
import type { PingRepository } from '../../domain/ping/PingRepository';
import { PingFactory } from './PingFactory';
import type { Ping } from '../../domain/ping/Ping';
import type { InputGetPing } from './InputGetPing';
import type { InputPing } from './InputPing';

@injectable()
export class HttpPingRepository implements PingRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly pingFactory: PingFactory,
  ) {}

  async noWorkstationPing(): Promise<Ping> {
    try {
      const inputPing = await this.httpClient.get<InputGetPing>(
        '/api/v0/facility/{facilityId}/ping',
      );

      return this.pingFactory.make(inputPing);
    } catch (e) {
      if (e instanceof HttpInterceptionError) {
        throw new NotReadyToPingError();
      }

      throw e;
    }
  }

  async ping(sessionStartTime: string): Promise<Ping> {
    try {
      const inputPing = await this.httpClient.post<InputPing>(
        `/api/v0/facility/{facilityId}/ping`,
        {
          since: sessionStartTime,
        },
      );

      return this.pingFactory.make(inputPing);
    } catch (e) {
      if (e instanceof HttpInterceptionError) {
        throw new NotReadyToPingError();
      }

      throw e;
    }
  }
}
