import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { PatientRepositoryToken } from '../token';
import { PatientApiPatientRepository } from '../../adapters/patient/PatientApiPatientRepository';
import type { PatientRepository } from '../../domain/patient/PatientRepository';

export class PatientProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<PatientRepository>(PatientRepositoryToken, PatientApiPatientRepository);
  }
}
