import type { DependencyContainer } from 'tsyringe';
import { injectable } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { StoreWorkstationRepository } from '@/modules/workstation/adapters/StoreWorkstationRepository';
import { authenticationApi } from '@/modules/authentication/public/api';
import type { Authentication } from '@/modules/workstation/domain/Authentication';
import type { WorkstationRepository } from '@/modules/workstation/domain/WorkstationRepository';
import { AuthenticationToken, WorkstationRepositoryToken } from '@/modules/workstation/di/tokens';

@injectable()
export class AdapterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<WorkstationRepository>(
      WorkstationRepositoryToken,
      StoreWorkstationRepository,
    );

    container.register<Authentication>(AuthenticationToken, {
      useFactory: () => authenticationApi(),
    });
  }
}
