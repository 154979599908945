import type { BookVisitModuleUpcomingVisit } from './BookVisitModuleUpcomingVisit';
import { UpcomingVisit } from '../../../domain/visit/UpcomingVisit';

export class BookVisitModuleUpcomingVisitFactory {
  static tryMake(
    bookVisitModuleUpcomingVisit: BookVisitModuleUpcomingVisit | null,
  ): UpcomingVisit | null {
    if (!bookVisitModuleUpcomingVisit) {
      return null;
    }

    if (
      bookVisitModuleUpcomingVisit.status !== 'upcoming' &&
      bookVisitModuleUpcomingVisit.status !== 'in-progress'
    ) {
      return null;
    }

    return new UpcomingVisit(
      bookVisitModuleUpcomingVisit.status,
      bookVisitModuleUpcomingVisit.doctorName,
      bookVisitModuleUpcomingVisit.serviceName,
      bookVisitModuleUpcomingVisit.startAt,
    );
  }
}
