export enum SearchCriteriaName {
  DateRange = 'dateRange',
  Personnel = 'personnel',
  Numbers = 'numbers',
  Results = 'results',
  Phones = 'phones',
  Forms = 'forms',
  Tasks = 'tasks',
  Filter = 'filter',
  TextQueries = 'textQueries',
  PatientRequests = 'patientRequestTypes',
}
