import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { Authorization } from '@/common/authorization/public/api';
import { AuthorizationApiAuthorizer } from '@/modules/timeline/adapters/authorization/AuthorizationApiAuthorizer';
import type { Authorizer } from '@/modules/timeline/domain/authorization/Authorizer';
import {
  CopyCallSid,
  DownloadRecording,
  ReportCallQuality,
  SeeAutomatedSummaries,
} from '../policies';
import { AuthorizerToken } from '../tokens';

export class AuthorizationProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<Authorizer>(AuthorizerToken, AuthorizationApiAuthorizer);
  }

  boot(container: DependencyContainer): void {
    const authorization = container.resolve(Authorization);

    authorization.addPolicies([
      {
        name: DownloadRecording,
        handler: user => user.isManager(),
      },
      {
        name: CopyCallSid,
        handler: user => user.hasRole('CALL_QUALITY'),
      },
      {
        name: ReportCallQuality,
        handler: user => user.isFacilityUser(),
      },
      {
        name: SeeAutomatedSummaries,
        handler: user => user.hasFeature('AI_SUMMARIES'),
      },
    ]);
  }
}
