<template>
  <div class="tw-overflow-hidden" :class="$attrs.class">
    <p class="tw-break-words">
      {{ content }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormShowText',

  inheritAttrs: false,

  props: {
    content: {
      type: String,
      default: '',
    },
  },
});
</script>
