import type {
  OutputDistanceNormalizerFactory,
  OutputDistanceNormalizer,
} from './OutputDistanceNormalizer';
import type { OutputFormatter } from './types';
import { TranslatedOutputDistanceNormalizer as TranslateOutputDistanceNormalizer } from './TranslatedOutputDistanceNormalizer';

export class TranslateOutputDistanceNormalizerFactory implements OutputDistanceNormalizerFactory {
  constructor(private readonly outputFormatter: OutputFormatter) {}

  make(startDate: Date, endDate: Date): OutputDistanceNormalizer {
    return new TranslateOutputDistanceNormalizer(this.outputFormatter, startDate, endDate);
  }

  makeToNow(date: Date): OutputDistanceNormalizer {
    return new TranslateOutputDistanceNormalizer(this.outputFormatter, date);
  }
}
