import { format, isDate } from 'date-fns';
import {
  FORM_FIELDS,
  FORM_STEPS,
  FORM_TYPES,
} from '@/store/modules/settings/utils/compliance-form';
import { mapObject } from '@/utils/functions';

export function uploadFile(dispatch) {
  return async ({ file, type }) => {
    const { id } = await dispatch('UPLOAD_SENSITIVE_FILE_ACTION', file, { root: true });
    return {
      type,
      file: `/api/v1/media/${id}`,
    };
  };
}

export function transformDates(value) {
  if (!isDate(value)) {
    return value;
  }

  return format(value, 'yyyy-MM-dd');
}

export function toApi(getters, uploadedDocuments) {
  const bundle = {
    [FORM_TYPES.END_USER_TYPE]: getters.getFormData(FORM_TYPES.END_USER_TYPE)[
      FORM_TYPES.END_USER_TYPE
    ],
    [FORM_TYPES.BUSINESS_INFO]: mapObject(transformDates)(
      getters.getFormData(FORM_TYPES.BUSINESS_INFO),
    ),
    [FORM_TYPES.PERSONAL_INFO]: mapObject(transformDates)(
      getters.getFormData(FORM_TYPES.PERSONAL_INFO),
    ),
    [FORM_TYPES.DOCUMENTS]: uploadedDocuments,
  };

  const customerName = bundle[FORM_TYPES.BUSINESS_INFO][FORM_FIELDS.BUSINESS_INFO.CUSTOMER_NAME];
  if (customerName) {
    delete bundle[FORM_TYPES.BUSINESS_INFO][FORM_FIELDS.BUSINESS_INFO.CUSTOMER_NAME];
    bundle[FORM_TYPES.BUSINESS_INFO][FORM_FIELDS.BUSINESS_INFO.BUSINESS_NAME] = customerName;
  }
  return bundle;
}

export function getWizardSecondStep(endUserType) {
  return endUserType === FORM_FIELDS.END_USER_TYPE.BUSINESS
    ? FORM_STEPS.BUSINESS_PAGE
    : FORM_STEPS.ADDRESS_PAGE;
}

export function getWizardSteps(endUserType, fieldsRequirements) {
  const secondStep = getWizardSecondStep(endUserType);

  if (fieldsRequirements[FORM_TYPES.PERSONAL_INFO]?.length > 0) {
    return [
      FORM_STEPS.END_USER_PAGE,
      secondStep,
      FORM_STEPS.PERSONAL_PAGE,
      FORM_STEPS.DOCUMENTS_PAGE,
    ];
  }
  return [FORM_STEPS.END_USER_PAGE, secondStep, FORM_STEPS.DOCUMENTS_PAGE];
}
