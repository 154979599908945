import { PatientValidator } from '@/modules/patient/public/api/PatientValidator';
import { PublicPatientApi } from '@/modules/patient/public/api/PublicPatientApi';
import { delay, inject, injectable } from 'tsyringe';
import type { Patient } from '../../domain/models/Patient';
import { PatientFactory } from '../../domain/factories/PatientFactory';
import type { PatientRepository } from '../../domain/repositories/PatientRepository';

@injectable()
export class PatientApiPatientRepository implements PatientRepository {
  constructor(
    @inject(delay(() => PublicPatientApi))
    private readonly patientApi: PublicPatientApi,
    @inject(delay(() => PatientValidator))
    private readonly patientValidator: PatientValidator,
    private readonly patientFactory: PatientFactory,
  ) {}

  get(id: string): Patient | null {
    try {
      const patient = this.patientApi.get(id);

      return this.patientFactory.make(patient);
    } catch (e) {
      if (this.patientValidator.isPatientError(e)) {
        return null;
      }

      throw e;
    }
  }

  findByNumber(number: string): Patient | null {
    try {
      const patient = this.patientApi.getByPhoneNumber(number);

      return this.patientFactory.make(patient);
    } catch (e) {
      if (this.patientValidator.isPatientError(e)) {
        return null;
      }

      throw e;
    }
  }

  async findByPhrase(phrase: string): Promise<Patient[]> {
    const { patients } = await this.patientApi.getList(phrase);

    return patients.map(this.patientFactory.make);
  }
}
