import { injectable } from 'tsyringe';
import type { SsoIntegrationRepository } from '@/modules/authentication/domain/sso/integration/SsoIntegrationRepository';
import { HttpClientApi } from '@/modules/http-client/public/api';
import { SsoIntegrationProfile } from '@/modules/authentication/domain/sso/integration/SsoIntegrationProfile';

interface HttpProfile {
  name: string;
  image: string;
  marketplace_id: string;
  type: 'facility' | 'default';
}

@injectable()
export class HttpSsoIntegrationRepository implements SsoIntegrationRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async getSetupId(code: string, redirectUri: string, facilityId: string): Promise<string> {
    const { id } = await this.httpClient.post<{ id: string }>('/api/v0/docplanner-profile/setup', {
      code,
      redirectUri,
      facilityId,
    });

    return id;
  }

  async getProfiles(setupId: string): Promise<SsoIntegrationProfile[]> {
    const data = await this.httpClient.get<HttpProfile[]>(
      `/api/v0/docplanner-profile/setup/${setupId}/options`,
    );

    return data.map(
      (profile: any) =>
        new SsoIntegrationProfile(
          profile.name,
          profile.image,
          profile.marketplace_id,
          profile.type === 'facility' ? 'facility' : 'default',
        ),
    );
  }

  commit(setupId: string, chosenProfileId: string): Promise<void> {
    return this.httpClient.post(`/api/v0/docplanner-profile/setup/${setupId}/commit`, {
      chosenProfileId,
    });
  }
}
