import { injectable } from 'tsyringe';
import type { DesktopNotificationPermission } from '../../domain/permissions/DesktopNotificationPermission';

@injectable()
export class BrowserDesktopNotificationPermission implements DesktopNotificationPermission {
  get permissionStatus(): 'granted' | 'denied' | 'default' {
    return Notification.permission;
  }

  async requestPermission(): Promise<'granted' | 'denied' | 'default'> {
    if (Notification.permission === 'default') {
      return Notification.requestPermission();
    }

    return Notification.permission;
  }

  isSupportingNotifications(): boolean {
    return typeof window.Notification === 'function';
  }
}
