import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import type { Translator } from '@/common/root/domain/Translator';
import { VueI18nTranslator } from '@/common/root/adapters/VueI18nTranslator';
import type { DpProvider } from '@/core/di/DpProvider';
import { i18n } from '@/ui/plugins/i18n';

export const RootTranslatorToken = Symbol('rootTranslator');

@injectable()
export class RootTranslatorProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<Translator>(RootTranslatorToken, {
      useValue: new VueI18nTranslator(i18n.global),
    });
  }
}
