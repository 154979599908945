import { Authorization } from '@/common/authorization/public/api';
import { injectable } from 'tsyringe';
import type { AuthorizationChecker } from '../../domain/authorization/AuthorizationChecker';
import type { Policies } from '../../domain/authorization/policies';

@injectable()
export class AuthorizationCheckerAdapter implements AuthorizationChecker {
  constructor(private readonly authorization: Authorization) {}

  can(featureId: Policies): boolean {
    return this.authorization.can(featureId);
  }
}
