import { injectable } from 'tsyringe';
import type { PresenceRepository } from '../domain/PresenceRepository';
import { UserApi } from '@/modules/user/public/api/UserApi';
import { UserPresence } from '../domain/UserPresence';

@injectable()
export class UserPresenceRepository implements PresenceRepository {
  constructor(private readonly userApi: UserApi) {}

  all(): UserPresence[] {
    return this.userApi.getAll().map(user => new UserPresence(user.id, user.status));
  }
}
