import { injectable } from 'tsyringe';
import type { Policy } from './policy/Policy';
import { PolicyRepository } from './policy/PolicyRepository';
import { UserFactory } from './UserFactory';

@injectable()
export class Authorization {
  constructor(
    private readonly policyRepository: PolicyRepository,
    private readonly userFactory: UserFactory,
  ) {}

  addPolicies(policies: Policy[]): void {
    this.policyRepository.add(policies);
  }

  canSome(featureNames: string[]): boolean {
    if (featureNames.length === 0) {
      return true;
    }

    return featureNames.some(this.can, this);
  }

  can(policyName: string): boolean {
    const policy = this.policyRepository.find(policyName);
    if (!policy) {
      return true;
    }

    const user = this.userFactory.make();

    return policy.handler(user);
  }
}
