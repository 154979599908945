import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import type { TextChannelEvaluator } from '../domain/TextChannelEvaluator';

interface Store {
  getters: {
    getUserFacility: {
      channels: {
        has_text_channel: boolean;
        type?: 'whatsapp' | 'sms';
      };
    };
  };
}

@injectable()
export class StoreTextChannelEvaluator implements TextChannelEvaluator {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
  ) {}

  hasWhatsAppChannel(): boolean {
    const { channels } = this.store.getters.getUserFacility;

    return channels?.type === 'whatsapp';
  }
}
