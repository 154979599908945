import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { HttpPasswordResetRequester } from '@/modules/authentication/adapters/password-reset/HttpPasswordResetRequester';
import {
  RequestDetailsRepositoryToken,
  PasswordResetRequesterToken,
  PasswordResetDispatcherToken,
} from '@/modules/authentication/di/token';
import { HttpRequestDetailsRepository } from '@/modules/authentication/adapters/password-reset/HttpRequestDetailsRepository';
import type { RequestDetailsRepository } from '@/modules/authentication/domain/password-reset/details/RequestDetailsRepository';
import type { PasswordResetRequester } from '@/modules/authentication/domain/password-reset/request/PasswordResetRequester';
import { HttpPasswordResetDispatcher } from '@/modules/authentication/adapters/password-reset/HttpPasswordResetDispatcher';
import type { PasswordResetDispatcher } from '@/modules/authentication/domain/password-reset/reset/PasswordResetDispatcher';

export class PasswordResetProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<PasswordResetRequester>(
      PasswordResetRequesterToken,
      HttpPasswordResetRequester,
    );

    container.register<RequestDetailsRepository>(
      RequestDetailsRepositoryToken,
      HttpRequestDetailsRepository,
    );

    container.register<PasswordResetDispatcher>(
      PasswordResetDispatcherToken,
      HttpPasswordResetDispatcher,
    );
  }
}
