import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import type { Policy } from '@/common/authorization/public/types';
import { Authorization } from '@/common/authorization/public/api';
import {
  AccessOmniStatistics,
  StatisticsFilters,
  StatisticsMenu,
  StatisticsOverview,
  StatisticsTraffic,
} from '../../domain/policies';
import { IntegrationGate } from '../../domain/integration/IntegrationGate';
import type { PolicyValidator } from '../../domain/PolicyValidator';
import { PolicyValidatorToken, TextChannelEvaluatorToken } from '../tokens';
import { AuthorizationPolicyValidator } from '../../adapters/AuthorizationPolicyValidator';
import type { TextChannelEvaluator } from '../../domain/TextChannelEvaluator';

@injectable()
export class AuthorizationProvider implements DpProvider {
  constructor(
    private readonly authorization: Authorization,
    private readonly integrationGate: IntegrationGate,
  ) {}

  register(container: DependencyContainer): void {
    container.register<PolicyValidator>(PolicyValidatorToken, AuthorizationPolicyValidator);
  }

  boot(container: DependencyContainer): void {
    this.authorization.addPolicies(this.getPolicies(container));
  }

  private getPolicies(container: DependencyContainer): Policy[] {
    const generalAccess: Policy = {
      name: StatisticsMenu.AccessStatisticsPage,
      handler: user => user.isManager() || user.hasOpenGate('RECEPTION_STATS_ACCESS'),
    };

    const channelsFilterAccess: Policy = {
      name: StatisticsFilters.AccessStatisticsChannelsFilter,
      handler: user => user.hasFeature('CHANNEL_FILTER'),
    };

    const sideMenu: Policy[] = [
      {
        name: StatisticsMenu.AccessStatisticsOverviewPage,
        handler: () => true,
      },
      {
        name: StatisticsMenu.AccessStatisticsOverviewOutboundPage,
        handler: user => user.hasFeature('STATISTICS'),
      },
      {
        name: StatisticsMenu.AccessStatisticsTrafficPage,
        handler: () => true,
      },
      {
        name: StatisticsMenu.AccessStatisticsCallbackPage,
        handler: () => true,
      },
      {
        name: StatisticsMenu.AccessStatisticsChannelsPage,
        handler: user => user.hasFeature('STATISTICS'),
      },
      {
        name: StatisticsMenu.AccessStatisticsTeamPage,
        handler: user => user.hasFeature('STATISTICS'),
      },
      {
        name: StatisticsMenu.AccessStatisticsSubjectsPage,
        handler: () => true,
      },
      {
        name: StatisticsMenu.AccessStatisticsQualityPage,
        handler: () => true,
      },
      {
        name: StatisticsMenu.AccessStatisticVisitConfirmationPage,
        // TODO check what to do with it
        handler: this.integrationGate.hasVisitConfirmations.bind(this.integrationGate),
      },
    ];

    const outboundCallsOverview: Policy[] = [
      {
        name: StatisticsOverview.AccessStatisticsOverviewOutboundPage,
        handler: user => user.hasFeature('STATISTICS'),
      },
    ];

    const traffic: Policy[] = [
      {
        name: StatisticsTraffic.ShowPresenceOnHeatmap,
        handler: user => user.hasOpenGate('TRAFFIC_HEATMAP_PRESENCE'),
      },
    ];

    const textChannelEvaluator = container.resolve<TextChannelEvaluator>(TextChannelEvaluatorToken);

    const omniStatistics: Policy[] = [
      {
        name: AccessOmniStatistics,
        handler: user =>
          textChannelEvaluator.hasWhatsAppChannel() && user.hasOpenGate('WHATSAPP_STATISTICS'),
      },
    ];

    return [
      generalAccess,
      channelsFilterAccess,
      ...sideMenu,
      ...outboundCallsOverview,
      ...traffic,
      ...omniStatistics,
    ];
  }
}
