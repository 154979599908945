import { injectable } from 'tsyringe';
import type { TagFactory } from '@/modules/task/domain/tag/factories/TagFactory';
import type { Task } from '@/modules/task/domain/task/Task';
import type { Tag } from '@/modules/task/domain/tag/Tag';

@injectable()
export class VoiceMenuTagFactory implements TagFactory {
  make({ voiceMenu }: Task): Tag[] {
    if (voiceMenu.isEmpty) {
      return [];
    }

    return [voiceMenu.getTag()];
  }
}
