import type { DpProvider } from '@/core/di/DpProvider';
import { RootCacheToken } from '@/common/root/providers/RootCacheProvider';
import type { DependencyContainer } from 'tsyringe';
import type { BookingRepository } from '@/modules/book-visit/domain/booking/BookingRepository';
import type { PreloadedBookingOptionsRepository } from '@/modules/book-visit/domain/booking/PreloadedBookingOptionsRepository';
import type { FacilityIntegrationRepository } from '@/modules/book-visit/domain/facility/FacilityIntegrationRepository';
import type { BookingSlotRepository } from '@/modules/book-visit/domain/booking-slot/BookingSlotRepository';
import type { ServiceRepository } from '@/modules/book-visit/domain/service/ServiceRepository';
import type { DoctorWithAddressRepository } from '@/modules/book-visit/domain/doctor/DoctorWithAddressRepository';
import { HttpBookingRepository } from '@/modules/book-visit/adapters/booking/HttpBookingRepository';
import { StorePreloadedBookingOptionsRepository } from '@/modules/book-visit/adapters/booking/StorePreloadedBookingOptionsRepository';
import { StoreFacilityIntegrationRepository } from '@/modules/book-visit/adapters/facility/StoreFacilityIntegrationRepository';
import { HttpBookingSlotRepository } from '@/modules/book-visit/adapters/booking-slot/HttpBookingSlotRepository';
import { CachedServiceRepository } from '@/modules/book-visit/adapters/service/CachedServiceRepository';
import { CachedDoctorWithAddressRepository } from '@/modules/book-visit/adapters/doctor/CachedDoctorWithAddressRepository';
import { HttpServiceRepository } from '@/modules/book-visit/adapters/service/HttpServiceRepository';
import { HttpDoctorWithAddressRepository } from '@/modules/book-visit/adapters/doctor/HttpDoctorWithAddressRepository';
import { StoreVisitConfirmationReadRepository } from '@/modules/book-visit/adapters/visit/StoreVisitConfirmationReadRepository';
import type { VisitConfirmationReadRepository } from '@/modules/book-visit/domain/visit/VisitConfirmationReadRepository';
import { HttpVisitConfirmationWriteRepository } from '@/modules/book-visit/adapters/visit/HttpVisitConfirmationWriteRepository';
import type { VisitConfirmationWriteRepository } from '@/modules/book-visit/domain/visit/VisitConfirmationWriteRepository';
import type { UpcomingVisitRepository } from '@/modules/book-visit/domain/visit/UpcomingVisitRepository';
import { StoreUpcomingVisitRepository } from '@/modules/book-visit/adapters/visit/StoreUpcomingVisitRepository';
import {
  BookingRepositoryToken,
  BookingSlotRepositoryToken,
  DoctorWithAddressToken,
  FacilityIntegrationRepositoryToken,
  PreloadedBookingOptionsRepositoryToken,
  ServiceRepositoryToken,
  UpcomingVisitRepositoryToken,
  VisitConfirmationReadRepositoryToken,
  VisitConfirmationWriteRepositoryToken,
} from '../token';

export class RepositoryProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<BookingRepository>(BookingRepositoryToken, HttpBookingRepository);
    container.register<PreloadedBookingOptionsRepository>(
      PreloadedBookingOptionsRepositoryToken,
      StorePreloadedBookingOptionsRepository,
    );
    container.register<FacilityIntegrationRepository>(
      FacilityIntegrationRepositoryToken,
      StoreFacilityIntegrationRepository,
    );

    container.register<BookingSlotRepository>(
      BookingSlotRepositoryToken,
      HttpBookingSlotRepository,
    );
    container.register<VisitConfirmationReadRepository>(
      VisitConfirmationReadRepositoryToken,
      StoreVisitConfirmationReadRepository,
    );
    container.register<VisitConfirmationWriteRepository>(
      VisitConfirmationWriteRepositoryToken,
      HttpVisitConfirmationWriteRepository,
    );
    container.register<UpcomingVisitRepository>(
      UpcomingVisitRepositoryToken,
      StoreUpcomingVisitRepository,
    );
    container.register<ServiceRepository>(ServiceRepositoryToken, {
      useFactory: (c: DependencyContainer) =>
        new CachedServiceRepository(c.resolve(HttpServiceRepository), c.resolve(RootCacheToken)),
    });
    container.register<DoctorWithAddressRepository>(DoctorWithAddressToken, {
      useFactory: (c: DependencyContainer) =>
        new CachedDoctorWithAddressRepository(
          c.resolve(HttpDoctorWithAddressRepository),
          c.resolve(RootCacheToken),
        ),
    });
  }
}
