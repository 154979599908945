import type { DateFormatter } from '@/app/formatters/date';
import { RootDateFormatterToken } from '@/common/root';
import type { DateString } from '@/types/commons';
import { inject, injectable } from 'tsyringe';
import type { DateConverter } from '@/modules/tasks-list/domain/task-expiration/DateConverter';
import type { FacilityTimeZone } from '@/modules/tasks-list/domain/task-expiration/FacilityTimeZone';
import { FacilityTimeZoneToken } from '../../di/tokens';

@injectable()
export class DateFnsConverter implements DateConverter {
  constructor(
    @inject(FacilityTimeZoneToken) private readonly facilityTimeZone: FacilityTimeZone,
    @inject(RootDateFormatterToken) private readonly dateFormatter: DateFormatter,
  ) {}

  getDateFromIso(dateString: DateString): Date {
    return this.dateFormatter.dateFromIso(dateString, this.facilityTimeZone.get()).date;
  }
}
