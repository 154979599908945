import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { Store } from 'vuex';
import { DpEventBus } from '@/core/events/DpEventBus';
import { StoreFacilityStateObserver } from '../../adapters/StoreFacilityStateObserver';
import { FacilityLoadedListener } from '../../domain/tracking/FacilityLoadedListener';
import { FacilityUnloadedListener } from '../../domain/tracking/FacilityUnloadedListener';
import { FacilityUnloadedEvent } from '../../domain/events/FacilityUnloadedEvent';
import { FacilityLoadedEvent } from '../../domain/events/FacilityLoadedEvent';

@injectable()
export class ObserverProvider implements DpProvider {
  boot(container: DependencyContainer): void {
    const facilityObserver = container.resolve(StoreFacilityStateObserver);
    const store: Store<any> = container.resolve(RootStoreToken);

    facilityObserver.observeStore(store);

    const eventBus = container.resolve(DpEventBus);
    const facilityLoadedListener = container.resolve(FacilityLoadedListener);
    const facilityUnloadedListener = container.resolve(FacilityUnloadedListener);

    eventBus.subscribe(FacilityLoadedEvent.eventName, facilityLoadedListener);
    eventBus.subscribe(FacilityUnloadedEvent.eventName, facilityUnloadedListener);
  }
}
