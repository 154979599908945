import { Media } from './Media';

export class VoiceMessage {
  constructor(
    readonly type: 'text' | 'file',
    readonly value: string,
    readonly placeholder: string,
    public file: Media,
  ) {}

  get hasFile(): boolean {
    return this.fileUrl.length > 0;
  }

  get isDirty(): boolean {
    return this.file.isDirty;
  }

  get requireFileUpload(): boolean {
    return this.isDirty && this.hasFile;
  }

  get fileUrl(): string {
    return this.file.url;
  }

  static empty(): VoiceMessage {
    return new VoiceMessage('text', '', '', Media.empty());
  }
}
