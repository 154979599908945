import type {
  ChannelPhoneAddRequest,
  ChannelPhoneEditRequest,
  OrderCollection,
  SettingsChannelsPhonesRepository,
} from '@/api/repositories/types/SettingsChannelsPhonesRepository';
import { BaseAxiosRepository } from '@/api/repositories/axios/BaseAxiosRepository';
import type { ChannelPhoneId } from '@/api/models/ChannelPhone';
import { ChannelPhone } from '@/api/models/ChannelPhone';
import type { FacilityId } from '@/api/models/Facility';

export class AxiosSettingsChannelsPhonesRepository
  extends BaseAxiosRepository
  implements SettingsChannelsPhonesRepository
{
  async get(facilityId: FacilityId): Promise<ChannelPhone[]> {
    const data = (await this.axiosClient.get(
      `/api/v0/facility/${facilityId}/settings/phones`,
    )) as ChannelPhone[];

    return Object.values(data).map(ChannelPhone.make);
  }

  async reorder(facilityId: FacilityId, items: OrderCollection): Promise<ChannelPhone[]> {
    const data = (await this.axiosClient.patch(
      `/api/v0/facility/${facilityId}/settings/phones`,
      items,
    )) as ChannelPhone[];

    return data.map(ChannelPhone.make);
  }

  async add(facilityId: FacilityId, item: ChannelPhoneAddRequest): Promise<ChannelPhone> {
    const data = (await this.axiosClient.post(
      `/api/v0/facility/${facilityId}/settings/phones`,
      item,
    )) as ChannelPhone;

    return ChannelPhone.make(data);
  }

  async edit(
    facilityId: FacilityId,
    id: ChannelPhoneId,
    newValue: ChannelPhoneEditRequest,
  ): Promise<ChannelPhone> {
    const data = (await this.axiosClient.put(
      `/api/v0/facility/${facilityId}/settings/phones/${id}`,
      newValue,
    )) as ChannelPhone;

    return ChannelPhone.make(data);
  }

  async delete(
    facilityId: FacilityId,
    sourceId: ChannelPhoneId,
    targetId: ChannelPhoneId,
  ): Promise<void> {
    await this.axiosClient.delete(
      `/api/v0/facility/${facilityId}/settings/phone/${sourceId}/${targetId}`,
    );
  }

  async removeForwarding(facilityId: FacilityId, id: ChannelPhoneId): Promise<ChannelPhone> {
    const data = (await this.axiosClient.post(
      `/api/v0/facility/${facilityId}/settings/phones/${id}/remove-forwarding`,
    )) as ChannelPhone;

    return ChannelPhone.make(data);
  }
}
