export class NetworkError extends Error {
  constructor() {
    super('Cannot fetch data from the status page because of a network error.');

    // Changing prototype for extending default class is recommended by TS
    // https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, NetworkError.prototype);

    this.name = 'NetworkError';
  }
}
