import type { DateFormatter } from '@/modules/user/domain/DateFormatter';
import type { DateFormatter as VendorDateFormatter } from '@/app/formatters/date/types';
import { inject, injectable } from 'tsyringe';
import { RootDateFormatterToken, RootTimeZoneRepositoryToken } from '@/common/root';
import type { TimeZoneRepository } from '@/common/root';

@injectable()
export class ExternalDateFormatter implements DateFormatter {
  constructor(
    @inject(RootTimeZoneRepositoryToken) private readonly timezoneRepository: TimeZoneRepository,
    @inject(RootDateFormatterToken) private readonly formatter: VendorDateFormatter,
  ) {}

  format(unixTimestamp: number): string {
    return this.formatter
      .dateFromUnixTime(unixTimestamp, this.timezoneRepository.get())
      .toTimeOnly();
  }
}
