import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import type { CurrentUserRepository } from '../../domain/feedback-form/CurrentUserRepository';
import {
  CurrentFacilityRepositoryToken,
  CurrentUserRepositoryToken,
  FormControllerToken,
} from '../tokens';
import { UserApiCurrentUserRepository } from '../../adapters/feedback-form/UserApiCurrentUserRepository';
import type { FormController } from '../../domain/feedback-form/FormController';
import { TypeformFormController } from '../../adapters/feedback-form/TypeformFormController';
import type { CurrentFacilityRepository } from '../../domain/feedback-form/CurrentFacilityRepository';
import { StoreCurrentFacilityRepository } from '../../adapters/feedback-form/StoreFacilityRepository';

export class FeedbackFormProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<CurrentUserRepository>(
      CurrentUserRepositoryToken,
      UserApiCurrentUserRepository,
    );

    container.register<CurrentFacilityRepository>(
      CurrentFacilityRepositoryToken,
      StoreCurrentFacilityRepository,
    );

    container.register<FormController>(FormControllerToken, TypeformFormController);
  }
}
