import { injectable } from 'tsyringe';
import type { PanelController } from '../domain/actions/PanelController';
import { panelStoreName } from '../ui/store/panels';
import type { StoreAdapter } from './StoreAdapter';
import { StoreAdapterFactory } from './StoreAdapterFactory';

@injectable()
export class StorePanelController implements PanelController {
  private readonly storeAdapter: StoreAdapter;

  constructor(storeAdapterFactory: StoreAdapterFactory) {
    this.storeAdapter = storeAdapterFactory.make(panelStoreName);
  }

  get isDialKeyboardPanelVisible(): boolean {
    return this.getter('isDialKeyboardActive');
  }

  get isDtmfKeyboardPanelVisible(): boolean {
    return this.getter('isDtmfKeyboardActive');
  }

  get isWorkstationListVisible(): boolean {
    return this.getter('isWorkstationListActive');
  }

  get isInviteToConferenceVisible(): boolean {
    return this.getter('isInviteToConferenceActive');
  }

  hide(): void {
    this.commit('HIDE');
  }

  showDialKeyboard(): void {
    this.commit('SHOW_DIAL_PANEL');
  }

  showDtmfKeyboard(): void {
    this.commit('SHOW_DTMF_PANEL');
  }

  showWorkstationList(): void {
    this.commit('SHOW_WORKSTATION_LIST');
  }

  showInviteToConference(): void {
    this.commit('SHOW_INVITE_TO_CONFERENCE');
  }

  private commit(name: string, payload?: any): void {
    this.storeAdapter.commit(name, payload);
  }

  private getter(name: string): any {
    return this.storeAdapter.getter(name);
  }
}
