import { injectable, inject } from 'tsyringe';
import { AnonymizationEvaluatorToken } from '../di/tokens';
import type { AnonymizationEvaluator } from './AnonymizationEvaluator';
import type { UpdatedPatient } from './UpdatedPatient';

@injectable()
export class UpdatedPatientFactory {
  constructor(
    @inject(AnonymizationEvaluatorToken)
    private readonly anonymizationEvaluator: AnonymizationEvaluator,
  ) {}

  make(patient: UpdatedPatient): UpdatedPatient {
    if (this.anonymizationEvaluator()) {
      return {
        ...patient,
        name: undefined,
        surname: undefined,
        note: undefined,
      };
    }

    return patient;
  }
}
