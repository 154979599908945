import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { CallingGroup } from '@/modules/settings/domain/traffic-control/aggregates/CallingGroup';
import type { CallSelectedGroupRepository } from '@/modules/settings/domain/traffic-control/decide-who-picks/CallSelectedGroupRepository';
import { CallingGroupFactory } from './CallingGroupFactory';
import type { InputPriorityGroup } from '../types/InputPriorityGroup';
import type { OutputPriorityGroup } from '../types/OutputPriorityGroup';

@injectable()
export class HttpCallSelectedGroupRepository implements CallSelectedGroupRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly callingGroupFactory: CallingGroupFactory,
  ) {}

  async get(): Promise<CallingGroup> {
    const priorityGroup: InputPriorityGroup = await this.httpClient.get(
      '/api/v0/facility/{facilityId}/priority-group',
    );

    return this.callingGroupFactory.make(priorityGroup);
  }

  async save(callSelectedGroup: CallingGroup): Promise<void> {
    await this.httpClient.post<OutputPriorityGroup>(
      '/api/v0/facility/{facilityId}/priority-group',
      {
        destinations: callSelectedGroup.workstationIds,
        timeout: callSelectedGroup.fallback.timeout,
        fallback_to_reception: callSelectedGroup.fallback.isRedirectingToAllReceptionWorkstations,
      },
    );
  }
}
