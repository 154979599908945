import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { SettingsState } from '../../domain/settings/SettingsState';
import type { Settings } from '../../domain/settings/settings/Settings';

interface Store<S> {
  getters: {
    'task:settings/getSetting'<T extends keyof S>(key: T);
  };
  commit<T extends keyof S>(message: 'task:settings/SET_SETTING', payload: { key: T; value: S[T] });
}

@injectable()
export class StoreSettingsState implements SettingsState {
  constructor(@inject(RootStoreToken) private readonly store: Store<Settings>) {}

  get<K extends keyof Settings>(key: K): Settings[K] {
    return this.store.getters['task:settings/getSetting'](key);
  }

  set<K extends keyof Settings>(key: K, value: Settings[K]): void {
    this.store.commit('task:settings/SET_SETTING', { key, value });
  }
}
