import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { OmniChannelStatisticsRepositoryToken } from '../tokens';
import type { OmniChannelStatisticsRepository } from '../../domain/omni-statistics/OmniChannelStatisticsRepository';
import { StaticOmniChannelStatisticsRepository } from '../../adapters/omni-statistics/StaticOmniChannelStatisticsRepository';

export class OmniStatisticsProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<OmniChannelStatisticsRepository>(
      OmniChannelStatisticsRepositoryToken,
      StaticOmniChannelStatisticsRepository,
    );
  }
}
