import { HttpClientApi } from '@/modules/http-client/public/api';

import { injectable } from 'tsyringe';
import type { PermissionWriteRepository } from '../../domain/permissions/PermissionWriteRepository';
import type { PermissionProposal } from '../../domain/permissions/PermissionProposal';
import type { Permission } from '../../domain/permissions/Permission';
import { PermissionRole } from '../../domain/permissions/PermissionRole';
import type { Reorderable } from '../../domain/permissions/Reorderable';

@injectable()
export class HttpPermissionWriteRepository implements PermissionWriteRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async create(permissionProposal: PermissionProposal): Promise<void> {
    await this.httpClient.post('/api/v0/facility/{facilityId}/settings/permissions', {
      role: this.getRole(permissionProposal.role),
      user: permissionProposal.userId,
      weight: permissionProposal.weight,
    });
  }

  async edit(permission: Permission): Promise<void> {
    await this.httpClient.put(
      `/api/v0/facility/{facilityId}/settings/permissions/${permission.id}`,
      {
        role: this.getRole(permission.role),
        user: permission.userId,
        weight: permission.weight,
      },
    );
  }

  async delete(permissionId: string): Promise<void> {
    await this.httpClient.delete(
      `/api/v0/facility/{facilityId}/settings/permissions/${permissionId}`,
    );
  }

  async reorder(permissions: Reorderable[]): Promise<void> {
    const payload = permissions.reduce(
      (acc, permission) => ({
        ...acc,
        [permission.id]: {
          weight: permission.weight,
        },
      }),
      {},
    );

    await this.httpClient.patch(`/api/v0/facility/{facilityId}/settings/permissions`, payload);
  }

  private getRole(role: PermissionRole): 'manager' | 'user' {
    return role === PermissionRole.Manager ? 'manager' : 'user';
  }
}
