export enum WizardFormFields {
  ChannelType = 'channel_type',
  ChannelName = 'channel_name',
  PhoneNumber = 'phone_number',
}

export enum Fields {
  Priority = 'priority',
  Name = 'name',
  Type = 'type',
  VirtualNumber = 'virtualNumber',
  PresentationNumber = 'presentationNumber',
}
