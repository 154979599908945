import { inject, injectable } from 'tsyringe';
import { OwnerRepositoryToken, WorkstationRepositoryToken } from '../../di/tokens';
import type { OwnerRepository } from '../owner/OwnerRepository';
import type { Workstation } from './Workstation';
import type { WorkstationChecker } from './WorkstationChecker';
import type { WorkstationRepository } from './WorkstationRepository';

@injectable()
export class RepositoryWorkstationChecker implements WorkstationChecker {
  constructor(
    @inject(WorkstationRepositoryToken)
    private readonly workstationRepository: WorkstationRepository,
    @inject(OwnerRepositoryToken)
    private readonly ownerRepository: OwnerRepository,
  ) {}

  isSelectedPhone(): boolean {
    return !this.getMyWorkstation().isEmpty;
  }

  isSelectedWebPhone(): boolean {
    const workstation = this.getMyWorkstation();

    return workstation.isWebPhone;
  }

  private getMyWorkstation(): Workstation {
    const { id } = this.ownerRepository.getMe();

    return this.workstationRepository.getByUserId(id);
  }
}
