import { injectable } from 'tsyringe';
import { DynamicCallbackEventName } from '../callbacks/DynamicCallbackEventName';
import type { Callback } from '../models/Callback';
import { FrequencyStorage } from './frequency/FrequencyStorage';
import { ThrottledEventSender } from './ThrottledEventSender';

@injectable()
export class TaskUpdateCallback implements Callback {
  readonly name = 'Frequency stats for task';

  readonly eventName = DynamicCallbackEventName.Task;

  readonly channelName = 'pii';

  constructor(
    private readonly throttledEventSender: ThrottledEventSender,
    private readonly frequencyStorage: FrequencyStorage,
  ) {}

  handler = (): void => {
    this.frequencyStorage.add();
    this.throttledEventSender.send();
  };
}
