<template>
  <div :class="$b()">
    <div :class="$b('thumbnail')" @click="openPreview">
      <div :class="$b('thumbnail-overlay')">
        <dp-icon-play :size="48" :class="$b('thumbnail-icon')" />
      </div>
      <video :class="$b('thumbnail-video')" :src="src" @load="$emit('media-loaded')" />
    </div>
    <overlay :is-expanded="isExpanded" @click.stop="closePreview" @close="closePreview">
      <template #buttons>
        <a :class="$b('download-button')" :href="src" download @click.stop>
          <dp-icon-download color="white" :size="20" />
        </a>
      </template>
      <video-player v-if="isExpanded" :src="src" autoplay @click.stop />
    </overlay>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { DpIconDownload, DpIconPlay } from '@dp-ui-kit/icons';

import Overlay from '@/ui/molecules/Overlay/Overlay.vue';
import VideoPlayer from '@/ui/molecules/VideoPlayer/VideoPlayer.vue';

@Component({
  name: 'VideoPreview',
  emits: ['media-loaded'],
  components: { VideoPlayer, Overlay, DpIconDownload, DpIconPlay },
})
export default class VideoPreview extends Vue {
  @Prop({ type: String, required: true })
  readonly src: string;

  isExpanded = false;

  openPreview() {
    this.isExpanded = true;
  }

  closePreview() {
    this.isExpanded = false;
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables' as v;

$button-size: 2.5rem;

.dp-video-preview {
  display: block;
  cursor: pointer;

  &__thumbnail {
    position: relative;
    width: 100%;
  }

  &__thumbnail-overlay {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__thumbnail-icon {
    margin: auto;
  }

  &__thumbnail-video {
    border-radius: v.$border-radius;
  }

  &__download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $button-size;
    height: $button-size;
    border-radius: 50%;
    background: v.$gray-500;
    margin-right: v.$spacer-md;
  }
}
</style>
