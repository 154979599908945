import type { DependencyContainer } from 'tsyringe';
import { injectable } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import type { TaskCallback } from '../../domain/action/TaskCallback';
import {
  CallUpdaterToken,
  RouteResolverToken,
  TaskCallbackToken,
  TaskUpdaterToken,
  WebphoneToken,
} from '../tokens';
import { TaskCallbackAdapter } from '../../adapters/action/TaskCallbackAdapter';
import type { Webphone } from '../../domain/action/Webphone';
import { PhoneWidgetWebphone } from '../../adapters/action/PhoneWidgetWebphoneAdapter';
import type { TaskUpdater } from '../../domain/task/TaskUpdater';
import { TaskUpdaterAdapter } from '../../adapters/task/TaskUpdaterAdapter';
import type { RouteResolver } from '../../domain/action/RouteResolver';
import { RouteResolverAdapter } from '../../adapters/action/RouteResolverAdapter';
import type { CallUpdater } from '../../domain/call/CallUpdater';
import { CallUpdaterAdapter } from '../../adapters/call/CallUpdaterAdapter';

@injectable()
export class ActionProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<TaskCallback>(TaskCallbackToken, TaskCallbackAdapter);
    container.register<Webphone>(WebphoneToken, PhoneWidgetWebphone);
    container.register<RouteResolver>(RouteResolverToken, RouteResolverAdapter);
    container.register<TaskUpdater>(TaskUpdaterToken, TaskUpdaterAdapter);
    container.register<CallUpdater>(CallUpdaterToken, CallUpdaterAdapter);
  }
}
