import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { StorePermissionReadRepository } from '../../adapters/permissions/StorePermissionReadRepository';
import type { PermissionReadRepository } from '../../domain/permissions/PermissionReadRepository';
import {
  InvitationSenderToken,
  PermissionReadRepositoryToken,
  PermissionWriteRepositoryToken,
  UserWriteRepositoryToken,
} from '../token/permissions';
import type { PermissionWriteRepository } from '../../domain/permissions/PermissionWriteRepository';
import { HttpPermissionWriteRepository } from '../../adapters/permissions/HttpPermissionWriteRepository';
import type { UserWriteRepository } from '../../domain/permissions/UserWriteRepository';
import { HttpUserWriteRepository } from '../../adapters/permissions/HttpUserWriteRepository';
import type { InvitationSender } from '../../domain/permissions/InvitationSender';
import { HttpInvitationSender } from '../../adapters/permissions/HttpInvitationSender';

export class PermissionsProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<PermissionReadRepository>(
      PermissionReadRepositoryToken,
      StorePermissionReadRepository,
    );

    container.register<PermissionWriteRepository>(
      PermissionWriteRepositoryToken,
      HttpPermissionWriteRepository,
    );

    container.register<UserWriteRepository>(UserWriteRepositoryToken, HttpUserWriteRepository);

    container.register<InvitationSender>(InvitationSenderToken, HttpInvitationSender);
  }
}
