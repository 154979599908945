import type { RootState } from '@/store/types';
import { injectable } from 'tsyringe';
import type { Store } from 'vuex';
import { StoreEntityHandler } from './StoreEntityHandler';

@injectable()
export class StoreEntitySubscriber {
  private unsubscriber: (() => void) | null = null;

  constructor(
    private readonly store: Store<RootState>,
    private readonly storeEntityHandler: StoreEntityHandler,
  ) {}

  subscribe(): void {
    this.unsubscriber = this.store.subscribe(
      this.storeEntityHandler.handle.bind(this.storeEntityHandler),
    );
  }

  unsubscribe(): void {
    if (typeof this.unsubscriber !== 'function') {
      return;
    }

    this.unsubscriber();
  }
}
