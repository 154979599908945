import { $GET_ENTITIES_BY_ID } from '@dp-vue/entities';
import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';

interface Store {
  getters: {
    [$GET_ENTITIES_BY_ID](type: 'media', ids: string[]): { url: string };
  };
}

@injectable()
export class StoreRecordingUrlRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
  ) {}

  get(id: string): string {
    const { url } = this.store.getters[$GET_ENTITIES_BY_ID]('media', [id])[0];

    return url;
  }
}
