import { TimelineApi } from '@/modules/timeline/public/api';
import { injectable } from 'tsyringe';
import type { Message } from '../../domain/Message';
import type { MessageRepository } from '../../domain/MessageRepository';
import { MessageFactory } from './MessageFactory';
import { StorePatientRepository } from './StorePatientRepository';

@injectable()
export class TimelineMessageRepository implements MessageRepository {
  constructor(
    private readonly patientRepository: StorePatientRepository,
    private readonly timelineApi: TimelineApi,
    private readonly messageFactory: MessageFactory,
  ) {}

  get(taskId: string): Message[] {
    const messages = this.timelineApi.getMessages(taskId);

    const patient = this.patientRepository.getByTaskId(taskId);

    if (!patient) {
      return [];
    }

    return this.messageFactory.makeMany(messages, patient, taskId);
  }
}
