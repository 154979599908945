import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { MemoryUserSessionRepository } from '../../adapters/pause/MemoryUserSessionRepository';
import type { UserSessionRepository } from '../../domain/pause/UserSessionRepository';
import { PauseRepositoryToken, UserSessionRepositoryToken } from '../tokens';
import type { PauseRepository } from '../../domain/pause/PauseRepository';
import { HttpPauseRepository } from '../../adapters/pause/HttpPauseRepository';

export class PauseProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<UserSessionRepository>(
      UserSessionRepositoryToken,
      MemoryUserSessionRepository,
    );

    container.register<PauseRepository>(PauseRepositoryToken, HttpPauseRepository);
  }
}
