<template>
  <div :class="$b()">
    <component :is="icon" :size="12" color="gray-600" />
    <slot />
    <span>-</span>
    <span>{{ formattedDate }}</span>
  </div>
</template>

<script lang="ts">
import type { Component as VueComponent } from 'vue';

import { Vue, Component, Prop } from 'vue-facing-decorator';

import type { IsoDate } from '@/ui/types';

@Component({
  name: 'LabeledDate',
})
export default class LabeledDate extends Vue {
  @Prop({ type: Object, required: true })
  readonly date: IsoDate;

  @Prop({ type: Object, default: null })
  readonly icon: VueComponent | null;

  get formattedDate(): string {
    return this.$dateFormatter.dateFromIso(this.date.isoDate, this.date.timezone).toTimeOnly();
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-labeled-date {
  display: flex;
  align-items: center;
  gap: v.$spacer-xs;
  white-space: nowrap;
  color: v.$gray-600;
  font-size: v.$font-size-sm;
}
</style>
