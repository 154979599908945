import { Address } from './Address';
import { Doctor } from './Doctor';

export class DoctorWithAddress {
  readonly doctor: Doctor;

  readonly address: Address;

  constructor(doctor: Doctor | null, address: Address | null) {
    this.doctor = doctor ?? Doctor.makeEmpty();
    this.address = address ?? Address.makeEmpty();
  }

  get isEmpty(): boolean {
    return this.doctor.isEmpty || this.address.isEmpty;
  }

  get isAvailableForBookings(): boolean {
    return !this.isEmpty && this.address.isAvailableForBookings;
  }
}
