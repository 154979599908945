import type { Message } from './Message';

export class MessagesCount {
  private constructor(
    readonly inboundCount: number,
    readonly outboundCount: number,
  ) {}

  static of(messages: Message[]): MessagesCount {
    return new MessagesCount(
      messages.filter(message => message.direction === 'inbound').length,
      messages.filter(message => message.direction === 'outbound').length,
    );
  }

  static empty(): MessagesCount {
    return new MessagesCount(0, 0);
  }
}
