import { Locale } from '@/utils/languageDetector';

export class LocaleMapper {
  static toIntlLocale(locale: Locale): string {
    return (
      {
        [Locale.BR]: 'pt-BR',
        [Locale.ES]: 'es-ES',
        [Locale.IT]: 'it-IT',
        [Locale.MX]: 'es-MX',
        [Locale.PL]: 'pl-PL',
      }[locale] ?? 'en-US'
    );
  }
}
