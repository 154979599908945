import { injectable } from 'tsyringe';
import { AccessToken } from '@/modules/authentication/domain/oauth/token/AccessToken';
import type { AccessTokenRepository } from '@/modules/authentication/domain/oauth/token/AccessTokenRepository';

@injectable()
export class LocalStorageAccessTokenRepository implements AccessTokenRepository {
  findTokens(scope: 'user' | 'app'): AccessToken | null {
    const token = localStorage.getItem(this.getStoreKey(scope));

    if (!token) {
      return null;
    }

    try {
      return this.recreateTokenFromString(token);
    } catch {
      // If token is invalid, delete it.
      this.deleteTokens(scope);

      return null;
    }
  }

  saveTokens(scope: 'user' | 'app', accessToken: AccessToken): void {
    localStorage.setItem(this.getStoreKey(scope), JSON.stringify(accessToken));
  }

  deleteTokens(scope: 'user' | 'app'): void {
    localStorage.removeItem(this.getStoreKey(scope));
  }

  private getStoreKey(scope: 'user' | 'app'): string {
    return `tokens:${scope}`;
  }

  private recreateTokenFromString(tokenString: string): AccessToken {
    const token: AccessToken = JSON.parse(tokenString);

    if (['expiresAt', 'expiresIn', 'accessToken'].some(key => !(key in token))) {
      throw new Error('Invalid token data');
    }

    return AccessToken.recreate(
      token.accessToken,
      token.refreshToken,
      token.expiresIn,
      token.expiresAt,
    );
  }
}
