import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { WorkingHourOverride } from '../../domain/working-hours/WorkingHourOverride';
import type { WorkingHourOverrideRepository } from '../../domain/working-hours/WorkingHourOverrideRepository';
import { OutputWorkingHourOverrideFactory } from './OutputWorkingHourOverrideFactory';
import type { InputWorkingHourOverride } from './types/InputWorkingHourOverride';
import { WorkingHourOverrideFactory } from './WorkingHourOverrideFactory';

@injectable()
export class HttpWorkingHourOverrideRepository implements WorkingHourOverrideRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly workingHourOverrideFactory: WorkingHourOverrideFactory,
    private readonly outputWorkingHourOverrideFactory: OutputWorkingHourOverrideFactory,
  ) {}

  async all(): Promise<WorkingHourOverride[]> {
    const inputWorkingHourOverrides = await this.httpClient.get<InputWorkingHourOverride[]>(
      '/api/v0/facilities/{facilityId}/working-hours-overrides',
    );

    return this.workingHourOverrideFactory
      .makeMany(inputWorkingHourOverrides)
      .sort(this.ascendingByDate);
  }

  async add(workingHourOverride: WorkingHourOverride): Promise<void> {
    await this.httpClient.post(
      '/api/v0/facilities/{facilityId}/working-hours-overrides',
      this.outputWorkingHourOverrideFactory.make(workingHourOverride),
    );
  }

  async remove(workingHourOverrideId: string): Promise<void> {
    await this.httpClient.delete(
      `/api/v0/facilities/{facilityId}/working-hours-overrides/${workingHourOverrideId}`,
    );
  }

  private ascendingByDate(alpha: WorkingHourOverride, bravo: WorkingHourOverride): number {
    return alpha.date.getTime() - bravo.date.getTime();
  }
}
