<template>
  <div class="tw-form-group tw-w-full" :class="customClassNames">
    <label v-if="label" :class="`control-label label-${size}`">{{ label }}</label>
    <div>
      <dp-datepicker
        v-model="date"
        :placeholder="placeholder"
        :disabled="disabled"
        :size="size"
        :full-width="true"
        :typeable="true"
        :class="`form-input-datepicker form-input-datepicker--${size}`"
        @select="selectDate"
        @clear="clearDate"
      />
      <p v-if="hasError" class="form-text">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { DpDatepicker } from '@dp-ui-kit/vue';

export default defineComponent({
  name: 'FormInputDatepicker',

  components: {
    DpDatepicker,
  },

  inheritAttrs: false,

  props: {
    value: {
      type: Date,
      default: null,
    },

    placeholder: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: null,
    },

    size: {
      type: String,
      default: 'md',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    validationResult: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:modelValue'],

  computed: {
    customClassNames() {
      return {
        'has-error': this.hasError,
      };
    },

    date: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit('update:modelValue', val);
      },
    },

    hasError() {
      return this.validationResult?.type === 'error';
    },

    errorMessage() {
      if (!this.hasError) {
        return '';
      }

      return this.validationResult.messages[0];
    },
  },

  methods: {
    clearDate() {
      this.$emit('update:modelValue', null);
    },

    selectDate(date) {
      this.$emit('update:modelValue', date);
    },
  },
});
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.has-error :deep(.form-control) {
  border-right: 1px solid v.$danger;
}
</style>
