import {
  TimelineCall,
  TimelineWhatsAppMessage,
  TimelinePatientRequest,
  TimelineSmsMessage,
} from '@/modules/timeline/public/types';
import { injectable } from 'tsyringe';
import { TimelineContactForm } from '@/modules/timeline/public/api/data-transfer/contact-form/TimelineContactForm';
import { ContactForm } from '@/modules/task/domain/contact-form/ContactForm';
import { CallFactory } from '../call/CallFactory';
import { MessageFactory } from '../message/MessageFactory';
import { PatientRequest } from '../../domain/patient-request/PatientRequest';
import type { EventType as DomainEventType } from '../../domain/timeline/EventType';

type EventType =
  | TimelineCall
  | TimelineWhatsAppMessage
  | TimelineSmsMessage
  | TimelineContactForm
  | TimelinePatientRequest;

@injectable()
export class TimelineEventFactory {
  constructor(
    private readonly callFactory: CallFactory,
    private readonly messageFactory: MessageFactory,
  ) {}

  makeEvents(events: EventType[]): DomainEventType[] {
    return events.map(this.makeEvent, this);
  }

  private makeEvent(event: EventType): DomainEventType {
    if (event instanceof TimelineContactForm) {
      return new ContactForm(event.id, event.createdAt, event.channelId);
    }

    if (event instanceof TimelineCall) {
      return this.callFactory.make(event);
    }

    if (event instanceof TimelinePatientRequest) {
      return new PatientRequest(event.id, event.createdAt, event.type, event.subject);
    }

    return this.messageFactory.make(event);
  }
}
