interface LoginResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
}

export class UserTokenPayload {
  constructor(
    public accessToken: string,
    public expiresIn: number,
    public refreshToken: string,
  ) {}

  static makeFromResponse(data: LoginResponse): UserTokenPayload {
    return new UserTokenPayload(data.access_token, data.expires_in, data.refresh_token);
  }
}
