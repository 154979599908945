import { injectable } from 'tsyringe';
import type { OwnerId } from '@/modules/task/domain/owner/OwnerId';
import type { Owner } from '@/modules/task/domain/owner/Owner';
import type { OwnerRepository } from '@/modules/task/domain/owner/OwnerRepository';
import { OwnerFactory } from '@/modules/task/adapters/owner/OwnerFactory';
import { UserApi } from '@/modules/user/public/api/UserApi';

@injectable()
export class ApiOwnerRepository implements OwnerRepository {
  constructor(private userApi: UserApi) {}

  get(id: OwnerId): Owner {
    const { name, avatar, status } = this.userApi.getById(id.value);

    return OwnerFactory.make(id.value, name, avatar, status);
  }

  getMe(): Owner {
    const { id, name, avatar, status } = this.userApi.getCurrentUser();

    return OwnerFactory.make(id, name, avatar, status);
  }

  getAll(): Owner[] {
    const users = this.userApi.getAll();

    return users.map(({ id, name, avatar, status }) => OwnerFactory.make(id, name, avatar, status));
  }
}
