import type { TaskCounterRepository } from '@/modules/tasks-list/domain/counters/TaskCounterRepository';
import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { TaskGroup } from '@/modules/tasks-list/domain/task/TaskGroup';

interface Store {
  getters: {
    getTasksCounterForGroup: (group: TaskGroup) => number;
  };
}

@injectable()
export class StoreTaskCounterRepository implements TaskCounterRepository {
  constructor(@inject(RootStoreToken) private readonly store: Store) {}

  getGroupCount(group: TaskGroup): number {
    return this.store.getters.getTasksCounterForGroup(group);
  }
}
