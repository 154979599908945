import type { TaskRepository } from '@/modules/phone-widget/connection/TaskRepository';
import type { Task as StoreTask } from '@/api/models/Task';
import type { Task } from '@/modules/phone-widget/connection/Task';
import type { TaskFactory } from '@/modules/phone-widget/connection/TaskFactory';

export class StoreGetterTaskRepository implements TaskRepository {
  constructor(
    private readonly factory: TaskFactory,
    private readonly idFinder: (id: string) => StoreTask | null,
    private readonly ongoingFinder: () => StoreTask | null,
  ) {}

  find(id: string): Task | null {
    return this.factory.tryMake(this.idFinder(id));
  }

  findOngoing(): Task | null {
    return this.factory.tryMake(this.ongoingFinder());
  }
}
