<template>
  <div :class="$b()">
    <slot />
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator';

@Component({
  name: 'TabGroup',
})
export default class TabGroup extends Vue {}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables' as v;

.dp-tab-group {
  display: flex;
  gap: v.$spacer-md;
}
</style>
