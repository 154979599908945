import type { Timeline } from '@/modules/task/domain/timeline/Timeline';
import type { IconStrategy } from '../types';
import type { Task } from '../../task/Task';
import { IconName } from '../types';

export class OutgoingUnanswered implements IconStrategy {
  get(task: Task, { lastCall }: Timeline): null | IconName {
    return lastCall.isUnanswered && lastCall.isOutgoing ? IconName.OutgoingUnanswered : null;
  }
}
