import type { CallCount } from '@/modules/timeline/domain/call/CallCount';

export class TimelineCallCountOutput {
  private constructor(
    readonly successfulCount: number,
    readonly unsuccessfulCount: number,
  ) {}

  static of(callCount: CallCount): TimelineCallCountOutput {
    return new TimelineCallCountOutput(callCount.successfulCount, callCount.unsuccessfulCount);
  }
}
