export interface LinePositionInput {
  enabled: boolean;
}

export class LinePosition {
  public enabled: boolean;

  private constructor({ enabled }: LinePositionInput) {
    this.enabled = enabled;
  }

  static make(data: LinePositionInput): LinePosition {
    return new LinePosition(data);
  }
}
