import type { RouteRecordRaw } from 'vue-router';
import { createWebHashHistory, createRouter } from 'vue-router';
import multiguard from 'vue-router-multiguard';
import { UserRole } from '@/app/user-roles';
import { NavigationRoute as SettingsNavigationRouteName } from '@/modules/settings/public/router/NavigationRoute';
import { settings } from '@/modules/settings/public/router';
import { SettingMenu } from '@/modules/settings/public/policies';
import { guard } from '@/common/router/public';
import { ifAuthenticated, hasSsoFlowStateParams } from '@/modules/authentication/public/guards';
import {
  ifAuthenticatedAndManager,
  hasRole,
  ifHasAccessToFacility,
  canLoadChannelsWizard,
} from './guards';
import { ChangeWorkstation } from '@/modules/workstation/public/policies';
import { AccessSettings } from '@/modules/dashboard/public/policies';

export const routes: RouteRecordRaw[] = [
  {
    path: '/select-phone',
    name: 'select-phone',
    component: () =>
      import(/* webpackChunkName: "core" */ '../modules/workstation/public/components').then(
        module => module.SelectWorkstation,
      ),
    beforeEnter: multiguard([ifAuthenticated, guard().hasAuthorization(ChangeWorkstation)]),
  },
  {
    path: '/select-facility/:id?',
    name: 'select-facility',
    component: () => import(/* webpackChunkName: "core" */ '../views/SelectFacility.vue'),
    beforeEnter: ifAuthenticated,
    props: true,
  },
  {
    path: '/create-facility',
    name: 'create-facility',
    component: () => import(/* webpackChunkName: "core" */ '../views/CreateFacility.vue'),
    beforeEnter: hasRole(UserRole.CreateFacility),
  },
  {
    path: '/change-language',
    name: 'change-language',
    component: () => import(/* webpackChunkName: "core" */ '../views/ChangeLanguage.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/form-widget/:id',
    name: 'form-widget',
    component: () => import(/* webpackChunk: "widget" */ '../views/FormWidget.vue'),
    props: true,
  },
  {
    path: '/not-supported',
    name: 'not-supported',
    component: () => import(/* webpackChunkName: "notSupported" */ '../views/BrowserSupport.vue'),
  },
  {
    path: '/call/:phoneNumber',
    redirect: { name: 'call' },
  },
  {
    path: '/app',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName: "core" */ '../modules/dashboard/ui/pages/TheDashboard.vue'),
    beforeEnter: ifAuthenticated,
    redirect: {
      name: 'home',
    },
    children: [
      {
        path: 'home',
        name: 'home',
        beforeEnter: ifHasAccessToFacility,
        component: () => import(/* webpackChunkName: "core" */ '../views/app/Home.vue'),
        props: true,
        redirect: {
          name: 'tasksGroup',
          params: {
            group: 'open',
          },
        },
        children: [
          {
            path: ':group(open|todo|all)/:subGroup?',
            name: 'tasksGroup',
            component: () =>
              import(/* webpackChunkName: "core" */ '../views/app/home/HomeTasksList.vue'),
            props: true,
          },
          {
            path: 'task/:id',
            name: 'task',
            component: () =>
              import(/* webpackChunkName: "core" */ '../views/app/home/ShowSingleTask.vue'),
            props: true,
          },
        ],
      },
      {
        path: 'settings',
        name: 'settings',
        component: () =>
          import(/* webpackChunkName: "settings" */ '../modules/settings/ui/pages/TheSettings.vue'),
        beforeEnter: guard().hasAuthorization(AccessSettings),
        children: [
          {
            path: 'general-settings',
            name: SettingsNavigationRouteName.GeneralSettings,
            component: () =>
              import(
                /* webpackChunkName: "settings" */ '../modules/settings/ui/pages/GeneralSettings.vue'
              ),
          },
          {
            path: 'setup-integration-profile/:clientId/:code/:redirectUri',
            name: 'setupIntegrationProfile',
            beforeEnter: hasSsoFlowStateParams,
            component: () =>
              import(
                /* webpackChunkName: "settings" */ '@/modules/authentication/public/components'
              ).then(module => module.SetupIntegrationProfile),
          },
          {
            path: 'working-hours',
            name: SettingsNavigationRouteName.WorkingHours,
            component: () =>
              import(/* webpackChunkName: "settings" */ '../views/app/settings/WorkingHours.vue'),
            beforeEnter: guard().hasAuthorization(SettingMenu.AccessWorkingHoursSettings),
          },
          {
            path: 'statuses',
            name: SettingsNavigationRouteName.Subjects,
            beforeEnter: guard().hasAuthorization(SettingMenu.AccessSubjectsSettings),
            component: () =>
              import(
                /* webpackChunkName: "settings" */ '../views/app/settings/StatusesSettings.vue'
              ),
          },
          {
            path: 'work-stations',
            name: SettingsNavigationRouteName.Workstations,
            component: () =>
              import(
                /* webpackChunkName: "settings" */ '../modules/settings/ui/workstation/pages/TheWorkstation.vue'
              ),
            beforeEnter: guard().hasAuthorization(SettingMenu.AccessWorkstationsSettings),
          },
          {
            path: 'permissions',
            name: SettingsNavigationRouteName.Permissions,
            component: () =>
              import(
                /* webpackChunkName: "settings" */ '../views/app/settings/PermissionsSettings.vue'
              ),
            beforeEnter: guard().hasAuthorization(SettingMenu.AccessPermissionSettings),
          },
          ...settings,
          {
            path: 'data-export',
            name: 'data-export',
            component: () =>
              import(
                /* webpackChunkName: "settings" */ '../modules/settings-data-exports/ui/pages/DataExport.vue'
              ),
          },
          {
            path: 'channels',
            name: 'channels-settings',
            component: () =>
              import(
                /* webpackChunkName: "settings" */ '../views/app/settings/ChannelsSettings.vue'
              ),
            beforeEnter: guard().hasAuthorization(SettingMenu.AccessChannelsSettings),
            children: [
              {
                path: 'add',
                name: 'channels-settings-add',
                beforeEnter: canLoadChannelsWizard,
                component: () =>
                  import(
                    /* webpackChunkName: "settings" */ '../views/app/settings/channels/ChannelsAdd.vue'
                  ),
              },
              {
                path: 'add-pre-compliance',
                name: 'channels-settings-add-pre-compliance',
                beforeEnter: canLoadChannelsWizard,
                component: () =>
                  import(
                    /* webpackChunkName: "settings" */ '../views/app/settings/channels/ChannelsAddPreCompliance.vue'
                  ),
              },
              {
                path: 'convert',
                name: 'channels-settings-convert',
                beforeEnter: canLoadChannelsWizard,
                component: () =>
                  import(
                    /* webpackChunkName: "settings" */ '../views/app/settings/channels/ChannelsConvert.vue'
                  ),
              },
            ],
          },
          {
            path: 'contacts',
            name: 'contacts',
            component: () =>
              import(
                /* webpackChunkName: "settings" */ '../views/app/settings/PatientsSettings.vue'
              ),
            beforeEnter: guard().hasAuthorization(SettingMenu.AccessContactList),
          },
          {
            path: 'compliance',
            name: 'compliance',
            component: () =>
              import(
                /* webpackChunkName: "settings" */ '../views/app/settings/ComplianceSettings.vue'
              ),
            beforeEnter: guard().hasAuthorization(SettingMenu.AccessComplianceSettings),
            children: [
              {
                path: 'add',
                name: 'compliance-add',
                component: () =>
                  import(
                    /* webpackChunkName: "settings" */ '../views/app/settings/compliance/ComplianceAdd.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'account',
        name: 'account',
        component: () => import(/* webpackChunkName: "account" */ '../views/app/AccountLayout.vue'),
      },
      {
        path: 'call/:phoneNumber',
        name: 'call',
        component: () => import(/* webpackChunkName: "core" */ '../views/app/Call.vue'),
        props: true,
      },
      {
        path: 'webphone',
        name: 'webphone',
        component: () =>
          import(
            /* webpackChunkName: "webphone" */ '../modules/phone-widget/ui/pages/WebphoneTest.vue'
          ),
      },
      {
        path: 'no-facility',
        name: 'no-facility',
        component: () =>
          import(/* webpackChunkName: "no-facility" */ '../views/app/NoFacility.vue'),
      },
    ],
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import(/* webpackChunkName: "onboarding" */ '../views/OnboardingView.vue'),
    beforeEnter: ifAuthenticatedAndManager,
  },
  {
    path: '/terms/:fileName',
    name: 'terms',
    component: () => import(/* webpackChunkName: "onboarding" */ '../views/TermsPreview.vue'),
    props: true,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

export const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes,
});
