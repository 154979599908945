import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { ChannelTagFactory } from '@/modules/task/domain/tag/factories/ChannelTagFactory';
import { VoiceMenuTagFactory } from '@/modules/task/domain/tag/factories/VoiceMenuTagFactory';
import { ReminderTagFactory } from '@/modules/task/domain/tag/factories/ReminderTagFactory';
import { CallTagFactory } from '@/modules/task/domain/tag/factories/CallTagFactory';
import {
  CallTagFactoryToken,
  ChannelTagFactoryToken,
  ReminderTagFactoryToken,
  VoiceMenuTagFactoryToken,
} from '@/modules/task/di/tokens';

@injectable()
export class TagProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register(ReminderTagFactoryToken, ReminderTagFactory);
    container.register(ChannelTagFactoryToken, ChannelTagFactory);
    container.register(VoiceMenuTagFactoryToken, VoiceMenuTagFactory);
    container.register(CallTagFactoryToken, CallTagFactory);
  }
}
