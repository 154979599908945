import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { EventSenderToken } from '../tokens';
import { CommonEventSender } from '../../adapters/tracking/CommonEventSender';
import type { EventSender } from '../../domain/tracking/EventSender';

export class TrackingProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<EventSender>(EventSenderToken, CommonEventSender);
  }
}
