import type { RouteRecordRaw } from 'vue-router';
import { hasSsoFlowStateParams } from '../guards';

export const ssoIntegrationRoutes: RouteRecordRaw[] = [
  {
    path: '/auth',
    name: 'ssoAuth',
    component: () => import(/* webpackChunkName: "auth" */ './SsoAuth.vue'),
  },
  {
    path: '/auth/login/:clientId/:code/:redirectUri',
    name: 'ssoFlowLogin',
    beforeEnter: hasSsoFlowStateParams,
    component: () => import(/* webpackChunkName: "auth" */ './SsoFlowLogin.vue'),
  },
];
