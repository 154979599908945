import { inject, injectable } from 'tsyringe';
import { OwnerRepositoryToken, WorkstationRepositoryToken } from '@/modules/task/di/tokens';
import type { OwnerRepository } from '../owner/OwnerRepository';
import type { WorkstationRepository } from '../workstation/WorkstationRepository';
import type { Call } from './types/Call';

@injectable()
export class CallOwnershipEvaluator {
  constructor(
    @inject(OwnerRepositoryToken)
    private readonly ownerRepository: OwnerRepository,
    @inject(WorkstationRepositoryToken)
    private readonly workstationRepository: WorkstationRepository,
  ) {}

  isMine(call: Call): boolean {
    const me = this.ownerRepository.getMe();

    if (call.hasOwner) {
      return me.owns(call);
    }

    const workstation = this.workstationRepository.get(call.workstationId);

    return workstation.hasAssignedUser(me.id);
  }
}
