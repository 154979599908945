import { injectable } from 'tsyringe';
import { DpEventBus } from '@/core/events/DpEventBus';
import type { DpProvider } from '@/core/di/DpProvider';
import { InteractionEventLogger } from '@/common/tracking-event/domain/event-console-logger-subscriber/InteractionEventLogger';
import { DurationEventLogger } from '@/common/tracking-event/domain/event-console-logger-subscriber/DurationEventLogger';
import { SingularEventLogger } from '@/common/tracking-event/domain/event-console-logger-subscriber/SingularEventLogger';
import { DpEventName } from '@/common/tracking-event/domain/DpEventName';

@injectable()
export class TrackingEventSubscriberProvider implements DpProvider {
  constructor(
    private readonly eventBus: DpEventBus,
    private readonly interactionEventLogger: InteractionEventLogger,
    private readonly durationEventLogger: DurationEventLogger,
    private readonly singularEventLogger: SingularEventLogger,
  ) {}

  boot(): void {
    this.eventBus.subscribe(DpEventName.Interaction, this.interactionEventLogger);
    this.eventBus.subscribe(DpEventName.Duration, this.durationEventLogger);
    this.eventBus.subscribe(DpEventName.Singular, this.singularEventLogger);
  }
}
