import { injectable } from 'tsyringe';
import { DpEventBus } from '@/core/events/DpEventBus';
import type { RefreshableCallingDevice } from '../device/RefreshableCallingDevice';
import type { DeviceCall } from '../models/DeviceCall';
import { DeviceState } from '../models/DeviceState';
import type { InputCall } from '../models/dto/InputCall';
import { ActiveCallStateTracker } from './ActiveCallStateTracker';
import { DeviceStateRepository } from './DeviceStateRepository';
import { DeviceStateChanged } from '../events/DeviceStateChanged';

@injectable()
export class DeviceStateTracker {
  constructor(
    private readonly deviceStateRepository: DeviceStateRepository,
    private readonly activeCallStatTracker: ActiveCallStateTracker,
    private readonly eventPublisher: DpEventBus,
  ) {}

  get deviceState(): DeviceState {
    return this.deviceStateRepository.get();
  }

  private get activeCall(): DeviceCall {
    return this.activeCallStatTracker.activeCall;
  }

  handleCall(call: InputCall): void {
    if (this.activeCall.sid === call.parameters.CallSid) {
      return;
    }

    if (this.activeCall.isActive) {
      call.reject();
      return;
    }

    this.activeCallStatTracker.setActiveCall(call);
  }

  attachDeviceListeners(callingDevice: RefreshableCallingDevice): void {
    callingDevice.addListener('incoming', (call: InputCall) => {
      this.handleCall(call);
    });
    callingDevice.addListener('unregistered', () => {
      this.setDeviceState(DeviceState.Unregistered);
    });
    callingDevice.addListener('registering', () => {
      this.setDeviceState(DeviceState.Registering);
    });
    callingDevice.addListener('registered', () => {
      this.setDeviceState(DeviceState.Registered);
    });
    callingDevice.addListener('destroyed', () => {
      this.setDeviceState(DeviceState.Destroyed);
    });
    // callingDevice.addListener('tokenWillExpire', () => {
    //   console.log('TOKEN WILL EXPIRE!!!');
    // });
  }

  private setDeviceState(newDeviceState: DeviceState): void {
    this.deviceStateRepository.set(newDeviceState);
    this.eventPublisher.publish(new DeviceStateChanged(newDeviceState));
  }
}
