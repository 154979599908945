import { singleton } from 'tsyringe';

/**
 * Simple storage for extra data sent with together with queue ids. It has 2 type of storage:
 *   - one that overwrites data on each call, it's used to overwrite task payload received from the pusher
 *   - second that accumulates data, it's used to accumulate type of updates received from the pusher.
 *     In other words, there is array of string that keeps info what was change in a given task payload.
 */
@singleton()
export class PayloadStorage<T = unknown> {
  private readonly storage = new Map<string, T>();

  private readonly accumulatedStorage = new Map<string, string[]>();

  setOrReplace(id: string, payload: T): void {
    this.storage.set(id, payload);
  }

  merge(id: string, payload: string[]): void {
    const accumulatedPayload = this.accumulatedStorage.get(id) ?? [];

    this.accumulatedStorage.set(id, [...accumulatedPayload, ...payload]);
  }

  pop(id: string): T | undefined {
    const payload = this.storage.get(id);
    this.storage.delete(id);
    return payload;
  }

  popAccumulated(id: string): string[] {
    const payload = this.accumulatedStorage.get(id) ?? [];
    this.accumulatedStorage.delete(id);
    return payload;
  }

  reset(): void {
    this.storage.clear();
    this.accumulatedStorage.clear();
  }
}
