import { injectable, inject } from 'tsyringe';
import {
  InteractionEventPublisherToken,
  DurationEventTrackerToken,
  SingularEventPublisherToken,
  EventPublisherToken,
} from '../../di/tokens';
import type { DurationEventTracker } from '../../domain/event-publisher/duration-event-publisher/DurationEventTracker';
import type { InteractionEventPublisher } from '../../domain/event-publisher/interaction-event-publisher/InteractionEventPublisher';
import type { SingularEventPublisher } from '../../domain/event-publisher/singular-event-publisher/SingularEventPublisher';
import type { EventPublisher } from '../../domain/event-publisher/EventPublisher';
import type { InteractionEventExtraProperties } from '../../domain/models/InteractionEventExtraProperties';
import { InteractionEventType } from '../../domain/models/InteractionEventType';
import { DurationEvent } from '../../domain/models/DurationEvent';

@injectable()
export class EventTrackerFacade {
  constructor(
    @inject(InteractionEventPublisherToken)
    private readonly interactionEventPublisher: InteractionEventPublisher,
    @inject(DurationEventTrackerToken)
    private readonly durationEventTracker: DurationEventTracker,
    @inject(SingularEventPublisherToken)
    private readonly singularEventPublisher: SingularEventPublisher,
    @inject(EventPublisherToken)
    private readonly eventPublisher: EventPublisher,
  ) {}

  publishBusinessEvent(name: string, eventProperties?: InteractionEventExtraProperties): void {
    this.interactionEventPublisher.send(InteractionEventType.BusinessEvent, name, eventProperties);
  }

  publishContentSwitch(eventProperties?: InteractionEventExtraProperties): void {
    this.interactionEventPublisher.send(InteractionEventType.ContentSwitch, null, eventProperties);
  }

  performanceStart(name: string): void {
    this.durationEventTracker.start(name);
  }

  performanceEnd(name: string): void {
    this.durationEventTracker.end(name);
  }

  performanceHasStartedMeasurement(name: string): boolean {
    return this.durationEventTracker.isStarted(name);
  }

  async performanceMeasure(name: string, callback: () => Promise<void>): Promise<void> {
    await this.durationEventTracker.measure(name, callback);
  }

  publishSingularEvent(name: string): void {
    this.singularEventPublisher.tryPublishEvent(name);
  }

  publishCustomPerformance(
    name: string,
    duration: number | null,
    extraProperties?: Record<string, string | number | boolean | null>,
  ): void {
    this.eventPublisher.send(new DurationEvent(name, duration, extraProperties));
  }
}
