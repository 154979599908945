const fmod = (a, b) => Number((a - Math.floor(a / b) * b).toPrecision(8));
const hash = str =>
  Math.abs(str.split(/(?=.)/).reduce((res, char) => (res << 5) - res + char.charCodeAt(0) || 0, 0));

// http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
export const contrast = (alpha, bravo) => {
  const norm = value => (value < 0.03928 ? value / 12.92 : ((value + 0.055) / 1.055) ** 2.4);
  const relativeLuminance = ({ r, g, b }) => norm(r) * 0.2126 + norm(g) * 0.7152 + norm(b) * 0.0722;

  const ratio = relativeLuminance(alpha) / relativeLuminance(bravo);

  return ratio < 1 ? 1 / ratio : ratio;
};

// https://stackoverflow.com/a/36722579/42522
export const HSLtoRGB = (h, s, l) => {
  let r;
  let g;
  let b;

  if (s === 0) {
    r = l;
    g = l;
    b = l;
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) {
        t += 1;
      }
      if (t > 1) {
        t -= 1;
      }
      if (t < 1 / 6) {
        return p + (q - p) * 6 * t;
      }
      if (t < 1 / 2) {
        return q;
      }
      if (t < 2 / 3) {
        return p + (q - p) * (2 / 3 - t) * 6;
      }
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return { r, g, b };
};

// https://axonflux.com/handy-rgb-to-hsl-and-rgb-to-hsv-color-model-c
const HSVtoRGB = (h, s, v) => {
  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);

  const rgb = {
    0: { r: v, g: t, b: p },
    1: { r: q, g: v, b: p },
    2: { r: p, g: v, b: t },
    3: { r: p, g: q, b: v },
    4: { r: t, g: p, b: v },
    5: { r: v, g: p, b: q },
  }[i % 6];

  return {
    r: Math.round(rgb.r * 255),
    g: Math.round(rgb.g * 255),
    b: Math.round(rgb.b * 255),
  };
};

export const userColor = username => {
  const bucket = hash(username) % 16;

  const phi = (1 + Math.sqrt(5)) / 2 - 1;
  const hue = Math.round(fmod(bucket * phi, 1.0) * 360);
  const saturation = 0.12;
  const value = 0.97;

  const lightness = Math.round(value * (1 - saturation / 2) * 100);

  return {
    isDark: lightness < 68,
    ...HSVtoRGB(hue / 360, saturation, value),
  };
};
