import type { User } from './User';

export class Workstation {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly type: 'web' | 'mobile' | 'sip' | 'pstn',
    readonly mode: 'always' | 'signed-in' | 'backup' | 'never',
    readonly internalNumber: string,
    readonly user: User | null,
  ) {}

  get displayedLabel(): string {
    return this.user?.name ?? this.name;
  }
}
