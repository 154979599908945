export class TimelinePatientRequest {
  constructor(
    readonly id: string,
    readonly createdAt: string,
    readonly type:
      | 'prescription'
      | 'certificate'
      | 'refill-prescription'
      | 'test-results'
      | 'other',
    readonly subject: string,
  ) {}
}
