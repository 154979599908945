import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import type { UserDataFetcher } from '../../domain/login/me/UserDataFetcher';
import { UserDataFetcherToken, UserDataManagerToken } from '../token';
import { HttpUserDataFetcher } from '../../adapters/login/HttpUserDataFetcher';
import type { UserDataManager } from '../../domain/login/me/UserDataManager';
import { StoreUserDataManager } from '../../adapters/login/StoreUserDataManager';

export class LoginProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<UserDataFetcher>(UserDataFetcherToken, HttpUserDataFetcher);

    container.register<UserDataManager>(UserDataManagerToken, StoreUserDataManager);
  }
}
