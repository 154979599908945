import { inject, injectable } from 'tsyringe';
import { AnonymousHttpClientApi } from '@/modules/http-client/public/api';
import type { RequestDetailsRepository } from '@/modules/authentication/domain/password-reset/details/RequestDetailsRepository';
import { RequestDetails } from '@/modules/authentication/domain/password-reset/details/RequestDetails';
import type { LocaleDetector } from '@/modules/authentication/domain/LocaleDetector';
import { LocaleDetectorToken } from '@/modules/authentication/di/token';

interface RequestDetailsResponse {
  name: string;
  email: string;
}

@injectable()
export class HttpRequestDetailsRepository implements RequestDetailsRepository {
  constructor(
    @inject(LocaleDetectorToken)
    private readonly localeDetector: LocaleDetector,
    private readonly httpClient: AnonymousHttpClientApi,
  ) {}

  async get(token: string, phoneNumber?: string): Promise<RequestDetails> {
    const { name, email } = await this.httpClient.get<RequestDetailsResponse>(
      this.getUrl(token, phoneNumber),
    );

    return new RequestDetails(name, email);
  }

  async exists(token: string, phoneNumber?: string): Promise<boolean> {
    try {
      await this.httpClient.get<RequestDetailsResponse>(this.getUrl(token, phoneNumber));

      return true;
    } catch (error) {
      if (error.statusCode === 400) {
        return false;
      }

      throw error;
    }
  }

  private getUrl(token: string, phoneNumber?: string): string {
    const baseUrl = `/auth/reset-password`;

    if (phoneNumber) {
      const { locale } = this.localeDetector.getBasedOnDomain();

      return `${baseUrl}/${token}/${locale}/${phoneNumber}`;
    }

    return `${baseUrl}/${token}`;
  }
}
