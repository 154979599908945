import type { DpProvider } from '@/core/di/DpProvider';
import { inject, injectable } from 'tsyringe';
import type { Router } from 'vue-router';
import { RootRouterToken } from '@/common/root';
import { routerConfig } from '@/common/app-config/router';

@injectable()
export class RouterProvider implements DpProvider {
  constructor(@inject(RootRouterToken) private readonly rootRouter: Router) {}

  boot(): void {
    const { routes } = routerConfig;
    routes.forEach(route => {
      this.rootRouter.addRoute(route);
    });
  }
}
