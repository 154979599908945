import type { Cache } from '@/common/root/domain/Cache';
import { RootCacheToken } from '@/common/root/providers/RootCacheProvider';
import { inject, injectable } from 'tsyringe';
import type { ServiceRepository } from '@/modules/book-visit/domain/service/ServiceRepository';
import type { Service } from '@/modules/book-visit/domain/service/Service';

@injectable()
export class CachedServiceRepository implements ServiceRepository {
  constructor(
    private readonly inner: ServiceRepository,
    @inject(RootCacheToken) private readonly cache: Cache,
  ) {}

  async get(doctorId: string, addressId: string): Promise<Service[]> {
    const cacheKey = this.getCacheKey(doctorId, addressId);
    const cachedValue = this.cache.get<Service[]>(cacheKey);

    if (cachedValue) {
      return cachedValue;
    }

    const services = await this.inner.get(doctorId, addressId);
    this.cache.set(cacheKey, services);
    return services;
  }

  private getCacheKey(...args: string[]): string {
    return args.join('-');
  }
}
