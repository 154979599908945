import type { DpEvent } from '@/core/events/DpEvent';
import type { TaskPayload } from './TaskPayload';

export class TaskUpdatedEvent implements DpEvent {
  static readonly eventName = 'TasksList:TaskUpdated';

  readonly name = TaskUpdatedEvent.eventName;

  constructor(
    readonly id: string,
    readonly updateTriggers: string[],
    readonly taskPayload?: TaskPayload,
  ) {}
}
