import { WorkingHour } from '../WorkingHour';

export class WorkingHourList {
  private constructor(public workingHours: WorkingHour[]) {}

  get hasSiesta(): boolean {
    return this.workingHours.some(workingHour => workingHour.hasSiesta);
  }

  static make(workingHours: WorkingHour[]): WorkingHourList {
    if (workingHours.length !== 7) {
      throw new Error('Invalid length of working hour list');
    }

    return new WorkingHourList(workingHours);
  }
}
