import { CallState } from './CallState';
import type { InputCall } from './dto/InputCall';

export class DeviceCall {
  constructor(readonly call?: InputCall) {}

  get status(): CallState {
    if (!this.call) {
      return CallState.Closed;
    }

    return this.mapState(this.call.status());
  }

  get sid(): string {
    return this.call?.parameters.CallSid ?? '';
  }

  get isActive(): boolean {
    return this.status !== CallState.Closed;
  }

  get isClosed(): boolean {
    return this.status === CallState.Closed;
  }

  get isWaitingCall(): boolean {
    return [CallState.Pending, CallState.Connecting, CallState.Ringing].includes(this.status);
  }

  get isIncoming(): boolean {
    return this.call?.direction === 'INCOMING';
  }

  get isOutgoing(): boolean {
    return this.call?.direction === 'OUTGOING';
  }

  get canAcceptIncomingCall(): boolean {
    return this.isIncoming && this.isWaitingCall;
  }

  get canBounceIncomingCall(): boolean {
    return this.isIncoming && this.isWaitingCall;
  }

  get isInProgress(): boolean {
    return this.status === CallState.Open;
  }

  get canCancelCall(): boolean {
    return this.isInProgress || (this.isOutgoing && this.isWaitingCall);
  }

  get recipient(): string {
    if (!this.call) {
      return '';
    }

    if (this.isIncoming) {
      return this.call.parameters.From ?? '';
    }

    return this.call.customParameters.get('To') ?? '';
  }

  accept(): void {
    this.call?.accept();
  }

  cancel(): void {
    if (this.status === CallState.Pending) {
      this.reject();
      return;
    }

    this.disconnect();
  }

  private reject(): void {
    this.call?.reject();
  }

  private disconnect(): void {
    this.call?.disconnect();
  }

  sendDigits(digits: string): void {
    this.call?.sendDigits(digits);
  }

  addEventListener(name: string, listener: (...args: any[]) => void): void {
    this.call?.addListener(name, listener);
  }

  removeAllListeners(): void {
    this.call?.removeAllListeners();
  }

  static empty(): DeviceCall {
    return new DeviceCall();
  }

  private mapState(name: string): CallState {
    return (
      {
        closed: CallState.Closed,
        connecting: CallState.Connecting,
        open: CallState.Open,
        pending: CallState.Pending,
        reconnecting: CallState.Reconnecting,
        ringing: CallState.Ringing,
      }[name] ?? CallState.Closed
    );
  }
}
