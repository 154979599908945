import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { OutboundTaskStatus } from '../../domain/outbound-task/OutboundTaskStatus';
import type { OutboundTaskRepository } from '../../domain/outbound-task/OutboundTaskRepository';
import type { InputOutboundTaskStatus } from './InputOutboundTaskStatus';

@injectable()
export class HttpOutboundTaskRepository implements OutboundTaskRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async get(): Promise<OutboundTaskStatus> {
    const { enabled }: InputOutboundTaskStatus = await this.httpClient.get(
      '/api/v0/facility/{facilityId}/settings/outbound-tasks',
    );

    return { isEnabledTaskCreation: enabled };
  }

  async update(makeEnable: boolean): Promise<OutboundTaskStatus> {
    const { enabled }: InputOutboundTaskStatus = makeEnable
      ? await this.httpClient.put('/api/v0/facility/{facilityId}/settings/outbound-tasks')
      : await this.httpClient.delete('/api/v0/facility/{facilityId}/settings/outbound-tasks');

    return { isEnabledTaskCreation: enabled };
  }
}
