import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { notificationsManager } from '@/app/notifications/NotificationsManager';
import type { NotificationsManagerInterface } from '@/common/root/domain/NotificationsManager';

export const RootNotificationsManagerToken = Symbol('RootNotificationsManager');

@injectable()
export class RootNotificationsManagerProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<NotificationsManagerInterface>(RootNotificationsManagerToken, {
      useValue: notificationsManager,
    });
  }
}
