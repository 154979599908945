import { StateFetcherToken } from '@/modules/phone-widget/di/tokens';
import { inject, injectable } from 'tsyringe';
import type { CallState } from '../models/CallState';

import type { StateFetcher } from './StateFetcher';

@injectable()
export class CallStateTracker {
  constructor(
    @inject(StateFetcherToken)
    private readonly stateFetcher: StateFetcher,
  ) {}

  get callState(): CallState {
    return this.stateFetcher.callState;
  }

  setCallState(newCallState: CallState): void {
    this.stateFetcher.callState = newCallState;
  }
}
