import type { Store } from 'vuex';
import type { DependencyContainer } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import type { RootState } from '@/store/types';
import type { DpProvider } from '@/core/di/DpProvider';
import { SettingStateToken, SettingStorageToken } from '../tokens';
import { StoreSettingsState } from '../../adapters/settings/StoreSettingsState';
import { LocalSettingsStorage } from '../../adapters/settings/LocalSettingsStorage';
import { moduleName, module } from '../../store/settings';

export class SettingProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register(SettingStateToken, StoreSettingsState);
    container.register(SettingStorageToken, LocalSettingsStorage);
  }

  boot(container: DependencyContainer): void {
    const rootStore = container.resolve<Store<RootState>>(RootStoreToken);

    rootStore.registerModule(moduleName, module);
  }
}
