<template>
  <div :class="$b()">
    <layout-wrapper :class="$b('inner')" :collapsing-header-offset="getHeaderCollapseOffset">
      <template #header>
        <div :class="$b('nav-primary')">
          <slot name="primary-navigation" />
        </div>

        <div v-if="!tablet" :class="$b('nav-secondary')">
          <slot name="secondary-navigation" />
        </div>
      </template>
      <template #main>
        <div :class="$b('container')">
          <slot name="view">
            <router-view />
          </slot>
        </div>
      </template>
    </layout-wrapper>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator';

import { UserAvatar } from '@/ui/atoms';
import { NavbarSimple } from '@/ui/molecules';
import { LayoutWrapper } from '@/ui/templates';

@Component({
  name: 'HomePage',
  components: {
    LayoutWrapper,
    NavbarSimple,
    UserAvatar,
  },
})
export default class HomePage extends Vue {
  get navbarHeight(): string {
    return getComputedStyle(document.body).getPropertyValue('--phone-navbar-simple-height');
  }

  getHeaderCollapseOffset(): string {
    return this.navbarHeight;
  }
}
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use 'src/assets/scss/variables' as v;
@use 'src/assets/scss/mixins' as m;
@use 'src/assets/scss/functions' as f;

$expanded-tile-max-width: f.px-to-rem(1070px);
$container-max-width: calc(
  #{$expanded-tile-max-width} + #{2 * v.$container-max-horizontal-padding}
);
$container-vertical-padding: clamp(v.$spacer-lg, 5vw, v.$spacer * 2.5);

.dp-home-page {
  --nav-primary-height: #{v.$navbar-simple-height};
  --nav-secondary--height: 3rem;

  &__nav-secondary {
    padding-left: v.$container-horizontal-padding;
  }

  &__container {
    @include m.container($container-max-width);

    margin-top: calc(var(--nav-primary-height) + var(--nav-secondary--height));
    padding-top: $container-vertical-padding;
    padding-bottom: $container-vertical-padding;
  }

  @include m.media(md) {
    --nav-primary-height: #{v.$header-height};
    /* stylelint-disable-next-line length-zero-no-unit */
    --nav-secondary--height: 0px; // units to make it work with calc()

    &__nav-secondary {
      padding: 0 0 v.$spacer-md * 2;
      max-width: v.$max-width-call-tile;
      margin: 0 auto;
    }
  }
}
</style>
