import type { BodyPartRule } from './BodyPartRule';
import type { BodyPart } from './BodyPart';
import { LinkValidator } from './LinkValidator';
import { LinkPropertyTransformer } from './LinkPropertyTransformer';

export class BodyPartsFactory {
  private static readonly bodyMatchingRules: BodyPartRule[] = [
    {
      type: 'link',
      regexString: '\\b(?:https?:\\/\\/)?(?:www\\.)?[a-zA-Z0-9-]+\\.[a-zA-Z]{2,}[^\\s]*\\b',
      propertyTransformer: url => LinkPropertyTransformer.transform(url),
      propertyValidator: url => LinkValidator.validate(url),
    },
    {
      type: 'line-break',
      regexString: '\\n',
    },
  ];

  static make(bodyString: string): BodyPart[] {
    const bodyParts: BodyPart[] = [];
    const combinedRegex = this.bodyMatchingRules.map(rule => `(${rule.regexString})`).join('|');
    const stringParts = bodyString.split(new RegExp(combinedRegex, 'g')).filter(Boolean);

    stringParts.forEach(stringPart => {
      bodyParts.push(this.makeBodyPart(stringPart));
    });

    return bodyParts;
  }

  private static makeBodyPart(content: string): BodyPart {
    const rule = this.matchRule(content);
    const property = this.transformToProperty(rule, content);

    if (property && !this.isPropertyValid(rule, property)) {
      return {
        type: 'text',
        content,
      };
    }

    return {
      type: rule.type,
      property,
      content,
    };
  }

  private static matchRule(stringPart: string): BodyPartRule {
    return (
      this.bodyMatchingRules.find(rule => new RegExp(rule.regexString).test(stringPart)) ?? {
        type: 'text',
        regexString: '',
      }
    );
  }

  private static isPropertyValid(rule: BodyPartRule, property: string): boolean {
    return rule.propertyValidator ? rule.propertyValidator(property) : true;
  }

  private static transformToProperty(rule: BodyPartRule, content: string): string | undefined {
    return rule.propertyTransformer ? rule.propertyTransformer(content) : undefined;
  }
}
