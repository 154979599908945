import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import type { WorkstationAssigner } from '../../domain/webphone/services/WorkstationAssigner';

interface Store {
  getters: {
    'settings/reception/isCloudWebPhoneSelected': boolean;
  };
  dispatch(name: 'SIGN_OUT_FROM_WORKSTATION_ACTION'): Promise<void>;
}

@injectable()
export class StoreWorkstationAssigner implements WorkstationAssigner {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
  ) {}

  get isWebphone(): boolean {
    return this.store.getters['settings/reception/isCloudWebPhoneSelected'];
  }

  async singOut(): Promise<void> {
    await this.store.dispatch('SIGN_OUT_FROM_WORKSTATION_ACTION');
  }
}
