import { singleton } from 'tsyringe';

@singleton()
export class EventConsoleLogger {
  private isActive = false;

  get status(): boolean {
    return this.isActive;
  }

  display(content: string): void {
    if (!this.isActive) {
      return;
    }

    window.console.log(`>>>> ${content}`);
  }

  activate(): void {
    this.isActive = true;
  }

  deactivate(): void {
    this.isActive = false;
  }
}
