import type { TaskFeedFetcher } from '@/modules/tasks-list/domain/task-feed/TaskFeedFetcher';
import { TaskFeed } from '@/modules/tasks-list/domain/task-feed/TaskFeed';
import { inject, injectable } from 'tsyringe';
import type { TaskRepository } from '@/modules/tasks-list/domain/task-feed/TaskRepository';
import { OwnershipFilter } from '@/modules/tasks-list/domain/ownership-filter/OwnershipFilter';
import {
  PatientRequestViewTasksFilterToken,
  TaskEntityManagerToken,
  TaskRepositoryToken,
} from '@/modules/tasks-list/di/tokens';
import type { TaskEntityManager } from '@/modules/tasks-list/domain/task-feed/TaskEntityManager';
import type { PatientRequestViewTasksFilter } from '@/modules/tasks-list/domain/task-feed/views/PatientRequestViewTasksFilter';

@injectable()
export class PrescriptionTaskFeedFetcher implements TaskFeedFetcher {
  constructor(
    @inject(TaskRepositoryToken)
    private readonly taskFeedRepository: TaskRepository,
    @inject(TaskEntityManagerToken)
    private readonly taskEntityManager: TaskEntityManager,
    private readonly ownershipFilter: OwnershipFilter,
    @inject(PatientRequestViewTasksFilterToken)
    private readonly patientRequestViewTasksFilter: PatientRequestViewTasksFilter,
  ) {}

  async fetch(): Promise<TaskFeed> {
    const tasks = await this.taskFeedRepository.getInboxPage(
      this.ownershipFilter.getCurrentOption(),
    );

    const prescriptionTasks = this.patientRequestViewTasksFilter.byType(tasks, 'prescription');

    const ids = await this.taskEntityManager.storeEntities(prescriptionTasks);

    return new TaskFeed(ids);
  }
}
