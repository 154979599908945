<template>
  <callable-patient-search-input
    :placeholder-description="$t('phone_widget.search_patients.placeholder')"
    :no-options-message="$t('phone_widget.search_patients.no_results')"
    :is-loading="isLoading"
    :options="patientsAsOptions"
    :theme="searchTheme"
    @search-change="updateSuggestions"
    @search="handleSearch"
    @call="handleCall"
  />
</template>

<script lang="ts">
import { Vue, Component, Inject, Setup } from 'vue-facing-decorator';
import { debounce } from 'lodash-es';

import { CallablePatientSearchInput, PatientSearchTheme } from '@/ui';
import type { PatientSearchItem } from '@/ui/molecules/CallablePatientSearchInput/PatientSearchInput.types';
import type { EventTrackerFacade } from '@/common/tracking-event/public/api';
import { patientSearchSuggestionFactory } from '@/modules/phone-widget/domain/services/patientSearchSuggestionFactory';
import { Patient } from '../../domain/models/Patient';
import { patientRepository } from '../container/repositories';
import { useBusinessEvent, type BusinessEventComposable } from '@/composables/event-tracker';

const SEARCH_TIME_RATE_LIMIT = 200;

@Component({
  name: 'PhoneWidgetPatientSearch',
  components: {
    CallablePatientSearchInput,
  },
})
export default class PhoneWidgetPatientSearch extends Vue {
  @Setup(() => useBusinessEvent)
  sendBusinessEvent: BusinessEventComposable;

  @Inject()
  readonly eventTracker: EventTrackerFacade;

  isLoading = false;

  patients: Patient[] = [];

  get patientsAsOptions(): PatientSearchItem[] {
    return this.patients.map(patientSearchSuggestionFactory);
  }

  get searchTheme(): string {
    return PatientSearchTheme.LightGray;
  }

  handleSearch(item: PatientSearchItem) {
    if (!item.uri) {
      return;
    }

    this.handleCall(item.uri);
  }

  handleCall(to: string) {
    this.makeOutgoingCall(to);
  }

  async makeOutgoingCall(to: string): Promise<void> {
    await this.$store.dispatch('phoneWidget/MAKE_OUTGOING_CALL_ACTION', to);
    this.sendBusinessEvent('Phone Widget - Call from search');
  }

  updateSuggestions(phrase: string): void {
    this.isLoading = true;
    this.updatePatients(phrase);
  }

  readonly updatePatients = debounce(async function updatePatients(phrase) {
    this.patients = await this.fetchPatients(phrase);
    this.isLoading = false;
  }, SEARCH_TIME_RATE_LIMIT);

  async fetchPatients(phrase: string): Promise<Patient[]> {
    if (!phrase) {
      return [];
    }

    const patients = await patientRepository().findByPhrase(phrase);

    return patients;
  }
}
</script>
