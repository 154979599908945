import { inject, injectable } from 'tsyringe';
import type { TagFactory } from '@/modules/task/domain/tag/factories/TagFactory';
import type { Tag } from '@/modules/task/domain/tag/Tag';
import type { Task } from '@/modules/task/domain/task/Task';
import { TimelineRepositoryToken } from '@/modules/task/di/tokens';
import type { TimelineRepository } from '@/modules/task/domain/timeline/TimelineRepository';
import { Call } from '@/modules/task/domain/call/Call';
import { VoiceMessageTag } from '@/modules/task/domain/call/VoiceMessageTag';
import { UnansweredCallCount } from '@/modules/task/domain/timeline/UnansweredCallCount';
import { MissedCallTag } from '@/modules/task/domain/call/MissedCallTag';
import { UnansweredCallTag } from '@/modules/task/domain/call/UnansweredCallTag';

@injectable()
export class CallTagFactory implements TagFactory {
  constructor(
    @inject(TimelineRepositoryToken)
    private readonly timelineRepository: TimelineRepository,
  ) {}

  make(task: Task): Tag[] {
    const { lastUserEvent, usersEvents } = this.timelineRepository.get(task.id);

    if (!(lastUserEvent instanceof Call)) {
      return [];
    }

    if (lastUserEvent.hasVoiceMessage) {
      return [new VoiceMessageTag()];
    }

    const unansweredIncomingCallCount = UnansweredCallCount.ofIncoming(usersEvents);

    if (unansweredIncomingCallCount > 0) {
      return [new MissedCallTag(unansweredIncomingCallCount)];
    }

    const unansweredOutgoingCallCount = UnansweredCallCount.ofOutgoing(usersEvents);

    if (unansweredOutgoingCallCount > 0) {
      return [new UnansweredCallTag(unansweredOutgoingCallCount)];
    }

    return [];
  }
}
