import { singleton } from 'tsyringe';
import type { DpListener } from '@/core/events/DpListener';
import type { ActiveCallChanged } from '../events/ActiveCallChanged';
import { RestartScheduler } from './RestartScheduler';

@singleton()
export class ActiveCallChangedListener implements DpListener {
  constructor(private readonly restartScheduler: RestartScheduler) {}

  handle(event: ActiveCallChanged): void {
    if (!event.call.isClosed) {
      return;
    }

    this.restartScheduler.forceAwaitingReset();
  }
}
