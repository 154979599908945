import { injectable } from 'tsyringe';
import type { ConfirmationCallsDataRepository } from '@/modules/statistics/domain/confirmation-calls/ConfirmationCallsDataRepository';
import type { ConfirmationCallsData } from '@/modules/statistics/domain/confirmation-calls/ConfirmationCallsData';
import type { QueryParams } from '@/modules/statistics/domain/QueryParams';
import { HttpClientApi } from '@/modules/http-client/public/api';

@injectable()
export class HttpConfirmationCallsDataRepository implements ConfirmationCallsDataRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  get(queryParams: QueryParams): Promise<ConfirmationCallsData[]> {
    return this.httpClient.get('api/v0/facility/{facilityId}/stats/bookings', {
      ...queryParams,
    });
  }
}
