import type { TaskRepository } from '@/api/repositories/types/TaskRepository';
import { BaseAxiosRepository } from '@/api/repositories/axios/BaseAxiosRepository';
import type { FacilityId } from '@/api/models/Facility';
import type { TaskId } from '@/api/models/Task';
import { Task } from '@/api/models/Task';
import type { Task as RawTask } from '@/api/types/entities/task';
import type { InfinityScrollParams } from '@/api/types';
import type { TaskResponse } from '@/api/types/response/task';
import type { SearchPayload } from '@/api/types/request/search';
import type { ResultId } from '@/api/types/entities/result';
import type { NotificationPayload } from '@/api/types/response/notification';

interface RawTaskResponse {
  data: RawTask[];
  headers;
}

export class AxiosTaskRepository extends BaseAxiosRepository implements TaskRepository {
  async get(id: TaskId): Promise<Task> {
    const data = (await this.axiosClient.get(`/api/v0/task/${id}`)) as RawTask;

    return Task.make(data);
  }

  async archive(id: TaskId): Promise<Task> {
    const data = (await this.axiosClient.post(`/api/v0/task/${id}/archive`)) as RawTask;

    return Task.make(data);
  }

  async reopen(id: TaskId): Promise<void> {
    await this.axiosClient.post(`/api/v0/task/${id}/reopen`);
  }

  async setResult(id: TaskId, resultId: ResultId): Promise<NotificationPayload> {
    return (await this.axiosClient.post(`/api/v0/task/${id}/result`, {
      value: resultId,
    })) as NotificationPayload;
  }

  async search(
    facilityId: FacilityId,
    filters: SearchPayload,
    queryParams: InfinityScrollParams,
  ): Promise<TaskResponse> {
    const { data, headers } = (await this.axiosClient.postWithFullResponse(
      `/api/v0/facility/${facilityId}/search`,
      filters,
      queryParams as Record<string, any>,
    )) as RawTaskResponse;

    return {
      data: data.map(Task.make),
      headers,
    };
  }
}
