import { injectable } from 'tsyringe';
import { Patient } from '@/modules/phone-widget/domain/models/Patient';
import type { InputPatient } from '../models/data-transfer/InputPatient';

@injectable()
export class PatientFactory {
  make(patient: InputPatient): Patient {
    return new Patient(patient.id, patient.phoneNumber, patient.name, patient.surname);
  }
}
