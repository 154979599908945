import { inject, injectable } from 'tsyringe';
import { CountryCodeProviderToken } from '../../di/token';
import { CountryCodeCurrency } from './CountryCodeCurrency';
import type { CountryCodeProvider } from './CountryCodeProvider';
import { Service } from './Service';

@injectable()
export class ServiceSuffixFactory {
  constructor(
    @inject(CountryCodeProviderToken) private readonly countryCodeProvider: CountryCodeProvider,
  ) {}

  withSuffix(service: Service): Service {
    if (service.price === 0) {
      return service;
    }

    const suffix = this.getSuffix(service.price);
    return new Service(service.id, `${service.name} (${suffix})`, service.addressId, service.price);
  }

  private getSuffix(price: number): string {
    const countryCode = this.countryCodeProvider.get();
    return Intl.NumberFormat(countryCode, {
      style: 'currency',
      currency: CountryCodeCurrency[countryCode],
    }).format(price);
  }
}
