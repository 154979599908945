import type { DependencyContainer } from 'tsyringe';
import { injectable } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { ApiOwnerRepository } from '@/modules/task/adapters/owner/ApiOwnerRepository';
import { StoreTaskReadRepository } from '@/modules/task/adapters/task/StoreTaskReadRepository';
import { ApiTimelineRepository } from '@/modules/task/adapters/timeline/ApiTimelineRepository';
import type { AnonymizationRepository } from '@/modules/task/domain/anonymization/AnonymizationRepository';
import {
  ChannelRepositoryToken,
  AnonymizationRepositoryToken,
  PatientRepositoryToken,
  TaskReadRepositoryToken,
  CommentRepositoryToken,
  TimelineRepositoryToken,
  OwnerRepositoryToken,
  TimezoneRepositoryToken,
  ClockToken,
  OwnershipFilterTypeRepositoryToken,
  TaskWriteRepositoryToken,
} from '@/modules/task/di/tokens';
import { ApiPatientRepository } from '@/modules/task/adapters/patient/ApiPatientRepository';
import type { OwnerRepository } from '@/modules/task/domain/owner/OwnerRepository';
import type { TaskReadRepository } from '@/modules/task/domain/task/TaskReadRepository';
import type { TimelineRepository } from '@/modules/task/domain/timeline/TimelineRepository';
import type { PatientRepository } from '@/modules/task/domain/patient/PatientRepository';
import { StoreChannelRepository } from '@/modules/task/adapters/channel/StoreChannelRepository';
import type { ChannelRepository } from '@/modules/task/domain/channel/ChannelRepository';
import type { CommentRepository } from '@/modules/task/domain/comment/CommentRepository';
import { HttpCommentRepository } from '@/modules/task/adapters/comment/HttpCommentRepository';
import { ApiAnonymizationRepository } from '@/modules/task/adapters/anonymization/ApiAnonymizationRepository';
import type { TimezoneRepository } from '@/modules/task/domain/time/TimezoneRepository';
import { RootTimeZoneRepositoryToken } from '@/common/root';
import { RootClock } from '@/common/root/providers/RootStoreProvider';
import type { Clock } from '@/modules/task/domain/time/Clock';
import type { OwnershipFilterTypeRepository } from '@/modules/task/domain/ownership-filter/OwnershipFilterTypeRepository';
import { TaskListApiOwnershipFilterTypeRepository } from '@/modules/task/adapters/ownership-filter/TaskListApiOwnershipFilterTypeRepository';
import { HttpTaskWriteRepository } from '@/modules/task/adapters/task/HttpTaskWriteRepository';
import type { TaskWriteRepository } from '@/modules/task/domain/task/TaskWriteRepository';

@injectable()
export class RepositoryProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<OwnerRepository>(OwnerRepositoryToken, ApiOwnerRepository);
    container.register<TaskReadRepository>(TaskReadRepositoryToken, StoreTaskReadRepository);
    container.register<TaskWriteRepository>(TaskWriteRepositoryToken, HttpTaskWriteRepository);
    container.register<CommentRepository>(CommentRepositoryToken, HttpCommentRepository);
    container.register<TimelineRepository>(TimelineRepositoryToken, ApiTimelineRepository);
    container.register<PatientRepository>(PatientRepositoryToken, ApiPatientRepository);
    container.register<ChannelRepository>(ChannelRepositoryToken, StoreChannelRepository);
    container.register<TimezoneRepository>(TimezoneRepositoryToken, {
      useFactory(c: DependencyContainer) {
        return c.resolve(RootTimeZoneRepositoryToken);
      },
    });
    container.register<Clock>(ClockToken, {
      useFactory(c: DependencyContainer) {
        return c.resolve(RootClock);
      },
    });
    container.register<AnonymizationRepository>(
      AnonymizationRepositoryToken,
      ApiAnonymizationRepository,
    );
    container.register<OwnershipFilterTypeRepository>(
      OwnershipFilterTypeRepositoryToken,
      TaskListApiOwnershipFilterTypeRepository,
    );
  }
}
