import { schema } from 'normalizr';
import { EntityTypes } from '@/api/types/entities';
import { userSchema } from '@/api/schemas/user-schema';
import { patientSchema } from '@/api/schemas/patient-schema';
import { UpcomingVisit } from '@/api/models/UpcomingVisit';
import { Call } from '@/api/models/Call';
import { contextSchema } from '@/api/schemas/context-schema';
import { resultSchema } from '@/api/schemas/result-schema';
import { formMessageSchema } from '@/api/schemas/form-message-schema';
import { TaskGroup } from '@/api/types/entities/task';
import { channelPhoneSchema } from '@/api/schemas/phone-schema';
import { conversationSchema } from './conversation-schema';

export function isEmptyStateAllowed(groupName: TaskGroup): boolean {
  return groupName !== TaskGroup.All;
}

export const taskSchema = new schema.Entity(
  EntityTypes.Task,
  {
    phone: channelPhoneSchema,
    personnel: userSchema,
    result: resultSchema,
    patient: patientSchema,
    patient_upcoming_visit: UpcomingVisit.schema,
    context: contextSchema,
    form_message: formMessageSchema,
    history: [Call.schema],
    conversation: conversationSchema,
  },
  {
    processStrategy(value) {
      return {
        patientId: value.patient.id,
        result: null,
        personnel: null,
        reminder_at: null,
        ...value,
      };
    },
  },
);
