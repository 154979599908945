import { RootStoreToken } from '@/common/root';
import { HttpClientApi } from '@/modules/http-client/public/api';
import { inject, injectable } from 'tsyringe';
import type { Store } from 'vuex';
import type { TaskUpdater } from '../../domain/task/TaskUpdater';
import type { HttpTask } from '../action/types/HttpTask';
import type { Task } from '../../domain/task/Task';

@injectable()
export class TaskUpdaterAdapter implements TaskUpdater {
  constructor(
    private readonly httpClient: HttpClientApi,
    @inject(RootStoreToken)
    private readonly store: Store<unknown>,
  ) {}

  async archive(taskId: string): Promise<void> {
    const task = await this.httpClient.post<HttpTask>(`/api/v0/task/${taskId}/archive`);
    await this.update(task);
  }

  async reopen(taskId: string): Promise<void> {
    await this.httpClient.post(`/api/v0/task/${taskId}/reopen`);
  }

  async setSubject(taskId: string, subjectId: string): Promise<void> {
    await this.httpClient.post(`/api/v0/task/${taskId}/result`, { value: subjectId });
  }

  async setToDo(task: Task): Promise<void> {
    await this.httpClient.put(`/api/v0/task/${task.id}/comments`, {
      comment: task.comment,
      todo: true,
    });
  }

  private async update(task: HttpTask): Promise<void> {
    await this.store.dispatch('UPDATE_TASK_ACTION', [task]);
  }
}
