import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import apiConfiguration from '@/config/api-configuration.json';
import { RootLocalStorageFactoryToken } from '@/common/root/providers/RootStorageProvider';
import type { StorageFactory } from '@/common/root/domain/storage/services/StorageFactory';
import { ConfigurationRepositoryToken, DomainGetterToken } from '../tokens';
import { StorageKey } from '../../StorageKey';
import { StoredConfigurationRepository } from '../../domain/repositories/StoredConfigurationRepository';
import { DefaultConfigurationFactory } from '../../domain/repositories/DefaultConfigurationFactory';
import type { DomainRepository } from '../../domain/repositories/DomainRepository';
import type { ConfigurationRepository } from '../../domain/repositories/ConfigurationRepository';
import { BrowserDomainRepository } from '../../adapters/BrowserDomainRepository';

export class AdaptersProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<ConfigurationRepository>(ConfigurationRepositoryToken, {
      useFactory: (c: DependencyContainer) =>
        new StoredConfigurationRepository(
          c
            .resolve<StorageFactory>(RootLocalStorageFactoryToken)
            .createStorageItemFactory(StorageKey),
        ),
    });

    container.register<DomainRepository>(DomainGetterToken, {
      useFactory: () => new BrowserDomainRepository(window),
    });

    container.register<DefaultConfigurationFactory>(DefaultConfigurationFactory, {
      useFactory: (c: DependencyContainer) =>
        new DefaultConfigurationFactory(apiConfiguration, c.resolve(DomainGetterToken)),
    });
  }
}
