import { RootStoreToken } from '@/common/root';
import { $FILTER_ENTITIES_BY } from '@dp-vue/entities';
import { inject, injectable } from 'tsyringe';
import { PROCESS_PATIENTS_ACTION } from '@/store/modules/patient';
import type { Patient } from '../domain';
import { PatientError } from '../domain';
import { PatientFactory } from './PatientFactory';
import type { InputPatient } from './input';
import { PhoneNumberDeformatter } from './store-patient-repository/PhoneNumberDeformatter';

interface Store {
  getters: {
    [$FILTER_ENTITIES_BY]: (
      type: 'patient',
      key: 'id' | 'phoneNumber',
      values: string | string[],
    ) => InputPatient[];
  };
  dispatch(name: typeof PROCESS_PATIENTS_ACTION, patients: InputPatient[]): Promise<void>;
}

@injectable()
export class StorePatientRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
    private readonly patientFactory: PatientFactory,
    private readonly phoneNumberDeformatter: PhoneNumberDeformatter,
  ) {}

  get(id: string): Patient {
    const patients = this.store.getters[$FILTER_ENTITIES_BY]('patient', 'id', id);

    this.assert(patients);

    return this.patientFactory.make(patients[0]);
  }

  getByPhoneNumber(phoneNumber: string): Patient {
    const patients = this.store.getters[$FILTER_ENTITIES_BY](
      'patient',
      'phoneNumber',
      this.phoneNumberDeformatter.get(phoneNumber),
    );

    this.assert(patients);

    return this.patientFactory.make(patients[0]);
  }

  async createOrUpdate(patients: Patient[]): Promise<void> {
    this.store.dispatch(PROCESS_PATIENTS_ACTION, patients);
  }

  private assert(patients?: InputPatient[]): asserts patients {
    if (!patients || patients.length === 0) {
      throw new PatientError(
        "There wasn't possible to find patient in store for a given id or phone number.",
      );
    }
  }
}
