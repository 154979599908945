<template>
  <div :class="$b({ theme })" @click="$emit('click')">
    <div :class="$b('icon')">
      <slot name="icon">
        <component :is="icon" v-if="icon" :size="32" />
      </slot>
    </div>
    <div>
      <div :class="$b('title')">
        {{ title }}
      </div>
      <div :class="$b('label')">
        <slot name="label" />
      </div>
      <div :class="$b('description')">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import type BaseIcon from '@dp-ui-kit/icons/vue/BaseIcon.vue';

@Component({
  name: 'TabTile',
  emits: ['click'],
})
export default class TabTile extends Vue {
  @Prop({ type: Object, default: null })
  readonly icon: typeof BaseIcon;

  @Prop({ type: String, required: true })
  readonly title: string;

  @Prop({ type: Boolean, default: false })
  readonly isActive: boolean;

  get theme(): 'default' | 'active' {
    return this.isActive ? 'active' : 'default';
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables' as v;
@use 'sass:map';

$theme: (
  'default': (
    bg-color: v.$white,
    hover-bg-color: v.$gray-100,
    border-color: v.$gray-300,
    title-color: v.$gray-900,
    icon-color: v.$gray-600,
    description-color: v.$gray-600,
  ),
  'active': (
    bg-color: v.$primary-light,
    hover-bg-color: v.$primary-light,
    border-color: v.$primary,
    title-color: v.$primary-dark,
    icon-color: v.$primary,
    description-color: v.$gray-600,
  ),
);

.dp-tab-tile {
  $this: &;

  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  gap: v.$spacer-md;
  border-radius: v.$border-radius-lg;
  padding: v.$spacer-md v.$spacer-md;
  cursor: pointer;
  position: relative;

  @each $theme-name, $theme-map in $theme {
    &--theme_#{$theme-name} {
      border: 1px solid map.get($theme-map, border-color);
      background-color: map.get($theme-map, bg-color);

      &:hover {
        background-color: map.get($theme-map, hover-bg-color);
      }

      #{$this}__title {
        color: map.get($theme-map, title-color);
      }

      #{$this}__description {
        color: map.get($theme-map, description-color);
      }

      #{$this}__icon {
        color: map-get($theme-map, icon-color);
      }
    }
  }

  &__description {
    margin-top: v.$spacer-xs;
    font-size: v.$font-size-sm;
  }

  &__icon :deep(.svg-icon:not([class*='svg-icon-color'])) {
    fill: currentColor;
  }

  &__content {
    position: relative;
  }

  &__label {
    position: absolute;
    top: v.$spacer-sm;
    right: v.$spacer-sm;
  }
}
</style>
