import { injectable } from 'tsyringe';
import { OutboundTaskSwitcherApi } from '@/modules/settings/public/api/OutboundTaskSwitcherApi';
import type { OutboundTaskSwitcherRepository } from '../../domain/outbound-task/OutboundTaskSwitcherRepository';
import type { OutboundTaskStatus } from '../../domain/outbound-task/OutboundTaskStatus';

@injectable()
export class SettingsOutboundTaskSwitcherRepository implements OutboundTaskSwitcherRepository {
  constructor(private readonly outboundTaskSwitcherApi: OutboundTaskSwitcherApi) {}

  async get(): Promise<OutboundTaskStatus> {
    return this.outboundTaskSwitcherApi.get();
  }

  async enable(): Promise<OutboundTaskStatus> {
    return this.outboundTaskSwitcherApi.enable();
  }
}
