import type { VisitRenderEvaluator } from '@/modules/task/domain/visit/VisitRenderEvaluator';
import { BookVisitApi } from '@/modules/book-visit/public/api';
import { injectable } from 'tsyringe';

@injectable()
export class BookVisitModuleVisitRenderEvaluator implements VisitRenderEvaluator {
  constructor(private readonly bookVisitApi: BookVisitApi) {}

  shouldRender(): boolean {
    return this.bookVisitApi.hasSsoIntegration();
  }
}
