import type { OutputPatientCollection } from '@/modules/patient/public/types';
import { Patient } from '@/modules/tasks-list/domain/models/Patient';
import { injectable } from 'tsyringe';
import { phoneNumberFormatter } from '@/utils/phone-number-formatter';

@injectable()
export class PatientFactory {
  makeMany(patients: OutputPatientCollection): Patient[] {
    return patients.patients.map(
      patient =>
        new Patient(
          patient.phoneNumber,
          phoneNumberFormatter(patient.phoneNumber),
          patient.name,
          patient.surname,
          patient.fullName,
          Boolean(patient.priority),
        ),
    );
  }
}
