import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import type { DateFormatter } from '@/modules/user/domain/DateFormatter';
import { ExternalDateFormatter } from '@/modules/user/adapters/ExternalDateFormatter';
import { DateFormatterToken } from '@/modules/user/di/tokens';

export class DateFormatterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<DateFormatter>(DateFormatterToken, ExternalDateFormatter);
  }
}
