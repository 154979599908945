import type { UserSession } from '../pause/UserSession';
import type { ServerTime } from '../time/ServerTime';
import type { UserStatus } from '../user-status/UserStatus';

export class Ping {
  constructor(
    readonly currentTime: ServerTime,
    readonly statuses: Record<string, UserStatus>,
    readonly userSession?: UserSession,
  ) {}
}
