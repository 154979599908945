import type { ErrorLogger } from '../ErrorLogger';
import type { EventTracker } from '../EventTracker';
import { Measurement } from './Measurement';

export class TaskProcessingPerformanceTracker {
  private readonly publishedEventName = 'Task update';

  // Disabled as we exceed mixpanel quota.
  private readonly disabled = true;

  private measurements = new Map<string, Measurement>();

  constructor(
    private readonly flow: 'api' | 'websocket',
    private readonly eventTracker: EventTracker,
    private readonly errorLogger: ErrorLogger,
  ) {}

  start(taskId: string): void {
    const measurement = this.measurements.get(taskId);

    if (measurement) {
      measurement.increaseRepetition();
      return;
    }

    this.measurements.set(taskId, new Measurement(performance.now()));
  }

  endQueueTime(taskId: string): void {
    const measurement = this.measurements.get(taskId);

    if (!measurement) {
      this.errorLogger.logExceptionWithContext(
        new Error("You're trying to add queue time for non existing measurement"),
        {
          task: {
            id: taskId,
          },
          pusher: {
            type: this.flow,
          },
        },
        { module: 'real-time-communication' },
      );
      return;
    }

    measurement.queueEnd = performance.now();
  }

  stop(taskId: string): void {
    const measurement = this.measurements.get(taskId);

    if (!measurement) {
      this.errorLogger.logExceptionWithContext(
        new Error("You're trying to end time for non existing measurement"),
        {
          task: {
            id: taskId,
          },
          pusher: {
            type: this.flow,
          },
        },
        { module: 'real-time-communication' },
      );
      return;
    }

    const now = performance.now();
    const duration = now - measurement.startTime;
    const queueTime = measurement.queueEnd ? measurement.queueEnd - measurement.startTime : null;
    const webTime = measurement.queueEnd ? now - measurement.queueEnd : null;

    const extraProperties = {
      type: this.flow,
      task_id: taskId,
      numberOfRepetitions: measurement.numberOfRepetitions,
      numberOfEventsToProcess: this.measurements.size,
      queueTime,
      webTime,
    };

    if (this.shouldSendEvent(taskId, measurement)) {
      this.sendEvent(duration, extraProperties);
    }
    this.measurements.delete(taskId);
  }

  private sendEvent(
    duration: number,
    extraProperties: Record<string, string | number | boolean | null>,
  ): void {
    this.eventTracker.publishCustomPerformance(this.publishedEventName, duration, extraProperties);
  }

  private shouldSendEvent(taskId: string, measurement: Measurement): boolean {
    if (window.location.hostname === 'localhost') {
      return true;
    }

    if (this.disabled) {
      return false;
    }

    // Random sample
    if (this.isValidTaskId(taskId)) {
      return true;
    }

    const hasBacklogOfEvents = this.measurements.size > 10;

    // Browser can't keep up
    if (hasBacklogOfEvents) {
      return true;
    }

    // Had congestion of events during measurement
    return measurement.numberOfRepetitions > 10;
  }

  private isValidTaskId(id: string) {
    const taskHash = parseInt(id.substring(0, 2), 16);
    return taskHash < 4;
  }
}
