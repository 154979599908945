import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { injectable } from 'tsyringe';
import type { AuthRepository } from '@/modules/authentication/domain/repositories/AuthRepository';
import { HttpAuthRepository } from '@/modules/authentication/adapters/HttpAuthRepository';
import { AuthRepositoryToken } from '@/modules/authentication/di/token';

@injectable()
export class RepositoryProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<AuthRepository>(AuthRepositoryToken, HttpAuthRepository);
  }
}
