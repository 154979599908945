import type { VisitConfirmationStatus } from './VisitConfirmationStatus';

export class VisitConfirmation {
  constructor(
    readonly status: VisitConfirmationStatus,
    readonly doctorName: string,
    readonly serviceName: string,
    readonly patientDetailUrl: string,
    readonly startAt: string,
  ) {}

  get isClosedSuccessfully(): boolean {
    return this.isConfirmed || this.isCanceled || this.isRescheduled;
  }

  get isNew(): boolean {
    return this.status === 'new';
  }

  get isConfirmed(): boolean {
    return this.status === 'confirmed';
  }

  get isCanceled(): boolean {
    return this.status === 'canceled';
  }

  get isRescheduled(): boolean {
    return this.status === 'rescheduled';
  }
}
