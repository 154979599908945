interface MediaInput {
  id?: string;
  name?: string;
  url: string;
  isDirty?: boolean;
}

interface DirtyMediaInput {
  url: string;
}

export class Media {
  id: string;

  name: string;

  url: string;

  isDirty: boolean;

  private constructor(data: MediaInput) {
    this.id = data.id ?? '';
    this.name = data.name ?? '';
    this.url = data.url;
    this.isDirty = data.isDirty ?? false;
  }

  static make(data: MediaInput): Media {
    return new Media(data);
  }

  static tryMake(data?: MediaInput | null): Media | null {
    return data ? new Media(data) : null;
  }

  static makeDirty(data: DirtyMediaInput): Media {
    return new Media({ ...data, isDirty: true });
  }
}
