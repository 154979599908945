import type { Queue } from './Queue';
import type { QueueHandler } from './QueueHandler';
import type { QueueRunner } from './QueueRunner';

/**
 * {@link QueueRunner} implementation that processes all items from the queue.
 * Main purpose is to process at once all data received from pusher.
 */
export class AllQueueRunner implements QueueRunner {
  private isActive = false;

  constructor(private readonly allHandler: QueueHandler) {}

  async run(queue: Queue): Promise<void> {
    if (this.isActive) {
      throw new Error("You shouldn't run the queue while it is already running");
    }

    this.isActive = true;

    const items = queue.dequeueAll();

    if (items.length === 0) {
      this.isActive = false;
      return;
    }

    await this.allHandler(items);

    this.isActive = false;
  }
}
