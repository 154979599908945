export class CustomPropertiesReader {
  private computedStyles = getComputedStyle(document.body);

  get(name: string): string {
    const value =
      this.computedStyles.getPropertyValue(`--brand-${name}`) ||
      this.computedStyles.getPropertyValue(`--${name}`);

    if (!value) {
      throw new Error(`There is no custom property with name ${name}.`);
    }

    return value;
  }
}
