import { UserApi } from '@/modules/user/public/api/UserApi';
import { delay, inject, injectable } from 'tsyringe';
import { User } from '../../domain/models/User';
import type { UserRepository } from '../../domain/repositories/UserRepository';

@injectable()
export class UserApiUserRepository implements UserRepository {
  constructor(
    @inject(delay(() => UserApi))
    private readonly userApi: UserApi,
  ) {}

  getCurrentUser(): User {
    const currentUser = this.userApi.getCurrentUser();

    return new User(currentUser.id, currentUser.name);
  }

  find(id?: string): User | null {
    if (!id) {
      return null;
    }

    const foundUser = this.userApi.getById(id);

    if (!foundUser.id) {
      return null;
    }

    return new User(foundUser.id, foundUser.name);
  }
}
