<template>
  <a :href="src" :download="fileName" :class="$b({ theme })">
    <dp-icon-file-pdf color="gray-600" :size="32" :class="$b('file-icon')" />

    <div :class="$b('file-name')">
      {{ fileName }}
    </div>

    <dp-icon-download color="gray-600" :size="24" :class="$b('download-icon')" />
  </a>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { DpIconDownload, DpIconFilePdf } from '@dp-ui-kit/icons';

import Overlay from '@/ui/molecules/Overlay/Overlay.vue';
import { PdfPreviewTheme } from '@/ui/molecules/PdfPreview/PdfPreview.types';

@Component({
  name: 'PdfPreview',
  components: { Overlay, DpIconDownload, DpIconFilePdf },
})
export default class PdfPreview extends Vue {
  @Prop({ type: String, required: true })
  readonly src: string;

  @Prop({
    type: String,
    required: true,
    validator: (value: PdfPreviewTheme) => Object.values(PdfPreviewTheme).includes(value),
  })
  readonly theme: PdfPreviewTheme;

  get fileName(): string {
    const { pathname } = new URL(this.src);

    const filename = pathname.split('/').pop();
    return filename ? decodeURIComponent(filename) : '';
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables' as v;
@use 'src/assets/scss/mixins' as m;
@use 'sass:color';

.dp-pdf-preview {
  flex: 1;
  display: flex;
  align-items: center;
  border-radius: v.$border-radius;
  padding: v.$spacer-md;
  cursor: pointer;

  &--theme_gray {
    background: color.adjust(v.$gray-200, $lightness: -5%);
  }

  &--theme_primary {
    background: color.adjust(v.$primary-light, $lightness: -5%);
  }

  &__file-icon {
    margin-right: v.$spacer-sm;
  }

  &__file-name {
    flex: 1;

    @include m.truncate;
  }

  &__download-icon {
    display: flex;
    align-items: center;
    margin-left: v.$spacer-sm;
  }
}
</style>
