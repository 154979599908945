import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { CallRepository } from '@/modules/timeline/domain/call/CallRepository';

@injectable()
export class HttpCallRepository implements CallRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  markAsUnanswered(id: string): Promise<void> {
    return this.httpClient.post(`/api/v0/call/${id}/mark-as-unanswered`);
  }
}
