import type { Personnel } from '@/modules/phone-widget/connection/Personnel';

export class Workstation {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly personnel: Personnel | null = null,
    public readonly internalNumber: string | null = null,
  ) {}

  get displayName(): string {
    return this.personnel?.name || this.name;
  }
}
