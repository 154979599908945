import { injectable } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { Authorization } from '@/common/authorization/public/api';

@injectable()
export class AuthorizationProvider implements DpProvider {
  constructor(private readonly authorization: Authorization) {}

  boot(): void {
    this.authorization.addPolicies([
      //
    ]);
  }
}
