import { inject, injectable } from 'tsyringe';
import type { DateUtils } from '@/modules/tasks-list/domain/task-expiration/DateUtils';
import type { DateConverter } from '@/modules/tasks-list/domain/task-expiration/DateConverter';
import { DateConverterToken, DateUtilsToken } from '../../di/tokens';

@injectable()
export class TaskExpirationDateEvaluator {
  constructor(
    @inject(DateUtilsToken) private readonly dateUtils: DateUtils,
    @inject(DateConverterToken) private readonly dateConverter: DateConverter,
  ) {}

  isExpiringToday(date?: string): boolean {
    if (!date) {
      return false;
    }

    return this.dateUtils.isToday(this.parseDate(date));
  }

  private parseDate(date: string): Date {
    return this.dateConverter.getDateFromIso(date);
  }
}
