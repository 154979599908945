import { injectable } from 'tsyringe';
import { DpEventBus } from '@/core/events/DpEventBus';
import { FacilityLoadedEvent } from './events/FacilityLoadedEvent';
import { FacilityUnloadedEvent } from './events/FacilityUnloadedEvent';

@injectable()
export class FacilityPublisher {
  constructor(private readonly eventBus: DpEventBus) {}

  publishFacilityLoadedEvent(): void {
    this.eventBus.publish(new FacilityLoadedEvent());
  }

  publishFacilityUnloadedEvent(): void {
    this.eventBus.publish(new FacilityUnloadedEvent());
  }
}
