import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { BookingRepository } from '@/modules/book-visit/domain/booking/BookingRepository';
import type { BookingRequest } from '@/modules/book-visit/domain/booking/BookingRequest';
import { HttpOutputBookingRequestFactory } from './HttpOutputBookingRequestFactory';

@injectable()
export class HttpBookingRepository implements BookingRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly outputBookingRequestFactory: HttpOutputBookingRequestFactory,
  ) {}

  async save(bookingRequest: BookingRequest): Promise<void> {
    return this.httpClient.post(
      `/api/v0/facility/{facilityId}/booking/book-slot`,
      this.outputBookingRequestFactory.make(bookingRequest),
    );
  }
}
