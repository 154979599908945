import { WebphoneApi } from '@/modules/phone-widget/public/api/WebphoneApi';
import { delay, inject, injectable } from 'tsyringe';
import type { Webphone } from '../../domain/action/Webphone';

@injectable()
export class PhoneWidgetWebphone implements Webphone {
  constructor(
    @inject(delay(() => WebphoneApi))
    private readonly webphoneApi: WebphoneApi,
  ) {}

  async call(to: string): Promise<void> {
    await this.webphoneApi.call(to);
  }
}
