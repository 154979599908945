<template>
  <div v-if="overlay">
    <slot />
    <div v-if="loading" class="spinner-wrapper bg-gray-100">
      <dp-spinner :color="compatibleColor" :size="size" :class="extraClass" />
    </div>
  </div>
  <div v-else>
    <dp-spinner v-if="loading" :color="compatibleColor" :size="size" :class="extraClass" />
    <slot v-else />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { DpSpinner } from '@dp-ui-kit/vue';

@Component({
  name: 'DpLoading',
  components: {
    DpSpinner,
  },
})
export default class DpLoading extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly loading: boolean;

  @Prop({ type: Boolean, default: false })
  readonly overlay: boolean;

  @Prop({ type: String, default: 'primary' })
  readonly color: 'primary' | 'secondary' | 'white' | 'gray-300';

  @Prop({ type: String, default: 'md' })
  readonly size: 'sm' | 'md' | 'lg';

  // color `primary` is not supported by default by ui kit.
  get compatibleColor(): 'secondary' | 'white' | 'gray-300' {
    if (this.color === 'primary') {
      return 'white';
    }

    return this.color;
  }

  get extraClass(): string {
    if (this.color === 'primary') {
      return 'spinner-brand-primary m-auto';
    }

    return 'm-auto';
  }
}
</script>
