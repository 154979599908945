import { injectable } from 'tsyringe';
import { SubscriptionApi } from '@/common/authorization/public/api/SubscriptionApi';
import type { SubscriptionPlanRepository } from '../../domain/tracking/repositories/SubscriptionPlanRepository';

@injectable()
export class AuthSubscriptionPlanRepository implements SubscriptionPlanRepository {
  constructor(private readonly subscriptionApi: SubscriptionApi) {}

  async getPlan(): Promise<string | null> {
    return this.subscriptionApi.get();
  }
}
