<template>
  <div :class="$b()">
    <div :class="$b('file-uploader-wrapper')">
      <file-uploader
        v-model="fileUrl"
        :class="$b('file-uploader', { error: hasError })"
        :accept-types="['audio/mpeg']"
        :validation="validation"
        @error="onError"
      />
      <div v-if="hasError" :class="$b('error')">
        {{ errorMessage }}
      </div>
    </div>

    <audio-player :key="fileUrl" :src="fileUrl" :class="$b('player')" />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Model, Prop } from 'vue-facing-decorator';

import type { Validation } from '@/ui/types';
import { AudioPlayer, FileUploader } from '../../../molecules';

@Component({
  name: 'VoiceMessageUploader',

  components: {
    FileUploader,
    AudioPlayer,
  },
})
export default class VoiceMessageUploader extends Vue {
  @Model({ type: String, default: null })
  fileUrl: string;

  @Prop({ type: Object, default: null })
  readonly validation: Validation;

  get hasError(): boolean {
    return this.validation?.type === 'invalid';
  }

  get errorMessage(): string {
    return this.validation?.messages[0] ?? '';
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-voice-message-uploader {
  display: flex;

  &__file-uploader {
    width: 100%;
    border: 1px dashed v.$gray-300;
    border-radius: 0.25rem;

    &--error {
      border-color: v.$danger;
    }
  }

  &__file-uploader-wrapper {
    flex-grow: 1;
    width: 100%;
    margin-right: 0.5rem;
  }

  &__error {
    color: v.$danger;
    margin-top: 0.5rem;
  }

  &__player {
    margin-top: v.$spacer-xs;
    align-self: self-start;
  }
}
</style>
