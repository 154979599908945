import type { DpListener } from '@/core/events/DpListener';
import { injectable } from 'tsyringe';
import { ClientManager } from '../ClientManager';

@injectable()
export class StartClientListener implements DpListener {
  constructor(private readonly clientManager: ClientManager) {}

  handle(): void {
    this.clientManager.create();
  }
}
