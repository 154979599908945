import { injectable } from 'tsyringe';
import { DeviceCall } from '../models/DeviceCall';
import type { InputCall } from '../models/dto/InputCall';

@injectable()
export class DeviceCallFactory {
  make(call: InputCall): DeviceCall {
    return new DeviceCall(call);
  }
}
