import { injectable } from 'tsyringe';
import type { TimelineRepository } from '@/modules/timeline/domain/timeline/TimelineRepository';
import type { Timeline } from '@/modules/timeline/domain/timeline/Timeline';
import { HttpTimelineRepository } from './http/HttpTimelineRepository';
import { StoreTimelineRepository } from './store/StoreTimelineRepository';

@injectable()
export class CombinedTimelineRepository implements TimelineRepository {
  constructor(
    private readonly httpTimelineRepository: HttpTimelineRepository,
    private readonly storeTimelineRepository: StoreTimelineRepository,
  ) {}

  async find(taskId: string): Promise<Timeline | null> {
    const storeTimeline = await this.storeTimelineRepository.find(taskId);

    if (storeTimeline) {
      return storeTimeline;
    }

    return this.httpTimelineRepository.find(taskId);
  }

  subscribeUpdates(taskId: string, callback: (timeline: Timeline) => void): () => void {
    return this.storeTimelineRepository.subscribeUpdates(taskId, callback);
  }

  findSync(taskId: string): Timeline | null {
    return this.storeTimelineRepository.findSync(taskId);
  }
}
