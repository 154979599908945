import { BaseAxiosRepository } from '@/api/repositories/axios/BaseAxiosRepository';
import type { UserRepository } from '@/api/repositories/types/UserRepository';
import type { UserCreationPayload } from '@/api/types/request/user';
import { User } from '@/api/models/User';
import type { User as RawUser } from '@/api/types/entities/user';
import type { FacilityId } from '@/api/models/Facility';

export class AxiosUserRepository extends BaseAxiosRepository implements UserRepository {
  async createUser(facilityId: FacilityId, payload: UserCreationPayload): Promise<User> {
    const data = (await this.axiosClient.put(
      `/api/v0/facility/${facilityId}/create-user`,
      payload,
    )) as RawUser;

    return User.make(data);
  }
}
