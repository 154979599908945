import { AnonymizationApi } from '@/modules/anonymization/public/api/AnonymizationApi';
import { injectable } from 'tsyringe';
import type { AnonymizationManager } from '../../domain/data-anonymization/AnonymizationManager';
import type { AnonymizationStatus } from '../../domain/data-anonymization/AnonymizationStatus';

@injectable()
export class AnonymizationAnonymizationManager implements AnonymizationManager {
  constructor(private readonly anonymizationApi: AnonymizationApi) {}

  async get(): Promise<AnonymizationStatus> {
    return this.anonymizationApi.get();
  }

  async makeDataPublic(): Promise<AnonymizationStatus> {
    return this.anonymizationApi.makeDataPublic();
  }

  async makeDataPrivate(): Promise<AnonymizationStatus> {
    return this.anonymizationApi.makeDataPrivate();
  }
}
