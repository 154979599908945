import type { DeviceErrorRepository } from '@/modules/phone-widget/domain/webphone/errors/DeviceErrorRepository';
import type { DeviceError } from '@/modules/phone-widget/domain/webphone/models/DeviceError';
import { webphoneStoreName } from '@/modules/phone-widget/ui/store/webphone';
import { injectable } from 'tsyringe';
import type { StoreAdapter } from '../../StoreAdapter';
import { StoreAdapterFactory } from '../../StoreAdapterFactory';

@injectable()
export class StoreDeviceErrorRepository implements DeviceErrorRepository {
  private storeAdapter: StoreAdapter;

  constructor(storeAdapterFactory: StoreAdapterFactory) {
    this.storeAdapter = storeAdapterFactory.make(webphoneStoreName);
  }

  all(): DeviceError[] {
    return this.storeAdapter.getter('getDeviceErrors');
  }

  clear(): void {
    this.storeAdapter.commit('CLEAR_DEVICE_ERROR');
  }

  add(deviceError: DeviceError): void {
    this.storeAdapter.commit('ADD_DEVICE_ERROR', deviceError);
  }
}
