function sanitize(apiData) {
  return Object.entries(apiData).reduce((acc, [key, data]) => {
    if (!key.startsWith('@')) {
      acc.push({ ...data });
    }
    return acc;
  }, []);
}

export function handleApiResponse(data) {
  if (data['@type'] !== 'hydra:Collection') {
    return sanitize(data);
  }

  return {
    totalItems: data['hydra:totalItems'],
    items: data['hydra:member'],
  };
}
