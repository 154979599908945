import { injectable } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { DpEventBus } from '@/core/events/DpEventBus';
import { PhoneWidgetEvent } from '@/modules/phone-widget/events';
import { HideHubspotChatListener } from '../../domain/hubspot/HideHubspotChatListener';
import { ShowHubspotChatListener } from '../../domain/hubspot/ShowHubspotChatListener';

@injectable()
export class EventProvider implements DpProvider {
  constructor(
    private readonly eventBus: DpEventBus,
    private readonly hideHubspotChat: HideHubspotChatListener,
    private readonly showHubspotChat: ShowHubspotChatListener,
  ) {}

  boot(): void {
    this.eventBus.subscribe(PhoneWidgetEvent.PhoneWidgetStartCalling, this.hideHubspotChat);
    this.eventBus.subscribe(PhoneWidgetEvent.PhoneWidgetStopCalling, this.showHubspotChat);
    this.eventBus.subscribe(PhoneWidgetEvent.PhoneWidgetDisconnect, this.showHubspotChat);
  }
}
