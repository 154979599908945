import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import { UserStatus } from '../../domain/models/UserStatus';
import type { UserStatusRepository } from '../../domain/repositories/UserStatusRepository';

interface Store {
  getters: {
    getUserStatus(userId): { status: 'online' | 'offline' | 'break' } | undefined;
  };
}

@injectable()
export class StoreUserStatusRepository implements UserStatusRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
  ) {}

  get(userId: string): UserStatus {
    const data = this.store.getters.getUserStatus(userId);

    return new UserStatus(this.mapStatus(data?.status ?? 'offline'));
  }

  private mapStatus(status: 'online' | 'offline' | 'break'): 'online' | 'pause' | 'offline' {
    return (
      {
        online: 'online',
        offline: 'offline',
        break: 'pause',
      } as const
    )[status];
  }
}
