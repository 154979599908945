import type { DpListener } from '@/core/events/DpListener';
import { injectable } from 'tsyringe';
import { PingManager } from './PingManager';

@injectable()
export class WorkstationChangedListener implements DpListener {
  constructor(private readonly pingManager: PingManager) {}

  handle(): void {
    this.pingManager.start();
  }
}
