import { UserApi } from '@/modules/user/public/api/UserApi';
import { PublicWorkstationRepository } from '@/modules/workstation/public/api/PublicWorkstationRepository';
import { injectable } from 'tsyringe';
import { User } from '../../domain/User';
import type { UserRepository } from '../../domain/UserRepository';

@injectable()
export class UserApiUserRepository implements UserRepository {
  constructor(
    private readonly userApi: UserApi,
    private readonly workstationApi: PublicWorkstationRepository,
  ) {}

  getMe(): User {
    const user = this.userApi.getCurrentUser();
    const userWorkstation = this.workstationApi
      .all()
      .find(workstation => workstation.currentUser?.id === user.id);
    const workstationId = userWorkstation?.id ?? null;

    return new User(user.id, workstationId);
  }
}
