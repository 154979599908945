import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { ReminderWriteRepository } from '@/modules/task/domain/reminder/ReminderWriteRepository';
import type { Reminder } from '@/modules/task/domain/reminder/Reminder';

@injectable()
export class HttpReminderWriteRepository implements ReminderWriteRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async update(reminder: Reminder): Promise<void> {
    await this.httpClient.post(`/api/v0/task/${reminder.taskId}/reminder`, {
      reminderAt: this.getApiDate(reminder.timestamp),
    });
  }

  async delete(reminder: Reminder): Promise<void> {
    await this.httpClient.delete(`/api/v0/task/${reminder.taskId}/reminder`);
  }

  private getApiDate(timestamp) {
    return `@${timestamp}`;
  }
}
