import { injectable } from 'tsyringe';
import type { Service } from './Service';
import { ServiceSuffixFactory } from './ServiceSuffixFactory';

type Counts = Record<string, number>;

@injectable()
export class UniqueServiceCollectionFactory {
  constructor(private readonly serviceSuffixFactory: ServiceSuffixFactory) {}

  get(services: Service[]): Service[] {
    const duplicates = services.reduce(
      (counts: Counts, service: Service) => ({
        ...counts,
        [service.name]: (counts[service.name] ?? 0) + 1,
      }),
      {},
    );

    const isDuplicated = (service: Service) => duplicates[service.name] > 1;

    return services.map((service: Service) =>
      isDuplicated(service) ? this.serviceSuffixFactory.withSuffix(service) : service,
    );
  }
}
