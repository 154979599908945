import { injectable } from 'tsyringe';
import { OwnerIdFactory } from '@/modules/task/adapters/owner/OwnerIdFactory';
import { Workstation } from '../../domain/workstation/Workstation';
import type { StoreWorkstation } from './types/StoreWorkstation';

@injectable()
export class WorkstationFactory {
  constructor(private readonly ownerIdFactory: OwnerIdFactory) {}

  tryMake(workstation?: StoreWorkstation): Workstation {
    if (!workstation) {
      return this.makeEmpty();
    }

    return this.make(workstation);
  }

  make(workstation: StoreWorkstation): Workstation {
    return new Workstation(
      workstation.id,
      workstation.type,
      workstation.name,
      this.ownerIdFactory.tryMake(workstation.current_user),
    );
  }

  makeEmpty(): Workstation {
    return new Workstation('', 'never', '');
  }
}
