import { injectable } from 'tsyringe';
import type { Timeline } from '@/modules/task/domain/timeline/Timeline';
import type { IconStrategy } from '../types';
import type { Task } from '../../task/Task';
import { IconName } from '../types';

@injectable()
export class OutgoingWaiting implements IconStrategy {
  get(task: Task, { lastCall }: Timeline): null | IconName {
    return lastCall.isWaiting && lastCall.isOutgoing ? IconName.OutgoingWaiting : null;
  }
}
