import type { DpListener } from '@/core/events/DpListener';
import { injectable } from 'tsyringe';
import { EnvironmentAwareSender } from '../EnvironmentAwareSender';
import type { NavbarVisibilityChangeEvent } from '../types/NavbarVisibilityChangeEvent';
import { NavbarVisibilityChangeEventConverter } from './factories/NavbarVisibilityChangeEventConverter';

@injectable()
export class NavbarVisibilityChangeListener implements DpListener {
  constructor(
    private readonly sender: EnvironmentAwareSender,
    private readonly navbarVisibilityChangeEventConverter: NavbarVisibilityChangeEventConverter,
  ) {}

  handle(event: NavbarVisibilityChangeEvent): void {
    this.sender.trackEvent(this.navbarVisibilityChangeEventConverter.make(event.state));
  }
}
