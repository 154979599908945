import {
  initialRestartFrequency,
  maxRestartFrequency,
} from '@/modules/phone-widget/domain/webphone/restart/config';

export class RestartTimer {
  private period = initialRestartFrequency;

  private startedAt = Date.now();

  isRunning(): boolean {
    return this.getTimePassed() < this.period;
  }

  start(): void {
    this.startedAt = Date.now();
  }

  getTimeLeft(): number {
    const timeLeft = this.period - this.getTimePassed();

    return timeLeft > 0 ? timeLeft : this.period;
  }

  resetPeriod(): void {
    this.period = initialRestartFrequency;
  }

  increasePeriod(): void {
    this.period = Math.min(this.period * 2, maxRestartFrequency);
  }

  private getTimePassed(): number {
    return Date.now() - this.startedAt;
  }
}
