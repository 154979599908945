import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { WaitingMusicRepository } from '../../domain/waiting-music/WaitingMusicRepository';
import type { WaitingMusic } from '../../domain/waiting-music/WaitingMusic';
import type { WaitingMusicUrlInput } from '../../domain/waiting-music/WaitingMusicUrl';
import { WaitingMusicUrl } from '../../domain/waiting-music/WaitingMusicUrl';
import type { WaitingMusicFactoryInput } from '../factories/WaitingMusicFactory';
import { WaitingMusicFactory } from '../factories/WaitingMusicFactory';

@injectable()
export class HttpWaitingMusicRepository implements WaitingMusicRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async all(): Promise<WaitingMusic[]> {
    const data: WaitingMusicFactoryInput = await this.httpClient.get('/api/v0/waiting-music');

    return WaitingMusicFactory.makeMany(data);
  }

  async get(): Promise<WaitingMusicUrl> {
    const data: WaitingMusicUrlInput = await this.httpClient.get(
      `/api/v0/facility/{facilityId}/reception/waiting-music`,
    );

    return WaitingMusicUrl.make(data);
  }

  async set(waitingMusic: string): Promise<WaitingMusicUrl> {
    const data: WaitingMusicUrlInput = await this.httpClient.put(
      `/api/v0/facility/{facilityId}/reception/waiting-music`,
      { waitingMusic },
    );

    return WaitingMusicUrl.make(data);
  }
}
