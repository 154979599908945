import { taskMapper } from '@/api/mappers';
import type { Task } from '@/api/models/Task';
import { injectable } from 'tsyringe';
import type { TaskNormalizer } from '../../domain/real-time-updates/TaskNormalizer';

@injectable()
export class ApiTaskNormalizer implements TaskNormalizer {
  private readonly taskMapper = taskMapper;

  make(task: Task): { entities: unknown; result: unknown } {
    // TODO verify if some clean up is necessary for this old types
    return this.taskMapper.normalizeSingle(task);
  }

  makeMany(tasks: Task[]): { entities: unknown; result: unknown } {
    return this.taskMapper.normalizeMany(tasks);
  }
}
