<template>
  <form-field-wrapper :class="$b()" :icon="icon" :label="label">
    <resizable-text-area
      ref="input"
      v-model="value"
      v-bind="attrs"
      :label="label"
      :max-rows="10"
      hide-label
    />
  </form-field-wrapper>
</template>

<script lang="ts">
import type { Component as VueComponent } from 'vue';

import { Vue, Component, Prop, Ref, Model } from 'vue-facing-decorator';

import { FormFieldWrapper, ResizableTextArea } from '@/ui/atoms';

@Component({
  name: 'CustomFormTextArea',
  emits: ['input', 'open'],
  components: {
    FormFieldWrapper,
    ResizableTextArea,
  },
})
export default class CustomFormTextArea extends Vue {
  @Model({ type: String, default: '' })
  value: string;

  @Prop({ type: Object, default: null })
  readonly icon: VueComponent | null;

  @Prop({ type: String, required: true })
  readonly label: string;

  @Ref()
  readonly input: { focus: () => void };

  get attrs() {
    const attrs = { ...this.$attrs };

    delete attrs.class;

    return attrs;
  }

  focus(): void {
    this.input.focus();
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/mixins' as m;
@use '@/assets/scss/variables' as v;

:deep(.dp-resizable-text-area__input) {
  margin: 0;
  padding: 0;

  textarea {
    @include m.custom-input;
  }
}
</style>
