<template>
  <div :class="$b({ color })">
    <div :class="$b('status')">
      <component :is="icon" v-if="icon" :class="$b('icon')" :size="24" />
      {{ name }}
      <span v-if="phoneNumber" :class="$b('phone-number')">
        <dp-badge type="gray-200">{{ phoneNumber }}</dp-badge>
      </span>
    </div>

    <div :class="$b('date')">
      <date :date="date" />
      <span v-if="callDuration">({{ callDuration }})</span>
    </div>

    <div :class="$b('audio')">
      <audio-player v-if="recordingUrl" :src="recordingUrl" can-change-speed />
    </div>

    <div :class="$b('actions')">
      <slot name="actions" />
    </div>
  </div>
</template>
<script lang="ts">
import type { Component as VueComponent } from 'vue';

import { Component, Prop, Vue } from 'vue-facing-decorator';
import { DpBadge } from '@dp-ui-kit/vue';

import { isIncludedIn } from '@/utils/functions';
import AudioPlayer from '@/ui/molecules/AudioPlayer/AudioPlayer.vue';
import Date from '@/ui/atoms/Date/Date.vue';
import type { DateInterface } from '@/ui/atoms/Date/Date.types';
import { TaskCallColor } from './TaskCallCard.types';

@Component({
  name: 'TaskCallCard',
  components: {
    AudioPlayer,
    Date,
    DpBadge,
  },
})
export default class TaskCallCard extends Vue {
  @Prop({
    type: Array,
  })
  readonly actions: VueComponent[];

  @Prop({
    type: String,
    default: '',
  })
  readonly callDuration: string;

  @Prop({
    type: String,
    default: TaskCallColor.Default,
    validator: isIncludedIn(Object.values(TaskCallColor)),
  })
  readonly color: string;

  @Prop({
    type: Object,
    required: true,
  })
  readonly date: DateInterface;

  @Prop({
    type: Object,
    required: true,
  })
  readonly icon: VueComponent;

  @Prop({
    type: String,
    required: true,
  })
  readonly name: string;

  @Prop({
    type: String,
    default: '',
  })
  readonly phoneNumber: string;

  @Prop({
    type: String,
    default: '',
  })
  readonly recordingUrl: string;

  get hasActions(): boolean {
    return !!(this.actions && this.actions.length);
  }
}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;
@use 'src/assets/scss/mixins' as m;

$call-card-colors: (
  color_danger: v.$danger,
  color_primary: v.$primary,
);

.dp-task-call-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @each $type, $color in $call-card-colors {
    &--#{$type} {
      color: $color;

      :deep(svg) {
        fill: currentColor;
      }
    }
  }

  &__status {
    display: flex;
    align-items: center;
    margin-right: v.$spacer-md;
  }

  &__icon {
    margin-right: v.$spacer-sm;
  }

  &__date {
    font-size: v.$font-size-sm;
    white-space: nowrap;
  }

  &__actions,
  &__audio,
  &__phone-number {
    display: none;
  }

  @include m.media(md) {
    display: grid;
    grid-template-columns: minmax(0, 5fr) minmax(0, 3fr) minmax(0, 5fr) minmax(0, 2fr);
    gap: v.$spacer-sm;

    &__actions,
    &__audio,
    &__phone-number {
      display: block;
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &__phone-number {
      margin-left: v.$spacer-sm;
      font-size: v.$font-size-sm;
    }
  }
}
</style>
