import { injectable } from 'tsyringe';
import type { ClientCredentialsRepository } from '@/modules/authentication/domain/oauth/client/ClientCredentialsRepository';
import { configProvider } from '@/common/app-config/public/configuration-provider';

@injectable()
export class AppConfigClientCredentialsRepository implements ClientCredentialsRepository {
  async get(): Promise<{ clientId: string; clientSecret: string }> {
    return configProvider.get();
  }
}
