<template>
  <div :class="$b()">
    <dp-icon-phone :class="$b('icon')" :size="16" color="gray-600" />
    <div :class="$b('content')">
      {{ content }}
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { DpIconPhone } from '@dp-ui-kit/icons';

@Component({
  name: 'TextSuggestionLine',
  components: {
    DpIconPhone,
  },
})
export default class TextSuggestionLine extends Vue {
  @Prop({ type: String, default: '' })
  readonly content: string;
}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;
@use 'src/assets/scss/mixins' as m;

.dp-text-suggestion-line {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding-left: 0 v.$spacer-sm;

  &__icon {
    display: block;
    flex-shrink: 0;
    margin-right: v.$spacer-md;
  }

  &__content {
    color: v.$gray-900;
    @include m.truncate;
  }
}
</style>
