import { DeviceCall } from '../webphone/models/DeviceCall';
import { TaskCall } from './TaskCall';

export class Call {
  constructor(
    private readonly taskCall: TaskCall,
    private readonly webphoneCall: DeviceCall,
  ) {}

  static empty(): Call {
    return new Call(TaskCall.empty(), DeviceCall.empty());
  }

  get recipient(): string {
    if (this.taskCall.patientId) {
      return this.taskCall.patientId;
    }

    return this.webphoneCall.recipient;
  }

  get isWaiting(): boolean {
    return this.taskCall.isWaiting || this.webphoneCall.isWaitingCall;
  }

  get isIncoming(): boolean {
    return this.webphoneCall.isIncoming;
  }

  get canBeAccepted(): boolean {
    return this.webphoneCall.canAcceptIncomingCall;
  }

  get canBeBounced(): boolean {
    return this.webphoneCall.canBounceIncomingCall;
  }

  get canBeCanceled(): boolean {
    return this.webphoneCall.canCancelCall;
  }

  get canHavePatientOnHold(): boolean {
    return this.taskCall.isInProgress;
  }

  get canSendDtmf(): boolean {
    return this.isInProgress;
  }

  get isNotActive(): boolean {
    return this.taskCall.isNotActive && this.webphoneCall.isClosed;
  }

  get canInviteToConference(): boolean {
    return this.taskCall.isInProgress && !this.hasConference && !this.hasPatientOnHold;
  }

  get isOngoing(): boolean {
    return this.taskCall.isOngoing || this.webphoneCall.isInProgress;
  }

  get isInProgress(): boolean {
    if (this.taskCall.isEmpty) {
      return this.webphoneCall.isInProgress;
    }

    return this.taskCall.isInProgress && this.webphoneCall.isInProgress;
  }

  get hasPatientOnHold(): boolean {
    return this.taskCall.isPatientOnHold;
  }

  get hasConference(): boolean {
    return this.taskCall.hasConference;
  }

  get targetWorkstationId(): string {
    return this.taskCall.targetId;
  }

  get isTargetConnected(): boolean {
    return this.taskCall.isTargetConnected;
  }
}
