import { RootTranslatorToken } from '@/common/root';
import type { Translator } from '@/common/root/domain/Translator';
import { inject, injectable } from 'tsyringe';
import { Brand } from '../../domain/tracking/models/Brand';
import type { BrandFactory } from '../../domain/tracking/repositories/BrandFactory';

@injectable()
export class TranslatedBrandFactory implements BrandFactory {
  constructor(
    @inject(RootTranslatorToken)
    private readonly translator: Translator,
  ) {}

  make(): Brand {
    return new Brand(this.translator.translate('gtm.brand'));
  }
}
