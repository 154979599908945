import type { Translator } from '@/common/root/domain/Translator';
import { injectable } from 'tsyringe';
import type { NotificationManager } from '../domain/NotificationManager';

interface NotificationSender {
  fire({ name, ...args }: { name: string; [k: string]: any }): Promise<any>;
}

@injectable()
export class UiNotificationManager implements NotificationManager {
  constructor(
    private readonly notificationSender: NotificationSender,
    private readonly translator: Translator,
  ) {}

  async changesSavedSuccessfully(): Promise<void> {
    await this.notificationSender.fire({
      name: 'api-success',
      title: this.translator.translate('notify.success'),
      message: this.translator.translate('notify.changes_saved'),
    });
  }
}
