/* eslint-disable no-underscore-dangle */
import { injectable } from 'tsyringe';
import { DoctorWithAddress } from '@/modules/book-visit/domain/doctor/DoctorWithAddress';
import type { HttpDoctorWithAddresses } from './HttpDoctorWithAddresses';
import { HttpAddressFactory } from './HttpAddressFactory';
import { HttpDoctorFactory } from './HttpDoctorFactory';

@injectable()
export class HttpDoctorWithAddressFactory {
  constructor(
    private readonly doctorFactory: HttpDoctorFactory,
    private readonly addressFactory: HttpAddressFactory,
  ) {}

  make(doctorWithAddress: HttpDoctorWithAddresses): DoctorWithAddress[] {
    const { addresses } = doctorWithAddress._embedded;
    const doctor = this.doctorFactory.make(doctorWithAddress);

    if (!addresses) {
      return [];
    }

    return addresses._items.map(
      address => new DoctorWithAddress(doctor, this.addressFactory.make(address)),
    );
  }
}
