import type { Email, NumericId, PhoneNumber } from '@/api/types';
import type { User as RawUser } from '@/api/types/entities/user';
import type { Media } from '@/api/types/entities/media';

export type UserId = NumericId;

export class User {
  id: UserId;

  name: string;

  email: Email;

  phone_number: PhoneNumber;

  avatar?: Media | null;

  private constructor(data: RawUser) {
    this.id = data.id;
    this.name = data.name;
    this.email = data.email ?? '';
    this.phone_number = data.phone_number ?? '';
    this.avatar = data.avatar ?? null;
  }

  get phoneNumber(): PhoneNumber {
    return this.phone_number;
  }

  static make(data: RawUser): User {
    return new User(data);
  }
}
