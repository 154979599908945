import type { Tag } from '@/modules/task/domain/tag/Tag';
import { TagType } from '@/modules/task/domain/tag/Tag';

export class MissedCallTag implements Tag {
  constructor(private readonly numberOfCalls: number) {}

  readonly type = TagType.MissedCall;

  get content(): string {
    return this.numberOfCalls.toString();
  }
}
