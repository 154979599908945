import { injectable } from 'tsyringe';
import type { DpListener } from '@/core/events/DpListener';
import { TrackingEventDispatcher } from './TrackingEventDispatcher';

@injectable()
export class FacilityLoadedListener implements DpListener {
  constructor(private readonly trackingEventDispatcher: TrackingEventDispatcher) {}

  handle(): void {
    this.trackingEventDispatcher.sendFacilityLoaded();
  }
}
