export class Facility {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly integrationMethod: 'none' | 'sso' | 'url',
    readonly saasOnly: boolean,
    readonly workstationLimit: number | null,
    readonly numberOfWorkstations: number,
    readonly numberOfWebphoneWorkstations: number,
    readonly activationDate: string | null,
  ) {}

  get isEmpty(): boolean {
    return !this.id;
  }
}
