import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import type { FacilityTimeZone } from '@/modules/tasks-list/domain/task-expiration/FacilityTimeZone';
import type { DateUtils } from '@/modules/tasks-list/domain/task-expiration/DateUtils';
import type { DateConverter } from '@/modules/tasks-list/domain/task-expiration/DateConverter';
import { StoreFacilityTimeZone } from '@/modules/tasks-list/adapters/task-expiration/StoreFacilityTimeZone';
import { DateFnsUtils } from '@/modules/tasks-list/adapters/task-expiration/DateFnsUtils';
import { DateFnsConverter } from '@/modules/tasks-list/adapters/task-expiration/DateFnsConverter';
import { FacilityTimeZoneToken, DateUtilsToken, DateConverterToken } from '../tokens';

export class DateProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<FacilityTimeZone>(FacilityTimeZoneToken, StoreFacilityTimeZone);
    container.register<DateUtils>(DateUtilsToken, DateFnsUtils);
    container.register<DateConverter>(DateConverterToken, DateFnsConverter);
  }
}
