import { injectable } from 'tsyringe';
import { UserApi } from '@/modules/user/public/api/UserApi';
import type { UserRepository } from '@/modules/tasks-list/domain/ownership-filter/user/UserRepository';
import { User } from '@/modules/tasks-list/domain/ownership-filter/user/User';

@injectable()
export class UserApiUserRepository implements UserRepository {
  constructor(private userApi: UserApi) {}

  getCurrentUser(): User {
    const { id, isManager } = this.userApi.getCurrentUser();

    return new User(id, isManager);
  }
}
