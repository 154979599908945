import { inject, singleton } from 'tsyringe';
import type { Subscription } from './Subscription';
import type { SubscriptionRepository } from './SubscriptionRepository';
import { SubscriptionRepositoryToken } from '../../di/token';

@singleton()
export class SubscriptionFetcher {
  private subscriptionCache: Subscription | null = null;

  private mutex: (() => void) | null = null;

  private mutexPromise: Promise<void> | null = null;

  constructor(
    @inject(SubscriptionRepositoryToken)
    private readonly subscriptionRepository: SubscriptionRepository,
  ) {}

  async fetch(): Promise<void> {
    if (this.subscriptionCache) {
      return;
    }

    if (this.mutexPromise) {
      await this.mutexPromise;
      return;
    }

    this.mutexPromise = this.lock();

    try {
      this.subscriptionCache = await this.subscriptionRepository.get();
    } finally {
      this.unlock();
    }
  }

  get(): Subscription | null {
    return this.subscriptionCache;
  }

  clear(): void {
    this.subscriptionCache = null;
  }

  private lock(): Promise<void> {
    return new Promise(resolve => {
      this.mutex = resolve;
    });
  }

  private unlock(): void {
    if (this.mutex) {
      this.mutex();
      this.mutex = null;
      this.mutexPromise = null;
    }
  }
}
