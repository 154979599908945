import type { StorageFactory } from '@/common/root/domain/storage/services/StorageFactory';
import { RootLocalStorageFactoryToken } from '@/common/root/providers/RootStorageProvider';
import { inject, injectable } from 'tsyringe';
import { UserApi } from '@/modules/user/public/api/UserApi';
import { NotificationSetting } from '../../domain/configuration/NotificationSetting';
import type { NotificationSettingRepository } from '../../domain/configuration/NotificationSettingRepository';
import type { NotificationSettingsDTO } from './NotificationSettingsDTO';

@injectable()
export class LocalStorageNotificationSettingRepository implements NotificationSettingRepository {
  constructor(
    @inject(RootLocalStorageFactoryToken)
    private readonly storageFactory: StorageFactory,
    private readonly userApi: UserApi,
  ) {}

  find(name: string): NotificationSetting | null {
    const status = this.getStoredItem(name).value;

    return status ? new NotificationSetting(name, status) : null;
  }

  set(notificationSettings: NotificationSetting): void {
    this.getStoredItem(notificationSettings.name).set(notificationSettings.status);
  }

  private getStoredItem(name: string) {
    const userId = this.userApi.getCurrentUser().id;

    return this.storageFactory.createStorageItemFactory<NotificationSettingsDTO>(
      `notification-settings-${userId}`,
    )(name);
  }
}
