import { singleton } from 'tsyringe';
import { PendingCallError } from './errors/PendingCallError';
import { RestartFailError } from './errors/RestartFailError';
import { ActiveCallStateRepository } from '../state/ActiveCallStateRepository';

@singleton()
export class RestartExecutor {
  constructor(private readonly activeCallStateRepository: ActiveCallStateRepository) {}

  execute(callback: () => void): void {
    if (this.hasPendingCall) {
      throw new PendingCallError();
    }

    try {
      callback();
    } catch (error) {
      throw new RestartFailError(error.message);
    }
  }

  private get hasPendingCall(): boolean {
    return this.activeCallStateRepository.get().isActive;
  }
}
