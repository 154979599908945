import { loginRoutes } from '@/modules/authentication/ui/login/routes';
import { passwordResetRoutes } from '@/modules/authentication/ui/password-reset/routes';
import { userInvitationRoutes } from '@/modules/authentication/ui/user-invitation/routes';
import { ssoIntegrationRoutes } from '@/modules/authentication/ui/sso-integration/routes';
import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  ...passwordResetRoutes,
  ...userInvitationRoutes,
  ...ssoIntegrationRoutes,
  ...loginRoutes,
];
