import { TokenRepositoryToken } from '@/modules/phone-widget/di/tokens';
import { inject, injectable } from 'tsyringe';
import { WorkstationFetcher } from '../../services/WorkstationFetcher';
import type { TokenRepository } from './TokenRepository';

@injectable()
export class TokenGenerator {
  constructor(
    @inject(TokenRepositoryToken)
    private readonly tokenRepository: TokenRepository,
    private readonly workstationFetcher: WorkstationFetcher,
  ) {}

  async get(lifetimeInMinutes: number): Promise<string> {
    return this.tokenRepository.get(this.getWorkstationId(), lifetimeInMinutes);
  }

  private getWorkstationId(): string {
    const workstation = this.workstationFetcher.getMine();

    if (!workstation) {
      throw new Error('User is not logged to a valid workstation.');
    }

    return workstation.id;
  }
}
