import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { routes as moduleRouter } from '@/modules/authentication/ui/routes';
import type { Router } from 'vue-router';
import { RootRouterToken } from '@/common/root';
import { RouterToken } from '@/modules/authentication/di/token';
import { VueRouter } from '@/modules/authentication/adapters/VueRouter';

@injectable()
export class RouterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register(RouterToken, VueRouter);
  }

  boot(container: DependencyContainer): void {
    const router = container.resolve<Router>(RootRouterToken);

    moduleRouter.forEach(route => router.addRoute(route));
  }
}
