import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import { Media } from '../../domain/media/Media';
import type { MediaFileRepository } from '../../domain/media/MediaFileRepository';

interface HttpMedia {
  id?: string;
  name?: string;
  url: string;
}

@injectable()
export class HttpMediaFileRepository implements MediaFileRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async uploadPublicFile(fileUrl: string): Promise<Media> {
    const data: HttpMedia = await this.httpClient.put(`/api/v0/facility/{facilityId}/upload`, {
      url: fileUrl,
      storage: 'public',
    });

    return Media.make(data);
  }
}
