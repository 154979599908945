export class ConversationId {
  private constructor(private readonly id: string | null) {}

  get value(): string | null {
    return this.id;
  }

  static of(id: string | null): ConversationId {
    return new ConversationId(id);
  }
}
