import { injectable } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { StoreToConnectionStateAdapter } from '@/modules/phone-widget/adapters/StoreToConnectionStateAdapter';
import { StoreToWorkstationStateAdapter } from '@/modules/phone-widget/adapters/StoreToWorkstationStateAdapter';
import type { ConnectionState } from '@/modules/phone-widget/services/ConnectionState';
import type { WorkstationState } from '@/modules/phone-widget/services/WorkstationState';
import { EventTrackerFacade } from '@/common/tracking-event/public/api';
import { RootTranslatorToken } from '@/common/root';
import {
  ConnectionStateToken,
  EventTrackerToken,
  PanelControllerToken,
  TranslatorToken,
  WorkstationStateToken,
  ConnectionIdentifierLabelFetcherToken,
} from '../tokens';
import type { EventTracker } from '../../domain/EventTracker';
import type { Translator } from '../../domain/Translator';
import type { PanelController } from '../../domain/actions/PanelController';
import { StorePanelController } from '../../adapters/StorePanelController';
import { StoreConnectionIdentifierLabelFetcher } from '../../adapters/StoreConnectionIdentifierLabelFetcher';
import type { ConnectionIdentifierLabelFetcher } from '../../domain/services/ConnectionIdentifierLabelFetcher';

@injectable()
export class AdapterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<ConnectionState>(ConnectionStateToken, StoreToConnectionStateAdapter);

    container.register<WorkstationState>(WorkstationStateToken, StoreToWorkstationStateAdapter);

    container.register<EventTracker>(EventTrackerToken, EventTrackerFacade);
    container.register<Translator>(TranslatorToken, {
      useFactory: c => c.resolve(RootTranslatorToken),
    });

    container.register<PanelController>(PanelControllerToken, StorePanelController);

    container.register<ConnectionIdentifierLabelFetcher>(
      ConnectionIdentifierLabelFetcherToken,
      StoreConnectionIdentifierLabelFetcher,
    );
  }
}
