import { injectable } from 'tsyringe';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import type { AudioExtension, AudioConverter } from '../../domain/audio-recorder/AudioConverter';
import { TranscodingError } from '../../domain/audio-recorder/TranscodingError';
import { MimeTypesFactory } from '../../domain/audio-recorder/MimeTypesFactory';

@injectable()
export class FfmpegAudioConverter implements AudioConverter {
  private ffmpeg;

  private inputExtension: AudioExtension = 'webm';

  private outputExtension: AudioExtension = 'mp3';

  constructor(private readonly mimeTypesFactory: MimeTypesFactory) {
    this.ffmpeg = new FFmpeg();
  }

  public async convertToMp3(inputFile: string | Blob | File): Promise<Blob> {
    try {
      await this.loadFFmpeg();

      const inputFileName = `input.${this.inputExtension}`;
      const outputFileName = `output.${this.outputExtension}`;

      await this.ffmpeg.writeFile(inputFileName, await fetchFile(inputFile));

      // Run the FFmpeg command to transcode the file
      await this.ffmpeg.exec(['-i', inputFileName, outputFileName]);

      // Read the output file from the FFmpeg file system
      const data = await this.ffmpeg.readFile(outputFileName);

      // Create a Blob from the transcoded file
      const transcodedBlob = new Blob([data.buffer], {
        type: this.mimeTypesFactory.make(this.outputExtension),
      });

      return transcodedBlob;
    } catch {
      throw new TranscodingError('An error occurred while converting the audio.');
    }
  }

  private async loadFFmpeg(): Promise<void> {
    await this.ffmpeg.load();
  }
}
