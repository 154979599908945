import { BookingSlotList } from '../booking-slot/BookingSlotList';
import type { DoctorWithAddress } from '../doctor/DoctorWithAddress';
import type { Service } from '../service/Service';

export class FacilityBookingOptions {
  constructor(
    readonly doctorsWithAddressesList: DoctorWithAddress[],
    readonly servicesList: Service[],
    readonly bookingSlotList: BookingSlotList,
    readonly hasError = false,
  ) {}

  get hasDoctorsWithAddress(): boolean {
    return this.doctorsWithAddressesList.length > 0;
  }

  get hasService(): boolean {
    return this.servicesList.length > 0;
  }

  get hasSlots(): boolean {
    return this.bookingSlotList.hasSlots;
  }

  get isEmpty(): boolean {
    return !this.hasDoctorsWithAddress && !this.hasService && !this.hasSlots;
  }

  get isErrored(): boolean {
    return this.hasError;
  }

  static makeEmpty(): FacilityBookingOptions {
    return new FacilityBookingOptions([], [], BookingSlotList.makeEmpty());
  }

  static makeErrored(): FacilityBookingOptions {
    return new FacilityBookingOptions([], [], BookingSlotList.makeEmpty(), true);
  }
}
