import { inject, injectable } from 'tsyringe';
import { FacilityIdRepositoryToken, CSModeRepositoryToken } from '../../di/token';
import type { CSModeRepository } from './CSModeRepository';
import { CSMode } from './CSMode';
import type { FacilityIdRepository } from './FacilityIdRepository';
import type { CSModeEnabledChecker } from './CSModeEnabledChecker';

@injectable()
export class CSModeManager implements CSModeEnabledChecker {
  private readonly defaultStatus = 'enabled';

  constructor(
    @inject(CSModeRepositoryToken)
    private readonly csModeRepository: CSModeRepository,
    @inject(FacilityIdRepositoryToken)
    private readonly facilityIdRepository: FacilityIdRepository,
  ) {}

  isEnabled(): boolean {
    this.validateStatus();
    return this.getStatus() === 'enabled';
  }

  enable(): void {
    this.setStatus('enabled');
  }

  disable(): void {
    this.setStatus('disabled');
  }

  clearStatus(): void {
    this.csModeRepository.remove();
  }

  private get facilityId(): string {
    return this.facilityIdRepository.get();
  }

  private validateStatus(): void {
    const mode = this.csModeRepository.find();

    if (mode === null) {
      return;
    }

    if (mode.facilityId !== this.facilityId) {
      this.csModeRepository.remove();
    }
  }

  private getStatus(): 'enabled' | 'disabled' {
    const mode = this.csModeRepository.find();

    if (mode === null) {
      return this.defaultStatus;
    }

    return mode.status;
  }

  private setStatus(status: 'enabled' | 'disabled'): void {
    this.csModeRepository.save(new CSMode(status, this.facilityId));
  }
}
