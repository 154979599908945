import type { Locale } from '@/utils/languageDetector';
import { LocaleMapper } from '@/app/formatters/number/LocaleMapper';

export class TimeFormatter {
  static readonly secInHour = 3600;

  static readonly secInMinute = 60;

  private numberFormatter = Intl.NumberFormat;

  private intlLocale: string;

  private constructor(locale: Locale) {
    this.intlLocale = LocaleMapper.toIntlLocale(locale);
  }

  static make(locale: Locale): TimeFormatter {
    return new TimeFormatter(locale);
  }

  asDuration(durationInSeconds: number): string {
    let hours = '';

    if (TimeFormatter.hasAtLeastOneHour(durationInSeconds)) {
      hours = this.getHours(durationInSeconds).toString();
    }

    const minutes = this.getMinutes(durationInSeconds).toString().padStart(2, '0');
    const seconds = this.getSeconds(durationInSeconds).toString().padStart(2, '0');

    return [hours, minutes, seconds].filter(segment => segment !== '').join(':');
  }

  asTime(timeInSeconds: number): string {
    const output: string[] = [];

    if (TimeFormatter.hasAtLeastOneHour(timeInSeconds)) {
      const hours = this.numberFormatter(
        this.intlLocale,
        TimeFormatter.getOptionsForUnit('hour'),
      ).format(this.getHours(timeInSeconds));

      output.push(hours);
    }

    if (TimeFormatter.hasAtLeastOneMinute(timeInSeconds)) {
      const minutes = this.numberFormatter(
        this.intlLocale,
        TimeFormatter.getOptionsForUnit('minute'),
      ).format(this.getMinutes(timeInSeconds));

      output.push(minutes);
    }

    // if we have hours, the seconds' precision is not required
    if (!TimeFormatter.hasAtLeastOneHour(timeInSeconds)) {
      const seconds = this.numberFormatter(
        this.intlLocale,
        TimeFormatter.getOptionsForUnit('second'),
      ).format(this.getSeconds(timeInSeconds));

      output.push(seconds);
    }

    return output.join(' ');
  }

  private getHours(seconds: number): number {
    return Math.floor(seconds / TimeFormatter.secInHour);
  }

  private getMinutes(seconds: number): number {
    const secondsRemainingInHour = seconds % TimeFormatter.secInHour;

    return Math.floor(secondsRemainingInHour / TimeFormatter.secInMinute);
  }

  private getSeconds(seconds: number): number {
    return seconds % TimeFormatter.secInMinute;
  }

  private static hasAtLeastOneHour(seconds: number) {
    return seconds >= TimeFormatter.secInHour;
  }

  private static hasAtLeastOneMinute(seconds: number) {
    return seconds >= TimeFormatter.secInMinute;
  }

  private static getOptionsForUnit(unit: string) {
    return {
      style: 'unit',
      unitDisplay: 'narrow',
      unit,
    } as const;
  }
}
