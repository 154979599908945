import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import type { PingRepository } from '../../domain/ping/PingRepository';
import { FacilityAccessCheckerToken, PingRepositoryToken } from '../tokens';
import type { FacilityAccessChecker } from '../../domain/ping/UserFacilityAccessValidator';
import { StoreFacilityAccessChecker } from '../../adapters/ping/StoreFacilityAccessChecker';
import { HttpPingRepository } from '../../adapters/ping/HttpPingRepository';

export class PingProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<PingRepository>(PingRepositoryToken, HttpPingRepository);
    container.register<FacilityAccessChecker>(
      FacilityAccessCheckerToken,
      StoreFacilityAccessChecker,
    );
  }
}
