import type { DpModule, IDpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { TrackingEventModule } from '@/common/tracking-event';
import { RootModule } from '@/common/root';
import { AuthenticationModule } from '@/modules/authentication';
import { AdapterProvider } from './providers/AdapterProvider';
import { EventBusProvider } from './providers/EventBusProvider';
import { AuthorizationProvider } from './providers/AuthorizationProvider';

export class WorkstationModule implements DpModule {
  readonly requires: IDpModule[] = [RootModule, TrackingEventModule, AuthenticationModule];

  providers(): IDpProvider[] {
    return [AdapterProvider, EventBusProvider, AuthorizationProvider];
  }
}
