import type { CallQualityModalController } from '@/modules/task/domain/call-quality/CallQualityModalController';
import { injectable } from 'tsyringe';
import { TimelineApi } from '@/modules/timeline/public/api';

@injectable()
export class TimelineCallQualityModalController implements CallQualityModalController {
  constructor(private readonly timelineApi: TimelineApi) {}

  canOpen(): boolean {
    return this.timelineApi.canOpenCallQualityModal();
  }

  open(callId: string): void {
    this.timelineApi.openCallQualityModal(callId);
  }
}
