<template>
  <i :class="iconClass">
    <svg width="22" height="18" viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.9159 12.85C15.1134 7.72583 6.37755 7.72583 0.584216 12.85C-0.185784 13.5283 -0.185785 14.7292 0.538382 15.4533L1.63838 16.5533C2.28922 17.2042 3.32505 17.2592 4.04005 16.6908L5.84588 15.2517C6.27672 14.9125 6.53338 14.39 6.53338 13.8308V11.475C9.26505 10.5858 12.2259 10.5767 14.9667 11.475V13.84C14.9667 14.39 15.2234 14.9125 15.6542 15.2608L17.4509 16.6908C18.175 17.2592 19.2017 17.2042 19.8526 16.5533L20.9525 15.4533C21.6859 14.7292 21.6767 13.5283 20.9159 12.85V12.85ZM5.25005 6.25C5.75422 6.25 6.16672 5.8375 6.16672 5.33333V3.89417L10.0534 7.78083C10.4109 8.13833 10.9884 8.13833 11.3459 7.78083L16.5342 2.5925C16.8917 2.235 16.8917 1.6575 16.5342 1.3C16.1767 0.9425 15.5992 0.9425 15.2417 1.3L10.7042 5.8375L7.45005 2.58333H8.91672C9.42088 2.58333 9.83338 2.17083 9.83338 1.66667C9.83338 1.1625 9.42088 0.75 8.91672 0.75H5.25005C4.74588 0.75 4.33338 1.1625 4.33338 1.66667V5.33333C4.33338 5.8375 4.74588 6.25 5.25005 6.25Z"
      />
    </svg>
  </i>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import BaseIcon from '@dp-ui-kit/icons/vue/BaseIcon.vue';

export default defineComponent({
  name: 'BounceArrowOnPhoneLeftIcon',
  extends: BaseIcon as any,
  iconName: 'bounce-arrow-on-phone-left',
});
</script>
