import { delay, inject, injectable } from 'tsyringe';
import { CustomEventSender } from '@/common/tracking-event/domain/event-subscriber/CustomEventSender';
import type { EventPublisher } from '../../domain/tracking/EventPublisher';
import type { TrackingFacilityEvent } from '../../domain/tracking/TrackingFacilityEvent';

@injectable()
export class TrackingEventPublisher implements EventPublisher {
  constructor(
    @inject(delay(() => CustomEventSender))
    private readonly eventSender: CustomEventSender,
  ) {}

  send(event: TrackingFacilityEvent): void {
    this.eventSender.publishCustomEvent(event);
  }
}
