import { $GET_ENTITIES_BY_ID } from '@dp-vue/entities';
import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import { Media } from '@/modules/timeline/domain/message-composer/message/media/Media';
import { StoreMediaFactory } from '@/modules/timeline/adapters/timeline/store/message/StoreMediaFactory';
import type { Store } from '@/modules/timeline/adapters/timeline/store/Store';

@injectable()
export class StoreMediaRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
    private readonly storeMediaFactory: StoreMediaFactory,
  ) {}

  getListWithProxy(ids: string[]): Media[] {
    return this.storeMediaFactory.makeManyWithProxy(
      this.store.getters[$GET_ENTITIES_BY_ID]('media', ids),
    );
  }

  getList(ids: string[]): Media[] {
    return this.storeMediaFactory.makeMany(this.store.getters[$GET_ENTITIES_BY_ID]('media', ids));
  }

  get(id: string): Media {
    const media = this.store.getters[$GET_ENTITIES_BY_ID]('media', [id])[0];

    return this.storeMediaFactory.make(media);
  }
}
