import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { OutboundTaskRepository } from '../../domain/outbound-task/OutboundTaskRepository';
import { OutboundTaskRepositoryToken } from '../token';
import { HttpOutboundTaskRepository } from '../../adapters/outbound-task/HttpOutboundTaskRepository';

@injectable()
export class OutboundTaskProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<OutboundTaskRepository>(
      OutboundTaskRepositoryToken,
      HttpOutboundTaskRepository,
    );
  }
}
