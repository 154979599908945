import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import type { Authorizer } from '@/modules/phone-widget/domain/Authorizer';
import { Authorization } from '@/common/authorization/public/api';
import { AuthorizationApiAuthorizer } from '@/modules/phone-widget/adapters/AuthorizationApiAuthorizer';
import { useInternalTransfersAction } from '@/modules/phone-widget/public/policies';
import { AuthorizerToken } from '@/modules/phone-widget/di/tokens';

export class AuthorizationProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<Authorizer>(AuthorizerToken, AuthorizationApiAuthorizer);
  }

  boot(container: DependencyContainer): void {
    const authorization = container.resolve(Authorization);

    authorization.addPolicies([
      {
        name: useInternalTransfersAction,
        handler: user => user.hasFeature('INBOX_INTERNAL_TRANSFER'),
      },
    ]);
  }
}
