import { injectable } from 'tsyringe';
import { UserAuthenticator } from '@/modules/authentication/domain/login/UserAuthenticator';
import { UserOAuthClient } from '../../domain/oauth/UserOAuthClient';

@injectable()
export class AuthenticationApi {
  constructor(
    private readonly userAuthenticator: UserAuthenticator,
    private readonly oauthClient: UserOAuthClient,
  ) {}

  logout(): Promise<void> {
    return this.userAuthenticator.logout();
  }

  isAuthenticated(): boolean {
    return this.oauthClient.isAuthenticated();
  }
}
