import { inject, injectable } from 'tsyringe';
import { DesktopNotificationEvaluator } from '@/modules/notification/domain/permissions/DesktopNotificationEvaluator';
import { NotificationSettingRepositoryToken } from '../../di/tokens';
import { incomingCallNotificationName, newMessageNotificationName } from './NotificationName';
import type { NotificationSettingRepository } from './NotificationSettingRepository';

@injectable()
export class NotificationConfigurationReader {
  private static defaults = {
    [incomingCallNotificationName]: true,
    [newMessageNotificationName]: true,
  };

  constructor(
    @inject(NotificationSettingRepositoryToken)
    private readonly notificationSettingRepository: NotificationSettingRepository,
    private readonly desktopNotificationEvaluator: DesktopNotificationEvaluator,
  ) {}

  isEnabled(name: string): boolean {
    if (!this.desktopNotificationEvaluator.supportsNotifications()) {
      return false;
    }

    const configuration = this.notificationSettingRepository.find(name);

    if (!configuration) {
      return NotificationConfigurationReader.defaults[name] ?? false;
    }

    return configuration.status === 'enabled';
  }
}
