import { RootDateFormatterToken, RootTimeZoneRepositoryToken } from '@/common/root';
import type { DateFormatter, TimeZoneRepository } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import { FacilityProfile } from '../../domain/facility-integration/FacilityProfile';
import type { InputFacilityProfile } from './types/InputFacilityProfile';

@injectable()
export class FacilityProfileFactory {
  constructor(
    @inject(RootDateFormatterToken)
    private readonly dateFormatter: DateFormatter,
    @inject(RootTimeZoneRepositoryToken)
    private readonly timezoneRepository: TimeZoneRepository,
  ) {}

  make(facilityProfile: InputFacilityProfile): FacilityProfile {
    return new FacilityProfile(
      facilityProfile.name,
      facilityProfile.image,
      this.dateFormatter
        .dateFromIso(facilityProfile.updated_at, this.timezoneRepository.get())
        .toDefaultDate(),
      facilityProfile.url,
    );
  }
}
