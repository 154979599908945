import type { EventRecord } from './EventRecord';
import { Frequency } from './Frequency';

interface Percentiles {
  median: number;
  p90: number;
  max: number;
}

export class FrequencyFactory {
  private readonly bucketSize = 1000;

  static getSorter() {
    return (eventAlpha: EventRecord, eventBravo: EventRecord): number =>
      eventAlpha.timestamp - eventBravo.timestamp;
  }

  static timeFilter(startTimestamp: number, endTimestamp: number) {
    return (eventRecord: EventRecord): boolean =>
      eventRecord.timestamp >= startTimestamp && eventRecord.timestamp < endTimestamp;
  }

  make(eventRecords: EventRecord[], startTimestamp: number, endTimestamp: number): Frequency {
    const events = eventRecords
      .filter(FrequencyFactory.timeFilter(startTimestamp, endTimestamp))
      .sort(FrequencyFactory.getSorter());

    const { median, p90, max } = this.getPercentiles(events, startTimestamp, endTimestamp);

    return new Frequency(events.length, median, p90, max);
  }

  private getPercentiles(
    eventRecords: EventRecord[],
    startTimestamp: number,
    endTimestamp: number,
  ): Percentiles {
    const buckets = this.initializeBuckets(startTimestamp, endTimestamp);

    eventRecords.forEach(eventRecord => {
      const bucketId = this.getBucketId(startTimestamp, eventRecord.timestamp);
      buckets[bucketId] += 1;
    });

    const sortedByFrequency = Object.values(buckets).sort(
      (alphaFrequency, bravoFrequency) => alphaFrequency - bravoFrequency,
    );

    const max = sortedByFrequency[sortedByFrequency.length - 1];
    const median = this.getPercentile(50, sortedByFrequency);
    const p90 = this.getPercentile(90, sortedByFrequency);

    return { median, p90, max };
  }

  private initializeBuckets(startTimestamp: number, endTimestamp: number): Record<number, 0> {
    const size = this.getBucketId(startTimestamp, endTimestamp);

    return Array.from(Array(size + 1), (_, i) => i).reduce(
      (accumulator, current) => ({ ...accumulator, [current]: 0 }),
      {},
    );
  }

  private getBucketId(startTimestamp: number, timestamp: number): number {
    return Math.floor((timestamp - startTimestamp) / this.bucketSize);
  }

  /**
   * Just take higher number for simplicity.
   */
  private getPercentile(rank: number, scores: number[]) {
    const highRank = Math.ceil((rank / 100) * scores.length);

    return scores[highRank - 1];
  }
}
