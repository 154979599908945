import { injectable } from 'tsyringe';

@injectable()
export class HubspotChatVisibilityManager {
  private hubspotId = 'hubspot-messages-iframe-container';

  hide(): void {
    const node = this.getChatNode();

    if (node) {
      node.style.visibility = 'hidden';
    }
  }

  show(): void {
    const node = this.getChatNode();

    if (node) {
      node.style.visibility = 'visible';
    }
  }

  private getChatNode(): HTMLElement | null {
    return document.getElementById(this.hubspotId) ?? null;
  }
}
