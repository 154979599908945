import type { TrafficDataRepository } from '@/modules/statistics/domain/traffic/TrafficDataRepository';
import type { TrafficData } from '@/modules/statistics/domain/traffic/TrafficData';
import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { QueryParams } from '@/modules/statistics/domain/QueryParams';

@injectable()
export class HttpTrafficDataRepository implements TrafficDataRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async get(queryParams: QueryParams): Promise<TrafficData[]> {
    return this.httpClient.get('api/v0/facility/{facilityId}/stats/traffic', {
      ...queryParams,
    });
  }
}
