import type { UUID } from '@/api/types';
import type { FacilityUsersRoles } from '@/app/facility-users-roles';
import { User } from '@/api/models/User';
import type { Permission as RawPermission } from '@/api/types/entities/permission';

export type PermissionId = UUID;

export class Permission {
  id: PermissionId;

  has_signed_in: boolean;

  role: FacilityUsersRoles;

  weight: number;

  user: User;

  private constructor(data: RawPermission) {
    this.id = data.id;
    this.has_signed_in = data.has_signed_in;
    this.role = data.role as FacilityUsersRoles;
    this.weight = data.weight;
    // TODO Extract users and permissions as store entities
    this.user = User.make(data.user);
  }

  get hasSignedIn(): boolean {
    return this.has_signed_in;
  }

  static make(data: RawPermission): Permission {
    return new Permission(data);
  }
}
