import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { StoreDateRangeRepository } from '../../adapters/filters/StoreDateRangeRepository';
import type { DateRangeRepository } from '../../domain/filters/DateRangeRepository';
import { DateRangeRepositoryToken } from '../tokens';

@injectable()
export class FilterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<DateRangeRepository>(DateRangeRepositoryToken, StoreDateRangeRepository);
  }
}
