import { inject, injectable } from 'tsyringe';
import type { BaseUrlFactory } from '@/modules/http-client/domain/base-url/BaseUrlFactory';
import type { HttpRequestConfig } from '@/modules/http-client/domain/request/HttpRequestConfig';
import { BaseUrlFactoryToken } from '@/modules/http-client/di/tokens';

@injectable()
export class BaseHttpRequestConfigFactory {
  constructor(
    @inject(BaseUrlFactoryToken)
    private readonly baseUrlFactory: BaseUrlFactory,
  ) {}

  make(): HttpRequestConfig {
    return {
      url: '',
      method: 'get',
      baseURL: this.baseUrlFactory.make(),
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }
}
