import type { RouteRecordRaw } from 'vue-router';
import { ifNotAuthenticated } from '../guards';

export const userInvitationRoutes: RouteRecordRaw[] = [
  {
    path: '/user-invitation/:token/:phoneNumber?',
    name: 'user-invitation-token',
    beforeEnter: ifNotAuthenticated,
    component: () => import(/* webpackChunkName: "auth" */ './pages/InvitationToken.vue'),
    props: true,
  },
  {
    path: '/user-invitation',
    name: 'user-invitation-phone',
    beforeEnter: ifNotAuthenticated,
    component: () => import(/* webpackChunkName: "auth" */ './pages/InvitationPhone.vue'),
  },
];
