import { injectable } from 'tsyringe';
import type { SubjectsDataRepository } from '@/modules/statistics/domain/subjects/SubjectsDataRepository';
import type { QueryParams } from '@/modules/statistics/domain/QueryParams';
import type { SubjectsData } from '@/modules/statistics/domain/subjects/SubjectsData';
import { HttpClientApi } from '@/modules/http-client/public/api';

@injectable()
export class HttpSubjectsDataRepository implements SubjectsDataRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  get(queryParams: QueryParams): Promise<SubjectsData[]> {
    return this.httpClient.get('api/v0/facility/{facilityId}/stats/results', {
      ...queryParams,
    });
  }
}
