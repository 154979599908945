import type { PermissionRole } from './PermissionRole';
import type { Reorderable } from './Reorderable';
import type { User } from './User';

export class Permission implements Reorderable {
  constructor(
    readonly id: string,
    public role: PermissionRole,
    readonly user: User,
    readonly weight: number,
    readonly hasSignedIn: boolean,
  ) {}

  get userId(): string {
    return this.user.id;
  }
}
