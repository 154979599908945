import type { WorkstationRepository } from '@/modules/phone-widget/connection/WorkstationRepository';
import type { TaskRepository } from '@/modules/phone-widget/connection/TaskRepository';
import type { PatientRepository } from '@/modules/phone-widget/domain/repositories/PatientRepository';
import { phoneNumberFormatter } from '@/utils/phone-number-formatter';
import { Patient } from '@/modules/phone-widget/domain/models/Patient';
import type { Task } from '@/modules/phone-widget/connection/Task';
import type { Workstation } from '@/modules/phone-widget/connection/Workstation';

export class ConnectionIdentifierLabelFactory {
  constructor(
    private workstations: WorkstationRepository,
    private tasks: TaskRepository,
    private patients: PatientRepository,
  ) {}

  get(to: string): string {
    const patient = this.findPatient(to);
    if (patient) {
      return patient.displayName;
    }

    const workstation = this.findWorkstation(to);
    if (workstation) {
      return workstation.displayName;
    }

    const task = this.findTask(to);
    if (task) {
      return task.displayName(this.patients);
    }

    return phoneNumberFormatter(to);
  }

  private findPatient(to: string): Patient | null {
    const patientId = Patient.findId(to);
    if (!patientId) {
      return null;
    }

    return this.patients.get(patientId);
  }

  private findTask(to: string): Task | null {
    const taskId = to.replace(/^task:/, '');
    return this.tasks.find(taskId) || this.tasks.findOngoing();
  }

  private findWorkstation(to: string): Workstation | null {
    return this.workstations.findByInternalNumber(to) ?? this.workstations.find(to);
  }
}
