export class WorkingHour {
  private constructor(
    readonly open: boolean,
    readonly start: string,
    readonly end: string,
    readonly siestaStart: string | null,
    readonly siestaEnd: string | null,
  ) {}

  get hasSiesta(): boolean {
    return !!this.siestaStart;
  }

  static make(
    open: boolean,
    start: string,
    end: string,
    siestaStart: string | null = null,
    siestaEnd: string | null = null,
  ): WorkingHour {
    if (siestaStart && siestaEnd) {
      return this.makeWithSiesta(open, start, end, siestaStart, siestaEnd);
    }

    return this.makeWithoutSiesta(open, start, end);
  }

  private static makeWithoutSiesta(open: boolean, start: string, end: string): WorkingHour {
    return new WorkingHour(open, start, end, null, null);
  }

  private static makeWithSiesta(
    open: boolean,
    start: string,
    end: string,
    siestaStart: string,
    siestaEnd: string,
  ): WorkingHour {
    return new WorkingHour(open, start, end, siestaStart, siestaEnd);
  }
}
