import { injectable } from 'tsyringe';
import type { SsoTokenRepository } from '@/modules/authentication/domain/sso/login/SsoTokenRepository';
import { UserTokenPayload } from '@/modules/authentication/domain/login/UserTokenPayload';
import { AnonymousHttpClientApi } from '@/modules/http-client/public/api';

interface HttpTokensResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
}

@injectable()
export class HttpSsoTokenRepository implements SsoTokenRepository {
  constructor(private readonly client: AnonymousHttpClientApi) {}

  async exchangeCodeForToken(
    clientId: string,
    code: string,
    redirectUri: string,
  ): Promise<UserTokenPayload> {
    const data = await this.client.post<HttpTokensResponse>(`/auth/code/${clientId}`, {
      code,
      redirectUri,
    });

    return UserTokenPayload.makeFromResponse(data);
  }
}
