import { injectable } from 'tsyringe';
import type { AccessTokenRequester } from '@/modules/authentication/domain/oauth/token/AccessTokenRequester';
import { AccessToken } from '@/modules/authentication/domain/oauth/token/AccessToken';
import type { ClientCredentialsTokenRequest } from '@/modules/authentication/domain/oauth/token/types/ClientCredentialsTokenRequest';
import type { CodeAccessTokenRequest } from '@/modules/authentication/domain/oauth/token/types/CodeAccessTokenRequest';
import type { PasswordAccessTokenRequest } from '@/modules/authentication/domain/oauth/token/types/PasswordAccessTokenRequest';
import type { RefreshTokenRequest } from '@/modules/authentication/domain/oauth/token/types/RefreshTokenRequest';
import { BaseHttpClientApi } from '@/modules/http-client/public/api';
import { HttpClientError } from '@/modules/http-client/public/types';
import { AccessTokenRefreshError } from '@/modules/authentication/domain/oauth/token/errors/AccessTokenRefreshError';
import { AccessTokenGrantError } from '@/modules/authentication/domain/oauth/token/errors/AccessTokenGrantError';
import { CredentialsValidationError } from '@/modules/authentication/domain/oauth/token/errors/CredentialsValidationError';

interface HttpTokenResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: 'bearer';
}

@injectable()
export class HttpAccessTokenRequester implements AccessTokenRequester {
  constructor(private readonly httpClient: BaseHttpClientApi) {}

  async requestAccessTokenWithCode(request: CodeAccessTokenRequest): Promise<AccessToken> {
    const data = await this.httpClient.post<HttpTokenResponse>(`/auth/code/${request.clientId}`, {
      code: request.code,
      redirectUri: request.redirectUri,
    });

    return AccessToken.make(data.access_token, data.refresh_token, data.expires_in);
  }

  async requestAccessTokenWithClientCredentials(
    request: ClientCredentialsTokenRequest,
  ): Promise<AccessToken> {
    const data = await this.httpClient.post<HttpTokenResponse>('/oauth/v2/token', {
      grant_type: 'client_credentials',
      client_id: request.clientId,
      client_secret: request.clientSecret,
    });

    return AccessToken.make(data.access_token, data.refresh_token, data.expires_in);
  }

  async requestAccessTokenWithPassword(request: PasswordAccessTokenRequest): Promise<AccessToken> {
    try {
      const data = await this.httpClient.post<HttpTokenResponse>('/oauth/v2/token', {
        grant_type: 'password',
        client_id: request.clientId,
        client_secret: request.clientSecret,
        locale: request.locale,
        username: request.username,
        password: request.password,
      });

      return AccessToken.make(data.access_token, data.refresh_token, data.expires_in);
    } catch (error) {
      if (error instanceof HttpClientError && error.statusCode === 400) {
        throw new AccessTokenGrantError();
      }

      if (error instanceof HttpClientError && error.statusCode === 422) {
        throw new CredentialsValidationError(error.message);
      }

      throw error;
    }
  }

  async requestAccessTokenWithRefreshToken(request: RefreshTokenRequest): Promise<AccessToken> {
    try {
      const data = await this.httpClient.post<HttpTokenResponse>('/oauth/v2/token', {
        grant_type: 'refresh_token',
        client_id: request.clientId,
        client_secret: request.clientSecret,
        refresh_token: request.refreshToken,
      });

      return AccessToken.make(data.access_token, data.refresh_token, data.expires_in);
    } catch (error) {
      if (error instanceof HttpClientError && [400, 401].includes(error.statusCode)) {
        throw new AccessTokenRefreshError();
      }

      throw error;
    }
  }
}
