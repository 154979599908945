import { injectable } from 'tsyringe';
import type { TaskUpdateEvent } from './TaskUpdateEvent';
import { ShortOutboundCallsTracker } from './trackers/ShortOutboundCallsTracker';
import { UnansweredCallTracker } from './trackers/UnansweredCallTracker';
import { CompleteInboundCallTracker } from './trackers/CompleteInboundCallTracker';
import { CallTrackerDispatcher } from './trackers/CallTrackerDispatcher';

@injectable()
export class TaskUpdateHandler {
  constructor(
    private readonly callTrackerDispatcher: CallTrackerDispatcher,
    private readonly shortOutboundCallsTracker: ShortOutboundCallsTracker,
    private readonly unansweredCallTracker: UnansweredCallTracker,
    private readonly completeInboundCallTracker: CompleteInboundCallTracker,
  ) {
    this.callTrackerDispatcher.addTracker(this.shortOutboundCallsTracker);
    this.callTrackerDispatcher.addTracker(this.unansweredCallTracker);
    this.callTrackerDispatcher.addTracker(this.completeInboundCallTracker);
  }

  handle(event: TaskUpdateEvent): void {
    this.callTrackerDispatcher.dispatch(event.id);
  }
}
