import type { OutputPatient as ApiPatient } from '@/modules/patient/public/types';
import { injectable } from 'tsyringe';
import { Patient } from '../../domain/patient/Patient';

@injectable()
export class PatientFactory {
  make(apiPatient: ApiPatient): Patient {
    return new Patient(
      apiPatient.id,
      apiPatient.phoneNumber,
      apiPatient.fullName,
      apiPatient.name,
      apiPatient.surname,
      apiPatient.note,
      !!apiPatient.priority,
      apiPatient.url,
    );
  }
}
