import { MUTATION_ENTITIES_UPDATE } from '@dp-vue/entities';
import type { ActionTree, GetterTree, MutationTree } from 'vuex';
import { api } from '@/modules/patient/public/api';
import { patientMapper } from '@/api/mappers';
import type { InputPatient } from '@/modules/patient/public/types';

type State = Record<string, never>;

const state: State = {};

const mutations: MutationTree<State> = {};

export const PROCESS_PATIENT_ACTION = 'PROCESS_PATIENT_ACTION';
export const PROCESS_PATIENTS_ACTION = 'PROCESS_PATIENTS_ACTION';

const getters: GetterTree<State, { entities: unknown }> = {
  getPatientByPhoneNumber: () => phoneNumber => api().getByPhoneNumber(phoneNumber),
  getPatientById: () => id => api().get(id),
};

const actions: ActionTree<State, unknown> = {
  async [PROCESS_PATIENT_ACTION]({ commit }, patient) {
    const { entities } = patientMapper.normalizeSingle(patient);

    commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });
  },
  async [PROCESS_PATIENTS_ACTION]({ commit }, patients: InputPatient[]) {
    const { entities } = patientMapper.normalizeMany(patients);

    commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });
  },
};

export const patient = {
  state,
  getters,
  mutations,
  actions,
};
