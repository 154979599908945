import type { IsoDate } from '../time/IsoDate';
import { OwnerId } from '../owner/OwnerId';
import type { Call } from './types/Call';

export class NullCall implements Call {
  readonly id = '';

  readonly ownerId = OwnerId.empty;

  readonly channelId = '';

  readonly channelName = '';

  readonly voiceMessageUrl = '';

  readonly workstationId = '';

  readonly duration = 0;

  readonly isInProgress = false;

  readonly isUnanswered = false;

  readonly isWaiting = false;

  readonly isDuringIntro = false;

  readonly hasVoiceMessage = false;

  readonly isOngoingCall = false;

  readonly isSuccessful = false;

  readonly isAbandoned = false;

  readonly isOutgoing = false;

  readonly isIncoming = false;

  readonly hasOwner = false;

  readonly isCompleted = false;

  readonly isLongerThan5Minutes = false;

  readonly hasAssignedWorkstation = false;

  get createdAt(): string {
    throw new Error("It's not allowed to read createdAt date of a null object.");
  }

  get updatedAt(): string {
    throw new Error("It's not allowed to read updatedAt date of a null object.");
  }

  get startWaitingAt(): string {
    throw new Error("It's not allowed to read startWaitingAt date of a null object.");
  }

  get answeredAt(): string {
    throw new Error("It's not allowed to read answeredAt date of a null object.");
  }

  currentDuration(): number {
    return 0;
  }

  isOwnedBy(): boolean {
    return false;
  }

  isMine(): boolean {
    return false;
  }

  displayedDate(): IsoDate {
    throw new Error("You shouldn't display null call displayDate anywhere.");
  }
}
