import type { DpListener } from '@/core/events/DpListener';
import { singleton } from 'tsyringe';
import type { DpEvent } from './DpEvent';

@singleton()
export class DpEventBus {
  private listeners = new Map<string, DpListener[]>();

  public subscribe(eventName: string, listener: DpListener): void {
    const listeners = this.getListenersForEvent(eventName);

    this.listeners.set(eventName, [...listeners, listener]);
  }

  public unsubscribe(eventName: string, listener: DpListener): void {
    const listeners = this.getListenersForEvent(eventName);
    const removedIndex = listeners.indexOf(listener);

    if (removedIndex === -1) {
      return;
    }

    listeners.splice(removedIndex, 1);

    this.listeners.set(eventName, listeners);
  }

  public publish(event: DpEvent): void {
    const listeners = this.getListenersForEvent(event.name);

    listeners.forEach(listener => listener.handle(event));
  }

  private getListenersForEvent(event: string): DpListener[] {
    return this.listeners.get(event) ?? [];
  }
}
