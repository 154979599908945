import type { GtmSupport } from '@gtm-support/vue-gtm';
import { injectable } from 'tsyringe';
import type { Sender } from '../domain/event-subscriber/Sender';
import type { CustomTrackingEvent } from '../domain/event-subscriber/dto/CustomTrackingEvent';

@injectable()
export class GtmSender implements Sender {
  constructor(private readonly gtmPlugin: () => GtmSupport | undefined) {}

  get isActive(): boolean {
    return this.gtmPlugin()?.enabled() ?? false;
  }

  enable(): void {
    this.gtmPlugin()?.enable(true);
  }

  disable(): void {
    this.gtmPlugin()?.enable(false);
  }

  trackEvent(eventPayload: CustomTrackingEvent): void {
    if (!this.isActive) {
      throw new Error("You're trying to send event for disabled gtm sender.");
    }

    this.gtmPlugin()?.trackEvent(eventPayload);
  }
}
