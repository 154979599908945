import { injectable } from 'tsyringe';
import { CurrentUserCallFetcher } from '../services/CurrentUserCallFetcher';
import { Call } from '../models/Call';
import { DeviceManager } from '../webphone/DeviceManager';

@injectable()
export class CallFactory {
  constructor(
    private readonly deviceManager: DeviceManager,
    private readonly currentUserCallFetcher: CurrentUserCallFetcher,
  ) {}

  make(): Call {
    return new Call(this.currentUserCallFetcher.find(), this.deviceManager.activeCall);
  }
}
