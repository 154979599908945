import type { ProblemType } from './ProblemType';

export class CallQualityReport {
  constructor(
    readonly callId: string,
    public problems: ProblemType[],
    public comment: string,
  ) {}

  static empty(): CallQualityReport {
    return new CallQualityReport('', [], '');
  }
}
