import { injectable } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { DpEventBus } from '@/core/events/DpEventBus';
import { FacilityLoadedEvent } from '@/modules/facility/public/events';
import { TaskFeedFetchedEvent } from '@/app/task/task-fetch/TaskFeedFetchedEvent';
import { FacilityChangedListener } from '../../domain/facility/FacilityChangedListener';
import { TaskFeedFetchedListener } from '../../domain/booking/TaskFeedFetchedListener';

@injectable()
export class EventProvider implements DpProvider {
  constructor(
    private readonly eventBus: DpEventBus,
    private readonly facilityChangedListener: FacilityChangedListener,
    private readonly taskFeedFetchedListener: TaskFeedFetchedListener,
  ) {}

  boot(): void {
    this.eventBus.subscribe(FacilityLoadedEvent.eventName, this.facilityChangedListener);
    this.eventBus.subscribe(new TaskFeedFetchedEvent().name, this.taskFeedFetchedListener);
  }
}
