import { TaskAutomationStatus } from '../../domain/task-automation/TaskAutomationStatus';

interface InputStatus {
  readonly enabled: boolean;
  readonly termsAccepted: boolean;
}

export class HttpTaskAutomationStatusFactory {
  static make(data: InputStatus): TaskAutomationStatus {
    return new TaskAutomationStatus(data.enabled, data.termsAccepted);
  }
}
