import { inject, injectable } from 'tsyringe';
import { RootStoreToken } from '@/common/root';
import { Store } from 'vuex';
import type { CountryCodeProvider } from '@/modules/book-visit/domain/service/CountryCodeProvider';

interface FacilityCountryCodeStore {
  getters: {
    getUserFacility: {
      country_code: 'PL' | 'ES' | 'IT' | 'BR' | 'MX';
    };
  };
}

@injectable()
export class StoreCountryCodeProvider implements CountryCodeProvider {
  constructor(@inject(RootStoreToken) private readonly store: Store<FacilityCountryCodeStore>) {}

  get(): string {
    return this.store.getters.getUserFacility?.country_code;
  }
}
