import { inject, injectable } from 'tsyringe';
import { UserRepositoryToken, WorkstationRepositoryToken } from '../../di/tokens';
import type { Workstation } from '../models/Workstation';
import type { UserRepository } from '../repositories/UserRepository';
import type { WorkstationRepository } from '../repositories/WorkstationRepository';

@injectable()
export class WorkstationFetcher {
  constructor(
    @inject(WorkstationRepositoryToken)
    private readonly workstationRepository: WorkstationRepository,
    @inject(UserRepositoryToken)
    private readonly userRepository: UserRepository,
  ) {}

  getOthers(): Workstation[] {
    const user = this.getUser();

    return this.workstationRepository.all().filter(workstation => workstation.user?.id !== user.id);
  }

  getMine(): Workstation | null {
    const user = this.getUser();

    return (
      this.workstationRepository.all().find(workstation => workstation.user?.id === user.id) ?? null
    );
  }

  private getUser() {
    return this.userRepository.getCurrentUser();
  }
}
