import { OwnerId } from '@/modules/task/domain/owner/OwnerId';

export class Workstation {
  constructor(
    readonly id: string,
    readonly type: 'mobile' | 'web' | 'sip' | 'pstn' | 'never',
    readonly name: string,
    readonly assignedUserId = OwnerId.empty,
  ) {}

  hasAssignedUser(userId: OwnerId): boolean {
    return this.assignedUserId.isEqual(userId);
  }

  get isEmpty(): boolean {
    return this.id === '';
  }

  get isWebPhone(): boolean {
    return !this.isEmpty && this.type === 'web';
  }
}
