import { injectable } from 'tsyringe';
import type { DpListener } from '@/core/events/DpListener';
import type { TaskUpdatedEvent } from '../events/TaskUpdatedEvent';
import { OutboundCallsTracker } from './OutboundCallsTracker';

@injectable()
export class TaskUpdatedListener implements DpListener {
  constructor(private readonly outboundCallsTracker: OutboundCallsTracker) {}

  handle({ taskPayload }: TaskUpdatedEvent): void {
    if (!taskPayload) {
      return;
    }

    this.outboundCallsTracker.track(taskPayload.id, taskPayload.type);
  }
}
