import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { Authorization } from './types/Authorization';

@injectable()
export class HttpAuthorizationRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async auth(channelName: string, socketId: string): Promise<Authorization> {
    return this.httpClient.post<Authorization>('/api/v0/pusher/auth', {
      channel_name: channelName,
      socket_id: socketId,
    });
  }
}
