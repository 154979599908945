import type { StoreMessage } from '@/modules/timeline/adapters/timeline/store/message/StoreMessage';
import type { StoreConversation } from '@/modules/timeline/adapters/timeline/store/message/StoreConversation';
import type { WhatsAppMessage } from '@/modules/timeline/domain/message-composer/message/WhatsAppMessage';
import { TimelineError } from '@/modules/timeline/domain/timeline/TimelineError';
import type { SmsMessage } from '@/modules/timeline/domain/message-composer/message/SmsMessage';
import type { DpChatMessage } from '@/modules/timeline/domain/message-composer/message/DpChatMessage';
import { injectable } from 'tsyringe';
import { StoreWhatsAppMessageFactory } from './StoreWhatsAppMessageFactory';
import { StoreSmsMessageFactory } from './StoreSmsMessageFactory';
import { StoreDpChatMessageFactory } from './StoreDpChatMessageFactory';

@injectable()
export class StoreMessageFactory {
  constructor(
    private readonly whatsAppMessageFactory: StoreWhatsAppMessageFactory,
    private readonly smsMessageFactory: StoreSmsMessageFactory,
    private readonly dpChatMessageFactory: StoreDpChatMessageFactory,
  ) {}

  makeMany(
    messages: StoreMessage[],
    conversation: StoreConversation,
    timezone: string,
  ): SmsMessage[] | WhatsAppMessage[] | DpChatMessage[] {
    if (conversation.channel_type === 'whatsapp') {
      return this.whatsAppMessageFactory.makeMany(messages, timezone);
    }

    if (conversation.channel_type === 'sms') {
      return this.smsMessageFactory.makeMany(messages, timezone);
    }

    if (conversation.channel_type === 'dp-chat') {
      return this.dpChatMessageFactory.makeMany(messages, timezone);
    }

    throw new TimelineError('Unsupported channel type');
  }
}
