<template>
  <div :class="$b()">
    {{ formattedDate }}
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';

import { Date } from '@/ui/atoms';
import type { IsoDate } from '@/ui/types';

@Component({
  name: 'DateSeparator',
  components: {
    Date,
  },
})
export default class DateSeparator extends Vue {
  @Prop({ type: Object, required: true })
  readonly date: IsoDate;

  get formattedDate(): string {
    return this.$dateFormatter.dateFromIso(this.date.isoDate, this.date.timezone).toDefaultDate();
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;
.dp-date-separator {
  display: block;
  height: 2em;
  overflow: hidden;
  color: v.$gray-600;
  font-size: v.$font-size-sm;
  text-align: center;
  text-transform: capitalize;
}
</style>
