import type {
  CommonChannelConfiguration,
  PiiChannelConfiguration,
} from '../domain/models/ChannelConfiguration';

export class Configuration {
  constructor(
    readonly appKey: string,
    readonly cluster: string,
    readonly commonChannel: CommonChannelConfiguration,
    readonly piiChannel: PiiChannelConfiguration,
  ) {}

  get isEmpty(): boolean {
    return this.appKey === '' || this.commonChannel.id === '' || this.piiChannel.id === '';
  }

  static empty(): Configuration {
    return new Configuration('', '', { id: '', name: 'common' }, { id: '', name: 'pii' });
  }
}
