export class LinkValidator {
  static validate(url: string): boolean {
    try {
      const parsedURL = new URL(url);

      if (!this.testXSS(parsedURL)) {
        return false;
      }

      if (!this.testHostname(parsedURL)) {
        return false;
      }

      return this.testProtocol(parsedURL);
    } catch {
      return false;
    }
  }

  private static testXSS(url: URL): boolean {
    const dangerousPattern = /(javascript:|data:|<script>|<\/script>)/i;
    return !dangerousPattern.test(url.href);
  }

  private static testHostname(url: URL): boolean {
    const hostnamePattern = /\.[a-z]{2,}$/i;
    return hostnamePattern.test(url.hostname);
  }

  private static testProtocol(url: URL): boolean {
    return ['http:', 'https:'].includes(url.protocol);
  }
}
