export const WorkstationStateToken = Symbol('WorkstationState');
export const ConnectionStateToken = Symbol('ConnectionState');
export const PatientRepositoryToken = Symbol('PatientRepository');
export const EventTrackerToken = Symbol('EventTracker');
export const TranslatorToken = Symbol('Translator');
export const ConnectionRepositoryToken = Symbol('ConnectionRepository');
export const PanelControllerToken = Symbol('PanelController');
export const WorkstationRepositoryToken = Symbol('WorkstationRepository');
export const UserRepositoryToken = Symbol('UserRepository');
export const UserStatusRepositoryToken = Symbol('UserStatusRepository');
export const TaskCallRepositoryToken = Symbol('TaskCallRepository');
export const ConnectionIdentifierLabelFetcherToken = Symbol('ConnectionIdentifierLabelFetcher');

export const WorkstationAssignerToken = Symbol('WorkstationAssigner');
export const ClockToken = Symbol('Clock');
export const CallingDeviceFactoryToken = Symbol('CallingDeviceFactory');
export const PhoneApiCallManagerToken = Symbol('PhoneApiCallManager');
export const StateFetcherToken = Symbol('StateFetcher');
export const CallDurationToken = Symbol('CallDuration');
export const TaskRepositoryToken = Symbol('TaskRepository');
export const TokenRepositoryToken = Symbol('TokenRepository');
export const BrowserSupportValidatorToken = Symbol('BrowserSupportValidator');
export const DeviceErrorRepositoryToken = Symbol('DeviceErrorRepository');
export const CallErrorRepositoryToken = Symbol('CallErrorRepository');
export const ErrorLoggerToken = Symbol('ErrorLogger');
export const AudioValidatorToken = Symbol('AudioValidator');
export const EventPublisherToken = Symbol('EventPublisher');
export const AuthorizerToken = Symbol('Authorizer');
