export enum ConferenceParticipantStatus {
  Invited = 'invited',
  InConference = 'in_conference',
  OutOfConference = 'out_of_conference',
}

export enum ConferenceParticipantType {
  Patient = 'patient',
  Reception = 'reception',
  Target = 'target',
}

export interface Conference {
  patient: { status: ConferenceParticipantStatus; on_hold: boolean };
  reception: { status: ConferenceParticipantStatus };
  target: { status: ConferenceParticipantStatus; workstation_id: string };
}
