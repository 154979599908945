import { RootStoreToken } from '@/common/root';
import { $GET_ENTITIES_BY_TYPE } from '@dp-vue/entities';
import { inject, injectable } from 'tsyringe';
import { phoneNumberFormatter } from '@/utils/phone-number-formatter';
import { Task } from '../../domain/models/Task';
import type { TaskRepository } from '../../domain/repositories/TaskRepository';
import type { InputTask } from '../types/InputTask';

interface Store {
  getters: {
    [$GET_ENTITIES_BY_TYPE]: (type: 'task') => InputTask[];
  };
}

@injectable()
export class StoreTaskRepository implements TaskRepository {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store,
  ) {}

  find(taskId: string): Task {
    const inputTask = this.store.getters[$GET_ENTITIES_BY_TYPE]('task').find(
      task => task.id === taskId,
    );

    if (!inputTask) {
      return Task.empty();
    }

    return new Task(inputTask.id, inputTask.patientId, phoneNumberFormatter(inputTask.caller));
  }
}
