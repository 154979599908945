import type { ConnectionManager } from 'pusher-js';
import type { Connection } from '../../domain/models/Connection';

export class PusherConnection implements Connection {
  constructor(private readonly connection: ConnectionManager) {}

  bind(eventName: string, callback: (...args: any[]) => any): void {
    this.connection.bind(eventName, callback);
  }
}
