import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { MediaRepository } from '@/modules/timeline/domain/message-composer/message/media/MediaRepository';
import type { Media } from '@/modules/timeline/domain/message-composer/message/media/Media';
import type { HttpMedia } from './HttpMedia';
import { MediaFactory } from './MediaFactory';

@injectable()
export class HttpMediaRepository implements MediaRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly mediaMapper: MediaFactory,
  ) {}

  async uploadFile(fileUrl: string): Promise<Media> {
    const data: HttpMedia = await this.httpClient.put(`/api/v0/facility/{facilityId}/upload`, {
      url: fileUrl,
      storage: 'regular',
    });

    return this.mediaMapper.make(data);
  }
}
