import { Media } from '@/api/models/Media';
import type { UrlAddress } from '@/api/types';
import type { UploadMediaFileRepository } from '../types/UploadMediaFileRepository';
import { BaseAxiosRepository } from './BaseAxiosRepository';

enum StorageType {
  Public = 'public',
  Regular = 'regular',
  Sensitive = 'sensitive',
}

export class AxiosUploadMediaFileRepository
  extends BaseAxiosRepository
  implements UploadMediaFileRepository
{
  async uploadPublicFile(facilityId: string, fileUrl: UrlAddress): Promise<Media> {
    return this.uploadFile(facilityId, fileUrl, StorageType.Public);
  }

  async uploadRegularFile(facilityId: string, fileUrl: UrlAddress): Promise<Media> {
    return this.uploadFile(facilityId, fileUrl, StorageType.Regular);
  }

  async uploadSensitiveFile(facilityId: string, fileUrl: UrlAddress): Promise<Media> {
    return this.uploadFile(facilityId, fileUrl, StorageType.Sensitive);
  }

  private async uploadFile(facilityId: string, fileUrl: UrlAddress, storageType: StorageType) {
    const data = (await this.axiosClient.put(`/api/v0/facility/${facilityId}/upload`, {
      url: fileUrl,
      storage: storageType,
    })) as Media;

    return Media.make(data);
  }
}
