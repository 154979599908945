import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { StoreRegulatoryRepository } from '@/modules/settings/adapters/StoreRegulatoryRepository';
import { injectable } from 'tsyringe';
import { StoreTermsUrlFactory } from '@/modules/settings/adapters/StoreTermsUrlFactory';
import { notificationsManager } from '@/app/notifications/NotificationsManager';
import { RootTranslatorToken } from '@/common/root';
import {
  DateFormatterToken,
  FacilityNameRepositoryToken,
  NotificationManagerToken,
} from '../token';
import { DateFnsDateFormatter } from '../../adapters/DateFnsDateFormatter';
import type { DateFormatter } from '../../domain/DateFormatter';
import type { RegulatoryRepository } from '../../domain/gates/RegulatoryRepository';
import type { FacilityNameRepository } from '../../domain/FacilityNameRepository';
import { StoreFacilityNameRepository } from '../../adapters/StoreFacilityNameRepository';
import type { NotificationManager } from '../../domain/NotificationManager';
import { UiNotificationManager } from '../../adapters/UiNotificationManager';

export const RegulatoryRepositoryToken = Symbol('RegulatoryRepository');
export const TermsUrlFactoryToken = Symbol('TermsUrlFactory');

@injectable()
export class AdapterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<RegulatoryRepository>(RegulatoryRepositoryToken, StoreRegulatoryRepository);
    container.register(TermsUrlFactoryToken, StoreTermsUrlFactory);
    container.register<DateFormatter>(DateFormatterToken, DateFnsDateFormatter);
    container.register<FacilityNameRepository>(
      FacilityNameRepositoryToken,
      StoreFacilityNameRepository,
    );
    container.register<NotificationManager>(NotificationManagerToken, {
      useFactory(dependencyContainer: DependencyContainer) {
        return new UiNotificationManager(
          notificationsManager,
          dependencyContainer.resolve(RootTranslatorToken),
        );
      },
    });
  }
}
