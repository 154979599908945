import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { HttpAccessTokenRequester } from '@/modules/authentication/adapters/oauth/HttpAccessTokenRequester';
import { LocalStorageAccessTokenRepository } from '@/modules/authentication/adapters/oauth/LocalStorageAccessTokenRepository';
import { AppConfigClientCredentialsRepository } from '@/modules/authentication/adapters/oauth/AppConfigClientCredentialsRepository';
import {
  AccessTokenRequesterToken,
  ClientCredentialsRepositoryToken,
  AccessTokenRepositoryToken,
} from '@/modules/authentication/di/token';
import { AnonymousHttpClientApi, HttpClientApi } from '@/modules/http-client/public/api';
import { UserApiTokenInterception } from '@/modules/authentication/domain/oauth/interception/UserApiTokenInterception';
import { AppApiTokenInterception } from '@/modules/authentication/domain/oauth/interception/AppApiTokenInterception';

export class OAuthProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register(AccessTokenRequesterToken, HttpAccessTokenRequester);
    container.register(AccessTokenRepositoryToken, LocalStorageAccessTokenRepository);
    container.register(ClientCredentialsRepositoryToken, AppConfigClientCredentialsRepository);
  }

  boot(container: DependencyContainer): void {
    this.addAppInterceptor(container);
    this.addUserApiInterceptor(container);
  }

  private addAppInterceptor(container: DependencyContainer) {
    const httpClient = container.resolve(AnonymousHttpClientApi);
    const appApiTokenInterception = container.resolve(AppApiTokenInterception);

    httpClient.addInterceptor(appApiTokenInterception);
  }

  private addUserApiInterceptor(container: DependencyContainer) {
    const httpClient = container.resolve(HttpClientApi);
    const userApiTokenInterception = container.resolve(UserApiTokenInterception);

    httpClient.addInterceptor(userApiTokenInterception);
  }
}
