import { HttpClientApi } from '@/modules/http-client/public/api';

import { injectable } from 'tsyringe';
import type { CallRecordingConfigurationResponse } from './types/CallRecordingConfigurationResponse';
import { CallRecordingConfiguration } from '../domain/CallRecordingConfiguration';
import type { CallRecordingConfigurationRepository } from '../domain/repositories/CallRecordingConfigurationRepository';

@injectable()
export class HttpCallRecordingConfigurationRepository
  implements CallRecordingConfigurationRepository
{
  constructor(private readonly httpClient: HttpClientApi) {}

  public async save(enabled: boolean): Promise<CallRecordingConfiguration> {
    return enabled ? this.enable() : this.disable();
  }

  public async get(): Promise<CallRecordingConfiguration> {
    const response: CallRecordingConfigurationResponse = await this.httpClient.get(
      `/api/v0/facility/{facilityId}/settings/recordings`,
    );

    return new CallRecordingConfiguration(response.enabled);
  }

  private async enable(): Promise<CallRecordingConfiguration> {
    const response: CallRecordingConfigurationResponse = await this.httpClient.put(
      `/api/v0/facility/{facilityId}/settings/recordings`,
      {},
    );
    return new CallRecordingConfiguration(response.enabled);
  }

  private async disable(): Promise<CallRecordingConfiguration> {
    const response: CallRecordingConfigurationResponse = await this.httpClient.delete(
      `/api/v0/facility/{facilityId}/settings/recordings`,
    );
    return new CallRecordingConfiguration(response.enabled);
  }
}
