import { inject, injectable } from 'tsyringe';
import type { IconStrategy } from '../types';
import type { Task } from '../../task/Task';
import { IconName } from '../types';
import { VisitConfirmationRepositoryToken } from '../../../di/tokens';
import type { VisitConfirmationRepository } from '../../visit/VisitConfirmationRepository';
import type { Timeline } from '../../timeline/Timeline';

@injectable()
export class VisitConfirmation implements IconStrategy {
  constructor(
    @inject(VisitConfirmationRepositoryToken)
    private readonly visitConfirmationRepository: VisitConfirmationRepository,
  ) {}

  get(task: Task, timeline: Timeline): null | IconName {
    return this.visitConfirmationRepository.existsFor(task) && timeline.isEmpty
      ? IconName.VisitConfirmation
      : null;
  }
}
