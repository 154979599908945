import { injectable } from 'tsyringe';
import type { QualityOfServiceDataRepository } from '@/modules/statistics/domain/quality-of-service/QualityOfServiceDataRepository';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { QueryParams } from '@/modules/statistics/domain/QueryParams';
import type { QualityOfServiceData } from '@/modules/statistics/domain/quality-of-service/QualityOfServiceData';

@injectable()
export class HttpQualityOfServiceDataRepository implements QualityOfServiceDataRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  get(queryParams: QueryParams): Promise<QualityOfServiceData[]> {
    return this.httpClient.get('api/v0/facility/{facilityId}/stats/quality-of-service', {
      ...queryParams,
    });
  }
}
