import { schema } from 'normalizr';
import { formSchema } from '@/api/schemas/form-schema';
import { EntityTypes } from '@/api/types/entities';

/**
 * @typedef {Object} Form
 * @property {string} id duplicated task id
 * @property {string} content
 * @property {string} email
 * @property {string} name
 * @property {string} surname
 * @property {Form} form
 */
export const formMessageSchema = new schema.Entity(
  EntityTypes.FormMessage,
  {
    form: formSchema,
  },
  {
    idAttribute(_, { id }) {
      // copy task.id as form message id.
      return id;
    },
    processStrategy(value, { id }) {
      return {
        id,
        ...value,
      };
    },
  },
);
