export class Service {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly addressId: string,
    readonly price: number,
  ) {}

  get isEmpty(): boolean {
    return this.id === '';
  }

  equals(service: Service): boolean {
    if (this.isEmpty) {
      return false;
    }

    return this.id === service.id;
  }

  belongsTo(addressId: string): boolean {
    if (this.isEmpty || this.addressId === '') {
      return false;
    }

    return this.addressId === addressId;
  }

  static makeEmpty(): Service {
    return new Service('', '', '', 0);
  }
}
