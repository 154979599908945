import { singleton } from 'tsyringe';
import { CurrentUserCallFetcher } from '../../services/CurrentUserCallFetcher';
import { WorkstationFetcher } from '../../services/WorkstationFetcher';
import { ActiveCallStateRepository } from '../state/ActiveCallStateRepository';
import { DeviceStateRepository } from '../state/DeviceStateRepository';

@singleton()
export class ContextRepository {
  private deviceSessionStartTime: number | null = null;

  private lastTokenDownloadedAt: number | null = null;

  constructor(
    private readonly currentUserCallFetcher: CurrentUserCallFetcher,
    private readonly workstationFetcher: WorkstationFetcher,
    private readonly activeCallStateRepository: ActiveCallStateRepository,
    private readonly deviceStateRepository: DeviceStateRepository,
  ) {}

  startDeviceSession(): void {
    this.deviceSessionStartTime = Date.now();
  }

  setNewTokenTime(): void {
    this.lastTokenDownloadedAt = Date.now();
  }

  get(): Record<string, Record<string, any>> {
    return {
      task: this.getTaskContext(),
      workstation: this.getWorkstationContext(),
      device: {
        deviceSessionTime: this.getDistanceInSecondsOrNull(this.deviceSessionStartTime),
        tokenAge: this.getDistanceInSecondsOrNull(this.lastTokenDownloadedAt),
        deviceState: this.deviceStateRepository.get() ?? null,
      },
      'device call': this.getActiveCallContext(),
    };
  }

  clear(): void {
    this.deviceSessionStartTime = null;
    this.lastTokenDownloadedAt = null;
  }

  private getWorkstationContext(): Record<string, any> {
    const workstation = this.workstationFetcher.getMine();
    if (!workstation) {
      return {};
    }

    return {
      workstationId: workstation.id,
      workstationMode: workstation.mode,
      workstationType: workstation.type,
      workstationUser: workstation.user?.id ?? null,
    };
  }

  private getActiveCallContext(): Record<string, any> {
    const activeCall = this.activeCallStateRepository.get();

    if (!activeCall) {
      return {};
    }

    return {
      activeCallStatus: activeCall.status,
      codec: activeCall.call?.codec ?? undefined,
      callParams: activeCall.call?.parameters,
    };
  }

  private getTaskContext(): Record<string, any> {
    const taskCall = this.currentUserCallFetcher.find();
    if (!taskCall.id) {
      return {};
    }

    return {
      callSid: taskCall.sid,
      taskId: taskCall.id,
    };
  }

  private getDistanceInSecondsOrNull(startTime: number | null): number | null {
    if (!startTime) {
      return null;
    }

    return (Date.now() - startTime) / 1000;
  }
}
