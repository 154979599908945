<template>
  <span :class="$b()">
    {{ valueString }}
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

@Component({
  name: 'CounterBadge',
})
export default class CounterBadge extends Vue {
  @Prop({ type: Number, required: true })
  readonly value: number;

  @Prop({ type: Number, default: 199 })
  readonly maxValue: number;

  get valueString(): string {
    return this.value > this.maxValue ? `${this.maxValue}+` : `${this.value}`;
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-counter-badge {
  display: inline-block;
  vertical-align: text-bottom;
  padding: (v.$spacer-xs * 0.5) v.$spacer-xs;
  border-radius: v.$spacer-md;
  font-size: v.$font-size-sm;
  font-weight: v.$font-weight-medium;
  line-height: 1.1666; // ¯\_(ツ)_/¯
  background-color: v.$gray-300;
  color: v.$black;
}
</style>
