import type { CallingDevice } from './CallingDevice';
import type { Clock } from './Clock';
import type { ConnectOptions } from './ConnectOptions';
import { DeviceState } from '../models/DeviceState';
import type { InputCall } from '../models/dto/InputCall';
import { PhoneWidgetError } from '../PhoneWidgetError';
import type { RefreshingOptions } from './RefreshingOptions';
import type { TokenGenerator } from './TokenGenerator';
import type { ContextRepository } from '../errors/ContextRepository';

export class RefreshableCallingDevice implements CallingDevice {
  constructor(
    private readonly callingDevice: CallingDevice,
    private readonly clock: Clock,
    private readonly tokenGenerator: TokenGenerator,
    private readonly refreshingOptions: RefreshingOptions,
    private readonly contextRepository: ContextRepository,
  ) {
    this.clock.start(async () => {
      await this.setNewToken();
    }, this.refreshingOptions.refreshInterval);
  }

  get state(): DeviceState {
    return this.mapState(this.callingDevice.state);
  }

  get isBusy(): boolean {
    return this.callingDevice.isBusy;
  }

  async connect(options: ConnectOptions): Promise<InputCall> {
    return this.callingDevice.connect(options);
  }

  destroy(): void {
    this.clock.stop();
    this.callingDevice.destroy();
  }

  addListener(eventName: string, listener: (...args: any[]) => void): void {
    this.callingDevice.addListener(eventName, listener);
  }

  removeListener(eventName: string, listener: (...args: any[]) => void): void {
    this.callingDevice.removeListener(eventName, listener);
  }

  async register(): Promise<void> {
    await this.callingDevice.register();
  }

  updateToken(): void {
    throw new PhoneWidgetError("You shouldn't update token manually.");
  }

  private async setNewToken(): Promise<void> {
    const token = await this.tokenGenerator.get(this.refreshingOptions.tokenLifetime);
    this.callingDevice.updateToken(token);
    this.contextRepository.setNewTokenTime();
  }

  private mapState(state: string): DeviceState {
    return (
      {
        unregistered: DeviceState.Unregistered,
        registering: DeviceState.Registering,
        registered: DeviceState.Registered,
        destroyed: DeviceState.Destroyed,
      }[state] ?? DeviceState.Unregistered
    );
  }
}
