import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { AnonymizationManager } from '../../domain/data-anonymization/AnonymizationManager';
import { AnonymizationManagerToken } from '../token';
import { AnonymizationAnonymizationManager } from '../../adapters/data-anonymization/AnonymizationAnonymizationManager';

@injectable()
export class AnonymizationProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<AnonymizationManager>(
      AnonymizationManagerToken,
      AnonymizationAnonymizationManager,
    );
  }
}
