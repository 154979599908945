import type { FacilityRepository } from '@/api/repositories/types/FacilityRepository';
import type { TaskId } from '@/api/types/entities/task';
import type { FacilityCreatePayload, FacilitySearchPayload } from '@/api/types/request/facility';
import { BaseAxiosRepository } from '@/api/repositories/axios/BaseAxiosRepository';
import type { FacilityId } from '@/api/models/Facility';
import { Facility } from '@/api/models/Facility';
import type { Facility as InputFacility } from '@/api/types/entities/facility';

export class AxiosFacilityRepository extends BaseAxiosRepository implements FacilityRepository {
  async get(id: FacilityId): Promise<Facility> {
    const data = (await this.axiosClient.get(`/api/v0/facility/${id}`)) as InputFacility;

    return Facility.make(data);
  }

  async getByTaskId(id: TaskId): Promise<Facility> {
    const data = (await this.axiosClient.get(`/api/v0/task/${id}/facility`)) as InputFacility;

    return Facility.make(data);
  }

  async searchByPhrase({ phrase }: FacilitySearchPayload): Promise<Facility[]> {
    const data = (await this.axiosClient.get('/api/v0/search-facilities', {
      phrase,
    })) as InputFacility[];

    return data.map(Facility.make);
  }

  async create(payload: FacilityCreatePayload): Promise<Facility> {
    const { id } = (await this.axiosClient.post(`/api/v1/facilities`, payload)) as { id: string };

    return this.get(id);
  }

  async selectPhone(
    facilityId: string,
    phoneId: string | null,
    replaceId: string | null,
  ): Promise<Facility> {
    const data = (await this.axiosClient.post(`/api/v0/facility/${facilityId}/reception`, {
      phoneId,
      replaceId,
    })) as InputFacility;

    return Facility.make(data);
  }
}
