import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { SettingsOutboundTaskSwitcherRepository } from '../../adapters/outbound-task/SettingsOutboundTaskSwitcherRepository';
import type { OutboundTaskSwitcherRepository } from '../../domain/outbound-task/OutboundTaskSwitcherRepository';
import { OutboundTaskSwitcherRepositoryToken } from '../tokens';

@injectable()
export class OutboundTaskProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<OutboundTaskSwitcherRepository>(
      OutboundTaskSwitcherRepositoryToken,
      SettingsOutboundTaskSwitcherRepository,
    );
  }
}
