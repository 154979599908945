import { inject, injectable } from 'tsyringe';
import { EventPublisherToken } from '@/common/tracking-event/di/tokens';
import type { DurationEventTracker } from './DurationEventTracker';
import { Clock } from './Clock';
import type { EventPublisher } from '../EventPublisher';
import { DurationEvent } from '../../models/DurationEvent';

@injectable()
export class DpBusDurationEventTracker implements DurationEventTracker {
  constructor(
    private readonly clock: Clock,
    @inject(EventPublisherToken)
    private readonly eventPublisher: EventPublisher,
  ) {}

  start(name: string): void {
    this.clock.startTimer(name);
  }

  end(name: string): void {
    const duration = this.clock.endTimer(name);

    if (!duration) {
      return;
    }

    this.eventPublisher.send(new DurationEvent(name, duration));
  }

  isStarted(name: string): boolean {
    return this.clock.hasStartedTimer(name);
  }

  async measure(name: string, callback: () => Promise<void>): Promise<void> {
    this.start(name);

    try {
      await callback();
      this.end(name);
    } catch (e) {
      this.clearTimer(name);
      throw e;
    }
  }

  private clearTimer(name: string): void {
    this.clock.clearTimer(name);
  }
}
