import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';

import type { TextChannelRepository } from '@/modules/settings/domain/channels/TextChannelRepository';
import { TextChannel } from '@/modules/settings/domain/channels/TextChannel';
import type { HttpTextChannel } from '@/modules/settings/adapters/channels/HttpTextChannel';
import { HttpTextChannelFactory } from '@/modules/settings/adapters/channels/HttpTextChannelFactory';

@injectable()
export class HttpTextChannelRepository implements TextChannelRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async get(): Promise<TextChannel[]> {
    const data = await this.httpClient.get<HttpTextChannel[]>(
      '/api/v0/facility/{facilityId}/text-channels',
    );

    return HttpTextChannelFactory.makeMany(data);
  }
}
