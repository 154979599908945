import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { ErrorSenderToken, PresenceRepositoryToken } from '../tokens';
import { SentryErrorSender } from '../../adapters/SentryErrorSender';
import type { ErrorSender } from '../../domain/ErrorSender';
import { UserPresenceRepository } from '../../adapters/UserPresenceRepository';
import type { PresenceRepository } from '../../domain/PresenceRepository';

export class ModuleProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<ErrorSender>(ErrorSenderToken, SentryErrorSender);
    container.register<PresenceRepository>(PresenceRepositoryToken, UserPresenceRepository);
  }
}
