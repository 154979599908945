import type { Workstation as NormalizedStoreWorkstation } from '@/api/types/entities/workstation';
import type { WorkstationRepository } from './WorkstationRepository';
import type { Workstation } from './Workstation';
import type { User } from './User';

interface RootGetters {
  'settings/reception/getReceptionPhones': NormalizedStoreWorkstation[];
}

const first = () => true;

export class StoreWorkstationRepository implements WorkstationRepository {
  constructor(private readonly store: RootGetters) {}

  findByUser(user: User): Workstation | null {
    return (
      this.store['settings/reception/getReceptionPhones']
        .filter(workstation => workstation.current_user?.id === user.id)
        .map(({ id }) => ({ id }))
        .find(first) ?? null
    );
  }
}
