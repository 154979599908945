import type { Workstation } from '@/modules/settings/domain/traffic-control/models/Workstation';
import type { WorkstationRepository } from '@/modules/settings/domain/traffic-control/WorkstationRepository';
import { injectable } from 'tsyringe';
import { workstationRepository } from '@/modules/workstation/public/api';
import { WorkstationFactory } from './WorkstationFactory';

@injectable()
export class WorkstationApiWorkstationRepository implements WorkstationRepository {
  constructor(private readonly workstationFactory: WorkstationFactory) {}

  all(): Workstation[] {
    return workstationRepository()
      .all()
      .map(workstation => this.workstationFactory.make(workstation));
  }

  get(ids: string[]): Workstation[] {
    return this.all().filter(workstation => ids.includes(workstation.id));
  }
}
