import type { ContactForm as DomainTimelineContactForm } from '@/modules/timeline/domain/contact-form/ContactForm';
import { TimelineContactForm } from '@/modules/timeline/public/api/data-transfer/contact-form/TimelineContactForm';

export class OutputTimelineContactFormFactory {
  make(timelineContactForm: DomainTimelineContactForm): TimelineContactForm {
    return new TimelineContactForm(
      timelineContactForm.id,
      timelineContactForm.createdAt.isoDate,
      timelineContactForm.channelId,
    );
  }
}
