<template>
  <dp-badge v-dpp-tooltip="tooltipOptions" type="info">
    {{ $ts('ai.assistant.label') }}
  </dp-badge>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator';
import { DpBadge } from '@dp-ui-kit/vue';

import { dppTooltip } from '@/ui/directives/dpp-tooltip';

@Component({
  name: 'AssistantBadge',
  components: {
    DpBadge,
  },
  directives: {
    dppTooltip,
  },
})
export default class AssistantBadge extends Vue {
  get tooltipOptions() {
    return {
      placement: 'top',
      content: this.$ts('ai.assistant.tooltip'),
    };
  }
}
</script>
