import type { EventSender } from './EventSender';

type LinkType = 'Help center' | 'Chat' | 'News' | 'Feedback';

export class ItemSelectedEvent {
  static make(linkType: LinkType): ItemSelectedEvent {
    if (!(['Help center', 'Chat', 'News', 'Feedback'] as const).includes(linkType)) {
      throw new Error(`Invalid link type: ${linkType}`);
    }

    return new ItemSelectedEvent(linkType);
  }

  private readonly businessEventName = 'Help item selected';

  private constructor(private readonly linkType: LinkType) {}

  async track(eventSender: EventSender): Promise<void> {
    eventSender.send(this.businessEventName, { 'Help item name': this.linkType });
  }
}
