import { RootStoreToken } from '@/common/root';
import { inject, injectable } from 'tsyringe';
import type { Store } from 'vuex';
import type { PhoneApiCallManager } from '../../domain/phone-api/PhoneApiCallManager';

@injectable()
export class StorePhoneApiCallManager implements PhoneApiCallManager {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store<unknown>,
  ) {}

  async inviteToConference(workstationId: string): Promise<void> {
    await this.store.dispatch('phoneWidget/conference/INVITE_TO_CONFERENCE_ACTION', workstationId);
  }

  async conferenceRemoveReception(): Promise<void> {
    await this.store.dispatch('phoneWidget/conference/REMOVE_RECEPTION_FROM_CONFERENCE_ACTION');
  }

  async conferenceRemoveTarget(): Promise<void> {
    await this.store.dispatch('phoneWidget/conference/REMOVE_TARGET_FROM_CONFERENCE_ACTION');
  }

  async hold(): Promise<void> {
    await this.store.dispatch('phoneWidget/conference/HOLD_PATIENT_CALL_ACTION');
  }

  async unhold(): Promise<void> {
    await this.store.dispatch('phoneWidget/conference/UNHOLD_PATIENT_CALL_ACTION');
  }
}
