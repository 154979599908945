<template>
  <dl :class="$b()">
    <div v-for="{ hint, label, value } in items" :key="label" :class="$b('item')">
      <dt :class="$b('label')">
        {{ label }}
      </dt>
      <dd :class="$b('description')">
        {{ value }}
        <small v-if="hint" :class="$b('hint')">
          <dp-icon-info :class="$b('hint-icon')" color="gray-300" :size="12" />
          {{ hint }}
        </small>
      </dd>
    </div>
  </dl>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { DpIconInfo } from '@dp-ui-kit/icons';

import type { SettingsListItem } from './SettingsList.types';

@Component({
  name: 'SettingsList',
  components: {
    DpIconInfo,
  },
})
export default class SettingsList extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  readonly items: SettingsListItem[];
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

$item-border: 1px solid v.$gray-300;

.dp-settings-list {
  &__item {
    padding: v.$spacer-md 0;
    border-top: $item-border;

    &:last-child {
      border-bottom: $item-border;
    }
  }

  &__label {
    color: v.$gray-900;
    font-size: v.$h6-font-size;
    font-weight: v.$font-weight-medium;
  }

  &__description {
    color: v.$gray-600;
  }

  &__hint {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    column-gap: v.$spacer-xs;
    margin-top: v.$spacer-sm;
    font-size: v.$font-size-sm;
  }
}
</style>
