import type { DependencyContainer } from 'tsyringe';
import { injectable } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { SubjectModalToken, SubjectRepositoryToken } from '../tokens';
import type { SubjectModal } from '../../domain/subject/SubjectModal';
import { SubjectModalAdapter } from '../../adapters/subject/SubjectModalAdapter';
import type { SubjectRepository } from '../../domain/subject/SubjectRepository';
import { StoreSubjectRepository } from '../../adapters/subject/StoreSubjectRepository';

@injectable()
export class SubjectProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<SubjectModal>(SubjectModalToken, SubjectModalAdapter);
    container.register<SubjectRepository>(SubjectRepositoryToken, StoreSubjectRepository);
  }
}
