import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { ResourceLoaderToken } from '../tokens';
import type { ResourceLoader } from '../../domain/help-center/ResourceLoader';
import { WindowResourceLoader } from '../../adapters/help-center/WindowResourceLoader';

export class HelpCenterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<ResourceLoader>(ResourceLoaderToken, WindowResourceLoader);
  }
}
