<template>
  <li :class="$b({ active })" @click="$emit('click')">
    <div :class="$b('content')">
      <slot />
    </div>

    <slot name="right">
      <dp-icon-caret-right :size="24" color="primary" :class="$b('caret')" />
    </slot>
  </li>
</template>

<script lang="ts">
import { DpIconCaretRight } from '@dp-ui-kit/icons';
import { Vue, Component, Prop } from 'vue-facing-decorator';

@Component({
  name: 'SelectListItem',
  emits: ['click'],

  components: {
    DpIconCaretRight,
  },
})
export default class SelectListItem extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly active: boolean;
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables' as v;
@use '@/assets/scss/functions/px-to-rem' as f;

.dp-select-list-item {
  $this: &;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-top: -1px;
  transition: all 0.3s;
  padding: v.$spacer-sm;
  min-height: f.px-to-rem(58px);
  border: 1px solid v.$gray-300;

  &--active {
    background-color: v.$gray-200;
  }

  &__content {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__caret {
    margin-right: v.$spacer-sm;
    min-width: 9px;
    transition: all 0.3s;
  }

  &:first-child {
    border-top-left-radius: v.$border-radius-lg;
    border-top-right-radius: v.$border-radius-lg;
  }

  &:last-child {
    border-bottom-left-radius: v.$border-radius-lg;
    border-bottom-right-radius: v.$border-radius-lg;
  }

  &:hover {
    box-shadow: inset 0 0 5px 0 rgba(61, 130, 223, 0.1);

    #{$this}__caret {
      transform: translateX(0.5rem);
    }
  }
}
</style>
