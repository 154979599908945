import { injectable } from 'tsyringe';
import { Authorization } from '@/common/authorization/public/api';
import type { Authorizer } from '@/modules/timeline/domain/authorization/Authorizer';

@injectable()
export class AuthorizationApiAuthorizer implements Authorizer {
  constructor(private readonly authorization: Authorization) {}

  can(policyName: string): boolean {
    return this.authorization.can(policyName);
  }
}
