import { DpEventBus } from '@/core/events/DpEventBus';
import { injectable } from 'tsyringe';
import type { TaskUpdatedEvent } from '../../domain/events/TaskUpdatedEvent';
import type { TaskUpdatedEventPublisher } from '../../domain/events/TaskUpdatedEventPublisher';

@injectable()
export class EventBusTaskUpdatedEventPublisher implements TaskUpdatedEventPublisher {
  constructor(private readonly eventBus: DpEventBus) {}

  publish(event: TaskUpdatedEvent): void {
    this.eventBus.publish(event);
  }
}
