import { delay, inject, injectable } from 'tsyringe';
import { PingApi } from '@/modules/user/public/api/PingApi';
import { StaticCommonEventName } from '../../domain/callbacks/StaticCommonEventName';
import type { Callback } from '../../domain/models/Callback';

@injectable()
export class PresenceCallback implements Callback {
  readonly name = 'CommonPresence';

  readonly eventName = StaticCommonEventName.Presence;

  readonly channelName = 'common';

  constructor(
    @inject(delay(() => PingApi))
    private readonly pingApi: PingApi,
  ) {}

  handler = (): void => {
    this.pingApi.start();
  };
}
