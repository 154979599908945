import type { Store } from 'vuex';
import { inject, injectable } from 'tsyringe';
import type { RootState } from '@/store/types';
import type { UserStatusRepository } from '@/modules/user/domain/user-status/UserStatusRepository';
import { UserStatus } from '@/modules/user/domain/user-status/UserStatus';
import type { Status } from '@/modules/user/domain/user-status/Status';
import { userStatus } from '@/app/user-statuses';
import { RootStoreToken } from '@/common/root';
import type { StoreUserStatus } from './types/StoreUserStatus';

@injectable()
export class StoreUserStatusRepository implements UserStatusRepository {
  constructor(@inject(RootStoreToken) private readonly store: Store<RootState>) {}

  async save(status: UserStatus): Promise<void> {
    this.store.commit('SET_USERS_STATUSES', this.makeStoreUserStatus(status));
  }

  async saveMany(userStatuses: Record<string, UserStatus>): Promise<void> {
    const payload = Object.values(userStatuses).reduce(
      (acc, status) => ({ ...acc, ...this.makeStoreUserStatus(status) }),
      {},
    );

    this.store.commit('SET_USERS_STATUSES', payload);
  }

  get(userId: string): UserStatus {
    const data: StoreUserStatus | null = this.store.getters.getUserStatus(userId);
    if (!data) {
      return UserStatus.empty();
    }

    const status = this.getStatus(data.status);

    return new UserStatus(userId, status, data.firstLoginDate, data.endDate);
  }

  private getStatus(status: string): Status {
    switch (status) {
      case userStatus.ONLINE:
        return 'online';
      case userStatus.OFFLINE:
        return 'offline';
      case userStatus.PAUSE:
        return 'break';
      default:
        throw new Error(`Unknown status from API: ${status}`);
    }
  }

  private makeStoreUserStatus(data: UserStatus): Record<StoreUserStatus['id'], StoreUserStatus> {
    return {
      [data.id]: {
        firstLoginDate: data.firstLogin,
        id: data.id,
        endDate: data.endDate,
        status: data.status,
      },
    };
  }
}
