import type { TimelineApi } from '@/modules/timeline/public/api';
import type { TimelineCall } from '@/modules/timeline/public/types';
import { CallStatus } from '../call-status';
import type { Call } from './Call';
import type { CallRepository } from './CallRepository';

export class TimelineCallRepository implements CallRepository {
  constructor(private readonly timelineApiGetter: () => TimelineApi) {}

  getByTask(taskId: string): Call[] {
    const calls = this.timelineApiGetter().getCallHistory(taskId);

    return calls.map(this.makeCall, this);
  }

  private makeCall(call: TimelineCall): Call {
    return {
      status: this.makeCallStatus(call.status),
      personnel: call.ownerId ?? undefined,
      workstation: call.workstationId ?? undefined,
    };
  }

  private makeCallStatus(
    status: 'abandoned' | 'completed' | 'in-progress' | 'intro' | 'unanswered' | 'waiting',
  ): CallStatus {
    return {
      abandoned: CallStatus.Abandoned,
      completed: CallStatus.Completed,
      'in-progress': CallStatus.InProgress,
      intro: CallStatus.Intro,
      unanswered: CallStatus.Unanswered,
      waiting: CallStatus.Waiting,
    }[status];
  }
}
