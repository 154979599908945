import { Workstation } from '@/modules/settings/domain/traffic-control/models/Workstation';
import type { Workstation as InputWorkstation } from '@/modules/workstation/public/types';
import { injectable } from 'tsyringe';

@injectable()
export class WorkstationFactory {
  make(workstation: InputWorkstation): Workstation {
    return new Workstation(workstation.id, workstation.name, workstation.type, workstation.mode);
  }
}
