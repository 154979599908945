import { inject, injectable } from 'tsyringe';
import type { PasswordResetDispatcher } from '@/modules/authentication/domain/password-reset/reset/PasswordResetDispatcher';
import type { Locale, LocaleDetector } from '@/modules/authentication/domain/LocaleDetector';
import { LocaleDetectorToken } from '@/modules/authentication/di/token';
import { AnonymousHttpClientApi } from '@/modules/http-client/public/api';
import { AccessToken } from '@/modules/authentication/domain/oauth/token/AccessToken';

interface PasswordResetResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

@injectable()
export class HttpPasswordResetDispatcher implements PasswordResetDispatcher {
  constructor(
    @inject(LocaleDetectorToken)
    private readonly localeDetector: LocaleDetector,
    private readonly httpClient: AnonymousHttpClientApi,
  ) {}

  async dispatch(
    token: string,
    password: string,
    name: string,
    phoneNumber?: string,
  ): Promise<AccessToken> {
    const data = await this.httpClient.post<PasswordResetResponse>(`/auth/reset-password`, {
      token,
      phoneNumber: phoneNumber || null,
      password,
      name,
      locale: this.getLocale(),
    });

    return AccessToken.make(data.access_token, data.refresh_token, data.expires_in);
  }

  private getLocale(): Locale {
    return this.localeDetector.getBasedOnDomain().locale;
  }
}
