import type { MutationTree } from 'vuex';
import type { Settings } from '@/modules/task/domain/settings/settings/Settings';

interface State {
  taskSettings: Settings;
}

const state: State = {
  taskSettings: {
    layout: 'large-timeline',
  },
};

const mutations: MutationTree<State> = {
  SET_SETTING(state: State, { key, value }): void {
    state.taskSettings[key] = value;
  },
};

const getters = {
  getSetting:
    (state: State) =>
    (key: string): string =>
      state.taskSettings[key],
};

export const module = { state, mutations, getters, namespaced: true };

export const moduleName = 'task:settings';
