import type { SettingsStorage } from '@/modules/task/domain/settings/SettingsStorage';
import { inject, injectable } from 'tsyringe';
import type { Settings } from '@/modules/task/domain/settings/settings/Settings';
import { RootStoreToken } from '@/common/root';

interface Store {
  getters: {
    getUser: { id: string };
  };
}

@injectable()
export class LocalSettingsStorage implements SettingsStorage {
  constructor(@inject(RootStoreToken) private readonly store: Store) {}

  private readonly storageKeyPrefix = 'task:settings';

  load<K extends keyof Settings>(key: K): Settings[K] | null {
    return localStorage.getItem(this.getKey(key)) as Settings[K];
  }

  save<K extends keyof Settings>(key: K, value: Settings[K]): void {
    localStorage.setItem(this.getKey(key), value as string);
  }

  private getKey(key) {
    return `${this.storageKeyPrefix}:user-${this.userIdPrefix}:${key}`;
  }

  private get userIdPrefix(): string {
    return this.store.getters.getUser.id;
  }
}
