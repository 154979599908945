import { inject, injectable } from 'tsyringe';
import { EventPublisherToken } from '../../di/tokens';
import { TrackingFacilityEventFactory } from './TrackingFacilityEventFactory';
import type { EventPublisher } from './EventPublisher';

@injectable()
export class TrackingEventDispatcher {
  constructor(
    @inject(EventPublisherToken)
    private readonly eventPublisher: EventPublisher,
    private readonly trackingFacilityEventFactory: TrackingFacilityEventFactory,
  ) {}

  async sendFacilityLoaded(): Promise<void> {
    const trackingEvent = await this.trackingFacilityEventFactory.make();
    this.eventPublisher.send(trackingEvent);
  }

  async sendFacilityUnloaded(): Promise<void> {
    const trackingEvent = await this.trackingFacilityEventFactory.makeEmptyEvent();
    this.eventPublisher.send(trackingEvent);
  }
}
