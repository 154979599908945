import type { FacilityLocale } from '@/utils/languageDetector';

export enum Locale {
  en = 'en',
  pl = 'pl',
  es = 'es',
  mx = 'mx',
  it = 'it',
  br = 'br',
}

export enum Language {
  en = 'en',
  pl = 'pl',
  es = 'es',
  it = 'it',
  pt = 'pt',
}

export enum IETFLanguage {
  en = 'en',
  pl = 'pl',
  es = 'es',
  mx = 'es-419',
  it = 'it',
  br = 'pt-BR',
}

export interface LanguageConfig {
  locale: Locale;
  language: Language;
  ietfLanguage: IETFLanguage;
}

export interface LanguageConfigBasedOnDomain {
  locale: Locale;
  facilityLocale: FacilityLocale;
  baseUrl: string;
}

export interface LanguageConfigProvider {
  get(): LanguageConfig;
  getBasedOnDomain(): LanguageConfigBasedOnDomain;
}
