import type { FilterOption } from '@/modules/tasks-list/domain/ownership-filter/options/FilterOption';

export class MeOption implements FilterOption {
  readonly type = 'me';

  private constructor(readonly value: string) {}

  getName(translationFunction: (key: string) => string): string {
    return translationFunction('task_filter.me');
  }

  isMatchingOwner(ownerId: string): boolean {
    return this.value === ownerId;
  }

  static make(value: string): MeOption {
    return new MeOption(value);
  }
}
