import { Locale, getLanguageConfig } from '../../../utils/languageDetector';

const { locale } = getLanguageConfig();

export const i18nOptions = {
  locale,
  legacy: true,
  allowComposition: true,
  fallbackLocale: Locale.EN,
  escapeParameterHtml: true,
  silentTranslationWarn: true,
  pluralizationRules: {
    pl: (choice: number, choicesLength: number): number => {
      if (choicesLength === 3) {
        if (choice === 0) {
          return 0;
        }

        if (choice < 5) {
          return 1;
        }

        return 2;
      }

      if (choice < 5) {
        return 0;
      }

      return 1;
    },
  },
};
