export enum UserRole {
  CallQuality = 'ROLE_CALL_QUALITY', // Affects only diagnostic part of calls, reporting quality issues is available for all users
  CreateFacility = 'ROLE_CREATE_FACILITY',
  CrmSettings = 'ROLE_CRM_SETTINGS',
  DocplannerProfileIntegration = 'ROLE_DOCPLANNER_PROFILE_INTEGRATION',
  FacilitySwitcher = 'ROLE_FACILITY_SWITCHER',
  PhoneManagement = 'ROLE_PHONE_MANAGEMENT',
  PreCompliancePhone = 'ROLE_PRE_COMPLIANCE_PHONES',
  SwitchLanguages = 'ROLE_SWITCH_LANGUAGES',
  Translator = 'ROLE_TRANSLATOR',
}
