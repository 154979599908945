import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { DpEventBus } from '@/core/events/DpEventBus';
import { PresenceCallbackFactoryToken } from '../tokens';
import type { PresenceCallbackFactory } from '../../domain/callbacks/PresenceCallbackFactory';
import { StorePresenceCallbackFactory } from '../../adapters/callbacks/StorePresenceCallbackFactory';
import { CallbackRepository } from '../../domain/repositories/CallbackRepository';
import { FacilityCallback } from '../../adapters/callbacks/FacilityCallback';
import { PatientCallback } from '../../adapters/callbacks/PatientCallback';
import { PatientUploadCallback } from '../../adapters/callbacks/PatientUploadCallback';
import { PresenceCallback } from '../../adapters/callbacks/PresenceCallback';
import { UpcomingVisitCallback } from '../../adapters/callbacks/UpcomingVisitCallback';
import { VerificationCallback } from '../../adapters/callbacks/VerificationCallback';
import { StartClientListener } from '../../domain/listeners/StartClientListener';
import { StopClientListener } from '../../domain/listeners/StopClientListener';
import { ConfigurationSet } from '../../domain/events/ConfigurationSet';
import { ConfigurationCleared } from '../../domain/events/ConfigurationCleared';

@injectable()
export class CallbacksProvider implements DpProvider {
  constructor(
    private readonly eventBus: DpEventBus,
    private readonly startClientListener: StartClientListener,
    private readonly stopClientListener: StopClientListener,
    private readonly callbackRepository: CallbackRepository,
    private readonly facilityCallback: FacilityCallback,
    private readonly patientCallback: PatientCallback,
    private readonly patientUploadCallback: PatientUploadCallback,
    private readonly presenceCallback: PresenceCallback,
    private readonly upcomingVisitCallback: UpcomingVisitCallback,
    private readonly verificationCallback: VerificationCallback,
  ) {}

  register(container: DependencyContainer): void {
    container.register<PresenceCallbackFactory>(
      PresenceCallbackFactoryToken,
      StorePresenceCallbackFactory,
    );
  }

  boot(): void {
    this.callbackRepository.add(this.facilityCallback);
    this.callbackRepository.add(this.patientCallback);
    this.callbackRepository.add(this.patientUploadCallback);
    this.callbackRepository.add(this.presenceCallback);
    this.callbackRepository.add(this.upcomingVisitCallback);
    this.callbackRepository.add(this.verificationCallback);

    this.eventBus.subscribe(ConfigurationSet.eventName, this.startClientListener);
    this.eventBus.subscribe(ConfigurationCleared.eventName, this.stopClientListener);
  }
}
