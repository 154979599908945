export enum SubjectSelectColors {
  Danger = 'danger',
  Light = 'light',
  Success = 'success',
}

export interface SubjectSelectOption {
  label: string;
  value: string;
  id?: string;
}
