export class TimelineCall {
  constructor(
    readonly id: string,
    readonly sid: string,
    readonly status: 'abandoned' | 'completed' | 'in-progress' | 'intro' | 'unanswered' | 'waiting',
    readonly direction: 'outgoing' | 'incoming',
    readonly createdAt: string,
    readonly updatedAt: string | null,
    readonly startWaitingAt: string | null,
    readonly answeredAt: string | null,
    readonly duration: number,
    readonly hasQualityReports: boolean,
    readonly recordingUrl: string | null,
    readonly voicemailUrl: string | null,
    readonly channelId: string | null,
    readonly channelName: string | null,
    readonly ownerId: string | null,
    readonly workstationId: string | null,
  ) {}

  get isAbandoned(): boolean {
    return this.status === 'abandoned';
  }

  get isCompleted(): boolean {
    return this.status === 'completed';
  }

  get isInProgress(): boolean {
    return this.status === 'in-progress';
  }

  get isIntro(): boolean {
    return this.status === 'intro';
  }

  get isUnanswered(): boolean {
    return this.status === 'unanswered';
  }

  get isWaiting(): boolean {
    return this.status === 'waiting';
  }

  get isSuccessful(): boolean {
    return ['completed', 'in-progress'].includes(this.status);
  }

  get isOutgoing(): boolean {
    return this.direction === 'outgoing';
  }

  get isIncoming(): boolean {
    return this.direction === 'incoming';
  }
}
