import { inject, injectable } from 'tsyringe';
import { FacilityRepositoryToken, UserRolesRepositoryToken } from '../di/token';
import type { UserRolesRepository } from './user/UserRolesRepository';
import type { FacilityRepository } from './facility/FacilityRepository';
import { SubscriptionFetcher } from './subscription/SubscriptionFetcher';
import { User } from './User';
import { CSModeManager } from './customer-success-mode/CSModeManager';
import type { CSModeEnabledChecker } from './customer-success-mode/CSModeEnabledChecker';

@injectable()
export class UserFactory {
  constructor(
    @inject(UserRolesRepositoryToken)
    private readonly userRolesRepository: UserRolesRepository,
    @inject(FacilityRepositoryToken)
    private readonly facilityRepository: FacilityRepository,
    private readonly subscriptionFetcher: SubscriptionFetcher,
    @inject(CSModeManager)
    private readonly csModeEnabledChecker: CSModeEnabledChecker,
  ) {}

  make(): User {
    const { userId, roles } = this.userRolesRepository.getCurrent();
    const facility = this.facilityRepository.find();
    const subscription = this.subscriptionFetcher.get();

    return new User(userId, roles, this.csModeEnabledChecker, facility, subscription);
  }
}
