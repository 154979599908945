import { injectable } from 'tsyringe';
import type { ChannelsDataRepository } from '@/modules/statistics/domain/channels/ChannelsDataRepository';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { QueryParams } from '@/modules/statistics/domain/QueryParams';
import type { ChannelsData } from '@/modules/statistics/domain/channels/ChannelsData';

@injectable()
export class HttpChannelsDataRepository implements ChannelsDataRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async get(queryParams: QueryParams): Promise<ChannelsData[]> {
    return this.httpClient.get<ChannelsData[]>('api/v0/facility/{facilityId}/stats/channels', {
      ...queryParams,
    });
  }
}
