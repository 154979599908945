import type { ContainerItem } from '../models/ContainerItem';
import type { InputStorage } from '../models/InputStorage';

export class ContainerRepository<T> {
  constructor(
    private readonly storage: InputStorage,
    private readonly prefix: string,
  ) {}

  get item(): ContainerItem<T> | null {
    const item = this.storage.getItem(this.prefix);

    if (!item) {
      return null;
    }

    return JSON.parse(item) as ContainerItem<T>;
  }

  set(value: ContainerItem<T>): void {
    this.storage.setItem(this.prefix, JSON.stringify(value));
  }

  clear(): void {
    this.storage.removeItem(this.prefix);
  }
}
