import { injectable } from 'tsyringe';
import type { PatientRequest as DomainTimelinePatientRequest } from '@/modules/timeline/domain/patient-request/PatientRequest';
import { TimelinePatientRequest } from './TimelinePatientRequest';

@injectable()
export class OutputTimelinePatientRequestFactory {
  make(patientRequest: DomainTimelinePatientRequest): TimelinePatientRequest {
    return new TimelinePatientRequest(
      patientRequest.id,
      patientRequest.createdAt.isoDate,
      patientRequest.type,
      patientRequest.subject,
    );
  }
}
