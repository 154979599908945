import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { errorLogger } from '@/ui/plugins/error-logger';
import type { ErrorLogger } from '../domain/ErrorLogger';

export const RootErrorLoggerToken = Symbol('RootErrorLogger');

export class RootErrorLoggerProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<ErrorLogger>(RootErrorLoggerToken, { useValue: errorLogger });
  }
}
