import type { DpProvider } from '@/core/di/DpProvider';
import { phoneNumberFormatter } from '@/utils/phone-number-formatter';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import type { PhoneNumberFormatter } from '../../domain/patient/PhoneNumberFormatter';
import { PhoneNumberFormatterToken } from '../tokens';

@injectable()
export class PhoneNumberFormatterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<PhoneNumberFormatter>(PhoneNumberFormatterToken, {
      useFactory: () => phoneNumberFormatter,
    });
  }
}
