import type { TaskRepository } from '@/modules/tasks-list/domain/task-feed/TaskRepository';
import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { FilterOption } from '@/modules/tasks-list/domain/ownership-filter/options/FilterOption';
import type { Task } from '@/modules/tasks-list/domain/real-time-updates/Task';

@injectable()
export class HttpTaskRepository implements TaskRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async getInboxPage(ownershipFilterOption: FilterOption): Promise<Task[]> {
    return await this.httpClient.get<any[]>(
      '/api/v0/facility/{facilityId}/tasks/inbox',
      ownershipFilterOption.type === 'everyone'
        ? {}
        : {
            ownerId: ownershipFilterOption.value,
          },
    );
  }

  async getTodoPage(): Promise<Task[]> {
    return await this.httpClient.get<any[]>('/api/v0/facility/{facilityId}/tasks/todo');
  }
}
