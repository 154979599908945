import { inject, injectable } from 'tsyringe';
import { DateFormatterToken } from '../../di/token';
import type { DateFormatter } from '../../domain/DateFormatter';
import { WorkingHour } from '../../domain/working-hours/WorkingHour';
import { WorkingHourOverride } from '../../domain/working-hours/WorkingHourOverride';
import type { InputWorkingHourOverride } from './types/InputWorkingHourOverride';

@injectable()
export class WorkingHourOverrideFactory {
  constructor(
    @inject(DateFormatterToken)
    private readonly dateFormatter: DateFormatter,
  ) {}

  makeMany(inputWorkingHourOverrides: InputWorkingHourOverride[]): WorkingHourOverride[] {
    return inputWorkingHourOverrides.map(this.make, this);
  }

  private make(inputWorkingHourOverride: InputWorkingHourOverride): WorkingHourOverride {
    return new WorkingHourOverride(
      inputWorkingHourOverride.id,
      this.parseToDate(inputWorkingHourOverride.date),
      WorkingHour.make(
        inputWorkingHourOverride.open,
        inputWorkingHourOverride.start ?? '',
        inputWorkingHourOverride.end ?? '',
        inputWorkingHourOverride.siesta_start,
        inputWorkingHourOverride.siesta_end,
      ),
    );
  }

  private parseToDate(dateString: string): Date {
    return this.dateFormatter.parse(dateString);
  }
}
