import { injectable } from 'tsyringe';
import type { DpListener } from '@/core/events/DpListener';
import type { TaskUpdatedEvent } from '@/modules/tasks-list/public/events';
import { TaskUpdateHandler } from '@/modules/task/domain/call-tracking/TaskUpdateHandler';
import type { TaskUpdateEvent } from '@/modules/task/domain/call-tracking/TaskUpdateEvent';

@injectable()
export class TaskUpdateListener implements DpListener {
  constructor(private readonly taskUpdateHandler: TaskUpdateHandler) {}

  handle(event: TaskUpdatedEvent): void {
    this.taskUpdateHandler.handle(this.mapEvent(event));
  }

  private mapEvent(event: TaskUpdatedEvent): TaskUpdateEvent {
    return {
      id: event.id,
    };
  }
}
