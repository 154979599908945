import Pusher from 'pusher-js';
import { inject, injectable } from 'tsyringe';
import { DpEventBus } from '@/core/events/DpEventBus';
import type { Configuration } from '../configuration/Configuration';
import { PusherConfigurationRepository } from '../configuration/PusherConfigurationRepository';
import type { ClientFactory } from '../domain/ClientFactory';
import type { Client } from '../domain/models/Client';
import { HttpAuthorizationRepository } from './HttpAuthorizationRepository';
import { PusherClient } from './models/PusherClient';
import type {
  CommonChannelConfiguration,
  PiiChannelConfiguration,
} from '../domain/models/ChannelConfiguration';
import { CallbackRepository } from '../domain/repositories/CallbackRepository';
import type { PresenceChannel } from '../domain/models/PresenceChannel';
import type { PresenceCallbackFactory } from '../domain/callbacks/PresenceCallbackFactory';
import { PresenceCallbackFactoryToken } from '../di/tokens';
import { ClientUnauthorized } from '../domain/events/ClientUnauthorized';
import { ClientAuthorized } from '../domain/events/ClientAuthorized';

@injectable()
export class PusherClientFactory implements ClientFactory {
  constructor(
    private readonly authorizationRepository: HttpAuthorizationRepository,
    private readonly configurationRepository: PusherConfigurationRepository,
    private readonly callbackRepository: CallbackRepository,
    @inject(PresenceCallbackFactoryToken)
    private readonly presenceCallbackFactory: PresenceCallbackFactory,
    private readonly eventBus: DpEventBus,
  ) {}

  make(): Client {
    const configuration = this.getConfig();
    const client = this.createClient(configuration);

    this.configureChannels(client, configuration);

    return client;
  }

  change(client: Client): Client {
    const configuration = this.getConfig();

    client.reset();

    this.configureChannels(client, configuration);

    return client;
  }

  private getConfig(): Configuration {
    const configuration = this.configurationRepository.get();

    if (configuration.isEmpty) {
      throw new Error('Cannot initialize Pusher. Configuration not found');
    }

    return configuration;
  }

  private createClient(configuration: Configuration): Client {
    return new PusherClient(this.getPusherInstance(configuration.appKey, configuration.cluster));
  }

  private configureChannels(client: Client, configuration: Configuration): void {
    this.configureCommonChannel(client, configuration.commonChannel);
    this.configurePiiChannel(client, configuration.piiChannel);
  }

  private configureCommonChannel(client: Client, configuration: CommonChannelConfiguration): void {
    client.subscribe(configuration.id);
    const commonChannel = client.getChannel(configuration.id) as PresenceChannel;
    const callbacks = this.callbackRepository.get(configuration.name);
    const presenceCallbacks = this.presenceCallbackFactory.make(commonChannel);
    commonChannel.bindMany(callbacks);
    commonChannel.bindMany(presenceCallbacks);
  }

  private configurePiiChannel(client: Client, configuration: PiiChannelConfiguration): void {
    client.subscribe(configuration.id);
    const piiChannel = client.getChannel(configuration.id);
    const callbacks = this.callbackRepository.get(configuration.name);
    piiChannel.bindMany(callbacks);
  }

  private getPusherInstance(appKey: string, cluster: string): Pusher {
    return new Pusher(appKey, {
      cluster,
      channelAuthorization: {
        transport: 'ajax',
        endpoint: '',
        customHandler: this.authorizationHandler.bind(this),
      },
    });
  }

  private async authorizationHandler({ socketId, channelName }, callback) {
    try {
      const { auth, channel_data } = await this.authorizationRepository.auth(channelName, socketId);
      callback(null, { auth, channel_data });
      this.eventBus.publish(new ClientAuthorized());
    } catch {
      callback(true, new Error(`Cannot authorize user for this channel: ${channelName}`));
      this.eventBus.publish(new ClientUnauthorized());
    }
  }
}
