import { injectable } from 'tsyringe';
import type { CustomTrackingEvent } from '../../dto/CustomTrackingEvent';

@injectable()
export class NavbarVisibilityChangeEventConverter {
  make(type: 'shown' | 'hidden'): CustomTrackingEvent {
    return {
      event: `navbar_${type}`,
    };
  }
}
