export class Task {
  constructor(
    readonly id: string,
    readonly patientId: string,
    readonly caller: string,
  ) {}

  static empty(): Task {
    return new Task('', '', '');
  }
}
