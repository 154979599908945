import { Media } from '../media/Media';

export enum VoiceMessageType {
  Text = 'text',
  File = 'file',
}

export enum VoiceMessageName {
  WelcomeMessage = 'welcomeMessage',
  VoiceMenu = 'voiceMenu',
  FacilityAfterHours = 'facilityAfterHours',
  VoicemailInQueue = 'voicemailInQueue',
}

export interface VoiceMessageCreateInput {
  file?: Media | null;
  type: VoiceMessageType;
  value: string;
  placeholder?: string;
}

export interface VoiceMessageUpdateInput {
  fileUrl: string;
  type: VoiceMessageType;
  value: string;
}

export class VoiceMessage {
  file: Media | null;

  type: VoiceMessageType;

  value: string;

  placeholder: string;

  private constructor(data: VoiceMessageCreateInput) {
    this.file = Media.tryMake(data.file);
    this.type = data.type;
    this.value = data.value;
    this.placeholder = data.placeholder || '';
  }

  get fileUrl(): string {
    return this.file?.url ?? '';
  }

  update(data: VoiceMessageUpdateInput): VoiceMessage {
    this.updateFile(data.fileUrl);
    this.type = data.type;
    this.value = data.value;

    return this;
  }

  updateFile(fileUrl: string): void {
    if (fileUrl === '') {
      this.file = null;
    }

    if (fileUrl !== this.fileUrl) {
      this.file = Media.makeDirty({
        url: fileUrl,
      });
    }
  }

  static make(data: VoiceMessageCreateInput): VoiceMessage {
    return new VoiceMessage(data);
  }

  static makeEmpty(): VoiceMessage {
    return new VoiceMessage({
      type: VoiceMessageType.Text,
      value: '',
    });
  }

  static tryMake(data?: VoiceMessageCreateInput | null): VoiceMessage | null {
    return data ? new VoiceMessage(data) : null;
  }
}
