import type { Channel } from 'pusher-js';
import type { Callback } from '../../domain/models/Callback';
import type { PrivateChannel } from '../../domain/models/PrivateChannel';

export class PusherPrivateChannel implements PrivateChannel {
  constructor(private readonly channel: Channel) {}

  bind(eventName: string, handler: (...args: any[]) => void): void {
    this.channel.bind(eventName, handler);
  }

  unbind(eventName: string, handler: (...args: any[]) => void): void {
    this.channel.unbind(eventName, handler);
  }

  bindMany(callbacks: Callback[]): void {
    callbacks.forEach(({ eventName, handler }) => {
      this.bind(eventName, handler);
    });
  }
}
