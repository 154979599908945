<template>
  <div :class="$b()">
    <slot />
    <div v-if="value" :class="$b('badge')">
      {{ value }}
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

@Component({
  name: 'AvatarWithBadge',
})
export default class AvatarWithBadge extends Vue {
  @Prop({ type: Number, required: true })
  readonly value: number;
}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

$badge-size: v.$spacer-md;

.dp-avatar-with-badge {
  position: relative;
  display: inline-block;
  line-height: 0;

  &__badge {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0.666rem;
    min-width: $badge-size;
    padding: 0 v.$spacer-xs;
    background: v.$white;
    font-size: v.$font-size-sm;
    line-height: $badge-size;
  }
}
</style>
