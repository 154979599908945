export class PhoneNumber {
  private constructor(readonly value: string) {}

  static of(phoneNumber: string): PhoneNumber {
    return new PhoneNumber(phoneNumber);
  }

  static empty(): PhoneNumber {
    return new PhoneNumber('');
  }
}
