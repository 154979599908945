import type { DpEvent } from '@/core/events/DpEvent';
import type { Call } from '../../models/Call';

export class CallChanged implements DpEvent {
  static readonly eventName = 'PhoneWidget:CallChanged';

  readonly name = CallChanged.eventName;

  constructor(readonly call: Call) {}
}
